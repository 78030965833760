import { FlashList, type ListRenderItem } from '@shopify/flash-list';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import SecondaryHeader from '@/components/headers/SecondaryHeader';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import { ConversationActivityCard } from '@/modules/activity/components';
import type { MainStackScreenProps } from '@/navigation/types';
import type { ChatMessage } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const ItemSeparatorComponent = React.memo(() => (
  <View style={styles.itemSeparator} />
));

export type ViewProps = MainStackScreenProps<'ArchivedConversations'>;

export const ArchivedConversations = ({ route }: ViewProps) => {
  const { chatUnitId } = route.params;
  const { navigate } = useNavigation();
  const userId = useUserStore(useShallow(state => state.user?.id));

  const getArchivedConversationsByTableId = useConversationStore(
    useShallow(state => state.getArchivedConversationsByTableId),
  );

  const archivedConversations = getArchivedConversationsByTableId(chatUnitId);

  const sortedMessages = useMessageStore(
    useShallow(state =>
      state.getMostRecentMessagesFromConversations(
        chatUnitId,
        archivedConversations.map(convo => convo.conversationId),
      ),
    ),
    isEqual,
  );

  const onActivityPress = useCallback(
    (message: ChatMessage) => {
      navigate('ArchivedConversation', {
        conversationId: message.conversationId,
        chatUnitId: message.chatUnitId,
      });
    },
    [navigate],
  );

  const { translate } = useTranslations();
  const { top } = useSafeAreaInsets();

  const renderItem: ListRenderItem<ChatMessage> = useCallback(
    ({ item }) => (
      <ConversationActivityCard
        message={item}
        onPress={onActivityPress}
        userId={userId || ''}
        showChatunitName={false}
      />
    ),
    [onActivityPress, userId],
  );

  return (
    <View style={styles.container}>
      <View
        style={[
          {
            backgroundColor: Greys.shade0,
          },
          { paddingTop: top },
        ]}>
        <SecondaryHeader
          backButtonText={'common_back'}
          title={translate('conversation_archive')}
        />
      </View>

      {sortedMessages.length > 0 && (
        <FlashList
          data={sortedMessages}
          ItemSeparatorComponent={ItemSeparatorComponent}
          keyExtractor={item => item.id.toString()}
          renderItem={renderItem}
          estimatedItemSize={200}
          drawDistance={450}
          showsVerticalScrollIndicator={false}
          refreshing={false} // stop the spinner from displaying in place with loading bar
          keyboardShouldPersistTaps="handled"
        />
      )}
    </View>
  );
};

const ITEM_SEPARATOR_HEIGHT = 1;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemSeparator: {
    height: ITEM_SEPARATOR_HEIGHT,
  },

  noResultsContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultsText: {
    fontSize: 12,
    fontWeight: '700',
    marginTop: 30,
    marginBottom: 20,
  },
  noResultsQuery: { fontSize: 28, fontWeight: '400' },
});

export default ArchivedConversations;
