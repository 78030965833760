import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { Greys } from '@/ui/common/colors';

type Props = {
  count: number;
  hidden?: boolean;
  invisible?: boolean;
};

export const MoreIndicator = ({ count, hidden, invisible }: Props) => {
  if (hidden) {
    return null;
  }

  const styles = getStyles(!!invisible);

  return <Text style={styles.moreIndicator}>+ {count} more</Text>;
};

const getStyles = (isInvisible: boolean) =>
  StyleSheet.create({
    moreIndicator: {
      borderColor: Greys.shade999,
      borderRadius: 14,
      borderWidth: 1,
      fontSize: 10,
      fontWeight: 'bold',
      opacity: isInvisible ? 0 : 1,
      paddingHorizontal: 10,
      paddingVertical: 6,
    },
  });
