import { ChatUnitColorId } from '../../API';

import { validateInput } from './validateInput';

import type { ChatUnit as IChatUnit } from '@/services/chatUnit/types';

export const chatUnitFactory = (input: any): IChatUnit => {
  validateInput(
    input,
    ['id', 'title', 'colorId', 'backgroundImage', 'type'],
    'ChatUnit',
  );
  return {
    allConversationId: input.allConversationId ?? undefined,
    allowedUserIds: input.allowedUserIds ?? undefined,
    backgroundImage: input.backgroundImage,
    colorId: ChatUnitColorId[input.colorId as keyof typeof ChatUnitColorId],
    createdAt: input.createdAt ?? '',
    description: input.description ?? undefined,
    id: input.id,
    owner: input.owner ?? undefined,
    title: input.title,
    type: input.type,
    subtype: input.subtype,
    updatedAt: input.updatedAt ?? '',
    url: input.url ?? undefined,
    organisationId: input.organisationId ?? undefined,
  };
};
