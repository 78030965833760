// FileOpener.ts

import { NativeModules, Platform } from 'react-native';

// Define the TypeScript interface for our native module
interface FileOpenerModuleInterface {
  openFile(localFileUrl: string, mimeType: string): Promise<string>;
  openFileWithChooser(localFileUrl: string, mimeType: string): Promise<string>;
}

// Get the module from NativeModules
const { RCFileOpener } = NativeModules;

// Ensure the module exists and conforms to our interface
const FileOpener: FileOpenerModuleInterface = RCFileOpener;

// Function to open a file using the native module
export const openFileAndroid = async (
  localFileUrl: string,
  mimeType: string,
): Promise<boolean> => {
  if (!FileOpener || Platform.OS !== 'android') {
    console.error(
      'FileOpener module is not available or not supported on this platform',
    );
    return false;
  }

  try {
    await FileOpener.openFile(localFileUrl, mimeType);
    return true;
  } catch (error) {
    console.error('Error opening file:', error);
    return false;
  }
};

// Function to open a file with chooser using the native module
export const openFileWithChooserAndroid = async (
  localFileUrl: string,
  mimeType: string,
): Promise<boolean> => {
  if (!FileOpener || Platform.OS !== 'android') {
    console.error(
      'FileOpener module is not available or not supported on this platform',
    );
    return false;
  }

  try {
    await FileOpener.openFileWithChooser(localFileUrl, mimeType);
    return true;
  } catch (error) {
    console.error('Error opening file with chooser:', error);
    return false;
  }
};

// Export the functions for use throughout the codebase
export default {
  openFileAndroid,
  openFileWithChooserAndroid,
};
