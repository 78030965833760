import { useCallback } from 'react';
import type PagerView from 'react-native-pager-view';
import type { SharedValue } from 'react-native-reanimated';

import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import { logger } from '@/services/logger/logger';
import { web } from '@/utilities/platform';

export type Props = {
  offset: SharedValue<number>;
  pagerRef: React.RefObject<PagerView>;
};

export const useSwipeChatHeads = ({ pagerRef, offset }: Props) => {
  const storeApi = useActiveConversationStoreApi();

  const onSwipeChatHeads = useCallback(async () => {
    const {
      activeConversationIds,
      setPendingConvoId,
      setWebActiveConversationId,
      getOrCreateConversationId,
      getChatUnitUserIds,
    } = storeApi.getState();
    const newConversationId = await getOrCreateConversationId(
      getChatUnitUserIds(),
    );
    if (!newConversationId) {
      logger.warn(
        'onPressChathead::unexpectedConversationId',
        newConversationId,
      );
      return;
    }

    let targetConversationIndex = activeConversationIds.findIndex(
      id => id === newConversationId,
    );
    if (targetConversationIndex < 0) {
      setPendingConvoId(newConversationId);
      targetConversationIndex = 0;
    }

    pagerRef?.current?.setPageWithoutAnimation(targetConversationIndex);
    if (web) {
      setWebActiveConversationId(newConversationId);
    }
    // web cant use set Page without animation since it is a pagerview method.
    offset.value = targetConversationIndex;
  }, [offset, pagerRef]);

  return onSwipeChatHeads;
};
