import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';

import { Icon } from '@/ui/app/elements';
import type { IconKey } from '@/ui/app/elements/Icon/icons';
import { Brand, Greys } from '@/ui/common/colors';

interface Props {
  iconKey: IconKey;
  onPress?: () => void;
  style?: ViewStyle;
  subtitle: string;
  title: string;
}

const ICON_SIZE = 30;

const GridButton = ({ iconKey, onPress, subtitle, title }: Props) => {
  const isActive = !!onPress;

  const containerStyle = StyleSheet.flatten([
    styles.container,
    { backgroundColor: isActive ? Greys.shade0 : Greys.shade200 },
  ]);

  const textStyle = StyleSheet.flatten([
    styles.text,
    { color: isActive ? Brand.primary100 : Greys.shade500 },
  ]);

  const titleStyle = StyleSheet.flatten([
    styles.title,
    { color: isActive ? Greys.shade600 : Greys.shade500 },
  ]);

  return (
    <TouchableOpacity
      disabled={!isActive}
      onPress={onPress}
      style={containerStyle}>
      <View style={styles.content}>
        <Icon
          fillColor={isActive ? Greys.shade500 : Greys.shade400}
          iconKey={iconKey}
          size={ICON_SIZE}
        />
        <Text style={titleStyle}>{title}</Text>
      </View>
      <Text ellipsizeMode="tail" numberOfLines={2} style={textStyle}>
        {isActive ? subtitle : 'Coming soon'}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 15,
    width: '49.9%',
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
    marginTop: 8,
    textAlign: 'left',
  },
  title: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginLeft: 8,
  },
});

export default GridButton;
