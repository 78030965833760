import React, { type FC } from 'react';
import { Trans } from 'react-i18next';
import { Text } from 'react-native';

import { Link } from './Link';

import { useTheme } from '@/domain/theme';
import {
  LICENCE_AGREEMENT,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from '@/utilities/constants';

interface Props {
  variant?: 'dark' | 'light';
}

export const Terms: FC<Props> = ({ variant = 'dark' }) => {
  const {
    theme: {
      terms: { variants, text: styles },
    },
  } = useTheme();

  const color = variants[variant].color;

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <Text style={[styles, variants[variant], { textAlign: 'center' }]}>
      <Trans
        i18nKey="terms"
        components={{
          terms: <Link href={TERMS_OF_USE} textStyle={{ color }} />,
          privacy: <Link href={PRIVACY_POLICY} textStyle={{ color }} />,
          eula: <Link href={LICENCE_AGREEMENT} textStyle={{ color }} />,
        }}
      />
    </Text>
  );
};
