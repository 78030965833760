import React from 'react';
import { StyleSheet, View } from 'react-native';

import HourglassIcon from '@/assets/svg/hourglass.svg';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';

type Props = {
  status?: ChatUnitUserStatus;
};

const { avatarSize } = getAvatarConfig();

export const AvatarStatusIcon = ({ status }: Props) => {
  switch (status) {
    case ChatUnitUserStatus.ACTIVE:
      return <View style={styles.dot} />;
    case ChatUnitUserStatus.PENDING:
      return (
        <View style={styles.statusIcon}>
          <HourglassIcon height={14} />
        </View>
      );
    default:
      return null;
  }
};

const styles = StyleSheet.create({
  dot: {
    borderRadius: avatarSize / 6,
    bottom: 4,
    height: avatarSize / 6,
    left: 4,
    position: 'absolute',
    width: avatarSize / 6,
  },
  statusIcon: {
    left: -1,
    position: 'absolute',
    right: 0,
    bottom: -1,
    zIndex: 10,
  },
});
