import { StyleSheet } from 'react-native';

import { ligthBlue } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import type { TableColor } from '@/utilities/constants/table-colors';
import { web } from '@/utilities/platform';

export const getStyles = (
  selected: boolean,
  color: TableColor,
  isWhiteMode?: boolean,
) =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      // @ts-ignore: Is typed correctly for web
      marginVertical: web ? '20px' : wp(3),
      // @ts-ignore: Is typed correctly for web
      height: web ? '40px' : wp(11),
      flex: 1,
      flexGrow: 0,
      width: 20,
      // @ts-ignore: Is typed correctly for web
      flexBasis: web ? '80px' : '13%',
    },
    item: {
      backgroundColor: color.color,
      borderRadius: wp(6.5),
      // @ts-ignore: Is typed correctly for web
      height: web ? '52px' : wp(11),
      // @ts-ignore: Is typed correctly for web
      width: web ? '50px' : wp(11),
    },
    selectedOverlay: {
      borderColor: selected
        ? ligthBlue
        : isWhiteMode
          ? Greys.shade0
          : Greys.shade600,
      borderRadius: wp(6.5),
      position: 'absolute',
      zIndex: 100,
      ...(selected
        ? {
            borderWidth: 3,
            padding: 2,
          }
        : {
            borderWidth: 1.5,
            padding: 0,
          }),
    },
  });
