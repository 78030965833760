import { Linking } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';

import { android, web } from '../platform';

async function openLink(url: string) {
  const sanitizedUrl = encodeURI(url);

  if (web) {
    Linking.openURL(sanitizedUrl);
    return;
  }

  if (InAppBrowser && (await InAppBrowser?.isAvailable())) {
    await InAppBrowser.open(sanitizedUrl, {
      animated: true,
      animations: {
        endEnter: 'slide_in_left',
        endExit: 'slide_out_right',
        startEnter: 'slide_in_right',
        startExit: 'slide_out_left',
      },
      dismissButtonStyle: 'cancel',
      enableBarCollapsing: false,
      enableDefaultShare: true,
      enableUrlBarHiding: true,
      forceCloseOnRedirection: false,
      modalEnabled: true,

      modalPresentationStyle: 'fullScreen',
      modalTransitionStyle: 'coverVertical',
      readerMode: false,
      showInRecents: android,
      showTitle: true,
    });
  } else {
    Linking.openURL(sanitizedUrl);
  }
}

export default openLink;
