import { useRoute } from '@react-navigation/native';
import { useEffect, useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import { useMMKVBoolean } from 'react-native-mmkv';
import {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import type { RiveRef } from 'rive-react-native';

import type { OnboardingProps } from './types';

import useNavigation from '@/hooks/useNavigation';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import { useSwipe } from '@/hooks/useSwipe';

const WINDOW_WIDTH = Dimensions.get('window').width;
const TOTAL_SLIDES = 5;
const STATE_MACHINE_NAME = 'Onboarding SM';
const ACTION_NAME = 'Screen';
const SLIDES = [1, 2, 3, 4, 5];
const ANIMATION_NAME = 'onboarding';

export const useConnect = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { params } = useRoute<OnboardingProps['route']>();
  const [isUnmounting, setIsUnmounting] = useState(false);

  const [_onBoardingViewed, setOnBoardingViewed] =
    useMMKVBoolean('onboardingViewed');
  useStatusBarHandler('dark-content');
  const { goBack, navigate } = useNavigation();
  const transition = useSharedValue(0);
  const rotationAngle = useSharedValue(240);

  const textOpacity = useSharedValue(1);
  const subtitleOpacity = useSharedValue(0.3);
  const scrollX = useSharedValue(0);
  const skipOpacity = useSharedValue(1);

  const riveAnimationRef = useRef<RiveRef>(null);
  const previousStep = useRef(currentStep);
  const isFirstRender = useRef(true);

  const applyRotation = (degree: number) => {
    rotationAngle.value = withTiming(rotationAngle.value + degree, {
      duration: 1000,
      easing: Easing.linear,
    });
  };

  const setAnimationLevel = (level: number) => {
    riveAnimationRef.current?.setInputState(
      STATE_MACHINE_NAME,
      ACTION_NAME,
      level,
    );
  };

  const slideTransitionStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: -currentStep * WINDOW_WIDTH + transition.value,
        },
      ],
    };
  });

  const textOpacityStyle = useAnimatedStyle(() => {
    return {
      opacity: textOpacity.value,
    };
  });

  const subtitleOpacityStyle = useAnimatedStyle(() => {
    return {
      opacity: subtitleOpacity.value,
    };
  });

  const skipStyle = useAnimatedStyle(() => {
    return {
      opacity: skipOpacity.value,
    };
  });

  useEffect(() => {
    if (isFirstRender.current) {
      previousStep.current = currentStep;
      isFirstRender.current = false;
      return;
    }

    const isMovingForward = currentStep > previousStep.current;
    previousStep.current = currentStep;

    transition.value = isMovingForward ? WINDOW_WIDTH : -WINDOW_WIDTH;

    const scrollDistance = WINDOW_WIDTH * currentStep;

    setTimeout(() => {
      scrollX.value = withTiming(scrollDistance, {
        duration: 1000,
        easing: Easing.linear,
      });
    }, 100);

    if (currentStep === 2 && !isMovingForward) {
      textOpacity.value = withTiming(1, {
        duration: 1000,
        easing: Easing.linear,
      });
      subtitleOpacity.value = withTiming(0.3, {
        duration: 1000,
        easing: Easing.linear,
      });
      return;
    }
    if (currentStep === 3 && isMovingForward) {
      textOpacity.value = withTiming(0.3, {
        duration: 1000,
        easing: Easing.linear,
      });
      subtitleOpacity.value = withTiming(1, {
        duration: 1000,
        easing: Easing.linear,
      });
      return;
    }
    if (currentStep === 4) {
      skipOpacity.value = withTiming(0, {
        duration: 1000,
        easing: Easing.linear,
      });
    }
    if (currentStep === 3) {
      skipOpacity.value = withTiming(1, {
        duration: 1000,
        easing: Easing.linear,
      });
    }

    setTimeout(() => {
      transition.value = withTiming(0, {
        duration: 1000,
        easing: Easing.linear,
      });
    }, 100);
  }, [
    currentStep,
    scrollX,
    skipOpacity,
    subtitleOpacity,
    textOpacity,
    transition,
  ]);

  const moveStepForward = () => {
    if (currentStep >= TOTAL_SLIDES - 1) {
      return;
    }

    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    setAnimationLevel(nextStep + 1);

    switch (nextStep) {
      case 1:
        applyRotation(70);
        break;
      case 2:
        applyRotation(50);
        break;
      case 4:
        applyRotation(60);
        break;
      default:
        break;
    }
  };

  const moveStepBackward = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep(currentStep - 1);
    setAnimationLevel(currentStep);

    switch (currentStep) {
      case 1:
        applyRotation(-70);
        break;
      case 2:
        applyRotation(-50);
        break;
      case 4:
        applyRotation(-60);
        break;
      default:
        break;
    }
  };

  const { onTouchEnd, onTouchStart } = useSwipe(
    moveStepForward,
    moveStepBackward,
  );

  const backToMain = () => {
    setIsUnmounting(true);
    setTimeout(() => {
      goBack();
    }, 50);
  };
  const handleSkipOrDone = () => {
    setOnBoardingViewed(true);
    params?.fromMain ? backToMain() : navigate('OnboardingStart');
  };

  return {
    actionName: ACTION_NAME,
    animatedRotationStyle: useAnimatedStyle(() => ({
      transform: [{ rotate: `${rotationAngle.value}deg` }],
    })),
    animationName: ANIMATION_NAME,
    currentStep,
    handleSkipOrDone,
    isUnmounting,
    onTouchEnd,
    onTouchStart,
    riveRef: riveAnimationRef,
    scrollX,
    skipStyle,
    slideTransitionStyle,
    slides: SLIDES,
    stateMachineName: STATE_MACHINE_NAME,
    subtitleOpacityStyle,
    textOpacityStyle,
    transition,
  };
};
