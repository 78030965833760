import React, { useCallback, useMemo, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Day, isSameDay, utils } from 'react-native-gifted-chat';
import type { DayProps } from 'react-native-gifted-chat';
import type {
  IMessage as GiftedMessage,
  MessageProps,
} from 'react-native-gifted-chat';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import Bubble from './BubbleMessage';
import MessageOptionsSheet from './MessageOptionsSheet';
import type { CustomMessage, GiftedUser } from './types';

import Avatar from '@/components/avatar';
import { constants } from '@/constants';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { getName } from '@/domain/user/functions/getName';
import type { LocalAvatar } from '@/types';
import { Spacer } from '@/ui/app/elements';
import { Brand, Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';

const { isSameUser } = utils;

type AvatarPosition = 'left' | 'right';

interface ChatMsg extends GiftedMessage {
  user: GiftedUser;
}
export interface Props extends MessageProps<ChatMsg> {
  avatars: LocalAvatar[];
  myUser: GiftedUser;
}

const Message = (props: CustomMessage) => {
  const isOwner = props?.myUser?.id === props?.currentMessage?.user?._id;
  const [open, setOpen] = useState(false);
  const chatUnitUser = useTableUsersStore(state =>
    state.getTableUser(props?.currentMessage?.chatUnitUserId),
  );

  // Use a shared value to control the style transition
  const borderWidth = useSharedValue(0); // Initial borderWidth is 0

  // Create an animated style based on the shared value
  const animatedStyle = useAnimatedStyle(() => {
    // Interpolate the borderWidth value to a color value
    const backgroundColor = interpolateColor(
      borderWidth.value,
      [0, 1.5], // Input range corresponding to the borderWidth animated value
      ['transparent', `${Brand.primary50}1A`], // Output range of colors
    );

    return {
      backgroundColor: backgroundColor,
      borderWidth: borderWidth.value,
      paddingVertical: borderWidth.value * 16,
      flexDirection: 'row',
      marginHorizontal: 5,
      paddingHorizontal: 5,
      borderColor: borderWidth.value > 0 ? Brand.primary50 : 'transparent',
      borderRadius: BorderRadius.sm,
    };
  });

  const initiateMessageAnimation = useCallback(() => {
    // Change the borderWidth value to trigger the animated style
    borderWidth.value = withSpring(1.5, { duration: 400 });
  }, [borderWidth]);

  const closeModalAndInitiateHideAnimation = useCallback(() => {
    setOpen(false);
    borderWidth.value = withTiming(0, { duration: 200 });
  }, [borderWidth]);

  const getInnerComponentProps = useCallback(() => {
    const { currentMessage, ...restProps } = props;
    const user = props?.currentMessage?.user;
    const _id = currentMessage?._id ?? '';
    const username = chatUnitUser?.username ?? '';
    const userId = user?._id ?? '';
    const text = currentMessage?.text ?? '';
    const createdAt = currentMessage?.createdAt ?? new Date();
    return {
      ...restProps,
      currentMessage: {
        ...currentMessage,
        _id,
        createdAt,
        id: _id,
        text,
        user: {
          ...user,
          _id: userId,
          id: userId,
          username,
        },
      },
      isSameUser,
      position: 'left' as AvatarPosition,
    };
  }, [props, chatUnitUser]);

  const renderDay = useCallback(() => {
    if (props?.currentMessage?.createdAt) {
      const dayProps = getInnerComponentProps() as DayProps;
      return <Day {...dayProps} textStyle={styles.day} />;
    }
    return null;
  }, [getInnerComponentProps, props]);

  const onLongPress = useCallback(() => {
    if (!props.currentMessage) {
      return;
    }

    const isImage = constants.supportedImageExtensions.some(ext =>
      props.currentMessage?.image?.toLowerCase().endsWith(ext),
    );

    // Clean up above
    if (!isImage && !props.currentMessage?.text) {
      return;
    }

    initiateMessageAnimation();

    setOpen(true);
  }, [initiateMessageAnimation, props.currentMessage]);

  const renderBubble = useCallback(() => {
    const bubbleProps = getInnerComponentProps();
    return (
      <Bubble
        {...bubbleProps}
        myUser={props.myUser}
        chatUnitId={chatUnitUser?.chatUnitId}
        onLongPress={onLongPress}
      />
    );
  }, [getInnerComponentProps, props, chatUnitUser, onLongPress]);

  const marginBottom = useMemo(() => {
    if (!props?.currentMessage || !props.nextMessage) {
      return 0;
    }
    return isSameUser(props?.currentMessage, props.nextMessage) &&
      isSameDay(props?.currentMessage, props.nextMessage) &&
      !props.previousMessage?.image
      ? 10
      : 10;
  }, [props?.currentMessage, props.nextMessage, props.previousMessage?.image]);

  const isConsecutiveMessage = useMemo(() => {
    if (!props?.currentMessage || !props.previousMessage) {
      return false;
    }
    return (
      isSameUser(props?.currentMessage, props.previousMessage) &&
      isSameDay(props?.currentMessage, props.previousMessage)
    );
  }, [props?.currentMessage, props.previousMessage]);

  return (
    <>
      <View>
        {renderDay()}
        <Pressable onLongPress={onLongPress}>
          <Animated.View style={animatedStyle}>
            {isConsecutiveMessage ? (
              <Spacer width={44} />
            ) : (
              <>
                <Avatar
                  size={32}
                  imageKey={chatUnitUser?.avatar}
                  displayName={
                    chatUnitUser ? getName(chatUnitUser, 'short') : ''
                  }
                />
                <Spacer width={12} />
              </>
            )}
            {renderBubble()}
          </Animated.View>
        </Pressable>
        <Spacer height={marginBottom} />
      </View>
      {props.currentMessage && open ? (
        <MessageOptionsSheet
          open={open}
          message={props}
          isOwner={isOwner}
          onClose={closeModalAndInitiateHideAnimation}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  day: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 11,
    fontWeight: '700',
  },
});

const checkIfEqual = (
  prevMessage: CustomMessage,
  nextMessage: CustomMessage,
) => {
  return (
    prevMessage?.currentMessage?._id === nextMessage?.currentMessage?._id &&
    prevMessage?.previousMessage?._id === nextMessage?.previousMessage?._id
  );
};

export default React.memo(Message, checkIfEqual);
