import type { Conversation } from '@/services/types';

export const sortConversationsForRoom = (
  conversations: Conversation[],
  allConversationId?: string,
) => {
  if (!allConversationId) {
    return conversations;
  }

  // find the all conversation and move it to the start of the conversations
  const allConversation = conversations.find(
    conversation => conversation.conversationId === allConversationId,
  );

  if (allConversation) {
    const allConversationIndex = conversations.indexOf(allConversation);
    conversations.splice(allConversationIndex, 1);
    conversations.unshift(allConversation);
  }

  return conversations;
};
