import * as React from 'react';
import Svg, { Mask, Path, G } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgRoomsLgFlat = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}>
    <Mask
      id="roomsLgFlat_svg__a"
      width={73}
      height={79}
      x={4}
      y={0}
      maskUnits="userSpaceOnUse">
      <Path
        fill="#fff"
        d="M45.503 76.537a7.907 7.907 0 0 1-9.388 0L11.249 58.193a7.907 7.907 0 0 1-3.175-5.591L5.01 21.344a7.907 7.907 0 0 1 4.754-8.04l27.93-11.97a7.907 7.907 0 0 1 6.23 0l27.93 11.97a7.907 7.907 0 0 1 4.754 8.04l-3.064 31.258a7.907 7.907 0 0 1-3.175 5.591z"
      />
    </Mask>
    <G stroke="#fff" mask="url(#roomsLgFlat_svg__a)">
      <Path
        strokeWidth={3}
        d="m10.911 57.945 25.202 18.592a7.907 7.907 0 0 0 9.388 0l25.202-18.592a1.977 1.977 0 0 0-.227-3.326L44.592 40.505a7.907 7.907 0 0 0-7.57 0L11.139 54.62a1.977 1.977 0 0 0-.228 3.326ZM11.038 54.672l25.648-13.986a7.906 7.906 0 0 0 4.121-6.941V2.995a1.977 1.977 0 0 0-2.755-1.817L9.762 13.302a7.907 7.907 0 0 0-4.754 8.04l3.116 31.787a1.977 1.977 0 0 0 2.914 1.543ZM40.809 2.995v30.75a7.907 7.907 0 0 0 4.121 6.941l25.648 13.986a1.977 1.977 0 0 0 2.914-1.543l3.116-31.788a7.907 7.907 0 0 0-4.755-8.039L43.564 1.178a1.977 1.977 0 0 0-2.755 1.817Z"
      />
      <Path
        strokeWidth={4.5}
        d="M44.167 74.727a5.657 5.657 0 0 1-6.716 0L12.585 56.382a5.657 5.657 0 0 1-2.272-4L7.25 21.124a5.657 5.657 0 0 1 3.401-5.751l27.93-11.97a5.657 5.657 0 0 1 4.457 0l27.93 11.97a5.656 5.656 0 0 1 3.401 5.751l-3.063 31.258a5.657 5.657 0 0 1-2.272 4z"
      />
    </G>
  </Svg>
);
export default SvgRoomsLgFlat;
