import React from 'react';
import {
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useMMKVBoolean } from 'react-native-mmkv';
import Animated, {
  FadeOutRight,
  Layout,
  FadeInRight,
} from 'react-native-reanimated';
import Rive, { Fit } from 'rive-react-native';

import LogoDark from '@/assets/svg/logo-dark.svg';
import SubtitleBold from '@/components/text/SubtitleBold';
import useNavigation from '@/hooks/useNavigation';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import { deepNavy } from '@/theme/colors';
import { hp, wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import {
  LICENCE_AGREEMENT,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from '@/utilities/constants';
import openLink from '@/utilities/inAppBrowser';
import { ios, web } from '@/utilities/platform';

const riveName = ios ? '8seats_intro' : 'seats_intro';

const GetStartedScreen = () => {
  const { translate } = useTranslations();
  const { navigate } = useNavigation();
  const [_onBoardingViewed, setOnBoardingViewed] =
    useMMKVBoolean('onboardingViewed');
  useStatusBarHandler('light-content');

  return (
    <View style={styles.container}>
      <StatusBar barStyle="light-content" hidden={false} translucent={true} />

      <View style={styles.rive}>
        <Rive fit={Fit.Cover} resourceName={riveName} style={styles.rive} />
      </View>

      {web && (
        <View style={styles.titleContainer}>
          <LogoDark style={styles.webLogoDark} />
          <Text style={styles.title}>{translate('home_screen_title')}</Text>
        </View>
      )}

      <Animated.View
        entering={FadeInRight.duration(500)}
        exiting={FadeOutRight.duration(500)}
        layout={Layout.springify()}
        style={styles.image}>
        {!web && (
          <>
            <LogoDark style={{ top: wp(42) }} />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{translate('home_screen_title')}</Text>
            </View>
          </>
        )}

        <View style={styles.bottomContainer}>
          <Text style={styles.text}>
            {translate('view_our_text')}{' '}
            <Text
              onPress={() => openLink(TERMS_OF_USE)}
              style={styles.textBold}>
              {translate('terms_of_use')}
            </Text>
            {', '}
            <Text
              onPress={() => openLink(PRIVACY_POLICY)}
              style={styles.textBold}>
              {translate('privacy_policy')}
            </Text>{' '}
            {translate('common_and_text')}{' '}
            <Text
              onPress={() => openLink(LICENCE_AGREEMENT)}
              style={styles.textBold}>
              {translate('end_user_agreement')}
            </Text>
          </Text>

          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() => {
              setOnBoardingViewed(true);
              navigate('OnboardingVideo');
            }}
            style={styles.buttonContainer}>
            <LinearGradient
              colors={[Greys.shade0, '#CCC5BE']}
              style={styles.button}>
              <SubtitleBold
                children={translate('get_started_text')}
                style={styles.signInText}
              />
            </LinearGradient>
          </TouchableOpacity>
        </View>

        <LinearGradient
          colors={['transparent', deepNavy]}
          end={{ x: 0, y: 2 }}
          start={{ x: 0, y: 0 }}
          style={styles.linearGradient}
        />
      </Animated.View>
    </View>
  );
};

export default GetStartedScreen;

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    bottom: wp(20),
    flex: 1,
    justifyContent: 'flex-end',
    paddingHorizontal: 40,
    width: web ? '100%' : wp(100),
    zIndex: 10000,
  },
  breker: {
    backgroundColor: Greys.shade0,
    height: 1.5,
    width: '42%',
  },
  button: {
    backgroundColor: Greys.shade0,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: 0,
    zIndex: 100000000,
  },
  buttonContainer: {
    width: web ? 150 : '100%',
  },
  container: {
    flex: 1,
    position: 'relative',
  },
  createAccountText: {
    color: Greys.shade0,
    textAlign: 'center',
  },
  iAmNewText: { color: Greys.shade0, textAlign: 'center' },
  image: {
    alignItems: 'center',
    flex: 1,
  },
  linearGradient: {
    bottom: -100,
    height: web ? 0 : hp(40),
    position: 'absolute',
    width: web ? '100%' : wp(100),
  },
  orContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: wp(10),
  },
  orText: {
    color: Greys.shade0,
    marginTop: 4,
    paddingHorizontal: wp(3),
  },
  rive: {
    height: web ? '100%' : hp(100),
    position: 'absolute',
    width: web ? '100%' : wp(100),
  },
  signInText: { color: Greys.shade800, textAlign: 'center' },
  text: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 30,
    textAlign: 'center',
  },
  text2: {
    bottom: 10,
    paddingBottom: 20,
  },
  textBold: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 30,
    textAlign: 'center',
  },
  title: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: web ? 26 : wp(7),
    fontWeight: '900',
    textAlign: 'center',
    top: web ? 0 : wp(48),
  },
  titleContainer: web
    ? {
        flex: 1,
        paddingHorizontal: wp(15),
        position: 'relative',
        top: 20,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
      }
    : { paddingHorizontal: 20 },
  webLogoDark: {
    zIndex: 10000,
    marginTop: 50,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
