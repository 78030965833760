import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import type { LocalAvatar } from '../../types';
import { BottomSheet } from '../actionList/BottomSheet';
import Avatar from '../avatar';

import { getAvatars } from '@/services';
import { hp, wp } from '@/theme';
import { blue2 } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';
import { web } from '@/utilities/platform';

const avatars = getAvatars();

interface Props {
  avatar: string;
  onPressAvatar: (avatar: LocalAvatar) => void;
  showPicker: boolean;
  setShowPicker: (show: boolean) => void;
}
const AvatarPicker = ({
  avatar,
  onPressAvatar,
  showPicker,
  setShowPicker,
}: Props) => {
  const { translate } = useTranslations();
  const { avatarSize } = getAvatarConfig();

  return (
    <BottomSheet darkMode open={showPicker} pinToBottom setOpen={setShowPicker}>
      <View style={styles.sheetAvatarsContainer}>
        <Text style={styles.chooseAvatarLabel}>
          {translate('choose_avatar_label')}
        </Text>
        <ScrollView>
          <View style={styles.sheetAvatarsRow}>
            {avatars.map((av: any, index: number) => {
              return (
                <TouchableOpacity
                  key={index.toString()}
                  onPress={() => onPressAvatar(av)}
                  style={
                    av.name === avatar
                      ? styles.sheetAvatarContainerSelected
                      : styles.sheetAvatarContainer
                  }>
                  <Avatar
                    imageKey={av?.name}
                    key={index.toString()}
                    size={web ? avatarSize : avatarSize + 12}
                    displayName=""
                  />
                </TouchableOpacity>
              );
            })}
          </View>
        </ScrollView>
      </View>
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 60,
    height: (wp(100) - 60) / 6,
    position: 'absolute',
    width: (wp(100) - 60) / 6,
  },
  avatarSelected: {
    borderRadius: 60,
    height: (wp(100) - 60) / 6.8,
    position: 'absolute',
    width: (wp(100) - 60) / 6.8,
  },
  chooseAvatarLabel: {
    color: Greys.shade200,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 25,
  },
  sheetAvatarContainer: {
    alignItems: 'center',
    height: web ? 65 : (wp(100) - 60) / 5.5,
    justifyContent: 'center',
    marginBottom: 10,
    width: web ? 65 : (wp(100) - 60) / 5.5,
  },
  sheetAvatarContainerSelected: {
    alignItems: 'center',
    borderColor: blue2,
    borderRadius: 60,
    borderWidth: 2,
    height: web ? 65 : (wp(100) - 60) / 5.5,
    justifyContent: 'center',
    marginBottom: 10,
    width: web ? 65 : (wp(100) - 60) / 5.5,
  },
  sheetAvatarsContainer: {
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 20,
    height: web ? '100%' : hp(52),
  },
  sheetAvatarsRow: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: 40,
  },
});

export default AvatarPicker;
