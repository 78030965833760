import type { PersistStorage, StorageValue } from 'zustand/middleware';
type JsonStorageOptions = {
  reviver?: (key: string, value: unknown) => unknown;
  replacer?: (key: string, value: unknown) => unknown;
};

// Extend the JsonStorageOptions with a debounceTime property
interface DebouncedJsonStorageOptions extends JsonStorageOptions {
  /**
   * @property debounceTime - The time to wait before setting the item
   */
  debounceTime: number;
  /**
   * @property immediately - Whether to set the item immediately
   * @default false
   */
  immediately?: boolean;
}

export function createDebouncedStorage(
  storageApi: PersistStorage<unknown>,
  options: DebouncedJsonStorageOptions,
): PersistStorage<unknown> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let pendingValue: any | null = null;

  const { debounceTime } = options;

  async function debouncedSetItem(
    name: string,
    value: StorageValue<unknown>,
  ): Promise<void> {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    pendingValue = value;

    timeoutId = setTimeout(async () => {
      if (pendingValue !== null) {
        await storageApi.setItem(name, pendingValue);
        pendingValue = null;
      }
    }, debounceTime);
  }

  const debouncedStorageApi: PersistStorage<unknown> = {
    ...storageApi,
    setItem: debouncedSetItem,
  };

  return debouncedStorageApi;
}
