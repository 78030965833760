import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgTag = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="m1.114 20.356 7.198-3.995L26.111 6.48a8.998 8.998 0 0 1 8.866.074L95.5 41.497a8.998 8.998 0 0 1 3.293 12.291L77.799 90.151a8.998 8.998 0 0 1-12.291 3.293L4.985 58.502a9 9 0 0 1-4.498-7.641L.005 22.158a1.859 1.859 0 0 1-.004-.199L0 21.883h.006a1.813 1.813 0 0 1 1.108-1.527m7.958 5.874 21.406-11.884 60.523 34.943-20.994 36.363L9.484 50.71zm22.081 12.749a6.002 6.002 0 0 1-8.194 2.196 6.002 6.002 0 0 1-2.196-8.194c1.656-2.867 5.327-3.851 8.194-2.196s3.851 5.327 2.196 8.194" />
  </Svg>
);
export default SvgTag;
