import { useCallback, useRef, useState } from 'react';
import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { useShallow } from 'zustand/react/shallow';

import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import { useStickyDateStore } from '@/domain/conversation/state/useStickyDateStore';
import { logger } from '@/services/logger/logger';
import { debounceFunction } from '@/utilities/helpers/debounceFunction';

export const useChatScroll = (conversationId: string) => {
  const setConversationStickyDate = useStickyDateStore(
    store => store.setConversationStickyDate,
  );
  const [showStickyDate, setShowStickyDate] = useState(false);

  const storeApi = useActiveConversationStoreApi();

  const onScrollEnd = useCallback(() => {
    setShowStickyDate(false);
  }, []);

  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (e.nativeEvent.contentOffset.y > 0) {
        setShowStickyDate(true);
      }
    },
    [],
  );

  const onViewableItemsChanged = useRef(({ viewableItems }: any) => {
    const { selectedMessage, clearSelectedMessage } = storeApi.getState();

    // NOTE: this doesn't work on web
    // TODO: this is where we can mark messages as read as they scroll into view
    try {
      if (viewableItems && viewableItems.length) {
        if (selectedMessage) {
          const viewableMessageIndex = viewableItems.findIndex(
            (item: any) => item.item.id === selectedMessage.id,
          );
          if (viewableMessageIndex > 0) {
            debounceFunction(() => clearSelectedMessage(), 500);
          }
        }
        const lastItem = viewableItems.pop();
        if (lastItem?.item && lastItem?.item?.createdAt) {
          setConversationStickyDate(conversationId, lastItem.item.createdAt);
        }
      }
    } catch (error) {
      logger.warn('useStickyDate:onViewableItemsChangedError', error);
    }
  }).current;

  return {
    handleScroll,
    onScrollEnd,
    onViewableItemsChanged,
    showStickyDate,
  };
};
