import { getUrl } from 'aws-amplify/storage';
import { Image } from 'expo-image';
import * as VideoThumbnails from 'expo-video-thumbnails';
import type { VideoThumbnailsOptions } from 'expo-video-thumbnails';
import { Platform } from 'react-native';
import RNBlobUtil from 'react-native-blob-util';

import { logger } from '@/services/logger/logger';
import { openFileWithChooserAndroid } from '@/services/modules/RCFileOpener';
import { web } from '@/utilities/platform';
import getThumbnailAsync from '@/utilities/web/getThumbnailAsync';

/**
 * Fetches an image URL from Amplify Storage using the provided image key.
 *
 * @param imageKey The key of the image to fetch from storage.
 * @returns A promise that resolves to the image URL.
 */
const fetchPresignedUrlFromBackend = async (imageKey: string) => {
  const img = await getUrl({ key: imageKey });
  return img?.url.toJSON();
};

/**
 * Returns the base64 representation of the image at the specified local file path by image key.
 *
 * @param imageKey The key of the image to fetch from file system and convert to base64.
 * @returns The base64 representation of the image at the specified local file path.
 */
const getBase64FromLocalFilePathByImageKey = async (imageKey: string) => {
  const localPath = await getLocalFileSystemPathByImageKey(imageKey);
  if (!localPath) {
    return null;
  }
  return await RNBlobUtil.fs.readFile(localPath, 'base64');
};

/**
 * Returns the local file system path of the image at the specified image key.
 *
 * @param imageKey The key of the image to fetch from file system.
 * @returns The local file system path of the image at the specified image key.
 */
const getLocalFileSystemPathByImageKey = async (imageKey: string) => {
  return await Image.getCachePathAsync(imageKey);
};
/**
 * Open a document, given local file path.
 */
const openDocument = async (localFilePath: string, mimeType: string) => {
  try {
    if (Platform.OS === 'ios') {
      await RNBlobUtil.ios.openDocument(localFilePath);
      return true;
    } else if (Platform.OS === 'android') {
      const res = await openFileWithChooserAndroid(localFilePath, mimeType);
      return !!res;
    } else {
      // TODO: Implement for other platforms (web, desktop etc)
      return false;
    }
    // return false;
  } catch (err) {
    // Reason: The error code is not documented in the library
    // @ts-expect-error
    if (err?.code === 'EINVAL') {
      RNBlobUtil.ios.presentOpenInMenu(localFilePath);
      return true;
    } else if (Platform.OS === 'android') {
      // Navigate to the file in the file system
      logger.error(
        'DocumentService::openDocumentError: Failed to open document on Android',
      );
    }
    return false;
  }
};

const getVideoThumbnail = async (
  videoPath: string,
  options: VideoThumbnailsOptions,
) => {
  if (web) {
    return (await getThumbnailAsync(
      videoPath,
      options,
    )) as VideoThumbnails.VideoThumbnailsResult;
  }

  return await VideoThumbnails.getThumbnailAsync(videoPath, options);
};

const DocumentService = {
  fetchPresignedUrlFromBackend,
  getBase64FromLocalFilePathByImageKey,
  getLocalFileSystemPathByImageKey,
  openDocument,
  getVideoThumbnail,
};

export default DocumentService;
