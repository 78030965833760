import { FlashList, type ListRenderItem } from '@shopify/flash-list';
import { Image } from 'expo-image';
import React, { memo, useCallback, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';

import { BottomSheet } from '../actionList/BottomSheet';

import SearchIcon from '@/assets/svg/search.svg';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Greys } from '@/ui/common/colors';
import { Fonts } from '@/ui/common/styles';
import { type Country, countries } from '@/utilities/countries';

interface Props {
  onCountrySelected: (country: Country) => void;
  open: boolean;
  selectedCountry: Country;
  setOpen: (open: boolean) => void;
}

const PAGE_SIZE = 25;
const INITIAL_COUNTRIES = countries.slice(0, PAGE_SIZE);

const CountryPicker = ({
  open,
  onCountrySelected,
  selectedCountry,
  setOpen,
}: Props) => {
  const [countriesList, setCountriesList] =
    useState<Country[]>(INITIAL_COUNTRIES);

  const [searchValue, setSearchValue] = useState<string>('');
  const { translate } = useTranslations();

  const setSelectedCountry = useCallback(
    (country: Country) => {
      onCountrySelected(country);
      setOpen(false);
    },
    [onCountrySelected, setOpen],
  );

  const searchCountry = (value: string) => {
    setSearchValue(value);
    if (value) {
      const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(value.toLowerCase()),
      );
      setCountriesList(filteredCountries);
    } else {
      setCountriesList(countries);
    }
  };

  const handleEndReached = useCallback(() => {
    if (searchValue) {
      return null;
    }
    // Fetch more countries, if any, and add it to the existing countries list
    // Slice the next page of countries
    setCountriesList(prevCountries => {
      const nextPage = prevCountries.length;
      if (nextPage >= countries.length) {
        return prevCountries;
      }
      const nextCountries = countries.slice(nextPage, nextPage + PAGE_SIZE);
      return [...prevCountries, ...nextCountries];
    });
  }, [searchValue]);

  const handleRenderItem: ListRenderItem<Country> = useCallback(
    ({ item }) => {
      const isSelected = selectedCountry?.code === item.code;

      return (
        <Pressable
          onPress={() => {
            setSelectedCountry(item);
          }}
          style={styles.countrySheetRow}>
          <View
            style={{
              ...styles.flagContainer,
              borderColor: isSelected ? Colors.primaryLight : Colors.neutral0,
            }}>
            <Image
              // Use a flag CDN to get the flag image
              source={{
                uri:
                  'https://hatscripts.github.io/circle-flags/flags/' +
                  item.code.toLowerCase() +
                  '.svg',
              }}
              style={styles.flag}
            />
          </View>
          <View style={styles.textOverflowContainer}>
            <AppText
              type={isSelected ? 'primary700' : 'primary400'}
              size={14}
              numberOfLines={1}
              ellipsizeMode="tail">
              {item.name}
            </AppText>
          </View>
          <AppText type="primary700" size={14}>
            {item.dial_code}
          </AppText>
        </Pressable>
      );
    },
    [selectedCountry?.code, setSelectedCountry],
  );

  return (
    <BottomSheet open={open} pinToBottom setOpen={setOpen}>
      <View style={styles.contentContainer}>
        <AppText
          type="primary700"
          size={14}
          textAlign="center"
          color={Colors.neutral70}>
          {translate('select_your_country_code_text')}
        </AppText>

        <View style={styles.inputContainer}>
          <SearchIcon style={styles.searchIcon} />
          <TextInput
            onChangeText={searchCountry}
            placeholder={translate('search_countries_label')}
            style={styles.textInput}
            textAlignVertical="center"
            value={searchValue}
            placeholderTextColor={Colors.neutral50}
          />
        </View>
        <FlashList
          ItemSeparatorComponent={ItemSeperatorComponent}
          ListFooterComponent={ListFooterComponent}
          data={countriesList}
          estimatedItemSize={countries.length}
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          renderItem={handleRenderItem}
          renderScrollComponent={ScrollView}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </BottomSheet>
  );
};

const ItemSeperatorComponent = memo(() => (
  <View style={styles.sheetItemDivider} />
));

const ListFooterComponent = memo(() => <View style={styles.footer} />);

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Greys.shade0,
    borderTopEndRadius: 12,
    borderTopStartRadius: 12,
    paddingHorizontal: 16,
    paddingTop: 20,
    maxWidth: 400,
    height: '100%',
  },
  countryName: {
    color: Greys.shade600,
    marginLeft: 10,
    textAlign: 'left',
  },
  countryNameSelected: {
    color: Greys.shade600,
    marginLeft: 10,
    textAlign: 'left',
    fontFamily: Fonts.primary700,
  },
  countrySheetRow: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    gap: 16,
  },
  flag: {
    borderRadius: 100,
    height: 24,
    width: 24,
  },
  flagAndName: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  flagContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    borderWidth: 2,
    height: 32,
    width: 32,
  },
  footer: {
    height: 40,
  },
  inputContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    borderColor: Greys.shade400,
    borderRadius: 18,
    borderWidth: 1,
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 20,
    width: '100%',
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  paddingLeft10: {
    paddingLeft: 10,
  },
  searchIcon: {
    height: 20,
    marginLeft: 10,
    width: 20,
  },
  sheetItemDivider: {
    backgroundColor: Colors.neutral10,
    height: 1,
    marginVertical: 8,
  },
  sheetTitle: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    textAlign: 'center',
  },
  textInput: {
    flex: 1,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    paddingLeft: 10,
    paddingVertical: 8,
  },
  whiteText: {
    color: Greys.shade600,
  },
  textOverflowContainer: {
    width: 0,
    flexGrow: 1,
  },
});

export default CountryPicker;
