import { uploadData } from 'aws-amplify/storage';
import { debounce, uniqueId } from 'lodash';
import { useCallback, useState } from 'react';
import { launchImageLibrary } from 'react-native-image-picker';
import { useMMKVObject } from 'react-native-mmkv';

import type { CustomBackground } from './ImagePicker';

import { constants } from '@/constants';
import { logger } from '@/services/logger/logger';
import { uriToBlob } from '@/utilities/lib/uriToBlob';
import { web } from '@/utilities/platform';

export const useCustomBackground = (chatUnitId: string, isOwner = false) => {
  const [loading, setLoading] = useState(false);
  const [customBg, setCustomBg] =
    useMMKVObject<CustomBackground>('customBackground');
  const [progress, setProgress] = useState(0);

  // Reason:-
  // The amplify onProgress fires multiple times in a short period of time.
  // We do not want to recreate the function on every render
  // and we do not want to add it as a dependency to the hook.
  // We also do not want updates every millisecond.
  // Currently, the linter complains about having an unknown inline function
  // and not being able to detect dependencies. Fixing this error is not worth it
  // and out of scope.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleProgress = useCallback(
    debounce(setProgress, constants.progressDebounceTime),
    [],
  );

  const deletePreviouslyAppliedImage = useCallback(() => {
    if (!isOwner) {
      return;
    }
    setCustomBg({
      ...customBg,
      [chatUnitId]: {
        localFilePath: '',
        imageKey: '',
      },
    });
  }, [chatUnitId, customBg, isOwner, setCustomBg]);

  const pickAndApplyCustomBackground = useCallback(async () => {
    handleProgress(0);
    if (!isOwner) {
      return;
    }
    try {
      // Pick from image gallery
      const pickerResponse = await launchImageLibrary({
        includeBase64: false,
        mediaType: 'photo',
      });

      const assetLocalUri = pickerResponse?.assets?.[0]?.uri ?? '';

      if (
        !assetLocalUri ||
        !pickerResponse ||
        pickerResponse.didCancel ||
        pickerResponse.errorCode ||
        pickerResponse.errorMessage
      ) {
        // No image selected
        return '';
      }
      setLoading(true);
      // Set the local file path to the chatUnitId
      // in local device cache
      // We do not have the image key yet
      setCustomBg({
        ...customBg,
        [chatUnitId]: {
          localFilePath: assetLocalUri,
          imageKey: '',
        },
      });

      // Upload to S3
      // ...
      const assetFileName =
        pickerResponse?.assets?.[0]?.fileName ??
        (pickerResponse?.assets?.[0]?.uri?.split('/').pop() || '');
      const fileExtension = web
        ? assetLocalUri.split(':')[1].split(';')[0]
        : assetLocalUri.split('.').pop() || '';
      const mimeType = `image/${fileExtension}`;
      const sizeInBytes = pickerResponse?.assets?.[0]?.fileSize ?? 0;

      const uuid = web
        ? `customBg_${uniqueId('id')}`
        : `customBg_${uniqueId('id')}_${assetFileName}`;
      const storageKey = `chatunit/${chatUnitId}/background/${uuid}`;

      const blob = await uriToBlob(assetLocalUri);

      //Upload the image to S3
      const { key } = await uploadData({
        data: blob,
        key: storageKey,

        options: {
          contentType: mimeType,
          accessLevel: 'guest',
          // Folder in S3
          onProgress(event) {
            const totalBytes = event?.totalBytes || sizeInBytes;
            // One liner
            const progressValue = Math.min(
              100,
              Math.ceil((event.transferredBytes / totalBytes) * 100),
            );

            handleProgress(progressValue);
          },
        },
      }).result;
      // Set the image key to the chatUnitId
      // Calling this again to update the image key
      setCustomBg({
        ...customBg,
        [chatUnitId]: {
          localFilePath: assetLocalUri,
          imageKey: key,
        },
      });

      setLoading(false);

      return key;
    } catch (e) {
      logger.error('pickAndApplyCustomBackground:: ', e);
      return '';
    }
  }, [chatUnitId, customBg, handleProgress, isOwner, setCustomBg]);

  return {
    customBg: customBg?.[chatUnitId],
    pickAndApplyCustomBackground,
    progress,
    loading,
    deletePreviouslyAppliedImage,
  };
};
