import Section from '@/components/section/Section';
import type { PostLink as Props } from '@/domain/cms/graphql/generated/graphql';
import { AppText } from '@/ui/app';
import { Image } from 'expo-image';
import { Linking } from 'react-native';

export default function PostLink(props: Props) {
  return (
    <Section
      row
      aic
      gap={15}
      onPress={() => Linking.openURL(props.destination || '')}>
      <Image
        source={{ uri: props.image?.url }}
        style={{ width: 32, height: 32 }}
      />
      <AppText size={14} type="primary600">
        {props.title}
      </AppText>
    </Section>
  );
}
