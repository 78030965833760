import { useCallback, useEffect, useRef } from 'react';
import type {
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';

export const MIN_INPUT_HEIGHT = 48;
export const LABEL_OVERFLOW = 6;

export const Input_FONT_SIZE = 16;
export const Input_ACTIVE_FONT_SIZE = 12;

export const LABEL_LINE_HEIGHT = 16;
export const ACTIVE_LABEL_TOP = -16 / 2;

export const DEFAULT_DEBOUNCE_TIME = 350;

export enum Validation {
  NORMAL = 0,
  ERROR = 1,
  SUCCESS = 2,
}

interface UseInputArgs {
  onBlur?(e: NativeSyntheticEvent<TextInputFocusEventData>): void;
  onFocus?(e: NativeSyntheticEvent<TextInputFocusEventData>): void;
  validation: Validation;
  value: string | null;
}

export function useInput({ onBlur, onFocus, validation, value }: UseInputArgs) {
  const isFocused = useRef(false);
  const isActive = useRef<boolean>(true);
  const validationValue = useRef<Validation>(validation);

  useEffect(() => {
    validationValue.current = validation;
  }, [validation]);

  const setActive = useCallback((activeNewValue: boolean) => {
    if (activeNewValue !== isActive.current) {
      isActive.current = activeNewValue;
    }
  }, []);

  useEffect(() => {
    if (value) {
      setActive(isFocused.current || true || value !== '');
    }
  }, [setActive, value]);

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setActive(true);
      isFocused.current = true;
      onFocus?.(e);
    },
    [onFocus, setActive],
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      isFocused.current = false;
      if (!value) {
        setActive(false);
      }
      onBlur?.(e);
    },
    [onBlur, setActive, value],
  );

  return { handleBlur, handleFocus, isActive, validationValue };
}
