import { useEffect } from 'react';
import type { Subscription } from 'rxjs';
import { useShallow } from 'zustand/react/shallow';

import { useActiveConversationStore } from '../state/useActiveConversationStore';
import { useConversationStore } from '../state/useConversationStore';
import { useMessageStore } from '../state/useMessageStore';

import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import client from '@/services/API/client';
import { getDatastoreObservable } from '@/services/datastore/subscriptions/subscriptionManager';
import { factory } from '@/services/factory/factory';
import { onDeleteMessageV2 } from '@/services/graphql/subscriptions';
import { logger } from '@/services/logger/logger';

let initialised = false;

export const useConversationEventListener = () => {
  const { addMessages, deleteMessage } = useMessageStore(
    useShallow(state => {
      return {
        addMessages: state.addMessages,
        deleteMessage: state.deleteMessage,
      };
    }),
  );

  const upsertConversations = useConversationStore(
    useShallow(state => state.upsertConversations),
  );

  const addConversationToActiveConversations = useActiveConversationStore(
    useShallow(state => state.addConversationToActiveConversations),
  );

  const datastoreReady = useLoadingStore(state => state.datastoreReady);

  useEffect(() => {
    if (initialised) {
      return;
    }

    let conversationLoadedSubscription: Subscription | undefined;
    let messageLoadedSubscription: Subscription | undefined;
    let messageDeletedSubscription: Subscription;

    if (datastoreReady) {
      conversationLoadedSubscription = getDatastoreObservable(
        'Conversation',
      ).subscribe(subscriptionMessage => {
        try {
          const conversation = factory.conversation(
            subscriptionMessage.element,
          );
          upsertConversations([
            {
              chatUnitId: conversation.chatUnitId,
              conversations: [conversation],
            },
          ]);
          addConversationToActiveConversations(
            conversation.chatUnitId,
            conversation.conversationId,
          );
        } catch (e) {
          logger.error('subscribeConversationLoadedError', e);
        }
      });

      messageLoadedSubscription = getDatastoreObservable('Message').subscribe(
        subscriptionMessage => {
          try {
            if (subscriptionMessage?.element?._deleted) {
              return;
            }
            const message = factory.message(subscriptionMessage.element);
            addMessages({ messages: [message] });
          } catch (e) {
            logger.error('subscribeMessageLoadedError', e);
          }
        },
      );

      messageDeletedSubscription = client
        .graphql({ query: onDeleteMessageV2 })
        .subscribe({
          next: data => {
            try {
              const { messageId, chatUnitId, conversationId } =
                data.data.onDeleteMessageV2;
              deleteMessage(chatUnitId, conversationId, messageId);
            } catch (error) {
              logger.error('onDeleteMessageV2Error', error);
            }
          },
          error: error => logger.error('onDeleteMessageV2Error', error),
        });
    }

    initialised = true;
    return () => {
      conversationLoadedSubscription?.unsubscribe();
      messageLoadedSubscription?.unsubscribe();
      messageDeletedSubscription?.unsubscribe();
      initialised = false;
    };
  }, [
    addMessages,
    upsertConversations,
    deleteMessage,
    datastoreReady,
    addConversationToActiveConversations,
  ]);
};
