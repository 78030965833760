import CountryPicker from '@/components/country-picker';
import { constants } from '@/constants';
import { Colors } from '@/domain/theme/Colors';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import type { Country } from '@/utilities/countries';
import { android, web } from '@/utilities/platform';
import { Image } from 'expo-image';
import parsePhoneNumberFromString, {
  AsYouType,
  type CountryCode,
} from 'libphonenumber-js';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Pressable, StyleSheet, TextInput, View } from 'react-native';

type Props = {
  setPhoneNumber: (phoneNumber: string) => void;
  defaultSelectedCountry?: Country;
  onSubmit?: () => void;
  borderColor?: Colors;
  type?: 'normal' | 'compact';
};

/**
 * A component that allows the user to input a phone number with a country code
 * @param setPhoneNumber A function that will be called with the full international phone number as the user types
 * @param defaultSelectedCountry The default country to show in the input
 */
const OnboardingPhoneNumberInput = ({
  setPhoneNumber,
  defaultSelectedCountry = constants.defaultCountry,
  onSubmit,
  borderColor = Colors.neutral20,
  type = 'normal',
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState(
    defaultSelectedCountry,
  );
  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const [displayValue, setDisplayValue] = useState('');

  const handleCountryPress = useCallback(() => {
    setShowCountryPicker(true);
  }, []);

  const updatePhoneNumber = useCallback(
    (countryCode: CountryCode, inputValue: string) => {
      const asYouType = new AsYouType(countryCode);
      const formattedText = asYouType.input(inputValue);
      setDisplayValue(formattedText);

      const parsedNumObj = parsePhoneNumberFromString(
        asYouType.getNumber()?.number || '',
        countryCode,
      );
      if (parsedNumObj) {
        // Remove all spaces from the formatted international number, resulting in +61412341234 format
        setPhoneNumber(parsedNumObj.formatInternational().replace(/\s+/g, ''));
        // Set the display value to the national (local 0412 341 234) format
        setDisplayValue(parsedNumObj.formatNational());
      } else {
        setPhoneNumber(formattedText);
      }
    },
    [setPhoneNumber],
  );

  const handleCountryChange = useCallback(
    (country: Country) => {
      setSelectedCountry(country);
      updatePhoneNumber(country.code as CountryCode, displayValue);
    },
    [displayValue, updatePhoneNumber],
  );

  const handleChange = useCallback(
    (text: string) => {
      updatePhoneNumber(selectedCountry.code as CountryCode, text);
    },
    [selectedCountry?.code, updatePhoneNumber],
  );

  const containerStyles = useMemo(() => {
    return {
      ...(type === 'normal'
        ? styles.inputContainer
        : styles.compactInputContainer),
      borderColor,
    };
  }, [borderColor, type]);

  return (
    <>
      <View style={containerStyles}>
        <Pressable onPress={handleCountryPress} style={styles.flagRow}>
          <Image
            // Use a flag CDN to get the flag image
            source={{
              uri:
                'https://hatscripts.github.io/circle-flags/flags/' +
                selectedCountry.code.toLowerCase() +
                '.svg',
            }}
            style={styles.flag}
            contentFit="contain"
          />
          <AppText color={Colors.neutral70} size={17} type="primary700">
            {selectedCountry.dial_code}
          </AppText>
          <Spacer
            height={type === 'compact' ? 40 : 50}
            width={1}
            bg={borderColor}
          />
        </Pressable>
        <TextInput
          value={displayValue}
          onChangeText={handleChange}
          style={type === 'compact' ? styles.compactInput : styles.input}
          clearButtonMode="never"
          enterKeyHint="done"
          inputMode="tel"
          onSubmitEditing={onSubmit}
          numberOfLines={1}
        />
      </View>
      <CountryPicker
        selectedCountry={selectedCountry}
        onCountrySelected={handleCountryChange}
        open={showCountryPicker}
        setOpen={setShowCountryPicker}
      />
    </>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: 'transparent',
    borderColor: Colors.neutral20,
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  compactInputContainer: {
    backgroundColor: 'transparent',
    borderColor: Colors.neutral20,
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 5,
    maxHeight: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flagRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 10,
  },
  flag: {
    alignItems: 'center',
    borderRadius: 60,
    height: 30,
    justifyContent: 'center',
    overflow: 'hidden',
    width: 30,
  },
  input: {
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    fontSize: 17,
    width: 0,
    flexGrow: 1,
    paddingLeft: 10,
    ...(web ? { outlineStyle: 'none' } : {}),
  },
  compactInput: {
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    fontSize: 17,
    width: 0,
    flexGrow: 1,
    paddingLeft: 10,
    height: 40,
    transform: [{ translateY: android ? 2.5 : 0 }],
    ...(web ? { outlineStyle: 'none' } : {}),
  },
});

export default memo(OnboardingPhoneNumberInput);
