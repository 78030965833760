import type React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { Greys } from '@/ui/common/colors';

type WebArrowNavigationProps = {
  onNavigate: (direction: 'left' | 'right') => void;
};

const WebArrowNavigation: React.FC<WebArrowNavigationProps> = ({
  onNavigate,
}) => {
  const leftOpacity = useSharedValue(0);
  const rightOpacity = useSharedValue(0);

  const animatedStyleLeft = useAnimatedStyle(() => ({
    opacity: leftOpacity.value,
  }));

  const animatedStyleRight = useAnimatedStyle(() => ({
    opacity: rightOpacity.value,
  }));

  const handleHover = (direction: 'left' | 'right', hovering: boolean) => {
    const opacity = direction === 'left' ? leftOpacity : rightOpacity;
    opacity.value = withTiming(hovering ? 1 : 0, { duration: 300 });
  };

  return (
    <>
      <View style={[styles.hoverArea, styles.leftHoverArea]}>
        <Pressable
          style={styles.pressableArea}
          onHoverIn={() => handleHover('left', true)}
          onHoverOut={() => handleHover('left', false)}
          onPress={() => onNavigate('left')}>
          <Animated.View style={[styles.arrowButton, animatedStyleLeft]}>
            <View style={[styles.arrow, styles.leftArrow]} />
          </Animated.View>
        </Pressable>
      </View>

      <View style={[styles.hoverArea, styles.rightHoverArea]}>
        <Pressable
          style={styles.pressableArea}
          onHoverIn={() => handleHover('right', true)}
          onHoverOut={() => handleHover('right', false)}
          onPress={() => onNavigate('right')}>
          <Animated.View style={[styles.arrowButton, animatedStyleRight]}>
            <View style={[styles.arrow, styles.rightArrow]} />
          </Animated.View>
        </Pressable>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  hoverArea: {
    position: 'absolute',
    width: 75, // Width of the hover area
    pointerEvents: 'box-none',
    top: '50%',
  },
  leftHoverArea: {
    left: 0,
  },
  rightHoverArea: {
    right: 0,
  },
  pressableArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowButton: {
    padding: 10,
    // backgroundColor: 'rgba(200, 200, 200, 0.5)', // Slightly grey background
  },
  arrow: {
    width: 20,
    height: 20,
    borderTopWidth: 3,
    borderRightWidth: 3,
    borderColor: Greys.shade600,
  },
  leftArrow: {
    transform: [{ rotate: '-135deg' }],
  },
  rightArrow: {
    transform: [{ rotate: '45deg' }],
  },
});

export default WebArrowNavigation;
