import { Appearance, type ColorSchemeName } from 'react-native';

import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';

type Current = () => Promise<ColorSchemeName>;

export const current: Current = async () => {
  const value = await asyncStorageMethods.getItem('theme');

  if (value == null) {
    return Appearance.getColorScheme();
  }

  return value as ColorSchemeName;
};
