/**
 * Brand colours are designed and managed in Figma
 * https://www.figma.com/file/03ag61F68Ad8W3h6gkiAA6/8seats-%E2%80%93-App-Product-Design?type=design&node-id=10187-145255&mode=design&t=RVgyAKWU3uMwhTBt-0
 */

export enum Colors {
  // primary colours
  primaryLight = '#07818C',
  primaryDark = '#17D3E5',

  // infinity colours
  sand = '#F0AA6A',
  desert = '#DE6B3A',
  outback = '#CB3E23',
  ocean = '#0A314A',
  water = '#1E6E80',
  reef = '#359CA6',
  river = '#7FB8A7',
  beach = '#EAD1AC',

  // system colours light
  peppermint = '#008542',
  pumpkin = '#BF5600',
  rasberry = '#EB0000',

  // system colours dark
  mint = '#59FFAC',
  tangerine = '#FFAF50',
  melon = '#FF5050',

  // neutrals
  neutral0 = '#FFFFFF',
  neutral05 = '#F5F6F6',
  neutral10 = '#EBEDED',
  neutral20 = '#D4D6D6',
  neutral30 = '#B9BDBD',
  neutral40 = '#999E9E',
  neutral50 = '#7C7F80',
  neutral60 = '#5D6161',
  neutral70 = '#3F4242',
  neutral80 = '#262929',
  neutral90 = '#111212',
  neutral100 = '#000000',
}
