import React, { useCallback, useMemo } from 'react';
import { Keyboard, Pressable, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  type SharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { AnimatedDot } from './AnimatedDot';
import { styles } from './styles';

import DMBackIcon from '@/assets/dm_back_icon.svg';
import RoomBackIcon from '@/assets/room_back_arrow.svg';
import SettingButtonIcon from '@/assets/settings_button_icon.svg';
import AddFriendIcon from '@/assets/svg/add-friend-icon.svg';
import TableBackIcon from '@/assets/table_back_icon.svg';
import { constants } from '@/constants';
import {
  useActiveConversationStore,
  useActiveConversationStoreApi,
} from '@/domain/conversation/state/useActiveConversationStore';
import useRemainingSeatsLimit from '@/domain/invites/hooks/useRemainingSeats';
import { useKeyboardTransitions } from '@/hooks/useKeyboardTransition';
import { ChatUnitType } from '@/models';
import type { ChatUnit } from '@/services/types';
import { AppText } from '@/ui/app/elements';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const MAX_NUMBER_OF_DOTS = constants.maxDots;

interface TableControlsProps {
  goToSettings?: () => void;
  isWithoutAnimation?: boolean;
  offset: SharedValue<number>;
  onPress: () => void;
  opacity: SharedValue<number>;
  title: string;
  type?: ChatUnitType;
  chatUnit: ChatUnit;
  toggleInviteActions: () => void; // Ensure this is required
}

const DotBar = ({
  goToSettings,
  offset,
  onPress,
  opacity,
  title,
  type,
  chatUnit,
  toggleInviteActions, // Destructure the new prop
}: TableControlsProps) => {
  const { top } = useSafeAreaInsets();
  const { height } = useKeyboardTransitions();
  const limit = useRemainingSeatsLimit(chatUnit);
  const numberOfConvos = useActiveConversationStore(
    state => state.activeConversationIds.length,
  );
  const storeApi = useActiveConversationStoreApi();

  const chatUsersLength = useActiveConversationStore(
    useShallow(state => state.getActiveChatUsers().length),
  );

  const containerStyle = useAnimatedStyle(() => ({
    opacity: height.value === 0 ? 1 : 0,
  }));

  const dotContainerStyles = useMemo(() => {
    return [styles.dotsContainer, { opacity }, containerStyle];
  }, [containerStyle, opacity]);

  const handleDotPress = useCallback(
    (index: number) => {
      const { activeConversationIds, getPagerRef, setWebActiveConversationId } =
        storeApi.getState();

      // Only implemented on web as mobile's dots are very small
      if (web) {
        const pagerRef = getPagerRef();
        const conversationId = activeConversationIds[index];

        if (pagerRef?.current) {
          pagerRef?.current?.setPageWithoutAnimation(index);
        }

        setWebActiveConversationId(conversationId); // Ensure the web conversation ID is correctly updated
        offset.value = index; // Update the offset value
      }
    },
    [offset],
  );

  const animatedDots = useMemo(() => {
    const dots = new Array(Math.min(MAX_NUMBER_OF_DOTS, numberOfConvos)).fill(
      0,
    );
    return dots.map((_, index) => (
      <AnimatedDot
        index={index}
        key={index}
        scrollValue={offset}
        total={numberOfConvos}
        onPress={handleDotPress} // Pass handleDotPress to AnimatedDot
      />
    ));
  }, [numberOfConvos, offset]);

  /* On mobile if the number of users are 8,9 or 10 our first row is filled with heads and we want to avoid add button moving on the second row
     In that case add button is displayed next to the settings icon */
  const shouldDisplayAddButtonNextToSettings =
    !web && [8, 9, 10].includes(chatUsersLength) && type === ChatUnitType.TABLE;

  const _onAddPress = useCallback(() => {
    Keyboard.dismiss();
    toggleInviteActions();
  }, [toggleInviteActions]);

  return (
    <View style={[styles.container, { top }]}>
      <View style={styles.backContainer}>
        <Pressable onPress={onPress}>
          {type === ChatUnitType.TABLE ? (
            <TableBackIcon />
          ) : type === ChatUnitType.ROOM ? (
            <RoomBackIcon />
          ) : (
            <DMBackIcon />
          )}
        </Pressable>
      </View>

      {type === ChatUnitType.TABLE || type === ChatUnitType.QUICK_CHAT ? (
        <View style={styles.textContainer}>
          <AppText
            color={Greys.shade0}
            type="primary700"
            numberOfLines={2}
            ellipsizeMode="tail">
            {title}
          </AppText>
          <Animated.View style={dotContainerStyles}>
            {animatedDots}
          </Animated.View>
        </View>
      ) : (
        <View style={styles.textContainer}>
          <AppText
            color={Greys.shade0}
            type="primary700"
            numberOfLines={2}
            ellipsizeMode="tail">
            {title}
          </AppText>
        </View>
      )}

      <View style={styles.settingsContainer}>
        {limit > 0 && shouldDisplayAddButtonNextToSettings && (
          <Pressable onPress={_onAddPress} style={styles.addButton}>
            <AddFriendIcon width={30} height={30} />
          </Pressable>
        )}
        {goToSettings && (
          <Pressable onPress={goToSettings}>
            <SettingButtonIcon />
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default React.memo(DotBar);
