import { Image, type ImageProps } from 'expo-image';
import React from 'react';
import { StyleSheet, View } from 'react-native';

type CachedImageProps = ImageProps & {
  resizeMode?: ImageProps['contentFit'];
  style: ImageProps['style'];
};

const CachedImage = ({
  resizeMode = 'contain',
  source,
  style,
  ...props
}: CachedImageProps) => {
  return (
    <View style={[style, styles.imageContainer]}>
      <Image
        contentFit={resizeMode}
        source={source}
        style={styles.image}
        {...props}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    flex: 1,
  },
  imageContainer: {
    overflow: 'hidden',
  },
});

export default React.memo(CachedImage);
