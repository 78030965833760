import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import RightArrowIcon from '@/assets/svg/rigth-arrow.svg';
import useTranslation from '@/translations/useTranslation';
import { Greys, Neutrals } from '@/ui/common/colors';

type Props = {
  memberCount: number;
  chatUnitId: string;
};

export const InThisRoom = ({ memberCount, chatUnitId }: Props) => {
  const { translate } = useTranslation();
  const { navigate } = useNavigation();

  const onPress = useCallback(() => {
    navigate('ChatView', {
      showMembers: Date.now(),
      chatUnitId: chatUnitId,
    });
  }, [navigate, chatUnitId]);

  return (
    <View style={styles.container}>
      <Pressable onPress={onPress}>
        <View style={[styles.row, styles.viewAllMembers]}>
          <Text style={styles.title}>
            {translate('view_all_members', { count: memberCount })}
          </Text>
          <RightArrowIcon />
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Greys.shade0,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  title: {
    color: Neutrals.shade800,
    flex: 1,
  },
  viewAllMembers: {
    flexDirection: 'row',
    fontSize: 14,
    justifyContent: 'space-between',
  },
});
