import { Image } from 'expo-image';
import parsePhoneNumberFromString, {
  AsYouType,
  type CountryCode,
} from 'libphonenumber-js';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Pressable,
  StyleSheet,
  TextInput,
  View,
  type ViewStyle,
} from 'react-native';

import Caption from '@/components/text/Caption';
import { constants } from '@/constants';
import type { Validation } from '@/hooks/useInput';
import { AppText, Spacer } from '@/ui/app/elements';
import { Misc, Neutrals } from '@/ui/common/colors';
import { BorderRadius, Fonts } from '@/ui/common/styles';

export interface PhoneInputProps {
  backgroundColor?: string;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  error?: string;
  label: string;
  labelColor?: string;
  onBlur?(): void;
  onChange(option: string): void;
  onSearch(): void;
  readOnly?: boolean;
  required?: boolean;
  selectedCountry?: typeof constants.defaultCountry;
  validation?: Validation;
  value?: string;
}

export const PhoneInput = ({
  onBlur,
  onSearch,
  selectedCountry = constants.defaultCountry,

  ...props
}: PhoneInputProps) => {
  const { disabled, error, onChange, readOnly, value = '' } = props;

  // State to manage the local display value
  const [displayValue, setDisplayValue] = useState('');

  // Update displayValue when value changes
  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(
      value,
      selectedCountry?.code as CountryCode,
    );
    setDisplayValue(phoneNumber ? phoneNumber.formatNational() : '');
  }, [value, selectedCountry?.code]);

  const handleChange = useCallback(
    (text: string) => {
      // Format the input as the user types
      const asYouType = new AsYouType(selectedCountry?.code as CountryCode);
      const formattedText = asYouType.input(text);
      setDisplayValue(formattedText);

      // Extract the full international number and call onChange with it
      const phoneNumber = parsePhoneNumberFromString(
        asYouType.getNumber()?.number || '',
        selectedCountry?.code as CountryCode,
      );
      if (phoneNumber) {
        onChange(phoneNumber.formatInternational());
      }
    },
    [onChange, selectedCountry?.code],
  );

  return (
    <>
      <View style={styles.inputContainer}>
        <Pressable
          disabled={disabled || readOnly}
          onPress={onSearch}
          style={styles.flagRow}>
          <Image
            // Use a flag CDN to get the flag image
            source={{
              uri:
                'https://hatscripts.github.io/circle-flags/flags/' +
                selectedCountry.code.toLowerCase() +
                '.svg',
            }}
            style={styles.flag}
          />
          <AppText color={Neutrals.shade700} size={17} type="primary700">
            {selectedCountry?.dial_code}
          </AppText>
          <Spacer height={50} width={1} bg={Neutrals.shade200} />
        </Pressable>

        <TextInput
          clearButtonMode="never"
          enterKeyHint="done"
          inputMode="tel"
          onBlur={onBlur}
          onChangeText={handleChange}
          style={styles.input}
          value={displayValue}
        />
      </View>

      {error && <Caption>{error}</Caption>}
    </>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: Misc.transparent,
    borderColor: Neutrals.shade200,
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    pointerEvents: 'box-none',
  },
  flagRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 10,
  },
  flag: {
    alignItems: 'center',
    borderRadius: 60,
    height: 30,
    justifyContent: 'center',
    overflow: 'hidden',
    width: 30,
    resizeMode: 'center',
  },
  input: {
    color: Neutrals.shade800,
    fontFamily: Fonts.primary400,
    fontSize: 17,
    textAlignVertical: 'center',
    flexGrow: 1,
    paddingLeft: 10,
  },
});
