import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { ChatUnitType } from '../../../../API';

import { stat } from 'fs';
import BackButton from '@/components/BackButton';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import { Colors } from '@/domain/theme/Colors';
import useNavigation from '@/hooks/useNavigation';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Brand, Greys } from '@/ui/common/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';

type Props = {
  chatUnitTitle?: string;
  onDone: () => void;
  type?: ChatUnitType;
  statusText?: string;
  isSubmitting?: boolean;
  numberOfNewInvites?: number;
};

export const ChatDirectoryHeader = ({
  chatUnitTitle,
  onDone,
  type,
  statusText,
  isSubmitting,
  numberOfNewInvites,
}: Props) => {
  const { translate } = useTranslations();
  const { goBack } = useNavigation();

  if (!type) {
    return null;
  }

  const isDM = type === ChatUnitType.QUICK_CHAT;

  return (
    <View>
      {isDM ? (
        <View style={styles.dmheadercontainer}>
          <View style={styles.headerContentLeft}>
            <TouchableOpacity
              onPress={onDone}
              style={[
                styles.doneButton,
                isSubmitting ? { backgroundColor: Greys.shade400 } : {},
              ]}
              disabled={isSubmitting}>
              <AppText size={12} type="primary700" color={Colors.neutral0}>
                Done
              </AppText>
            </TouchableOpacity>
          </View>
          <View style={styles.title}>
            <AppText size={12} color={Colors.neutral70}>
              Adding to
            </AppText>
            <View style={styles.iconContainer}>
              <ChatUnitIcon fill={Brand.primary75} size={18} type={type} />
            </View>
            <AppText size={12} type="primary700" color={Colors.neutral80}>
              {chatUnitTitle
                ? chatUnitTitle
                : type === ChatUnitType.QUICK_CHAT
                  ? `New ${translate('Group_Chat')}`
                  : `New ${translate(type)}`}
            </AppText>
            {statusText ? (
              <View style={styles.statusText}>
                <AppText size={10} color={Colors.neutral80}>
                  {statusText}
                </AppText>
              </View>
            ) : null}
          </View>
        </View>
      ) : (
        <View style={styles.tableheadercontainer}>
          <View style={styles.header}>
            <BackButton onPress={goBack} buttonText="cancel_button_title" />
            <TouchableOpacity
              onPress={onDone}
              style={[
                styles.doneButton,
                numberOfNewInvites === 0 || isSubmitting
                  ? { backgroundColor: Greys.shade400 }
                  : {},
              ]}
              disabled={numberOfNewInvites === 0 || isSubmitting}>
              <AppText size={12} type="primary700" color={Colors.neutral0}>
                Send {numberOfNewInvites ? numberOfNewInvites + ' ' : null}
                Invites
              </AppText>
            </TouchableOpacity>
          </View>
          <View style={styles.subheader}>
            <View style={{ flexDirection: 'row' }}>
              <AppText size={12} color={Colors.neutral70}>
                Adding to
              </AppText>
              <View style={styles.iconContainer}>
                <ChatUnitIcon fill={Brand.primary75} size={18} type={type} />
              </View>
              <AppText size={12} type="primary700" color={Colors.neutral80}>
                {chatUnitTitle ? chatUnitTitle : `New ${translate(type)}`}
              </AppText>
            </View>
            {statusText ? (
              <AppText
                size={12}
                color={
                  statusText.split('/')[0] === statusText.split('/')[1]
                    ? Colors.rasberry
                    : Colors.neutral70
                }>
                {statusText} seats
              </AppText>
            ) : null}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dmheadercontainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 44,
    justifyContent: 'space-between',
  },
  tableheadercontainer: {
    height: 66,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: 8,
  },
  headerContentLeft: {
    paddingLeft: 15,
  },
  iconContainer: {
    marginHorizontal: 5,
  },
  title: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 15,
  },
  doneButton: {
    backgroundColor: Colors.primaryLight,
    borderRadius: 14,
    paddingHorizontal: 15,
    paddingVertical: 6,
  },
  statusText: {
    marginLeft: 8,
    borderRadius: 8,
    backgroundColor: Colors.neutral10,
    paddingHorizontal: 5,
    paddingVertical: 2.5,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subheader: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
  },
});
