import { useNavigation } from '@react-navigation/native';
import { Image, type ImageProps } from 'expo-image';
import React, { memo, useCallback, useMemo, useState, type FC } from 'react';
import {
  type ImageStyle,
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
} from 'react-native';

import errorImg from '@/assets/chatview/error.png';
import placeholderCardImg from '@/assets/chatview/placeholderCard.png';
import useNetworkImage from '@/components/image/useNetworkImage';
import { AppText } from '@/ui/app/elements';
import { Neutrals } from '@/ui/common/colors';
import type { BorderRadius } from '@/ui/common/styles';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';

type BaseProps = {
  imageKey?: string;
  width: ImageStyle['width'];
  height: ImageStyle['height'];
  borderRadius?: BorderRadius;
  contentFit?: ImageProps['contentFit'];
  contentPosition?: ImageProps['contentPosition'];
  /* Placeholder image to show while the image is loading */
  placeholder?: ImageProps['source'];
};

// Props: Extended properties for the image component that include conditional logic.
// This type combines BaseProps with additional properties based on the component's usage context.
// The structure below ensures that if `showImagePreview` is true, `onPress` cannot be set,
// enforcing a clear separation of behavior based on the component's configuration.
type Props = BaseProps & {
  onLongPress?: () => void;
  style?: StyleProp<ImageStyle>;
} & ( // If the component is used without image preview functionality:
    | {
        onPress?: () => void; // Optional onPress function if the component is interactive but does not show an image preview.
        showImagePreview?: false; // Explicitly stating that image preview is not available.
      }
    | {
        onPress?: undefined;
        showImagePreview: true;
      }
  );

const NetworkImage: FC<Props> = ({
  imageKey,
  contentFit = 'cover',
  contentPosition = 'center',
  width,
  height: initialHeight,
  placeholder = placeholderCardImg,
  borderRadius,
  onPress,
  showImagePreview = false,
  onLongPress,
  style,
  ...rest
}: Props) => {
  const { localUri, error, refetch, loaded } = useNetworkImage(imageKey);
  const navigation = useNavigation();
  const [height, setHeight] = useState(initialHeight);
  const [layoutWidth, setLayoutWidth] = useState(0);

  const computedPlaceHolderStyle = useMemo(
    () => ({
      width,
      height,
      borderRadius,
      ...styles.placeholderContainer,
    }),
    [borderRadius, height, width],
  );

  const computedImageStyle = useMemo(
    () => ({
      width,
      height,
      borderRadius,
      ...styles.squareImg,
    }),
    [borderRadius, height, width],
  );

  const handleImagePress = useCallback(() => {
    if (onPress && typeof onPress === 'function') {
      onPress();
      return;
    } else if (showImagePreview) {
      navigation.navigate('ImageExpand', {
        source: { uri: localUri },
        imageKey,
      });
    }
  }, [imageKey, localUri, navigation, onPress, showImagePreview]);

  if (ImageKeyHelpers.isImageKeyDocument(imageKey)) {
    return null;
  }

  if (error) {
    return (
      <Pressable style={[computedPlaceHolderStyle, style]} onPress={refetch}>
        <View>
          <Image source={errorImg} style={styles.icon} />
          <AppText textAlign="center" size={14}>
            Image could not be loaded
          </AppText>
          <AppText textAlign="center" color={Neutrals.shade700} size={14}>
            Tap to reload
          </AppText>
        </View>
      </Pressable>
    );
  }

  return (
    <Pressable
      onPress={handleImagePress}
      onLongPress={onLongPress}
      disabled={!!loaded && !showImagePreview}
      style={[computedImageStyle, style]}
      onLayout={({ nativeEvent }) => {
        setLayoutWidth(nativeEvent.layout.width);
      }}>
      <Image
        placeholder={placeholder}
        source={{ uri: localUri, cacheKey: imageKey }}
        cachePolicy="memory-disk"
        style={StyleSheet.absoluteFillObject}
        contentFit={contentFit}
        contentPosition={contentPosition}
        onLoad={({ source }) => {
          if (contentFit !== 'contain') {
            return;
          }

          // shrink height of image after loading
          if (height && typeof height === 'number') {
            if (source.height < height) {
              // shrink the image to fit the height
              setHeight(source.height);
              return;
            }

            // set height based on ratio of source image and current layout width
            const newHeight = (layoutWidth / source.width) * source.height;
            if (newHeight < height) {
              setHeight(newHeight);
            }
          }
        }}
        {...rest}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginBottom: 8,
  },
  placeholderContainer: {
    backgroundColor: Neutrals.shade100,
  },
  squareImg: {
    overflow: 'hidden',
  },
  squareImgPlaceholder: {
    zIndex: 3,
  },
});

export default memo(NetworkImage);
