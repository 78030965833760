import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Keyboard,
  type LayoutChangeEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
} from 'react-native';
import type PagerView from 'react-native-pager-view';
import type { SharedValue } from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitSubType, ChatUnitType } from '../../../../API';
import { useOnPressChatHead } from '../../hooks/useOnPressChatHead';

import AnimatedAvatar from './AnimatedAvatar';

import AddFriendIcon from '@/assets/svg/add-friend-icon.svg';
import AddFriendWebIcon from '@/assets/svg/add-friend-web-icon.svg';
import AppAvatar from '@/components/avatar';
import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import useRemainingSeatsLimit from '@/domain/invites/hooks/useRemainingSeats';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import ViewMembersButton from '@/domain/rooms/components/buttons/ViewMembersButton';
import AnimatedRoomsChathead from '@/domain/rooms/components/chatheads/AnimatedRoomsChathead';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatUnit } from '@/services/types';
import { Greys } from '@/ui/common/colors';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { web } from '@/utilities/platform';

interface ChatHeaderProps {
  chatUnit: ChatUnit;
  onAddPress: () => void;
  offset: SharedValue<number>;
  pagerRef: React.RefObject<PagerView>;
  targetIndex: SharedValue<number | undefined>;
  toggleShowMembers: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  chatUnit,
  onAddPress,
  offset,
  pagerRef,
  toggleShowMembers,
  targetIndex,
}) => {
  const [rows, setRows] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);

  // Fetch user data and chat configuration
  const userId = useUserStore(state => state.user?.id);
  const limit = useRemainingSeatsLimit(chatUnit);
  const userAvatar = useUserStore(useShallow(state => state.user?.avatar));
  const chatUsers = useActiveConversationStore(
    useShallow(state => state.getActiveChatUsers()),
  );

  const organisations = useOrganisations();

  const isAllowedToInvite =
    isActiveMemberOfOrg(organisations, chatUnit.organisationId, userId) &&
    chatUnit.subtype !== ChatUnitSubType.ONE_ON_ONE;

  // Calculate avatar configuration based on number of users and chat type
  const { avatarSize, betweenIconSpace, sidePadding, bottomMargin } = useMemo(
    () => getAvatarConfig(chatUsers.length, chatUnit?.type),
    [chatUsers.length, chatUnit.type],
  );

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  }, []);

  useEffect(() => {
    if (containerWidth > 0) {
      let totalAvatarWidth;

      // total width for avatar container in mobile decreases as the add button moves on top
      if (!web && [8, 9, 10].includes(chatUsers.length)) {
        totalAvatarWidth = (avatarSize + betweenIconSpace) * chatUsers.length;
      } else if (limit > 0) {
        totalAvatarWidth =
          (avatarSize + betweenIconSpace) * chatUsers.length +
          (avatarSize + sidePadding);
      } else {
        // total width for avatar container in decreases as the add dissapears on both mobile and web
        totalAvatarWidth = (avatarSize + betweenIconSpace) * chatUsers.length;
      }

      setRows(containerWidth < totalAvatarWidth ? 2 : 1);
    }
  }, [
    containerWidth,
    avatarSize,
    betweenIconSpace,
    chatUsers.length,
    limit,
    sidePadding,
  ]);

  // Handle add button press
  const handleAddPress = useCallback(() => {
    Keyboard.dismiss();
    onAddPress();
  }, [onAddPress]);

  const onPressChatHead = useOnPressChatHead({ offset, pagerRef });

  // Generate animated avatars for chat users
  const animatedAvatars = useMemo(() => {
    return chatUsers
      .filter(chatUser => chatUser.id !== userId)
      .map((chatUser, index) => (
        <AnimatedAvatar
          key={chatUser.chatUnitUserId}
          index={index + 1}
          numberOfUsers={chatUsers.length}
          offset={offset}
          onPressChatHead={onPressChatHead}
          tableUser={chatUser}
          targetIndex={targetIndex}
          size={avatarSize}
          betweenIconSpace={betweenIconSpace}
        />
      ));
  }, [
    chatUsers,
    userId,
    offset,
    onPressChatHead,
    targetIndex,
    avatarSize,
    betweenIconSpace,
  ]);

  const containerStyle = useMemo((): ViewStyle => {
    if (web) {
      return {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        flexGrow: 1,
        maxWidth: 'auto',
        minWidth: 500 + sidePadding,
        marginBottom: limit > 0 ? bottomMargin - 5 : bottomMargin + 15,
        paddingLeft: sidePadding,
      };
    }
    return StyleSheet.flatten([
      styles.container,
      { marginBottom: bottomMargin, paddingLeft: sidePadding },
    ]);
  }, [sidePadding, bottomMargin, limit]);

  // Style for avatars container
  const avatarsContainerStyle = useMemo(
    (): ViewStyle => ({
      flexDirection: 'row',
      rowGap: avatarSize / 2 + 4,
      flexWrap: 'wrap',
      flex: 1,
    }),
    [avatarSize],
  );

  // Style for add user button on mobile
  const mobileAddUserButtonStyle = useMemo(
    (): ViewStyle => ({
      marginLeft: 'auto',
      marginRight: sidePadding,
    }),
    [sidePadding],
  );

  // Style for add user button on web
  const webAddUserButtonStyle = useMemo(
    (): ViewStyle => ({
      marginLeft: 'auto',
      marginRight: sidePadding,
    }),
    [sidePadding],
  );

  const bottomOverlayHeight = useMemo(() => {
    if (chatUnit?.type === ChatUnitType.ROOM) {
      return web ? 50 : 45;
    } else if (web) {
      return rows === 2 ? 120 : 50;
    } else {
      return rows * 45;
    }
  }, [chatUnit?.type, rows]);

  const numberOfUsers = chatUsers.length;

  // Determine if add button should be displayed inline
  const shouldDisplayAddButtonInline =
    !web &&
    (![0, 8, 9, 10].includes(numberOfUsers) ||
      chatUnit?.type === ChatUnitType.ROOM);

  if (!userId) return null;

  return (
    <View style={styles.headerContainer}>
      <View style={containerStyle} onLayout={onContainerLayout}>
        <View style={avatarsContainerStyle}>
          {/* Current user's avatar */}
          <View
            style={[
              styles.myAvatarContainer,
              {
                width: avatarSize,
                height: avatarSize,
                marginRight: betweenIconSpace,
              },
            ]}>
            <AppAvatar
              imageKey={userAvatar}
              displayName={'You'}
              size={avatarSize}
            />
            <Text style={styles.avatarTitle}>You</Text>
          </View>

          {/* Render avatars based on chat type */}
          {(chatUnit?.type === ChatUnitType.TABLE ||
            chatUnit?.type === ChatUnitType.QUICK_CHAT) &&
            animatedAvatars}

          {chatUnit?.type === ChatUnitType.ROOM && (
            <>
              <AnimatedRoomsChathead
                index={1}
                key={chatUsers[0]?.chatUnitUserId}
                numberOfUsers={chatUsers.length > 0 ? chatUsers.length - 1 : 1}
                offset={offset}
                onPressChatHead={onPressChatHead}
              />
              <ViewMembersButton onPress={toggleShowMembers} />
            </>
          )}

          {/* Add button for mobile */}
          {shouldDisplayAddButtonInline && isAllowedToInvite && limit > 0 && (
            <Pressable
              onPress={handleAddPress}
              style={[
                mobileAddUserButtonStyle,
                { width: avatarSize, height: avatarSize },
              ]}>
              <AddFriendIcon
                height={avatarSize}
                width={avatarSize}
                size={200}
              />
              <Text style={styles.addButtonTitle}>Add</Text>
            </Pressable>
          )}
          {web && isAllowedToInvite && limit > 0 && (
            <Pressable onPress={handleAddPress} style={webAddUserButtonStyle}>
              <AddFriendWebIcon
                height={avatarSize}
                width={avatarSize}
                size={200}
              />
              <Text style={styles.addButtonTitle}>Add</Text>
            </Pressable>
          )}
        </View>
      </View>
      <View style={[styles.bottomOverlay, { height: bottomOverlayHeight }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  addButtonTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    fontWeight: '400',
    marginTop: 4,
    textAlign: 'center',
  },
  myAvatarContainer: {
    alignItems: 'center',
  },
  avatarTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 10,
    fontWeight: '700',
    marginTop: 4,
  },
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  bottomOverlay: {
    backgroundColor: Greys.shade600,
    bottom: 0,
    position: 'absolute',
    zIndex: -1,
    width: '100%',
  },
  headerContainer: {
    zIndex: 1000,
    minHeight: web ? 80 : undefined,
  },
});

export default React.memo(ChatHeader);
