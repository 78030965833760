import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Animated, {
  FadeIn,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

import ActivitySkeleton from '@/assets/svg/activity-skeleton.svg';
import { web } from '@/utilities/platform';

export const ActivitySkeletons = () => {
  return (
    <View>
      <Skeleton delay={100} />
      <Skeleton delay={300} />
      <Skeleton delay={400} />
      <Skeleton delay={600} />
      <Skeleton delay={800} />
    </View>
  );
};

const Skeleton = ({ delay }: { delay: number }) => {
  const opacity = useSharedValue(1);
  return (
    <Animated.View
      style={[styles.animatedWrapper]}
      entering={FadeIn.delay(delay)
        .duration(700)
        .withCallback(() => {
          opacity.value = withRepeat(
            withTiming(0.4, {
              duration: 700,
            }),
            -1,
            true,
          );
        })}>
      <Animated.View style={[{ opacity }, !web ? styles.skeleton : {}]}>
        <ActivitySkeleton />
      </Animated.View>
    </Animated.View>
  );
};

const svgRatio = 93 / 375; // SVG height divided by width
const width = Dimensions.get('window').width;
const height = width * svgRatio;
const styles = StyleSheet.create({
  skeleton: {
    height,
  },
  animatedWrapper: { marginBottom: 4 },
});
