import { useCallback, useMemo } from 'react';
import { InviteType } from 'src/models';
import { useShallow } from 'zustand/react/shallow';

import { InviteStatus } from '../../../API';
import { getInvitesForChatUnit } from '../helpers/getInvitesForChatUnit';
import type { Invite } from '../types/types';

import { useInviteStore } from '@/domain/invites/state/useInviteStore';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';

export const useChatInvites = (id?: string) => {
  const [setInvites, invites, invitesGroupedByStatus] = useInviteStore(
    useShallow(state => [
      state.setInvites,
      state.getInvites(id),
      state.getInvitesGroupedByStatus(id),
    ]),
  );

  const refreshInvites = useCallback(() => {
    if (!id) {
      return;
    }
    getInvitesForChatUnit(id).then(results => {
      if (!results) {
        return;
      }

      const newInvites: Invite[] = [];
      results.forEach(invite => {
        try {
          const newInvite = factory.invite(invite);
          if (newInvite.type === InviteType.RECEIVER) {
            newInvites.push(newInvite);
          }
        } catch (e) {
          logger.warn('refreshInvites', e);
        }
      });

      setInvites(id, newInvites);
    });
  }, [id, setInvites]);

  const invitedNumbers = useMemo(() => {
    const acceptedInviteNumbers =
      invitesGroupedByStatus?.[InviteStatus.ACCEPTED]?.flatMap(invite =>
        invite.inviteeInfo.map(person => person.phone as string),
      ) ?? [];
    const pendingInviteNumbers =
      invitesGroupedByStatus?.[InviteStatus.PENDING]?.flatMap(invite =>
        invite.inviteeInfo.map(person => person.phone as string),
      ) ?? [];

    return [...acceptedInviteNumbers, ...pendingInviteNumbers];
  }, [invitesGroupedByStatus]);

  return {
    invites,
    invitesGroupedByStatus,
    refreshInvites,
    invitedNumbers,
  };
};
