import { outputConversationDetails } from './outputConversationDetails';

import type { Message } from '@/services/chatUnit/types';
import { spamMessages } from '@/services/datastore/message/mockData';
import { getConstant } from '@/utilities/constants/getConstant';

export const handleMessageCommands = async (message: Message) => {
  if (getConstant('env') === 'prod') {
    return;
  }

  // non production commands below

  if (message.text.startsWith('!spam')) {
    spamMessages(message);
  } else if (message.text.startsWith('!conversation details')) {
    outputConversationDetails(
      message.conversationId,
      message.chatUnitId,
      message.userId,
      message.chatUnitUserId,
    );
  }
};
