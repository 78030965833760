import {
  type NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack';
import * as React from 'react';

import MainNavigator from './mainNavigators';
import { defaultScreenOptions } from './settings';
import type { ScreensParams } from './types';

import CreateChatUnitScreen from '@/domain/chatUnit/CreateChatUnitScreen';
import { CreateDM } from '@/domain/chatUnit/components/dm/createDM/CreateDM';
import ArchivedConversation from '@/domain/chatUnit/components/settings/ArchivedConversation';
import { ArchivedConversations } from '@/domain/chatUnit/components/settings/ArchivedConversations';
import { ChatSettings } from '@/domain/chatUnit/components/settings/ChatSettings';
import ChatViewContainer from '@/domain/chatUnit/components/view/ChatUnitView';
import ImageExpandView from '@/domain/chatUnit/components/view/ImageExpandView';
import useCMSRefresh from '@/domain/cms/useCMSRefresh';
import { Directory } from '@/domain/contact/components/directory/Directory';
import CreateInvitesView from '@/domain/invites/views/CreateInvitesView';
import InviteUserView from '@/domain/invites/views/InviteUserView';
import SenderInviteSuccessView from '@/domain/invites/views/SenderInviteSuccessView';
import InviteList from '@/domain/invites/views/invite-list';
import InviteDetails from '@/domain/invites/views/inviteDetails';
import OnboardingAddMembersScreen from '@/domain/onboarding/OnboardingAddMembersScreen';
import OnboardingCreateWorkspaceScreen from '@/domain/onboarding/OnboardingCreateWorkspaceScreen';
import OnboardingVideoScreen from '@/domain/onboarding/OnboardingVideoScreen';
import { useEventListenersBootstrap } from '@/hooks/useEventListenersBootstrap';
import useNotifications from '@/hooks/useNotifications';
import Onboarding from '@/modules/GetStarted/Onboarding';
import UserProfile from '@/modules/UserProfile';
import { Search } from '@/modules/activity/screens/search';
import CreateTableParameters from '@/modules/create-table/CreateTableParameters';
import OtherUserProfile from '@/modules/otherUserProfile';
import DeeplinkService from '@/services/deeplink/DeeplinkService';
import { Fonts } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
import OnboardingHeaderLeft from './components/OnboardingHeaderLeft';
import OnboardingHeaderTitle from './components/OnboardingHeaderTitle';

const onboardingScreenOptions: NativeStackNavigationOptions = {
  headerShown: true,
  headerLeft: OnboardingHeaderLeft,
  headerTitle: OnboardingHeaderTitle,
  headerBackTitleVisible: false,
  headerBackVisible: false,
  headerTitleAlign: 'center',
};

const Stack = createNativeStackNavigator<ScreensParams>();

export const CompletedProfileScreens = () => {
  DeeplinkService.useSetupDeeplink();
  useNotifications();
  useEventListenersBootstrap();
  useCMSRefresh();

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          ...defaultScreenOptions,
        }}>
        <Stack.Group>
          <Stack.Screen component={MainNavigator} name="MainNavigator" />
          <Stack.Screen component={ChatViewContainer} name="ChatView" />
          <Stack.Screen
            component={Search}
            name="Search"
            options={{
              animation: 'fade',
              gestureEnabled: false,
            }}
          />
          <Stack.Screen
            component={CreateChatUnitScreen}
            name="CreateRoom"
            options={{
              headerShown: true,
              headerLeft: OnboardingHeaderLeft,
              headerTitleAlign: 'center',
              headerTitleStyle: {
                fontFamily: Fonts.primary600,
                fontSize: 16,
              },
            }}
          />
          <Stack.Screen component={CreateDM} name="CreateDM" />
          <Stack.Screen
            component={CreateTableParameters}
            name="CreateTableParameters"
          />
          <Stack.Screen component={UserProfile} name="UserProfile" />
          <Stack.Screen component={OtherUserProfile} name="OtherUserProfile" />
          <Stack.Screen component={InviteList} name="InviteList" />
          <Stack.Screen component={InviteUserView} name="CreateInvite" />
          <Stack.Screen component={CreateInvitesView} name="CreateInvites" />
          <Stack.Screen component={InviteDetails} name="InviteDetails" />
          <Stack.Screen component={ChatSettings} name="ChatSettings" />
          <Stack.Screen
            component={ArchivedConversations}
            name="ArchivedConversations"
          />
          <Stack.Screen
            component={ArchivedConversation}
            name="ArchivedConversation"
          />
          <Stack.Screen component={Onboarding} name="Onboarding" />
          <Stack.Screen component={Directory} name="Directory" />
          <Stack.Screen
            component={SenderInviteSuccessView}
            name="SenderInviteSuccess"
          />
          <Stack.Screen component={ImageExpandView} name="ImageExpand" />
          <Stack.Screen
            component={OnboardingCreateWorkspaceScreen}
            name="OnboardingCreateWorkspace"
            initialParams={{
              stepNumber: 1,
              totalSteps: 2,
            }}
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={OnboardingAddMembersScreen}
            name="OnboardingAddMembers"
            initialParams={{
              stepNumber: 2,
              totalSteps: 2,
            }}
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={OnboardingVideoScreen}
            name="OnboardingVideo"
          />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};
