import { useFocusEffect, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type NativeScrollEvent, View } from 'react-native';
import { useResizeMode } from 'react-native-keyboard-controller';
import Animated, {
  useSharedValue,
  withTiming,
  useAnimatedRef,
  useWorkletCallback,
  type SharedValue,
  Easing,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitColorId, ChatUnitType } from '../../../../API';
import { useSettings } from '../../hooks/useSettings';

import { ChatUnitDetails } from './components/ChatUnitDetails';
import ColorPicker from './components/ColorPicker';
import DangerZone from './components/DangerZone';
import ImagePicker from './components/ImagePicker';
import { InThisChat } from './components/InThisChat';
import { SettingsHeader } from './components/SettingsHeader';
import { ViewArchivedConversations } from './components/ViewArchivedConversations';
import { styles } from './styles';

import { AnimatedShadowContainer } from '@/components/animatedShadowContainer/AnimatedShadowContainer';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { useAnimdatedScrollView } from '@/hooks/useAnimatedScrollView';
import { useAutoScrollForKeyboard } from '@/hooks/useAutoScrollForKeyboard';
import type { MemberType } from '@/models';
import type { MainStackScreenProps } from '@/navigation/types';
import { tableColors } from '@/utilities/constants/table-colors';
import { ios } from '@/utilities/platform';

const SCROLL_THRESHOLD = 100;
const ANIMATION_DURATION = 200;

export const ChatSettings = () => {
  const {
    params: { id },
  } = useRoute<MainStackScreenProps<'ChatSettings'>['route']>();
  const { top } = useSafeAreaInsets();
  const {
    cancelUpdate,
    isDirty,
    isLoading,
    isOwner,
    myChatUser,
    otherChatUsers,
    otherChatUsersIncludingArchived,
    saveUpdate,
    chatUnit,
    updateDetailsHandler,
  } = useSettings({ id });
  const type = chatUnit?.type ?? ChatUnitType.TABLE;
  useResizeMode();
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const opacity = useSharedValue(0);
  const zIndex = useSharedValue(1);
  const zIndexBlack = useSharedValue(0);
  const scrollViewAnimatedRef = useAnimatedRef<Animated.ScrollView>();
  const animatedIsDirty = useSharedValue(isDirty);
  const [orgMembers, setOrgMembers] = useState<(MemberType | null)[]>([]);
  const { onContentTouch, scrollPosition } = useAutoScrollForKeyboard({
    bottomOffset: 50,
    scrollViewAnimatedRef,
  });

  const getArchivedConversationsByTableId = useConversationStore(
    useShallow(state => state.getArchivedConversationsByTableId),
  );

  const archivedConversationsLength = getArchivedConversationsByTableId(
    chatUnit?.id || '',
  ).length;

  const ownerName = useMemo(() => {
    const ownerId = chatUnit?.owner;

    return myChatUser?.id === ownerId
      ? myChatUser?.username
      : otherChatUsersIncludingArchived.find(user => user.id === ownerId)
          ?.username;
  }, [
    myChatUser?.id,
    myChatUser?.username,
    otherChatUsersIncludingArchived,
    chatUnit?.owner,
  ]);

  const tableUsers = useTableUsersStore(state =>
    state.getTableUsersForTable(chatUnit?.id ?? ''),
  );
  const isLastMember = tableUsers.length === 1;
  const organisations = useOrganisations();

  useFocusEffect(
    useCallback(() => {
      const members = organisations.find(
        org => org.id === chatUnit?.organisationId,
      )?.members;
      if (members) {
        setOrgMembers(members);
      } else {
        setOrgMembers([]);
      }
    }, [organisations, chatUnit?.organisationId]),
  );

  const onScrollCallback = useWorkletCallback(
    (e: NativeScrollEvent) => {
      'worklet';
      scrollPosition.value = e.contentOffset.y;
      if (!animatedIsDirty.value) {
        opacity.value = e.contentOffset.y / SCROLL_THRESHOLD;
        zIndexBlack.value = e.contentOffset.y / SCROLL_THRESHOLD;
        zIndex.value = 1 - e.contentOffset.y / SCROLL_THRESHOLD;
      }
    },
    [isDirty],
  );

  const { animateShadow, onScroll } = useAnimdatedScrollView({
    onScrollCallback,
  });

  useEffect(() => {
    // adjust header when isDiry changes

    animatedIsDirty.value = isDirty;

    if (isDirty) {
      setWithTiming(opacity, 1);
      setWithTiming(zIndexBlack, 1);
      setWithTiming(zIndex, 0);
    } else {
      const normalizedScroll = scrollPosition.value / SCROLL_THRESHOLD;
      setWithTiming(opacity, normalizedScroll);
      setWithTiming(zIndexBlack, normalizedScroll);
      setWithTiming(zIndex, 1 - normalizedScroll);
    }
  }, [
    isDirty,
    scrollPosition.value,
    opacity,
    zIndexBlack,
    zIndex,
    animatedIsDirty,
  ]);

  const setWithTiming = (value: SharedValue<number>, target: number): void => {
    value.value = withTiming(target, {
      duration: ANIMATION_DURATION,
      easing: Easing.linear,
    });
  };

  const selectedColor = tableColors.find(
    color => color.id === chatUnit?.colorId,
  )?.id;

  if (!chatUnit) {
    return null;
  }

  return (
    <View style={styles.container}>
      <SettingsHeader
        cancelUpdate={cancelUpdate}
        isDirty={isDirty}
        isLoading={isLoading}
        opacity={opacity}
        saveUpdate={saveUpdate}
        setHeaderHeight={setHeaderHeight}
        type={type}
      />
      <AnimatedShadowContainer
        opacity={animateShadow}
        showBottomBlur={ios}
        showTopShadow
        topShadowOffset={top + headerHeight}>
        <Animated.ScrollView
          bounces={false}
          contentContainerStyle={styles.contentContainer}
          onScroll={onScroll}
          onTouchStart={onContentTouch}
          ref={scrollViewAnimatedRef}
          scrollEventThrottle={16}>
          <ChatUnitBackgroundImage
            imageKey={chatUnit.backgroundImage}
            width="100%"
            height={270}
          />
          <ChatUnitDetails
            currentChatUnit={chatUnit}
            ownerName={ownerName || ''}
            setCurrentChatUnit={updateDetailsHandler}
          />
          {archivedConversationsLength > 0 && (
            <ViewArchivedConversations chatUnitId={chatUnit.id} />
          )}
          <InThisChat
            isOwner={isOwner}
            myChatUser={myChatUser}
            otherChatUsers={otherChatUsers}
            ownerId={chatUnit.owner}
            chatUnitId={chatUnit.id}
            chatUnitTitle={chatUnit.title}
            type={type}
            orgMembers={orgMembers}
            orgId={chatUnit?.organisationId}
          />
          <ColorPicker
            currentChatUnit={chatUnit}
            selectedColor={selectedColor || ChatUnitColorId.RED}
            setCurrentChatUnit={updateDetailsHandler}
            chatUnitType={type}
          />
          <ImagePicker
            currentChatUnit={chatUnit}
            setCurrentChatUnit={updateDetailsHandler}
          />
          {!isLastMember && type === ChatUnitType.TABLE && (
            <DangerZone
              chatUnitTitle={chatUnit.title}
              myChatUser={myChatUser}
            />
          )}
        </Animated.ScrollView>
      </AnimatedShadowContainer>
    </View>
  );
};
