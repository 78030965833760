import { useNetInfo } from '@react-native-community/netinfo';
import {
  type DrawerContentComponentProps,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import {
  type NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack';
import type * as React from 'react';
import { View } from 'react-native';
import { useMMKVBoolean, useMMKVString } from 'react-native-mmkv';
import { Host } from 'react-native-portalize';

import { CompletedProfileScreens } from './CompletedProfileScreens';
import { SideBar } from './SideBar';
import { SplitView } from './SplitView';
import { navigationRef } from './navigationRef';
import { defaultScreenOptions } from './settings';
import { styles } from './styles';
import type { ScreensParams } from './types';

import Loader from '@/components/loader';
import { PromptProvider } from '@/components/prompt/PromptProvider';
import { constants } from '@/constants';
import { ExperienceProvider } from '@/context/Experience';
import { NoConnection } from '@/domain/auth/components/NoConnection';
import { ActiveConversationProvider } from '@/domain/conversation/state/useActiveConversationStore';
import ChooseAvatarScreen from '@/domain/onboarding/ChooseAvatarScreen';
import OnboardingAddMembersScreen from '@/domain/onboarding/OnboardingAddMembersScreen';
import OnboardingCreateProfileScreen from '@/domain/onboarding/OnboardingCreateProfileScreen';
import OnboardingCreateWorkspaceScreen from '@/domain/onboarding/OnboardingCreateWorkspaceScreen';
import OnboardingInvitedWorkspacesScreen from '@/domain/onboarding/OnboardingInvitedWorkspacesScreen';
import OnboardingSignInOTPScreen from '@/domain/onboarding/OnboardingSignInOTPScreen';
import OnboardingSignInScreen from '@/domain/onboarding/OnboardingSignInScreen';
import OnboardingStartScreen from '@/domain/onboarding/OnboardingStartScreen';
import OnboardingStartScreenWeb from '@/domain/onboarding/OnboardingStartScreenWeb';
import OnboardingStepOneOTPScreen from '@/domain/onboarding/OnboardingStepOneOTPScreen';
import OnboardingStepOneScreen from '@/domain/onboarding/OnboardingStepOneScreen';
import OnboardingStepZeroScreen from '@/domain/onboarding/OnboardingStepZeroScreen';
import OnboardingVideoScreen from '@/domain/onboarding/OnboardingVideoScreen';
import useUserStore from '@/domain/user/state/useUserStore';
import { useDatastoreBootstrap } from '@/hooks/useDatastoreBootstrap';
import { useDelayedNavigation } from '@/hooks/useDelayedNavigation';
import GetStartedScreen from '@/modules/GetStarted/GetStarted';
import Onboarding from '@/modules/GetStarted/Onboarding';
import Activity from '@/modules/activity';
import Search from '@/modules/activity/screens/search';
import CreateAccount from '@/modules/auth/screens/createAccount';
import ForgetPassword from '@/modules/auth/screens/reset-password';
import ForgotPasswordNewPassword from '@/modules/auth/screens/reset-password/ForgotPasswordNewPassword';
import ForgotPasswordSecurityCode from '@/modules/auth/screens/reset-password/ForgotPasswordSecurityCode';
import SmsVerification from '@/modules/auth/screens/smsVerification';
import AddPhoneNumber from '@/modules/exclusiveAcces/addPhoneNumber';
import { ExclusiveAccessIntro } from '@/modules/exclusiveAcces/intro';
import UserMenu from '@/modules/user-drawer-menu/user-menu';
import { ios, web } from '@/utilities/platform';
import useWindowDimensions, {
  ScreenSizes,
} from '@/utilities/useWindowDimensions/useWindowDimensions';
import ChooseAvatarHeaderTitle from './components/ChooseAvatarHeaderTitle';
import OnboardingHeaderLeft from './components/OnboardingHeaderLeft';
import OnboardingHeaderTitle from './components/OnboardingHeaderTitle';

const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator<ScreensParams>();

const DrawerContent: React.FC<DrawerContentComponentProps> = ({
  navigation,
}) => <UserMenu closeDrawer={navigation.closeDrawer} />;

const GetStartedStack = () => {
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Group>
        <Stack.Screen component={GetStartedScreen} name="GetStarted" />
        <Stack.Screen component={Onboarding} name="OnboardingScreen" />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const DrawerSideMenuWeb: React.FC<DrawerContentComponentProps> = ({
  navigation,
}) => <SideBar navigation={navigation} />;

const DrawerContentWeb: React.FC<DrawerContentComponentProps> = () => (
  <NavigationContainer documentTitle={{ enabled: false }} independent={true}>
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
      }}>
      <Drawer.Screen component={Activity} name="Activity" />
      <Drawer.Screen component={Search} name="Search" />
    </Drawer.Navigator>
  </NavigationContainer>
);

//Web only window
const MainWebWindow = () => (
  <Drawer.Navigator
    drawerContent={DrawerContentWeb}
    screenOptions={{
      drawerStyle: styles.mainWebWindowDrawerStyle,
      sceneContainerStyle: {
        backgroundColor: 'transparent',
        height: '100%',
      },
      drawerType: 'permanent',
      headerShown: false,
      swipeEdgeWidth: 0,
      drawerItemStyle: { height: 0, display: 'none' },
      swipeEnabled: false,
    }}>
    <Drawer.Screen component={SplitView} name={'SplitView'} />
  </Drawer.Navigator>
);

const CompletedProfileStack = ({}: any) => {
  const { screenSize } = useWindowDimensions();

  // disabling web layout temporarily, remove false to enable web layout.
  if (
    web &&
    ![ScreenSizes.xs, ScreenSizes.xxs, ScreenSizes.sm].includes(screenSize)
  ) {
    return (
      <View style={styles.body}>
        <View style={styles.fullView}>
          <View style={styles.container}>
            <Drawer.Navigator
              drawerContent={DrawerSideMenuWeb}
              screenOptions={{
                drawerStyle: styles.webCompletedProfileStack,
                sceneContainerStyle: {
                  backgroundColor: 'transparent',
                },
                drawerType: 'permanent',
                headerShown: false,
                swipeEdgeWidth: 0,
                drawerItemStyle: { height: 0 },
                swipeEnabled: false,
              }}>
              <Drawer.Screen component={MainWebWindow} name="Home" />
            </Drawer.Navigator>
          </View>
        </View>
      </View>
    );
  }

  return (
    <ActiveConversationProvider>
      <Host>
        <CompletedProfileScreens />
      </Host>
    </ActiveConversationProvider>
  );
};

const ForgotPasswordStack = () => (
  <Stack.Navigator screenOptions={defaultScreenOptions}>
    <Stack.Group>
      <Stack.Screen component={ForgetPassword} name="ForgetPassword" />
      <Stack.Screen
        component={ForgotPasswordSecurityCode}
        name="ForgotPasswordSecurityCode"
      />
      <Stack.Screen
        component={ForgotPasswordNewPassword}
        name="ForgotPasswordNewPassword"
      />
    </Stack.Group>
  </Stack.Navigator>
);

const AuthenticatedNavigator = () => {
  const isProfileSetup = useUserStore(state => state.isProfileComplete());
  const [onboardingType, _] = useMMKVString('onboardingType');
  // If the user has not completed their profile setup and is not in the onboarding flow
  // They should be redirected to the profile setup flow.
  if (!isProfileSetup) {
    return <UnconfirmedProfile />;
  }

  if (onboardingType === 'businessInvitee') {
    return <InviteeFlow />;
  }

  const defaultScreenForOnboardingIndividualOrBusiness =
    onboardingType === 'business'
      ? 'OnboardingCreateWorkspace'
      : onboardingType === 'individual'
        ? 'OnboardingAddMembers'
        : '';

  // If onboardingType is set, we are in the onboarding flow
  // At the end of the onboarding flow, onboardingType is set to undefined.
  if (defaultScreenForOnboardingIndividualOrBusiness) {
    return (
      <AuthenticatedOnboarding
        defaultScreenName={defaultScreenForOnboardingIndividualOrBusiness}
      />
    );
  }

  // Now, the user has completed their profile setup and is not in the onboarding flow anymore
  // Or, the user has completed the whole onboarding flow
  // Go to the in app experience
  return (
    <Drawer.Navigator
      drawerContent={DrawerContent}
      screenOptions={{
        drawerStyle: {
          width: 345,
        },
        drawerType: 'front',
        sceneContainerStyle: {
          ...(web && { backgroundColor: 'transparent' }),
        },
        headerShown: false,
        swipeEdgeWidth: 0,
        swipeEnabled: true,
        ...(ios
          ? {
              drawerHideStatusBarOnOpen: false,
              drawerStatusBarAnimation: 'fade',
            }
          : {}),
      }}>
      <Drawer.Screen component={CompletedProfileStack} name="Home" />
    </Drawer.Navigator>
  );
};

const UnconfirmedProfile = () => (
  <Stack.Navigator screenOptions={defaultScreenOptions}>
    <Stack.Screen
      component={OnboardingCreateProfileScreen}
      name="OnboardingCreateProfile"
      options={web ? undefined : onboardingScreenOptions}
    />
    {web ? null : (
      <Stack.Group>
        <Stack.Screen
          component={ChooseAvatarScreen}
          name="ChooseAvatar"
          options={chooseAvatarScreenOptions}
        />
      </Stack.Group>
    )}
  </Stack.Navigator>
);

const InviteeFlow = () => (
  <Stack.Navigator screenOptions={defaultScreenOptions}>
    <Stack.Screen
      component={OnboardingInvitedWorkspacesScreen}
      name="OnboardingInvitedWorkspaces"
      options={web ? undefined : onboardingScreenOptions}
    />
  </Stack.Navigator>
);

const onboardingScreenOptions: NativeStackNavigationOptions = {
  headerShown: true,
  headerLeft: OnboardingHeaderLeft,
  headerTitle: OnboardingHeaderTitle,
  headerBackTitleVisible: false,
  headerBackVisible: false,
  headerTitleAlign: 'center',
};

const chooseAvatarScreenOptions: NativeStackNavigationOptions = {
  headerShown: true,
  headerLeft: OnboardingHeaderLeft,
  headerTitle: ChooseAvatarHeaderTitle,
  headerBackTitleVisible: false,
  headerBackVisible: false,
  headerTitleAlign: 'center',
  presentation: 'formSheet',
};

const SignupFlow = () => {
  const { isInternetReachable } = useNetInfo();
  const showNoConnection = !isInternetReachable && isInternetReachable !== null;

  // Get the new onboarding feature flag
  const { defaultScreen, isNewOnboardingEnabled } = useNewOnboardingFlow();

  if (showNoConnection) {
    return <NoConnection />;
  }

  if (isNewOnboardingEnabled) {
    return (
      <Stack.Navigator
        initialRouteName={defaultScreen}
        screenOptions={defaultScreenOptions}>
        <Stack.Group>
          <Stack.Screen
            component={web ? OnboardingStartScreenWeb : OnboardingStartScreen}
            name="OnboardingStart"
          />
          <Stack.Screen
            name="OnboardingStepZero"
            options={web ? undefined : onboardingScreenOptions}
            component={OnboardingStepZeroScreen}
          />

          <Stack.Screen
            component={OnboardingSignInScreen}
            name="OnboardingSignIn"
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={OnboardingSignInOTPScreen}
            name="OnboardingSignInOTP"
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={OnboardingStepOneScreen}
            name="OnboardingStepOne"
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={OnboardingStepOneOTPScreen}
            name="OnboardingStepOneOTP"
            options={web ? undefined : onboardingScreenOptions}
          />
          <Stack.Screen
            component={ForgotPasswordStack}
            name="ForgotPasswordStack"
          />
          <Stack.Screen
            component={OnboardingVideoScreen}
            name="OnboardingVideo"
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  }

  return (
    <Stack.Navigator
      initialRouteName={defaultScreen}
      screenOptions={defaultScreenOptions}>
      <Stack.Group>
        <Stack.Screen component={ExclusiveAccessIntro} name="HomeScreen" />
        <Stack.Screen component={GetStartedStack} name="Onboarding" />
        <Stack.Screen component={CreateAccount} name="CreateAccount" />
        <Stack.Screen component={SmsVerification} name="SmsVerification" />
        <Stack.Screen component={AddPhoneNumber} name="SignIn" />
      </Stack.Group>
    </Stack.Navigator>
  );
};

type AuthenticatedOnboardingProps = {
  defaultScreenName: keyof ScreensParams;
};

const AuthenticatedOnboarding = ({
  defaultScreenName,
}: AuthenticatedOnboardingProps) => {
  const { isInternetReachable } = useNetInfo();
  const showNoConnection = !isInternetReachable && isInternetReachable !== null;

  if (showNoConnection) {
    return <NoConnection />;
  }

  return (
    <Stack.Navigator
      initialRouteName={defaultScreenName}
      screenOptions={defaultScreenOptions}>
      <Stack.Group>
        <Stack.Screen
          component={OnboardingCreateWorkspaceScreen}
          name="OnboardingCreateWorkspace"
          options={web ? undefined : onboardingScreenOptions}
        />
        <Stack.Screen
          component={OnboardingAddMembersScreen}
          name="OnboardingAddMembers"
          options={web ? undefined : onboardingScreenOptions}
        />
        {web ? null : (
          <Stack.Group>
            <Stack.Screen
              component={ChooseAvatarScreen}
              name="ChooseAvatar"
              options={chooseAvatarScreenOptions}
            />
          </Stack.Group>
        )}
        <Stack.Screen
          component={GetStartedStack}
          name="Onboarding"
          options={defaultScreenOptions}
        />
        <Stack.Screen
          component={CreateAccount}
          name="CreateAccount"
          options={defaultScreenOptions}
        />
        <Stack.Screen
          component={SmsVerification}
          name="SmsVerification"
          options={defaultScreenOptions}
        />
        <Stack.Screen
          component={AddPhoneNumber}
          name="SignIn"
          options={defaultScreenOptions}
        />
        <Stack.Screen
          component={ForgotPasswordStack}
          name="ForgotPasswordStack"
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const RenderedScreen = () => {
  const user = useUserStore(state => state.user);
  if (user) {
    return <AuthenticatedNavigator />;
  }

  return <SignupFlow />;
};

/**
 * Whether the document title handling by react navigation should be enabled. Defaults to true.
 * By default, React Navigation automatically updates the document title on Web to match the title option of the focused screen.
 * We dont want the default title, but a custom title is set in useActivities hook
 */
const documentTitleOptions = { enabled: false };

const Navigator = () => {
  const { isLoading } = useDatastoreBootstrap();
  const isProfileSetup = useUserStore(state => state.isProfileComplete());
  useDelayedNavigation({ isLoading, isProfileSetup });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={documentTitleOptions}>
      <ExperienceProvider>
        <PromptProvider>
          <RenderedScreen />
        </PromptProvider>
      </ExperienceProvider>
    </NavigationContainer>
  );
};

export default Navigator;

export function useNewOnboardingFlow() {
  const isNewOnboardingEnabled = true;
  // Get the onboarding viewed status from MMKV
  const [onboardingViewed] = useMMKVBoolean('onboardingViewed');
  const [onboardingViewedNew] = useMMKVBoolean('onboardingVideoViewed');

  // Named terribly to get around linter wrapping text
  // @ts-expect-error // window exists on web
  const gs = web && window.location.href.includes('getting-started');

  const newOnboardingDefaultScreen = gs
    ? 'OnboardingStepZero'
    : onboardingViewedNew
      ? 'OnboardingStart'
      : web
        ? 'OnboardingStart'
        : 'OnboardingVideo';

  const oldOnboardingDefaultScreen = onboardingViewed
    ? 'HomeScreen'
    : 'Onboarding';

  const defaultScreen: keyof ScreensParams = true
    ? newOnboardingDefaultScreen
    : oldOnboardingDefaultScreen;

  return { defaultScreen, isNewOnboardingEnabled };
}
