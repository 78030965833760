import type { PhoneNumber } from 'react-native-contacts';

export const sortContactNumbers = (a: PhoneNumber, b: PhoneNumber) => {
  if (a.label === 'mobile' && a.number.startsWith('+')) {
    return -1;
  }
  if (b.label === 'mobile' && b.number.startsWith('+')) {
    return 1;
  }
  if (a.label === 'mobile') {
    return -1;
  }
  if (b.label === 'mobile') {
    return 1;
  }
  if (a.number.startsWith('+')) {
    return -1;
  }
  if (b.number.startsWith('+')) {
    return 1;
  }
  return 0;
};
