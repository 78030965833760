import type { GraphQLQuery } from '@aws-amplify/api';
import type { GetOrganisationByUserQuery } from 'src/API';

import type { Roles } from '@/models';
import client from '@/services/API/client';
import type { User } from '@/services/chatUnit/types';
import { getOrganisationByUser } from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

export type OrgMeta = {
  orgId: string;
  orgName: string;
  orgRole: Roles;
};

export type OrganisationUser = User & OrgMeta;

export const getOrganisationsByUser = async () => {
  try {
    const response = await client.graphql<
      GraphQLQuery<GetOrganisationByUserQuery>
    >({
      query: getOrganisationByUser,
    });

    if (response && response.data && response.data.getOrganisationByUser) {
      const orgs = response.data.getOrganisationByUser.map(org => ({
        ...org,
        users: org?.users?.map(user => {
          const parsedUser: User = JSON.parse(JSON.parse(user!));

          const userRole = org.members?.find(
            member => member?.userId === parsedUser.id,
          )?.role;

          const orgMeta: OrgMeta = {
            orgId: org.id,
            orgName: org.name!,
            orgRole: userRole!,
          };

          return { ...parsedUser, ...orgMeta };
        }) as OrganisationUser[],
      }));

      return orgs;
    }

    return [];
  } catch (err) {
    logger.error('getOrganisationsByUser', err);
    return [];
  }
};
