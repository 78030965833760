import React, { useMemo } from 'react';
import { Pressable, type PressableProps, StyleSheet } from 'react-native';

import { Greys } from '@/ui/common/colors';
import { BorderRadius, Shadows } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';

interface Props extends PressableProps {
  bgColor?: Color; // accepted theme color value
  borderColor?: Color; // accepted theme color value
  borderRadius?: BorderRadius;
  // number value for padding
  noShadow?: boolean;
  // accepted border radius value
  padding?: number; // boolean to disable the shadow style
}

const PressBox = ({
  bgColor = Greys.shade0,
  borderColor,
  borderRadius = BorderRadius.xl,
  children,
  id,
  noShadow,
  onPress,
  padding = 5,
  style,
  ...props
}: Props) => {
  const styles = useMemo(() => {
    return StyleSheet.flatten([
      {
        backgroundColor: bgColor,
        borderRadius,
        padding,
      },
      !noShadow && { ...Shadows.sm },
      !!borderColor && { borderColor, borderWidth: 1 },
      style,
    ]);
  }, [bgColor, borderRadius, borderColor, noShadow, padding, style]);

  return (
    <Pressable
      id={`${id}-pressbox`}
      onPress={onPress}
      style={styles}
      testID={`${id}-pressbox`}
      {...props}>
      {children}
    </Pressable>
  );
};

export default PressBox;
