import React, { StyleSheet, View } from 'react-native';

import useTranslation from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Neutrals } from '@/ui/common/colors';

type Props = {
  hidden?: boolean;
};

export const EmailInfoMessage = ({ hidden }: Props) => {
  const { translate } = useTranslation();
  if (hidden) {
    return null;
  }

  return (
    <View style={styles.container}>
      <AppText style={styles.text}>
        {translate('login_email_otp_delay_info')}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 9,
    backgroundColor: Neutrals.shade100,
    marginVertical: 10,
    padding: 10,
    maxWidth: 314,
    width: '100%',
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
  },
});
