import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgEnvelope = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M100 21.875c0-5.174-4.201-9.375-9.375-9.375H9.375C4.201 12.5 0 16.701 0 21.875v56.25C0 83.299 4.201 87.5 9.375 87.5h81.25c5.174 0 9.375-4.201 9.375-9.375zM9.375 27.602v50.523h81.25V27.602L61.186 52.836a17.188 17.188 0 0 1-22.371 0zm7.724-5.727 27.817 23.843a7.811 7.811 0 0 0 10.168 0l27.817-23.843z" />
  </Svg>
);
export default SvgEnvelope;
