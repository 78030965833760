import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import type { Details } from '../components/settings/types';

import { useChatUnit } from '@/domain/chatUnit/hooks/useTable';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import type { ChatUnit } from '@/services/types';
import { shallowEqual } from '@/utilities/object/shallowEqual';

type State = {
  errors: null;
  isDirty: boolean;
  isLoading: boolean;
  chatUnit: ChatUnit | null;
};

type Props = {
  id: string;
};

export const useSettings = ({ id }: Props) => {
  useStatusBarHandler('light-content');
  const { goBack } = useNavigation();

  const userId = useUserStore(useShallow(state => state.user?.id));

  const { loading, chatUnit, updateChatUnitDetails } = useChatUnit(id);

  const [state, setState] = useState<State>({
    errors: null,
    isDirty: false,
    isLoading: loading || false,
    chatUnit: chatUnit || null,
  });

  const updateDetailsHandler = (input: Details) => {
    if (state.chatUnit) {
      setState({
        ...state,
        isDirty: !shallowEqual(chatUnit, { ...state.chatUnit, ...input }),
        chatUnit: { ...state.chatUnit, ...input },
      });
    }
  };

  const cancelUpdate = () => {
    if (chatUnit) {
      setState({
        errors: null,
        isDirty: false,
        isLoading: false,
        chatUnit: chatUnit,
      });
    }
  };

  const saveUpdate = () => {
    if (state.chatUnit) {
      setState({
        ...state,
        isLoading: true,
      });
      updateChatUnitDetails(state.chatUnit);
      setState({
        ...state,
        isDirty: false,
      });
      goBack();
    }
  };

  const [chatUsers, allChatUsers] = useTableUsersStore(
    useShallow(store => [
      store.getTableUsersForTable(id),
      store.getAllTableUsersForTable(id),
    ]),
  );

  const isOwner = chatUnit?.owner === userId || false;
  const myChatUser = chatUsers.find(user => user.id === userId);
  const otherChatUsers = chatUsers.filter(user => user.id !== userId);
  const otherChatUsersIncludingArchived = allChatUsers.filter(
    user => user.id !== userId,
  );

  return {
    cancelUpdate,
    isDirty: state.isDirty,
    isLoading: state.isLoading,
    isOwner,
    myChatUser,
    otherChatUsers,
    otherChatUsersIncludingArchived,
    saveUpdate,
    chatUnit: state.chatUnit,
    chatUsers,
    updateDetailsHandler,
  };
};
