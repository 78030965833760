export enum NotificationType {
  INCOMING_MESSAGE = 'INCOMING_MESSAGE',
  INVITE_TABLE = 'INVITE_TABLE',
}

type NotificationPayloadIncomingMessage = {
  conversationId: string;
  messageBody: string;
  messageCreatedAt: string;
  messageId: string;
  messageTitle: string;
  notificationType: NotificationType.INCOMING_MESSAGE;
  senderUserId: string;
  senderUsername: string;
  chatUnitId: string;
  chatUnitTitle: string;
};

type NotificationPayloadChatUnitInvite = {
  inviteId: string;
  messageBody: string;
  messageTitle: string;
  notificationType: NotificationType.INVITE_TABLE;
};
// Append more types here to handle other types of notifications using the
// '|' operator
export type Notification =
  | NotificationPayloadIncomingMessage
  | NotificationPayloadChatUnitInvite;
