import isEqual from 'fast-deep-equal';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import moment from 'moment';
import type { Post } from './graphql/generated/graphql';

type State = {
  lastReadDiscoveryPostPublishedAtInMillis: number;
  posts: Post[];
};

type Actions = {
  clearState: () => void;
  setLastReadDiscoveryPostPublishedAtInMillis: (time: number) => void;
  setPosts: (posts: Post[]) => void;
  getUnreadCriticalPosts: () => Post[];
  getUnreadPostsCount: () => number;
  getUnreadPostSections: () => string[];
};

const INITIAL_STATE: State = {
  lastReadDiscoveryPostPublishedAtInMillis: 0,
  posts: [],
};

const useCMSStore = createWithEqualityFn<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...INITIAL_STATE,
      setLastReadDiscoveryPostPublishedAtInMillis: time =>
        set(state => {
          state.lastReadDiscoveryPostPublishedAtInMillis = time;
        }),
      setPosts: posts =>
        set(state => {
          state.posts = posts;
        }),
      getUnreadCriticalPosts: () => {
        // Should be critical and unread
        return get()
          .posts.filter(post => post.critical)
          .filter(post => {
            const isUnread = moment(
              post.publishDate || post.sys.publishedAt,
            ).isAfter(get().lastReadDiscoveryPostPublishedAtInMillis);
            return isUnread;
          });
      },
      getUnreadPostsCount: () => {
        return get().posts.filter(post =>
          moment(post.publishDate || post.sys.publishedAt).isAfter(
            get().lastReadDiscoveryPostPublishedAtInMillis,
          ),
        ).length;
      },
      getUnreadPostSections: () => {
        const unreadPosts = get().posts.filter(post =>
          moment(post.publishDate || post.sys.publishedAt).isAfter(
            get().lastReadDiscoveryPostPublishedAtInMillis,
          ),
        );
        return Array.from(
          new Set(unreadPosts.map(post => post.type?.name || '')),
        );
      },
      clearState: () =>
        set(state => {
          state.lastReadDiscoveryPostPublishedAtInMillis = 0;
          state.posts = [];
        }),
    })),
    {
      name: 'cms-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
    },
  ),
  isEqual,
);

export default useCMSStore;
