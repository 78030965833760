import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import type { Contact } from '../../state/useContactStore';

import ChatUnitIcon from '@/assets/directory/chatunit.svg';
import CheckIcon from '@/assets/svg/check_icon.svg';
import Avatar from '@/components/avatar';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import type { LazyOrganisation } from '@/models';
import { brandTeal, brandTealHighlight, peppermint } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app/elements';
import { Greys, Neutrals, Status } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';

interface ContactItemProps {
  isInvited?: boolean;
  isSelected: boolean;
  item: string | Contact;
  onContactPress: (item: Contact) => void;
  searchQuery: string;
  canSelect?: boolean;
  isLimitFull?: boolean;
}

type HighlightSearchTermProps = {
  term: string;
  text: string;
};

const ContactItem = React.memo(
  ({
    isInvited,
    isSelected,
    item,
    onContactPress,
    searchQuery,
    canSelect = true,
    isLimitFull,
  }: ContactItemProps) => {
    const { translate } = useTranslations();
    const organisations = useOrganisations();

    if (typeof item === 'string') {
      return (
        <View style={styles.header}>
          <AppText style={styles.headerTitle} type="primary700" size={12}>
            {item}
          </AppText>
        </View>
      );
    }

    const contactOrgs: LazyOrganisation[] = [];

    organisations.forEach(org => {
      if (org.allowedUserIds?.indexOf(item.userId!) !== -1) {
        contactOrgs.push(org);
      }
    });

    const hasChatUnits = item.commonChatUnitIds?.length;

    const highlightSearchTerm = ({ term, text }: HighlightSearchTermProps) => {
      const parts = text.split(new RegExp(`(${term})`, 'gi'));
      return parts.map((part, index) =>
        part.toLowerCase() === term.toLowerCase() ? (
          <AppText
            key={index}
            size={14}
            type="primary700"
            color={Colors.neutral80}>
            {part}
          </AppText>
        ) : (
          <AppText
            key={index}
            size={14}
            type="primary600"
            color={Colors.neutral80}>
            {part}
          </AppText>
        ),
      );
    };

    return (
      <>
        <TouchableOpacity
          disabled={isInvited}
          onPress={() => onContactPress(item)}>
          <View style={styles.row}>
            <View style={styles.leftSide}>
              <Avatar
                size={40}
                displayName={getName(item, 'full')}
                imageKey={item.avatar}
                bgColor={Neutrals.shade100}
                fontSize={12}
                fontType="primary700"
              />
              <Spacer width={15} />
              <View>
                {!searchQuery ? (
                  <AppText size={14} type="primary600" color={Colors.neutral80}>
                    {`${getName(item, 'first')} `}
                    <AppText
                      type={searchQuery ? 'primary600' : 'primary700'}
                      size={14}
                      color={Colors.neutral80}>
                      {getName(item, 'last')}
                    </AppText>
                  </AppText>
                ) : (
                  <AppText size={14} type="primary600" color={Colors.neutral80}>
                    {highlightSearchTerm({
                      term: searchQuery.trim(),
                      text: `${getName(item, 'full')}`.trim(),
                    })}
                  </AppText>
                )}
                {item.phoneNumbers?.length ? (
                  <AppText
                    style={styles.phoneNumber}
                    size={12}
                    type="primary400"
                    color={Colors.neutral70}>
                    {item.phoneNumbers[0].number}
                  </AppText>
                ) : null}
              </View>
            </View>
            <View style={styles.rightSide}>
              {hasChatUnits ? <ChatUnitIcon height={20} width={20} /> : null}
              {contactOrgs.map(org => (
                <>
                  <Spacer width={15} />
                  <Avatar
                    size={20}
                    displayName={org.name!}
                    imageKey={org.image!}
                    style={{ borderRadius: BorderRadius.xs }}
                    fontSize={8}
                  />
                </>
              ))}
              {canSelect && (
                <View>
                  {isInvited ? (
                    <View style={styles.pill}>
                      <AppText
                        color={Status.success300}
                        size={9}
                        type="primary700">
                        {translate('already_invited')}
                      </AppText>
                    </View>
                  ) : isSelected ? (
                    <View style={styles.checkboxContainer}>
                      <View style={[styles.checkBox, styles.checkBoxActive]}>
                        <CheckIcon fill={Greys.shade0} />
                      </View>
                    </View>
                  ) : (
                    <View style={styles.checkboxContainer}>
                      <View
                        style={[
                          styles.checkBox,
                          isLimitFull
                            ? { backgroundColor: Colors.neutral30 }
                            : {},
                        ]}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </>
    );
  },
);

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 16,
    height: 32,
    marginRight: 16,
    width: 32,
  },
  avatarInitials: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 12,
    fontWeight: '700',
  },
  checkBox: {
    borderColor: Greys.shade400,
    borderRadius: 6,
    borderWidth: 1,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBoxActive: {
    backgroundColor: brandTeal,
    borderColor: brandTealHighlight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxContainer: { marginLeft: 15 },
  phoneNumber: {
    marginTop: 5,
  },
  row: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  header: {
    backgroundColor: Colors.neutral10,
    paddingLeft: 20,
    paddingVertical: 4,
  },
  headerTitle: {
    color: Colors.neutral80,
  },
  leftSide: { alignItems: 'center', flex: 1, flexDirection: 'row' },
  rightSide: {
    flexDirection: 'row',
  },
  noAvatar: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 16,
    height: 32,
    justifyContent: 'center',
    marginRight: 16,
    width: 32,
  },
  pill: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    borderColor: Status.success300,
    borderRadius: 100,
    borderWidth: 1,
    justifyContent: 'center',
    marginLeft: 15,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  selectedRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 30,
  },
  selectedTitle: {
    color: peppermint,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    marginLeft: 8,
  },
});

export default ContactItem;
