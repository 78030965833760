import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgOctagon = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="m44.401 1.114-25.01 10.359a14.63 14.63 0 0 0-7.918 7.918L1.114 44.401a14.63 14.63 0 0 0 0 11.198l10.359 25.01a14.63 14.63 0 0 0 7.918 7.918l25.011 10.36a14.634 14.634 0 0 0 11.196 0l25.012-10.36a14.633 14.633 0 0 0 7.917-7.917l10.36-25.012a14.634 14.634 0 0 0 0-11.196l-10.36-25.011a14.63 14.63 0 0 0-7.918-7.918L55.599 1.114a14.63 14.63 0 0 0-11.198 0m4.2 10.137a3.659 3.659 0 0 1 2.799 0l25.01 10.36a3.658 3.658 0 0 1 1.98 1.979L88.749 48.6a3.661 3.661 0 0 1 0 2.8L78.39 76.41a3.666 3.666 0 0 1-1.98 1.98L51.401 88.749a3.664 3.664 0 0 1-2.801 0L23.591 78.39a3.656 3.656 0 0 1-1.98-1.98L11.251 51.4a3.659 3.659 0 0 1 0-2.799l10.36-25.011a3.656 3.656 0 0 1 1.979-1.979z" />
  </Svg>
);
export default SvgOctagon;
