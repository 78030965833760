import React, { type FC, type ReactNode, useMemo } from 'react';
import { Pressable, StyleSheet, View, type ViewStyle } from 'react-native';

import type { Colors } from '@/domain/theme/Colors';

interface SectionProps {
  children?: ReactNode;
  onLayout?: (event: any) => void;
  bg?: Colors | 'transparent';
  br?: number;
  bc?: Colors | 'transparent';
  bw?: number;
  style?: ViewStyle;
  /* Convenience shortcut styles */
  // Align Items
  aic?: boolean; // center
  aifs?: boolean; // flex-start
  aife?: boolean; // flex-end
  ais?: boolean; // stretch
  // Justify Content
  jcc?: boolean; // center
  jcsb?: boolean; // space-between
  jcsa?: boolean; // space-around
  jcse?: boolean; // space-evenly
  jcfe?: boolean; // flex-end
  jcfs?: boolean; // flex-start
  // Flex Direction
  row?: boolean;
  // Flex Grow
  f1?: boolean; // flex: 1
  fg1?: boolean; // flexGrow: 1
  // Flex Gap
  gap?: number;
  // Flex Wrap
  fw?: boolean; // flex-wrap: wrap
  // Width
  w100?: boolean; // width: 100%
  w0?: boolean; // width: 0
  h100?: boolean; // height: 100%
  maxW?: number;
  // Align Self
  asc?: boolean; // center
  asfs?: boolean; // flex-start
  asfe?: boolean; // flex-end
  asb?: boolean; // baseline
  ass?: boolean; // stretch
  // Padding
  p?: number;
  ph?: number;
  pv?: number;
  pr?: number;
  pl?: number;
  pt?: number;
  pb?: number;
  // Margin
  m?: number;
  mh?: number;
  mv?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;

  onPress?: () => void;
}

/**
 * Utility view component to increase productivity
 *
 * @example
 * // Flex direction row, horizontal at 8
 * // Justify content center, align items center
 * // and flex of 1
 * <Section row pl={8} pr={8} jcc aic fg1>
 *   {children}
 * </Section>
 */
const Section: FC<SectionProps> = ({
  bg = 'transparent',
  bc,
  bw,
  style,
  children,
  // Convenience shortcut styles
  // Align Items
  aic,
  aifs,
  aife,
  ais,
  // Justify Content
  jcc,
  jcsb,
  jcsa,
  jcse,
  jcfe,
  jcfs,
  // Flex Direction
  row,
  // Flex wrap
  fw,
  // Flex Grow
  f1,
  fg1,
  // Flex gap
  gap,
  // Width
  w100,
  h100,
  w0,
  maxW,
  // Align Self
  asc,
  asfs,
  asfe,
  asb,
  ass,
  // Padding
  p,
  ph,
  pv,
  pr,
  pl,
  pt,
  pb,
  // Margin
  m,
  mh,
  mv,
  mr,
  ml,
  mt,
  mb,
  // Border Radius
  br = undefined,
  onPress = undefined,
  onLayout = undefined,
}) => {
  const processedStyles = useMemo(() => {
    const appliedStyles: ViewStyle = {
      backgroundColor: bg || undefined,
      alignItems: aic
        ? 'center'
        : aifs
          ? 'flex-start'
          : aife
            ? 'flex-end'
            : ais
              ? 'stretch'
              : undefined,
      justifyContent: jcc
        ? 'center'
        : jcsb
          ? 'space-between'
          : jcsa
            ? 'space-around'
            : jcse
              ? 'space-evenly'
              : jcfe
                ? 'flex-end'
                : jcfs
                  ? 'flex-start'
                  : undefined,
      flexDirection: row ? 'row' : undefined,
      flex: f1 ? 1 : undefined,
      flexGrow: fg1 ? 1 : undefined,
      width: w100 ? '100%' : w0 ? 0 : undefined,
      maxWidth: maxW ? maxW : undefined,
      height: h100 ? '100%' : undefined,
      alignSelf: asc
        ? 'center'
        : asfs
          ? 'flex-start'
          : asfe
            ? 'flex-end'
            : asb
              ? 'baseline'
              : ass
                ? 'stretch'
                : undefined,
      padding: p,
      paddingHorizontal: ph,
      paddingVertical: pv,
      paddingRight: pr,
      paddingLeft: pl,
      paddingTop: pt,
      paddingBottom: pb,
      margin: m,
      marginHorizontal: mh,
      marginVertical: mv,
      marginRight: mr,
      marginLeft: ml,
      marginTop: mt,
      marginBottom: mb,
      flexWrap: fw ? 'wrap' : undefined,
      borderRadius: br,
      gap,
      borderColor: bc,
      borderWidth: bw,
    };
    /**
     * Combines two styles such that 'style' will override any of the default or enforced styles.
     * If either style is falsy, the other one is returned without allocating an array,
     * saving allocations and maintaining reference equality for PureComponent checks.
     */
    return StyleSheet.compose(appliedStyles, style);
  }, [
    aic,
    aife,
    aifs,
    ais,
    asb,
    asc,
    asfe,
    asfs,
    ass,
    bc,
    bg,
    br,
    bw,
    f1,
    fg1,
    fw,
    gap,
    h100,
    jcc,
    jcfe,
    jcfs,
    jcsa,
    jcsb,
    jcse,
    m,
    mb,
    mh,
    ml,
    mr,
    mt,
    mv,
    p,
    pb,
    ph,
    pl,
    pr,
    pt,
    pv,
    row,
    style,
    w0,
    w100,
    maxW,
  ]);

  const Component = useMemo(() => {
    // Render view or pressable
    return onPress && typeof onPress === 'function' ? Pressable : View;
  }, [onPress]);

  return (
    <Component onPress={onPress} style={processedStyles} onLayout={onLayout}>
      {children}
    </Component>
  );
};

export default Section;
