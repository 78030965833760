import { DataStore } from 'aws-amplify/datastore';

import { Conversation } from '../../../models';

import { factory } from '@/services/factory/factory';

export const getConversations = async (
  chatUnitId?: string,
  lastUpdated?: Date | null,
) => {
  let results = [];
  if (lastUpdated || chatUnitId) {
    results = await DataStore.query(Conversation, conversation => {
      return conversation.and(c => {
        const predicates = [];
        if (lastUpdated) {
          predicates.push(c.updatedAt.gt(lastUpdated.toISOString()));
        }
        if (chatUnitId) {
          predicates.push(c.chatUnitId.eq(chatUnitId));
        }

        return predicates;
      });
    });
  } else {
    results = await DataStore.query(Conversation);
  }

  results = results.map(conversation => factory.conversation(conversation));

  return results;
};
