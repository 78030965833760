import React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { Greys } from '@/ui/common/colors';

export const AddDirectoryIcon = ({ size }: { size: number }) => (
  <View style={{ height: size, width: size }}>
    <Svg
      height="100%"
      preserveAspectRatio="xMinYMin slice"
      width="100%"
      fill={Greys.shade600}
      viewBox="0 0 25 25">
      <Path
        clipRule="evenodd"
        d="M2 19a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14Zm18 0a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v8.011h2.395L14 9.864l1.605 2.147H18V4h1a1 1 0 0 1 1 1v14ZM16 4h-4v5.336l2-2.676 2 2.676V4Z"
        fill={Greys.shade600}
        fillRule="evenodd"
      />
    </Svg>
  </View>
);
