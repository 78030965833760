import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';

import Avatar from '@/components/avatar';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import SvgCross from '@/ui/app/elements/Icon/icons/Cross';
import { Greys, Neutrals } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const AnimatedButton = Animated.createAnimatedComponent(TouchableOpacity);

const SelectedContacts = ({
  onContactPress,
  selectedContacts,
  searchQuery,
  setSearchQuery,
  onSubmitEditing,
}: {
  onContactPress: (contact: ContactInput) => void;
  selectedContacts: ContactInput[];
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  onSubmitEditing?: () => void;
}) => {
  const { translate } = useTranslations();

  return (
    <ScrollView persistentScrollbar={true} style={styles.container}>
      <View style={styles.selectedContactsRow}>
        {selectedContacts.map((contact: ContactInput) => {
          return (
            <AnimatedButton
              entering={FadeIn}
              key={contact.id}
              onPress={() => onContactPress(contact)}
              style={styles.selectedContactPill}>
              <Avatar
                size={24}
                displayName={getName(contact, 'full')}
                imageKey={contact.avatar}
                bgColor={Greys.shade200}
                fontSize={12}
                fontType="primary700"
              />
              <View style={styles.pillTextContainer}>
                <AppText
                  style={styles.pillText}
                  size={14}
                  type="primary600"
                  color={Colors.neutral80}>
                  {getName(contact, 'first')}
                </AppText>
              </View>
              <SvgCross />
            </AnimatedButton>
          );
        })}
        <TextInput
          placeholder={
            selectedContacts.length === 0
              ? searchQuery.length > 0
                ? ''
                : translate('search_contacts_label')
              : ''
          }
          style={[
            styles.searchbar,
            searchQuery.length > 0 && styles.searchbarActive,
          ]}
          onChangeText={setSearchQuery}
          value={searchQuery}
          onBlur={() => (web ? null : setSearchQuery(''))}
          onSubmitEditing={onSubmitEditing}
          placeholderTextColor={Colors.neutral70}
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: Greys.shade200,
    borderRadius: 12,
    height: 24,
    width: 24,
  },
  avatarInitials: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 8,
    fontWeight: '700',
  },
  bold: { color: Greys.shade999, fontWeight: '700' },
  container: {
    alignSelf: 'flex-start',
    backgroundColor: Neutrals.shade100,
    borderRadius: 12,
    maxHeight: 110,
    width: '100%',
  },
  noAvatar: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 12,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  pillText: {
    paddingLeft: 3,
  },
  pillTextContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginHorizontal: 5,
  },
  selectedContactPill: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    borderRadius: 12,
    elevation: 2,
    flexDirection: 'row',
    height: 24,
    justifyContent: 'space-between',
    margin: 5,
    marginRight: 2,
    paddingEnd: 5,
    paddingRight: 10,
    shadowColor: '#000',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.6,
    shadowRadius: 1,
    width: 'auto',
  },
  selectedContactsRow: {
    color: Greys.shade999,
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: 44,
    padding: 5,
    width: '90%',
  },
  searchbar: {
    paddingHorizontal: 10,
    height: 24,
    marginTop: 5,
    padding: 0,
    flex: 1,
    fontFamily: 'OpenSans-SemiBold',
    fontWeight: '600',
    fontSize: 14,
    color: Colors.neutral80,
  },
  searchbarActive: {
    flex: 0,
    marginLeft: 10,
    paddingHorizontal: 8,
    backgroundColor: Colors.neutral0,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.primaryLight,
  },
});
export default SelectedContacts;
