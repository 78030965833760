import { downloadData } from 'aws-amplify/storage';
import { Image } from 'expo-image';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  type ImageURISource,
  type LayoutChangeEvent,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import ReactNativeBlobUtil from 'react-native-blob-util';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Share from 'react-native-share';
import {
  ResumableZoom,
  type Source,
  getAspectRatioSize,
  useImageResolution,
} from 'react-native-zoom-toolkit';

import CircleSpinnerWithLogo from './spinner/CircleSpinnerWithLogo';

import closeIcon from '@/assets/chatview/close.png';
import shareIcon from '@/assets/chatview/share.png';
import shareIconDisabled from '@/assets/chatview/shareIconDisabled.png';
import { useTheme } from '@/domain/theme/hooks/useTheme';
import type { MainStackScreenProps } from '@/navigation/types';
import { logger } from '@/services/logger/logger';
import { android, ios, web } from '@/utilities/platform';

type Props = MainStackScreenProps<'ImageExpand'>;

const ImageExpandView = ({ route, navigation }: Props) => {
  const { width } = useWindowDimensions();
  const [webWidth, setWebWidth] = useState(0);
  const { source: imageSource, imageKey } = route.params;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);

  const updateWebWidth = useCallback(
    (nativeEvent: LayoutChangeEvent) => {
      if (web) {
        setWebWidth(nativeEvent.nativeEvent.layout.width);
      }
    },
    [setWebWidth],
  );
  // Gets the resolution of image
  const {
    isFetching,
    error: resolutionFetchError,
    resolution,
  } = useImageResolution(imageSource as Source);

  const handleShare = useCallback(async () => {
    try {
      setLoading(true);
      // First time, its an https link, so we need to fetch the blob
      // Second time, its a file:// link, so we need to read the file
      // The workaround is needed only for android
      const uri = (imageSource as ImageURISource)?.uri;
      if (!uri) {
        setLoading(false);
        return;
      }
      // Fetch blob and share image
      if (ios) {
        const currentDateFormatted = moment().format('YYYY-MM-DD-HH-mm-ss');

        await Share.open({
          url: uri,
          filename: `8seats_${currentDateFormatted}`,
          failOnCancel: false,
        });
      } else if (android) {
        let res;
        if (uri.startsWith('https')) {
          const response = await fetch(uri);
          res = await response.blob();
        } else {
          res = await ReactNativeBlobUtil.fs.readFile(uri, 'base64');
        }
        let extension = imageKey?.split('.').pop()!;
        if (extension?.includes('jpg')) {
          extension = 'jpeg';
        }
        const base64 = `data:image/${extension};base64,${res}`;
        const fileName = imageKey?.split('/').pop();

        await Share.open({
          url: base64,
          type: `image/${extension}`,
          filename: `${fileName}`,
          failOnCancel: false,
          showAppsToView: true,
        });
      } else if (web) {
        const downloadState = downloadData({ key: imageKey! });
        const { body } = await downloadState.result;
        const blob = await body.blob();
        const href = URL.createObjectURL(blob);
        // Reason: This is a web specific code and we need to download the image
        // @ts-ignore
        const aElement = document.createElement('a');
        const fileNameWithExtension = imageKey?.split('/').pop();
        aElement.setAttribute('download', fileNameWithExtension);
        aElement.setAttribute('target', '_blank');
        aElement.href = href;
        aElement.click();
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      logger.error('Error while sharing:', JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [imageKey, imageSource]);

  if (isFetching || !resolution || resolutionFetchError) {
    return null;
  }

  // An utility function to get the size without compromising the aspect ratio
  const imageSize = getAspectRatioSize({
    aspectRatio: resolution.width / resolution.height,
    width: web ? webWidth : width,
  });

  return (
    <View style={styles.container} onLayout={updateWebWidth}>
      <View style={styles.iconRow}>
        <Pressable onPress={handleShare} disabled={loading}>
          <Image
            style={styles.icon}
            source={loading ? shareIconDisabled : shareIcon}
          />
        </Pressable>
        <Pressable onPress={navigation.goBack}>
          <Image style={styles.icon} source={closeIcon} />
        </Pressable>
      </View>
      <ResumableZoom tapsEnabled>
        <Image style={imageSize} source={imageSource} />
      </ResumableZoom>
      {loading && (
        <View style={styles.loadingContainer}>
          <CircleSpinnerWithLogo />
        </View>
      )}
    </View>
  );
};

const useStyles = () => {
  const insets = useSafeAreaInsets();

  const {
    theme: {
      ui: {
        container: {
          variants: {
            contrast: { backgroundColor },
          },
        },
      },
    },
  } = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          backgroundColor,
        },

        icon: {
          width: 40,
          height: 40,
          resizeMode: 'contain',
        },
        iconRow: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 4,
          position: 'absolute',
          top: insets.top + 16,
          right: insets.right + 16,
          zIndex: 2,
        },
        loadingContainer: {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',

          zIndex: 3,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }),
    [backgroundColor, insets.right, insets.top],
  );
};

export default ImageExpandView;
