import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { postHogTrack } from '@/context/Experience';
import { logger } from '@/services/logger/logger';

type Prompt = {
  title: string;
  body: string;
  onConfirm?: () => Promise<void>;
  onCancel?: () => Promise<void>;
};

type State = {
  prompt: null | Prompt;
};

type Actions = {
  showPrompt: (newPrompt: Prompt) => void;
  clearPrompt: () => void;
};

const newState = {
  prompt: null,
};

export const usePromptStore = create<State & Actions>()(
  immer((set, get) => ({
    ...newState,
    showPrompt: newPrompt => {
      if (get().prompt) {
        logger.warn('Prompt already visible');
        return; // skip infinite rerender loop
      }

      set({ prompt: newPrompt });
      postHogTrack('Prompt Opened', {
        title: newPrompt.title,
        body: newPrompt.body,
      });
    },
    clearPrompt: () => {
      set({ prompt: null });
    },
  })),
);
