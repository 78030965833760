/**
 * Sets of border radius that are used in the app components
 * like buttons, icons, etc.
 */
enum BorderRadius {
  xs = 3,
  sm = 6,
  md = 9,
  lg = 12,
  xl = 15,
  full = 9999,
}

export default BorderRadius;
