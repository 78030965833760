import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import GridIcon from '@/assets/svg/grid-icon.svg';
import ListIcon from '@/assets/svg/list-icon.svg';
import MembersIcon from '@/assets/svg/members-icon.svg';
import type { ListViewMode } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  heading: {
    color: Greys.shade0,
    fontSize: 16,
  },
  headingContaner: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 10,
  },
  inactiveToggle: {
    opacity: 0.3,
  },
  layoutToggleContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'flex-end',
  },
});

type Props = {
  count: number;
  setViewMode: (viewMode: ListViewMode) => void;
  viewMode: ListViewMode;
};

export const Toolbar = ({ count, setViewMode, viewMode }: Props) => {
  const { translate } = useTranslations();

  return (
    <View style={styles.container}>
      <View style={styles.headingContaner}>
        <MembersIcon style={styles.icon} />
        <Text style={styles.heading}>
          {translate('Room')} {translate('Members')} ({count})
        </Text>
      </View>

      <View style={styles.layoutToggleContainer}>
        <Pressable
          onPress={() => setViewMode('grid')}
          style={[viewMode !== 'grid' && styles.inactiveToggle]}>
          <GridIcon />
        </Pressable>

        <Pressable
          onPress={() => setViewMode('list')}
          style={[viewMode !== 'list' && styles.inactiveToggle]}>
          <ListIcon />
        </Pressable>
      </View>
    </View>
  );
};
