import type { InviteStatus } from '../types/types';

import { getInvite } from '@/services/datastore/invite/getInvite';
import { updateInviteStatus } from '@/services/datastore/invite/updateInvite';

export const updateInviteBasedOnStatus = async (
  inviteId: string,
  status: InviteStatus,
) => {
  const invite = await getInvite(inviteId);

  await updateInviteStatus(invite, status);
};
