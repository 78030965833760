import { validateInput } from './validateInput';

import type { Conversation } from '@/services/types';

export const conversationFactory = (input: any): Conversation => {
  validateInput(input, ['conversationId', 'chatUnitId'], 'Conversation');

  return {
    allowedUserIds: input.allowedUserIds ?? [],
    conversationId: input.conversationId,
    owner: input.owner ?? undefined,
    chatUnitId: input.chatUnitId!,
    chatUnitUserIds: input.chatUnitUserIds ?? [],
    createdAt: input.createdAt ?? undefined,
    updatedAt: input.updatedAt ?? undefined,
  };
};
