import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Linking,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { runOnJS } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Camera,
  type PhotoFile,
  type Point,
  useCameraDevice,
  useCameraPermission,
} from 'react-native-vision-camera';

import RotateCameraIcon from '@/assets/svg/camera-rotate-icon.svg';
import CloseIcon from '@/assets/svg/close-icon.svg';
import { AppText } from '@/ui/app/elements';
import { Brand, Greys, Misc } from '@/ui/common/colors';

type Props = {
  isOpen: boolean;
  toggleCameraVisibility: () => void;
  onPhotoTaken: (photo: PhotoFile) => void;
};

const CameraModal = ({
  isOpen,
  toggleCameraVisibility,
  onPhotoTaken,
}: Props) => {
  const camera = useRef<Camera>(null);
  const [deviceType, setDeviceType] = useState<'front' | 'back'>('back');
  // Automatically select the device type based on the platform
  // Get the best available device type
  const device = useCameraDevice(deviceType);

  const { hasPermission, requestPermission } = useCameraPermission();

  // Request permissions if need be
  useEffect(() => {
    if (!hasPermission) {
      requestPermission();
    }
  }, [hasPermission, requestPermission]);

  const styles = useStyles();

  // Take a photo and send it off to the RN App
  const takePicture = useCallback(async () => {
    if (camera.current) {
      const photo = await camera.current.takePhoto();
      onPhotoTaken(photo);
      toggleCameraVisibility();
    }
  }, [onPhotoTaken, toggleCameraVisibility]);

  // Toggle front and back cameras
  const onPressChangeCamera = useCallback(() => {
    setDeviceType(d => (d === 'back' ? 'front' : 'back'));
  }, []);

  // Focus gesture related stuff
  // ...
  const focus = useCallback(
    (point: Point) => {
      const c = camera.current;
      if (c == null) {
        return;
      }
      if (device?.supportsFocus) {
        // TODO: Show a UI focus animated ring circle thingy
        c.focus(point);
      }
    },
    [device?.supportsFocus],
  );

  const gesture = Gesture.Tap().onEnd(({ x, y }) => {
    runOnJS(focus)({ x, y });
  });

  if (!isOpen) {
    return null;
  }

  if (!device || !hasPermission) {
    return (
      <Modal
        animationType="slide"
        onRequestClose={toggleCameraVisibility}
        transparent={false}
        visible={isOpen}>
        <View style={errorStyles.container}>
          <AppText textAlign="center">
            Camera Not Found! Ensure the app has camera access or your device
            has a camera.
          </AppText>
          <TouchableOpacity onPress={() => Linking.openSettings()}>
            <AppText size={16} type="primary700" color={Brand.primary100}>
              Check permissions
            </AppText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={toggleCameraVisibility}
            style={styles.close}>
            <CloseIcon fill={Greys.shade999} />
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }

  return (
    <Modal
      animationType="slide"
      onRequestClose={toggleCameraVisibility}
      transparent={false}
      visible={isOpen}>
      <View style={styles.modalContainer}>
        <TouchableOpacity onPress={toggleCameraVisibility} style={styles.close}>
          <CloseIcon fill={Greys.shade0} />
        </TouchableOpacity>
        <View style={styles.row}>
          <TouchableOpacity onPress={takePicture} style={styles.captureBorder}>
            <View style={styles.capture} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onPressChangeCamera}
            style={styles.reverseCam}>
            <RotateCameraIcon fill={Greys.shade0} height={30} width={30} />
          </TouchableOpacity>
        </View>
        <GestureDetector gesture={gesture}>
          <Camera
            ref={camera}
            style={StyleSheet.absoluteFill}
            device={device}
            video={false}
            audio={false}
            isActive={true}
            enableZoomGesture={true}
            lowLightBoost={device.supportsLowLightBoost}
            photo={true}
          />
        </GestureDetector>
      </View>
    </Modal>
  );
};

const useStyles = () => {
  const insets = useSafeAreaInsets();
  return useMemo(
    () =>
      StyleSheet.create({
        close: {
          position: 'absolute',
          top: 20 + insets.top,
          left: 20 + insets.right,
          zIndex: 2,
        },
        modalContainer: {
          flex: 1,
          alignItems: 'center',
        },
        capture: {
          backgroundColor: Greys.shade0,
          borderRadius: 100,
          height: 50,
          width: 50,
        },
        captureBorder: {
          alignItems: 'center',
          backgroundColor: Misc.transparent,
          borderRadius: 140,
          height: 70,
          justifyContent: 'center',
          width: 70,
          borderWidth: 2,
          borderColor: Greys.shade0,
          alignSelf: 'center',
        },
        reverseCam: {
          alignItems: 'center',
          backgroundColor: `${Greys.shade999}33`,
          borderRadius: 100,
          height: 50,
          justifyContent: 'center',
          alignSelf: 'center',
          width: 50,
          position: 'absolute',
          right: 20 + insets.right,
          zIndex: 2,
        },
        row: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 20 + insets.bottom,
          width: '100%',
          zIndex: 2,
        },
      }),
    [insets.bottom, insets.right, insets.top],
  );
};

const errorStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
});

export default CameraModal;
