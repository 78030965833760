import { ChatUnitColorId } from '../../API';

import { UserThemes } from '@/ui/common/colors';
import type { Color } from '@/ui/common/types/color';

export type TableColor = {
  color: Color;
  id: ChatUnitColorId;
  source?: string;
};

export const tableColors = [
  {
    color: UserThemes.choice1,
    id: ChatUnitColorId.BLACK,
  },
  {
    color: UserThemes.choice2,
    id: ChatUnitColorId.RED,
  },
  {
    color: UserThemes.choice3,
    id: ChatUnitColorId.ORANGE,
  },
  {
    color: UserThemes.choice4,
    id: ChatUnitColorId.LIME,
  },
  {
    color: UserThemes.choice5,
    id: ChatUnitColorId.GREEN,
  },
  {
    color: UserThemes.choice6,
    id: ChatUnitColorId.TURQUOISE,
  },
  {
    color: UserThemes.choice7,
    id: ChatUnitColorId.SKY,
  },
  {
    color: UserThemes.choice8,
    id: ChatUnitColorId.BLUE,
  },
  {
    color: UserThemes.choice9,
    id: ChatUnitColorId.VIOLET,
  },
  {
    color: UserThemes.choice10,
    id: ChatUnitColorId.PURPLE,
  },
  {
    color: UserThemes.choice11,
    id: ChatUnitColorId.PINK,
  },
  {
    color: UserThemes.choice12,
    id: ChatUnitColorId.MAGENTA,
  },
];

export const getRandomTableColor = (exclude?: ChatUnitColorId): UserThemes => {
  const tableColor =
    tableColors[Math.floor(Math.random() * tableColors.length)];
  if (exclude && exclude === tableColor.id) {
    return getRandomTableColor(exclude);
  }
  return tableColor.color;
};
