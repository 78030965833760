import { FlashList } from '@shopify/flash-list';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FlatList,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
  View,
} from 'react-native';
import {
  type SharedValue,
  runOnJS,
  useAnimatedReaction,
} from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { useMessageReadHandlers } from '../../../conversation/hooks/useMessageReadHandlers';
import { useMessages } from '../../../conversation/hooks/useMessages';

import Message from './Message';
import { StickyDate } from './StickyDate';
import { styles } from './styles';
import type { CustomMessage, CustomMsg } from './types';

import { constants } from '@/constants';
import { useChatScroll } from '@/domain/chatUnit/hooks/useChatScroll';
import { useRefreshMessages } from '@/domain/conversation/hooks/useRefreshMessages';
import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatMessage } from '@/services/types';
import { debounceFunction } from '@/utilities/helpers/debounceFunction';
import { web } from '@/utilities/platform';

type Props = {
  chatUnitId: string;
  conversationId: string;
  index: number;
  myTableUserId?: string;
  offset: SharedValue<number>;
  uniqueId?: string;
};

export const MessageList = ({
  chatUnitId,
  conversationId,
  index,
  myTableUserId,
  offset,
  uniqueId,
}: Props) => {
  const user = useUserStore(state => state.user);
  const { messages } = useMessages({
    chatUnitId,
    conversationId,
    myTableUserId,
  });
  const flatListRef = useRef<FlatList<ChatMessage>>(null);
  const flashListRef = useRef<FlashList<ChatMessage>>(null);
  const [isEndOfConversation, setIsEndOfConversation] = useState(false);
  const { isLoading, refreshMessages } = useRefreshMessages();
  const [hasRendered, setHasRendered] = useState(web);
  const { selectedMessage, clearSelectedMessage, clearQuery } =
    useActiveConversationStore(
      useShallow(s => ({
        selectedMessage: s.selectedMessage,
        clearSelectedMessage: s.clearSelectedMessage,
        clearQuery: s.clearQuery,
      })),
    );
  const selectedMessageIndex =
    selectedMessage?.conversationId === conversationId
      ? messages.findIndex(m => m.id === selectedMessage?.id)
      : 0;
  // Reset end of conversation status when conversationId changes
  useEffect(() => setIsEndOfConversation(false), [conversationId]);

  useAnimatedReaction(
    () => offset.value,
    offsetValue => {
      if (web) return; // we only render a single page at a time on web
      if (index === offsetValue) runOnJS(setHasRendered)(true);
    },
    [offset.value],
  );

  // Hook to handle message read status
  useMessageReadHandlers({
    chatUnitId,
    conversationId,
    currentIndex: index,
    offset,
  });

  // Convert messages to GiftedChat format
  const toGiftedChatFormat = useCallback(
    (props: any, indexMessage: number): CustomMessage => {
      const previousMessage = messages[indexMessage + 1] ?? {};
      const nextMessage = messages[indexMessage - 1] ?? {};

      return {
        ...props,
        user: { _id: '' },
        key: props.id,
        currentMessage: { ...props },
        previousMessage: previousMessage as CustomMsg,
        nextMessage: nextMessage as CustomMsg,
      };
    },
    [messages],
  );

  // Render individual message
  const renderMessage = useCallback(
    (props: { item: ChatMessage; index: number }) => (
      <Message
        {...toGiftedChatFormat(props?.item, props.index)}
        myUser={{
          _id: user?.id ?? '',
          avatar: user?.avatar ?? '',
          id: user?.id ?? '',
          name: user?.name ?? '',
          username: user?.username ?? '',
          status: true,
        }}
      />
    ),
    [user, toGiftedChatFormat],
  );

  // Custom hook to handle scrolling and sticky date
  const { handleScroll, onScrollEnd, onViewableItemsChanged, showStickyDate } =
    useChatScroll(conversationId);

  const earliestMessageTime =
    messages[messages.length - 1]?.createdAt.toISOString();

  // Load earlier messages when reaching the top of the list
  const onLoadEarlier = useCallback(async () => {
    if (!user?.id || isLoading || isEndOfConversation || !hasRendered) return;

    const isThisEndOfConversation = await refreshMessages({
      chatUnitId,
      conversationId,
      limit: constants.loadPreviousMessagesLimit,
      userId: user.id,
      dateTo: earliestMessageTime,
    });

    setIsEndOfConversation(!isThisEndOfConversation);
  }, [
    isEndOfConversation,
    chatUnitId,
    conversationId,
    earliestMessageTime,
    refreshMessages,
    user?.id,
    isLoading,
    hasRendered,
  ]);

  // Handle scroll event using Reanimated worklet
  const onScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      'worklet';
      handleScroll(e);
      const topOffsetForTrigger = 120;
      const topOffsetThreshold =
        e.nativeEvent.contentSize.height -
        e.nativeEvent.layoutMeasurement.height -
        topOffsetForTrigger;
      if (e.nativeEvent.contentOffset.y >= topOffsetThreshold) {
        runOnJS(onLoadEarlier)();
      }
      if (web && selectedMessageIndex > 0) {
        debounceFunction(() => clearSelectedMessage(), 500);
      }
    },
    [handleScroll, onLoadEarlier],
  );

  const onContentSizeChanged = useCallback(() => {
    if (selectedMessageIndex > 0) {
      debounceFunction(() => {
        (flatListRef.current || flashListRef.current)?.scrollToIndex({
          animated: true,
          index: selectedMessageIndex,
          viewPosition: 0.5,
        });
      }, 100);
    }
  }, [selectedMessageIndex]);

  // For web when you select differet search result from the same conversation then it should scroll to that message.
  // onContentSizeChanged on flatlist may not fire if the item is in the viewport
  useEffect(() => {
    if (web) {
      onContentSizeChanged();
    }
  }, [onContentSizeChanged, uniqueId]);

  useEffect(() => {
    return () => {
      clearSelectedMessage();
      clearQuery();
    };
  }, [clearSelectedMessage, clearQuery]);

  if (!user) return null;

  return web ? (
    <View style={styles.webChatContainer}>
      <StickyDate
        conversationId={conversationId}
        showStickyDate={showStickyDate}
      />
      <FlatList
        ref={flatListRef}
        inverted
        key={chatUnitId}
        contentContainerStyle={styles.chatListContentContainer}
        onMomentumScrollEnd={onScrollEnd}
        onScroll={onScroll}
        windowSize={7}
        onScrollEndDrag={onScrollEnd}
        onViewableItemsChanged={onViewableItemsChanged}
        scrollEventThrottle={3}
        showsVerticalScrollIndicator={false}
        viewabilityConfig={viewabilityConfig}
        renderItem={renderMessage}
        data={hasRendered ? messages : []}
        keyboardShouldPersistTaps={Platform.select({
          android: 'never',
          default: undefined,
        })}
        initialNumToRender={selectedMessageIndex + 20}
        onContentSizeChange={onContentSizeChanged}
        onScrollToIndexFailed={() => {
          debounceFunction(() => onContentSizeChanged(), 500);
        }}
      />
    </View>
  ) : (
    <>
      <StickyDate
        conversationId={conversationId}
        showStickyDate={showStickyDate}
      />
      <FlashList
        ref={flashListRef}
        estimatedItemSize={100}
        inverted
        key={chatUnitId}
        contentContainerStyle={styles.chatListContentContainer}
        onMomentumScrollEnd={onScrollEnd}
        onScroll={onScroll}
        onScrollEndDrag={onScrollEnd}
        onViewableItemsChanged={onViewableItemsChanged}
        scrollEventThrottle={3}
        showsVerticalScrollIndicator={false}
        viewabilityConfig={viewabilityConfig}
        renderItem={renderMessage}
        data={messages}
        keyboardShouldPersistTaps={Platform.select({
          android: 'never',
          default: undefined,
        })}
        onContentSizeChange={onContentSizeChanged}
      />
    </>
  );
};

// Viewability configuration for the list
const viewabilityConfig = {
  itemVisiblePercentThreshold: 10,
  waitForInteraction: true,
};
