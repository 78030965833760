/**
 * These colors are used in the app components like buttons, icons, etc.
 * They signify a sense of identity and familiarity with the brand.
 * They also expose a feeling of trust and reliability. These colors
 * are expected to change quite often. The colors give feedback
 * to the user about the state of the app.
 */
enum Brand {
  primary10 = '#b5d9ef',
  primary100 = '#1c75bc',
  primary200 = '#1e6e80',
  primary300 = '#0a314a',
  primary50 = '#00b9f7',
  primary75 = '#07818c',
  primaryDark100 = '#17D3E5',
  tint100 = '#de774b',
  tint200 = '#cb3e23',
}

export default Brand;
