import parsePhoneNumberFromString from 'libphonenumber-js';
import { useCallback, useEffect, useRef, useState } from 'react';

import useUserStore from '@/domain/user/state/useUserStore';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import { upsertUser } from '@/services/datastore/user/upsertUser';
import { logger } from '@/services/logger/logger';

interface State {
  avatar: string;
  birthdate: string;
  display_when_in_app: string;
  display_when_online: string;
  name: string;
  phone_number: string;
  preffered_username: string;
  pronouns: string;
}

export const useConnect = () => {
  const user = useUserStore(state => state.user);
  const [state, setState] = useState({
    avatar: '',
    birthdate: '',
    display_when_in_app: '1',
    display_when_online: '1',
    name: '',
    phone_number: '',
    preffered_username: '',
    pronouns: '',
  });
  const initialStateRef = useRef<State | null>(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useStatusBarHandler('light-content');

  useEffect(() => {
    const initialState = {
      avatar: user?.avatar || '',
      birthdate: user?.birthday || '',
      display_when_in_app: '0',
      display_when_online: '0',
      name: user?.name || '',
      phone_number: user?.phoneNumber || '',
      preffered_username: user?.username || '',
      pronouns: user?.pronouns || '',
    };
    setState(initialState);
    initialStateRef.current = initialState;
  }, [user]);

  const updateState = useCallback(
    (key: string, value: any) => {
      setState({ ...state, [key]: value });
    },
    [state],
  );

  const isDirty = () => {
    return (
      state.preffered_username !== user?.username ||
      state.name !== (user?.name ?? '') ||
      state.avatar !== user?.avatar ||
      Boolean(state.pronouns) !== Boolean(user?.pronouns) ||
      state.phone_number !== user?.phoneNumber ||
      Boolean(state.birthdate) !== Boolean(user?.birthday)
    );
  };

  const changesAdded = isDirty();
  const canSave =
    changesAdded && state.avatar && state.preffered_username && state.name;

  const onSubmit = useCallback(async () => {
    if (submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    try {
      if (user) {
        await upsertUser({
          avatar: state.avatar,
          birthday: state.birthdate,
          name: state.name,
          pronouns: state.pronouns,
          username: state.preffered_username,
        });
      }
      setSubmitInProgress(false);
      initialStateRef.current = state;
    } catch (err) {
      setSubmitInProgress(false);
      logger.error('userProfile::onSubmitError', err);
    }
  }, [state, submitInProgress, user]);

  const onPressCancel = () => {
    if (initialStateRef.current) {
      setState(initialStateRef.current);
    }
  };
  const parsedPhoneNumber = parsePhoneNumberFromString(user?.phoneNumber ?? '');

  const onSetAvatar = useCallback(
    (avatar: string | undefined) => {
      updateState('avatar', avatar);
    },
    [updateState],
  );

  return {
    canSave,
    changesAdded,
    countryCode: `+${parsedPhoneNumber?.countryCallingCode ?? ''}`,
    datePickerOpen,
    onPressCancel,
    onSetAvatar,
    onSubmit,
    phone: parsedPhoneNumber?.formatNational() ?? '',
    setDatePickerOpen,
    state,
    submitInProgress,
    updateState,
    user,
  };
};
