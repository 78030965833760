import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InviteUserView = ({ navigation, route }: any) => {
  //TODO All of this
  return <SafeAreaView edges={['left', 'right']} />;
};

export default InviteUserView;
