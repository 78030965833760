import { validateInput } from './validateInput';

import type { Message } from '@/services/chatUnit/types';

export const messageFactory = (input: any): Message => {
  validateInput(
    input,
    [
      'allowedUserIds',
      'authorId',
      'chatUnitId',
      'chatUnitUserId',
      'conversationId',
      'createdAt',
      'id',
      'messageId',
      'status',
    ],
    'Message',
  );

  const message: Message = {
    allowedUserIds: input.allowedUserIds,
    chatUnitId: input.chatUnitId,
    chatUnitUserId: input.chatUnitUserId,
    conversationId: input.conversationId,
    createdAt: input.createdAt,
    id: input.id,
    image: input.image ?? undefined,
    messageId: input.messageId,
    SK: input.SK ?? input.createdAt,
    status: input.status,
    text: input.text ?? undefined,
    updatedAt: input.updatedAt,
    userId: input.authorId,
    _version: input._version ?? 1,
    metaData: input.metaData ?? '',
  };

  return message;
};
