import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Markdown from 'react-native-markdown-display';

import Logo from '@/assets/svg/8seats-logo-dark.svg';
import LockIcon from '@/assets/svg/secure_icon.svg';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

interface NotInvitedProps {
  phoneNumber: string;
}

const NotInvited = ({ phoneNumber }: NotInvitedProps) => {
  const { translate } = useTranslations();
  const explanationText = translate('eas_error_explanation').replace(
    '{{phoneNumber}}',
    `**${phoneNumber}**`,
  );
  return (
    <View style={styles.container}>
      <Logo height={70} />
      <LockIcon height={20} style={styles.icon} width={20} />
      <Text style={styles.title}>{translate('eas_error_title')}</Text>
      <Markdown
        style={{
          body: styles.explanationText,
        }}>
        {explanationText}
      </Markdown>
      <Text style={styles.footerText}>
        {translate('eas_error_footer_text')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
  },
  explanationText: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginTop: 5,
    paddingHorizontal: wp(8),
    textAlign: 'center',
  },
  footerText: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginTop: 10,
    paddingHorizontal: wp(8),
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'center',
    marginTop: 30,
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
    marginTop: 10,
    paddingHorizontal: wp(15),
    textAlign: 'center',
  },
});
export default NotInvited;
