import type { ListRenderItem } from '@shopify/flash-list';
import moment from 'moment';
import { useRef } from 'react';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';

import { ChatUnitType } from '../../../../API';
import { type Invite, InviteStatus } from '../../types/types';

import { styles } from './styles';

import RightIcon from '@/assets/back-arrow.svg';
import RightActionIcon from '@/assets/svg/AcceptInvite.svg';
import LeftActionIcon from '@/assets/svg/DeclineInvite.svg';
import AcceptedInviteIcon from '@/assets/svg/accepted-invite-icon.svg';
import DeclinedInviteIcon from '@/assets/svg/declined-invite-icon.svg';
import WaitingIcon from '@/assets/svg/waiting_icon.svg';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import Avatar from '@/components/avatar';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import FreePill from '@/components/organisation/FreePill';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import useNavigation from '@/hooks/useNavigation';
import { InviteTargetType } from '@/models';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Greys } from '@/ui/common/colors';

const TablesBadge = require('@/assets/group_profile_icon.png');
const RoomsBadge = require('@/assets/team_profile_icon.png');

export const BADGES = { RoomsBadge, TablesBadge };

const Badge = ({ invite }: { invite: Invite }) => {
  const { translate } = useTranslations();
  if (invite.status === InviteStatus.ACCEPTED) {
    return (
      <View style={[styles.badgeBtn, styles.badgeAccepted]}>
        <Text style={[styles.badgeTxt, styles.badgeAcceptedText]}>
          {translate('accepted_badge_text')}
        </Text>
      </View>
    );
  }
  if (invite.status === InviteStatus.DECLINED) {
    return (
      <View style={[styles.badgeBtn, styles.declinedBadge]}>
        <Text style={[styles.badgeTxt, styles.declinedBadgeText]}>
          {translate('declined_badge_text')}
        </Text>
      </View>
    );
  }
  return (
    <View style={[styles.badgeBtn, styles.newBadge]}>
      <Text style={[styles.badgeTxt, styles.newBadgeText]}>
        {translate('new_badge_text')}
      </Text>
    </View>
  );
};

const InviteCard = ({
  avatar,
  inviterInfo,
  item,
  targetInfo,
}: {
  avatar?: string;
  index: number;
  inviterInfo: string & Record<string, any>;
  item: Invite;
  targetInfo: string & Record<string, any>;
  translate?: (text: string, options?: Record<string, any>) => string;
}) => (
  <View style={styles.itemContainer}>
    <View style={styles.imageBgContainer}>
      {item.targetType === InviteTargetType.ORGANISATION ? null : (
        <View style={styles.chatUnitIcon}>
          <ChatUnitIcon
            fill={Greys.shade0}
            size={50}
            type={ChatUnitType.TABLE}
          />
        </View>
      )}
      <View style={styles.itemImage}>
        <ChatUnitBackgroundImage
          imageKey={targetInfo.backgroundImage}
          width="100%"
          height="100%"
        />
      </View>
    </View>

    <View style={styles.itemContent}>
      <View style={styles.titleRow}>
        <Text style={styles.itemTitle}>{`${targetInfo?.title}`}</Text>
        <Badge invite={item} />
      </View>
      {item.targetType === InviteTargetType.ORGANISATION ? (
        <Section row aic jcfs gap={4} mb={12}>
          <FreePill plan="Free team" />
          <AppText size={12}>Workspace</AppText>
        </Section>
      ) : null}
      <View style={styles.acceptedInvitation}>
        {avatar || inviterInfo?.name ? (
          <Avatar
            imageKey={avatar}
            displayName={inviterInfo?.name}
            style={styles.acceptedInvitationImage}
          />
        ) : null}
        <Section gap={4}>
          <AppText size={12}>
            {(inviterInfo && inviterInfo?.name) || ''}
          </AppText>
          <AppText color={Colors.neutral70} size={10}>
            {(inviterInfo && inviterInfo?.phone) ?? ''}
          </AppText>
        </Section>
      </View>
      <View style={styles.inviteeDetailsRow}>
        <AppText size={10}>Invited you</AppText>
        <AppText size={10} color={Colors.neutral70}>
          {moment(item.createdAt).fromNow()}
        </AppText>
      </View>
    </View>
    <View style={styles.itemButton}>
      <RightIcon height={12} width={6} />
    </View>
  </View>
);

export const ReceivedInvitationItem = ({
  acceptInvitationHandler,
  declineInvitationHandler,
  index,
  item,
}: {
  acceptInvitationHandler: (id: string) => void;
  declineInvitationHandler: (id: string) => void;
  index: number;
  item: Invite;
}) => {
  const targetInfo = item?.targetInfo;
  const inviterInfo = item.inviterInfo;
  const { translate } = useTranslations();
  const swipeableRef = useRef<Swipeable>(null);
  const { navigate } = useNavigation();
  const onLeftActionPress = () => {
    declineInvitationHandler(item.id);
    swipeableRef.current?.close();
  };

  const onRightActionPress = () => {
    acceptInvitationHandler(item.id);
    swipeableRef.current?.close();
  };

  const renderLeftActions = () => {
    return (
      <View style={styles.actionContainer}>
        <TouchableOpacity onPress={onLeftActionPress} style={styles.leftAction}>
          <LeftActionIcon height={30} width={30} />
          <Text style={styles.actionText}>
            {translate('decline_button_title')}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderRightActions = () => {
    return (
      <View style={styles.actionContainer}>
        <TouchableOpacity
          onPress={onRightActionPress}
          style={styles.rightAction}>
          <RightActionIcon height={30} width={30} />
          <Text style={styles.actionText}>
            {translate('accept_button_title')}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  if (item.status === InviteStatus.PENDING) {
    return (
      <TouchableOpacity
        onPress={() =>
          navigate('InviteDetails', {
            id: item.inviteId,
          })
        }>
        <Swipeable
          key={item.id}
          leftThreshold={100}
          overshootLeft={false}
          overshootRight={false}
          ref={swipeableRef}
          renderLeftActions={renderLeftActions}
          renderRightActions={renderRightActions}
          rightThreshold={100}>
          <InviteCard
            avatar={inviterInfo?.avatar}
            index={index}
            inviterInfo={inviterInfo}
            item={item}
            key={index}
            targetInfo={targetInfo}
          />
        </Swipeable>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      onPress={() =>
        navigate('InviteDetails', {
          id: item.inviteId,
        })
      }>
      <InviteCard
        avatar={inviterInfo?.avatar}
        index={index}
        inviterInfo={inviterInfo}
        item={item}
        targetInfo={targetInfo}
      />
    </TouchableOpacity>
  );
};

export const ReceivedItemHeader = ({ type }: { type: InviteStatus }) => {
  const { translate } = useTranslations();

  const statusLookUp: Record<InviteStatus, Record<string, any>> = {
    [InviteStatus.ACCEPTED]: {
      icon: <AcceptedInviteIcon />,
      text: translate('accepted'),
    },
    [InviteStatus.PENDING]: {
      icon: <WaitingIcon />,
      text: translate('pending'),
    },
    [InviteStatus.DECLINED]: {
      icon: <DeclinedInviteIcon />,
      text: translate('declined'),
    },
    [InviteStatus.CANCELLED]: {},
    [InviteStatus.COMPLETED]: {},
    [InviteStatus.ACTIVE]: {},
  };
  const header = statusLookUp[type]?.icon;
  const text = statusLookUp[type]?.text;

  return (
    <View style={styles.receivedItemHeader}>
      {header}
      <Text style={styles.itemHeaderText}>{text}</Text>
    </View>
  );
};

export const renderReceivedItem =
  ({
    acceptInvitationHandler,
    declineInvitationHandler,
  }: {
    acceptInvitationHandler: (inviteId: string) => void;
    declineInvitationHandler: (inviteId: string) => void;
  }): ListRenderItem<{ groupedInvites: Invite[]; status: InviteStatus }> =>
  ({ item }) => {
    const invitesRendered = item.groupedInvites.map(
      (invite: Invite, index: number) => (
        <ReceivedInvitationItem
          acceptInvitationHandler={acceptInvitationHandler}
          declineInvitationHandler={declineInvitationHandler}
          index={index}
          item={invite}
          key={index}
        />
      ),
    );

    return (
      <>
        <ReceivedItemHeader type={item?.status ?? InviteStatus.PENDING} />
        <View style={styles.receivedItemcontainer}>{invitesRendered}</View>
      </>
    );
  };
