import React, { useState } from 'react';
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  type UseControllerProps,
} from 'react-hook-form';
import { Pressable, StyleSheet, TextInput, View } from 'react-native';

import PasswordInvisibleIcon from '@/assets/svg/eye-closed.svg';
import PasswordVisibleIcon from '@/assets/svg/eye-open.svg';
import { primaryBlue } from '@/theme/colors';
import { Greys } from '@/ui/common/colors';

const PasswordInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  ...props
}: { label: string } & UseControllerProps<TFieldValues, TName>) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const togglePasswordVisibility = () => {
    setSecureTextEntry(prev => !prev);
  };
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <View
          style={[
            styles.passwordInputContainer,
            isFocused ? styles.activeBorder : styles.inactiveBorder,
          ]}>
          <TextInput
            onBlur={() => {
              setIsFocused(false);
            }}
            onChangeText={(text: string) =>
              onChange(text as string as PathValue<TFieldValues, TName>)
            }
            onFocus={() => {
              setIsFocused(true);
            }}
            secureTextEntry={secureTextEntry}
            style={styles.textInput}
            value={value ?? ''}
          />
          <Pressable
            onPress={togglePasswordVisibility}
            style={styles.toggleButton}>
            {secureTextEntry ? (
              <PasswordInvisibleIcon />
            ) : (
              <PasswordVisibleIcon />
            )}
          </Pressable>
        </View>
      )}
      rules={{ required: true }}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  activeBorder: {
    borderColor: primaryBlue,
    borderWidth: 2,
  },
  inactiveBorder: {
    borderColor: Greys.shade300,
    borderWidth: 1,
  },
  passwordInputContainer: {
    alignItems: 'center',
    borderColor: Greys.shade300,
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: '80%',
  },
  textInput: {
    color: Greys.shade600,
    flex: 1,
    fontFamily: 'OpenSans-Regular',
    fontSize: 21,
    fontWeight: '400',
    textAlign: 'center',
  },
  toggleButton: {
    padding: 10,
    position: 'absolute',
    right: 0,
  },
});

export default PasswordInput;
