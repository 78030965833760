import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import type { PropsWithChildren } from 'react';
import Config from 'react-native-config';

const httpLink = new HttpLink({
  uri: Config.CMS_URL,
  headers: {
    Authorization: `Bearer ${Config.CMS_TOKEN}`,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // graphQLErrors.forEach(() => console.warn('[GraphQL error]:', graphQLErrors));
  }

  if (networkError) {
    console.error('Unable to reach GraphQL, network error', networkError);
  }
});

const retryLink = new RetryLink({
  attempts: {
    max: 1,
  },
});

const cache = new InMemoryCache();

// get front end apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(retryLink.concat(httpLink)),
  cache: cache,
  queryDeduplication: true,
  ssrMode: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
  },
});

export const CMSProvider = ({ children }: PropsWithChildren) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
