import useCMSStore from '@/domain/cms/useCMSStore';
import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import React, { memo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import DiscoverUnreadIndicator from './DiscoverUnreadIndicator';

type Props = {
  title: string;
  onPress?: (title: string) => void;
  variant: 'light' | 'dark' | 'selected';
  showUnread?: boolean;
};

function DiscoverTabPill({
  title,
  variant,
  onPress,
  showUnread = false,
}: Props) {
  const isUnread = useCMSStore(
    state =>
      state.getUnreadPostSections().length &&
      (title.includes('All') || state.getUnreadPostSections().includes(title)),
  );

  return (
    <Pressable
      style={
        variant === 'selected'
          ? selectedPillStyles
          : variant === 'dark'
            ? darkPillStyles
            : styles.pill
      }
      onPress={() => onPress?.(title)}>
      <AppText
        size={12}
        type="primary500"
        numberOfLines={1}
        color={variant === 'light' ? Colors.neutral80 : Colors.neutral0}>
        {title.trim()}{' '}
      </AppText>
      {isUnread && showUnread ? (
        <View style={styles.unread}>
          <DiscoverUnreadIndicator size={9} />
        </View>
      ) : null}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pill: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: Colors.neutral80,
    borderRadius: 12.5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  unread: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const selectedPillStyles = StyleSheet.compose(styles.pill, {
  backgroundColor: Colors.primaryLight,
  borderColor: Colors.primaryDark,
});

const darkPillStyles = StyleSheet.compose(styles.pill, {
  backgroundColor: Colors.neutral90,
  borderColor: Colors.neutral0,
});

export default memo(DiscoverTabPill);
