// @ts-nocheck
import { constants } from '@/constants';
import { postHogGetFeatureFlag, postHogTrack } from '@/context/Experience';
import { getConstant } from '@/utilities/constants/getConstant';
const { logLevels: LogLevel } = constants;
const logLevelMap = {
  [LogLevel.DEBUG]: 'DEBUG',
  [LogLevel.INFO]: 'INFO',
  [LogLevel.WARN]: 'WARN',
  [LogLevel.ERROR]: 'ERROR',
  [LogLevel.SILENT]: 'SILENT',
};

const getLogLevelLabel = _logLevel => logLevelMap[_logLevel];

export const logger = {
  debug: (eventName: string, ...args) => {
    if (getConstant('logLevel') <= LogLevel.DEBUG) {
      log(eventName, false, LogLevel.DEBUG, ...args);
    }
  },
  error: (eventName: string, ...args) => {
    if (getConstant('logLevel') <= LogLevel.ERROR) {
      log(eventName, true, LogLevel.ERROR, ...args);
    }
  },
  info: (eventName: string, ...args) => {
    if (getConstant('logLevel') <= LogLevel.INFO) {
      log(eventName, false, LogLevel.INFO, ...args);
    }
  },
  warn: (eventName: string, ...args) => {
    if (getConstant('logLevel') <= LogLevel.WARN) {
      log(eventName, false, LogLevel.WARN, ...args);
    }
  },
};

const log = (eventName: string, isError: boolean, level: LogLevel, ...args) => {
  try {
    const time = new Date().toISOString().split('T')[1].slice(0, -1);
    const prefix = `${time} [${getLogLevelLabel(level)}]`;

    if (isError) {
      console.error(prefix, eventName, ...args);
      postHogTrack('Error', {
        message: eventName + JSON.stringify(args),
      });
      return;
    } else if (level === LogLevel.INFO) {
      postHogGetFeatureFlag('captureInfoLogs')
        .then(captureInfoLogs => {
          if (captureInfoLogs) {
            postHogTrack('Info', {
              message: eventName + JSON.stringify(args),
            });
          }
        })
        .catch(e => {
          console.error('Error sending event to posthog', e);
        });
    }
    console.log(prefix, eventName, ...args);
  } catch (error) {
    console.error('Failed to log:', error);
  }
};
