import { useShallow } from 'zustand/react/shallow';

import type { ChatUnitType } from '@/API';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import type { ChatUnit } from '@/services';

export const useChatUnits = (type?: ChatUnitType) => {
  const myChatUsers = useTableUsersStore(
    useShallow(state => state.getMyChatUsers()),
  );
  const chatUnits = useTableStore(useShallow(state => state.getTables(type)));
  const activeChatUnits = chatUnits.reduce((acc, chatUnit) => {
    const myChatUser = myChatUsers.get(chatUnit.id);
    if (myChatUser) {
      acc.push(chatUnit);
    }
    return acc;
  }, [] as ChatUnit[]);
  return {
    chatUnits: activeChatUnits,
  };
};
