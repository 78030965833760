import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBulb = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M38.969 94.115A6.95 6.95 0 0 0 45.833 100h8.334a6.95 6.95 0 0 0 6.864-5.885c4.833-1.206 8.413-5.575 8.413-10.782V69.444a11.07 11.07 0 0 0-2.712-7.275c9.927-5.772 16.601-16.525 16.601-28.836C83.333 14.924 68.409 0 50 0 31.59 0 16.667 14.924 16.667 33.333c0 12.311 6.674 23.064 16.601 28.836a11.07 11.07 0 0 0-2.712 7.275v13.889c0 5.207 3.58 9.576 8.413 10.782m19.364-27.448H41.667a2.778 2.778 0 0 0-2.778 2.777v13.889a2.778 2.778 0 0 0 2.778 2.778h16.666a2.778 2.778 0 0 0 2.778-2.778V69.444a2.778 2.778 0 0 0-2.778-2.777m-44.045-6.301a5.555 5.555 0 1 0-5.556-9.622 5.555 5.555 0 0 0 5.556 9.622m79.013-2.033a5.555 5.555 0 0 1-7.589 2.033 5.556 5.556 0 1 1 7.589-2.033m-39.979-.218 3.736-18.681a2.778 2.778 0 0 0-2.724-3.323h-8.668a2.778 2.778 0 0 0-2.724 3.323l3.736 18.681c1.086.144 2.195.218 3.322.218s2.236-.074 3.322-.218m5.979-1.569C68.503 52.855 75 43.853 75 33.333c0-13.807-11.193-25-25-25s-25 11.193-25 25c0 10.52 6.497 19.522 15.699 23.213l-3.205-16.023c-1.031-5.156 2.913-9.967 8.172-9.967h8.668c5.259 0 9.203 4.81 8.172 9.967zM5.556 38.889a5.556 5.556 0 1 0-.002-11.112 5.556 5.556 0 0 0 .002 11.112m88.888 0a5.556 5.556 0 1 0 0-11.112 5.556 5.556 0 0 0 0 11.112M8.732 15.922a5.556 5.556 0 1 0 5.555-9.624 5.556 5.556 0 0 0-5.555 9.624m84.569-7.589a5.555 5.555 0 1 1-9.622 5.555 5.555 5.555 0 1 1 9.622-5.555" />
  </Svg>
);
export default SvgBulb;
