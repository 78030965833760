import type { GraphQLQuery } from '@aws-amplify/api';

import type {
  CreateUserInput,
  CreateUserMutation,
  GetUserQuery,
  UpdateUserInput,
  UpdateUserMutation,
} from '../../API';

import client from '@/services/API/client';
import {
  createUser as createUserMutation,
  updateUser as updateUserMutation,
} from '@/services/graphql/mutations';
import { getUser as getUserQuery } from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

export class UserServiceError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export const createUser = async (userDetails: CreateUserInput) => {
  try {
    const response = await client.graphql<GraphQLQuery<CreateUserMutation>>({
      query: createUserMutation,
      variables: { input: userDetails },
    });

    if (response && response.data && response.data.createUser) {
      return response.data?.createUser;
    } else if (response.errors) {
      throw response.errors;
    }
  } catch (err) {
    logger.error('ERROR::userService::createUser', err);
    throw err;
  }
};

export const getUser = async (userId: string) => {
  try {
    if (userId) {
      const response = await client.graphql<GraphQLQuery<GetUserQuery>>({
        query: getUserQuery,
        variables: { id: userId },
      });
      if (response && response.data && response.data.getUser) {
        return response.data?.getUser;
      } else if (response.errors) {
        throw response.errors;
      }

      throw new UserServiceError(404, `Failed to get user with id ${userId}`);
    }
  } catch (err) {
    logger.error('ERROR::userService::getUser', err);
    throw err;
  }
};

export const updateUser = async (
  userId: string,
  updateDetails: Omit<UpdateUserInput, 'id'>,
) => {
  try {
    if (userId) {
      const response = await client.graphql<GraphQLQuery<UpdateUserMutation>>({
        query: updateUserMutation,
        variables: { input: { id: userId, ...updateDetails } },
      });

      if (response && response.data && response.data.updateUser) {
        return response.data?.updateUser;
      }
    }
    throw new Error('No User');
  } catch (err) {
    logger.error('ERROR::userService::updateUser', err);
    throw err;
  }
};

export default {
  getUser,
  updateUser,
};
