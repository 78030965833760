import type { SyncError } from 'aws-amplify/datastore';
import { isArray, isObject } from 'lodash';

import { eventBus } from '@/services/eventBus/eventBus';
import { logger } from '@/services/logger/logger';

export const handleDatastoreError = (
  error: SyncError<Readonly<Record<string, any>>>,
) => {
  const { cause, ...errorData } = error;

  try {
    if (errorData.process === 'sync') {
      eventBus.emit('errorDataStoreSync', error);

      // Note: type of Cause is incorrect
      // @ts-ignore
      const causeErrors = cause?.errors as any[];
      // @ts-ignore
      const causeData = cause?.data as any;

      if (causeErrors && isArray(causeErrors)) {
        causeErrors.forEach(
          (item: { path: (string | number)[]; record: any }) => {
            try {
              if (isObject(item)) {
                // get error record data
                if (!isArray(item.path) || item.path.length < 3) {
                  return;
                }

                const record =
                  causeData[item.path[0]][item.path[1]][item.path[2]];

                item.record = record;
              }
            } catch (e) {
              logger.error('handleDatastoreError::causeErrorsLoop', e);
            }
          },
        );
      }

      logger.error('DatastoreSyncError', {
        ...errorData,
        causeErrors,
      });
      return;
    }
  } catch (e) {
    logger.error('handleDatastoreError', e);
  }

  logger.error('DataStoreError', errorData);
};
