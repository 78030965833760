import AsyncStorage from '@react-native-async-storage/async-storage';
import superjson from 'superjson';

import { logger } from '@/services/logger/logger';
import { app } from '@/utilities/platform';

export const asyncStorageMethods = {
  getItem: async (name: string): Promise<any> => {
    const str = await AsyncStorage.getItem(name);

    if (!str) {
      return null;
    }

    return superjson.parse(str);
  },

  removeItem: async (name: string) => await AsyncStorage.removeItem(name),

  setItem: async (name: string, value: any) => {
    AsyncStorage.setItem(name, superjson.stringify(value)).catch(err => {
      app
        ? logger.error('Error saving to AsyncStorage', err)
        : console.error('Error saving to AsyncStorage', err);
    });
  },
};
