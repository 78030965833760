export const parseNumber = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }
  const parsed = Number.parseInt(value, 10);
  if (isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};
