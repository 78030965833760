import type {
  Contact as DeviceContacts,
  PhoneNumber,
} from 'react-native-contacts';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import type {
  OrgMeta,
  OrganisationUser,
} from '@/domain/auth/helpers/getOrganisationByUser';
import type { ChatUnitUser } from '@/services/types';

export type Contact = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumbers: PhoneNumber[];
  organisations?: OrgMeta[];
  is8seatsUser: boolean;
  commonChatUnitIds?: string[];
  userId?: string;
  avatar?: string;
};

type State = {
  eightSeatsContacts: Map<string, Contact>;
  deviceContacts: Contact[];
  filteredContacts: Contact[]; // filtered & grouped for display
};

type Actions = {
  clearState: () => void;
  setDeviceContacts: (contacts: DeviceContacts[]) => void;
  upsertEightSeatsUsers: (
    contacts: (OrganisationUser | ChatUnitUser)[],
  ) => void;
};

const initialState: State = {
  eightSeatsContacts: new Map(),
  deviceContacts: [],
  filteredContacts: [],
};

export const useContactStore = create<State & Actions>()(
  immer(set => ({
    ...initialState,
    clearState: () => set(() => initialState),
    setDeviceContacts: (contacts: DeviceContacts[]) =>
      set(state => {
        state.deviceContacts = contacts.map(contact => ({
          id: `c-${contact.recordID}`,
          firstName: contact.givenName,
          lastName: contact.familyName,
          username: '',
          phoneNumbers: contact.phoneNumbers,
          is8seatsUser: false,
        }));
      }),
    upsertEightSeatsUsers: chatUsers =>
      set(state => {
        chatUsers.forEach(chatUser => {
          const id = `u-${chatUser.id}`;

          const existingContact = state.eightSeatsContacts.get(id);

          state.eightSeatsContacts.set(id, {
            id,
            firstName: chatUser.firstName!,
            lastName: chatUser.lastName!,
            username: chatUser.username!,
            phoneNumbers: [
              {
                label: 'mobile',
                number: chatUser.phoneNumber!,
              },
            ],
            avatar: chatUser.avatar,
            is8seatsUser: true,
            commonChatUnitIds: (chatUser as ChatUnitUser)?.chatUnitId
              ? Array.from(
                  new Set([
                    ...(existingContact?.commonChatUnitIds ?? []),
                    (chatUser as ChatUnitUser).chatUnitId,
                  ]),
                )
              : existingContact?.commonChatUnitIds ?? [],
            userId: chatUser.id,
            organisations: (chatUser as OrganisationUser)?.orgId
              ? [
                  ...(existingContact?.organisations ?? []),
                  {
                    orgId: (chatUser as OrganisationUser).orgId,
                    orgName: (chatUser as OrganisationUser).orgName,
                    orgRole: (chatUser as OrganisationUser).orgRole,
                  },
                ]
              : existingContact?.organisations ?? [],
          });
        });
      }),
  })),
);
