import messaging from '@react-native-firebase/messaging';

export async function ios_requestUserPermission() {
  const authStatus = await messaging().requestPermission({
    alert: true,
    badge: true,
    sound: true,
  });

  return (
    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
    authStatus === messaging.AuthorizationStatus.PROVISIONAL
  );
}
