export const getUser = () => {
  return {
    avatar: '',
    customAvatar: '',
    customNickname: '',
    date_joined: new Date().toDateString(),
    isOnline: true,
    name: 'Ukiah Sousa',
  };
};
