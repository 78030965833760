import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { type SharedValue } from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { useStyles } from '../../hooks/useStyles';

import InputToolBar from './InputToolBar';
import { MessageList } from './MessageList';
import { NewConversation } from './NewConversation';
import { styles } from './styles';

import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { getConversationIdFromUserIds } from '@/services/datastore/conversation/getConversationIdFromUserIds';
import { blackAlpha0, blackAlpha20 } from '@/theme/colors';
import { web } from '@/utilities/platform';
import WebArrowNavigation from './WebArrowNavigation';

const MIN_INPUT_HEIGHT = 32;

type Props = {
  chatUnitId: string;
  conversationId: string;
  index: number;
  offset: SharedValue<number>;
  handleArrowNavigation?: (direction: 'left' | 'right') => void;
  firstMessage?: string;
  uniqueId?: string;
};

const ChatView = ({
  chatUnitId,
  conversationId,
  index,
  offset,
  handleArrowNavigation,
  firstMessage,
  uniqueId,
}: Props) => {
  const table = useTableStore(useShallow(state => state.getTable(chatUnitId)));
  const myTableUser = useTableUsersStore(
    useShallow(state => state.myChatUsers.get(chatUnitId)),
  );
  const isOnlyMeConversation = useMemo(
    () =>
      myTableUser?.id
        ? conversationId === getConversationIdFromUserIds([myTableUser?.id])
        : false,
    [myTableUser?.id, conversationId],
  );

  const hasMessages = useMessageStore(
    useShallow(
      state => state.getMessages(chatUnitId, conversationId).length > 0,
    ),
  );

  const { scrollViewStyle } = useStyles();

  return (
    <View style={styles.flex}>
      <NewConversation
        chatUnitType={table?.type}
        isOnlyMe={isOnlyMeConversation}
        isAllConversation={conversationId === table?.allConversationId}
        hasMessages={hasMessages}
        styles={styles}
      />
      <Animated.View style={styles.shadowView}>
        <LinearGradient
          colors={[blackAlpha0, blackAlpha20]}
          end={{ x: 1, y: 0 }}
          start={{ x: 0, y: 0 }}
          style={styles.shadowLinearGradient}
        />
      </Animated.View>
      <Animated.View style={[scrollViewStyle, styles.flex]}>
        <View style={styles.flex}>
          <MessageList
            chatUnitId={chatUnitId}
            conversationId={conversationId}
            index={index}
            myTableUserId={myTableUser?.chatUnitUserId}
            offset={offset}
            uniqueId={uniqueId}
          />

          {web && handleArrowNavigation && (
            <WebArrowNavigation onNavigate={handleArrowNavigation} />
          )}
        </View>
        <InputToolBar
          chatUnitId={chatUnitId}
          conversationId={conversationId}
          myTableUserId={myTableUser?.chatUnitUserId}
          minHeight={MIN_INPUT_HEIGHT}
          firstMessage={firstMessage}
        />
      </Animated.View>
    </View>
  );
};
//
export default memo(ChatView);
