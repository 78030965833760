import { ImageBackground } from 'expo-image';
import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  type NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  TextInput,
  type TextInputContentSizeChangeEventData,
  type TextInputKeyPressEventData,
  View,
} from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useMessages } from '../../../conversation/hooks/useMessages';
import useDocumentUpload from '../../hooks/useDocumentUpload';
import useImageUpload from '../../hooks/useImageUpload';

import CameraModal from './CameraModal';
import UploadOptionsSheet from './UploadOptionsSheet';

import { ChatUnitColorId } from '@/API';
import AddIcon from '@/assets/chatview/addIcon.svg';
import CancelImg from '@/assets/chatview/cancel.svg';
import fileImg from '@/assets/chatview/file.png';
import TrashImg from '@/assets/chatview/trash.svg';
import UploadImg from '@/assets/chatview/upload.svg';
import OpenCameraIcon from '@/assets/svg/open-camera-icon.svg';
import SendButtonIcon from '@/assets/svg/send-button-icon.svg';
import Triangle from '@/assets/video/triangle.svg';
import { constants } from '@/constants';
import { useExperience } from '@/context/Experience';
import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import { wp } from '@/theme/responsiveHelpers';
import { AppText, Spacer } from '@/ui/app';
import { Brand, Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { tableColors } from '@/utilities/constants/table-colors';
import { formatBytes } from '@/utilities/helpers/formatBytes';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import sleep from '@/utilities/helpers/sleep';
import { android, ios, macOS, web } from '@/utilities/platform';
import ReplyPreview from './ReplyPreview';
import type { ReplyInfo } from './types';

const MIN_HEIGHT = 32;
const MAX_HEIGHT = ios ? 100 : 160;
const INPUT_PADDING = ios ? 5 : 10;
const CONTAINER_PADDING = 10;
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

interface Props {
  chatUnitId: string;
  conversationId: string;
  myTableUserId?: string;
  minHeight: number;
  firstMessage?: string;
}

const InputToolBar = ({
  chatUnitId,
  conversationId,
  myTableUserId,
  minHeight,
  firstMessage,
}: Props) => {
  const replyToMessage = useActiveConversationStore(
    state => state.replyToMessage,
  );
  const clearReplyToMessage = useActiveConversationStore(
    state => state.clearReplyToMessage,
  );
  const [text, setText] = useState('');
  const bottomInsets = useSafeAreaInsets().bottom;
  const [inputHeight, setInputHeight] = useState(minHeight);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const { track } = useExperience();

  // receives chat unit user if reply to message exists
  const chatUnitUser = useTableUsersStore(state =>
    state.getTableUser(replyToMessage?.chatUnitUserId),
  );
  const user = useUserStore(state => state.user);
  const isMe = user?.id === replyToMessage?.user._id;

  const { onSend, setImage } = useMessages({
    chatUnitId,
    conversationId,
    myTableUserId,
  });

  const {
    image: messageImage,
    setImage: setMessageImage,
    loading: imageUploadInProgress,
    progress: imageUploadProgress,
    cancelUpload: cancelImageUpload,
    handleImageLibrarySelection,
    handlePhotoTaken,
    handleWebImagePaste,
    thumbnail: vidThumb,
    size: imageSize,
    picking: imagePicking,
  } = useImageUpload(chatUnitId, setImage);

  const {
    document: doc,
    handleDocumentSelection,
    setDocument,
    loading: documentLoading,
    cancelUpload,
    progress,
    thumbnail: docThumb,
  } = useDocumentUpload(chatUnitId, setImage);

  const handleCancelUploadDocs = useCallback(() => {
    cancelUpload();
    setImage('');
    setMessageImage('');
    setDocument(undefined);
  }, [cancelUpload, setDocument, setImage, setMessageImage]);

  const handleCancelUploadImage = useCallback(() => {
    cancelImageUpload();
    setImage('');
    setMessageImage('');
    setDocument(undefined);
  }, [cancelImageUpload, setDocument, setImage, setMessageImage]);

  const thumbnail = vidThumb || docThumb;

  const formatMetaData = useCallback(
    (replyInfo: ReplyInfo | undefined): string | undefined => {
      if (!replyInfo) {
        return undefined;
      }

      const metaData = {
        replyInfo: {
          id: replyInfo.id.toString(),
          text: replyInfo.text,
          image: replyInfo.image || null,
          userId: replyInfo.userId || null,
          createdAt: replyInfo.createdAt,
          createdBy: replyInfo.createdBy,
        },
      };

      return JSON.stringify(metaData);
    },
    [],
  );

  const sendMessage = async () => {
    const formattedText = text;
    let metaDataString: string | undefined = undefined;

    if (replyToMessage) {
      const replyInfo: ReplyInfo = {
        id: replyToMessage._id,
        text: replyToMessage.text,
        image: replyToMessage.image,
        userId: replyToMessage.chatUnitUserId,
        createdAt: new Date(replyToMessage.createdAt).toISOString(),
        createdBy: replyToMessage.user._id,
      };
      metaDataString = formatMetaData(replyInfo);
    }

    onSend(formattedText, metaDataString);
    track('Message Sent', { conversationId, chatUnitId });
    setMessageImage('');
    setDocument(undefined);
    setInputHeight(minHeight);
    setText('');
    clearReplyToMessage(); // Clear the reply preview
  };
  const handleImageChoose = useCallback(async () => {
    setShowUploadOptions(false);
    await sleep(constants.modalRaceConditionDelay);
    if (web) {
      handleDocumentSelection(true);
    } else {
      handleImageLibrarySelection();
    }
  }, [handleDocumentSelection, handleImageLibrarySelection]);

  const handleKeyDown = async (
    e: NativeSyntheticEvent<
      TextInputKeyPressEventData & {
        shiftKey?: boolean;
        ctrlKey?: boolean;
        metaKey?: boolean;
      }
    >,
  ) => {
    if (web) {
      const modifierKey = macOS
        ? e.nativeEvent?.metaKey
        : e.nativeEvent?.ctrlKey;

      // Web Keyboard functions
      if (e.nativeEvent.key === 'Enter' && !e.nativeEvent?.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        if (text?.length > 0) await sendMessage();
        return;
      }

      if (e.nativeEvent.key === 'v' && modifierKey) {
        handleWebImagePaste();
      }
    }
  };

  const handleFileChoose = useCallback(async () => {
    setShowUploadOptions(false);
    await sleep(constants.modalRaceConditionDelay);
    handleDocumentSelection();
  }, [handleDocumentSelection]);

  const isVideo = ImageKeyHelpers.isImageKeyVideo(
    messageImage || doc?.fileName,
  );
  const document = isVideo ? null : doc;
  const containerBottomPadding = CONTAINER_PADDING + bottomInsets;

  const getTableColor = useCallback((chatUnitId: string) => {
    const colorId =
      useTableStore(state => state.getTable(chatUnitId))?.colorId ??
      ChatUnitColorId.GREEN;
    const tableColor = tableColors.find(colorObj => colorObj.id === colorId);
    return tableColor ? tableColor.color : Colors.primaryLight; // Default color if not found
  }, []);

  const themeColor = getTableColor(chatUnitId);

  const handleContentSizeChange = useCallback(
    (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
      const measuredHeight = event.nativeEvent.contentSize.height;
      if (measuredHeight > MIN_HEIGHT && measuredHeight < MAX_HEIGHT) {
        if (android || web) {
          setInputHeight(measuredHeight);
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (firstMessage && conversationId) {
      onSend(firstMessage);
    }
  }, [firstMessage, conversationId]);

  return (
    <>
      {isCameraOpen ? (
        <CameraModal
          isOpen={isCameraOpen}
          toggleCameraVisibility={() => setIsCameraOpen(o => !o)}
          onPhotoTaken={handlePhotoTaken}
        />
      ) : null}

      {showUploadOptions ? (
        <UploadOptionsSheet
          open={showUploadOptions}
          onClose={() => setShowUploadOptions(false)}
          onImageChooseOptionPress={handleImageChoose}
          onFileChooseOptionPress={handleFileChoose}
        />
      ) : null}

      <Animated.View
        style={[styles.container, { paddingBottom: containerBottomPadding }]}>
        {!(messageImage || doc) ? (
          <>
            <Pressable
              onPress={() => setIsCameraOpen(o => !o)}
              style={styles.cameraButton}>
              <OpenCameraIcon height={web ? 35 : 30} width={web ? 35 : 30} />
            </Pressable>

            <Pressable
              onPress={() => setShowUploadOptions(true)}
              style={styles.galleryButton}>
              <AddIcon height={web ? 35 : 30} width={web ? 35 : 30} />
            </Pressable>
          </>
        ) : null}

        <View style={styles.inputView}>
          {imagePicking ? (
            <>
              <View style={styles.pickerOuterView}>
                <View style={styles.pickerInnerView}>
                  <View style={styles.pickerLoaderRow}>
                    <ActivityIndicator color={Greys.shade999} size={20} />
                    <AppText
                      size={12}
                      color={Colors.neutral70}
                      style={styles.pickerText}>
                      Preparing media...
                    </AppText>
                  </View>
                </View>
              </View>
            </>
          ) : null}
          {messageImage && !isVideo ? (
            <>
              <Image source={{ uri: messageImage }} style={styles.image} />

              {imageUploadInProgress && (
                <View style={styles.imageOverlay}>
                  <ActivityIndicator color={Greys.shade0} size={'large'} />
                </View>
              )}

              <Pressable
                disabled={imageUploadInProgress}
                onPress={handleCancelUploadImage}
                style={styles.closeButton}>
                <CancelImg height={20} width={20} />
              </Pressable>
            </>
          ) : null}
          {replyToMessage && (
            <ReplyPreview
              message={replyToMessage}
              chatUnitUser={chatUnitUser}
              onCancel={clearReplyToMessage}
              isMe={isMe}
              themeColor={themeColor}
            />
          )}

          {isVideo && thumbnail ? (
            <View style={styles.videoContainer}>
              <ImageBackground
                source={{
                  uri: thumbnail.uri,
                  cacheKey: messageImage || doc?.fileName,
                }}
                style={styles.imageBg}>
                <View style={styles.videoLoadingContainer}>
                  {imageUploadInProgress || documentLoading ? (
                    <UploadImg height={24} width={24} />
                  ) : (
                    <Triangle height={24} width={24} />
                  )}
                  <AppText size={12}>
                    {formatBytes(imageSize || doc?.size || 0)}
                  </AppText>

                  {imageUploadInProgress || documentLoading ? (
                    <>
                      <View style={styles.progressBarContainer}>
                        <Spacer
                          width={`${imageUploadProgress || progress}%`}
                          height={4}
                          borderRadius={BorderRadius.xs}
                          bg={Brand.primary75}
                          zIndex={2}
                        />
                      </View>
                    </>
                  ) : (
                    <View style={styles.spacer} />
                  )}

                  {imageUploadInProgress || documentLoading ? (
                    <AppText size={12}>
                      {imageUploadProgress || progress}%
                    </AppText>
                  ) : null}
                  {!(imageUploadInProgress || documentLoading) ? (
                    <Pressable
                      onPress={
                        doc ? handleCancelUploadDocs : handleCancelUploadImage
                      }>
                      <TrashImg height={30} width={30} />
                    </Pressable>
                  ) : (
                    <Pressable
                      onPress={
                        doc ? handleCancelUploadDocs : handleCancelUploadImage
                      }>
                      <CancelImg height={30} width={30} />
                    </Pressable>
                  )}
                </View>
              </ImageBackground>
            </View>
          ) : null}

          {document && !isVideo && (
            <View style={styles.document}>
              <View style={styles.documentContainer}>
                <Image source={fileImg} style={styles.fileIcon} />

                <View style={styles.fileText}>
                  <AppText
                    size={12}
                    numberOfLines={1}
                    ellipsizeMode="middle"
                    type="primary700">
                    {document.fileName}
                  </AppText>

                  {document.size && !documentLoading ? (
                    <AppText size={12}>{formatBytes(document.size)}</AppText>
                  ) : null}
                </View>

                {!documentLoading && (
                  <Pressable onPress={handleCancelUploadDocs}>
                    <TrashImg height={30} width={30} />
                  </Pressable>
                )}
              </View>

              {documentLoading && (
                <>
                  <Spacer height={10} />
                  <Spacer height={1} width="100%" bg={Neutrals.shade100} />
                  <Spacer height={10} />
                  <View style={styles.documentLoadingContainer}>
                    <UploadImg height={24} width={24} />
                    <View style={styles.progressBarContainer}>
                      <Spacer
                        width={`${progress}%`}
                        height={4}
                        borderRadius={BorderRadius.xs}
                        bg={Brand.primary75}
                        zIndex={2}
                      />
                    </View>

                    <AppText size={12}>{progress}%</AppText>

                    <Pressable onPress={handleCancelUploadDocs}>
                      <CancelImg height={30} width={30} />
                    </Pressable>
                  </View>
                </>
              )}
            </View>
          )}

          <View style={styles.textImageContainer}>
            {messageImage ? <View style={styles.divider} /> : null}
            <AnimatedTextInput
              multiline
              numberOfLines={5}
              onChangeText={setText}
              onContentSizeChange={handleContentSizeChange}
              scrollEnabled
              onKeyPress={handleKeyDown}
              style={[
                styles.textInputStyle,
                ios ? {} : { height: inputHeight },
              ]}
              value={text}
              cursorColor={Colors.primaryLight}
              onBlur={Keyboard.dismiss}
            />
          </View>
        </View>

        {(text || messageImage || document || isVideo) &&
        !(imageUploadInProgress || documentLoading) ? (
          <Pressable
            disabled={imageUploadInProgress || documentLoading}
            onPress={sendMessage}
            style={styles.sendButton}>
            <SendButtonIcon height={30} width={30} />
          </Pressable>
        ) : null}
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  cameraButton: {
    marginRight: 10,
    width: 30,
  },
  closeButton: {
    alignItems: 'center',
    borderRadius: 10,
    height: 20,
    justifyContent: 'center',
    position: 'absolute',
    right: 10,
    top: 10,
    width: 20,
    resizeMode: 'contain',
    zIndex: 100,
  },
  container: {
    backgroundColor: Greys.shade200,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: CONTAINER_PADDING,
    paddingTop: CONTAINER_PADDING,
    width: '100%',
  },
  divider: {
    backgroundColor: Greys.shade200,
    height: 1,
    marginTop: 4,
    width: wp(75),
  },
  galleryButton: {
    marginRight: 10,
    width: 30,
  },
  image: {
    borderRadius: 6,
    height: 140,
    marginTop: 5,
    width: '100%',
  },
  imageBg: {
    borderRadius: 6,
    height: 140,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 2,
    overflow: 'hidden',
  },
  imageOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: 140,
    justifyContent: 'center',
    position: 'absolute',
    top: 5,
    left: 0,
    right: 0,
  },
  sendButton: {
    marginLeft: 10,
    width: 30,
  },
  textImageContainer: {
    alignItems: 'center',
  },
  textInputStyle: {
    fontFamily: Fonts.primary400,
    fontSize: 14,
    backgroundColor: Colors.neutral0,
    color: Colors.neutral80,
    borderRadius: 6,
    borderWidth: 0,
    marginLeft: 10,
    marginRight: 10,
    maxHeight: MAX_HEIGHT,
    minHeight: MIN_HEIGHT,
    paddingBottom: INPUT_PADDING,
    paddingHorizontal: 8,
    paddingTop: INPUT_PADDING,
    width: '100%',
  },
  document: {
    width: '100%',
    backgroundColor: Greys.shade0,
    borderColor: Neutrals.shade200,
    borderRadius: BorderRadius.md,
    marginBottom: 6,
    paddingVertical: 10,
  },
  documentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingHorizontal: 10,
  },
  inputView: {
    flex: 1,
  },
  fileIcon: {
    width: 24,
    height: 24,
    tintColor: Greys.shade999,
    resizeMode: 'contain',
  },
  fileText: {
    width: 0,
    flexGrow: 1,
  },
  progressBarContainer: {
    height: 4,
    borderRadius: BorderRadius.xs,
    backgroundColor: Neutrals.shade200,
    flexGrow: 1,
  },
  documentLoadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    backgroundColor: Greys.shade0,
    paddingHorizontal: 10,
  },
  videoLoadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    backgroundColor: Greys.shade0,
    paddingHorizontal: 10,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    paddingVertical: 8,
  },
  videoContainer: {
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    borderColor: Colors.neutral20,
    overflow: 'hidden',
    padding: 2,
    backgroundColor: Colors.neutral0,
    marginTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  pickerInnerView: {
    borderRadius: 6,
    flex: 1,
    flexGrow: 1,
    backgroundColor: Colors.neutral30,
    borderColor: Colors.neutral0,
    borderWidth: 1,
    width: '100%',
    padding: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pickerOuterView: {
    borderRadius: 6,
    height: 140,
    marginBottom: 5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.neutral30,
    padding: 1,
  },
  pickerLoaderRow: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 14,
  },
  pickerText: {
    width: 0,
    flexGrow: 1,
  },
});

export default memo(InputToolBar);
