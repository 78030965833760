import { useDrawerStatus } from '@react-navigation/drawer';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FlatList,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import GridItems from './components/GridItems';
import HelperItems from './components/HelperItems';
import ProfileButton from './components/ProfileButton';

import AddIcon from '@/assets/drawer/addPrimaryIcon.svg';
import Section from '@/components/section/Section';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import { useNewOnboardingFlow } from '@/navigation';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';
import { useNavigation } from '@react-navigation/native';
import DrawerWorkspaceItem, {
  type IDrawerWorkspaceItem,
} from './components/DrawerWorkspaceItem';

interface Props {
  closeDrawer: () => void;
}

const UserMenu = ({ closeDrawer }: Props) => {
  const { translate } = useTranslations();
  const { navigate: navigatorNavigate } = useNavigation();
  const isDrawerOpen = useDrawerStatus() === 'open';
  const { top } = useSafeAreaInsets();
  const scrollViewRef = useRef<ScrollView>(null);
  const organisations = useOrganisations();
  const user = useUserStore(state => state.user);

  const data: IDrawerWorkspaceItem[] = useMemo(() => {
    const list = organisations.map(org => {
      const role = org.members?.find(
        member => member?.userId === user?.id,
      )?.role;
      return {
        name: org.name!,
        id: org.id!,
        imageKey: org.image!,
        organisationPlan: 'Free team',
        role: role?.toUpperCase() || '',
      } as IDrawerWorkspaceItem;
    });
    const personal = {
      name: 'My Personal Space',
      id: 'personal',
      imageKey: user?.avatar,
      organisationPlan: 'Free',
      role: '',
    } as IDrawerWorkspaceItem;

    return [personal, ...list];
  }, [organisations, user]);

  const navigate = useCallback(
    (screenName: string) => {
      if (web) {
        navigatorNavigate('SplitView', {
          route: screenName,
        });
      } else {
        // @ts-ignore: Non web specific params
        navigatorNavigate(screenName);
        closeDrawer();
      }
    },
    [closeDrawer, navigatorNavigate],
  );
  const openProfile = () => {
    navigate('UserProfile');
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      scrollViewRef.current?.scrollTo({ animated: false, x: 0, y: 0 });
    }
  }, [isDrawerOpen]);

  return (
    <ScrollView
      contentContainerStyle={[styles.container, { paddingTop: top }]}
      ref={scrollViewRef}>
      <ProfileButton onPress={openProfile} />
      <Spacer width="100%" height={1} bg={Colors.neutral10} />

      <Section ph={20} mv={20}>
        <AppText size={14} type="primary700" color={Colors.neutral70}>
          {translate('my_workspaces').toUpperCase()}
        </AppText>
        <Spacer height={16} />
        <FlatList
          data={data}
          ItemSeparatorComponent={WorkspaceItemSeperator}
          scrollEnabled={false}
          renderItem={({ item }) => <DrawerWorkspaceItem {...item} />}
          ListFooterComponent={WorkspaceFooterComponent}
        />
      </Section>

      <GridItems navigate={navigate} />
      <HelperItems />
    </ScrollView>
  );
};

const WorkspaceItemSeperator = memo(() => <Spacer height={10} />);

const WorkspaceFooterComponent = memo(() => {
  const { isNewOnboardingEnabled } = useNewOnboardingFlow();
  const navigation = useNavigation();
  if (!isNewOnboardingEnabled) {
    return null;
  }

  return (
    <Pressable
      style={styles.createWorkspace}
      onPress={() => {
        if (web) {
          navigation.navigate('SplitView', {
            route: 'OnboardingCreateWorkspace',
            // @ts-ignore: Web specific params
            params: { stepNumber: 1, totalSteps: 2 },
          });
        } else {
          navigation.navigate('OnboardingCreateWorkspace', {
            stepNumber: 1,
            totalSteps: 2,
          });
        }
      }}>
      <AddIcon />
      <AppText size={14} type="primary600" color={Colors.primaryLight}>
        Create new workspace
      </AppText>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingBottom: 60,
  },
  createWorkspace: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    gap: 10,
  },
});

export default UserMenu;
