import type { SubscriptionMessage } from 'aws-amplify/datastore';
import { type Observable, Subject, type Subscription, takeUntil } from 'rxjs';

import { logger } from '@/services/logger/logger';

let subscriptions: { [key: string]: Subscription } = {};
const observables: { [key: string]: Observable<SubscriptionMessage<any>> } = {};
let subjects: Subject<any>[] = [];

export const subscriptionExists = (key: string) =>
  Object.hasOwn(subscriptions, key);

export const addSubscription = (id: string, subscription: Subscription) => {
  if (subscriptionExists(id)) {
    return;
  }
  subscriptions[id] = subscription;
};

export const addDatastoreObservable = (
  id: string,
  observable: Observable<SubscriptionMessage<any>>,
) => {
  observables[id] = observable;
};

export const getDatastoreObservable = (
  id: string,
): Observable<SubscriptionMessage<any>> => {
  const terminator = new Subject();
  subjects.push(terminator);
  return observables[id].pipe(takeUntil(terminator));
};

export const unsubscribe = (keys?: string[]) => {
  const unsubscribeList = keys || Object.keys(subscriptions); // default to all
  unsubscribeList.forEach(key => {
    try {
      subscriptions[key]?.unsubscribe();
      delete subscriptions[key];
    } catch (e) {
      logger.error('unsubscribeError', e);
    }
  });
  subscriptions = {};
  subjects.forEach(terminator => terminator.next(null));
  subjects = [];
};
