import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { StatusBar, type StatusBarStyle } from 'react-native';

import { blue4 } from '@/theme/colors';
import { Greys } from '@/ui/common/colors';
import { android } from '@/utilities/platform';

const backgroundColor: { [key in StatusBarStyle]: string } = {
  'dark-content': Greys.shade0,
  default: Greys.shade0,
  'light-content': blue4,
};

export const useStatusBarHandler = (style: StatusBarStyle) => {
  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle(style);
      if (android) {
        StatusBar.setBackgroundColor(backgroundColor[style]);
      }
    }, [style]),
  );
};
