import { BlurView } from '@react-native-community/blur';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import {
  Dimensions,
  FlatList,
  Image,
  type ListRenderItem,
  Pressable,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Share from 'react-native-share';
import { useShallow } from 'zustand/react/shallow';

import { useMyInviteStore } from '../state/useMyInvitesStore';

import Diamond from './success/Diamond';
import { InviteSuccessHeader } from './success/InviteSuccessHeader';

import infoImg from '@/assets/invites/info.png';
import shareImg from '@/assets/invites/share.png';
import { constants } from '@/constants';
import { WorkspaceIndicator } from '@/domain/chatUnit/components/chatUnitCard/WorkspaceIndicator';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { useTableStore } from '@/domain/table/state/useTableStore';
import useUserStore from '@/domain/user/state/useUserStore';
import type { MainStackScreenProps } from '@/navigation/types';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app/elements';
import { Brand, Greys, Misc, Neutrals, UserThemes } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { tableColors } from '@/utilities/constants/table-colors';
import { ios } from '@/utilities/platform';

type Props = MainStackScreenProps<'SenderInviteSuccess'>;

const SenderInviteSuccessView = ({ route }: Props) => {
  const { translate } = useTranslations();
  const { goBack } = useNavigation();
  const organisations = useOrganisations();
  const [name, username] = useUserStore(
    useShallow(state => [state.user?.name, state.user?.username]),
  );
  const {
    params: { inviteId },
  } = route;

  const invite = useMyInviteStore(state => state.getInvite(inviteId));
  const table = useTableStore(state => state.getTable(invite?.targetId!));

  const orgName = useMemo(() => {
    const org = organisations.find(org => org.id === invite?.targetInfo?.orgId);
    return org?.name;
  }, [organisations, invite]);
  const tableCardBorderStyles = useMemo(() => {
    if (!table) {
      return {};
    }

    return {
      ...styles.tableCard,
      borderBottomColor:
        tableColors.find(choice => choice.id === table.colorId)?.color ||
        UserThemes.choice10,
    };
  }, [table]);

  const numberOfInvitees = invite?.inviteeInfo.length || 0;

  const inviteLink = `${constants.inviteUrl}/?id=${inviteId}`;

  useEffect(() => {
    if (!invite) {
      logger.error(
        'SenderInviteSuccessView:: invite not found when landing on invite success screen',
      );
      goBack();
    }
  }, [invite]);

  const handleShare = useCallback(() => {
    if (!invite) {
      return;
    }

    const nameToDisplay = name || username;

    // Create the message to share
    // The message is a combination of the invite message and the invite link
    // The message is also translated
    // If there is only one invitee, the message is personalised
    // The message is then shared using the Share API
    let message = translate('invite_message', {
      chatUnitType: translate(invite.targetType!),
      chatUnitTitle: invite.targetInfo.title,
    })
      .concat(' ')
      .concat(inviteLink)
      .concat('\n\n')
      .concat(
        nameToDisplay ? `Sent by ${nameToDisplay} via the 8seats app.\n\n` : '',
      )
      .concat('What is 8seats? Learn more here: https://8seats.com');

    if (invite.inviteeInfo.length === 1) {
      message = message.replace('Hey', `Hi ${invite.inviteeInfo[0].name}`);
    }

    const inviteePhone = invite.inviteeInfo?.[0]?.phone;
    try {
      Share.open({
        title: translate('invite_title'),
        message,
        failOnCancel: false,
        subject: translate('invite_title'),
        recipient: invite.inviteeInfo.length === 1 ? inviteePhone : undefined,
      });
    } finally {
      // No-op
    }
  }, [invite, inviteLink, name, translate, username]);

  if (!invite) {
    // If the invite is not found

    // No-op for now
    // TODO: Show an invite not found screen
    return null;
  }

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="dark-content"
      />

      <ScrollView style={styles.container}>
        <InviteSuccessHeader invite={invite} />

        <View style={tableCardBorderStyles}>
          <View style={styles.pill}>
            <WorkspaceIndicator organisationName={orgName} size={12} />
            <AppText size={12}>{translate(invite.targetType!)}</AppText>
          </View>
          <View style={styles.blurContentContainer}>
            <View style={styles.tableCardContents}>
              <AppText size={22} textAlign="center" type="primary700">
                {translate(invite.targetInfo.title)}
              </AppText>
            </View>
            {ios ? (
              <BlurView
                blurAmount={10}
                blurType="light"
                style={StyleSheet.absoluteFillObject}
              />
            ) : (
              <View style={styles.headerInfoBackground} />
            )}
          </View>
        </View>
        <Spacer height={44} />
        <AppText
          size={12}
          color={Neutrals.shade600}
          type="primary700"
          textAlign="center">
          {translate('added_to_this').toUpperCase()}{' '}
          {translate(invite.targetType!).toUpperCase()}
          {numberOfInvitees > 1 ? ` (${numberOfInvitees})` : ''}
        </AppText>
        <View style={styles.inviteListRow}>
          <FlatList
            contentContainerStyle={styles.inviteeList}
            data={invite.inviteeInfo}
            ItemSeparatorComponent={Seperator}
            renderItem={renderItem}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
          />
          <LinearGradient
            colors={[`${Greys.shade0}00`, Greys.shade0]}
            start={{ x: 0.0, y: 0.0 }} // Start at right
            end={{ x: 1.0, y: 0.0 }} // End at left
            angle={270}
            style={styles.listEndGradientStyles}
          />
        </View>
        <Spacer height={1} width="100%" bg={Neutrals.shade100} />
        <Spacer height={10} />

        <AppText
          color={Neutrals.shade700}
          size={30}
          type="primary700"
          textAlign="center">
          {translate('final_step')}
        </AppText>
        <Spacer height={10} />
        <AppText size={14} textAlign="center">
          {translate('invite_them_personalised')}
        </AppText>
        <Pressable onPress={handleShare} style={styles.link}>
          {/**
           * Create the message to share. The message is a combination of the
           * invite message and the invite link. The message is also translated.
           * If there is only one invitee, the message is personalised
           */}
          <AppText type="primary500" size={14} numberOfLines={3}>
            {translate('invite_message', {
              chatUnitType: translate(invite.targetType!),
              chatUnitTitle: invite.targetInfo.title,
            }).replace(
              'Hey', // Replace the default "Hey" with "Hi" for a single invitee
              invite.inviteeInfo.length === 1
                ? `Hi ${invite.inviteeInfo[0].name}`
                : 'Hey',
            )}{' '}
            <AppText type="primary700" size={14} color={Brand.primary75}>
              {inviteLink}
            </AppText>
          </AppText>
        </Pressable>
        <AppText textAlign="center" color={Neutrals.shade700} size={12}>
          {translate('invite_editable')}
        </AppText>
        <Pressable style={styles.btn} onPress={handleShare}>
          <Image source={shareImg} style={styles.share} />
          <AppText color={Greys.shade0} size={17} type="primary700">
            {translate('share_invite_link')}
          </AppText>
        </Pressable>
        <View style={styles.infoBox}>
          <Image source={infoImg} />
          <AppText
            size={14}
            color={Neutrals.shade700}
            style={styles.infoTextFit}>
            {translate('invite_info')}
          </AppText>
        </View>
        <Pressable onPress={goBack} style={styles.doneBtn}>
          <AppText color={Neutrals.shade800} size={17} type="primary700">
            {translate('done')}
          </AppText>
        </Pressable>
        <Spacer height={40} />
      </ScrollView>
    </>
  );
};

const Seperator = () => <Spacer width={16} />;

const renderItem: ListRenderItem<Record<string, any>> = ({ item }) => {
  return (
    <View style={styles.avatarRow}>
      <View style={styles.avatar}>
        <AppText color={Greys.shade0} size={10}>
          {getInviteeInitials(item.name)}
        </AppText>
      </View>
      <AppText color={Neutrals.shade700} size={14}>
        {item.name}
      </AppText>
    </View>
  );
};

/**
 * Get the initials of the invitee
 * @param name Name of the invitee
 * @returns The initials of the invitee in uppercase
 * @example
 * getInviteeInitials('John Doe') // JD
 * getInviteeInitials('John') // J
 */
function getInviteeInitials(name: string) {
  const [firstName, lastName] = name.split(' ');
  const firstInitial = firstName[0];
  const lastInitial = lastName && lastName[0];
  return `${firstInitial}${lastInitial ? lastInitial : ''}`.toUpperCase();
}

const AVATAR_SIZE = 24;
const SCREEN_WIDTH = Dimensions.get('window').width;

const styles = StyleSheet.create({
  avatar: {
    alignItems: 'center',
    backgroundColor: UserThemes.choice3,
    borderRadius: 20,
    color: Greys.shade0,
    height: AVATAR_SIZE,
    justifyContent: 'center',
    width: AVATAR_SIZE,
  },
  avatarRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
  container: {
    backgroundColor: Greys.shade0,
  },
  headerInfoBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Greys.shade0,
    opacity: 0.7,
  },
  inviteeList: {
    paddingHorizontal: 20,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    borderColor: Neutrals.shade100,
    borderRadius: BorderRadius.md,
    borderWidth: 1,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 16,
    paddingVertical: 10,
    paddingHorizontal: 16,
    width: SCREEN_WIDTH - 40,
    alignSelf: 'center',
  },
  tableCard: {
    alignItems: 'center',
    alignSelf: 'center',
    borderBottomWidth: 10,
    borderRadius: BorderRadius.md,
    justifyContent: 'center',
    marginTop: 40,
    maxWidth: 332,
    width: SCREEN_WIDTH - 40,
    transform: [{ translateY: 24 }],
    zIndex: 8,
  },
  tableCardContents: {
    paddingTop: 24,
    paddingBottom: 14,
    zIndex: 11,
  },
  pill: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: BorderRadius.lg,
    backgroundColor: Greys.shade0,
    alignSelf: 'center',
    zIndex: 15,
    position: 'absolute',
    top: 0,
    transform: [{ translateY: -16 }],
  },
  blurContentContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: BorderRadius.md,
    borderTopRightRadius: BorderRadius.md,
  },
  listEndGradientStyles: {
    width: 40,
    height: 24,
    backgroundColor: Misc.transparent,
    zIndex: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    alignSelf: 'center',
  },
  inviteListRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 20,
  },
  btn: {
    borderRadius: 20,
    paddingVertical: 10,
    gap: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Brand.primary75,
    alignSelf: 'center',
    width: SCREEN_WIDTH - 40,
    marginTop: 28,
    marginBottom: 20,
  },
  share: {
    width: 24,
    height: 24,
  },
  infoBox: {
    flexDirection: 'row',
    padding: 10,
    gap: 10,
    backgroundColor: Neutrals.shade100,
    borderRadius: BorderRadius.sm,
    alignSelf: 'center',
    width: SCREEN_WIDTH - 40,
  },
  infoTextFit: {
    width: 0,
    flexGrow: 1,
  },
  doneBtn: {
    alignItems: 'center',
    borderColor: Neutrals.shade800,
    borderRadius: 20,
    borderWidth: 2,
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 30,
    alignSelf: 'center',
    marginTop: 40,
  },
});

export default SenderInviteSuccessView;
