import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  Extrapolation,
  useAnimatedStyle,
  interpolate,
  withTiming,
  useDerivedValue,
  type SharedValue,
  runOnJS,
} from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { AvatarStatusIcon } from './AvatarStatusIcon';

import AppAvatar from '@/components/avatar';
import { constants } from '@/constants';
import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import { getName } from '@/domain/user/functions/getName';
import type { ChatUnitUser } from '@/services/types';
import { wp } from '@/theme';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

// Destructure constants for easier access
const { onHeadTapDebounceDelay, chatHeadAnimationDuration } = constants;

interface AnimatedAvatarProps {
  index: number;
  numberOfUsers: number;
  offset: SharedValue<number>;
  onPressChatHead: ({
    index,
    isLongPress,
  }: {
    index: number;
    isLongPress?: boolean;
  }) => void;
  tableUser: ChatUnitUser;
  targetIndex: SharedValue<number | undefined>;
  size: number;
  betweenIconSpace: number;
}

const AnimatedAvatar: React.FC<AnimatedAvatarProps> = ({
  index,
  numberOfUsers,
  offset,
  onPressChatHead,
  tableUser,
  targetIndex,
  size,
  betweenIconSpace,
}) => {
  // Ref to store the timestamp of the last press
  const lastPress = useRef(Date.now());

  // Get active conversation data from the store
  const [activeConversationIds, conversationUserIds] =
    useActiveConversationStore(
      useShallow(state => [
        state.activeConversationIds,
        state.conversationUserIds,
      ]),
    );

  // State to track the current selection status of the avatar
  const [currSelection, setCurrSelection] = useState<boolean[]>([false, false]);

  // Update the avatar selection state when conversation data changes
  useEffect(() => {
    if (!activeConversationIds.length) {
      setCurrSelection(new Array(numberOfUsers).fill(false));
      return;
    }

    const newSelection = activeConversationIds.map(
      cid => !!conversationUserIds.get(cid)?.includes(tableUser.id),
    );

    // Ensure the selection array has at least 2 elements and matches the number of users
    while (newSelection.length < 2 || newSelection.length < numberOfUsers) {
      newSelection.push(false);
    }

    setCurrSelection(newSelection);
  }, [activeConversationIds, conversationUserIds, numberOfUsers, tableUser.id]);

  // Memoize input and output ranges for animations
  const inputRange = useMemo(
    () => currSelection.map((_, i) => i),
    [currSelection],
  );
  const outputRange = useMemo(
    () => currSelection.map(v => (v ? 0 : size - 10)),
    [currSelection, size],
  );

  // Animate avatar vertical position
  const avatarTopOffset = useDerivedValue(() => {
    if (targetIndex.value !== undefined && targetIndex.value % 1 === 0) {
      return withTiming(currSelection[targetIndex.value] ? 0 : size - 10, {
        duration: chatHeadAnimationDuration,
      });
    }
    return interpolate(
      offset.value,
      inputRange,
      outputRange,
      Extrapolation.CLAMP,
    );
  }, [targetIndex.value, currSelection, size]);

  // Animate name opacity
  const nameOpacity = useDerivedValue(() => {
    if (targetIndex.value !== undefined) {
      return withTiming(currSelection[targetIndex.value] ? 1 : 0.5, {
        duration: chatHeadAnimationDuration,
      });
    }
    return interpolate(
      offset.value,
      inputRange,
      currSelection.map(v => (v ? 1 : 0.5)),
      Extrapolation.CLAMP,
    );
  }, [currSelection, inputRange, offset, outputRange, targetIndex]);

  // Animated styles for avatar and name
  const animatedAvatarStyles = useAnimatedStyle(() => ({
    transform: [{ translateY: avatarTopOffset.value }],
  }));

  const animatedNameStyles = useAnimatedStyle(() => ({
    opacity: nameOpacity.value,
  }));

  // Handle press events with debounce
  const handlePress = useCallback(() => {
    if (Date.now() - lastPress.current >= onHeadTapDebounceDelay) {
      runOnJS(onPressChatHead)({ index });
      lastPress.current = Date.now();
    }
  }, [index, onPressChatHead]);

  const handleLongPress = useCallback(() => {
    if (Date.now() - lastPress.current >= onHeadTapDebounceDelay) {
      runOnJS(onPressChatHead)({ index, isLongPress: true });
      lastPress.current = Date.now();
    }
  }, [index, onPressChatHead]);

  // Memoized container style for the avatar
  const containerStyle = useMemo(
    () => ({
      width: size,
      height: size,
      marginRight: betweenIconSpace,
    }),
    [size, betweenIconSpace],
  );

  return (
    <Pressable
      onLongPress={handleLongPress}
      onPress={handlePress}
      style={[styles.container, containerStyle]}>
      <View style={[styles.avatar, { width: size, height: size }]}>
        <Animated.View style={animatedAvatarStyles}>
          <AppAvatar
            size={size}
            imageKey={tableUser.avatar}
            displayName={getName(tableUser, 'short')}
          />
        </Animated.View>
        <AvatarStatusIcon status={tableUser.status} />
      </View>
      <Animated.Text
        numberOfLines={1}
        style={[styles.name, animatedNameStyles]}>
        {getName(tableUser, 'short')}
      </Animated.Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  avatar: {
    overflow: 'hidden',
  },
  name: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    overflow: 'visible',
    fontSize: 10,
    fontWeight: '400',
    marginTop: 4,
    maxWidth: web ? '100%' : wp(10),
  },
});

export default React.memo(AnimatedAvatar);
