import { Dimensions, StyleSheet } from 'react-native';

import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  backgroundImage: {
    borderRadius: 6,
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  cardActivityText: {
    color: Greys.shade500,
    fontSize: 10,
    lineHeight: 12,
  },
  cardContent: {
    alignItems: 'center',
    backgroundColor: `${Greys.shade0}F2`,
    borderRadius: 6,
    height: 170,
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 14,
  },
  cardTypeText: {
    color: Greys.shade500,
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 16,
    marginTop: 10,
  },
  icon: {
    marginRight: 4,
  },
  iconLabel: {
    fontSize: 10,
  },
  iconLabelContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    ...(web && { flexShrink: 1 }),
  },
  invisible: {
    opacity: 0,
  },
  roomCard: {
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.sm,
    elevation: 5,
    height: 248,
    shadowColor: Greys.shade999,
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    ...(web && { margin: 5, flexShrink: 1, flexGrow: 1 }),
    width: web ? undefined : Dimensions.get('window').width / 2 - 15,
  },
  settingsButton: {
    position: 'absolute',
    top: 0,
    zIndex: 10,
  },
  cardBg: {
    flex: 1,
    zIndex: -2,
    borderRadius: BorderRadius.sm,
    overflow: 'hidden',
  },
});
