import React, { useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import GroupChat from './GroupChat';
import OneOnOneChat from './OneOnOneChat';

import SecondaryHeader from '@/components/headers/SecondaryHeader';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app/elements';

export const CreateDM = () => {
  const [activeTab, setActiveTab] = useState<'oneonone' | 'group'>('oneonone');
  const { translate } = useTranslations();
  const { top } = useSafeAreaInsets();

  return (
    <View style={styles.container}>
      <View style={{ paddingTop: top }}>
        <SecondaryHeader
          backButtonText={'common_back'}
          title={translate('new_dm')}
          style={styles.noOpacity}
        />
      </View>
      <View style={styles.tabOuterContainer}>
        <View style={styles.tabContainer}>
          <TouchableOpacity
            style={[styles.tab, activeTab === 'oneonone' && styles.activeTab]}
            onPress={() => setActiveTab('oneonone')}>
            <AppText
              style={[
                styles.tabText,
                activeTab === 'oneonone' && styles.activeTabText,
              ]}
              type="primary700">
              {translate('1-on-1_Chat')}
            </AppText>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.tab, activeTab === 'group' && styles.activeTab]}
            onPress={() => setActiveTab('group')}>
            <AppText
              style={[
                styles.tabText,
                activeTab === 'group' && styles.activeTabText,
              ]}
              type="primary700">
              {translate('Group_Chat')}
            </AppText>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.content}>
        {activeTab === 'oneonone' ? <OneOnOneChat /> : <GroupChat />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.neutral0,
  },
  header: {
    height: 44,
    backgroundColor: Colors.neutral0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  backButtonContainer: {
    position: 'absolute',
    left: 20,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  titleContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    fontSize: 17,
    fontWeight: '700',
    color: Colors.neutral100,
  },
  tabOuterContainer: {
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 10,
    backgroundColor: Colors.neutral0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.neutral20,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.neutral10,
    borderRadius: 12,
    padding: 5,
    gap: 2,
    borderWidth: 1,
    borderColor: Colors.neutral20,
  },
  tab: {
    flex: 1,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
  },
  activeTab: {
    backgroundColor: Colors.primaryLight,
  },
  tabText: {
    color: Colors.neutral80,
    fontWeight: '700',
    fontSize: 12,
  },
  activeTabText: {
    color: Colors.neutral0,
  },
  content: {
    flex: 1,
  },
  noOpacity: { shadowOpacity: 0 },
});

export default CreateDM;
