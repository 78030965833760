import * as React from 'react';
import { useMemo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  Extrapolate,
  useAnimatedStyle,
  interpolate,
  withTiming,
  useSharedValue,
} from 'react-native-reanimated';

import RoomsChatHead from '@/components/rooms-chathead';
import { constants } from '@/constants';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';

const { avatarSize } = getAvatarConfig();
const onHeadTapDebounceDelay = constants.onHeadTapDebounceDelay;
const animationDuration = constants.chatHeadAnimationDuration;

interface AnimatedRoomsChatheadProps {
  index: number;
  numberOfUsers: number;
  offset: Animated.SharedValue<number>;
  onPressChatHead: ({
    index,
    isLongPress,
  }: {
    index: number;
    isLongPress?: boolean;
  }) => void;
}

const AnimatedRoomsChathead = ({
  index,
  numberOfUsers,
  offset,
  onPressChatHead,
}: AnimatedRoomsChatheadProps) => {
  const lastPress = React.useRef(Date.now());

  const { translate } = useTranslations();
  const isWithTiming = useSharedValue(false);
  const currSelection = useMemo(
    // `true` depicts the position of the allConversation in the conversations array
    () => [true, false],
    [],
  );

  const inputRange = React.useMemo(
    () => currSelection.map((_, i) => i),
    [currSelection],
  );

  const outputRange = React.useMemo(
    // if `true` then we're mapping the allConversation, so we want the "Others" chathead up (value of zero)
    // otherwise we want the chatHead down, `avatarSize - 10` which is the vertical transformation offset
    () => currSelection.map(v => (v ? 0 : avatarSize - 10)),
    [currSelection],
  );

  const imageStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      offset.value,
      inputRange,
      outputRange,
      Extrapolate.EXTEND,
    );

    return {
      transform: [
        {
          translateY: withTiming(
            translateY,
            {
              // isWithTiming is used to animate chat heads after tapping a head
              duration: isWithTiming.value ? animationDuration : 0,
            },
            // reset isWithTiming value to allow swipe to animate with offset value
            () => (isWithTiming.value = false),
          ),
        },
      ],
    } as any;
  });

  const stylezText = useAnimatedStyle(() => {
    const outputRangeOpacity = currSelection.map(v => (v ? 1 : 0.5));
    const opacity = interpolate(
      offset.value,
      inputRange,
      outputRangeOpacity,
      Extrapolate.EXTEND,
    );

    return {
      opacity: withTiming(
        opacity,
        {
          // isWithTiming is used to animate chat heads after tapping a head
          duration: isWithTiming.value ? animationDuration : 0,
        },
        // reset isWithTiming value to allow swipe to animate with offset value
        () => (isWithTiming.value = false),
      ),
    };
  });

  const textStyle = [styles.name, stylezText];

  return (
    <Pressable
      onLongPress={() => {
        if (Date.now() - lastPress.current >= onHeadTapDebounceDelay) {
          isWithTiming.value = true;
          onPressChatHead({ index });
          lastPress.current = Date.now();
        }
      }}
      onPress={() => {
        if (Date.now() - lastPress.current >= onHeadTapDebounceDelay) {
          isWithTiming.value = true;
          onPressChatHead({ index });
          lastPress.current = Date.now();
        }
      }}
      style={styles.container}>
      <View style={[styles.avatar, index === 0 ? styles.marginLeft : {}]}>
        <Animated.View style={imageStyle}>
          <RoomsChatHead size={avatarSize} text={numberOfUsers.toString()} />
        </Animated.View>
        <View style={styles.dot} />
      </View>
      <Animated.Text numberOfLines={1} style={textStyle}>
        {translate('Others')}
      </Animated.Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  avatar: {
    height: avatarSize,
    overflow: 'hidden',
    width: avatarSize,
  },
  container: {
    alignItems: 'center',
    height: avatarSize,
    marginRight: 6,
    width: avatarSize,
    zIndex: 100,
  },
  defaultAvatar: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultAvatarText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    fontWeight: '400',
    marginTop: 4,
  },
  dot: {
    borderRadius: avatarSize / 6,
    bottom: 4,
    height: avatarSize / 6,
    left: 4,
    position: 'absolute',
    width: avatarSize / 6,
  },
  marginLeft: { marginLeft: 0 },
  name: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    fontWeight: '400',
    marginTop: 4,
    maxWidth: wp(10),
  },
});
export default React.memo(AnimatedRoomsChathead);
