import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';

import Avatar from '@/components/avatar';
import { getName } from '@/domain/user/functions/getName';
import type { ChatUnitUser } from '@/services/types';
import { Greys } from '@/ui/common/colors';

type Props = {
  tableUsers: ChatUnitUser[];
};

const AnimatedView = Animated.createAnimatedComponent(ScrollView);

export const MembersList = ({ tableUsers }: Props) => {
  return (
    <AnimatedView
      entering={FadeInRight}
      exiting={FadeOutLeft}
      style={styles.usersLayout}>
      {tableUsers.map(tableUser => {
        return (
          <View key={tableUser.chatUnitUserId} style={styles.userContainer}>
            <Avatar
              key={tableUser.id}
              size={40}
              imageKey={tableUser.avatar}
              displayName={getName(tableUser, 'short')}
            />
            <Text style={styles.username}>{getName(tableUser, 'full')}</Text>
          </View>
        );
      })}
    </AnimatedView>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10,
  },
  username: {
    color: Greys.shade0,
    fontSize: 14,
    marginLeft: 12,
    marginTop: 6,
  },
  usersLayout: {
    flexDirection: 'column',
  },
});
