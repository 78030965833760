import UpIcon from '@/assets/chatview/up.svg';
import ArrowLeftIcon from '@/assets/svg/back-arrow-black.svg';
import Avatar from '@/components/avatar';
import NetworkImage from '@/components/image/NetworkImage';
import FreePill from '@/components/organisation/FreePill';
import Section from '@/components/section/Section';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { web } from '@/utilities/platform';
import type React from 'react';

import { memo, useMemo, useState } from 'react';
import {
  FlatList,
  Modal,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

interface Props {
  visible: boolean;
  onClose: (selectedOrganisationId?: string) => void;
}

const SwitchWorkspaceModal: React.FC<Props> = ({ visible, onClose }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = useUserStore(state => state.user);
  const { translate } = useTranslations();
  const organisations = useOrganisations();
  const orgList = useMemo(() => {
    if (organisations.length) {
      return organisations
        ?.filter(org => isActiveMemberOfOrg([org], org.id, user?.id))
        .map(org => ({
          id: org?.id!,
          image: org?.image!,
          name: org?.name!,
        }))
        .concat({
          id: 'personal',
          image: user?.avatar!,
          name: translate('personal_workspace'),
        });
    }
    return [
      {
        id: 'personal',
        image: user?.avatar!,
        name: translate('personal_workspace'),
      },
    ];
  }, [organisations, translate, user?.avatar, user?.id]);

  const [selectedOrganisation, setSelectedOrganisation] = useState(orgList[0]);
  const handleConfirm = async () => {
    onClose(selectedOrganisation.id);
  };

  const itemContainerStyles = useMemo(
    () => ({
      borderBottomColor: showDropdown ? Colors.neutral10 : Colors.neutral30,
      borderBottomLeftRadius: showDropdown ? 0 : 3,
      borderBottomRightRadius: showDropdown ? 0 : 3,
      width: web ? 500 : 300,
    }),
    [showDropdown],
  );

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={() => onClose()}>
      <View style={styles.modalOverlay}>
        <View
          style={[
            styles.modalContent,
            { height: showDropdown ? (web ? 450 : 550) : web ? 420 : 440 },
          ]}>
          <AppText
            type="primary700"
            style={styles.modalTitle}
            size={17}
            color={Colors.neutral80}>
            Switch Workspace
          </AppText>

          <AppText size={14} style={styles.modalText} color={Colors.neutral80}>
            You can choose to create this DM in a different workspace if you
            need to.
          </AppText>
          <Spacer height={10} />
          <AppText size={14} style={styles.modalText} color={Colors.neutral80}>
            This will affect how your message data is stored. We recommend that
            work messages are stored in the relevant business workspace and
            personal messages are kept in your personal space.
          </AppText>

          <Section ph={20}>
            <Spacer height={15} />
            <Section
              onPress={() => setShowDropdown(toggle => !toggle)}
              br={3}
              bw={1}
              bc={Colors.neutral30}
              row
              w100
              aic
              bg={Colors.neutral0}
              style={itemContainerStyles}>
              <Spacer width={5} />
              {selectedOrganisation.id === 'personal' ||
              !selectedOrganisation.image ? (
                <Avatar
                  size={40}
                  displayName={
                    selectedOrganisation.id === 'personal'
                      ? user?.name || ''
                      : selectedOrganisation.name
                  }
                  imageKey={selectedOrganisation.image}
                  style={{ borderRadius: BorderRadius.xs }}
                />
              ) : (
                <NetworkImage
                  imageKey={selectedOrganisation.image}
                  width={40}
                  height={40}
                  borderRadius={BorderRadius.xs}
                />
              )}
              <Spacer width={15} />
              <AppText
                size={14}
                type="primary600"
                style={dropdownStyles.overflowText}>
                {selectedOrganisation.name}
              </AppText>
              <FreePill
                plan={
                  selectedOrganisation.id === 'personal' ? 'Free' : 'Free team'
                }
              />
              <Spacer width={5} />
              {!showDropdown ? (
                <>
                  <Spacer height="100%" bg={Colors.neutral30} width={1} />
                  <Section
                    jcc
                    aic
                    gap={4}
                    ph={20}
                    bg={Colors.neutral05}
                    style={dropdownStyles.arrowsContainer}>
                    <View>
                      <UpIcon />
                    </View>
                    <View style={styles.rotateUpToDown}>
                      <UpIcon />
                    </View>
                  </Section>
                </>
              ) : (
                <Spacer height={50} width={0} />
              )}
            </Section>
            {showDropdown ? (
              <FlatList
                data={orgList.filter(org => org.id !== selectedOrganisation.id)}
                ItemSeparatorComponent={DropdownItemSeperator}
                contentContainerStyle={dropdownStyles.contentContainer}
                style={styles.dropdownList}
                renderItem={({ item }) => (
                  <Pressable
                    onPress={() => {
                      setSelectedOrganisation(item);
                      setShowDropdown(false);
                    }}>
                    <Section pv={5} row aic bg={Colors.neutral05}>
                      <Spacer width={5} />
                      {item.id === 'personal' || !item.image ? (
                        <Avatar
                          size={40}
                          displayName={
                            item.id === 'personal'
                              ? user?.name || ''
                              : item.name
                          }
                          imageKey={item.image}
                          style={{ borderRadius: BorderRadius.xs }}
                        />
                      ) : (
                        <NetworkImage
                          imageKey={item.image}
                          width={40}
                          height={40}
                          borderRadius={BorderRadius.xs}
                        />
                      )}
                      <Spacer width={15} />
                      <AppText size={14} style={dropdownStyles.overflowText}>
                        {item.name}
                      </AppText>
                      <FreePill
                        plan={item.id === 'personal' ? 'Free' : 'Free team'}
                      />
                      <Spacer width={5} />
                    </Section>
                  </Pressable>
                )}
              />
            ) : null}
          </Section>

          <LinearGradient
            colors={[Colors.neutral40, 'rgba(151, 151, 151, 0)']}
            style={styles.divider1}
          />

          <TouchableOpacity
            style={styles.switchButton}
            onPress={handleConfirm}
            activeOpacity={0.7}>
            <AppText size={17} color={Colors.peppermint}>
              Switch Workspace
            </AppText>
          </TouchableOpacity>

          <View style={styles.divider2} />

          <TouchableOpacity
            style={styles.cancelButton}
            onPress={() => onClose()}
            activeOpacity={0.7}>
            <AppText color={Colors.neutral80} size={17}>
              Cancel
            </AppText>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const DropdownItemSeperator = memo(() => {
  return <Spacer width="100%" height={1} bg={Colors.neutral10} />;
});
const dropdownStyles = StyleSheet.create({
  overflowText: {
    width: 0,
    flexGrow: 1,
  },
  rotateLeftToUp: {
    transform: [{ rotate: '90deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
  rotateLeftToDown: {
    transform: [{ rotate: '-90deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowsContainer: {
    height: 50,
  },
  contentContainer: {
    backgroundColor: Colors.neutral05,
    borderColor: Colors.neutral30,
    borderWidth: 1,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderTopWidth: 0,
    zIndex: 4,
    transform: [{ translateY: -1 }],
  },
});
const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: Colors.neutral0,
    borderRadius: 18,
    paddingVertical: 20,
    paddingHorizontal: 25,
    width: web ? 535 : 335,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 40,
  },
  modalTitle: {
    textAlign: 'center',
    marginBottom: 15,
    paddingTop: 10,
  },
  modalText: {
    textAlign: 'center',
    marginBottom: 8,
  },
  divider1: {
    width: web ? 535 : 335,
    height: 10,
    opacity: 0.25,
    marginTop: 30,
  },
  divider2: {
    width: web ? 515 : 315,
    height: 1,
    backgroundColor: Colors.neutral80,
    opacity: 0.25,
  },
  switchButton: {
    paddingHorizontal: 24,
    alignItems: 'center',
    marginVertical: 16,
  },
  cancelButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  dropdownList: { maxHeight: 110 },
  rotateUpToDown: {
    transform: [{ rotate: '180deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SwitchWorkspaceModal;
