import React, { useCallback, useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import DeviceInfo from 'react-native-device-info';

import HelperItem from './HelperButton';
import RefreshDataModal from './RefreshDataModal';

import Caption from '@/components/text/Caption';
import { constants } from '@/constants';
import { useExperience } from '@/context/Experience';
import AuthService from '@/domain/auth/AuthService';
import { Colors } from '@/domain/theme/Colors'; // Importing Colors
import useNavigation from '@/hooks/useNavigation';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import {
  LICENCE_AGREEMENT,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from '@/utilities/constants';
import { web } from '@/utilities/platform';

const webReference = (fileName: string): string | undefined => {
  if (!web) {
    return;
  }

  return `/assets/menu-icons/${fileName}`;
};

const currentVersion = DeviceInfo.getVersion();

const HelperItems = () => {
  const { translate } = useTranslations();
  const version = `${currentVersion}.${constants.codePushVersion}`;

  const { resetPostHogSession, track } = useExperience();
  const { navigate } = useNavigation();
  const [isRefreshModalVisible, setIsRefreshModalVisible] = useState(false);

  const openExternalLink = (url: string) => {
    Linking.openURL(url);
  };

  const signout = useCallback(async () => {
    track('Logout');
    resetPostHogSession();
    await AuthService.logout();
  }, [resetPostHogSession, track]);

  const handleRefresh = () => {
    setIsRefreshModalVisible(true);
  };

  return (
    <View>
      <AppText type="primary700" size={15} style={styles.sectionTitle}>
        {translate('user_menu_get_help_title')}
      </AppText>

      <HelperItem
        iconSource={
          webReference('coffee.png') ??
          require('@/assets/menu-icons/coffee.png')
        }
        onPress={() => {
          if (web) {
            navigate('SplitView', {
              route: 'OnboardingVideo',
              fromMain: true,
            });
          } else {
            navigate('OnboardingVideo');
          }
        }}
        title={translate('user_menu_getting_started_button_title')}
      />

      <HelperItem
        iconSource={
          webReference('gift.png') ?? require('@/assets/menu-icons/gift.png')
        }
        onPress={() =>
          openExternalLink(
            'https://8seats.gitbook.io/8seats-guides-and-how-tos/hints-and-tips',
          )
        }
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_hints_button_title')}
      />

      <HelperItem
        iconSource={
          webReference('question-help.png') ??
          require('@/assets/menu-icons/question-help.png')
        }
        onPress={() =>
          openExternalLink(
            'https://8seats.gitbook.io/8seats-guides-and-how-tos/faqs',
          )
        }
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_faq_button_title')}
      />

      <HelperItem
        iconSource={
          webReference('thumb-up-outline.png') ??
          require('@/assets/menu-icons/thumb-up-outline.png')
        }
        onPress={() => openExternalLink('https://forms.gle/mzvXrM3y6KhXrsQX7')}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_share_feedback_button_title')}
      />

      <HelperItem
        iconSource={
          webReference('debug.png') ?? require('@/assets/menu-icons/debug.png')
        }
        onPress={() => openExternalLink('https://forms.gle/ov9yQVQSqRbtqpuJA')}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_report_bug_button_title')}
      />

      <AppText type="primary700" size={15} style={styles.sectionTitle}>
        {translate('user_menu_learn_about_section_title')}
      </AppText>

      <HelperItem
        onPress={() => openExternalLink('http://8seats.com')}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_about_button_title')}
      />

      <AppText type="primary700" size={15} style={styles.sectionTitle}>
        {translate('user_menu_important_stuff_section_title')}
      </AppText>

      <HelperItem
        onPress={() => openExternalLink(TERMS_OF_USE)}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_privacy_policy_button_title')}
      />

      <HelperItem
        onPress={() => openExternalLink(PRIVACY_POLICY)}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('privacy_policy')}
      />

      <HelperItem
        onPress={() => openExternalLink(LICENCE_AGREEMENT)}
        rightIconSource={
          webReference('external.png') ??
          require('@/assets/menu-icons/external.png')
        }
        title={translate('user_menu_eua_button_title')}
      />

      {!web && (
        <>
          <AppText type="primary700" size={15} style={styles.sectionTitle}>
            {translate('user_menu_version_section_title')}
          </AppText>

          <HelperItem
            badgeTitle={translate('early_access_badge_label')}
            hasBadge={true}
            title={translate('user_menu_version_title', {
              version,
            })}
          />

          {Config.ENV_URL !== 'dev' && (
            <>
              <AppText type="primary700" size={15} style={styles.sectionTitle}>
                Build number
              </AppText>
              <HelperItem title={web ? DeviceInfo.getBuildNumber() : version} />
            </>
          )}
        </>
      )}

      <AppText type="primary700" size={15} style={styles.sectionTitle}>
        {translate('my_account')}
      </AppText>

      <HelperItem
        color={Colors.rasberry} // Using rasberry color
        onPress={handleRefresh}
        rightIconSource={
          webReference('sync.png') ?? require('@/assets/menu-icons/sync.png')
        }
        title={translate('refresh_account')}
      />
      {/* Other HelperItems */}
      <RefreshDataModal
        visible={isRefreshModalVisible}
        onClose={() => setIsRefreshModalVisible(false)}
      />

      <HelperItem
        color={Colors.rasberry} // Using rasberry color
        onPress={signout}
        rightIconSource={
          webReference('sign-out.png') ??
          require('@/assets/menu-icons/sign-out.png')
        }
        title={translate('user_menu_logout_button_title')}
      />

      <Caption style={styles.trademark}>
        {translate('user_menu_trademark').replace(
          '{year}',
          new Date().getFullYear().toString(),
        )}
      </Caption>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  logoutButton: {
    marginTop: 20,
  },
  sectionTitle: {
    color: Colors.neutral40, // Using neutral40 color
    marginBottom: 20,
    marginLeft: 20,
    marginTop: 30,
    textAlign: 'left',
  },
  trademark: {
    color: Colors.neutral40, // Using neutral40 color
    fontFamily: 'OpenSans-Regular',
    fontSize: 13,
    marginLeft: 20,
    marginTop: 30,
    textAlign: 'left',
  },
});
export default HelperItems;
