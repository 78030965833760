import React from 'react';
import { View, type ViewProps } from 'react-native';

import { BorderRadius } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';

interface Props extends ViewProps {
  fillColor: Color; // accepted theme color value
  size: number; // a number that will be used to define the size of the dot
}

const Dot = ({ fillColor, id, size }: Props) => {
  const styles = {
    borderRadius: BorderRadius.full,
    backgroundColor: fillColor,
    height: size,
    width: size,
  };

  return <View id={`${id}-dot`} style={[styles]} testID={`${id}-dot`} />;
};

export default Dot;
