import React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { Greys } from '@/ui/common/colors';

export const AddRobotIcon = ({
  size,
  color,
}: {
  size: number;
  color?: string;
}) => (
  <View style={{ height: size, width: size }}>
    <Svg
      height="100%"
      preserveAspectRatio="xMinYMin slice"
      width="100%"
      fill={color || Greys.shade600}
      viewBox="0 0 23 23">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 1H9V3H11V5H7C4.79086 5 3 6.79086 3 9V19C3 21.2091 4.79086 23 7 23H17C19.2091 23 21 21.2091 21 19V9C21 6.79086 19.2091 5 17 5H13V3H15V1ZM17 7H7C5.89543 7 5 7.89543 5 9H19C19 7.89543 18.1046 7 17 7ZM19 11H5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V11Z"
      />
    </Svg>
  </View>
);
