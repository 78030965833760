import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import LoadingBar from '../loaders/LoadingBar';
import { NoNetworkBanner } from '../network/NoNetworkBanner';
import UserHeader from '../user-header';

import InvitesIconCircle from '@/assets/svg/invites-icon-circle.svg';
import InvitesIcon from '@/assets/svg/invites-icon.svg';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

type Variant = 'default' | 'light';
type Props = {
  hasShadow?: boolean;
  rightActionVisible?: boolean;
  title?: string;
  variant?: Variant;
  isInSidebar?: boolean;
};
const PrimaryHeader = ({
  hasShadow = true,
  rightActionVisible,
  title,
  variant = 'default',
  isInSidebar = false,
}: Props) => {
  const { navigate } = useNavigation();
  const { top } = useSafeAreaInsets();
  const styles = getStyles(variant, top);
  const hasPendingInvites = useMyInviteStore(state =>
    state.hasPendingInvites(),
  );

  const goToInvites = useCallback(() => {
    navigate('InviteList');
  }, [navigate]);

  const isLoading = useLoadingStore(useShallow(state => state.isLoading()));
  const showLoadingBar = isLoading && (!web || isInSidebar);

  return (
    <View style={styles.wrapper}>
      <View
        style={hasShadow ? styles.shadowContainer : styles.noShadowContainer}>
        <View style={hasShadow ? styles.containerWithShadow : styles.container}>
          <UserHeader title={title ?? ''} variant={variant} />
          <TouchableOpacity onPress={goToInvites} style={styles.rightAction}>
            {rightActionVisible &&
              (hasPendingInvites ? <InvitesIconCircle /> : <InvitesIcon />)}
          </TouchableOpacity>
        </View>
      </View>
      <NoNetworkBanner />
      <LoadingBar
        hidden={!showLoadingBar}
        containerStyles={
          isInSidebar ? styles.sidebarLoadingContainer : styles.loadingContainer
        }
        wrapperStyles={styles.loadingWrapper}
      />
    </View>
  );
};

const getStyles = (variant: Variant, top: number) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: variant === 'light' ? 'transparent' : Greys.shade0,
      flexDirection: 'row',
      height: 52,
      justifyContent: 'space-between',
      paddingLeft: 15,
    },
    containerWithShadow: {
      alignItems: 'center',
      backgroundColor: variant === 'light' ? 'transparent' : Greys.shade0,
      elevation: 2,
      flexDirection: 'row',
      height: 52,
      justifyContent: 'space-between',
      paddingLeft: 15,
      shadowColor: Greys.shade999,
      shadowOffset: { height: 2, width: 0 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    noShadowContainer: {
      overflow: 'hidden',
      paddingBottom: 0,
    },
    rightAction: {
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginRight: 15,
      paddingBottom: 3,
    },
    shadowContainer: {
      overflow: 'hidden',
      paddingBottom: 5,
    },
    wrapper: {
      backgroundColor: variant === 'light' ? 'transparent' : Greys.shade0,
      paddingTop: top,
    },
    loadingContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -5,
      zIndex: 10,
    },
    loadingWrapper: {
      width: 400,
    },
    sidebarLoadingContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -4,
    },
  });

export default PrimaryHeader;
