import type { ListRenderItem } from '@shopify/flash-list';
import moment from 'moment';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { ChatUnitType } from '../../../../API';
import type { Invite } from '../../types/types';

import { styles } from './styles';

import RightIcon from '@/assets/back-arrow.svg';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import { Greys } from '@/ui/common/colors';

export const SentInvitationItem = ({
  item,
  translate,
}: {
  index?: number;
  item: Invite;
  translate: (text: string, options?: Record<string, any>) => string;
}) => {
  const targetInfo = item?.targetInfo;

  return (
    <View style={styles.itemContainer} key={item.id}>
      {targetInfo.backgroundImage ? (
        <>
          <View style={styles.imageBgContainer}>
            <View style={styles.chatUnitIcon}>
              <ChatUnitIcon
                fill={Greys.shade0}
                size={50}
                type={ChatUnitType.TABLE}
              />
            </View>

            <View style={styles.itemImageSent}>
              <ChatUnitBackgroundImage
                imageKey={targetInfo.backgroundImage}
                width="100%"
                height="100%"
              />
            </View>
          </View>
        </>
      ) : (
        <></>
      )}

      <View style={styles.itemContent}>
        <Text style={styles.itemTitle}>{`${targetInfo?.title}`}</Text>
        <View style={styles.dateRow}>
          <Text style={styles.itemSubtitle}>
            {translate('you_have_invited_text', {
              numberOfInvites: item?.inviteeInfo?.length.toString(),
            })}
          </Text>
        </View>
        {/* <View style={styles.invitationStatusContainer}>
          <View style={styles.invitationStatus}>
            <AcceptedIcon />
            <Text style={styles.invitationStatusText}>
              {numberOfAccepted} {translate('accepted_text')}
            </Text>
          </View>

          <View style={styles.invitationStatus}>
            <DeclinedIcon />
            <Text style={styles.invitationStatusText}>
              {numberOfDeclined} {translate('declined_text')}
            </Text>
          </View>

          <View style={styles.invitationStatus}>
            <PendingIcon />
            <Text style={styles.invitationStatusText}>
              {numberOfPending} {translate('pending_text')}
            </Text>
          </View>
        </View> */}
        {/* <View style={styles.separator} /> */}
        {/* {item.acceptedInvitations.map(inv => {
          return (
            <View key={inv.name} style={styles.acceptedInvitation}>
              <CachedImage
                uri={inv.avatar}
                style={styles.acceptedInvitationImage}
                resizeMode="cover"
              />
              <Text style={styles.acceptedInvitationText}>{inv.name}</Text>
              <AcceptedIcon />
            </View>
          );
        })} */}
        <View style={styles.inviteeDetailsRow}>
          <View>
            {item.inviteeInfo.map((invitee, index) => {
              return (
                <View
                  style={styles.sentInviteeDetails}
                  key={invitee.name + index}>
                  <View style={styles.noAvatar}>
                    <Text style={styles.avatarInitials}>
                      {invitee.name.charAt(0).toUpperCase()}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.sentInviteeText}>{invitee.name}</Text>
                  </View>
                </View>
              );
            })}
          </View>
          <Text style={[styles.itemSubtitle, styles.itemContentTime]}>
            {moment(item.createdAt).fromNow()}
          </Text>
        </View>
      </View>

      <View style={styles.itemButton}>
        <RightIcon height={12} width={6} />
      </View>
    </View>
  );
};

export const renderSentItem =
  ({
    navigate,
    translate,
  }: {
    navigate: (navLink: string, params: Record<string, any>) => void;
    translate: (text: string) => string;
  }): ListRenderItem<Invite> =>
  ({ item }) => {
    return (
      <>
        <TouchableOpacity
          key={item.id}
          onPress={() =>
            navigate('InviteDetails', {
              id: item.inviteId,
            })
          }>
          <SentInvitationItem item={item} translate={translate} />
        </TouchableOpacity>
      </>
    );
  };
