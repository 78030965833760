import React, { type ReactNode } from 'react';
import { Button, Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import ChatUnitNotificationToast from '../notifications/ChatUnitNotificationToast';

import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { Greys, Status } from '@/ui/common/colors';

export const PromptProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ChatUnitNotificationToast />
      <PromptModal />
      {children}
    </>
  );
};

const PromptModal = () => {
  const { prompt, clearPrompt } = usePromptStore();

  if (!prompt) {
    return null;
  }

  const onConfirm = async () => {
    if (prompt.onConfirm) {
      await prompt.onConfirm();
    }
    clearPrompt();
  };

  const onCancel = async () => {
    if (prompt.onCancel) {
      await prompt.onCancel();
    }
    clearPrompt();
  };

  return (
    <Modal animationType="fade" transparent>
      <View style={styles.centeredView}>
        <View style={styles.modal}>
          <View style={styles.content}>
            <Text style={styles.title}>{prompt.title}</Text>
            <Text style={styles.body}>{prompt.body}</Text>
          </View>
          <View style={styles.actions}>
            <Pressable onPress={onConfirm} style={styles.button}>
              <LinearGradient
                colors={['rgba(151, 151, 151, 0.25)', 'rgba(151, 151, 151, 0)']}
                end={{ x: 1, y: 0.4 }}
                start={{ x: 1, y: 0 }}
                style={styles.buttonGradient}>
                <Text style={styles.buttonText}>Ok</Text>
              </LinearGradient>
            </Pressable>
            {prompt.onCancel && <Button onPress={onCancel} title="Cancel" />}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  actions: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  body: {
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  button: {
    flexGrow: 1,
  },
  buttonGradient: {
    paddingVertical: 30,
  },
  buttonText: {
    color: Greys.shade800,
    fontSize: 17,
    textAlign: 'center',
  },
  centeredView: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  content: {
    padding: 20,
  },
  modal: {
    borderRadius: 18,
    backgroundColor: Greys.shade0,
    maxWidth: 340,
  },
  title: {
    color: Status.danger100,
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
});
