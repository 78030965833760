import { useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { Text, type ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import useTranslations from '@/translations/useTranslation';
import { Greys, Status } from '@/ui/common/colors';

type Props = {
  containerStyles?: ViewStyle;
};

export const NoNetworkBanner = ({ containerStyles }: Props) => {
  const { isInternetReachable } = useNetInfo();
  const { translate } = useTranslations();

  // NOTE: isInternetReachable is initially null/undefined, so explcitly check for false
  if (isInternetReachable !== false) {
    return null;
  }

  return (
    <View style={containerStyles}>
      <View style={styles.banner}>
        <Text style={styles.text}>
          {translate('warning_no_internet_connection')}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Status.danger100,
    paddingHorizontal: 20,
  },
  text: {
    color: Greys.shade0,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 20,
    textAlign: 'center',
  },
});
