import { StyleSheet } from 'react-native';

import { blue2, hp, wp } from '@/theme';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  addPhotoText: {
    color: Greys.shade200,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 19,
    width: 50,
  },
  avatar: {
    borderRadius: 60,
    height: (wp(100) - 60) / 6,
    position: 'absolute',
    width: (wp(100) - 60) / 6,
  },
  avatarContainer: {
    flexDirection: web ? 'row' : undefined,
    alignItems: 'center',
    backgroundColor: web ? undefined : Greys.shade500,
    borderRadius: web ? 100 : 60,
    height: wp(30),
    justifyContent: 'center',
    width: wp(30),
    flex: web ? 1 : undefined,
  },
  avatarControls: {
    flexDirection: 'row',
    width: wp(30),
    flex: web ? 1 : undefined,
    flexGrow: web ? 2 : undefined,
  },
  avatarImage: {
    borderRadius: 100,
    // @ts-ignore: maxHeight exists on web
    maxHeight: web ? '120px' : undefined,
    // @ts-ignore: maxWidth exists on web
    maxWidth: web ? '120px' : undefined,
    height: web ? 200 : wp(30),
    width: web ? 200 : wp(30),
    resizeMode: 'cover',
  },
  avatarPicker: {
    backgroundColor: Greys.shade800,
    height: web ? hp(22) : wp(39),
    paddingTop: 20,
    flexDirection: web ? 'row' : undefined,
    justifyContent: web ? 'center' : undefined,
  },
  // @ts-ignore: maxWidth exists on web
  avatarPickerContainer: { maxWidth: web ? '500px' : undefined },
  avatarPickerSubtitle: {
    color: Greys.shade200,
    fontSize: 14,
    lineHeight: 14,
    marginBottom: wp(4),
    marginTop: wp(2),
    textAlign: 'center',
  },
  avatarPickerTitle: {
    color: Greys.shade0,
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 17,
    marginBottom: wp(1),
    textAlign: 'center',
  },
  avatarsContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
    width: '100%',
  },
  avatarsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: web ? undefined : 'space-between',
    paddingHorizontal: 20,
    width: '100%',
  },
  chooseFromGalleryButton: { marginRight: 10 },
  chooseNowButton: {
    alignItems: 'center',
    borderColor: blue2,
    borderRadius: 20,
    borderWidth: 2,
    flex: 1,
    height: 42,
    justifyContent: 'center',
    marginTop: wp(2),
    width: wp(30),
  },
  chooseNowButtonText: {
    color: blue2,
    fontWeight: '600',
  },
  header: {
    alignItems: 'center',
    backgroundColor: Greys.shade600,
    justifyContent: 'center',
    paddingBottom: 16,
    width: web ? '100%' : undefined,
  },
  headerContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerTextContainer: {
    alignItems: 'flex-end',
    width: wp(30),
    flex: web ? 1 : undefined,
    flexGrow: web ? 2 : undefined,
  },
  loader: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 60,
    height: wp(30),
    justifyContent: 'center',
    position: 'absolute',
    width: wp(30),
  },
  setupText: {
    color: Greys.shade0,
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 28,
    marginBottom: web ? hp(1) : wp(3),
    marginTop: web ? hp(1) : wp(3),
  },
});
