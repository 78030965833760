import { useEffect } from 'react';
import type { Subscription } from 'rxjs';
import { useShallow } from 'zustand/react/shallow';

import { useTableStore } from '../state/useTableStore';
import { useTableUsersStore } from '../state/useTableUsersStore';

import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { getDatastoreObservable } from '@/services/datastore/subscriptions/subscriptionManager';
import { eventBus } from '@/services/eventBus/eventBus';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';

export const useTableEventListeners = () => {
  const { navigate } = useNavigation();
  const upsertTables = useTableStore(state => state.upsertTables);
  const addTableUserToTable = useTableUsersStore(
    state => state.addTableUserToTable,
  );

  const removeTableUserFromTable = useTableUsersStore(
    state => state.removeTableUserFromTable,
  );

  const datastoreReady = useLoadingStore(state => state.datastoreReady);

  const userId = useUserStore(useShallow(state => state.user?.id));

  useEffect(() => {
    let chatUnitLoadedSubscription: Subscription | undefined;
    let chatUnitUserSubscription: Subscription | undefined;

    if (datastoreReady) {
      chatUnitLoadedSubscription = getDatastoreObservable('ChatUnit').subscribe(
        subscriptionMessage => {
          try {
            const chatUnit = factory.chatUnit(subscriptionMessage.element);
            upsertTables([chatUnit]);
          } catch (e) {
            logger.error('subscribeChatUnitLoadedError', e);
          }
        },
      );

      chatUnitUserSubscription = getDatastoreObservable(
        'ChatUnitUser',
      ).subscribe(subscriptionMessage => {
        try {
          const chatUnitUser = factory.chatUnitUser(
            subscriptionMessage.element,
          );

          if (subscriptionMessage.opType === 'DELETE') {
            removeTableUserFromTable(chatUnitUser);
          } else {
            addTableUserToTable(chatUnitUser);
            if (
              chatUnitUser?.status === ChatUnitUserStatus.ARCHIVED &&
              chatUnitUser?.id === userId
            ) {
              navigate('Tables');
            }
          }
        } catch (e) {
          logger.error('subscribeChatUnitUserLoadedError', e);
        }
      });
    }

    eventBus.on('chatUnitUpdated', table => {
      upsertTables([table]);
    });

    return () => {
      chatUnitLoadedSubscription?.unsubscribe();
      eventBus.off('chatUnitUpdated');
      chatUnitUserSubscription?.unsubscribe();
    };
  }, [
    addTableUserToTable,
    removeTableUserFromTable,
    upsertTables,
    datastoreReady,
    userId,
    navigate,
  ]);
};
