const RNCamera = { useCameraDevice: () => {}, useCameraPermission: () => {} };

export const useCameraDevice = () => {};
export const useCameraPermission = () => {
  return { hasPermission: false, requestPermission: () => {} };
};
export const Camera = () => {};
export const PhotoFile = () => {};

export const Point = () => {};

export default RNCamera;
