import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Keyboard, StatusBar, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import Logo from '@/assets/svg/8seats-logo-dark.svg';
import CountryPicker from '@/components/country-picker';
import { PhoneController } from '@/components/phoneInput/PhoneController';
import Body from '@/components/text/Body';
import Subtitle from '@/components/text/Subtitle';
import { constants } from '@/constants';
import AuthService from '@/domain/auth/AuthService';
import { Button } from '@/domain/theme/components/Button';
import { Validation } from '@/hooks/useInput';
import useNavigation from '@/hooks/useNavigation';
import { primaryRed } from '@/theme';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import type { Country } from '@/utilities/countries';
import { generatePassword } from '@/utilities/lib/generateRandomPassword';

export interface FormFields {
  phoneNumber: string;
}

const tempPassword = generatePassword();

const CreateAccount = () => {
  const { translate } = useTranslations();
  const [showPicker, setShowPicker] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { navigate } = useNavigation();
  const [selectedCountry, setSelectedCountry] = useState(
    constants.defaultCountry,
  );

  const resolver = yupResolver(
    yup.object().shape({
      phoneNumber: yup
        .string()
        .test(
          'is-valid-phone',
          translate('phone_number_is_invalid_text'),
          value => {
            return isValidPhoneNumber(value || '');
          },
        )
        .required(translate('phone_number_is_required_text')),
    }),
    { abortEarly: false },
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormFields>({
    defaultValues: {
      phoneNumber: selectedCountry?.dial_code ?? '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const phoneNumber = watch('phoneNumber');

  const onSubmit = async (data: FormFields) => {
    setIsSubmitting(true);
    const parsedPhoneNumber = data.phoneNumber?.replace(/\s/g, '') ?? '';
    try {
      const user = await AuthService.signUp(parsedPhoneNumber, tempPassword);
      setIsSubmitting(false);
      if (user) {
        navigate('SmsVerification', {
          phoneNumber: parsedPhoneNumber,
          tempPassword,
        });
      }
    } catch (e: any) {
      setIsSubmitting(false);
      setError(e.message?.replace('phone_number', 'phone number'));
    }
  };

  const goToSignIn = () => {
    navigate('SignIn');
  };

  const isFormValid = () => {
    return isValidPhoneNumber(phoneNumber || '');
  };

  const onCountrySelected = (selCountry: Country) => {
    setSelectedCountry(selCountry);
  };

  return (
    <View onTouchStart={Keyboard.dismiss} style={styles.dismissable}>
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar barStyle="dark-content" />
        <Logo />

        {error ? (
          <Subtitle style={styles.errorTitle}>{error}</Subtitle>
        ) : (
          <Subtitle style={styles.title}>{translate('signup_text')}</Subtitle>
        )}

        <View style={styles.divider40} />

        <Body style={styles.inputLabel}>
          <Trans i18nKey="phone_number_text" />
        </Body>

        <PhoneController
          control={control}
          error={errors.phoneNumber?.message}
          label={translate('phone_number_placeholder_text')}
          name="phoneNumber"
          onSearch={() => {
            setShowPicker(false);
            Keyboard?.dismiss();
          }}
          selectedCountry={selectedCountry}
          validation={errors.phoneNumber && Validation.ERROR}
        />

        <Button
          i18nKey="next_text"
          busy={isSubmitting}
          disabled={!isFormValid()}
          onPress={() => {
            handleSubmit(onSubmit)();
          }}
        />

        <Button
          i18nKey="sign_in_instead_text"
          onPress={goToSignIn}
          variant="link"
        />

        <CountryPicker
          onCountrySelected={onCountrySelected}
          open={showPicker}
          selectedCountry={selectedCountry}
          setOpen={setShowPicker}
        />
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 24,
  },
  dismissable: { flex: 1 },
  divider40: { marginTop: 40 },
  errorTitle: {
    color: primaryRed,
    marginTop: 30,
    paddingHorizontal: 30,
    textAlign: 'center',
  },
  inputLabel: { color: Greys.shade500, marginBottom: 10 },
  screenContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: 1,
    paddingTop: 30,
  },
  title: {
    color: Greys.shade500,
    marginTop: 30,
    paddingHorizontal: 30,
    textAlign: 'center',
  },
});

export default CreateAccount;
