import { useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { Colors } from '@/domain/theme/Colors';
import { AppText, Icon, PressBox } from '@/ui/app/elements';

type Props = {
  onCreatePress: () => void;
};

export const DMListHeader = React.memo(({ onCreatePress }: Props) => {
  const { isInternetReachable } = useNetInfo();

  return (
    <View style={styles.container}>
      <PressBox
        onPress={isInternetReachable ? onCreatePress : null}
        padding={0}>
        <LinearGradient
          colors={[Colors.primaryLight, Colors.primaryDark]}
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          style={styles.buttonContainer}>
          <Icon
            fill={Colors.neutral0}
            iconKey="Bubble"
            size={18}
            style={styles.icon}
          />
          <AppText style={styles.buttonLabel} size={14}>
            Compose
          </AppText>
        </LinearGradient>
      </PressBox>
    </View>
  );
});

const styles = StyleSheet.create({
  buttonLabel: {
    color: Colors.neutral0,
    fontWeight: '400',
  },
  container: {
    backgroundColor: Colors.neutral10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 6,
  },
  icon: {
    marginRight: 5,
  },
});
