import React from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import Rive, { Fit } from 'rive-react-native';

import { ios } from '@/utilities/platform';

type Props = {
  hidden?: boolean;
  containerStyles?: ViewStyle;
  wrapperStyles?: ViewStyle;
};

const LoadingBar = (props?: Props) => {
  if (props?.hidden) return null;

  return (
    <Animated.View
      entering={FadeIn}
      exiting={FadeOut}
      style={[styles.container, props?.containerStyles]}>
      <View style={[styles.wrapper, props?.wrapperStyles]}>
        <Rive
          fit={Fit.FitHeight}
          resourceName={ios ? '8seats_bar_loader' : 'loading_bar'}
          style={styles.bar}
        />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  bar: {
    width: '100%',
  },
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  wrapper: {
    flexGrow: 1,
    maxWidth: 500,
    height: 10,
  },
});

const checkIfEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps?.hidden === nextProps?.hidden;
};

export default React.memo<Props>(LoadingBar, checkIfEqual);
