import { constants } from '@/constants';

const SHORT_NAME_CHAR_LIMIT = constants.displayUserNameLimit || 7;

export const getName = (
  user: {
    name?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
  },
  type: 'short' | 'full' | 'first' | 'last',
): string => {
  if (type === 'short') {
    return (
      user.username?.substring(0, SHORT_NAME_CHAR_LIMIT) ||
      user.firstName?.substring(0, SHORT_NAME_CHAR_LIMIT) ||
      user.name?.substring(0, SHORT_NAME_CHAR_LIMIT) ||
      'Unknown'
    ).trim();
  }

  if (type === 'first') {
    return (
      user.firstName ||
      user.name?.split(' ')[0] ||
      user.username ||
      'Unknown'
    ).trim();
  }

  if (type === 'last') {
    return (user.lastName || user.name?.split(' ')[1] || '').trim();
  }

  // fullname
  if (!user.firstName && !user.lastName) {
    return user.name || user.username || 'Unknown';
  }

  return (user.firstName + ' ' + user.lastName).trim();
};
