import { validateInput } from './validateInput';

import type { Invite } from '@/domain/invites/types/types';

export const inviteFactory = (input: any): Invite => {
  validateInput(
    input,
    [
      'id',
      'inviteId',
      'owner',
      'type',
      'status',
      'targetId',
      'targetType',
      'targetInfo',
      'inviterInfo',
      'inviteeInfo',
      'createdAt',
    ],
    'Invite',
  );

  const inviteeInfo = input?.inviteeInfo
    ? input?.inviteeInfo?.map((item: string) => item && JSON.parse(item))
    : [];

  const invite: Invite = {
    __typename: 'Invite',
    _deleted: input?._deleted,
    _lastChangedAt: input?._lastChangedAt,
    _version: input?._version,
    createdAt: input?.createdAt,
    id: input?.id,
    inviteId: input?.inviteId,
    inviteeInfo: inviteeInfo,
    inviterInfo: input?.inviterInfo,
    message: input?.message,
    owner: input?.owner,
    status: input?.status,
    targetId: input?.targetId,
    targetInfo: input?.targetInfo,
    targetType: input?.targetType,
    type: input?.type,
    updatedAt: input?.updatedAt,
  };

  return invite;
};
