import { min } from 'lodash';
import type React from 'react';
import { Text, View } from 'react-native';

import { type StyleProps, getStyles } from './styles';

type Props = {
  badgeContent?: number;
  children?: React.ReactNode;
  hidden?: boolean;
  styles?: StyleProps;
};

export const Badge = ({
  badgeContent,
  children,
  hidden,
  styles = {},
}: Props) => {
  if (hidden) {
    return <>{children}</>;
  }
  const thisStyles = getStyles(styles, badgeContent !== undefined);
  return (
    <View style={thisStyles.badgeContainer}>
      {children}
      <View style={thisStyles.badge}>
        {badgeContent !== undefined && (
          <Text style={thisStyles.badgeText}>{min([badgeContent, 999])}</Text>
        )}
      </View>
    </View>
  );
};
