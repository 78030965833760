import React, { useCallback, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Colors } from '../theme/Colors';

import type { MainStackScreenProps } from '@/navigation/types';
import { createAuthUsers } from '@/services';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import { useMMKVString } from 'react-native-mmkv';
import AuthService from '../auth/AuthService';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingPhoneNumberInput from './components/OnboardingPhoneNumberInput';
import OnboardingWebLayout from './components/OnboardingWebLayout';
type Props = MainStackScreenProps<'OnboardingStepOne'>;

function OnboardingStepOneScreen({ route, navigation }: Props) {
  const { translate } = useTranslations();
  const [phoneNumber, setPhoneNumber] = useState('');
  const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : false;
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      await createAuthUsers([phoneNumber]);
      const signInResult = await AuthService.signIn(phoneNumber);
      if (signInResult?.nextStep.signInStep) {
        navigation.navigate('OnboardingStepOneOTP', {
          phoneNumber,
          onboardingType: route?.params?.onboardingType,
        });
      }
    } catch (err) {
      logger.error('Error signing up with phone number', err);
    } finally {
      setSubmitting(false);
    }
  }, [navigation, phoneNumber, route.params.onboardingType]);

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <Spacer height={50} />
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {(route.params.onboardingType === 'businessInvitee'
            ? translate('join_workspace_caption')
            : translate('step_x_of_y', {
                current: route.params?.stepNumber || 1,
                total: route.params?.totalSteps || 4,
              })
          ).toUpperCase()}
        </AppText>
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate(
            route.params.onboardingType === 'businessInvitee'
              ? 'join_workspace_title'
              : 'enter_phone_label',
          )}
        </AppText>
        <Spacer height={20} />
        <AppText size={web ? 21 : 17}>
          {route.params.onboardingType === 'businessInvitee'
            ? translate('join_workspace_subtitle')
            : translate('enter_phone_subtitle')}
        </AppText>
        <Spacer height={40} />
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('your_phone_number')}
        </AppText>
        <Spacer height={16} />
        <OnboardingPhoneNumberInput
          setPhoneNumber={setPhoneNumber}
          onSubmit={isValid ? handleSubmit : undefined}
        />
        <Spacer height={40} />
        <OnboardingNextButton
          onPress={handleSubmit}
          text={translate('next_step')}
          disabled={!isValid}
          loading={submitting}
        />
        <Spacer height={30} />
        <Pressable onPress={() => navigation.replace('OnboardingSignIn')}>
          <AppText size={web ? 17 : 14} color={Colors.neutral80}>
            {translate('already_have_account')}
            <AppText
              size={web ? 17 : 14}
              type="primary700"
              color={Colors.primaryLight}>
              {' '}
              {translate('sign_in_here')}
            </AppText>
          </AppText>
        </Pressable>
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    flexGrow: 1,
  },
});

const OnboardingStepOneScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout showBackButton>
      <OnboardingStepOneScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web ? OnboardingStepOneScreenWeb : OnboardingStepOneScreen;
