import { useCallback } from 'react';
import { type GestureResponderEvent, useWindowDimensions } from 'react-native';
import {
  type AnimatedRef,
  interpolate,
  scrollTo,
  useSharedValue,
  useWorkletCallback,
} from 'react-native-reanimated';
import type { AnimatedScrollView } from 'react-native-reanimated/lib/typescript/reanimated2/component/ScrollView';

import { useSmoothKeyboardHandler } from '@/components/keyboard-aware-scrollview/useSmoothKeyboardHandler';

type Props = {
  bottomOffset: number;
  scrollViewAnimatedRef: AnimatedRef<AnimatedScrollView>;
};

export const useAutoScrollForKeyboard = ({
  bottomOffset,
  scrollViewAnimatedRef,
}: Props) => {
  const { height } = useWindowDimensions();
  const scrollPosition = useSharedValue(0);
  const clickYPos = useSharedValue(0);
  const isKeyboardVisible = useSharedValue(false);

  const scrollWhenHidden = useWorkletCallback(
    (currentKeyboardHeight: number) => {
      'worklet';

      if (isKeyboardVisible.value) {
        return;
      }
      const visibleArea = height - currentKeyboardHeight;

      if (visibleArea - clickYPos.value <= bottomOffset) {
        const interpolatedScrollTo = interpolate(
          currentKeyboardHeight,
          [0, currentKeyboardHeight],
          [
            0,
            currentKeyboardHeight - (height - clickYPos.value) + bottomOffset,
          ],
        );
        const targetScrollY =
          Math.max(interpolatedScrollTo, 0) + scrollPosition.value;

        scrollTo(scrollViewAnimatedRef, 0, targetScrollY, false);
      }
    },
    [],
  );

  useSmoothKeyboardHandler(
    {
      onEnd: e => {
        'worklet';

        isKeyboardVisible.value = e.height > 0;
      },
      onStart: e => {
        'worklet';

        if (e.height > 0) {
          scrollWhenHidden(e.height);
        }
      },
    },
    [height],
  );

  /**
   * Used to capture where the user is touching on the screen to determine
   * if the keyboard will end up hiding the focued input
   */
  const onContentTouch = useCallback(
    (e: GestureResponderEvent) => {
      if (!isKeyboardVisible.value) {
        clickYPos.value = e.nativeEvent.pageY;
      }
    },
    [clickYPos, isKeyboardVisible],
  );

  return { onContentTouch, scrollPosition };
};
