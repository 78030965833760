import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Greys } from '@/ui/common/colors';

type Props = {
  subtitle?: string;
  title: string;
};

export const SettingsSubtitle = ({ subtitle, title }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Greys.shade200,
    paddingBottom: 10,
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  subtitle: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    marginTop: 10,
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
});
