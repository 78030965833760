import { BottomSheet } from '@/components/actionList/BottomSheet';
import Avatar from '@/components/avatar';
import Section from '@/components/section/Section';
import type { Contact } from '@/domain/contact/state/useContactStore';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import { Roles } from '@/models';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  contact?: Contact;
  onMemberTypeSelect: (input: {
    contact: Contact;
    role: Roles;
  }) => Promise<void>;
};

export default function ChooseMemberTypeSheet({
  open,
  setOpen,
  contact,
  onMemberTypeSelect,
}: Props) {
  if (!contact) {
    return null;
  }
  return (
    <BottomSheet open={open} setOpen={setOpen}>
      <Spacer height={15} />
      <Section ph={20} row gap={20} bg={Colors.neutral0}>
        <Avatar
          size={40}
          imageKey={contact?.avatar}
          displayName={getName(contact, 'full')}
          style={styles.avatar}
        />
        <Section>
          <AppText size={17} type="primary700">
            {getName(contact, 'full')}
          </AppText>
          <AppText size={14}>Choose how you want to invite them</AppText>
        </Section>
      </Section>
      <Spacer height={15} />
      <LinearGradient
        colors={[Colors.neutral20, `${Colors.neutral20}00`]}
        angle={180}
        useAngle
        style={styles.gradient}
      />
      <Section
        onPress={() => {
          onMemberTypeSelect({ contact, role: Roles.MEMBER });
          setOpen(false);
        }}>
        <Section
          br={BorderRadius.md}
          bw={1}
          mh={20}
          ph={10}
          pv={5}
          mb={5}
          bc={Colors.neutral80}
          aic
          jcfs
          bg={Colors.neutral0}
          asfs>
          <AppText size={12} type="primary600">
            {Roles.MEMBER}
          </AppText>
        </Section>
        <Section ph={20} mb={10} bg={Colors.neutral0}>
          <AppText size={12} color={Colors.neutral70}>
            Can start Tables, Rooms and DMs. Can invite existing members or new
            guests.{' '}
            <AppText size={12} type="primary600" color={Colors.neutral70}>
              Billed as part of your team.
            </AppText>
          </AppText>
        </Section>
      </Section>
      <Spacer height={1} bg={Colors.neutral10} />
      <Section
        bg={Colors.neutral05}
        ph={20}
        onPress={() => {
          onMemberTypeSelect({ contact, role: Roles.GUEST });
          setOpen(false);
        }}>
        <Section
          br={BorderRadius.md}
          bw={1}
          ph={10}
          pv={5}
          mb={5}
          mt={10}
          bc={Colors.neutral70}
          aic
          jcfs
          bg={Colors.neutral05}
          asfs
          style={styles.dashedBorder}>
          <AppText size={12} color={Colors.neutral70} type="primary600">
            GUEST
          </AppText>
        </Section>
        <AppText size={12} color={Colors.neutral70}>
          Cannot start new chats. Must be invited to Tables, Rooms or DMs.{' '}
          <AppText size={12} type="primary600" color={Colors.neutral70}>
            Always free.
          </AppText>
        </AppText>
        <Spacer height={10} />
      </Section>
      <Spacer height={1} bg={Colors.neutral10} />
      <Pressable onPress={() => setOpen(false)}>
        <Spacer height={15} />
        <AppText size={14} textAlign="center" type="primary700">
          Cancel
        </AppText>
        <Spacer height={15} />
      </Pressable>
    </BottomSheet>
  );
}

const styles = StyleSheet.create({
  avatar: {
    borderWidth: 1,
    borderRadius: BorderRadius.full,
    borderColor: Colors.neutral80,
  },
  gradient: {
    height: 20,
  },
  dashedBorder: {
    borderStyle: 'dashed',
  },
});
