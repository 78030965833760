import { InviteStatus, InviteType } from '../types/types';

import { InviteTargetType } from '@/API';
import client from '@/services/API/client';
import { createNewInvite } from '@/services/graphql/mutations';
import { logger } from '@/services/logger/logger';

type CreateInviteProps = {
  InviterInfo: Record<string, any>;
  inviteTarget: string;
  inviteeInfo: Record<string, any>[];
  targetInfo: Record<string, any>;
  type?: InviteType;
  targetType: InviteTargetType;
};

export const createInvite = async ({
  InviterInfo,
  inviteTarget,
  inviteeInfo,
  targetInfo,
  targetType = InviteTargetType.TABLE,
}: CreateInviteProps) => {
  try {
    const result = await client.graphql({
      query: createNewInvite,
      variables: {
        input: {
          inviteeInfo: inviteeInfo.map(info => JSON.stringify(info)) ?? [],
          inviterInfo: JSON.stringify(InviterInfo),
          message: '',
          owner: '',
          status: InviteStatus.PENDING,
          targetId: inviteTarget,
          targetInfo: JSON.stringify(targetInfo),
          targetType: targetType,
          type: InviteType.SENDER,
        },
      },
    });
    return result;
  } catch (e) {
    logger.error('Error:', e);
    throw new Error('Failed to create new invite');
  }
};
