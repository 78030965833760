import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useEffect, type FC } from 'react';

import { ChatUnitType } from '../API';

import { noHeaderScreenOptions } from './settings';
import TabBar from './tabBar';
import type { Props } from './types';

import { ChatListScreen } from '@/domain/chatUnit/components/browse/ChatUnitScreen';
import DiscoverScreen from '@/domain/discover/DiscoverScreen';
import Dashboard from '@/modules/dashboard';
import { Activity } from '@/modules/index';
import { web } from '@/utilities/platform';
import useWindowDimensions, {
  ScreenSizes,
} from '@/utilities/useWindowDimensions/useWindowDimensions';
import { useMMKVBoolean } from 'react-native-mmkv';

const Tab = createBottomTabNavigator();

const TabBarComponent = (props: any) => {
  const { screenSize } = useWindowDimensions();
  return web &&
    ![ScreenSizes.xs, ScreenSizes.xxs, ScreenSizes.sm].includes(screenSize) ? (
    <></>
  ) : (
    <TabBar {...props} />
  );
};

const MainNavigator: FC<Props> = () => {
  const [onboardingTableCreated, setOnboardingTableCreated] = useMMKVBoolean(
    'onboardingTableCreated',
  );

  useEffect(() => {
    setOnboardingTableCreated(false);
  }, []);
  const { screenSize } = useWindowDimensions();
  return (
    <Tab.Navigator
      id="MainNavigator"
      initialRouteName={
        onboardingTableCreated ? 'Tables' : web ? undefined : 'Activity'
      }
      screenOptions={noHeaderScreenOptions}
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => <TabBarComponent {...props} />}>
      {web &&
      ![ScreenSizes.xs, ScreenSizes.xxs, ScreenSizes.sm].includes(
        screenSize,
      ) ? (
        <></>
      ) : (
        <Tab.Screen component={Activity} name="Activity" />
      )}

      <Tab.Screen
        component={ChatListScreen}
        initialParams={{ type: ChatUnitType.QUICK_CHAT }}
        name="DMs"
      />

      <Tab.Screen
        component={ChatListScreen}
        initialParams={{ type: ChatUnitType.TABLE }}
        name="Tables"
      />

      <Tab.Screen
        component={ChatListScreen}
        initialParams={{ type: ChatUnitType.ROOM }}
        name="Rooms"
      />

      <Tab.Screen component={DiscoverScreen} name="Discover" />
    </Tab.Navigator>
  );
};

export default MainNavigator;
