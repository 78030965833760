import { uploadData } from 'aws-amplify/storage';
import { useCallback, useEffect, useState } from 'react';
import { launchImageLibrary } from 'react-native-image-picker';

import type { LocalAvatar } from '../../../types';

import generateUniqueId from '@/utilities/helpers/uuid';
import { uriToBlob } from '@/utilities/lib/uriToBlob';

type Props = {
  initialAvatar?: string;
  onSetAvatar?: (avatar: string) => void;
};

export const useAvatar = ({ initialAvatar, onSetAvatar }: Props = {}) => {
  const [avatar, setAvatar] = useState(initialAvatar);
  const [isUploading, setIsUploading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setAvatar(initialAvatar);
  }, [initialAvatar]);

  const onPickAvatar = useCallback(
    async (newAvatar: LocalAvatar) => {
      setAvatar(newAvatar.name);
      if (onSetAvatar) {
        onSetAvatar(newAvatar.name);
      }
      setShowPicker(false);
    },
    [onSetAvatar],
  );

  const upload = useCallback(
    async (filePath: string, fileName: string) => {
      try {
        setIsUploading(true);
        const blob = await uriToBlob(filePath);
        const { key } = await uploadData({
          data: blob,
          key: fileName,
          options: {
            contentType: 'image/jpeg',
          },
        }).result;
        setAvatar(key);
        if (onSetAvatar) {
          onSetAvatar(key);
        }
        setIsUploading(false);
      } catch (err) {
        setIsUploading(false);
      }
    },
    [onSetAvatar],
  );

  const chooseImageFromLibrary = useCallback(async () => {
    launchImageLibrary(
      {
        includeBase64: false,
        maxHeight: 200,
        maxWidth: 200,
        mediaType: 'photo',
      },
      response => {
        if (response?.didCancel) {
          return;
        }
        const uri = response?.assets?.[0]?.uri;
        upload(
          uri ?? '',
          response?.assets?.[0]?.fileName ?? generateUniqueId(),
        );
      },
    );
  }, [upload]);

  const clearAvatar = useCallback(() => {
    setAvatar('');
    if (onSetAvatar) {
      onSetAvatar('');
    }
  }, [onSetAvatar]);

  return {
    avatarImageKey: avatar,
    chooseImageFromLibrary,
    clearAvatar,
    isUploading,
    onPickAvatar,
    showPicker,
    setShowPicker,
  };
};
