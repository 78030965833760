import { asNotification } from './asNotification';
import { NotificationType } from './types';

import { navigateOnLoad } from '@/hooks/useDelayedNavigation';

async function notificationHandler(notificationData?: Record<string, unknown>) {
  const notification = asNotification(notificationData);
  // If the app is in the background or terminated and the user thens open the app, then we
  // can navigate the user to the relevant screen based on the notification type and payload
  switch (notification.notificationType) {
    // If the notification is an incoming message in the background,
    // we navigate the user to the chat screen
    case NotificationType.INCOMING_MESSAGE:
      if (notification.conversationId && notification.chatUnitId) {
        navigateOnLoad('ChatView', {
          conversationId: notification.conversationId,
          chatUnitId: notification.chatUnitId,
        });
      }
      return;
    // If the notification is an invite to a table in the background,
    // we navigate the user to the invite details screen to accept or reject the invite
    case NotificationType.INVITE_TABLE:
      navigateOnLoad('InviteDetails', {
        id: notification.inviteId,
      });

      return;
    // Add cases here to handle other types of notifications
    // after extending the NotificationType to support
    // said notification

    // ...
  }
}

export default notificationHandler;
