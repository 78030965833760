import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBust = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M16.147 86.784C6.222 77.644 0 64.543 0 50 0 22.404 22.404 0 50 0s50 22.404 50 50c0 14.551-6.229 27.659-16.165 36.8l.001.012a50.232 50.232 0 0 1-5.384 4.309v-.011A49.751 49.751 0 0 1 50 100a49.753 49.753 0 0 1-28.473-8.904v.011a50.24 50.24 0 0 1-5.381-4.311zm60.972-5.161C69.829 87.883 60.353 91.667 50 91.667c-10.36 0-19.842-3.789-27.135-10.057 2.438-7.658 8.031-13.914 15.264-17.236l7.698-3.535-6.31-5.652a15.653 15.653 0 0 1-5.232-11.704c0-8.674 7.032-15.705 15.706-15.705s15.706 7.031 15.706 15.705c0 4.649-2.014 8.821-5.233 11.705l-6.31 5.651 7.698 3.535c7.236 3.324 12.831 9.585 15.267 17.249m4.339-4.312a34.13 34.13 0 0 0-17.287-17.985 21.393 21.393 0 0 0 3.723-4.369 21.168 21.168 0 0 0 3.358-11.474c0-11.742-9.519-21.261-21.261-21.261-11.743 0-21.262 9.519-21.262 21.261 0 4.226 1.233 8.163 3.358 11.473a21.425 21.425 0 0 0 3.723 4.37 34.153 34.153 0 0 0-17.282 17.969C12.178 69.981 8.333 60.436 8.333 50 8.333 27.004 27.004 8.333 50 8.333S91.667 27.004 91.667 50c0 10.443-3.85 19.993-10.209 27.311" />
  </Svg>
);
export default SvgBust;
