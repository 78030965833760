import { useEffect, useRef } from 'react';

/**
 * A hook that manages a timeout for a component, i.e, it can be used to hide a component after a certain duration.
 * @param isActive Whether the component is currently visible.
 * @param duration Duration in milliseconds after which the component should hide.
 * @param onTimeout Callback to execute when the timeout completes.
 *
 * @returns An object with a `resetTimer` function that can be used to reset the timer back to the initial duration.
 */
const useTimeout = (
  isActive: boolean,
  duration: number,
  onTimeout: () => void,
) => {
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isActive) {
      // Set the timer to hide the component
      timerRef.current = setTimeout(() => {
        onTimeout();
      }, duration);
    }

    // Cleanup function to clear the timer if the component unmounts or the visibility changes
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isActive, duration, onTimeout]);

  // Function to reset the timer
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        onTimeout();
      }, duration);
    }
  };

  return { resetTimer };
};

export default useTimeout;
