import type { GraphQLQuery } from '@aws-amplify/api';
import type { GetUsersDetailsQuery } from 'src/API';

import client from '@/services/API/client';
import { getUsersDetails } from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

export const getUsersAdditionalDetails = async (
  userIdsOrPhoneNumbers: string[],
) => {
  try {
    const response = await client.graphql<GraphQLQuery<GetUsersDetailsQuery>>({
      query: getUsersDetails,
      variables: {
        users: userIdsOrPhoneNumbers,
      },
    });

    return response.data?.getUsersDetails;
  } catch (err) {
    logger.error('getUsersAdditionalDetails', err);
    return [];
  }
};
