import React from 'react';
import type { Control, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { StyleSheet, Text } from 'react-native';

import type { SignInFormData } from '..';

import { PhoneController } from '@/components/phoneInput/PhoneController';
import { Validation } from '@/hooks/useInput';
import { primaryBlue } from '@/theme';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import type { Country } from '@/utilities/countries';

type SignInFormProps = {
  control: Control<SignInFormData>;
  country?: Country;
  errors: FieldErrors<SignInFormData>;
  getValues: UseFormGetValues<SignInFormData>;
  onCountrySelect: () => void;
  onPhoneInputBlur: () => void;
};

const SignInForm = ({
  control,
  country,
  errors,
  onCountrySelect,
  onPhoneInputBlur,
}: SignInFormProps) => {
  const { translate } = useTranslations();

  return (
    <>
      <Text style={styles.inputLabel}>{translate('phone_number_text')}</Text>
      <PhoneController
        control={control}
        error={''}
        label={translate('phone_number_placeholder_text')}
        name="phoneNumber"
        onBlur={onPhoneInputBlur}
        onSearch={onCountrySelect}
        selectedCountry={country}
        validation={errors.phoneNumber && Validation.ERROR}
      />
    </>
  );
};

export default SignInForm;

const styles = StyleSheet.create({
  forgetPasswordText: {
    color: primaryBlue,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    lineHeight: 14,
    marginTop: 10,
  },
  inputLabel: {
    color: Greys.shade500,
    fontSize: 14,
    lineHeight: 14,
    marginVertical: 10,
  },
});
