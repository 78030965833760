import { v4 as uuidv4 } from 'uuid';

import { getOrDefault } from '@/utilities/storage';

// Store a single UUID in local storage, so we can uniquly identify this device for this app.
export function getUniqueDeviceId(): string {
  const DEVICE_ID_STORAGE_KEY = '8Seats_unique_device_id';
  return getOrDefault(DEVICE_ID_STORAGE_KEY, uuidv4);

  // documentation says .. Google store may reject the application if you call DeviceInfo.getUniqueId
  // return await sha256(await DeviceInfo.getUniqueId()).catch(() => 'unknown');
}
