import type { TextStyle, ViewStyle } from 'react-native';

import type { Colors } from '../Colors';
import type { SchemeName } from '../types';

import { DarkTheme } from './DarkTheme';
import { LightTheme } from './LightTheme';

export interface ButtonStyle {
  container: ViewStyle;
  text: TextStyle;
}

export type Theme = {
  chat: {
    presence: ViewStyle;
    head: {
      avatar: {
        container: ViewStyle;
        text: TextStyle;
      };
      name: {
        container: ViewStyle;
        text: TextStyle;
      };
    };
    addUser: {
      container: ViewStyle;
      text: TextStyle;
    };
  };
  icon: {
    color: Colors;
  };
  terms: {
    variants: {
      dark: TextStyle;
      light: TextStyle;
    };
    text: TextStyle;
    link: {
      container: ViewStyle;
      text: TextStyle;
    };
  };
  ui: {
    button: {
      container: ViewStyle;
      text: TextStyle;
      variants: {
        disabled: ButtonStyle;
        disabledLink: ButtonStyle;
        feature: ButtonStyle;
        link: ButtonStyle;
        primary: ButtonStyle;
        secondary: ButtonStyle;
      };
    };
    icon: {
      color: Colors;
    };
    toast: {
      variants: {
        newMessage: {
          backgroundColor: Colors;
          shadowColor: Colors;
          gradientColor: [Colors, Colors];
          gradientBackgroundColor: Colors;
          highEmphasisTextColor: Colors;
          lowEmphasisTextColor: Colors;
        };
      };
    };
    text: {
      defaultColor: Colors;
    };
    container: {
      variants: {
        contrast: {
          backgroundColor: Colors;
        };
      };
    };
  };
};

export const themes: Record<SchemeName, Theme> = {
  light: LightTheme,
  dark: DarkTheme,
};
