import { DataStore } from 'aws-amplify/datastore';

import { ChatUnitUser } from '../../../models';

import { factory } from '@/services/factory/factory';

export const getChatUnitUsers = async (lastUpdated: Date | null) => {
  let results;
  if (lastUpdated) {
    results = await DataStore.query(ChatUnitUser, chatUnitUser =>
      chatUnitUser.updatedAt.gt(lastUpdated.toISOString()),
    );
  } else {
    results = await DataStore.query(ChatUnitUser);
  }

  return results.map(chatUnitUser => factory.chatUnitUser(chatUnitUser));
};
