import { ChatUnitColorId } from '../../../models';

import { addNewUsersToAllConversation } from './addNewUsersToAllConversation';

import type { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { updateChatUnitDetails } from '@/services/datastore/chatUnit/updateTableDetails';
import { createChatUnitUser } from '@/services/datastore/user/createChatUnitUser';
import { getChatUnitUsersForChatUnit } from '@/services/datastore/user/getChatUnitUsersForChatUnit';
import { updateChatUnitUser } from '@/services/datastore/user/updateChatUnitUser';
import { getRandomTableColor } from '@/utilities/constants/table-colors';

type NewUser = {
  avatar?: string;
  phoneNumber: string;
  username: string;
  userId: string;
};

export const addNewUsersToChatUnit = async (
  chatUnitId: string,
  newUsers: NewUser[],
  newUserStatus: ChatUnitUserStatus,
  currentUserId: string,
) => {
  // assumes that the current user is in results
  const existingChatUsers = await getChatUnitUsersForChatUnit(chatUnitId);

  // remove users from newUsers array that already exist in existingChatUsers
  const usersTobeCreated = newUsers.filter(
    newUser =>
      !existingChatUsers.some(
        existingChatUser => existingChatUser.id === newUser.userId,
      ),
  );

  // find users from existingChatUsers array that are already in newUsers
  const usersToBeUpdated = existingChatUsers.filter(existingChatUser =>
    newUsers.some(newUser => newUser.userId === existingChatUser.id),
  );

  const allowedUserIds = Array.from(
    // use Set to remove duplicates
    new Set([
      currentUserId,
      ...existingChatUsers.map(cu => cu.id), // chatUnitUser.id is the user.id
      ...newUsers.map(user => user.userId),
    ]),
  );

  const updatedChatUnit = await updateChatUnitDetails(chatUnitId, {
    allowedUserIds,
  });

  if (!updatedChatUnit) {
    throw new Error('chatUnit not updated');
  }

  const newChatUsers = await Promise.all(
    usersTobeCreated.map(async user => {
      const { avatar, phoneNumber, username, userId } = user;

      return await createChatUnitUser({
        allowedUserIds,
        avatar: avatar || getRandomTableColor(ChatUnitColorId.BLACK),
        chatUnitId: updatedChatUnit.id,
        userId,
        username,
        owner: `${userId}::${userId}`,
        phoneNumber,
        status: newUserStatus,
      });
    }),
  );

  // update existing users with allowedUserIds
  await Promise.all(
    existingChatUsers.map(chatUnitUser =>
      updateChatUnitUser(chatUnitUser.chatUnitUserId, {
        allowedUserIds,
      }),
    ),
  );

  // update existing users who were added back with status PENDING
  await Promise.all(
    usersToBeUpdated.map(chatUnitUser =>
      updateChatUnitUser(chatUnitUser.chatUnitUserId, {
        status: newUserStatus,
      }),
    ),
  );

  await addNewUsersToAllConversation(updatedChatUnit, newChatUsers);
};
