/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const acceptShareableInviteForTarget = /* GraphQL */ `mutation AcceptShareableInviteForTarget($id: ID!) {
  acceptShareableInviteForTarget(id: $id)
}
` as GeneratedMutation<
  APITypes.AcceptShareableInviteForTargetMutationVariables,
  APITypes.AcceptShareableInviteForTargetMutation
>;
export const updateInviteStatus = /* GraphQL */ `mutation UpdateInviteStatus($id: ID!, $status: InviteStatus!) {
  updateInviteStatus(id: $id, status: $status) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInviteStatusMutationVariables,
  APITypes.UpdateInviteStatusMutation
>;
export const createActivity = /* GraphQL */ `mutation CreateActivity(
  $input: CreateActivityInput!
  $condition: ModelActivityConditionInput
) {
  createActivity(input: $input, condition: $condition) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActivityMutationVariables,
  APITypes.CreateActivityMutation
>;
export const updateActivity = /* GraphQL */ `mutation UpdateActivity(
  $input: UpdateActivityInput!
  $condition: ModelActivityConditionInput
) {
  updateActivity(input: $input, condition: $condition) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityMutationVariables,
  APITypes.UpdateActivityMutation
>;
export const deleteActivity = /* GraphQL */ `mutation DeleteActivity(
  $input: DeleteActivityInput!
  $condition: ModelActivityConditionInput
) {
  deleteActivity(input: $input, condition: $condition) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivityMutationVariables,
  APITypes.DeleteActivityMutation
>;
export const createChatUnit = /* GraphQL */ `mutation CreateChatUnit(
  $input: CreateChatUnitInput!
  $condition: ModelChatUnitConditionInput
) {
  createChatUnit(input: $input, condition: $condition) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatUnitMutationVariables,
  APITypes.CreateChatUnitMutation
>;
export const updateChatUnit = /* GraphQL */ `mutation UpdateChatUnit(
  $input: UpdateChatUnitInput!
  $condition: ModelChatUnitConditionInput
) {
  updateChatUnit(input: $input, condition: $condition) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatUnitMutationVariables,
  APITypes.UpdateChatUnitMutation
>;
export const deleteChatUnit = /* GraphQL */ `mutation DeleteChatUnit(
  $input: DeleteChatUnitInput!
  $condition: ModelChatUnitConditionInput
) {
  deleteChatUnit(input: $input, condition: $condition) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatUnitMutationVariables,
  APITypes.DeleteChatUnitMutation
>;
export const createInvite = /* GraphQL */ `mutation CreateInvite(
  $input: CreateInviteInput!
  $condition: ModelInviteConditionInput
) {
  createInvite(input: $input, condition: $condition) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInviteMutationVariables,
  APITypes.CreateInviteMutation
>;
export const updateInvite = /* GraphQL */ `mutation UpdateInvite(
  $input: UpdateInviteInput!
  $condition: ModelInviteConditionInput
) {
  updateInvite(input: $input, condition: $condition) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInviteMutationVariables,
  APITypes.UpdateInviteMutation
>;
export const deleteInvite = /* GraphQL */ `mutation DeleteInvite(
  $input: DeleteInviteInput!
  $condition: ModelInviteConditionInput
) {
  deleteInvite(input: $input, condition: $condition) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInviteMutationVariables,
  APITypes.DeleteInviteMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation(
  $input: CreateConversationInput!
  $condition: ModelConversationConditionInput
) {
  createConversation(input: $input, condition: $condition) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation(
  $input: UpdateConversationInput!
  $condition: ModelConversationConditionInput
) {
  updateConversation(input: $input, condition: $condition) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation(
  $input: DeleteConversationInput!
  $condition: ModelConversationConditionInput
) {
  deleteConversation(input: $input, condition: $condition) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createMessageV2 = /* GraphQL */ `mutation CreateMessageV2(
  $input: CreateMessageV2Input!
  $condition: ModelMessageV2ConditionInput
) {
  createMessageV2(input: $input, condition: $condition) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    assetMetadata
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageV2MutationVariables,
  APITypes.CreateMessageV2Mutation
>;
export const updateMessageV2 = /* GraphQL */ `mutation UpdateMessageV2(
  $input: UpdateMessageV2Input!
  $condition: ModelMessageV2ConditionInput
) {
  updateMessageV2(input: $input, condition: $condition) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    assetMetadata
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageV2MutationVariables,
  APITypes.UpdateMessageV2Mutation
>;
export const deleteMessageV2 = /* GraphQL */ `mutation DeleteMessageV2(
  $input: DeleteMessageV2Input!
  $condition: ModelMessageV2ConditionInput
) {
  deleteMessageV2(input: $input, condition: $condition) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    assetMetadata
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageV2MutationVariables,
  APITypes.DeleteMessageV2Mutation
>;
export const createOrganisation = /* GraphQL */ `mutation CreateOrganisation(
  $input: CreateOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  createOrganisation(input: $input, condition: $condition) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganisationMutationVariables,
  APITypes.CreateOrganisationMutation
>;
export const updateOrganisation = /* GraphQL */ `mutation UpdateOrganisation(
  $input: UpdateOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  updateOrganisation(input: $input, condition: $condition) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganisationMutationVariables,
  APITypes.UpdateOrganisationMutation
>;
export const deleteOrganisation = /* GraphQL */ `mutation DeleteOrganisation(
  $input: DeleteOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  deleteOrganisation(input: $input, condition: $condition) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganisationMutationVariables,
  APITypes.DeleteOrganisationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createChatUnitUser = /* GraphQL */ `mutation CreateChatUnitUser(
  $input: CreateChatUnitUserInput!
  $condition: ModelChatUnitUserConditionInput
) {
  createChatUnitUser(input: $input, condition: $condition) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatUnitUserMutationVariables,
  APITypes.CreateChatUnitUserMutation
>;
export const updateChatUnitUser = /* GraphQL */ `mutation UpdateChatUnitUser(
  $input: UpdateChatUnitUserInput!
  $condition: ModelChatUnitUserConditionInput
) {
  updateChatUnitUser(input: $input, condition: $condition) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatUnitUserMutationVariables,
  APITypes.UpdateChatUnitUserMutation
>;
export const deleteChatUnitUser = /* GraphQL */ `mutation DeleteChatUnitUser(
  $input: DeleteChatUnitUserInput!
  $condition: ModelChatUnitUserConditionInput
) {
  deleteChatUnitUser(input: $input, condition: $condition) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatUnitUserMutationVariables,
  APITypes.DeleteChatUnitUserMutation
>;
export const createNewInvite = /* GraphQL */ `mutation CreateNewInvite($input: CreateNewInviteInput!) {
  createNewInvite(input: $input) {
    inviteId
    message
    newInvites {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    newSenderInvite {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNewInviteMutationVariables,
  APITypes.CreateNewInviteMutation
>;
export const batchUpdateMessages = /* GraphQL */ `mutation BatchUpdateMessages($input: [UpdateMessageV2Input!]!) {
  batchUpdateMessages(input: $input)
}
` as GeneratedMutation<
  APITypes.BatchUpdateMessagesMutationVariables,
  APITypes.BatchUpdateMessagesMutation
>;
export const sendPushMessages = /* GraphQL */ `mutation SendPushMessages($msg: String, $title: String) {
  sendPushMessages(msg: $msg, title: $title)
}
` as GeneratedMutation<
  APITypes.SendPushMessagesMutationVariables,
  APITypes.SendPushMessagesMutation
>;
export const joinOpenInvite = /* GraphQL */ `mutation JoinOpenInvite(
  $chatUnitId: String
  $uniqueUrlId: String
  $userId: String
) {
  joinOpenInvite(
    chatUnitId: $chatUnitId
    uniqueUrlId: $uniqueUrlId
    userId: $userId
  ) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.JoinOpenInviteMutationVariables,
  APITypes.JoinOpenInviteMutation
>;
