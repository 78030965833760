import Avatar from '@/components/avatar';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { DEFAULT_CHAT_UNIT_IMAGE } from '@/utilities/constants/background-images';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import samplePerson1 from '@/assets/onboarding/avatar_one.png';
import samplePerson2 from '@/assets/onboarding/avatar_two.png';
import { getAllAvatarsMap } from '@/services/assetService';
import { Image, ImageBackground } from 'expo-image';

type Props = {
  avatar: string;
  shortName: string;
  fullName: string;
};

const CUSTOM_AVATAR_COLOR = '#17A4B0';
const AVATARS_MAP = getAllAvatarsMap();

const OnboardingAvatarPreview = ({ avatar, shortName, fullName }: Props) => {
  return (
    <ImageBackground source={DEFAULT_CHAT_UNIT_IMAGE} style={styles.bgPreview}>
      <View style={styles.avatarRow}>
        <Section jcc aic gap={6}>
          <Image source={samplePerson1} style={styles.avatar} />
          <AppText size={15} color={Colors.neutral0} textAlign="center">
            Jason
          </AppText>
        </Section>
        <Section jcc aic gap={6}>
          {avatar ? (
            <Image
              source={AVATARS_MAP.get(avatar)?.url || { uri: avatar }}
              style={styles.avatar}
            />
          ) : (
            <Avatar
              size={60}
              displayName={fullName}
              bgColor={CUSTOM_AVATAR_COLOR as Colors}
              fontSize={26}
              fontType="primary400"
            />
          )}
          <AppText size={15} color={Colors.neutral0} textAlign="center">
            {shortName}
          </AppText>
        </Section>
        <Section jcc aic gap={6}>
          <Image source={samplePerson2} style={styles.avatar} />
          <AppText size={15} color={Colors.neutral0} textAlign="center">
            Seema
          </AppText>
        </Section>
      </View>
      <View style={styles.avatarRowBar} />
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  bgPreview: {
    width: '100%',
    height: 120,
    borderColor: Colors.neutral30,
    borderWidth: 1,
    borderRadius: BorderRadius.sm,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -3,
  },
  avatarRowBar: {
    width: '100%',
    height: 74,
    backgroundColor: Colors.neutral80,
    position: 'absolute',
    bottom: 0,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    overflow: 'hidden',
    resizeMode: 'cover',
  },
  avatarRow: {
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 9,
    width: 198,
  },
});

export default OnboardingAvatarPreview;
