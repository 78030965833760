import { post } from 'aws-amplify/api';

import type { User } from '../../models';

import { logger } from '@/services/logger/logger';

type UserResponse = {
  created: boolean;
  cognitoId: string;
  phoneNumber: string;
  userProfile: User | null;
}[];
export const createAuthUsers = async (
  phoneNumbers: string[],
): Promise<UserResponse> => {
  try {
    const { body } = await post({
      apiName: 'restApi8Seats',
      options: {
        body: {
          phoneNumbers,
        },
      },
      path: '/user',
    }).response;
    return (await body.json()) as unknown as UserResponse;
  } catch (err) {
    logger.error('ERROR::', err);
    throw err;
  }
};

export const acceptInvitation = async (id: string) => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve({
        message: `Invitation with ${id} accepted successfully`,
        status: 200,
      });
    }, 0);
  });
};

export const declineInvitation = async (id: string) => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve({
        message: `Invitation with ${id} accepted declined`,
        status: 200,
      });
    }, 0);
  });
};
