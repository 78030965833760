import isEqual from 'fast-deep-equal';
import React, { memo, useMemo, useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType } from '../../../../API';

import { Avatars } from './Avatars';
import { CardBadge } from './CardBadge';
import { MoreIndicator } from './Rooms/MoreIndicator';
import { WorkspaceIndicator } from './WorkspaceIndicator';
import { styles } from './styles';

import SettingButtonIcon from '@/assets/settings_button_icon.svg';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useNavigation from '@/hooks/useNavigation';
import type { ChatUnit } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Brand } from '@/ui/common/colors';

type Props = ChatUnit & { organisationName?: string | null };

const ChatUnitCard = (chatUnit: Props) => {
  const { navigate } = useNavigation();

  const type = chatUnit.type ?? ChatUnitType.TABLE;
  const unreadCount = useMessageStore(
    useShallow(store => store.getUnreadCountForChatUnit(chatUnit.id)),
  );
  const { translate } = useTranslations();
  const tableUsers = useTableUsersStore(
    useShallow(store => store.getTableUsersForTable(chatUnit.id)),
    isEqual,
  );

  // for supersized table  and rooms
  const useExpandedAvatarLayout =
    type === ChatUnitType.ROOM || tableUsers.length > 8;
  const AVATAR_COUNT_LIMIT = useExpandedAvatarLayout ? 5 : 8;

  // Memoizing the previewTableUsers array
  const previewTableUsers = useMemo(
    () => tableUsers.slice(0, AVATAR_COUNT_LIMIT),
    [tableUsers, AVATAR_COUNT_LIMIT],
  );

  // Memoizing the onPress handler
  const onPress = useCallback(() => {
    navigate('ChatView', { chatUnitId: chatUnit.id });
  }, [navigate, chatUnit.id]);

  // Memoizing the goToSettings handler
  const goToSettings = useCallback(() => {
    navigate('ChatSettings', { chatUnitId: chatUnit.id });
  }, [navigate, chatUnit.id]);

  return (
    <TouchableOpacity onPress={onPress} style={styles.roomCard}>
      <TouchableOpacity onPress={goToSettings} style={styles.settingsButton}>
        <SettingButtonIcon />
      </TouchableOpacity>
      <CardBadge unreadMessageCount={unreadCount ?? 0} />

      <View style={styles.cardBg}>
        <ChatUnitBackgroundImage
          imageKey={chatUnit.backgroundImage}
          width="100%"
          height="100%"
          onPress={onPress}
        />
      </View>

      <View style={styles.cardContent}>
        <AppText
          size={14}
          type="primary700"
          textAlign="center"
          numberOfLines={2}>
          {chatUnit.title}
        </AppText>

        <View style={styles.iconLabelContainer}>
          <View style={styles.icon}>
            <ChatUnitIcon fill={Brand.primary75} size={16} type={type} />
          </View>
          <AppText size={10}>{translate(type)}</AppText>
        </View>
        <Avatars
          tableUsers={previewTableUsers}
          // Supersized tables assumes chatUnitCard properties of a room
          type={
            useExpandedAvatarLayout ? ChatUnitType.ROOM : ChatUnitType.TABLE
          }
        />
        <MoreIndicator
          count={tableUsers.length - previewTableUsers.length}
          hidden={!useExpandedAvatarLayout}
          invisible={tableUsers.length <= AVATAR_COUNT_LIMIT}
        />
        <WorkspaceIndicator organisationName={chatUnit.organisationName} />
      </View>
    </TouchableOpacity>
  );
};

export default memo(ChatUnitCard);
