import type { Message } from '@/services/chatUnit/types';
import { factory } from '@/services/factory/factory';

export const processMessageResults = (messages: any): Message[] => {
  if (!Array.isArray(messages)) {
    return [];
  }
  return messages.reduce((acc: any, message: any) => {
    try {
      if (!message._deleted) {
        acc.push(factory.message(message));
      }
    } catch (e) {
      console.log('processMessageResults', e);
    } finally {
      return acc;
    }
  }, [] as any);
};
