import React from 'react';
import { View } from 'react-native';

import { ChatUnitType } from '../../../../../API';
import { styles } from '../styles';

import { InThisRoom } from './InThisRoom';
import MyUserCard from './MyUserCard';
import { SettingsSubtitle } from './SettingsSubtitle';
import TableUsers from './TableUsers';

import { getName } from '@/domain/user/functions/getName';
import type { MemberType } from '@/models';
import type { ChatUnitUser } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { pluralize } from '@/utilities/helpers/pluralize';

type Props = {
  isOwner: boolean;
  myChatUser?: ChatUnitUser;
  otherChatUsers: ChatUnitUser[];
  ownerId?: string;
  chatUnitId: string;
  type: ChatUnitType;
  chatUnitTitle: string;
  orgMembers: (MemberType | null)[];
  orgId?: string;
};

export const InThisChat = ({
  isOwner,
  myChatUser,
  otherChatUsers,
  ownerId,
  chatUnitId,
  type,
  chatUnitTitle,
  orgMembers,
  orgId,
}: Props) => {
  const { translate } = useTranslations();

  return (
    <>
      <SettingsSubtitle
        subtitle={pluralize(
          otherChatUsers.length + 1,
          translate('table_members_label'),
        )}
        title={translate(
          type === ChatUnitType.TABLE
            ? 'on_this_chat_unit'
            : 'in_this_chat_unit',
          {
            capitalized_type: translate(type),
          },
        )}
      />

      {type === ChatUnitType.ROOM ? (
        <InThisRoom
          memberCount={otherChatUsers.length + 1}
          chatUnitId={chatUnitId}
        />
      ) : (
        <>
          <MyUserCard
            avatar={myChatUser?.avatar || ''}
            isTableOwner={isOwner}
            participantsCount={otherChatUsers.length + 1}
            type={type}
            userName={myChatUser ? getName(myChatUser, 'short') : ''}
            orgMembers={orgMembers}
            orgId={orgId}
            tableUser={myChatUser}
          />
          <View style={styles.spacer10} />
          <TableUsers
            chatUnitType={type}
            otherTableUsers={otherChatUsers}
            ownerId={ownerId || ''}
            chatUnitTitle={chatUnitTitle}
            orgMembers={orgMembers}
            orgId={orgId}
          />
        </>
      )}
    </>
  );
};
