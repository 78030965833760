import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType, InviteTargetType } from '../../../../models';

import PhoneIcon from '@/assets/svg/phone-icon.svg';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import Avatar from '@/components/avatar';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import FreePill from '@/components/organisation/FreePill';
import Section from '@/components/section/Section';
import { useExperience } from '@/context/Experience';
import { WorkspaceIndicator } from '@/domain/chatUnit/components/chatUnitCard/WorkspaceIndicator';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { updateInviteBasedOnStatus } from '@/domain/invites/helpers/setStatus';
import { type Invite, InviteStatus } from '@/domain/invites/types/types';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { Colors } from '@/domain/theme/Colors';
import { ligthBlue2, purple, red } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import Icon from '@/ui/app/elements/Icon';
import { Greys } from '@/ui/common/colors';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { InviteTypeConversion } from '@/utilities/helpers/inviteTypesConverter';
import { web } from '@/utilities/platform';

const ReceivedInviteTypeText = {
  invite_chat_unit_type: {
    [InviteTargetType.QUICK_CHAT]: 'DM',
    [InviteTargetType.TABLE]: 'TABLE',
    [InviteTargetType.ROOM]: 'ROOM',
    [InviteTargetType.ORGANISATION]: 'ORGANISATION',
  },
  join_chat_unit_type: {
    [InviteTargetType.QUICK_CHAT]: 'join_dm',
    [InviteTargetType.TABLE]: 'join_table',
    [InviteTargetType.ROOM]: 'join_room',
    [InviteTargetType.ORGANISATION]: 'join_organisation',
  },
};

const SingleReceivedInvitation = ({ data }: { data: Invite }) => {
  const navigation = useNavigation();
  const { track } = useExperience();
  const organisations = useOrganisations();
  const { translate } = useTranslations();
  const clearChatUnit = useMessageStore(
    useShallow(state => state.clearChatUnit),
  );
  const orgName = useMemo(() => {
    const org = organisations.find(org => org.id === data?.targetInfo?.orgId);
    return org?.name;
  }, [organisations, data]);

  const declineInvite = () => {
    track('Invite Declined', { inviteId: data.inviteId });
    updateInviteBasedOnStatus(data.id, InviteStatus.DECLINED);
    // clear messages from store
    clearChatUnit(data.targetId);
    navigation.goBack();
  };
  const acceptInvite = () => {
    track('Invite Accepted', { inviteId: data.inviteId });
    updateInviteBasedOnStatus(data.id, InviteStatus.ACCEPTED);
    if (
      data.targetType === InviteTargetType.ORGANISATION &&
      data.targetInfo.chatUnitId
    ) {
      navigation.dispatch(StackActions.pop(2));
      navigation.navigate('ChatView', {
        chatUnitId: data.targetInfo.chatUnitId,
      });
    } else if (
      data.targetType !== InviteTargetType.ORGANISATION &&
      data.targetId
    ) {
      // Pop two screens off the stack (InviteDetails and InviteList)
      navigation.dispatch(StackActions.pop(2));
      // Navigate to ChatView with the appropriate params
      navigation.navigate('ChatView', { chatUnitId: data.targetId });
    }
  };

  const insets = useSafeAreaInsets();

  return (
    <View style={[styles.container, { marginTop: 40 + insets.top }]}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <View style={styles.backgroundImg}>
          <ChatUnitBackgroundImage
            imageKey={data.targetInfo.backgroundImage}
            width="100%"
            height="100%"
          />
          {data.targetType === InviteTargetType.ORGANISATION ? (
            <View style={styles.orgPillContainer}>
              <FreePill plan="Free team" />
              <AppText size={12} type="primary600">
                {translate('Workspace')}
              </AppText>
            </View>
          ) : null}
        </View>

        <View style={styles.tableIcon}>
          {data.targetType &&
            data.targetType !== InviteTargetType.ORGANISATION && (
              <ChatUnitIcon
                fill={Greys.shade0}
                size={70}
                type={
                  InviteTypeConversion[data.targetType] ?? ChatUnitType.TABLE
                }
              />
            )}
        </View>
        <View style={styles.subheader}>
          <AppText size={12} type="primary700">
            {data?.inviterInfo?.name}&nbsp;
          </AppText>
          <AppText size={12}>
            {translate('invited_you')}&nbsp;&nbsp;&nbsp;
          </AppText>
          {data.targetType === InviteTargetType.ORGANISATION ||
          data.targetInfo?.orgId ? (
            <>
              <WorkspaceIndicator
                organisationName={orgName ?? 'workspace'}
                size={12}
              />
            </>
          ) : (
            <WorkspaceIndicator size={12} />
          )}
          <AppText> </AppText>
          <AppText size={12}>
            {data.targetType !== InviteTargetType.ORGANISATION
              ? translate(
                  ReceivedInviteTypeText.invite_chat_unit_type[
                    data.targetType ?? InviteTargetType.TABLE
                  ],
                )
              : translate('workspace').toLowerCase()}
          </AppText>
        </View>
        <Spacer height={10} />
        <AppText size={21} type="primary700" textAlign="center">
          {data.targetInfo?.title}
        </AppText>
        <Spacer height={10} />
        <Spacer height={1} bg={Colors.neutral10} />
        <View>
          <>
            {data.status === InviteStatus.PENDING && (
              <View style={styles.top16}>
                <View style={styles.replyStatusView}>
                  <>
                    <Icon
                      fillColor={Colors.neutral80 as any}
                      iconKey="Bell"
                      size={18}
                    />
                    <Spacer width={8} />
                    <AppText size={14}>{translate('have_not_replied')}</AppText>
                  </>
                </View>
                <Section row aic jcsb gap={10} ph={20} mt={20} mb={15}>
                  <Section
                    onPress={acceptInvite}
                    jcc
                    aic
                    pv={8}
                    ph={28}
                    f1
                    br={20}
                    bw={2}
                    bc={Colors.primaryLight}
                    bg={Colors.primaryLight}>
                    <AppText
                      size={17}
                      type="primary700"
                      color={Colors.neutral0}>
                      Join
                    </AppText>
                  </Section>
                  <Section
                    onPress={declineInvite}
                    jcc
                    aic
                    pv={8}
                    ph={28}
                    f1
                    br={20}
                    bw={2}
                    bc={Colors.neutral80}
                    bg={Colors.neutral0}>
                    <AppText size={17} type="primary700">
                      Decline
                    </AppText>
                  </Section>
                </Section>
              </View>
            )}
            {data.status === InviteStatus.DECLINED && (
              <View style={styles.buttonsContainer}>
                <Text style={styles.declinedText}>
                  {translate('you_declined')}
                </Text>
              </View>
            )}
            {data.status === InviteStatus.ACCEPTED && (
              <View style={styles.buttonsContainer}>
                <Text style={styles.acceptedText}>
                  {translate('you_accepted')}
                </Text>
              </View>
            )}
            <View style={styles.divider} />
            <Section ph={15} mt={15}>
              <AppText type="primary700" size={12} color={Colors.neutral50}>
                INVITE FROM
              </AppText>
            </Section>

            <View style={styles.userContainer}>
              {data?.inviterInfo?.avatar || data?.inviterInfo?.name ? (
                <View style={styles.avatarContainer}>
                  <Avatar
                    style={styles.acceptedInvitationImage}
                    imageKey={data?.inviterInfo?.avatar}
                    displayName={data?.inviterInfo?.name}
                  />
                </View>
              ) : null}
              <View>
                <Text style={styles.userName}>{data?.inviterInfo?.name}</Text>
                <View style={styles.phoneContainer}>
                  <PhoneIcon />
                  <Text style={styles.phoneNumber}>
                    {data?.inviterInfo?.phone.split(' ')[0]}
                  </Text>
                </View>
              </View>
            </View>
          </>
        </View>
      </ScrollView>
    </View>
  );
};

export default SingleReceivedInvitation;

const styles = StyleSheet.create({
  mainButton: { width: web ? '40%' : wp(40) },
  acceptBtn: {
    backgroundColor: Greys.shade0,
    borderColor: Greys.shade600,
    borderWidth: 2,
  },
  acceptBtnTxt: {
    color: Greys.shade600,
    fontSize: 17,
    fontWeight: '700',
  },
  acceptedInvitation: {
    alignItems: 'center',
    borderBottomColor: Greys.shade200,
    borderBottomWidth: 1,
    flexDirection: 'row',
    marginBottom: 5,
    paddingHorizontal: 8,
    paddingLeft: 16,
    paddingVertical: 8,
  },
  acceptedInvitationImage: {
    borderColor: Greys.shade600,
    borderRadius: 15,
    borderWidth: 2,
    height: 28,
    marginRight: 0,
    width: 28,
  },
  acceptedInvitationText: {
    color: Greys.shade600,
    fontSize: 14,
    marginRight: 5,
  },
  acceptedText: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '400',
  },
  actionButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 6,
  },
  avatarContainer: {
    alignItems: 'center',
    borderColor: Greys.shade600,
    borderRadius: 14,
    borderWidth: 1,
    height: 29,
    justifyContent: 'center',
    marginRight: 16,
    overflow: 'hidden',
    width: 29,
  },
  avatarImg: {
    borderRadius: 14,
    height: '100%',
    width: '100%',
  },
  backgroundImg: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: web ? 160 : wp(40),
    width: '100%',
  },
  boldTxt: {
    fontWeight: '700',
  },
  btnTxt: {
    fontSize: 17,
    fontWeight: '700',
  },
  buttonsContainer: {
    alignItems: 'center',
    marginBottom: 5,
    marginTop: 10,
  },
  cancelAllBtn: {
    alignSelf: 'center',
    marginVertical: 32,
  },
  cancelAllText: {
    color: red,
    fontSize: 14,
    fontWeight: '400',
  },
  cancelButton: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 5,
    marginTop: 20,
  },
  cancelText: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '400',
  },
  container: {
    backgroundColor: Greys.shade0,
    borderRadius: 20,
    elevation: 10,
    shadowColor: Greys.shade999,
    shadowOffset: {
      height: 10,
      width: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 10,
  },
  declinedText: {
    color: red,
    fontSize: 14,
    fontWeight: '400',
  },
  diamond: {
    backgroundColor: ligthBlue2,
    height: 10,
    marginLeft: 4,
    marginRight: 4,
    transform: [{ rotate: '45deg' }],
    width: 10,
  },
  divider: {
    backgroundColor: Greys.shade200,
    height: 1,
    marginTop: 16,
    width: web ? '100%' : wp(100),
  },
  expandableItem: {
    borderBottomColor: Greys.shade200,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  expandableItemTitle: {
    color: Greys.shade500,
    fontSize: 12,
    fontWeight: '700',
    paddingLeft: 6,
  },
  expandablesContainer: {
    width: '100%',
  },
  flagLabel: {
    color: Greys.shade0,
    fontSize: 10,
    fontWeight: '600',
  },
  flexWithGap: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
    marginLeft: 12,
  },
  invitedPeopleContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  invitedPersonTxt: {
    color: Greys.shade600,
    fontSize: 10,
    lineHeight: 14,
    marginLeft: 10,
  },
  marginBottom0: { marginBottom: 0 },
  marginBottom16: { marginBottom: 16 },
  messageTxt: {
    color: Greys.shade0,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
  },
  messageView: {
    backgroundColor: purple,
    borderRadius: 9,
    marginHorizontal: 16,
    marginTop: 15,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  newBadge: {
    backgroundColor: red,
    borderRadius: 8,
    marginRight: 4,
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
  notRepliedTxt: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '400',
    paddingLeft: 4,
  },
  outlinedButton: {
    borderColor: Greys.shade999,
    height: 40,
    marginLeft: 16,
    width: web ? '40%' : wp(40),
  },
  outlinedButtonTitle: {
    color: Greys.shade999,
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 21,
  },
  personsContainer: {
    paddingHorizontal: 8,
  },
  phoneContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 5,
  },
  phoneNumber: {
    color: Colors.neutral70,
    fontSize: 10,
    fontFamily: Fonts.primary400,
  },
  receivedInvitationsStatusesRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  replyStatusView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  singleInvitedPerson: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 5,
    width: '50%',
  },
  small10Txt: {
    color: Greys.shade600,
    fontSize: 10,
    fontWeight: '400',
  },
  subheader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 30,
  },
  subheaderBottomRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  subheaderRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
  subheaderSentContainer: {
    marginBottom: 16,
  },
  subheaderTxt: {
    color: Greys.shade600,
    fontSize: 12,
    lineHeight: 16,
  },
  tableIcon: {
    alignSelf: 'center',
    position: 'absolute',
    top: web ? '12%' : wp(12),
    width: web ? '20%' : wp(20),
    ...(web && { width: '100%', alignItems: 'center' }),
  },
  tableType: {
    fontWeight: '700',
  },
  title: {
    color: Greys.shade600,
    fontWeight: '700',
    height: 21,
    lineHeight: 21,
  },
  titleContainer: {
    alignItems: 'center',
    borderBottomColor: Greys.shade200,
    borderBottomWidth: 1,
    marginTop: 16,
  },
  top16: { marginTop: 16 },
  userContainer: {
    flexDirection: 'row',
    marginTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  userName: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '700',
  },
  orgPillContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 4,
    ...Platform.select({
      ios: {
        shadowColor: Colors.neutral100,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 4,
      },
      android: {
        elevation: 2,
      },
      web: {
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      },
    }),
    paddingVertical: 4,
    paddingLeft: 4,
    paddingRight: 8,
    borderRadius: BorderRadius.lg,
    alignSelf: 'center',
    position: 'absolute',
    bottom: -16,
    backgroundColor: Colors.neutral0,
  },
});
