import Section from '@/components/section/Section';
import type { Asset, Post } from '@/domain/cms/graphql/generated/graphql';
import useCMSStore from '@/domain/cms/useCMSStore';
import { Colors } from '@/domain/theme/Colors';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import moment from 'moment';
import {
  type DimensionValue,
  FlatList,
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import RenderHtml from 'react-native-render-html';
import DiscoverUnreadIndicator from './DiscoverUnreadIndicator';
import PostButton from './PostButton';
import PostImage from './PostImage';
import PostLink from './PostLink';

export default function PostCard(props: Post) {
  const html = documentToHtmlString(props.description!.json);
  const { width } = useWindowDimensions();
  const htmlContentWidth = width - 80;

  const lastReadAt = useCMSStore(
    state => state.lastReadDiscoveryPostPublishedAtInMillis,
  );

  const isUnread = moment(props.publishDate || props.sys.publishedAt).isAfter(
    lastReadAt,
  );

  return (
    <>
      <Section
        br={BorderRadius.lg}
        bg={Colors.neutral0}
        style={styles.shadowStyles}>
        {isUnread ? (
          <View style={styles.unread}>
            <DiscoverUnreadIndicator />
          </View>
        ) : null}
        <PostImage
          {...(props.image as Asset)}
          sectionTitle={props.type?.name || ''}
        />
        <Section p={20}>
          {props.author?.name ? (
            <Section row aic jcsb mb={10}>
              <AppText type="primary600" size={14} color={Colors.neutral60}>
                {props.author?.name}
              </AppText>
              <AppText type="primary600" size={14} color={Colors.neutral60}>
                {moment(props.publishDate || props.sys.publishedAt).format(
                  'MMM DD YYYY',
                )}
              </AppText>
            </Section>
          ) : null}
          <AppText type="secondary500" size={21}>
            {props.title}
          </AppText>
          <RenderHtml
            contentWidth={htmlContentWidth}
            source={{ html }}
            tagsStyles={tagStyles}
            defaultTextProps={{
              style: tagStyles.body,
              allowFontScaling: false,
            }}
          />
          <FlatList
            scrollEnabled={false}
            data={props.linksCollection?.items || []}
            renderItem={({ item }) => <PostLink {...item} />}
            keyExtractor={item => item._id}
            ItemSeparatorComponent={() => <Spacer height={20} />}
          />
          {/* Add a spacer only if collections for both buttons and links exist */}
          {props.buttonsCollection?.items?.length &&
          props.linksCollection?.items?.length ? (
            <Spacer height={20} />
          ) : null}
          <FlatList
            scrollEnabled={false}
            data={props.buttonsCollection?.items || []}
            renderItem={({ item }) => <PostButton {...item} />}
            keyExtractor={item => item._id}
            ItemSeparatorComponent={() => <Spacer height={10} />}
          />
        </Section>
      </Section>
    </>
  );
}

const styles = StyleSheet.create({
  shadowStyles: {
    ...Platform.select({
      default: {
        shadowColor: Colors.neutral100,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
    }),
    width: '100%',
    maxWidth: web ? ('calc(100% - 16px)' as DimensionValue) : undefined,
  },
  unread: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 4,
  },
});

const tagStyles = {
  body: {
    fontFamily: Fonts.primary400,
    fontSize: 14,
    color: Colors.neutral80,
  },
  p: {
    fontFamily: Fonts.primary400,
    fontSize: 14,
    color: Colors.neutral80,
  },
  strong: {
    fontFamily: Fonts.primary700,
    fontSize: 14,
    color: Colors.neutral80,
  },
  h1: {
    fontFamily: Fonts.primary700,
    fontSize: 21,
    color: Colors.neutral80,
  },
  h2: {
    fontFamily: Fonts.primary700,
    fontSize: 18,
    color: Colors.neutral80,
  },
  h3: {
    fontFamily: Fonts.primary700,
    fontSize: 16,
    color: Colors.neutral80,
  },
  h4: {
    fontFamily: Fonts.primary700,
    fontSize: 14,
    color: Colors.neutral80,
  },
  h5: {
    fontFamily: Fonts.primary700,
    fontSize: 14,
    color: Colors.neutral80,
  },
  h6: {
    fontFamily: Fonts.primary700,
    fontSize: 14,
    color: Colors.neutral80,
  },
};
