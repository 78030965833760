import React, { useCallback } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import AddIcon from '@/assets/svg/add-friend-icon.svg';
import { constants } from '@/constants';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import SelectedContacts from '@/domain/contact/components/directory/selectedContacts';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Icon } from '@/ui/app/elements';
import { Brand, Neutrals } from '@/ui/common/colors';

type Props = {
  addHandler: () => void;
  contacts: ContactInput[];
  setContacts: (contacts: ContactInput[]) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  onSubmitEditing?: () => void;
};

export const UsersInputGroup = ({
  addHandler,
  contacts,
  setContacts,
  searchQuery,
  setSearchQuery,
  onSubmitEditing,
}: Props) => {
  const { translate } = useTranslations();
  const remainingUserCapacity = constants.dmUserLimit - contacts.length - 1; // remember to account for current user

  const onContactPress = useCallback(
    (contact: ContactInput) => {
      // remove contact from selectedContacts
      setContacts([
        ...contacts.filter(
          selectedContact => selectedContact.id !== contact.id,
        ),
      ]);
    },
    [contacts, setContacts],
  );

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.iconGroup}>
          <Icon
            fill={Brand.primary75}
            iconKey="Bust"
            size={18}
            style={styles.icon}
          />
          <AppText style={styles.label} size={12} type="primary700">
            {translate('To')}
          </AppText>
        </View>
        <View>
          <AppText style={styles.headerNote} size={12} type="primary400">
            {translate('add_up_to', { count: remainingUserCapacity })}
          </AppText>
        </View>
      </View>
      <View style={styles.userInputContainer}>
        <View style={styles.userInput}>
          <SelectedContacts
            onContactPress={onContactPress}
            selectedContacts={contacts}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onSubmitEditing={onSubmitEditing}
          />
        </View>
        <Pressable onPress={addHandler}>
          <AddIcon size={40} />
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 10,
    minHeight: 28,
  },
  headerNote: { color: Colors.neutral70, textTransform: 'capitalize' },
  icon: {
    marginRight: 10,
  },
  iconGroup: {
    flexDirection: 'row',
  },
  label: {
    color: Colors.neutral70,
    textTransform: 'uppercase',
  },
  userInput: {
    flexGrow: 1,
    marginRight: 10,
  },
  userInputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
