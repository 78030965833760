/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getActivity = /* GraphQL */ `query GetActivity($PK: String!, $SK: String!) {
  getActivity(PK: $PK, SK: $SK) {
    PK
    SK
    owner
    userId
    model
    type
    relation
    payload
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityQueryVariables,
  APITypes.GetActivityQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities(
  $PK: String
  $SK: ModelStringKeyConditionInput
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listActivities(
    PK: $PK
    SK: $SK
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      PK
      SK
      owner
      userId
      model
      type
      relation
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
export const syncActivities = /* GraphQL */ `query SyncActivities(
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      PK
      SK
      owner
      userId
      model
      type
      relation
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncActivitiesQueryVariables,
  APITypes.SyncActivitiesQuery
>;
export const activitiesByOwnerAndType = /* GraphQL */ `query ActivitiesByOwnerAndType(
  $owner: String!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  activitiesByOwnerAndType(
    owner: $owner
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      PK
      SK
      owner
      userId
      model
      type
      relation
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActivitiesByOwnerAndTypeQueryVariables,
  APITypes.ActivitiesByOwnerAndTypeQuery
>;
export const activitiesByRelation = /* GraphQL */ `query ActivitiesByRelation(
  $relation: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  activitiesByRelation(
    relation: $relation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      PK
      SK
      owner
      userId
      model
      type
      relation
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActivitiesByRelationQueryVariables,
  APITypes.ActivitiesByRelationQuery
>;
export const getChatUnit = /* GraphQL */ `query GetChatUnit($id: ID!) {
  getChatUnit(id: $id) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatUnitQueryVariables,
  APITypes.GetChatUnitQuery
>;
export const listChatUnits = /* GraphQL */ `query ListChatUnits(
  $filter: ModelChatUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatUnitsQueryVariables,
  APITypes.ListChatUnitsQuery
>;
export const syncChatUnits = /* GraphQL */ `query SyncChatUnits(
  $filter: ModelChatUnitFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChatUnits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChatUnitsQueryVariables,
  APITypes.SyncChatUnitsQuery
>;
export const chatUnitByGuestOpenInviteId = /* GraphQL */ `query ChatUnitByGuestOpenInviteId(
  $guestOpenInviteId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelChatUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  chatUnitByGuestOpenInviteId(
    guestOpenInviteId: $guestOpenInviteId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatUnitByGuestOpenInviteIdQueryVariables,
  APITypes.ChatUnitByGuestOpenInviteIdQuery
>;
export const chatUnitByMemberOpenInviteId = /* GraphQL */ `query ChatUnitByMemberOpenInviteId(
  $memberOpenInviteId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelChatUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  chatUnitByMemberOpenInviteId(
    memberOpenInviteId: $memberOpenInviteId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatUnitByMemberOpenInviteIdQueryVariables,
  APITypes.ChatUnitByMemberOpenInviteIdQuery
>;
export const getInvite = /* GraphQL */ `query GetInvite($id: ID!) {
  getInvite(id: $id) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetInviteQueryVariables, APITypes.GetInviteQuery>;
export const listInvites = /* GraphQL */ `query ListInvites(
  $id: ID
  $filter: ModelInviteFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvites(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvitesQueryVariables,
  APITypes.ListInvitesQuery
>;
export const syncInvites = /* GraphQL */ `query SyncInvites(
  $filter: ModelInviteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvitesQueryVariables,
  APITypes.SyncInvitesQuery
>;
export const invitesByInviteIdAndOwner = /* GraphQL */ `query InvitesByInviteIdAndOwner(
  $inviteId: ID!
  $owner: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByInviteIdAndOwner(
    inviteId: $inviteId
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitesByInviteIdAndOwnerQueryVariables,
  APITypes.InvitesByInviteIdAndOwnerQuery
>;
export const invitesByOwner = /* GraphQL */ `query InvitesByOwner(
  $owner: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitesByOwnerQueryVariables,
  APITypes.InvitesByOwnerQuery
>;
export const invitesByTargetIdAndStatus = /* GraphQL */ `query InvitesByTargetIdAndStatus(
  $targetId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByTargetIdAndStatus(
    targetId: $targetId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inviteId
      owner
      type
      status
      targetId
      targetType
      message
      isOpenInvite
      targetInfo
      inviterInfo
      inviteeInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitesByTargetIdAndStatusQueryVariables,
  APITypes.InvitesByTargetIdAndStatusQuery
>;
export const getConversation = /* GraphQL */ `query GetConversation($conversationId: ID!, $chatUnitId: ID!) {
  getConversation(conversationId: $conversationId, chatUnitId: $chatUnitId) {
    conversationId
    chatUnitId
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    chatUnitUserIds
    allowedUserIds
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConversationQueryVariables,
  APITypes.GetConversationQuery
>;
export const listConversations = /* GraphQL */ `query ListConversations(
  $conversationId: ID
  $chatUnitId: ModelIDKeyConditionInput
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConversations(
    conversationId: $conversationId
    chatUnitId: $chatUnitId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      conversationId
      chatUnitId
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatUnitUserIds
      allowedUserIds
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversationsQueryVariables,
  APITypes.ListConversationsQuery
>;
export const syncConversations = /* GraphQL */ `query SyncConversations(
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConversations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      conversationId
      chatUnitId
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatUnitUserIds
      allowedUserIds
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConversationsQueryVariables,
  APITypes.SyncConversationsQuery
>;
export const conversationByChatUnitID = /* GraphQL */ `query ConversationByChatUnitID(
  $chatUnitId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  conversationByChatUnitID(
    chatUnitId: $chatUnitId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      conversationId
      chatUnitId
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatUnitUserIds
      allowedUserIds
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConversationByChatUnitIDQueryVariables,
  APITypes.ConversationByChatUnitIDQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!, $createdAt: AWSDateTime!) {
  getMessage(id: $id, createdAt: $createdAt) {
    id
    conversationId
    userId
    chatUnitId
    text
    image
    chatUnitUserId
    createdAt
    allowedUserIds
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $id: ID
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMessages(
    id: $id
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      conversationId
      userId
      chatUnitId
      text
      image
      chatUnitUserId
      createdAt
      allowedUserIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const syncMessages = /* GraphQL */ `query SyncMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMessages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conversationId
      userId
      chatUnitId
      text
      image
      chatUnitUserId
      createdAt
      allowedUserIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMessagesQueryVariables,
  APITypes.SyncMessagesQuery
>;
export const messagesByConversationID = /* GraphQL */ `query MessagesByConversationID(
  $conversationId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByConversationID(
    conversationId: $conversationId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conversationId
      userId
      chatUnitId
      text
      image
      chatUnitUserId
      createdAt
      allowedUserIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByConversationIDQueryVariables,
  APITypes.MessagesByConversationIDQuery
>;
export const messagesByChatUnitID = /* GraphQL */ `query MessagesByChatUnitID(
  $chatUnitId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByChatUnitID(
    chatUnitId: $chatUnitId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conversationId
      userId
      chatUnitId
      text
      image
      chatUnitUserId
      createdAt
      allowedUserIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByChatUnitIDQueryVariables,
  APITypes.MessagesByChatUnitIDQuery
>;
export const getMessageV2 = /* GraphQL */ `query GetMessageV2($id: ID!) {
  getMessageV2(id: $id) {
    id
    userId
    SK
    allowedUserIds
    authorId
    chatUnitUserId
    chatUnitId
    chatUnitIdConversationId
    conversationId
    createdAt
    image
    messageId
    owner
    status
    text
    source
    metaData
    assetMetadata
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageV2QueryVariables,
  APITypes.GetMessageV2Query
>;
export const listMessageV2s = /* GraphQL */ `query ListMessageV2s(
  $id: ID
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMessageV2s(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessageV2sQueryVariables,
  APITypes.ListMessageV2sQuery
>;
export const syncMessageV2s = /* GraphQL */ `query SyncMessageV2s(
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMessageV2s(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMessageV2sQueryVariables,
  APITypes.SyncMessageV2sQuery
>;
export const messagesByUserIdV2 = /* GraphQL */ `query MessagesByUserIdV2(
  $userId: ID!
  $SK: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByUserIdV2(
    userId: $userId
    SK: $SK
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByUserIdV2QueryVariables,
  APITypes.MessagesByUserIdV2Query
>;
export const messagesByChatUnitIdConversationIdV2 = /* GraphQL */ `query MessagesByChatUnitIdConversationIdV2(
  $chatUnitIdConversationId: String!
  $SK: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByChatUnitIdConversationIdV2(
    chatUnitIdConversationId: $chatUnitIdConversationId
    SK: $SK
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByChatUnitIdConversationIdV2QueryVariables,
  APITypes.MessagesByChatUnitIdConversationIdV2Query
>;
export const messagesByConversationIdV2 = /* GraphQL */ `query MessagesByConversationIdV2(
  $conversationId: ID!
  $SK: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByConversationIdV2(
    conversationId: $conversationId
    SK: $SK
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByConversationIdV2QueryVariables,
  APITypes.MessagesByConversationIdV2Query
>;
export const messagesByMessageIdV2 = /* GraphQL */ `query MessagesByMessageIdV2(
  $messageId: ID!
  $SK: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageV2FilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByMessageIdV2(
    messageId: $messageId
    SK: $SK
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByMessageIdV2QueryVariables,
  APITypes.MessagesByMessageIdV2Query
>;
export const getOrganisation = /* GraphQL */ `query GetOrganisation($id: ID!) {
  getOrganisation(id: $id) {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganisationQueryVariables,
  APITypes.GetOrganisationQuery
>;
export const listOrganisations = /* GraphQL */ `query ListOrganisations(
  $filter: ModelOrganisationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganisationsQueryVariables,
  APITypes.ListOrganisationsQuery
>;
export const syncOrganisations = /* GraphQL */ `query SyncOrganisations(
  $filter: ModelOrganisationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrganisations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrganisationsQueryVariables,
  APITypes.SyncOrganisationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      firstName
      lastName
      username
      avatar
      email
      phoneNumber
      gender
      birthday
      pronouns
      marketingOptIn
      organisations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      firstName
      lastName
      username
      avatar
      email
      phoneNumber
      gender
      birthday
      pronouns
      marketingOptIn
      organisations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getChatUnitUser = /* GraphQL */ `query GetChatUnitUser($id: ID!) {
  getChatUnitUser(id: $id) {
    id
    chatUnitId
    userId
    username
    phoneNumber
    avatar
    status
    allowedUserIds
    conversationIds
    chatUnit {
      id
      title
      colorId
      backgroundImage
      description
      url
      guestOpenInviteId
      memberOpenInviteId
      chatUnitUsers {
        items {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      allowedUserIds
      type
      subtype
      allConversationId
      ownerDetails {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      organisationId
      organisation {
        id
        name
        billingId
        users
        members {
          userId
          role
          status
          name
          phoneNumber
          avatar
          __typename
        }
        allowedUserIds
        plan
        image
        usage {
          guests
          tables
          rooms
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    firstName
    lastName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    chatUnitChatUnitUsersId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatUnitUserQueryVariables,
  APITypes.GetChatUnitUserQuery
>;
export const listChatUnitUsers = /* GraphQL */ `query ListChatUnitUsers(
  $filter: ModelChatUnitUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatUnitUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatUnitUsersQueryVariables,
  APITypes.ListChatUnitUsersQuery
>;
export const syncChatUnitUsers = /* GraphQL */ `query SyncChatUnitUsers(
  $filter: ModelChatUnitUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChatUnitUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChatUnitUsersQueryVariables,
  APITypes.SyncChatUnitUsersQuery
>;
export const chatUnitUserByChatUnitID = /* GraphQL */ `query ChatUnitUserByChatUnitID(
  $chatUnitId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelChatUnitUserFilterInput
  $limit: Int
  $nextToken: String
) {
  chatUnitUserByChatUnitID(
    chatUnitId: $chatUnitId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatUnitUserByChatUnitIDQueryVariables,
  APITypes.ChatUnitUserByChatUnitIDQuery
>;
export const chatUnitUserByUserID = /* GraphQL */ `query ChatUnitUserByUserID(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelChatUnitUserFilterInput
  $limit: Int
  $nextToken: String
) {
  chatUnitUserByUserID(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatUnitUserByUserIDQueryVariables,
  APITypes.ChatUnitUserByUserIDQuery
>;
export const getBatchConversationMessages = /* GraphQL */ `query GetBatchConversationMessages(
  $chatUnitIdConversationIds: [String!]
  $lastMessageTime: String
  $nextTokens: [String]
  $limit: Int
) {
  getBatchConversationMessages(
    chatUnitIdConversationIds: $chatUnitIdConversationIds
    lastMessageTime: $lastMessageTime
    nextTokens: $nextTokens
    limit: $limit
  ) {
    chatUnitId
    conversationId
    messages {
      id
      userId
      SK
      allowedUserIds
      authorId
      chatUnitUserId
      chatUnitId
      chatUnitIdConversationId
      conversationId
      createdAt
      image
      messageId
      owner
      status
      text
      source
      metaData
      assetMetadata
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBatchConversationMessagesQueryVariables,
  APITypes.GetBatchConversationMessagesQuery
>;
export const getInvitesForTarget = /* GraphQL */ `query GetInvitesForTarget($id: ID!) {
  getInvitesForTarget(id: $id) {
    id
    inviteId
    owner
    type
    status
    targetId
    targetType
    message
    isOpenInvite
    targetInfo
    inviterInfo
    inviteeInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvitesForTargetQueryVariables,
  APITypes.GetInvitesForTargetQuery
>;
export const getOrganisationByUser = /* GraphQL */ `query GetOrganisationByUser {
  getOrganisationByUser {
    id
    name
    billingId
    users
    members {
      userId
      role
      status
      name
      phoneNumber
      avatar
      __typename
    }
    allowedUserIds
    plan
    image
    usage {
      guests
      tables
      rooms
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganisationByUserQueryVariables,
  APITypes.GetOrganisationByUserQuery
>;
export const getUsersDetails = /* GraphQL */ `query GetUsersDetails($users: [String]) {
  getUsersDetails(users: $users) {
    id
    name
    firstName
    lastName
    username
    avatar
    email
    phoneNumber
    gender
    birthday
    pronouns
    marketingOptIn
    organisations
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsersDetailsQueryVariables,
  APITypes.GetUsersDetailsQuery
>;
export const getChatUnitByUrl = /* GraphQL */ `query GetChatUnitByUrl($uniqueUrlId: String) {
  getChatUnitByUrl(uniqueUrlId: $uniqueUrlId) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatUnitByUrlQueryVariables,
  APITypes.GetChatUnitByUrlQuery
>;
export const getPublicChatUnitByUrl = /* GraphQL */ `query GetPublicChatUnitByUrl($uniqueUrlId: String) {
  getPublicChatUnitByUrl(uniqueUrlId: $uniqueUrlId) {
    id
    title
    colorId
    backgroundImage
    description
    url
    guestOpenInviteId
    memberOpenInviteId
    chatUnitUsers {
      items {
        id
        chatUnitId
        userId
        username
        phoneNumber
        avatar
        status
        allowedUserIds
        conversationIds
        chatUnit {
          id
          title
          colorId
          backgroundImage
          description
          url
          guestOpenInviteId
          memberOpenInviteId
          chatUnitUsers {
            nextToken
            startedAt
            __typename
          }
          allowedUserIds
          type
          subtype
          allConversationId
          ownerDetails {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          organisationId
          organisation {
            id
            name
            billingId
            users
            allowedUserIds
            plan
            image
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatUnitChatUnitUsersId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    allowedUserIds
    type
    subtype
    allConversationId
    ownerDetails {
      id
      chatUnitId
      userId
      username
      phoneNumber
      avatar
      status
      allowedUserIds
      conversationIds
      chatUnit {
        id
        title
        colorId
        backgroundImage
        description
        url
        guestOpenInviteId
        memberOpenInviteId
        chatUnitUsers {
          items {
            id
            chatUnitId
            userId
            username
            phoneNumber
            avatar
            status
            allowedUserIds
            conversationIds
            firstName
            lastName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatUnitChatUnitUsersId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        allowedUserIds
        type
        subtype
        allConversationId
        ownerDetails {
          id
          chatUnitId
          userId
          username
          phoneNumber
          avatar
          status
          allowedUserIds
          conversationIds
          chatUnit {
            id
            title
            colorId
            backgroundImage
            description
            url
            guestOpenInviteId
            memberOpenInviteId
            allowedUserIds
            type
            subtype
            allConversationId
            organisationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatUnitChatUnitUsersId
          owner
          __typename
        }
        organisationId
        organisation {
          id
          name
          billingId
          users
          members {
            userId
            role
            status
            name
            phoneNumber
            avatar
            __typename
          }
          allowedUserIds
          plan
          image
          usage {
            guests
            tables
            rooms
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatUnitChatUnitUsersId
      owner
      __typename
    }
    organisationId
    organisation {
      id
      name
      billingId
      users
      members {
        userId
        role
        status
        name
        phoneNumber
        avatar
        __typename
      }
      allowedUserIds
      plan
      image
      usage {
        guests
        tables
        rooms
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicChatUnitByUrlQueryVariables,
  APITypes.GetPublicChatUnitByUrlQuery
>;
