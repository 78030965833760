import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgCopy = (props: SvgProps) => (
  <Svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      clipRule="evenodd"
      d="M4 19a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3h3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-3zm11-2V3H5v14zm2-10v11a1 1 0 0 1-1 1H9v2h10V7z"
      fill="#7C7F80"
      fillRule="evenodd"
    />
  </Svg>
);
export default SvgCopy;
