import Section from '@/components/section/Section';
import type {
  MainStackScreenProps,
  OnboardingCreateProfileParams,
} from '@/navigation/types';
import { BorderRadius } from '@/ui/common/styles';
import React, { useCallback, useState } from 'react';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Colors } from '../theme/Colors';
import AvatarList from './components/AvatarList';
import OnboardingNextButton from './components/OnboardingNextButton';

type Props = MainStackScreenProps<'ChooseAvatar'>;

export default function ChooseAvatarScreen({ route, navigation }: Props) {
  const { selectedAvatar = '' } = route.params;
  const [selected, setSelected] = useState(selectedAvatar);

  const handleSelectedAvatar = useCallback((avatar: string) => {
    setSelected(currSelected => (currSelected === avatar ? '' : avatar));
  }, []);

  return (
    <>
      <AvatarList
        selectedAvatarName={selected}
        onSelectAvatar={handleSelectedAvatar}
      />
      <View style={styles.gradientBg} />
      <LinearGradient
        colors={[`${Colors.neutral100}00`, `${Colors.neutral100}33`]}
        style={styles.gradient}
        angle={180}
        useAngle
      />
      <Section
        p={20}
        pb={60}
        bg={Colors.neutral0}
        jcc
        aic
        style={styles.btnContainer}>
        <OnboardingNextButton
          onPress={() => {
            const currentCreateProfileParams = navigation
              .getState()
              .routes.find(r => r.name === 'OnboardingCreateProfile')
              ?.params as OnboardingCreateProfileParams;
            navigation.navigate({
              name: 'OnboardingCreateProfile',
              params: {
                ...currentCreateProfileParams,
                selectedAvatar: selected,
              },
              merge: true,
            });
          }}
          text={'Choose avatar'}
        />
      </Section>
    </>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 24,
    backgroundColor: Colors.neutral0,
  },
  img: {
    width: 50,
    height: 50,
    borderRadius: 50,
    alignSelf: 'center',
  },
  list: {
    backgroundColor: Colors.neutral0,
  },
  colWrapper: {
    gap: 20,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  gradientBg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: Dimensions.get('window').width,
    height: 115,
    backgroundColor: `${Colors.neutral100}33`,
  },
  gradient: { height: 24, width: '100%', position: 'absolute', bottom: 115 },
  selectedItemContainer: {
    padding: 4,
    backgroundColor: Colors.neutral0,
    borderColor: Colors.primaryDark,
    borderRadius: BorderRadius.full,
    borderWidth: 4,
    marginHorizontal: -8,
    marginVertical: -8,
  },
  press: {
    backgroundColor: Colors.neutral0,
    borderRadius: BorderRadius.full,
    ...Platform.select({
      default: {
        shadowColor: Colors.neutral100,
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
    }),
  },
  btnContainer: {
    borderTopLeftRadius: BorderRadius.lg,
    borderTopRightRadius: BorderRadius.lg,
  },
});
