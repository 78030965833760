import React from 'react';

import { Badge } from '@/components/badge/Badge';
import { getBadgeTextSize } from '@/components/badge/getBadgeTextSize';
import useCMSStore from '@/domain/cms/useCMSStore';
import { Icon } from '@/ui/app/elements';
import { Brand, Neutrals } from '@/ui/common/colors';

type Props = {
  isActive: boolean;
};

export const DiscoverIcon = ({ isActive }: Props) => {
  const unreadPosts = useCMSStore(state => state.getUnreadPostsCount());

  return (
    <Badge
      badgeContent={unreadPosts}
      hidden={!unreadPosts}
      styles={{ fontSize: getBadgeTextSize(unreadPosts) }}>
      <Icon
        fill={isActive ? Brand.primary100 : Neutrals.shade700}
        iconKey="Saturn"
        size={25}
      />
    </Badge>
  );
};
