import { savePushTokenForDevice } from './api/savePushTokenForDevice';
import { getUniqueDeviceId } from './getUniqueDeviceId';

export async function registerDeviceToken(
  token: string,
  userId: string | undefined,
): Promise<any> {
  if (!userId) {
    return Promise.resolve();
  }
  const device = getUniqueDeviceId();
  return savePushTokenForDevice(userId, token, device);
}
