import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { ChatUnitType } from '@/API';
import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import type { ChatUnit } from '@/services/types';
import { sortByCreatedAt } from '@/utilities/sorters/sortByCreatedAt';

type TableState = {
  data: Record<
    ChatUnitType,
    Map<
      string, // tableId
      ChatUnit
    >
  >;
  lastUpdated: null | Date;
  clearState: () => void;
  setLastUpdated: (lastUpdated: Date) => void;
  getTable: (id: string) => ChatUnit | undefined;
  getTables: (type?: ChatUnitType) => ChatUnit[];
  setTables: (tables: ChatUnit[], type?: ChatUnitType) => void;
  getActiveChatUnits: (type?: ChatUnitType) => ChatUnit[];
  upsertTables: (tables: ChatUnit[]) => void;
};

const newState = {
  data: {
    [ChatUnitType.TABLE]: new Map(),
    [ChatUnitType.ROOM]: new Map(),
    [ChatUnitType.QUICK_CHAT]: new Map(),
  },
  lastUpdated: null,
};

type EqualityFn = (a: any, b: any) => boolean;

// by default it will use shallow in the selector if you want to use deep equality pass isEqual in the component level
export const createTableStore = (equalityFn: EqualityFn = shallow) =>
  createWithEqualityFn<TableState>()(
    persist(
      immer((set, get) => ({
        ...newState,
        clearState: () => set(() => newState),
        setLastUpdated: (lastUpdated: Date) =>
          set(state => {
            state.lastUpdated = lastUpdated;
          }),
        getTable: (id: string) =>
          get().data[ChatUnitType.TABLE].get(id) ??
          get().data[ChatUnitType.ROOM].get(id) ??
          get().data[ChatUnitType.QUICK_CHAT].get(id),
        getTables: (type?: ChatUnitType) => {
          const results = (
            type
              ? [...get().data[type].values()]
              : [
                  ...get().data[ChatUnitType.TABLE].values(),
                  ...get().data[ChatUnitType.ROOM].values(),
                  ...get().data[ChatUnitType.QUICK_CHAT].values(),
                ]
          ).sort(sortByCreatedAt);
          if (type === ChatUnitType.QUICK_CHAT) {
            return results.reverse();
          }
          return results;
        },
        getActiveChatUnits: (type?: ChatUnitType) => {
          const myChatUsers = useTableUsersStore.getState().getMyChatUsers();
          const chatUnits = get().getTables(type);

          return chatUnits.filter(chatUnit => myChatUsers.has(chatUnit.id));
        },
        setTables: (tables: ChatUnit[], type) =>
          set(state => {
            const results = type
              ? { ...state.data, [type]: new Map() }
              : {
                  [ChatUnitType.TABLE]: new Map<string, ChatUnit>(),
                  [ChatUnitType.ROOM]: new Map<string, ChatUnit>(),
                  [ChatUnitType.QUICK_CHAT]: new Map<string, ChatUnit>(),
                };

            tables.forEach(table => {
              const t = table.type ?? ChatUnitType.TABLE;
              results[t].set(table.id, table);
            });

            state.data = results;
          }),
        upsertTables: (tables: ChatUnit[]) =>
          set(state => {
            tables.forEach(table => {
              if (table.type) {
                state.data[table.type].set(table.id, table);
              }
            });

            return state;
          }),
      })),
      {
        name: 'table-state-storage',
        storage: createDebouncedStorage(asyncStorageMethods, {
          debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
        }),
        partialize: state => ({
          data: state.data,
          lastUpdated: state.lastUpdated ?? null,
        }),
      },
    ),
    equalityFn,
  );

export const useTableStore = createTableStore();
