import { post } from 'aws-amplify/api';

import { logger } from '@/services/logger/logger';

export const savePushTokenForDevice = async (
  userId: string,
  token: string,
  device: string,
) => {
  try {
    const { body } = await post({
      apiName: 'restApi8Seats',
      options: {
        body: {
          token,
          device,
        },
      },
      path: '/tokens/' + userId,
    }).response;
    return await body.json();
  } catch (err) {
    logger.error('ERROR::savePushTokenForDevice::', err);
    // TODO: NIGEL posthog
    throw err;
  }
};
