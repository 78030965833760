import { getChatUnitUsersForConversation } from '../user/getChatUnitUsersByConversationId';

import { saveMessage } from './saveMessage';

import type { Message } from '@/services/chatUnit/types';
import { logger } from '@/services/logger/logger';
import generateUniqueId from '@/utilities/helpers/uuid';
import { getRandomInt } from '@/utilities/number/getRandomInt';

const createMessage = (
  userId: string,
  conversationId: string,
  chatUnitId: string,
  chatUnitUserId: string,
  allowedUserIds: string[],
) => ({
  allowedUserIds,
  conversationId,
  createdAt: new Date().toISOString(),
  chatUnitId,
  chatUnitUserId,
  messageId: generateUniqueId(),
  SK: '1',
  text: 'Some sentence',
  userId,
});

export const spamMessages = async (triggerMessage: Message) => {
  try {
    const chatUnitUsers = await getChatUnitUsersForConversation(
      triggerMessage.conversationId,
      triggerMessage.chatUnitId,
    );
    const spamCount = getNumberOfMessagesToSpam(triggerMessage.text);
    logger.debug('spamMessages', { spamCount });

    for (let i = 0; i < spamCount; i++) {
      // get random user from conversation.users
      const randomUser =
        // @ts-ignore
        chatUnitUsers[getRandomInt(chatUnitUsers.length)];

      const msg = createMessage(
        randomUser.id,
        triggerMessage.conversationId,
        triggerMessage.chatUnitId,
        randomUser.chatUnitUserId,
        triggerMessage.allowedUserIds || [],
      );
      await saveMessage(msg);
    }
  } catch (e) {
    logger.error('spamMessagesError', e);
  }
};

const getNumberOfMessagesToSpam = (message: string) => {
  const matches = message.match(/^!spam ([\d]+)/);
  const count = matches && matches[1] ? Number(matches[1]) : 10;
  return count;
};
