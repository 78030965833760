import { FlashList, type ListRenderItemInfo } from '@shopify/flash-list';
import isEqual from 'fast-deep-equal';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { DMListHeader } from './DMListHeader';
import { DMListItem } from './DMListItem';

import { ChatUnitType } from '@/API';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import useNavigation from '@/hooks/useNavigation';
import type { ChatUnit } from '@/services/types';

// Optimize keyExtractor
const keyExtractor = (item: ChatUnit) => item.id;

// Optimize getItemType
const getItemType = (item: ChatUnit) => item.type;

// Memoize the entire component to prevent unnecessary re-renders
export const DMList = React.memo(() => {
  const { navigate } = useNavigation();

  // Pass shallow equality function for chatUnits
  const chatUnits = useTableStore(state =>
    state.getActiveChatUnits(ChatUnitType.QUICK_CHAT),
  );

  // Memoize item IDs
  const itemIds = useMemo(() => chatUnits.map(item => item.id), [chatUnits]);

  const latestMessages = useMessageStore(
    state => state.getMostRecentMessagesFromChatUnits(itemIds),
    isEqual,
  );

  const goToCreateDM = useCallback(() => {
    navigate('CreateDM');
  }, [navigate]);

  // Memoize renderDMListItem
  const renderDMListItem = useCallback(
    ({ item }: ListRenderItemInfo<ChatUnit>) => <DMListItem dm={item} />,
    [],
  );

  // Extract and memoize sort function
  const sortFunction = useCallback(
    (a: ChatUnit, b: ChatUnit) => {
      const aMessage = latestMessages[a.id];
      const bMessage = latestMessages[b.id];
      const aDate = aMessage?.createdAt ?? a.createdAt;
      const bDate = bMessage?.createdAt ?? b.createdAt;

      if (!aDate) return 1;
      if (!bDate) return -1;
      // Making each a date first since the createdAt in the chatUnit of type string.
      return new Date(bDate) < new Date(aDate) ? -1 : 1;
    },
    [latestMessages],
  );

  // Memoize sorted DMs
  const sortedDMs = useMemo(
    () => [...chatUnits].sort(sortFunction),
    [chatUnits, sortFunction],
  );

  return (
    <View style={styles.container}>
      <DMListHeader onCreatePress={goToCreateDM} />

      <FlashList
        data={sortedDMs}
        keyExtractor={keyExtractor}
        renderItem={renderDMListItem}
        estimatedItemSize={60}
        drawDistance={300}
        showsVerticalScrollIndicator={false}
        refreshing={false}
        refreshControl={undefined}
        // Add getItemType for better performance with heterogeneous lists
        getItemType={getItemType}
        onEndReachedThreshold={0.5}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    // backgroundColor: Greys.shade0,
  },
});
