import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback } from 'react';
import { ActivityIndicator, Pressable, Switch, Text, View } from 'react-native';
import DatePicker from 'react-native-date-picker';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import DangerZone from './components/DangerZone';
import { styles } from './styles';
import { useConnect } from './useConnect';

import BackIcon from '@/assets/svg/back-arrow.svg';
import LockIcon from '@/assets/svg/lock-icon.svg';
import AvatarPicker from '@/components/avatarPicker';
import FlaotingTextInput from '@/components/floating-text-input';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import { constants } from '@/constants';
import { AvatarHeader } from '@/domain/user/components/configureProfile/avatarHeader';
import { ChooseAvatarBlock } from '@/domain/user/components/configureProfile/chooseAvatarBlock';
import { useAvatar } from '@/domain/user/hooks/useAvatar';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { DATE_LOCALE } from '@/utilities/constants';
import { formatDate } from '@/utilities/helpers/formatDate';
import { web } from '@/utilities/platform';

const notificationsDisabled = true;
const displayWhenOnlineDisabled = true;

const UserProfile = () => {
  const { goBack } = useNavigation();
  const {
    canSave,
    changesAdded,
    countryCode,
    datePickerOpen,
    onPressCancel,
    onSetAvatar,
    onSubmit,
    phone,
    setDatePickerOpen,
    state,
    submitInProgress,
    updateState,
    user,
  } = useConnect();
  const {
    avatarImageKey,
    chooseImageFromLibrary,
    clearAvatar,
    isUploading,
    onPickAvatar,
    setShowPicker,
    showPicker,
  } = useAvatar({ initialAvatar: state.avatar, onSetAvatar });

  const { translate } = useTranslations();
  const { top } = useSafeAreaInsets();

  const opacity = useSharedValue(0);

  const navigation = useNavigation();

  const stylez = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  const handleCancel = useCallback(() => {
    onPressCancel();
    navigation.goBack();
  }, [navigation, onPressCancel]);

  const handleSubmit = useCallback(() => {
    onSubmit();
    navigation.goBack();
  }, [navigation, onSubmit]);

  return (
    <>
      <Animated.View style={[styles.smallHeader, { height: top }, stylez]} />
      <KeyboardAwareScrollView
        bounces={false}
        contentContainerStyle={styles.scrollContainer}
        onScrollY={yOffset => {
          opacity.value = yOffset / 50;
        }}
        showsVerticalScrollIndicator={false}
        style={styles.container}>
        <View style={styles.container}>
          <View style={[styles.header, { paddingTop: top }]}>
            <View style={styles.headerInside}>
              {changesAdded ? (
                <Pressable onPress={handleCancel}>
                  <Text style={styles.cancelText}>
                    {translate('cancel_common_button_title')}
                  </Text>
                </Pressable>
              ) : (
                <Pressable onPress={goBack} style={styles.backContainer}>
                  <BackIcon />
                  <Text style={styles.backText}>
                    {translate('common_back')}
                  </Text>
                </Pressable>
              )}

              {canSave && (
                <Pressable onPress={handleSubmit}>
                  {submitInProgress ? (
                    <ActivityIndicator />
                  ) : (
                    <Text style={styles.saveText}>
                      {translate('common_save')}
                    </Text>
                  )}
                </Pressable>
              )}
            </View>

            <AvatarHeader
              avatarImageKey={avatarImageKey || ''}
              chooseImageFromLibrary={chooseImageFromLibrary}
              heading={translate('your_profile_label')}
              isUploading={isUploading}
              removeAvatar={clearAvatar}
              translate={translate}
            />
          </View>
          <ChooseAvatarBlock
            heading={translate('update_profile_avatar_title')}
            showAvatarPicker={() => setShowPicker(true)}
            subHeading={translate('update_profile_avatar_subtitle')}
            translate={translate}
          />
          <View style={styles.spacer} />
          <View style={styles.content}>
            <FlaotingTextInput
              label={translate('short_nickname_label')}
              onChangeText={text => updateState('preffered_username', text)}
              style={styles.fullNameInput}
              value={state.preffered_username}
              maxLength={constants.displayUserNameLimit}
            />
          </View>
          <View style={styles.spacer} />
          <View style={styles.content}>
            <FlaotingTextInput
              label={translate('full_name_label')}
              onChangeText={text => updateState('name', text)}
              style={styles.fullNameInput}
              value={state.name}
            />
          </View>
          <View style={styles.spacer10} />
          <View style={styles.contentPhoneNumber}>
            <Text style={styles.phoneNumberLabel}>
              {translate('registered_phone_label')}
            </Text>
            <View style={styles.row}>
              <LockIcon style={styles.lockIcon} />
              <Text style={styles.countryCode}>{countryCode}</Text>
              <Text style={styles.phoneNumber}>{phone}</Text>
            </View>
            <View style={styles.spacer10} />
          </View>
          <View style={styles.spacer} />
          <View style={styles.content}>
            <FlaotingTextInput
              label={translate('pronouns_label')}
              onChangeText={text => updateState('pronouns', text)}
              style={styles.fullNameInput}
              value={state.pronouns}
            />
          </View>
          {/* Disabling date picker on web since it is causing problems. */}
          {!web && (
            <>
              <View style={styles.spacer} />
              <Pressable
                onPress={() => setDatePickerOpen(true)}
                style={styles.birthdayButton}>
                <FlaotingTextInput
                  editable={false}
                  label={translate('birthdate_label')}
                  onPress={() => setDatePickerOpen(true)}
                  style={styles.fullNameInput}
                  value={state?.birthdate ? formatDate(state.birthdate) : ''}
                />
              </Pressable>
            </>
          )}

          <View style={styles.coloredSpacer} />
          <View style={styles.contentRow}>
            <Text style={styles.dateJoinedText}>
              {translate('date_joined_label')}
            </Text>
            <Text style={styles.joinDate}>
              {moment(user?.createdAt).format('DD MMM YYYY')}
            </Text>
          </View>
          <View style={styles.coloredSpacer} />
          <DangerZone />
          <View style={styles.coloredSpacer} />
          {!displayWhenOnlineDisabled && (
            <View style={styles.settingsContainer}>
              <Text style={styles.settingsHeader}>
                {translate('settings_and_privacy')}
              </Text>
              <Text style={styles.settingsSubheader}>
                {translate('user_profile_settings_text')}
              </Text>
            </View>
          )}
          {!displayWhenOnlineDisabled && (
            <View style={styles.switchRow}>
              <Text style={styles.switchTitle}>
                {translate('display_when_online_label')}
              </Text>
              <Switch
                onChange={() =>
                  updateState(
                    'display_when_online',
                    state.display_when_online === '1' ? '0' : '1',
                  )
                }
                thumbColor={Greys.shade0}
                trackColor={{ false: Greys.shade500, true: Greys.shade500 }}
                value={Boolean(Number(state.display_when_online))}
              />
            </View>
          )}
          {!notificationsDisabled && (
            <View style={styles.switchRow}>
              <Text style={styles.switchTitle}>
                {translate('display_when_inapp_label')}
              </Text>
              <Switch
                onChange={() =>
                  updateState(
                    'display_when_in_app',
                    state.display_when_in_app === '1' ? '0' : '1',
                  )
                }
                thumbColor={Greys.shade0}
                trackColor={{ false: Greys.shade500, true: Greys.shade500 }}
                value={Boolean(Number(state.display_when_in_app))}
              />
            </View>
          )}
          {/* Disabling date picker on web since it is causing problems. */}
          {!web && (
            <DatePicker
              date={new Date()}
              modal
              mode="date"
              onCancel={() => {
                setDatePickerOpen(false);
              }}
              onConfirm={date => {
                setDatePickerOpen(false);
                updateState('birthdate', date.toLocaleDateString(DATE_LOCALE));
              }}
              open={datePickerOpen}
            />
          )}
          <AvatarPicker
            avatar={avatarImageKey || ''}
            onPressAvatar={onPickAvatar}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
          />
        </View>
      </KeyboardAwareScrollView>
    </>
  );
};

export default UserProfile;
