import React, { StatusBar, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useNoConnection } from '../hooks/useNoConnection';

import NoConnectionImage from '@/assets/svg/no-connection.svg';
import MainButton from '@/components/MainButton';
import { wp } from '@/theme';
import useTranslations from '@/translations/useTranslation';
import { Brand, Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { android } from '@/utilities/platform';

export const NoConnection = () => {
  const { checkInternetConnection, isChecking } = useNoConnection();
  const { translate } = useTranslations();

  StatusBar.setBarStyle('dark-content');
  if (android) {
    StatusBar.setBackgroundColor(Greys.shade0);
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <NoConnectionImage />
        </View>
        <Text style={styles.heading}>
          {translate('no_internet_connection_heading')}
        </Text>
        <Text style={styles.content}>
          {translate('no_internet_connection_content')}
        </Text>

        <View style={styles.comingSoonContainer}>
          <Text style={styles.comingSoon}>
            {translate('offline_mode_coming_soon')}
          </Text>
        </View>

        <View style={styles.spacer} />

        <MainButton
          title={translate('no_internet_connection_button_label')}
          onPress={checkInternetConnection}
          disabled={isChecking}
          loading={isChecking}
          style={styles.button}
          textStyle={styles.buttonText}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: Brand.primary75,
    width: '100%',
  },
  buttonText: {
    color: Greys.shade0,
    fontSize: 17,
    fontWeight: 'bold',
    padding: 6,
    textAlign: 'center',
  },
  comingSoon: {
    color: Greys.shade0,
    fontSize: 14,
    textAlign: 'center',
  },
  comingSoonContainer: {
    alignSelf: 'center',
    backgroundColor: Greys.shade800,
    borderRadius: BorderRadius.full,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  container: {
    height: '100%',
    backgroundColor: Greys.shade0,
    paddingBottom: 20,
    paddingHorizontal: 30,
    width: wp(100),
  },
  content: {
    fontSize: 17,
    lineHeight: 23,
    marginBottom: 20,
    textAlign: 'center',
  },
  heading: {
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: 29,
    marginBottom: 20,
    textAlign: 'center',
  },
  iconContainer: {
    alignItems: 'center',
    marginTop: 40,
  },
  screenContainer: {
    backgroundColor: Greys.shade0,
  },
  spacer: {
    flexGrow: 1,
  },
  spinnerContainer: {
    width: '100%',
    alignItems: 'center',
  },
});
