import { PostHogProvider } from 'posthog-react-native';
import React, { type ReactElement, type FC } from 'react';
import Config from 'react-native-config';

import {
  Providers,
  postHogGetFeatureFlag,
  postHogTrack,
  useExperience,
} from './Providers';

interface Props {
  children: ReactElement;
}

export const ExperienceProvider: FC<Props> = ({ children }) => {
  return (
    <PostHogProvider
      apiKey={Config.POSTHOG_API_KEY}
      autocapture={
        Config.ENV === 'dev'
          ? {
              captureLifecycleEvents: true,
              captureTouches: true,
              captureScreens: true,
            }
          : {
              captureLifecycleEvents: true,
              captureScreens: true,
            }
      }
      debug={Config.POSTHOG_DEBUG}>
      <Providers>{children}</Providers>
    </PostHogProvider>
  );
};

export { useExperience, postHogTrack, postHogGetFeatureFlag };
export default ExperienceProvider;
