import { Colors } from '@/domain/theme/Colors';
import { BorderRadius } from '@/ui/common/styles';
import React from 'react';
import { StyleSheet, View } from 'react-native';

type Props = {
  size?: number;
};

export default function DiscoverUnreadIndicator({ size = 12 }: Props) {
  return (
    <View style={[styles.unreadIndicator, { width: size, height: size }]} />
  );
}

const styles = StyleSheet.create({
  unreadIndicator: {
    borderWidth: 2,
    borderRadius: BorderRadius.full,
    backgroundColor: Colors.rasberry,
    borderColor: Colors.neutral0,
  },
});
