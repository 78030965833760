import type { GraphQLQuery } from '@aws-amplify/api';
import type { GetInvitesForTargetQuery } from 'src/API';

import client from '@/services/API/client';
import { getInvitesForTarget } from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

export const getInvitesForChatUnit = async (id: string) => {
  try {
    const response = await client.graphql<
      GraphQLQuery<GetInvitesForTargetQuery>
    >({
      query: getInvitesForTarget,
      variables: { id },
    });

    if (response && response.data && response.data.getInvitesForTarget) {
      return response.data.getInvitesForTarget.filter(
        invite => invite?._deleted !== true,
      );
    }

    throw response.errors;
  } catch (err) {
    logger.error('getInvitesForChatUnitError', err);
    throw err;
  }
};
