import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgContact = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.906 15.623a6.12 6.12 0 013.54-4.944A3.81 3.81 0 015.17 7.827a3.827 3.827 0 116.38 2.852 6.12 6.12 0 013.54 4.948c-.305.28-.629.538-.97.775v-.156a5.124 5.124 0 00-2.988-4.659l-1.385-.636 1.136-1.017a2.827 2.827 0 10-3.77 0l1.135 1.017-1.386.636a5.124 5.124 0 00-2.988 4.66v.152a9.047 9.047 0 01-.969-.776z"
      fill="#07818C"
    />
    <Circle cx={9} cy={9} r={8.25} stroke="#07818C" strokeWidth={1.5} />
  </Svg>
);
export default SvgContact;
