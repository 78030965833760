import React, { useMemo } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';

import { Badge } from '@/components/badge/Badge';

export const ActivityItemBadge = React.memo(
  ({
    containerStyles,
    unreadMessageCount,
  }: {
    containerStyles?: ViewStyle;
    unreadMessageCount: number;
  }) => {
    const badgeContainerStyles = useMemo(
      () => StyleSheet.flatten([styles.container, containerStyles]),
      [containerStyles],
    );
    return (
      <View style={badgeContainerStyles}>
        <Badge
          badgeContent={unreadMessageCount}
          hidden={!unreadMessageCount}
          styles={badgeStyles}
        />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
  },
});

const badgeStyles = {
  borderBottomRightRadius: 12,
  borderTopRightRadius: 0,
  borderWidth: 0,
  height: 20,
  position: 'relative' as const,
  right: -9,
  top: -9,
  width: 'auto' as const,
};
