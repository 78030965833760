// @ts-nocheck
import React, { type PropsWithChildren } from 'react';
import {
  type StyleProp,
  StyleSheet,
  Text,
  type TextProps,
  type TextStyle,
} from 'react-native';

import { useTheme } from '@/domain/theme';
import type { Colors } from '@/domain/theme/Colors';
import { Fonts } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';

export interface AppTextProps extends TextProps {
  /** Color of the text */
  color?: Color | Colors;
  /**
   * Specifies whether font should be scaled down
   * automatically to fit given style constraints.
   * This results in font size being reduced
   * until it fits the constraints of the container.
   */
  shrinkFit?: TextProps['adjustsFontSizeToFit'];
  /** Font size in density pixels (dp) */
  size?: number;
  style?: StyleProp<TextStyle>;
  /** Specifies text alignment in the container */
  textAlign?: TextStyle['textAlign'];
  /** Font type */
  type?: keyof typeof Fonts;
  /** Specifies whether text should be underlined */
  underline?: boolean;
}

type Props = PropsWithChildren<AppTextProps>;

const AppText = ({
  children,
  color: inputColor,
  shrinkFit = false,
  size: fontSize = 16,
  style,
  textAlign = undefined,
  // Convinience shortcut styles
  type = 'primary400',
  underline = false,
  ...otherProps
}: Props) => {
  const fontFamily = getFontFamilyByWeight(type);

  const {
    theme: {
      ui: {
        text: { defaultColor },
      },
    },
  } = useTheme();

  const color = inputColor ?? defaultColor;

  const textStyle = StyleSheet.create({
    text: {
      color,
      fontFamily,
      fontSize,
      ...(underline && {
        textDecorationColor: color,
        textDecorationLine: 'underline',
      }),
      ...(textAlign && {
        textAlign,
      }),
    },
  });

  return (
    <Text
      adjustsFontSizeToFit={shrinkFit}
      allowFontScaling={false}
      maxFontSizeMultiplier={1}
      style={[textStyle.text, style]}
      {...otherProps}>
      {children}
    </Text>
  );
};

/**
 * Get font family from theme based on weight
 * @param weight desired font emphasis
 * @returns font family from theme
 */
const getFontFamilyByWeight = (weight: keyof typeof Fonts) => {
  const font = Fonts[weight];
  return font;
};

export default AppText;
