import { Image } from 'expo-image';
import React, { useCallback, useMemo } from 'react';
import {
  FlatList,
  type ListRenderItem,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Share from 'react-native-share';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType } from '../../../../API';

import shareImg from '@/assets/invites/share.png';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import { constants } from '@/constants';
import { WorkspaceIndicator } from '@/domain/chatUnit/components/chatUnitCard/WorkspaceIndicator';
import type { Invite } from '@/domain/invites/types/types';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import useUserStore from '@/domain/user/state/useUserStore';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app/elements';
import { Brand, Greys, Misc, Neutrals, UserThemes } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { InviteTypeConversion } from '@/utilities/helpers/inviteTypesConverter';
import { web } from '@/utilities/platform';

const SingleSentInvitation = ({ data }: { data: Invite }) => {
  const { translate } = useTranslations();
  const insets = useSafeAreaInsets();
  const organisations = useOrganisations();

  const orgName = useMemo(() => {
    const org = organisations.find(org => org.id === data?.targetInfo?.orgId);
    return org?.name;
  }, [organisations, data]);
  const numberOfInvitees = data?.inviteeInfo.length || 0;
  const inviteLink = `${constants.inviteUrl}/?id=${data.inviteId}`;
  const [name, username] = useUserStore(
    useShallow(state => [state.user?.name, state.user?.username]),
  );

  const handleShare = useCallback(() => {
    if (!data) {
      return;
    }

    const nameToDisplay = name || username;

    // Create the message to share
    // The message is a combination of the invite message and the invite link
    // The message is also translated
    // If there is only one invitee, the message is personalised
    // The message is then shared using the Share API
    let message = translate('invite_message', {
      chatUnitType: translate(data.targetType!),
      chatUnitTitle: data.targetInfo.title,
    })
      .concat(' ')
      .concat(inviteLink)
      .concat('\n\n')
      .concat(
        nameToDisplay ? `Sent by ${nameToDisplay} via the 8seats app.\n\n` : '',
      )
      .concat('What is 8seats? Learn more here: https://8seats.com');

    if (data.inviteeInfo.length === 1) {
      message = message.replace('Hey', `Hi ${data.inviteeInfo[0].name}`);
    }

    const inviteePhone = data.inviteeInfo?.[0]?.phone;
    try {
      Share.open({
        title: translate('invite_title'),
        message,
        failOnCancel: false,
        subject: translate('invite_title'),
        recipient: data.inviteeInfo.length === 1 ? inviteePhone : undefined,
      });
    } finally {
      // No-op
    }
  }, [data, inviteLink, name, translate, username]);

  return (
    <View style={[styles.container, { marginTop: 40 + insets.top }]}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <View style={styles.backgroundImg}>
          <ChatUnitBackgroundImage
            imageKey={data.targetInfo.backgroundImage}
            width="100%"
            height="100%"
          />
        </View>
        <View style={styles.tableIcon}>
          <ChatUnitIcon
            fill={Greys.shade0}
            size={70}
            type={
              data.targetType
                ? InviteTypeConversion[data.targetType]
                : ChatUnitType.TABLE ?? ChatUnitType.TABLE
            }
          />
        </View>
        <View style={styles.pill}>
          <WorkspaceIndicator organisationName={orgName} size={12} />

          <AppText size={12}>{translate(data.targetType!)}</AppText>
        </View>
        <AppText size={20} type="primary700" textAlign="center">
          {data.targetInfo.title}
        </AppText>
        <Spacer height={20} />
        <Spacer height={1} width="100%" bg={Neutrals.shade100} />
        <Spacer height={20} />
        <AppText
          size={12}
          color={Neutrals.shade600}
          type="primary700"
          textAlign="center">
          {translate('added_to_this').toUpperCase()}{' '}
          {translate(data.targetType!).toUpperCase()}
          {numberOfInvitees > 1 ? ` (${numberOfInvitees})` : ''}
        </AppText>
        <View style={styles.inviteListRow}>
          <FlatList
            contentContainerStyle={styles.inviteeList}
            data={data.inviteeInfo}
            ItemSeparatorComponent={Seperator}
            renderItem={renderItem}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
          />
          <LinearGradient
            colors={[`${Greys.shade0}00`, Greys.shade0]}
            start={{ x: 0.0, y: 0.0 }} // Start at right
            end={{ x: 1.0, y: 0.0 }} // End at left
            angle={270}
            style={styles.listEndGradientStyles}
          />
        </View>
        <Spacer height={1} width="100%" bg={Neutrals.shade100} />
        <Spacer height={20} />
        <AppText
          color={Neutrals.shade700}
          size={17}
          type="primary700"
          textAlign="center">
          {translate('send_a_reminder')}
        </AppText>
        <Spacer height={10} />
        <AppText size={14} textAlign="center">
          {translate('reminder_subtitle')}
        </AppText>
        <Pressable onPress={handleShare} style={styles.link}>
          {/**
           * Create the message to share. The message is a combination of the
           * invite message and the invite link. The message is also translated.
           * If there is only one invitee, the message is personalised
           */}
          <AppText type="primary500" size={14} numberOfLines={3}>
            {translate('invite_message', {
              chatUnitType: translate(data.targetType!),
              chatUnitTitle: data.targetInfo.title,
            }).replace(
              'Hey', // Replace the default "Hey" with "Hi" for a single invitee
              data.inviteeInfo.length === 1
                ? `Hi ${data.inviteeInfo[0].name}`
                : 'Hey',
            )}{' '}
            <AppText type="primary700" size={14} color={Brand.primary75}>
              {inviteLink}
            </AppText>
          </AppText>
        </Pressable>
        <AppText textAlign="center" color={Neutrals.shade700} size={12}>
          {translate('reminder_warn')}
        </AppText>
        <Spacer height={32} />
        <Pressable style={styles.btn} onPress={handleShare}>
          <Image source={shareImg} style={styles.share} />
          <AppText color={Greys.shade0} size={17} type="primary700">
            {translate('share_invite_link')}
          </AppText>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const Seperator = () => <Spacer width={16} />;

const renderItem: ListRenderItem<Record<string, any>> = ({ item }) => {
  return (
    <View style={styles.avatarRow}>
      <View style={styles.avatar}>
        <AppText color={Greys.shade0} size={10}>
          {getInviteeInitials(item.name)}
        </AppText>
      </View>
      <AppText color={Neutrals.shade700} size={14}>
        {item.name}
      </AppText>
    </View>
  );
};

/**
 * Get the initials of the invitee
 * @param name Name of the invitee
 * @returns The initials of the invitee in uppercase
 * @example
 * getInviteeInitials('John Doe') // JD
 * getInviteeInitials('John') // J
 */
function getInviteeInitials(name: string) {
  const [firstName, lastName] = name.split(' ');
  const firstInitial = firstName[0];
  const lastInitial = lastName && lastName[0];
  return `${firstInitial}${lastInitial ? lastInitial : ''}`.toUpperCase();
}

export default SingleSentInvitation;

const AVATAR_SIZE = 24;
const styles = StyleSheet.create({
  backgroundImg: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 160,
    width: '100%',
  },
  container: {
    backgroundColor: Greys.shade0,
    borderRadius: 20,
    elevation: 10,
    shadowColor: Greys.shade999,
    shadowOffset: {
      height: 10,
      width: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 10,
  },
  tableIcon: {
    alignSelf: 'center',
    position: 'absolute',
    marginTop: 40,
    width: '100%',
    alignItems: 'center',
    ...(web && { width: '100%', alignItems: 'center' }),
  },
  pill: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: BorderRadius.lg,
    backgroundColor: Greys.shade0,
    alignSelf: 'center',
    zIndex: 2,
    transform: [{ translateY: -16 }],

    ...Platform.select({
      ios: {
        shadowColor: Greys.shade999,
        shadowOffset: {
          height: 1,
          width: 0,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
      web: { boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' },
    }),
  },
  listEndGradientStyles: {
    width: 40,
    height: 24,
    backgroundColor: Misc.transparent,
    zIndex: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    alignSelf: 'center',
  },
  inviteListRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 20,
  },
  avatar: {
    alignItems: 'center',
    backgroundColor: UserThemes.choice3,
    borderRadius: 20,
    color: Greys.shade0,
    height: AVATAR_SIZE,
    justifyContent: 'center',
    width: AVATAR_SIZE,
  },
  avatarRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
  inviteeList: {
    paddingHorizontal: 20,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    borderColor: Neutrals.shade100,
    borderRadius: BorderRadius.md,
    borderWidth: 1,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 16,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginHorizontal: 20,
    alignSelf: 'center',
  },
  share: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  btn: {
    borderRadius: 20,
    paddingVertical: 10,
    gap: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Brand.primary75,
    marginHorizontal: 40,
    marginTop: 28,
    marginBottom: 20,
  },
});
