import { DataStore } from 'aws-amplify/datastore';

import { User } from '@/models';
import { factory } from '@/services/factory/factory';

export const getDbUser = async (userId: string) => {
  try {
    const user = await DataStore.query(User, userId);
    return factory.user(user);
  } catch {
    return undefined;
  }
};
