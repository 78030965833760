import { DataStore } from 'aws-amplify/datastore';

import { Conversation } from '../../../models';

import { factory } from '@/services/factory/factory';
import type { Conversation as IConversation } from '@/services/types';

export const updateConversation = async (conversation: IConversation) => {
  const results = await DataStore.query(Conversation, c =>
    c.and(convo => [
      convo.conversationId.eq(conversation.conversationId),
      convo.chatUnitId.eq(conversation.chatUnitId),
    ]),
  );
  const existingConversation = results[0];

  if (!existingConversation) {
    throw new Error(
      `Conversation with conversationId ${conversation.conversationId} not found`,
    );
  }

  const result = await DataStore.save(
    Conversation.copyOf(existingConversation, updated => {
      updated.allowedUserIds =
        conversation.allowedUserIds ?? existingConversation.allowedUserIds;
      updated.chatUnitUserIds =
        conversation.chatUnitUserIds ?? existingConversation.chatUnitUserIds;
      updated.updatedAt =
        conversation.updatedAt ?? existingConversation.updatedAt;
    }),
  );

  return factory.conversation(result);
};
