import { addNewUsersToChatUnit } from '@/domain/chatUnit/functions/addNewUsersToChatUnit';
import { extractContactsFromInvites } from '@/domain/chatUnit/functions/extractContactsFromInvites';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { createInvite } from '@/domain/invites/helpers/createInvite';
import { getName } from '@/domain/user/functions/getName';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { parseInviteJson } from '@/services/invite/parseInviteJson';
export async function inviteContactsToRoomOrTable(
  chatUnitId: string,
  selectedContacts: ContactInput[],
  chatUnit: any,
  userId?: string,
  orgId?: string,
) {
  const newInvite = (
    await createInvite({
      InviterInfo: {},
      inviteTarget: chatUnitId,
      inviteeInfo: selectedContacts.map(item => ({
        name: getName(item, 'first'),
        phone: item.phoneNumbers[0].number,
        avatar: item.avatar,
      })),
      targetInfo: {
        backgroundImage: chatUnit?.backgroundImage,
        title: chatUnit?.title,
        orgId: orgId,
      },
      targetType: chatUnit.type,
    })
  )?.data?.createNewInvite;

  if (!newInvite) return null;

  const newInvites = extractContactsFromInvites(newInvite.newInvites);
  const inviteId = newInvite.inviteId;
  const newSenderInvite = parseInviteJson(newInvite?.newSenderInvite);
  if (newInvites?.length && userId) {
    await addNewUsersToChatUnit(
      chatUnitId,
      newInvites,
      ChatUnitUserStatus.PENDING,
      userId,
    );
  }

  return { inviteId, newInvites, newSenderInvite };
}
