import { ChatUnitType } from '@/API';
import UpIcon from '@/assets/chatview/up.svg';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import Avatar from '@/components/avatar';
import NetworkImage from '@/components/image/NetworkImage';
import FreePill from '@/components/organisation/FreePill';
import Section from '@/components/section/Section';
import { constants } from '@/constants';
import { ChatUnitColorId } from '@/models';
import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { getRandomTableColor } from '@/utilities/constants/table-colors';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { web } from '@/utilities/platform';
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FlatList, Pressable, StyleSheet, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import * as yup from 'yup';
import OnboardingNextButton from '../onboarding/components/OnboardingNextButton';
import useOrganisations from '../organisation/hooks/useOrganisations';
import { Colors } from '../theme/Colors';
import useUserStore from '../user/state/useUserStore';
import { createChatUnit } from './functions/createChatUnit';

type Props = MainStackScreenProps<'CreateRoom'>;

const validationSchema = yup.object({
  title: yup
    .string()
    .min(constants.minChatUnitTitleLength)
    .max(constants.maxChatUnitTitleLength)
    .required(),
  organisation: yup.object({
    id: yup.string().required(),
    image: yup.string(),
    name: yup.string().required(),
  }),
});

export default function CreateChatUnitScreen({ route, navigation }: Props) {
  const { translate } = useTranslations();
  const user = useUserStore(state => state.user);
  const chatUnitType = route.params?.type || ChatUnitType.TABLE;

  const organisations = useOrganisations();
  const orgList = useMemo(() => {
    if (organisations.length) {
      return organisations
        .filter(org => isActiveMemberOfOrg([org], org.id, user?.id))
        .map(org => ({
          id: org?.id!,
          image: org?.image!,
          name: org?.name!,
        }))
        .concat({
          id: 'personal',
          image: user?.avatar!,
          name: translate('personal_workspace'),
        });
    }
    return [
      {
        id: 'personal',
        image: user?.avatar!,
        name: translate('personal_workspace'),
      },
    ];
  }, [organisations, translate, user?.avatar, user?.id]);

  const [showDropdown, setShowDropdown] = useState(false);

  const {
    control,
    formState: { isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      organisation: orgList[0]!,
    },
  });

  const onSubmit = useCallback(
    async (data: { title: string; organisation: { id: string } }) => {
      const orgId =
        data.organisation.id === 'personal' ? undefined : data.organisation.id;
      const { chatUnit } = await createChatUnit({
        ...data,
        allowedUserIds: [user?.id!],
        type: chatUnitType,
        user: user!,
        organisationId: orgId,
        backgroundImage: 'retro',
      });
      if (chatUnit) {
        navigation.navigate('CreateTableParameters', {
          table: chatUnit,
        });
      }
    },
    [chatUnitType, navigation, user],
  );

  // Set the title of the screen based on the chat unit type
  useEffect(() => {
    navigation.setOptions({
      title: translate('create_table_button_title', {
        type: capitalize(chatUnitType),
      }),
    });
  }, [chatUnitType, navigation, translate]);

  const itemContainerStyles = useMemo(
    () => ({
      borderBottomColor: showDropdown ? Colors.neutral10 : Colors.neutral30,
      borderBottomLeftRadius: showDropdown ? 0 : 3,
      borderBottomRightRadius: showDropdown ? 0 : 3,
    }),
    [showDropdown],
  );

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <Spacer height={10} />
        <Section
          row
          aic
          ph={15}
          pv={10}
          gap={15}
          br={9}
          bg={Colors.primaryLight}
          mh={-10}>
          <ChatUnitIcon
            size={36}
            type={route.params.type}
            fill={Colors.neutral0}
          />
          <AppText
            size={14}
            color={Colors.neutral0}
            style={styles.overflowText}>
            {chatUnitType === ChatUnitType.TABLE
              ? translate('table_desc')
              : translate('room_desc')}
          </AppText>
        </Section>
        <Spacer height={40} />
        <AppText type="primary700" size={14}>
          {translate('create_table_input_title', {
            type: capitalize(chatUnitType),
          })}
        </AppText>
        <Spacer height={15} />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="title"
          render={({ field: { onChange, onBlur, value } }) => (
            <Section>
              <TextInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                style={styles.nameInput}
                textAlign="center"
                verticalAlign="middle"
                maxLength={constants.maxChatUnitTitleLength}
              />
              <AppText
                size={11}
                type="primary600"
                color={
                  (value || '').length > constants.maxChatUnitTitleLength
                    ? Colors.rasberry
                    : Colors.peppermint
                }
                style={styles.countText}>
                {(value || '').length}/{constants.maxChatUnitTitleLength}
              </AppText>
            </Section>
          )}
        />
        <Spacer height={40} />
        <AppText type="primary700" size={14}>
          {translate('org_input_title', {
            type: capitalize(chatUnitType),
          })}
        </AppText>
        <Spacer height={15} />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="organisation"
          render={({ field: { onChange, value } }) => (
            <>
              <Section
                onPress={() => setShowDropdown(toggle => !toggle)}
                br={3}
                bw={1}
                bc={Colors.neutral30}
                row
                aic
                bg={Colors.neutral0}
                style={itemContainerStyles}>
                <Spacer width={5} />
                {value.id === 'personal' ? (
                  <Avatar
                    size={40}
                    displayName={user?.name || ''}
                    imageKey={value.image}
                    style={{ borderRadius: BorderRadius.xs }}
                  />
                ) : (
                  <Avatar
                    size={40}
                    displayName={value.name || ''}
                    imageKey={
                      value.image ?? getRandomTableColor(ChatUnitColorId.BLACK)
                    }
                    style={{ borderRadius: BorderRadius.xs }}
                  />
                )}
                <Spacer width={15} />
                <AppText
                  size={14}
                  type="primary600"
                  style={styles.overflowText}>
                  {value.name}
                </AppText>
                <FreePill
                  plan={value.id === 'personal' ? 'Free' : 'Free team'}
                />
                <Spacer width={5} />
                {!showDropdown ? (
                  <>
                    <Spacer height="100%" bg={Colors.neutral30} width={1} />
                    <Section
                      jcc
                      aic
                      gap={10}
                      ph={20}
                      bg={Colors.neutral05}
                      style={styles.arrowsContainer}>
                      <View>
                        <UpIcon />
                      </View>
                      <View style={styles.rotateUpToDown}>
                        <UpIcon />
                      </View>
                    </Section>
                  </>
                ) : (
                  <Spacer height={50} width={0} />
                )}
              </Section>
              {showDropdown ? (
                <FlatList
                  data={orgList.filter(org => org.id !== value.id)}
                  ItemSeparatorComponent={DropdownItemSeperator}
                  nestedScrollEnabled={true}
                  contentContainerStyle={styles.contentContainer}
                  style={styles.list}
                  renderItem={({ item }) => {
                    return (
                      <Pressable
                        onPress={() => {
                          onChange(item);
                          setShowDropdown(false);
                        }}>
                        <Section pv={5} row aic bg={Colors.neutral05}>
                          <Spacer width={5} />
                          {item.id === 'personal' ? (
                            <Avatar
                              size={40}
                              displayName={user?.name || ''}
                              imageKey={item.image}
                              style={{ borderRadius: BorderRadius.xs }}
                            />
                          ) : (
                            <Avatar
                              size={40}
                              displayName={item.name || ''}
                              imageKey={
                                item.image ??
                                getRandomTableColor(ChatUnitColorId.BLACK)
                              }
                              style={{ borderRadius: BorderRadius.xs }}
                            />
                          )}
                          <Spacer width={15} />
                          <AppText size={14} style={styles.overflowText}>
                            {item.name}
                          </AppText>
                          <FreePill
                            plan={item.id === 'personal' ? 'Free' : 'Free team'}
                          />
                          <Spacer width={5} />
                        </Section>
                      </Pressable>
                    );
                  }}
                />
              ) : null}
            </>
          )}
        />
        <View style={styles.fg} />
        <OnboardingNextButton
          disabled={!isValid}
          loading={isSubmitting}
          onPress={control.handleSubmit(onSubmit)}
          text={translate('create_table_button_title', {
            type: capitalize(chatUnitType),
          })}
        />
        <Spacer height={60} />
      </KeyboardAwareScrollView>
    </>
  );
}

const DropdownItemSeperator = memo(() => {
  return <Spacer width="100%" height={1} bg={Colors.neutral10} />;
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 20,
    flexGrow: 1,
  },
  overflowText: {
    width: 0,
    flexGrow: 1,
  },
  nameInput: {
    fontSize: 17,
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    height: 50,
    borderWidth: 1,
    borderRadius: BorderRadius.sm,
    borderColor: Colors.neutral30,
    paddingLeft: web ? 10 : undefined,
    ...(web ? { outlineStyle: 'none' } : {}),
    flexGrow: 1,
  },
  countText: {
    position: 'absolute',
    right: 6,
    bottom: 6,
    letterSpacing: 1.1,
  },
  rotateUpToDown: {
    transform: [{ rotate: '180deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
  rotateLeftToDown: {
    transform: [{ rotate: '-90deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowsContainer: {
    height: 50,
  },
  contentContainer: {
    backgroundColor: Colors.neutral05,
    borderColor: Colors.neutral30,
    borderWidth: 1,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderTopWidth: 0,
    zIndex: 4,
    transform: [{ translateY: -1 }],
  },
  fg: {
    flexGrow: 1,
  },
  list: {
    maxHeight: 300,
  },
});
