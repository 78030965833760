import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgArchive = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Rect width={40} height={40} rx={20} fill="#07818C" />
    <Path
      d="M25.25 25.25V15.5h1.5v9.75a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25V15.5h1.5v9.75c0 .414.336.75.75.75h9a.75.75 0 00.75-.75z"
      fill="#fff"
    />
    <Path
      d="M17.75 19.25c0-.6.5-.75.75-.75h3c.25 0 .75.15.75.75s-.5.75-.75.75h-3c-.25 0-.75-.15-.75-.75z"
      fill="#fff"
    />
    <Path
      d="M12.5 14a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v1.5a.75.75 0 01-.75.75h-13.5a.75.75 0 01-.75-.75V14z"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </Svg>
);
export default SvgArchive;
