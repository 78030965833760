import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import React from 'react';

//import seatsIntro from '../../src/assets/seats_intro.riv';

const Rive = ({ resourceName }: { resourceName: string }) => {
  const images: any = {
    seats_intro: '/assets/seats_intro.riv',
    onboarding: '/assets/onboarding.riv',
    loader: '/assets/loader.riv',
    loading_bar: '/assets/loading_bar.riv',
  };
  const imageSrc = images[resourceName];

  //TODO do this as config and set a root path
  // const root = window.location.href
  //   .replace('/index.html', '')
  //   .replace('file:///', '');

  const { RiveComponent } = useRive({
    src: imageSrc,
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return (
    <>
      <RiveComponent />
    </>
  );
};

export { Alignment, Fit };

export const RiveRef = {};

export default Rive;
