import { DataStore } from 'aws-amplify/datastore';

import type { UpdateOrganisationInput } from '@/API';
import { Organisation } from '@/models';

export const saveOrganisation = async (
  organisationDetails: UpdateOrganisationInput,
) => {
  const {
    id,
    name,
    billingId,
    users,
    members,
    allowedUserIds,
    plan,
    image,
    usage,
  } = organisationDetails;

  let organisation = null;

  const existingOrganisation = await DataStore.query(Organisation, id);

  if (id && existingOrganisation) {
    organisation = await DataStore.save(
      Organisation.copyOf(existingOrganisation, updated => {
        updated.name = name ?? updated.name;
        updated.billingId = billingId ?? updated.billingId;
        updated.users = users ?? updated.users;
        updated.members = members ?? updated.members;
        updated.allowedUserIds = allowedUserIds ?? updated.allowedUserIds;
        updated.plan = plan ?? updated.plan;
        updated.image = image ?? updated.image;
        updated.usage = usage ?? updated.usage;
      }),
    );
  } else {
    organisation = await DataStore.save(
      new Organisation({
        name,
        billingId,
        users,
        members,
        allowedUserIds,
        plan,
        image,
        usage,
      }),
    );
  }
  return organisation;
};
