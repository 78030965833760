import { uploadData } from 'aws-amplify/storage';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { launchImageLibrary } from 'react-native-image-picker';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import PhotoRollIcon from '@/assets/svg/photo_roll_icon_colored.svg';
import TrashIcon from '@/assets/svg/trash_icon.svg';
import BackButtonLight from '@/components/BackButtonLight';
import AppAvatar from '@/components/avatar';
import FlaotingTextInput from '@/components/floating-text-input';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import LottieLoader from '@/components/loaders/LottieLoader';
import useNavigation from '@/hooks/useNavigation';
import { getUserDetails } from '@/services/chatUsers';
import type { TableChatUser } from '@/services/chatUsers/types';
import type { ChatUnit } from '@/services/index';
import { blue2, ligthBlue2, primaryBlue, red } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { uriToBlob } from '@/utilities/lib/uriToBlob';

const OtherUserProfile = () => {
  const [avatarUploadInProgress, setAvatarUploadInProgress] = useState(false);
  const [localAvatar, setLocalAvatar] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<TableChatUser>({
    avatar: '',
    customAvatar: '',
    customNickname: '',
    date_joined: '',
    isOnline: false,
    name: '',
  });
  //TODO: needs to be fixed to use datastore
  const tables: ChatUnit[] = [];
  const { translate } = useTranslations();
  const { goBack } = useNavigation();
  const { top } = useSafeAreaInsets();

  const pathToImageFile = async (filePath: string, fileName: string) => {
    try {
      setAvatarUploadInProgress(true);
      const blob = await uriToBlob(filePath);
      const { key } = await uploadData({
        data: blob,
        key: fileName,
        options: {
          contentType: 'image/jpeg',
        },
      }).result;
      setUser({ ...user, avatar: key });
      setAvatarUploadInProgress(false);
    } catch (err) {
      setAvatarUploadInProgress(false);
    }
  };

  const chooseImageFromLibrary = async () => {
    launchImageLibrary(
      {
        includeBase64: false,
        maxHeight: 200,
        maxWidth: 200,
        mediaType: 'photo',
      },
      response => {
        if (response?.assets?.[0]?.uri) {
          setLocalAvatar(response?.assets?.[0]?.uri);
        }
        pathToImageFile(
          response?.assets?.[0]?.uri ?? '',
          response?.assets?.[0]?.fileName ?? '',
        );
      },
    );
  };

  const startConversation = () => {};
  const createNewTable = () => {};
  const muteUser = () => {};
  const blockUser = () => {};

  const getUserData = async () => {
    setIsLoading(true);
    const data = await getUserDetails();
    setUser({ ...data });
    setIsLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (isLoading) {
    return (
      <View style={styles.loader}>
        <LottieLoader />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        bounces={false}
        contentContainerStyle={styles.scrollContainer}
        showsVerticalScrollIndicator={false}>
        <View style={[styles.header, { paddingTop: top }]}>
          <View style={styles.backBtn}>
            <BackButtonLight onPress={() => goBack()} />
          </View>
          <View style={styles.headerContent}>
            <View style={styles.headerTextContainer}>
              <Text style={styles.addPhotoText}>
                {true ? translate('add_own_photo') : translate('edit_photo')}
              </Text>
            </View>
            <View style={styles.avatarContainer}>
              {true && (
                // TODO; wire this up with real user data
                <AppAvatar
                  imageKey={user.avatar}
                  displayName={user.customNickname || user.name || ''}
                  style={styles.avatarImage}
                />
              )}
              {avatarUploadInProgress && (
                <View style={styles.avatarOverlay}>
                  <ActivityIndicator />
                </View>
              )}
            </View>
            <View style={styles.avatarControls}>
              <TouchableOpacity
                onPress={chooseImageFromLibrary}
                style={styles.chooseImgBtn}>
                <PhotoRollIcon />
              </TouchableOpacity>
              {localAvatar && (
                <TouchableOpacity
                  onPress={() => {
                    setLocalAvatar('');
                  }}>
                  <TrashIcon />
                </TouchableOpacity>
              )}
            </View>
          </View>

          <Text style={styles.setupText}>
            {user.name}
            {user.customNickname && (
              <>
                <Text style={styles.nicknameDot}> • </Text>
                {user.customNickname}
              </>
            )}
          </Text>

          {user.isOnline && (
            <View style={styles.availableContainer}>
              <View style={styles.dot} />
              <Text style={styles.availableTxt}>{translate('available')}</Text>
            </View>
          )}
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.content}>
            <FlaotingTextInput
              label={translate('custom_nickname')}
              onChangeText={text => setUser({ ...user, customNickname: text })}
              style={styles.fullNameInput}
              value={user.customNickname}
            />
          </View>
          <View style={[styles.contentRow, styles.dateContainer]}>
            <Text style={styles.dateJoinedText}>
              {translate('date_joined_label')}
            </Text>
            <Text style={styles.joinDate}>
              {new Date().toLocaleDateString()}
            </Text>
          </View>
          <View style={styles.tablesContainer}>
            <Text style={styles.tablesHeader}>
              {translate('table_is_on').replace('${name}', user.name)}
            </Text>
            <Text style={styles.tablesSubheader}>
              {user.name}
              {translate('user_is_member_of_tables').replace(
                '${number}',
                tables.length.toLocaleString(),
              )}
            </Text>
          </View>
          <View style={styles.tablesList}>
            {tables.map((table: ChatUnit) => {
              return (
                <View key={table.id} style={styles.singleTable}>
                  <View style={styles.tableImg}>
                    <ChatUnitBackgroundImage
                      imageKey={table.backgroundImage}
                      width="100%"
                      height="100%"
                    />
                  </View>
                  <View>
                    <Text style={styles.tableName}>{table.title}</Text>
                    <Text style={styles.tableMembers}>
                      {table.users?.length ?? 0} {translate('members')}
                    </Text>
                  </View>
                </View>
              );
            })}
            <TouchableOpacity onPress={createNewTable}>
              <Text style={styles.blueHeader}>
                {translate('create_new_table')} {user.name}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.spacer} />
          <View style={styles.contentRowColumn}>
            <TouchableOpacity onPress={startConversation}>
              <Text style={styles.blueHeader}>
                {translate('start_1_on_1')} {user.name}
              </Text>
            </TouchableOpacity>
            <Text>{translate('wont_be_associated')}</Text>
          </View>
          <View style={styles.spacer} />
          <View style={styles.contentRowColumn}>
            <TouchableOpacity onPress={muteUser}>
              <Text style={styles.blueHeader}>
                {translate('mute')} {user.name}
              </Text>
            </TouchableOpacity>
            <Text>{translate('mute_explained')}</Text>
            <TouchableOpacity onPress={blockUser}>
              <Text style={styles.blockUserTxt}>
                {translate('block')} {user.name}
              </Text>
            </TouchableOpacity>
            <Text>{translate('block_explained')}</Text>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

export default OtherUserProfile;

const styles = StyleSheet.create({
  addPhotoText: {
    color: '#B3B3B3',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 19,
    textAlign: 'center',
    width: 70,
  },
  availableContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    paddingBottom: 16,
  },
  availableTxt: {
    color: Greys.shade200,
  },
  avatarContainer: {
    alignItems: 'center',
    backgroundColor: '#D9D9D9',
    borderRadius: 60,
    height: wp(30),
    justifyContent: 'center',
    width: wp(30),
  },
  avatarControls: {
    flexDirection: 'row',
    width: wp(30),
  },
  avatarImage: {
    borderRadius: 60,
    height: wp(30),
    width: wp(30),
  },
  avatarOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 60,
    height: wp(30),
    justifyContent: 'center',
    position: 'absolute',
    width: wp(30),
  },
  backBtn: {
    alignSelf: 'flex-start',
  },
  blockUserTxt: {
    color: red,
    fontSize: 14,
    fontWeight: '600',
    paddingBottom: 8,
    paddingTop: 16,
  },
  blueHeader: {
    color: primaryBlue,
    fontSize: 14,
    fontWeight: '600',
    paddingVertical: 8,
  },
  bottomContainer: {
    backgroundColor: Greys.shade200,
    flex: 1,
  },
  chooseImgBtn: {
    marginLeft: 8,
    marginRight: 8,
  },
  container: {
    backgroundColor: Greys.shade200,
    flex: 1,
  },
  content: {
    alignItems: 'flex-start',
    flex: 1,
  },
  contentRow: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: 1,
    flexDirection: 'row',
    height: 30,
    paddingHorizontal: 20,
  },
  contentRowColumn: {
    backgroundColor: Greys.shade0,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  dateContainer: {
    marginTop: 8,
  },
  dateJoinedText: {
    color: Greys.shade400,
    fontSize: 11,
  },
  dot: {
    backgroundColor: ligthBlue2,
    borderRadius: 6,
    height: 12,
    width: 12,
  },
  fullNameInput: {
    backgroundColor: Greys.shade0,
    paddingHorizontal: 20,
    paddingVertical: 12,
    width: wp(100),
  },
  header: {
    alignItems: 'center',
    backgroundColor: Greys.shade600,
    paddingHorizontal: 16,
  },
  headerContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 24,
    width: '100%',
  },
  headerTextContainer: {
    alignItems: 'flex-end',
    paddingRight: 12,
    width: wp(28),
  },
  joinDate: {
    color: Greys.shade400,
    fontSize: 11,
    paddingLeft: 12,
  },
  loader: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  nicknameDot: {
    color: blue2,
  },
  scrollContainer: {
    paddingBottom: 50,
  },
  setupText: {
    color: Greys.shade0,
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 28,
    marginBottom: wp(3),
    marginTop: wp(3),
  },
  singleTable: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    marginVertical: 6,
  },
  spacer: {
    height: 20,
  },
  tableImg: {
    borderRadius: 25,
    height: 50,
    width: 50,
  },
  tableMembers: {
    color: Greys.shade500,
    fontSize: 9,
    fontWeight: '400',
  },
  tableName: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '600',
  },
  tablesContainer: {
    backgroundColor: Greys.shade200,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 18,
  },
  tablesHeader: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 14,
  },
  tablesList: {
    backgroundColor: Greys.shade0,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  tablesSubheader: {
    color: Greys.shade500,
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 11,
    marginTop: 5,
  },
});
