import PersonIcon from '@/assets/onboarding/person.svg';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function OnboardingAvatarEmptyPlaceholder() {
  const { translate } = useTranslations();

  return (
    <Section p={3} bw={1} bc={Colors.neutral30} br={BorderRadius.sm}>
      <View style={styles.dottedAvatarContainer}>
        <PersonIcon width={36} height={36} />
        <AppText size={12} color={Colors.neutral70} textAlign="center">
          {translate('tap_avatar_text')}
        </AppText>
      </View>
    </Section>
  );
}

const styles = StyleSheet.create({
  dottedAvatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 136,
    borderWidth: 1,
    borderRadius: BorderRadius.full,
    borderStyle: 'dashed',
    borderColor: Colors.neutral30,
    gap: 10,
  },
});
