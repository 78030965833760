import { clearPushTokenForDevice } from './api/clearPushTokenForDevice';

import { getUniqueDeviceId } from '@/services/notifications/getUniqueDeviceId';
import { app } from '@/utilities/platform';

export async function unsubFromNotifications(userId: string | undefined) {
  if (!userId) return;

  if (app) await clearPushTokenForDevice(userId, getUniqueDeviceId());
}
