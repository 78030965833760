import { navigationRef } from '@/navigation/navigationRef';
import React, { useCallback } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { useShallow } from 'zustand/react/shallow';
import { ActivityItemBadge } from './ActivityItemBadge';

import appCircularIcon from '@/assets/discover/appCircular.png';
import gradientEnvelopeImg from '@/assets/invites/gradientEnvelope.png';
import gradientGroupImg from '@/assets/invites/group.png';
import useCMSStore from '@/domain/cms/useCMSStore';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { Colors } from '@/domain/theme/Colors';
import { InviteSlideType } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app/elements';
import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
import { CommonActions, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { formatActivityDate } from '../timeHelper';

const OrganisationsInviteRow = () => {
  const navigation = useNavigation();
  const { translate } = useTranslations();
  const [numberOfInvites, mostRecent] = useMyInviteStore(
    useShallow(state => [
      state.getPendingInviteCountForOrganisations(),
      state.getLatestInviteDateForOrganisations(),
    ]),
  );

  const handlePress = useCallback(() => {
    if (web) {
      navigationRef.navigate('SplitView', {
        route: 'InviteList',
        activeSlideType: InviteSlideType.WORKSPACES,
      });
    } else {
      navigation.navigate('InviteList', {
        activeSlideType: InviteSlideType.WORKSPACES,
      });
    }
  }, [navigation]);

  if (!numberOfInvites) {
    return null;
  }

  return (
    <Pressable style={styles.row} onPress={handlePress}>
      <Image source={gradientGroupImg} style={styles.icon} />
      <View style={styles.col}>
        <View style={styles.titleRow}>
          <AppText type="primary700" size={14}>
            {numberOfInvites > 1
              ? translate('new_workspace_invites')
              : translate('new_workspace_invite')}
          </AppText>
          <AppText size={11}>{mostRecent}</AppText>
        </View>
        <AppText size={14}>
          {numberOfInvites > 1
            ? translate('new_workspace_invites_subtitle', { numberOfInvites })
            : translate('new_workspace_invite_subtitle')}
        </AppText>
      </View>
      <ActivityItemBadge
        unreadMessageCount={numberOfInvites ?? 0}
        containerStyles={styles.badge}
      />
    </Pressable>
  );
};

const MessagesInviteRow = () => {
  const navigation = useNavigation();
  const { translate } = useTranslations();
  const [numberOfInvites, mostRecent] = useMyInviteStore(
    useShallow(state => [
      state.getPendingInviteCountForMessages(),
      state.getLatestInviteDateForMessages(),
    ]),
  );

  const handlePress = useCallback(() => {
    if (web) {
      navigationRef.navigate('SplitView', {
        route: 'InviteList',
      });
    } else {
      navigation.navigate('InviteList');
    }
  }, [navigation]);

  if (!numberOfInvites) {
    return null;
  }

  return (
    <Pressable style={styles.row} onPress={handlePress}>
      <Image source={gradientEnvelopeImg} style={styles.icon} />
      <View style={styles.col}>
        <View style={styles.titleRow}>
          <AppText type="primary700" size={14}>
            {numberOfInvites > 1
              ? translate('new_invites')
              : translate('new_invite')}
          </AppText>
          <AppText size={11}>{mostRecent}</AppText>
        </View>
        <AppText size={14}>
          {numberOfInvites > 1
            ? translate('new_invites_subtitle', { numberOfInvites })
            : translate('new_invite_subtitle')}
        </AppText>
      </View>
      <ActivityItemBadge
        unreadMessageCount={numberOfInvites ?? 0}
        containerStyles={styles.badge}
      />
    </Pressable>
  );
};

const DiscoverRow = () => {
  const navigation = useNavigation();
  const unreadPosts = useCMSStore(
    // Filter out read posts
    state => state.getUnreadPostsCount(),
  );
  const mostRecentPost = useCMSStore(
    state =>
      [...state.posts].sort((a, b) => {
        return moment(b.publishDate || b.sys.publishedAt).diff(
          moment(a.publishDate || a.sys.publishedAt),
        );
      })[0],
  );

  if (!unreadPosts) {
    return null;
  }

  if (!mostRecentPost) {
    return null;
  }

  return (
    <Pressable
      style={styles.row}
      onPress={() =>
        web
          ? navigationRef.navigate('SplitView', {
              route: 'Discover',
            })
          : navigation.dispatch(
              CommonActions.navigate({
                name: 'MainNavigator',
                params: { screen: 'Discover' },
              }),
            )
      }>
      <Image source={appCircularIcon} style={styles.icon} />
      <View style={styles.col}>
        <View style={styles.titleRow}>
          <AppText type="primary700" size={14}>
            Team 8seats
          </AppText>
          <AppText size={11}>
            {formatActivityDate(
              new Date(
                mostRecentPost.publishDate || mostRecentPost.sys.publishedAt,
              ),
            )}
          </AppText>
        </View>
        <AppText size={14}>{mostRecentPost.title}</AppText>
      </View>
      <ActivityItemBadge
        unreadMessageCount={unreadPosts}
        containerStyles={styles.badge}
      />
    </Pressable>
  );
};

const InvitesHeaderRow = () => {
  return (
    <>
      <Spacer height={8} />
      <DiscoverRow />
      <Spacer height={1} bg={Colors.neutral10} width="100%" />
      <OrganisationsInviteRow />
      <Spacer height={1} bg={Colors.neutral10} width="100%" />
      <MessagesInviteRow />
      <Spacer height={8} />
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    backgroundColor: Greys.shade0,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: BorderRadius.xs,
    marginLeft: 4,
    marginRight: 4,
  },
  icon: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
  },
  badge: {
    position: 'absolute',
    top: 9,
    right: 9,
  },
  col: {
    flex: 1,
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 8,
  },
});

export default InvitesHeaderRow;
