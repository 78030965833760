import isEqual from 'fast-deep-equal';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import type { User } from '@/services/chatUnit/types';

type State = {
  user: User | undefined;
};

type Actions = {
  setUser: (user?: User) => void;
  clearState: () => void;
  isProfileComplete: () => boolean;
};

const INITIAL_STATE: State = {
  user: undefined,
};

const useUserStore = createWithEqualityFn<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...INITIAL_STATE,
      /**
       * Check if the user has completed their profile.
       * This is determined by the presence of a name, username, and avatar.
       */
      isProfileComplete: () => {
        const user = get().user;
        if (!user) {
          return false;
        }
        return !!user.avatar && !!user.name && !!user.username;
      },

      setUser: user => {
        set(state => {
          state.user = user;
        });
      },

      clearState: () => {
        set(state => {
          state.user = undefined;
        });
      },
    })),
    {
      name: 'user-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
    },
  ),
  isEqual,
);

export default useUserStore;
