import { MessageV2 } from '@/models';
import type { Message as IMessage } from '@/services/chatUnit/types';
import { eventBus } from '@/services/eventBus/eventBus';
import { logger } from '@/services/logger/logger';
import generateUniqueId from '@/utilities/helpers/uuid';
import { DataStore } from 'aws-amplify/datastore';
import { updateConversation } from '../conversation/updateConversation';

export const saveMessage = async (messageDetails: IMessage) => {
  const {
    id,
    allowedUserIds,
    conversationId,
    chatUnitId,
    chatUnitUserId,
    image,
    messageId,
    SK,
    text,
    userId,
    metaData,
  } = messageDetails;

  const date = new Date().toISOString();

  const message = await DataStore.save(
    new MessageV2({
      id, // should be overwritten by amplify
      allowedUserIds,
      authorId: userId,
      createdAt: date,
      conversationId,
      chatUnitId,
      chatUnitIdConversationId: `${chatUnitId}#${conversationId}`,
      chatUnitUserId,
      image,
      messageId,
      owner: `${userId}::${userId}`,
      SK,
      source: 'app',
      status: 'SENT',
      text,
      userId,
      metaData: JSON.stringify(metaData),
    }),
  );
  await updateConversation({
    conversationId,
    chatUnitId,
    updatedAt: date,
  });

  try {
    eventBus.emit('messageSaved', {
      message: message as unknown as IMessage,
    });
  } catch (e) {
    logger.error('error emitting messagedSaved', e);
    throw e;
  }
};
