import { useConversationStore } from '../state/useConversationStore';

export const generateConversationUsersBinaryMap = (
  chatUnitId: string,
  chatUsers: { id: string }[],
) => {
  const conversationUserIds = new Map<string, string[]>();
  const userIdIndexMap = new Map<string, number>();
  const userConversationIds = new Map<string[], string>();

  // Assign each user an index, useful for UI-related operations or user management within conversations.
  chatUsers.forEach((user, index) => {
    userIdIndexMap.set(user.id, index);
  });

  // Retrieve active conversations for the current chat unit.
  // Ensures the state reflects the most up-to-date conversation information for this chat context.
  const activeConversations = useConversationStore
    .getState()
    .getNonArchivedConversationsByTableId(chatUnitId);

  activeConversations.forEach(conversation => {
    const { conversationId, allowedUserIds } = conversation;

    // Skip conversations without participants to avoid processing irrelevant data.
    if (!allowedUserIds || allowedUserIds.length === 0) {
      return;
    }

    // Map the conversation ID to its participant user IDs for quick lookups.
    conversationUserIds.set(conversationId, allowedUserIds);

    // Store the conversation ID for the specific set of user IDs.
    userConversationIds.set(allowedUserIds, conversationId);
  });

  return {
    userConversationIds,
    conversationUserIds,
    userIdIndexMap,
  };
};
