import { useEffect } from 'react';
import type { Subscription } from 'rxjs';

import { useMyInviteStore } from '../state/useMyInvitesStore';

import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import { getDatastoreObservable } from '@/services/datastore/subscriptions/subscriptionManager';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';

export const useInviteLoadedEventListener = () => {
  const { addInvites, removeInvite } = useMyInviteStore(state => state);
  const datastoreReady = useLoadingStore(state => state.datastoreReady);

  useEffect(() => {
    let invitesLoadedSubscription: Subscription | undefined;

    if (datastoreReady) {
      invitesLoadedSubscription = getDatastoreObservable('Invites').subscribe(
        subscriptionMessage => {
          try {
            const invite = factory.invite(subscriptionMessage?.element);

            if (subscriptionMessage.opType === 'DELETE') {
              removeInvite(invite.inviteId);
            } else {
              addInvites([invite]);
            }
          } catch (e) {
            logger.error('subscribeInviteLoadedError', e);
          }
        },
      );
    }

    return () => {
      invitesLoadedSubscription?.unsubscribe();
    };
  }, [addInvites, removeInvite, datastoreReady]);
};
