import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import React, { useMemo } from 'react';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import crossImg from '@/assets/invites/cross.png';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import type { Invite } from '@/domain/invites/types/types';
import { InviteTargetType } from '@/models';
import { Icon } from '@/ui/app/elements';

type Props = {
  invite?: Invite;
};

export const InviteSuccessHeader = ({ invite }: Props) => {
  const { goBack } = useNavigation();
  const insets = useSafeAreaInsets();

  const closeIconStyles = useMemo(() => {
    return {
      ...styles.close,
      top: Platform.select({ ios: 0, default: 16 }) + insets.top,
      right: 12 + insets.right,
    };
  }, [insets.right, insets.top]);

  if (!invite) {
    return null;
  }

  return (
    <View>
      <View style={styles.img}>
        <ChatUnitBackgroundImage
          imageKey={invite.targetInfo.backgroundImage}
          width="100%"
          height="100%"
        />
      </View>
      <Pressable onPress={goBack}>
        <Image source={crossImg} style={closeIconStyles} />
      </Pressable>
      <View style={styles.chatUnitIcon}>
        <Icon
          fill="transparent"
          iconKey={
            invite.targetType === InviteTargetType.ROOM
              ? 'RoomsLgFlat'
              : 'LogoOctagon'
          }
          size={80}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    zIndex: 11,
    width: 40,
    height: 40,
  },
  img: {
    height: 280,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  chatUnitIcon: {
    alignSelf: 'center',
    marginTop: 68,
  },
});
