import React from 'react';
import type { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { useTheme } from '@/domain/theme';
import { BorderRadius } from '@/ui/common/styles';

const GRADIENT_START = { x: 0, y: 0 };
const GRADIENT_END = { x: 0, y: 1 };

const ChatUnitNotificationGradient = (props: PropsWithChildren) => {
  const {
    theme: {
      ui: {
        toast: {
          variants: {
            newMessage: { gradientColor, gradientBackgroundColor },
          },
        },
      },
    },
  } = useTheme();

  return (
    <LinearGradient
      colors={gradientColor}
      start={GRADIENT_START}
      end={GRADIENT_END}
      style={[styles.tableRow, { backgroundColor: gradientBackgroundColor }]}>
      {props.children}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  tableRow: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    borderBottomLeftRadius: BorderRadius.lg,
    borderBottomRightRadius: BorderRadius.lg,
  },
});

export default ChatUnitNotificationGradient;
