export function removeMarkdown(text: string): string {
  const removeMd = require('remove-markdown');
  // example '# This is a heading\n\nThis is a paragraph with [a link](http://www.disney.com/) in it.';
  return removeMd(text); // plainText is now 'This is a heading\n\nThis is a paragraph with a link in it.'
}

export function truncate(
  text: string,
  length = 60,
  include: string | undefined = undefined,
) {
  if (!text) {
    return '';
  }
  // If the text is shorter than the desired length, return the text as is
  if (text.length <= length) {
    return text;
  }
  // if the text includes the include string
  if (text.length > length && include && text.includes(include.toLowerCase())) {
    const includeIndex = text.indexOf(include.toLowerCase());
    const halfLength = Math.floor((length - include.length) / 2) - 3;
    const sliceStart =
      includeIndex - halfLength > 0 ? includeIndex - halfLength : 0;
    return `${sliceStart > 0 ? '...' : ''}${text.slice(
      sliceStart,
      includeIndex + include.length + halfLength,
    )}...`;
  }

  return text.length < length ? text : `${text.slice(0, length - 2)}...`;
}

export default {
  truncate,
  removeMarkdown,
};
