import { FlashList, type ListRenderItem } from '@shopify/flash-list';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { ConversationActivityCard } from '../../components';

import SearchIcon from '@/assets/svg/search.svg';
import SecondaryHeader from '@/components/headers/SecondaryHeader';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import type { ChatMessage } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import SvgClose from '@/ui/app/elements/Icon/icons/Close';
import SvgUnion from '@/ui/app/elements/Icon/icons/Union';
import { Greys, Neutrals } from '@/ui/common/colors';
import generateUniqueId from '@/utilities/helpers/uuid';
import { web } from '@/utilities/platform';

const ItemSeparatorComponent = React.memo(() => (
  <View style={styles.itemSeparator} />
));

export const Search = () => {
  const [query, setQuery] = useState('');

  const { navigate, goBack, navigationRef } = useNavigation();
  const userId = useUserStore(useShallow(state => state.user?.id));
  const getMostRecentMessagesMatchingQuery = useMessageStore(
    useShallow(state => state.getMostRecentMessagesMatchingQuery),
  );

  const sortedMessages = useMemo(
    () => getMostRecentMessagesMatchingQuery(query.trim()),
    [query, getMostRecentMessagesMatchingQuery],
  );
  const inputRef = useRef<TextInput>(null);

  const isArchivedConversation = useConversationStore(
    useShallow(state => state.isArchivedConversation),
  );

  const onActivityPress = useCallback(
    (message: ChatMessage) => {
      const archivedConversation = isArchivedConversation(
        message.chatUnitId,
        message.conversationId,
      );
      if (web) {
        navigationRef.current?.navigate('SplitView', {
          route: archivedConversation ? 'ArchivedConversation' : 'ChatView',
          conversationId: message.conversationId,
          chatUnitId: message.chatUnitId,
          selectedMessage: message,
          selectedQuery: query,
          uniqueId: generateUniqueId(),
        });
      } else {
        navigate(archivedConversation ? 'ArchivedConversation' : 'ChatView', {
          conversationId: message.conversationId,
          chatUnitId: message.chatUnitId,
          selectedMessage: message,
          selectedQuery: query,
        });
      }
      Keyboard.dismiss();
    },
    [navigate, query, navigationRef, isArchivedConversation],
  );

  const { translate } = useTranslations();
  const { top } = useSafeAreaInsets();

  const renderItem: ListRenderItem<ChatMessage> = useCallback(
    ({ item }) => (
      <ConversationActivityCard
        message={item}
        onPress={onActivityPress}
        userId={userId || ''}
        boldText={query.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}
      />
    ),
    [onActivityPress, userId, query],
  );

  const textChanged = useCallback(
    (text: string) => {
      // remove preceding spaces before setting the query
      setQuery(text.replace(/^\s+/, ''));
    },
    [setQuery],
  );

  // dismiss the keyboard when user starts scrolling the results
  const handleScroll = useCallback(() => {
    Keyboard.dismiss();
  }, []);

  // clear the query and focus the text input
  const clearQuery = useCallback(() => {
    setQuery('');
    inputRef.current?.focus();
  }, [setQuery]);

  // if the query is empty, go back
  const handleSubmit = useCallback(() => {
    if (query === '') {
      goBack();
    }
  }, [query, goBack]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}>
      <View
        style={[
          {
            backgroundColor: Greys.shade0,
          },
          { paddingTop: top },
        ]}>
        <SecondaryHeader
          backButtonText={'common_back'}
          title={translate('search')}
        />
      </View>
      <View style={styles.searchBar}>
        <SearchIcon style={styles.searchIcon} />
        <TextInput
          placeholder="Search messages"
          placeholderTextColor={Neutrals.shade700}
          ref={inputRef}
          style={styles.searchInput}
          onChangeText={textChanged}
          onSubmitEditing={handleSubmit}
          autoFocus={true}
          autoCorrect={false}
          value={query}
          selectTextOnFocus={false}
          enterKeyHint="search"
          returnKeyLabel="search"
          maxLength={75}
        />
        {query.length > 0 && (
          <TouchableOpacity onPress={clearQuery} style={styles.closeIcon}>
            <SvgClose />
          </TouchableOpacity>
        )}
      </View>
      {sortedMessages.length > 0 && (
        <FlashList
          data={sortedMessages}
          ItemSeparatorComponent={ItemSeparatorComponent}
          keyExtractor={item => item.id.toString()}
          renderItem={renderItem}
          estimatedItemSize={200}
          drawDistance={450}
          onScroll={handleScroll}
          showsVerticalScrollIndicator={false}
          refreshing={false} // stop the spinner from displaying in place with loading bar
          keyboardShouldPersistTaps="handled"
        />
      )}
      {query.length > 0 && sortedMessages.length === 0 && (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.noResultsContainer}>
            <SvgUnion height={72} width={72} />
            <Text style={styles.noResultsText}>THERE ARE NO RESULTS FOR</Text>
            <Text style={styles.noResultsQuery}>{query}</Text>
          </View>
        </TouchableWithoutFeedback>
      )}
    </KeyboardAvoidingView>
  );
};

const ITEM_SEPARATOR_HEIGHT = 1;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemSeparator: {
    height: ITEM_SEPARATOR_HEIGHT,
  },
  searchBar: {
    marginTop: 14,
    marginBottom: 10,
    paddingHorizontal: 20,
  },
  searchIcon: {
    left: 35,
    position: 'absolute',
    top: 8,
    zIndex: 1,
  },
  searchInput: {
    backgroundColor: Greys.shade0,
    borderRadius: 18,
    height: 36,
    paddingLeft: 40,
    paddingRight: 30,
    shadowColor: Greys.shade999,
    shadowOffset: { height: 0.5, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  closeIcon: {
    position: 'absolute',
    right: 30,
    top: 7,
  },
  noResultsContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultsText: {
    fontSize: 12,
    fontWeight: '700',
    marginTop: 30,
    marginBottom: 20,
  },
  noResultsQuery: { fontSize: 28, fontWeight: '400' },
});

export default Search;
