import type { ImageSource } from 'expo-image';
import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';

import CachedImage from '@/components/image/cachedImage';
import Subtitle from '@/components/text/Subtitle';
import { Greys } from '@/ui/common/colors';

interface Props {
  badgeTitle?: string;
  color?: string;
  hasBadge?: boolean;
  iconSource?: string | ImageSource;
  onPress?: () => void;
  rightIconSource?: number;
  style?: ViewStyle;
  title: string;
}
const HelperItem = ({
  badgeTitle,
  color,
  hasBadge,
  iconSource,
  onPress,
  rightIconSource,
  style,
  title,
}: Props) => {
  const fontStyle = color ? { color } : {};
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      style={[styles.container, style]}>
      {iconSource && <CachedImage source={iconSource} style={styles.icon} />}
      <Subtitle style={fontStyle}>{title}</Subtitle>
      {rightIconSource && (
        <CachedImage source={rightIconSource} style={styles.rightIcon} />
      )}
      {hasBadge && (
        <View style={styles.badge}>
          <Subtitle style={fontStyle}>{badgeTitle}</Subtitle>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    borderColor: Greys.shade600,
    borderRadius: 14,
    borderWidth: 1,
    justifyContent: 'center',
    marginLeft: 10,
    paddingHorizontal: 12,
    paddingVertical: 5,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  content: {
    flexDirection: 'row',
  },
  icon: {
    height: 24,
    marginRight: 8,
    width: 24,
  },
  rightIcon: {
    height: 24,
    marginLeft: 15,
    width: 24,
  },
});
export default HelperItem;
