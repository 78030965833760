import { DataStore } from 'aws-amplify/datastore';

import { ChatUnitUser as ChatUnitUserModel } from '../../../models';

import type { ChatUnitUser } from '@/services/chatUnitUser/types';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';

export const getChatUnitUsersForChatUnit = async (chatUnitId: string) => {
  const results = await DataStore.query(ChatUnitUserModel, chatUser =>
    chatUser.chatUnitId.eq(chatUnitId),
  );
  return results.reduce((acc, chatUser) => {
    try {
      acc.push(factory.chatUnitUser(chatUser));
    } catch (e) {
      logger.error('getChatUnitUsersForChatUnit', e);
    }
    return acc;
  }, [] as ChatUnitUser[]);
};
