import type React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

import { Greys } from '@/ui/common/colors';

export interface FormTextFields {
  [key: string]: string;
}
interface TextAreaInputProps {
  label: string;
  onBlur: (e: unknown) => void;
  onChangeText: ((text: string) => void) | undefined;
  style?: ViewStyle;
  textStyle?: TextStyle;
  title: string;
  value: string | undefined;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  label,
  onBlur,
  onChangeText,
  style,
  textStyle,
  title,
  value,
}) => {
  return (
    <View style={[styles.textInputContainer, style]}>
      <Text style={styles.title}>{title}</Text>
      <TextInput
        multiline
        onBlur={onBlur}
        onChangeText={onChangeText}
        placeholder={label}
        style={[styles.textInput, textStyle]}
        value={value ?? ''}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
  textInputContainer: {
    flexDirection: 'column',
  },
  title: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
  },
});

export default TextAreaInput;
