import moment from 'moment';

export const formatDate = (dateString: string): string => {
  const inputFormat = 'DD/MM/YYYY';
  const date = moment(dateString, inputFormat);

  if (!date.isValid()) {
    return 'Invalid Date';
  }

  return date.format('D MMMM');
};
