import type { GraphQLQuery } from '@aws-amplify/api';

import type {
  ChatUnitUserByUserIDQuery,
  ChatUnitUser as IChatUnitUser,
} from '../../../API';
import type { ChatUnitUser } from '../types';

import client from '@/services/API/client';
import { logger } from '@/services/logger/logger';

const formatItem = (chatUnitUser: IChatUnitUser): ChatUnitUser => {
  return {
    avatar: chatUnitUser.avatar as string,
    id: chatUnitUser.userId,
    chatUnitId: chatUnitUser.chatUnitId,
    chatUnitUserId: chatUnitUser.id,
    username: chatUnitUser.username as string,
    phoneNumber: chatUnitUser.phoneNumber as string,
  };
};

const chatUnitUserByUserIDQuery = /* GraphQL */ `
  query ChatUnitUserByUserID(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatUnitUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatUnitUserByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        __typename
        _deleted
        _lastChangedAt
        _version
        allowedUserIds
        avatar
        chatUnitChatUnitUsersId
        chatUnitId
        conversationIds
        createdAt
        id
        owner
        phoneNumber
        status
        updatedAt
        userId
        username
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTableUserByUserId = async (userId: string) => {
  try {
    const response = await client.graphql<
      GraphQLQuery<ChatUnitUserByUserIDQuery>
    >({
      query: chatUnitUserByUserIDQuery,
      variables: { userId: userId },
    });
    if (response && response.data && response.data.chatUnitUserByUserID) {
      return response.data.chatUnitUserByUserID.items
        .filter(item => !item?._deleted)
        .map(chatUnitUser => formatItem(chatUnitUser as IChatUnitUser));
    }
    throw response.errors;
  } catch (err) {
    logger.error('ERROR::getTableUserByUserId::', err);
    throw err;
  }
};
