import GalleryIcon from '@/assets/onboarding/gallery.svg';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { Image } from 'expo-image';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

type Props = {
  onPress: () => void;
  logo?: string;
};

export default function OnboardingWorkspaceLogoSelect({
  onPress,
  logo,
}: Props) {
  const { translate } = useTranslations();
  if (!logo) {
    return (
      <Pressable onPress={onPress} style={styles.container}>
        <GalleryIcon width={36} height={36} />
        <AppText size={12} color={Colors.neutral70} textAlign="center">
          {translate('your_company_logo_instruct')}
        </AppText>
      </Pressable>
    );
  } else {
    return (
      <Pressable onPress={onPress} style={styles.container}>
        <Image source={{ uri: logo }} style={styles.img} />
      </Pressable>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 140,
    height: 140,
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    borderColor: Colors.neutral30,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  img: {
    height: 140,
    width: 140,
    borderRadius: BorderRadius.sm,
  },
});
