import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import AnimatedSpinner from './AnimatedSpinner';

import logoImg from '@/assets/chatview/logoIcon.png';
import { Greys } from '@/ui/common/colors';

const CircleSpinnerWithLogo = () => {
  return (
    <View style={styles.circle}>
      <AnimatedSpinner />
      <Image source={logoImg} style={styles.logo} />
    </View>
  );
};
const CIRCLE_DIAMETER = 70;

const styles = StyleSheet.create({
  circle: {
    alignItems: 'center',
    height: CIRCLE_DIAMETER,
    justifyContent: 'center',
    width: CIRCLE_DIAMETER,
    borderRadius: CIRCLE_DIAMETER / 2,
    backgroundColor: `${Greys.shade0}E6`,
    padding: 8,
  },
  spinner: {
    width: 54,
    height: 54,
    resizeMode: 'contain',
    zIndex: 2,
    alignSelf: 'center',
  },
  logo: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
    zIndex: 3,
    position: 'absolute',
  },
});

export default CircleSpinnerWithLogo;
