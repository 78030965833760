import type { ChatUnitSubType } from '@/API';
import type { User } from '@/services/chatUnit/types';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { createChatUnit as datastoreCreateChatUnit } from '@/services/datastore/chatUnit/createChatUnit';
import { createChatUnitUser } from '@/services/datastore/user/createChatUnitUser';
import { logger } from '@/services/logger/logger';

type ChatUnitInput = Parameters<typeof datastoreCreateChatUnit>[0] & {
  user: User;
  subtype?: ChatUnitSubType;
};

export const createChatUnit = async (input: ChatUnitInput) => {
  const { user } = input;

  const chatUnit = await datastoreCreateChatUnit({
    ...input,
    allowedUserIds: [user.id],
  });

  logger.debug('createdChatUnit', chatUnit.id);

  // create table user
  const chatUnitUser = await createChatUnitUser({
    allowedUserIds: [user.id],
    avatar: user.avatar ?? '',
    chatUnitId: chatUnit.id,
    userId: user.id,
    username: user.username ?? '',
    phoneNumber: user.phoneNumber ?? '',
    status: ChatUnitUserStatus.ACTIVE,
  });
  logger.debug('createdChatUser', chatUnitUser.id);

  return {
    chatUnit,
    chatUnitUser,
  };
};
