import { BlurView } from '@react-native-community/blur';
import type React from 'react';
import { Dimensions, Modal, Platform, StyleSheet, View } from 'react-native';
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { ios, web } from '@/utilities/platform';

type Props = {
  darkMode?: boolean;
  children: React.ReactNode;
  pinToBottom?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const BottomSheet = ({
  darkMode,
  children,
  open,
  pinToBottom,
  setOpen,
}: Props) => {
  const { bottom, top } = useSafeAreaInsets();

  if (!open) {
    return null;
  }

  return (
    <Modal animationType="fade" transparent visible={open}>
      <View
        onTouchStart={() => setOpen(false)}
        style={styles.background}
        // @ts-ignore: This adds it to the div element on web and works as expected.
        onClick={() => setOpen(false)}>
        <Animated.View
          entering={SlideInDown}
          exiting={SlideOutDown}
          style={[
            styles.sheet,
            darkMode && styles.sheetDark,
            // eslint-disable-next-line react-native/no-inline-styles
            { marginBottom: pinToBottom ? 0 : bottom || 20, marginTop: top },
          ]}
          onTouchStart={e => e.stopPropagation()}>
          {children}
          {ios && pinToBottom && (
            <BlurView
              style={styles.blurredFooter}
              blurType={darkMode ? 'dark' : 'light'}
            />
          )}
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  blurredFooter: {
    height: 40,
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  sheet: {
    // marginHorizontal: 16,
    alignSelf: 'center',
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.lg,
    marginLeft: web ? 'auto' : undefined,
    marginRight: web ? 'auto' : undefined,
    maxHeight: '90%',
    maxWidth: 400,
    width: Dimensions.get('window').width - 20,
    ...Platform.select({
      default: {
        // For Android, adjust as necessary
        elevation: 20,
      },
      ios: {
        shadowColor: Greys.shade999,
        shadowOffset: {
          height: 10,
          width: 1, // You can adjust the height to control the spread
        },
        shadowOpacity: 0.8, // This approximates the 20% black at the end of your gradient
        shadowRadius: 20, // This controls the blur radius
      },
    }),
  },
  sheetDark: {
    backgroundColor: Greys.shade500,
  },
});
