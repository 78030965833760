import { Hub } from '@aws-amplify/core';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

import { postHogTrack } from '@/context/Experience';
import AuthService from '@/domain/auth/AuthService';
import RefreshService from '@/domain/table/functions/RefreshService';
import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import useUserStore from '@/domain/user/state/useUserStore';
import {
  doBootstrap,
  setupSubscriptions,
} from '@/services/datastore/bootstrap/datastoreBootstrap';
import { logger } from '@/services/logger/logger';

const bootstrapStartTime = Date.now();

const runRefresh = async () => {
  try {
    const userId = useUserStore.getState().user?.id;
    if (!userId) {
      return;
    }
    // Refresh all stores
    await Promise.allSettled([
      RefreshService.refreshUser(),
      RefreshService.refreshConversations(),
      RefreshService.refreshChatUsers(),
      RefreshService.refreshChatUnits(),
      RefreshService.refreshInvites(),
    ]);
    const initialized = await AsyncStorage.getItem('initialized');
    if (!initialized) {
      // Wait if first time run
      await RefreshService.seedActivities(userId);
    } else {
      // Do not wait
      RefreshService.seedActivities(userId);
    }
    await AsyncStorage.setItem('initialized', 'true');
  } catch (e) {
    logger.error('refreshStoreError', e);
  }
};

export const useDatastoreBootstrap = () => {
  const [isLoading, setIsLoading] = useState(false);

  const setDatastoreReady = useLoadingStore(state => state.setDatastoreReady);

  const userId = useUserStore(state => state.user?.id);

  // Create listener to refresh stores when datastore is ready.
  useEffect(() => {
    const listener = Hub.listen('datastore', async hubData => {
      const { event } = hubData.payload;
      if (event === 'ready') {
        if (userId) {
          setupSubscriptions();
          setDatastoreReady(true);
          await runRefresh();
        }
        setIsLoading(false);
        logger.debug('bootstrapTime', {
          seconds: (Date.now() - bootstrapStartTime) / 1000,
        });
        postHogTrack('Bootstrap Complete', {
          seconds: (Date.now() - bootstrapStartTime) / 1000,
        });
      }
    });

    return () => {
      listener();
    };
  }, [userId, setDatastoreReady]);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      try {
        const cognitoUser = await AuthService.getCognitoUser();

        if (!cognitoUser) {
          postHogTrack('Bootstrap Cognito User Not Found', {
            message:
              'useDatastoreBootstrap:: No cognito user. Stopping bootstrap, logging out and doing cleanup.',
          });
          logger.debug(
            'useDatastoreBootstrap:: No cognito user. Stopping bootstrap, logging out and doing cleanup.',
          );
          await AuthService.logout();
          setIsLoading(false);
          return;
        }

        // Check first time run.
        const dataCacheExists =
          (await AsyncStorage.getItem('initialized')) === 'true';

        if (!dataCacheExists) {
          setIsLoading(true);
        }

        // Even though this isn't required, still keeping this as it explictly
        // starts datastore, otherwise it could start it in multiple different places
        doBootstrap();
      } catch (error) {
        logger.error('useDatastoreBootstrapError', error);
        setIsLoading(false);
      }
    };

    run();
  }, [userId]);

  return { isLoading };
};
