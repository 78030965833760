// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ActivityModel = {
  "MESSAGE": "MESSAGE"
};

const ChatUnitColorId = {
  "BLACK": "BLACK",
  "RED": "RED",
  "ORANGE": "ORANGE",
  "LIME": "LIME",
  "GREEN": "GREEN",
  "TURQUOISE": "TURQUOISE",
  "SKY": "SKY",
  "BLUE": "BLUE",
  "VIOLET": "VIOLET",
  "PURPLE": "PURPLE",
  "PINK": "PINK",
  "MAGENTA": "MAGENTA"
};

const ChatUnitType = {
  "TABLE": "TABLE",
  "ROOM": "ROOM",
  "QUICK_CHAT": "QUICK_CHAT"
};

const ChatUnitSubType = {
  "ONE_ON_ONE": "ONE_ON_ONE",
  "GROUP_CHAT": "GROUP_CHAT"
};

const InviteType = {
  "SENDER": "SENDER",
  "RECEIVER": "RECEIVER",
  "SENDERSHAREABLE": "SENDERSHAREABLE"
};

const InviteStatus = {
  "PENDING": "PENDING",
  "ACTIVE": "ACTIVE",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "DECLINED": "DECLINED",
  "ACCEPTED": "ACCEPTED"
};

const InviteTargetType = {
  "TABLE": "TABLE",
  "ROOM": "ROOM",
  "QUICK_CHAT": "QUICK_CHAT",
  "ORGANISATION": "ORGANISATION"
};

const Roles = {
  "OWNER": "OWNER",
  "MEMBER": "MEMBER",
  "ADMIN": "ADMIN",
  "GUEST": "GUEST"
};

const Statuses = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED",
  "PENDING": "PENDING"
};

const { Activity, ChatUnit, Invite, Conversation, Message, MessageV2, Organisation, User, ChatUnitUser, ChatUnitUserItems, OpenChatUnitResponse, CreateNewInviteResponse, ConversationMessages, MemberType, Usage } = initSchema(schema);

export {
  Activity,
  ChatUnit,
  Invite,
  Conversation,
  Message,
  MessageV2,
  Organisation,
  User,
  ChatUnitUser,
  ActivityModel,
  ChatUnitColorId,
  ChatUnitType,
  ChatUnitSubType,
  InviteType,
  InviteStatus,
  InviteTargetType,
  Roles,
  Statuses,
  ChatUnitUserItems,
  OpenChatUnitResponse,
  CreateNewInviteResponse,
  ConversationMessages,
  MemberType,
  Usage
};