import React from 'react';
import {
  ActivityIndicator,
  type ImageStyle,
  type StyleProp,
  Text,
  TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';

import { styles } from './styles';

import PhotoRollIcon from '@/assets/svg/photo_roll_icon_colored.svg';
import TrashIcon from '@/assets/svg/trash_icon.svg';
import Avatar from '@/components/avatar';
import type { Translate } from '@/translations/useTranslation';

type Props = {
  avatarImageKey: string;
  chooseImageFromLibrary: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  heading: string;
  isUploading: boolean;
  removeAvatar: () => void;
  translate: Translate;
};

export const AvatarHeader = ({
  avatarImageKey,
  chooseImageFromLibrary,
  containerStyle = {},
  heading,
  isUploading,
  removeAvatar,
  translate,
}: Props) => {
  return (
    <View style={[styles.header, containerStyle]}>
      <View style={styles.headerContent}>
        <View style={styles.headerTextContainer}>
          <Text style={styles.addPhotoText}>
            {translate(avatarImageKey ? 'edit_photo_text' : 'add_photo_text')}
          </Text>
        </View>
        <View style={styles.avatarContainer}>
          {avatarImageKey ? (
            <Avatar
              imageKey={avatarImageKey}
              displayName=""
              style={styles.avatarImage as ImageStyle}
            />
          ) : null}
          {isUploading && (
            <View style={styles.loader}>
              <ActivityIndicator />
            </View>
          )}
        </View>
        <View style={styles.avatarControls}>
          <TouchableOpacity
            onPress={chooseImageFromLibrary}
            style={styles.chooseFromGalleryButton}>
            <PhotoRollIcon />
          </TouchableOpacity>
          {avatarImageKey ? (
            <TouchableOpacity onPress={removeAvatar}>
              <TrashIcon />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
      <Text style={styles.setupText}>{heading}</Text>
    </View>
  );
};
