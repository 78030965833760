import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgLightningLgFlat = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}>
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M11.111 44.445 55.556 6.666 42.222 33.333l26.667 2.223-44.444 37.777 13.333-26.666z"
    />
  </Svg>
);
export default SvgLightningLgFlat;
