import { DateTime } from 'luxon';
import * as RNLocalize from 'react-native-localize';

const timezone = RNLocalize.getTimeZone();

export const getDiffMinutesFromNow = (timestamp?: Date) => {
  if (!timestamp) {
    return 0;
  }
  const now = DateTime.now().setZone(timezone);
  const dateTime = DateTime.fromJSDate(timestamp).setZone(timezone);
  return now.diff(dateTime, 'minutes').minutes;
};

export const formatActivityDate = (timestamp: Date): string => {
  const now = DateTime.now().setZone(timezone);
  const activityDate = DateTime.fromJSDate(timestamp).setZone(timezone);

  if (getDiffMinutesFromNow(timestamp) < 1) {
    return 'Just now';
  }

  if (now.hasSame(activityDate, 'day')) {
    return activityDate.toFormat('HH:mm');
  }

  if (now.minus({ days: 1 }).hasSame(activityDate, 'day')) {
    return 'Yesterday';
  }

  const diffDays = now.diff(activityDate, 'days').days;

  if (diffDays < 7) {
    return activityDate.toFormat('EEEE');
  }

  return activityDate.toFormat('dd.MM.yy');
};
