import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  showUhOhScreen: boolean;
};

type Actions = {
  clearState: () => void;
  setShowUhOhScreen: (show: boolean) => void;
};

const newState = {
  showUhOhScreen: false,
};

export const useErrorStore = create<State & Actions>()(
  immer(set => ({
    ...newState,
    clearState: () => {
      set(() => ({ ...newState }));
    },
    setShowUhOhScreen: (show: boolean) => {
      set(state => {
        state.showUhOhScreen = show;
      });
    },
  })),
);
