import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  type TextStyle,
  TouchableOpacity,
  type ViewStyle,
} from 'react-native';

import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const OutlinedButton = ({
  disabled = false,
  loading = false,
  onPress,
  style,
  title,
  titleStyle,
}: {
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
  style?: ViewStyle;
  title: string;
  titleStyle?: TextStyle;
}) => {
  const disabledStyle = { opacity: disabled ? 0.5 : 1 };
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      disabled={disabled}
      onPress={onPress}
      style={[styles.container, disabledStyle, style]}>
      {loading ? (
        <ActivityIndicator color="#fff" size="small" />
      ) : (
        <Text style={[styles.title, titleStyle]}>{title}</Text>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderColor: Greys.shade0,
    borderRadius: 20,
    borderWidth: 2,
    height: 32,
    justifyContent: 'center',
    marginTop: 20,
    width: web ? 200 : wp('80'),
  },
  title: { color: Greys.shade0, fontSize: 14, fontWeight: '400' },
});

export default OutlinedButton;
