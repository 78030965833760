import React from 'react';
import LinearGradient, {
  type LinearGradientProps,
} from 'react-native-linear-gradient';
import Animated, { type AnimateProps } from 'react-native-reanimated';

import { styles } from './styles';

const AnimdatedGradient = Animated.createAnimatedComponent(LinearGradient);

type Props = {
  hidden?: boolean;
} & AnimateProps<LinearGradientProps>;

export const AnimatedLinearGradient = ({ hidden, style, ...rest }: Props) =>
  hidden ? null : (
    <AnimdatedGradient
      {...rest}
      style={[style, styles.passThroughTouchEvent]}
    />
  );
