/* eslint-disable react-native/no-inline-styles */
import { yupResolver } from '@hookform/resolvers/yup';
import type { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, StatusBar, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { Terms } from '../intro/Terms';

import NotInvited from './components/NotInvited';
import SignInForm from './components/SignInForm';
import SignInTitle from './components/SignInTitle';
import useConnect from './useConnect';

import CountryPicker from '@/components/country-picker';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import { constants } from '@/constants';
import { Button } from '@/domain/theme/components/Button';
import type { TranslationKey } from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { formatPhoneNumber } from '@/utilities/helpers/formatPhoneNumber';

const signInFormSchema = (translate: TFunction<TranslationKey>) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .test(
        'is-valid-phone',
        translate('phone_number_is_invalid_text'),
        value => {
          return isPossiblePhoneNumber(value || '');
        },
      )
      .required(translate('phone_number_is_required_text')),
  });

export type SignInFormData = yup.InferType<ReturnType<typeof signInFormSchema>>;

const AddPhoneNumber = () => {
  const {
    error,
    goToWaitList,
    isSubmitting,
    newAccount,
    onSubmit,
    resetError,
    translate,
  } = useConnect();
  const resolver = yupResolver(signInFormSchema(translate), {
    abortEarly: false,
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const [selectedCountry, setSelectedCountry] = useState(
    constants.defaultCountry,
  );

  const [open, setOpen] = useState(false);

  const phoneNumber = getValues('phoneNumber');

  const tryOtherNumber = () => {
    reset({ phoneNumber: '' });
    resetError();
  };

  const onPhoneInputBlur = async () => {
    const phone = getValues('phoneNumber');

    if (!isPossiblePhoneNumber(phone)) {
      return;
    }

    const formattedPhoneNumber = formatPhoneNumber(phone);
    setValue('phoneNumber', formattedPhoneNumber);
  };

  return (
    <View onTouchStart={Keyboard.dismiss} style={styles.dismissable}>
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar barStyle="dark-content" />
        {!error ? (
          <>
            <SignInTitle newAccount={newAccount} />
            <View style={styles.divider40} />

            <View style={{ gap: 20, flex: 1, marginBottom: 40 }}>
              <KeyboardAwareScrollView
                contentContainerStyle={{ alignItems: 'center', maxWidth: 400 }}>
                <SignInForm
                  control={control}
                  country={selectedCountry}
                  errors={errors}
                  getValues={getValues}
                  onCountrySelect={() => setOpen(true)}
                  onPhoneInputBlur={onPhoneInputBlur}
                />
              </KeyboardAwareScrollView>

              <View style={{ gap: 20 }}>
                <Button
                  i18nKey="next_text"
                  busy={isSubmitting}
                  onPress={() => {
                    handleSubmit(onSubmit)();
                  }}
                />

                <Button
                  i18nKey="exclusive_access_add_phone_join_waitlist_title"
                  onPress={goToWaitList}
                  variant="link"
                />
              </View>
            </View>

            <View style={{ marginBottom: 40 }}>
              <Terms />
            </View>
          </>
        ) : (
          <>
            <NotInvited phoneNumber={phoneNumber} />

            <View style={styles.bottomContainer}>
              <View style={{ alignSelf: 'stretch' }}>
                <Button
                  i18nKey="exclusive_access_wait_list_button_title"
                  busy={isSubmitting}
                />
              </View>

              <Button
                i18nKey="eas_use_different_phone_number_button_title"
                onPress={tryOtherNumber}
                variant="link"
              />
            </View>

            <View style={{ marginBottom: 40 }}>
              <Terms />
            </View>
          </>
        )}

        <CountryPicker
          open={open}
          onCountrySelected={setSelectedCountry}
          selectedCountry={selectedCountry}
          setOpen={setOpen}
        />
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 20,
    paddingBottom: 20,
    paddingHorizontal: 40,
  },
  dismissable: { flex: 1 },
  divider10: { marginTop: 10 },
  divider20: { marginTop: 20 },
  divider40: { marginTop: 40 },
  screenContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: 1,
    paddingTop: 30,
  },
});

export default AddPhoneNumber;
