import { DataStore } from 'aws-amplify/datastore';

import { ChatUnit } from '../../../models';

import type { ChatUnit as IChatUnit } from '@/services/types';

export const updateTableUrl = async (chatUnit: IChatUnit, url: string) => {
  const currChatUnit = await DataStore.query(ChatUnit, chatUnit.id);
  if (!currChatUnit) {
    throw new Error('Unable to find chat unit');
  }
  await DataStore.save(
    ChatUnit.copyOf(currChatUnit as ChatUnit, updated => {
      updated.url = url;
    }),
  );
};
