import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgLightning = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M7.592 52.884a4.225 4.225 0 0 0 2.386 7.43l30.4 2.534-15.519 31.037a4.226 4.226 0 0 0 6.516 5.109l61.033-51.878a4.226 4.226 0 0 0-2.386-7.43l-30.4-2.533L75.141 6.115a4.224 4.224 0 0 0-6.516-5.109zm13.221-.147 37.511-31.883-9.052 18.102a4.226 4.226 0 0 0 3.429 6.1l26.486 2.207-37.511 31.884 9.052-18.102a4.226 4.226 0 0 0-3.429-6.101z" />
  </Svg>
);
export default SvgLightning;
