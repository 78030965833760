import messaging from '@react-native-firebase/messaging';

import { registerDeviceToken } from './registerDeviceToken';
import { unsubFromNotifications } from './unsubFromNotifications';

export async function updateTokenRegistration(
  userId: string | undefined,
  allowed: boolean,
) {
  if (!userId) return;

  if (allowed) {
    const token = await messaging().getToken();
    await registerDeviceToken(token, userId);
  } else {
    await unsubFromNotifications(userId);
  }
}
