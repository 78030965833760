import { defaultAvatarName } from '../assetService';

import { validateInput } from './validateInput';

import type { ChatUnitUser } from '@/services/types';

export const chatUnitUserFactory = (input: any): ChatUnitUser => {
  validateInput(input, ['id', 'userId', 'chatUnitId'], 'ChatUnitUser');

  return {
    allowedUserIds: input.allowedUserIds ?? [],
    avatar: input.avatar ?? defaultAvatarName,
    createdAt: input.createdAt,
    // NOTE: `id` value is from `user` model
    id: input.userId,
    owner: input.owner,
    chatUnitId: input.chatUnitId,
    chatUnitUserId: input.id,
    phoneNumber: input.phoneNumber ?? '',
    // TODO: HZN-857 remove active fallback as status will be required
    status: input.status ?? 'ACTIVE',
    updatedAt: input.updatedAt,
    username: input.username ?? 'Unknown User',
    firstName: input.firstName ?? '',
    lastName: input.lastName ?? '',
  };
};
