import React from 'react';
import { StyleSheet, Text } from 'react-native';
import Reanimated, { FadeIn, FadeOut } from 'react-native-reanimated';

import { useStickyDateStore } from '@/domain/conversation/state/useStickyDateStore';
import { Greys } from '@/ui/common/colors';

type Props = {
  conversationId: string;
  showStickyDate: boolean;
};

export const StickyDate = ({ conversationId, showStickyDate }: Props) => {
  const currentDate = useStickyDateStore(state =>
    state.getConversationStickyDate(conversationId),
  );

  if (!currentDate || !showStickyDate) {
    return null;
  }

  return currentDate ? (
    <Reanimated.View
      entering={FadeIn}
      exiting={FadeOut.delay(500)}
      style={styles.stickyDate}>
      <Text style={[styles.stickyDateText, { color: Greys.shade600 }]}>
        {currentDate}
      </Text>
    </Reanimated.View>
  ) : null;
};

const styles = StyleSheet.create({
  stickyDate: {
    alignSelf: 'center',
    backgroundColor: Greys.shade0,
    borderRadius: 20,
    elevation: 5,
    paddingHorizontal: 12,
    paddingVertical: 4.5,
    position: 'absolute',
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    zIndex: 2000000,
    top: 25,
  },
  stickyDateText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 11,
    fontWeight: '700',
  },
});
