import {
  type Post,
  PostsDocument,
  type PostsQueryResult,
} from '@/domain/cms/graphql/generated/graphql';
import { useTableStore } from '../state/useTableStore';
import { useTableUsersStore } from '../state/useTableUsersStore';

import type { ChatUnitType } from '@/API';
import { apolloClient } from '@/domain/cms/CMSModule';
import useCMSStore from '@/domain/cms/useCMSStore';
import { getBatchConversationMessages } from '@/domain/conversation/services/getBatchConversationMessages';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import useUserStore from '@/domain/user/state/useUserStore';
import type { Message } from '@/services/chatUnit/types';
import { getChatUnits } from '@/services/datastore/chatUnit/getChatUnits';
import { getConversations } from '@/services/datastore/conversation/getConversations';
import { getInvites } from '@/services/datastore/invite/getInvites';
import { getChatUnitUsers } from '@/services/datastore/user/getChatUnitUsers';
import { getDbUser } from '@/services/datastore/user/getDbUser';
import { logger } from '@/services/logger/logger';
import { web } from '@/utilities/platform';
import Config from 'react-native-config';

const refreshChatUnits = async (type?: ChatUnitType) => {
  try {
    const upsertTables = useTableStore.getState().upsertTables;
    const lastUpdated = useTableStore.getState().lastUpdated;
    const setLastUpdated = useTableStore.getState().setLastUpdated;
    upsertTables(await getChatUnits({ type, lastUpdated }));
    setLastUpdated(new Date());
  } catch (e) {
    logger.error('refreshTablesError', e);
  } finally {
  }
};

const refreshConversations = async (tableId?: string | undefined) => {
  const startTime = Date.now();
  logger.debug('refreshConversations::start');
  try {
    const upsertConversations =
      useConversationStore.getState().upsertConversations;
    const lastUpdated = useConversationStore.getState().lastUpdated;
    const setLastUpdated = useConversationStore.getState().setLastUpdated;
    const setConversationData =
      useConversationStore.getState().setConversationData;
    // update conversations in store
    const conversations = await getConversations(tableId, lastUpdated);
    setLastUpdated(new Date());
    if (tableId) {
      upsertConversations([{ chatUnitId: tableId, conversations }]);
    } else {
      // create batches of conversations by chatUnitId
      const chatUnitConversations: Parameters<typeof setConversationData>[0] =
        new Map();
      for (const conversation of conversations) {
        const chatUnitId = conversation.chatUnitId;
        const existingConversations = chatUnitConversations.get(chatUnitId);
        if (existingConversations) {
          existingConversations.set(conversation.conversationId, conversation);
        } else {
          chatUnitConversations.set(
            chatUnitId,
            new Map([[conversation.conversationId, conversation]]),
          );
        }
      }

      const conversationArray = Array.from(
        chatUnitConversations,
        ([chatUnitId, conversationMap]) => ({
          chatUnitId: chatUnitId,
          conversations: Array.from(conversationMap, ([_, value]) => value),
        }),
      );

      upsertConversations(conversationArray);
    }
  } catch (e) {
    logger.error('refreshConversationsError', e);
  } finally {
    logger.debug(
      `refreshConversations::Complete ${
        (Date.now() - startTime) / 1000
      } seconds`,
    );
  }
};

const refreshChatUsers = async () => {
  try {
    const setTableUserIds = useTableUsersStore.getState().setTableUserIds;
    const addUsers = useTableUsersStore.getState().addUsers;
    const lastUpdated = useTableUsersStore.getState().lastUpdated;
    const setLastUpdated = useTableUsersStore.getState().setLastUpdated;

    const tableUsers = await getChatUnitUsers(lastUpdated);
    addUsers(tableUsers);

    setTableUserIds(tableUsers);
    setLastUpdated(new Date());
  } catch (e) {
    logger.error('refreshTableUsersError', e);
  }
};

const refreshInvites = async () => {
  try {
    const lastUpdated = useMyInviteStore.getState().lastUpdated;
    const setLastUpdated = useMyInviteStore.getState().setLastUpdated;
    const invites = await getInvites(lastUpdated);
    useMyInviteStore.getState().addInvites(invites);
    setLastUpdated(new Date());
  } catch (e) {
    logger.error('refreshInvitesError', e);
  }
};

// Backend will fetch messages in batches of 5, and all messages for a conversation
const CONVERSATION_BATCH_SIZE = 25;
const MESSAGES_PER_CONVERSATION = 40; // 0 means all messages

const seedActivities = async (userId: string) => {
  const start = Date.now();
  let messageCount = 0;
  let nextTokens: string[] = [];

  const myChatUsers = useTableUsersStore.getState().myChatUsers;
  const addMessages = useMessageStore.getState().addMessages;
  // harcod to '1970-01-01T00:00:00.000Z' for testing
  // bring down everything everytime for web because AsynStorage hists its limit and you cant store all data in web
  const lastMessageTime = web
    ? '1970-01-01T00:00:00.000Z'
    : useMessageStore.getState().lastMessageTime;
  const setLoadingMessages = useMessageStore.getState().setLoadingMessages;

  try {
    if (!userId) {
      console.warn('seedActivities: userId is required');
      return;
    }
    setLoadingMessages(true);
    // get all existing conversations
    // get the most recent message for each conversation and add to store
    let chatUnitIdConversationIds = (await getConversations())
      .filter(c => {
        const chatUnitUser = myChatUsers.get(c.chatUnitId);
        return (
          chatUnitUser?.status !== 'INACTIVE' &&
          new Date(c.updatedAt!) > new Date(lastMessageTime)
        );
      })
      .sort(
        // sort by updated at so that you bring down the most recent conversation's data first
        (a, b) =>
          new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime(),
      )
      .map(c => `${c.chatUnitId}#${c.conversationId}`);

    // do {
    const pendingRequests = [];
    for (
      let i = 0;
      i < chatUnitIdConversationIds.length;
      i += CONVERSATION_BATCH_SIZE
    ) {
      const batchIds = chatUnitIdConversationIds.slice(
        i,
        i + CONVERSATION_BATCH_SIZE,
      );
      const tokenBatches = nextTokens.slice(i, i + CONVERSATION_BATCH_SIZE);
      pendingRequests.push(
        getBatchConversationMessages(
          batchIds,
          lastMessageTime,
          tokenBatches,
          MESSAGES_PER_CONVERSATION,
        ),
      );
    }

    chatUnitIdConversationIds = [];
    nextTokens = [];

    const results = await Promise.all(pendingRequests);

    const messages: Message[] = results
      ?.flat()
      ?.flatMap(r => {
        if (r?.nextToken) {
          chatUnitIdConversationIds.push(
            `${r?.chatUnitId}#${r?.conversationId}`,
          );
          nextTokens.push(r?.nextToken);
        }
        return r?.messages;
      })
      .filter(Boolean) as Message[];

    messageCount += messages.length;
    addMessages({ messages });
    // } while (chatUnitIdConversationIds.length > 0 && nextTokens.length > 0);
  } catch (e) {
    logger.error('seedActivities', e);
  } finally {
    setLoadingMessages(false);
    logger.debug('seedActivities::complete', {
      seconds: (Date.now() - start) / 1000,
      messageCount,
    });
  }
};

const refreshUser = async () => {
  const setUser = useUserStore.getState().setUser;
  const userId = useUserStore.getState().user?.id!;
  // const users = await getUsers();
  // if (users.length >= 1 && users?.[0]) {
  //   setUser(users?.[0]);
  // }
  const dbUser = await getDbUser(userId);
  if (dbUser) {
    setUser(dbUser);
  }
};

/**
 * Refreshes the CMS posts by querying the server and updating the state.
 * This function retrieves the posts from the server using Apollo Client,
 * updates the post IDs in the CMS store, and identifies critical and unread posts.
 */
const refreshCMSPosts = async () => {
  const { data } = (await apolloClient.query({
    query: PostsDocument,
  })) as PostsQueryResult;
  // Filter out posts that are not in production in production environment
  const posts =
    Config.ENV === 'prod'
      ? data?.postCollection?.items.filter(post => post.production)
      : data?.postCollection?.items ?? [];
  useCMSStore.getState().setPosts(posts as Post[]);
};

const RefreshService = {
  refreshChatUnits,
  refreshConversations,
  refreshChatUsers,
  refreshInvites,
  seedActivities,
  refreshUser,
  refreshCMSPosts,
};

export default RefreshService;
