import { DataStore } from 'aws-amplify/datastore';

import { ChatUnit, ChatUnitColorId } from '../../../models';
import type { ChatUnit as IChatUnit } from '../../chatUnit/types';
import { factory } from '../../factory/factory';

import { DEFAULT_CHAT_UNIT_PICKER_IMAGE } from '@/utilities/constants/chat-unit-background-images';

export type ChatUnitInput = Partial<
  Pick<
    IChatUnit,
    'title' | 'description' | 'colorId' | 'backgroundImage' | 'url'
  >
> &
  Pick<IChatUnit, 'type' | 'allowedUserIds' | 'organisationId'>;

export const createChatUnit = async (input: ChatUnitInput) => {
  const chatUnitData = {
    backgroundImage: input.backgroundImage ?? DEFAULT_CHAT_UNIT_PICKER_IMAGE,
    colorId: input.colorId ?? ChatUnitColorId.RED,
    title: 'Default Title',
    ...input,
  };

  const result = await DataStore.save(new ChatUnit(chatUnitData));
  return factory.chatUnit(result);
};
