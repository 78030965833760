import React, { useMemo } from 'react';
import {
  Dimensions,
  Pressable,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, {
  FadeIn,
  FadeOut,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { useChatUnitViewLoadingTimeoutPrompt } from '../../hooks/useChatUnitViewLoadingTimeoutPrompt';
import { MessageList } from '../view/MessageList';

import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import LoadingBar from '@/components/loaders/LoadingBar';
import LottieLoader from '@/components/loaders/LottieLoader';
import { NoNetworkBanner } from '@/components/network/NoNetworkBanner';
import { useChatUnit } from '@/domain/chatUnit/hooks/useTable';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { Colors } from '@/domain/theme/Colors';
import { TableBackIcon } from '@/domain/theme/components/Icon/icons/TableBackIcon';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import type { MainStackScreenProps } from '@/navigation/types';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { AppText } from '@/ui/app';
import SvgArchiveSmall from '@/ui/app/elements/Icon/icons/ArchiveSmall';
import SvgDeleted from '@/ui/app/elements/Icon/icons/Deleted';
import { Greys } from '@/ui/common/colors';

const linearGradientColors = ['rgba(0,0,0,0.2)', 'rgba(0,0,0,0)'];

export type ViewProps = MainStackScreenProps<'ArchivedConversation'>;

const AnimatedView = Animated.createAnimatedComponent(View);

const ArchivedConversation = ({ route }: ViewProps) => {
  const { goBack, navigate } = useNavigation();
  const { conversationId, chatUnitId } = route.params;
  const { loading, chatUnit } = useChatUnit(chatUnitId);

  const conversation = useConversationStore(
    useShallow(state => state.getConversation(chatUnitId, conversationId!)),
  );

  const offset = useSharedValue(0);

  useChatUnitViewLoadingTimeoutPrompt({
    loading,
    hasChatUnit: !!chatUnit,
    navigate,
    type: chatUnit?.type,
  });
  const loadingMessages = useMessageStore(
    useShallow(state => state.loadingMessages),
  );

  const myTableUser = useTableUsersStore(
    useShallow(state => state.myChatUsers.get(chatUnitId)),
  );

  const { top, bottom } = useSafeAreaInsets();

  const getAllTableUsersForTable = useTableUsersStore(
    useShallow(state => state.getAllTableUsersForTable),
  );

  const chatUsers = useMemo(() => {
    return getAllTableUsersForTable(chatUnitId).filter(user =>
      conversation?.chatUnitUserIds?.includes(user.chatUnitUserId),
    );
  }, [chatUnitId, conversation, getAllTableUsersForTable]);

  const userId = useUserStore(state => state.user?.id);

  if (loading || !chatUnit) {
    return (
      <View style={styles.loader}>
        <LottieLoader />
      </View>
    );
  }

  return (
    <>
      <View style={styles.imgContainer}>
        <ChatUnitBackgroundImage
          imageKey={chatUnit.backgroundImage}
          height={Dimensions.get('window').height}
          width="100%"
        />
      </View>
      <AnimatedView entering={FadeIn} exiting={FadeOut}>
        <LinearGradient
          colors={[Colors.neutral100, 'rgba(0, 0, 0, 0)']}
          end={{ x: 0.0, y: 4.0 }}
          start={{ x: 0.0, y: 0.0 }}
          style={styles.linearGradient}
        />
      </AnimatedView>

      <View style={[styles.container, { top }]}>
        <StatusBar barStyle="light-content" />
        <View style={[styles.headerContainer]}>
          <View style={styles.backContainer}>
            <Pressable onPress={goBack}>
              <TableBackIcon color={Colors.neutral0} />
            </Pressable>
            <View style={styles.textContainer}>
              <AppText color={Greys.shade0} type="primary700">
                {chatUnit.title}
              </AppText>
              <View style={styles.headerChip}>
                <AppText style={styles.headerChipText}>
                  Archived Conversation
                </AppText>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.chipContainer}>
          <View style={[styles.chip, styles.yourChip]}>
            <AppText style={[styles.chipText, styles.yourChipText]}>
              You
            </AppText>
          </View>
          {chatUsers
            .filter(chatUser => chatUser.id !== userId)
            .map(user => {
              return (
                <View
                  key={user.id}
                  style={
                    user.status === ChatUnitUserStatus.ARCHIVED
                      ? styles.chipRemoved
                      : styles.chip
                  }>
                  {user.status === ChatUnitUserStatus.ARCHIVED && (
                    <SvgDeleted
                      width={10}
                      height={10}
                      style={styles.deletedIcon}
                    />
                  )}
                  <AppText
                    key={user.id}
                    style={
                      user.status === ChatUnitUserStatus.ARCHIVED
                        ? styles.chipTextRemoved
                        : styles.chipText
                    }>
                    {user.username}
                  </AppText>
                </View>
              );
            })}
        </View>

        <NoNetworkBanner />

        <View style={styles.pagerContainer}>
          <LinearGradient
            colors={linearGradientColors}
            end={{ x: 0, y: 1 }}
            start={{ x: 0, y: 0 }}
            style={styles.topShadow}
          />
          <LoadingBar
            hidden={!loadingMessages}
            containerStyles={styles.loadingBar}
          />
          <View style={styles.container}>
            <MessageList
              chatUnitId={chatUnitId}
              conversationId={conversationId!}
              index={0}
              myTableUserId={myTableUser?.chatUnitUserId}
              offset={offset}
            />
          </View>
          <View style={[styles.footer, { marginBottom: bottom }]}>
            <View style={styles.flexRow}>
              <AppText style={styles.footerTitle}>
                This conversation has been
              </AppText>
              <SvgArchiveSmall />
              <AppText style={styles.footerTitle}>Archived</AppText>
            </View>

            <AppText style={styles.footerDesc}>
              Some participants are no longer members of this Table
            </AppText>
          </View>
        </View>
      </View>
    </>
  );
};

export default ArchivedConversation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    width: '100%',
    zIndex: 100,
  },
  image: {
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    height: 130,
    width: '100%',
  },
  linearGradient: {
    opacity: 0.5,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    height: 135,
  },
  loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },
  imgContainer: {
    height: 135,
    width: '100%',
    zIndex: -2,
    position: 'absolute',
  },
  backContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 10000,
    gap: 16,
  },
  textContainer: {
    justifyContent: 'center',
    transform: [{ translateY: 10 }],
  },
  bottomOverlay: {
    backgroundColor: Greys.shade600,
    bottom: 0,
    height: 45,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
  },
  pagerContainer: {
    backgroundColor: Greys.shade0,
    flex: 1,
  },
  topShadow: {
    height: 90,
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  },
  loadingBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  footer: {
    height: 90,
    backgroundColor: Colors.neutral10,
    justifyContent: 'flex-start',
    paddingTop: 10,
    alignItems: 'center',
  },
  footerTitle: {
    fontWeight: '700',
    fontSize: 12,
    marginHorizontal: 4,
  },
  footerDesc: {
    fontSize: 12,
    color: Colors.neutral70,
  },
  deletedIcon: {
    marginRight: 5,
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: Greys.shade600,
    width: '100%',
    padding: 5,
    marginTop: 26,
  },
  chip: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.neutral0,
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    margin: 5,
    paddingHorizontal: 8,
  },
  yourChip: {
    backgroundColor: Colors.neutral0,
  },
  chipRemoved: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.melon,
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    marginHorizontal: 5,
    flexDirection: 'row',
    paddingHorizontal: 5,
  },

  chipText: {
    color: Colors.neutral0,
    fontSize: 12,
    fontWeight: '600',
  },
  yourChipText: {
    color: Colors.neutral80,
  },
  chipTextRemoved: {
    color: Colors.melon,
    fontSize: 12,
    fontWeight: '700',
  },
  headerChip: {
    borderColor: Colors.neutral0,
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 8,
    paddingVertical: 3.5,
    backgroundColor: Colors.neutral90,
    marginTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerChipText: { color: Colors.neutral0, fontSize: 10, fontWeight: '400' },
});
