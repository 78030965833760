import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import { useShallow } from 'zustand/react/shallow';

import { SettingsSubtitle } from './SettingsSubtitle';

import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { Colors } from '@/domain/theme/Colors';
import useNavigation from '@/hooks/useNavigation';
import useTranslations from '@/translations/useTranslation';
import SvgArchive from '@/ui/app/elements/Icon/icons/Archive';
import { Greys } from '@/ui/common/colors';
import { pluralize } from '@/utilities/helpers/pluralize';

type Props = {
  chatUnitId: string;
};

export const ViewArchivedConversations = ({ chatUnitId }: Props) => {
  const { translate } = useTranslations();
  const { navigate } = useNavigation();

  const [getArchivedConversationsByTableId, getConversationsByTableId] =
    useConversationStore(
      useShallow(state => [
        state.getArchivedConversationsByTableId,
        state.getConversationsByTableId,
      ]),
    );

  const archivedConversations =
    getArchivedConversationsByTableId(chatUnitId).length;

  const activeConversations =
    getConversationsByTableId(chatUnitId).length - archivedConversations;

  return (
    <TouchableHighlight
      onPress={() =>
        navigate('ArchivedConversations', { chatUnitId: chatUnitId })
      }>
      <>
        <SettingsSubtitle
          subtitle={
            pluralize(activeConversations, translate('active_conversation')) +
            ` • ${archivedConversations} ` +
            translate('archived')
          }
          title={translate('CONVERSATIONS')}
        />
        <View style={styles.userCard}>
          <SvgArchive height={50} width={50} />
          <View style={styles.labelContainer}>
            <Text style={styles.label}>
              {translate('view') +
                ' ' +
                pluralize(
                  archivedConversations,
                  translate('archived_conversation'),
                )}
            </Text>
          </View>
        </View>
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  label: {
    color: Colors.primaryLight,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '700',
  },
  roleLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 9,
    fontWeight: '700',
  },
  userCard: {
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  labelContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 20,
  },
});
