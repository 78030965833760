import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import type { ChatUnitType } from '../../../../../API';

import Avatar from '@/components/avatar';
import { type MemberType, Roles } from '@/models';
import type { ChatUnitUser } from '@/services/types';
import { primaryBlue } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
type Props = {
  avatar: string;
  isTableOwner: boolean;
  participantsCount: number;
  type: ChatUnitType;
  userName: string;
  orgMembers: (MemberType | null)[];
  orgId?: string;
  tableUser?: ChatUnitUser;
};

const MyUserCard = ({
  avatar,
  isTableOwner,
  userName,
  orgMembers,
  orgId,
  tableUser,
}: Props) => {
  const { translate } = useTranslations();
  const RoleLabels = {
    [Roles.OWNER]: 'owner_label',
    [Roles.MEMBER]: 'member_label',
    [Roles.GUEST]: 'guest_label',
    [Roles.ADMIN]: 'admin_label',
  };

  const roleName = orgId
    ? translate(
        RoleLabels[
          orgMembers?.find(m => m?.userId === tableUser?.id)?.role ??
            Roles.MEMBER
        ],
      )
    : translate('member_label');
  return (
    <>
      <View style={styles.userCard}>
        <Avatar imageKey={avatar} displayName={userName} size={50} />
        <View style={styles.userDetails}>
          <View style={styles.nameRow}>
            <Text style={styles.nameLabel}>{userName}</Text>
            <View style={styles.dot} />
            <Text style={styles.youLabel}>{translate('table_you_label')}</Text>
          </View>
        </View>
        <View style={isTableOwner ? styles.owner : styles.member}>
          <Text
            style={isTableOwner ? styles.roleLabel : styles.roleLabelMember}>
            {isTableOwner ? translate('owner_label') : roleName}
          </Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  dot: {
    backgroundColor: primaryBlue,
    borderRadius: 2.5,
    height: 5,
    marginHorizontal: 6,
    marginTop: 2,
    width: 5,
  },
  member: {
    alignSelf: 'center',
    borderColor: Greys.shade200,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  nameLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '700',
  },
  nameRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  owner: {
    alignSelf: 'center',
    borderColor: Greys.shade400,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  roleLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 9,
    fontWeight: '700',
  },
  roleLabelMember: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 9,
    fontWeight: '400',
  },
  userCard: {
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  userDetails: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 20,
  },
  youLabel: {
    color: primaryBlue,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
  },
});

export default MyUserCard;
