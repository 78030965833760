import type { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import React from 'react';
import { Pressable, Text, View } from 'react-native';

import { TabBarIcon } from '../TabBarIcon/TabBarIcon';

import PrimaryHeader from '@/components/headers/PrimaryHeader';

type Route = { name: string; route: string; displayName: string };

const RouteButtons: Route[] = [
  { name: 'DMs', route: 'DMs', displayName: 'DMs' },
  { name: 'Tables', route: 'Tables', displayName: 'Tables' },
  { name: 'Rooms', route: 'Rooms', displayName: 'Rooms' },
  { name: 'Discover', route: 'Discover', displayName: 'Discover' },
];

const SideRouteButton = ({
  item,
  navigation,
}: {
  item: Route;
  navigation: DrawerNavigationHelpers;
}) => {
  return (
    <>
      <Pressable
        style={styles.routeButton}
        onPress={() => {
          //SideRouteButton is only web.
          navigation.navigate('SplitView', { route: item.route });
        }}>
        <TabBarIcon name={item?.name} isFocused={false} size={30} />
        <Text style={styles.routeButtonText}>{item.displayName}</Text>
      </Pressable>
    </>
  );
};

const SideBar = ({ navigation }: { navigation: DrawerNavigationHelpers }) => {
  return (
    <View style={styles.sideBarContainer}>
      <View style={styles.logoContainer}>
        <PrimaryHeader
          hasShadow={false}
          rightActionVisible={false}
          isInSidebar
        />
      </View>
      <View style={styles.routeButtonContainer}>
        {RouteButtons.map(item => (
          <SideRouteButton
            item={item}
            navigation={navigation}
            key={item?.name}
          />
        ))}
      </View>
    </View>
  );
};

const styles: any = {
  sideBarContainer: {
    backgroundColor: 'white',
    height: '100%',
    alignItems: 'center',
  },
  logo: {},
  ruler: { width: '100%' },
  logoContainer: { padding: 10 },
  profileText: { textAlign: 'center', fontWeight: 700 },
  routeButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    textAlign: 'center',
  },
  routeButton: {
    marginTop: '22px',
    padding: '7px',
    textAlign: 'center',
    maxWidth: '65px',
    alignItems: 'center',
  },

  routeButtonText: {
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 650,
    marginTop: '5px',
  },
  routeButtonIcon: { marginLeft: 'auto', marginRight: 'auto' },
};

export { SideBar };
