import type { GraphQLQuery } from 'aws-amplify/api';

import { processMessageResults } from './processMessageResults';

import type { GetBatchConversationMessagesQuery } from '@/API';
import client from '@/services/API/client';
import { getBatchConversationMessages as getBatchConversationMessagesQuery } from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

type ConversationMessages = {
  chatUnitId: string;
  conversationId: string;
  nextToken: string;
  messages: any;
};

export const getBatchConversationMessages = async (
  chatUnitIdConversationIds: string[],
  lastMessageTime: string,
  nextTokens: string[],
  limit: number,
) => {
  try {
    let results: ConversationMessages[] = [];
    const queryResults = await client.graphql<
      GraphQLQuery<GetBatchConversationMessagesQuery>
    >({
      query: getBatchConversationMessagesQuery,
      variables: {
        chatUnitIdConversationIds,
        lastMessageTime,
        nextTokens,
        limit,
      },
    });

    if (
      queryResults &&
      queryResults.data &&
      queryResults.data.getBatchConversationMessages
    ) {
      results = queryResults.data.getBatchConversationMessages.reduce(
        (acc, conversationMessages) => {
          if (!conversationMessages) {
            return acc;
          }
          acc.push({
            ...conversationMessages,
            messages: processMessageResults(conversationMessages?.messages),
          });
          return acc;
        },
        [] as any,
      );
    } else if (queryResults.errors) {
      throw queryResults.errors;
    }

    return results;
  } catch (e) {
    logger.error('getBatchConversationMessages', e);
    return [];
  }
};
