import React from 'react';
import { StyleSheet } from 'react-native';

import type { Details } from '../types';

import { SettingsSubtitle } from './SettingsSubtitle';

import TableColorPicker from '@/components/table-color-picker';
import { ChatUnitType } from '@/domain/invites/types/types';
import type { ChatUnit } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const ColorPickerTypeText = {
  color_explanation: {
    [ChatUnitType.QUICK_CHAT]: 'dm_color_explanation',
    [ChatUnitType.TABLE]: 'table_color_explanation',
    [ChatUnitType.ROOM]: 'room_color_explanation',
  },
  colour_label: {
    [ChatUnitType.QUICK_CHAT]: 'dm_colour_label',
    [ChatUnitType.TABLE]: 'table_colour_label',
    [ChatUnitType.ROOM]: 'room_colour_label',
  },
};

interface ColorPickerProps {
  currentChatUnit: ChatUnit;
  selectedColor: ChatUnit['colorId'];
  setCurrentChatUnit: (input: Details) => void;
  chatUnitType: ChatUnitType;
}
const ColorPicker = ({
  currentChatUnit,
  selectedColor,
  setCurrentChatUnit,
  chatUnitType,
}: ColorPickerProps) => {
  const { translate } = useTranslations();
  return (
    <>
      <SettingsSubtitle
        subtitle={translate(
          ColorPickerTypeText.color_explanation[chatUnitType],
        )}
        title={translate(ColorPickerTypeText.colour_label[chatUnitType])}
      />
      <TableColorPicker
        containerStyle={styles.colorPicker}
        isWhiteMode={false}
        onChange={color =>
          setCurrentChatUnit({ ...currentChatUnit, colorId: color.id })
        }
        value={selectedColor}
      />
    </>
  );
};

const styles = StyleSheet.create({
  colorPicker: {
    backgroundColor: Greys.shade0,
    marginTop: 0,
  },
});

export default ColorPicker;
