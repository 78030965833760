import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgTelephone = (props: SvgProps) => (
  <Svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M2.667 8.889V4.667a2 2 0 0 1 2-2H7.97c1.116 0 2.016.912 2 2.028l-.027 2.194a2.023 2.023 0 0 1-2.018 2h-.111c-.552 0-1.006.45-.94.998a8.3 8.3 0 0 0 7.238 7.239c.548.065.998-.389.998-.941v-1.111a1 1 0 0 1 1-1h3.222a2 2 0 0 1 2 2v3.26a2 2 0 0 1-1.999 2h-4.223c-6.873 0-12.444-5.572-12.444-12.445Z"
      stroke="#3F4242"
      strokeWidth={1.5}
    />
  </Svg>
);
export default SvgTelephone;
