import { v5 as uuidv5 } from 'uuid';

import { getConstant } from '@/utilities/constants/getConstant';

export function getConversationIdFromUserIds(
  userIds: string[],
  namespace = getConstant('NAMESPACE') as string,
): string {
  if (userIds.length === 0) {
    throw new Error('Cannot create conversation without users');
  }

  return uuidv5(userIds.sort().toString(), namespace);
}
