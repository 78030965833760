import type { GraphQLQuery } from 'aws-amplify/api';

import { processMessageResults } from './processMessageResults';

import {
  type ListMessageV2sQueryVariables,
  type MessagesByChatUnitIdConversationIdV2Query,
  type MessagesByChatUnitIdConversationIdV2QueryVariables,
  type MessagesByConversationIdV2QueryVariables,
  type MessagesByUserIdV2Query,
  type MessagesByUserIdV2QueryVariables,
  ModelSortDirection,
} from '@/API';
import client from '@/services/API/client';
import type { Message } from '@/services/chatUnit/types';
import {
  messagesByChatUnitIdConversationIdV2,
  messagesByUserIdV2,
} from '@/services/graphql/queries';
import { logger } from '@/services/logger/logger';

type Props = {
  chatUnitId?: string;
  conversationId?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  userId: string;
};

export const getMessages = async (props: Props): Promise<Message[]> => {
  const { conversationId, chatUnitId } = props;
  const variables = initCommonVars(props);

  try {
    let messages = [];
    if (chatUnitId && conversationId) {
      messages = await getMessagesForConversation({
        ...variables,
        chatUnitIdConversationId: `${chatUnitId}#${conversationId}`,
      });
    } else {
      messages = await getMessageForOwner(variables);
    }

    return processMessageResults(messages);
  } catch (err) {
    logger.error('ERROR::conversationService::getMessages', err);
    throw err;
  }
};

const initCommonVars = ({
  conversationId,
  dateFrom,
  dateTo,
  limit,
  userId,
}: Props) => {
  const variables: ListMessageV2sQueryVariables &
    Partial<MessagesByConversationIdV2QueryVariables> & {
      userId: string;
    } = {
    userId,
    sortDirection: ModelSortDirection.DESC,
  };

  if (conversationId) {
    variables.conversationId = conversationId;
  }

  if (dateFrom && dateTo) {
    variables.SK = {
      between: [`${userId}#${dateFrom}`, `${userId}#${dateTo}`],
    };
  } else if (dateFrom) {
    // need to guard on next possible prefix (userId) as we connot have multiple
    // SK clauses (e.g. beginsWith & gt)
    const nextUserId = userId.replace(
      /.$/,
      String.fromCharCode(userId.charCodeAt(userId.length - 1) + 1),
    );
    variables.SK = { between: [`${userId}#${dateFrom}`, nextUserId] };
  } else if (dateTo) {
    // need to guard on prev possible prefix (userId) as we connot have multiple
    // SK clauses (e.g. beginsWith & lt)
    const prevUserId = userId.replace(
      /.$/,
      String.fromCharCode(userId.charCodeAt(userId.length - 1) - 1),
    );
    variables.SK = {
      between: [prevUserId, `${userId}#${dateTo}`],
    };
  } else {
    variables.SK = { beginsWith: userId };
  }

  if (limit) {
    variables.limit = limit;
  }

  return variables;
};

const getMessageForOwner = async (
  variables: MessagesByUserIdV2QueryVariables,
) => {
  const results = [];
  const response = await client.graphql<GraphQLQuery<MessagesByUserIdV2Query>>({
    query: messagesByUserIdV2,
    variables,
  });

  if (response && response.data && response.data.messagesByUserIdV2) {
    for (const message of response.data?.messagesByUserIdV2.items) {
      if (message) {
        results.push(message);
      }
    }
  } else if (response.errors) {
    throw response.errors;
  }

  return results;
};

const getMessagesForConversation = async (
  variables: MessagesByChatUnitIdConversationIdV2QueryVariables & {
    userId: string;
  },
) => {
  const results = [];
  const response = await client.graphql<
    GraphQLQuery<MessagesByChatUnitIdConversationIdV2Query>
  >({
    query: messagesByChatUnitIdConversationIdV2,
    variables,
  });

  if (
    response &&
    response.data &&
    response.data.messagesByChatUnitIdConversationIdV2
  ) {
    for (const message of response.data?.messagesByChatUnitIdConversationIdV2
      ?.items) {
      if (message) {
        results.push(message);
      }
    }
  } else if (response.errors) {
    throw response.errors;
  }

  return results;
};
