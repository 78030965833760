export type { ChatUnit } from './chatUnit/types';
export type { ChatUnitUser } from './chatUnitUser/types';

export type { Conversation } from './chatUnit/types';

export type { ChatMessage } from './chatUnit/types';

export type PaginatedResponse<T> = {
  data: T[];
  nextToken?: string;
};

export type ListViewMode = 'grid' | 'list';

export enum InviteSlideType {
  RECEIVED = 0,
  SENT = 1,
  WORKSPACES = 2,
}
