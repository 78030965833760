import React from 'react';

import { ChatUnitType } from '../../../../API';
import { DMList } from '../dm/list/DMList';

import { ChatGrid } from './ChatGrid';

import type { ChatUnit } from '@/services/index';
import { logger } from '@/services/logger/logger';

type Props = {
  isLoading: boolean;
  items: ChatUnit[];
  type: ChatUnitType;
};

export const ChatItems = React.memo(({ type }: Props) => {
  if (type === ChatUnitType.ROOM || type === ChatUnitType.TABLE) {
    return <ChatGrid type={type} />;
  }

  if (type === ChatUnitType.QUICK_CHAT) {
    return <DMList />;
  }

  logger.error('ChatItems::Invalid type', type);
  return null;
});
