import { android_requestUserPermission } from './android/android_requestUserPermission';
import { ios_requestUserPermission } from './ios/ios_requestUserPermission';

import { android, ios } from '@/utilities/platform';

export const checkAndRequestPushNotificationsPermissions = async () => {
  // await messaging().registerDeviceForRemoteMessages(); Not needed as it is auto registered
  if (ios) return await ios_requestUserPermission();
  if (android) return await android_requestUserPermission();
  return false;
};
