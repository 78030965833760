import { BlurView } from '@react-native-community/blur';
import React from 'react';
import type { StyleProp } from 'react-native';
import Animated from 'react-native-reanimated';

const AnimatedView = Animated.createAnimatedComponent(BlurView);

type Props = {
  hidden?: boolean;
  style?: StyleProp<any>;
};

export const AnimatedBlurView = ({ hidden, style }: Props) =>
  hidden ? null : (
    <AnimatedView blurAmount={10} blurType="light" style={style} />
  );
