import React from 'react';

import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';

export default function ChooseAvatarHeaderTitle() {
  const { translate } = useTranslations();

  return (
    <AppText type="primary700" size={14}>
      {translate('choose_avatar_title')}
    </AppText>
  );
}
