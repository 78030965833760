import React from 'react';
import Markdown from 'react-native-markdown-display';
import { FadeIn } from 'react-native-reanimated';
import Animated from 'react-native-reanimated';

import { ChatUnitType } from '@/API';
import EmptyChatIcon from '@/assets/svg/emptyChat.svg';
import useTranslations from '@/translations/useTranslation';

type Props = {
  isOnlyMe: boolean;
  isAllConversation: boolean;
  hasMessages: boolean;
  styles: any;
  chatUnitType?: ChatUnitType;
};

const NewConversationTypeText = {
  no_conversationinvite_chat_unit_type: {
    [ChatUnitType.QUICK_CHAT]: 'no_conversation_dm_text',
    [ChatUnitType.TABLE]: 'no_conversation_table_text',
    [ChatUnitType.ROOM]: 'no_conversation_room_text',
  },
};

export const NewConversation = ({
  isOnlyMe,
  isAllConversation,
  hasMessages,
  chatUnitType,
  styles,
}: Props) => {
  const { translate } = useTranslations();

  if (hasMessages) {
    return null;
  }

  if (isOnlyMe && !isAllConversation) {
    return (
      <Animated.View
        entering={FadeIn.delay(700).duration(1000)}
        style={styles.newConversation}>
        <EmptyChatIcon style={styles.emptyChatIcon} />
        <Markdown
          style={{
            body: { ...styles.newConversationText },
            textgroup: { ...styles.textGroup },
          }}>
          {translate('no_conversation_my_chat_text')}
        </Markdown>
      </Animated.View>
    );
  }

  return (
    <Animated.View
      entering={FadeIn.delay(700).duration(1000)}
      style={styles.newConversation}>
      <EmptyChatIcon style={styles.emptyChatIcon} />
      <Markdown
        style={{
          body: { ...styles.newConversationText },
          textgroup: { ...styles.textGroup },
        }}>
        {translate(
          NewConversationTypeText.no_conversationinvite_chat_unit_type[
            chatUnitType ?? 'TABLE'
          ],
        )}
      </Markdown>
    </Animated.View>
  );
};
