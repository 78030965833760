import isEqual from 'fast-deep-equal';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import useUserStore from '@/domain/user/state/useUserStore';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import type { ChatUnitUser } from '@/services/types';
import { sortByCreatedAt } from '@/utilities/sorters/sortByCreatedAt';
type ChatUnitUserIdsMap = Map<
  string, // tableId
  Set<string> // tableUserIds
>;

type State = {
  chatUnitUserIds: ChatUnitUserIdsMap;
  myChatUsers: Map<
    string, // chatUnitId
    ChatUnitUser
  >;
  users: Map<
    string, // tableUserId
    ChatUnitUser
  >;
  lastUpdated: null | Date;
};

type Actions = {
  addTableUserToTable: (chatUser: ChatUnitUser) => void;
  addUsers: (chatUser: ChatUnitUser[]) => void;
  removeTableUserFromTable: (chatUnitUser: ChatUnitUser) => void;
  clearState: () => void;
  setLastUpdated: (lastUpdated: Date) => void;
  getMyChatUsers: () => Map<string, ChatUnitUser>;
  getTableUser: (id: string | undefined) => ChatUnitUser | undefined;
  getTableUsersForTable: (tableId: string) => ChatUnitUser[];
  getAllTableUsersForTable: (chatUnitId: string) => ChatUnitUser[];
  getAllChatUnitUsers: () => ChatUnitUser[];
  setTableUserIds: (chatUnitUsers: ChatUnitUser[]) => void;
};

const newState = {
  chatUnitUserIds: new Map(),
  myChatUsers: new Map(),
  users: new Map(),
  lastUpdated: null,
};

export const useTableUsersStore = createWithEqualityFn<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...newState,
      addTableUserToTable: (chatUser: ChatUnitUser) => {
        set(state => {
          // add user to the `myChatUsers` map
          if (chatUser.id === useUserStore.getState().user?.id) {
            state.myChatUsers.set(chatUser.chatUnitId, chatUser);
          }

          // add user to the `users` map
          state.users.set(chatUser.chatUnitUserId, chatUser);

          // update the tableUsers map
          const tableId = chatUser.chatUnitId;
          if (
            state.chatUnitUserIds.get(tableId)?.has(chatUser.chatUnitUserId)
          ) {
            return state; // user already in state
          }
          if (state.chatUnitUserIds.get(tableId)) {
            state.chatUnitUserIds.get(tableId)!.add(chatUser.chatUnitUserId);
            return state;
          }
          state.chatUnitUserIds.set(
            tableId,
            new Set([chatUser.chatUnitUserId]),
          );
          return state;
        });
      },
      addUsers: (chatUsers: ChatUnitUser[]) => {
        set(state => {
          chatUsers.forEach(tableUser => {
            state.users.set(tableUser.chatUnitUserId, tableUser);

            // add user to the `myChatUsers` map
            if (tableUser.id === useUserStore.getState().user?.id) {
              state.myChatUsers.set(tableUser.chatUnitId, tableUser);
            }
          });
          return state;
        });
      },
      removeTableUserFromTable: (chatUnitUser: ChatUnitUser) => {
        set(state => {
          const tableId = chatUnitUser.chatUnitId;

          // remove user from the `users` map
          state.users.delete(chatUnitUser.chatUnitUserId);

          if (
            state.chatUnitUserIds.get(tableId)?.has(chatUnitUser.chatUnitUserId)
          ) {
            state.chatUnitUserIds
              .get(tableId)
              ?.delete(chatUnitUser.chatUnitUserId);
            return state;
          }
          return state;
        });
      },
      clearState: () => {
        set(() => newState);
      },
      setLastUpdated: (lastUpdated: Date) =>
        set(state => {
          state.lastUpdated = lastUpdated;
        }),
      getMyChatUsers: () => {
        return [...get().myChatUsers.values()].reduce((acc, chatUser) => {
          // TODO: HZN-857  remove falsey check
          if (chatUser.status === 'ACTIVE' || !chatUser.status) {
            acc.set(chatUser.chatUnitId, chatUser);
          }
          return acc;
        }, new Map<string, ChatUnitUser>());
      },
      getTableUser: (id: string | undefined) => {
        return id ? get().users.get(id) : undefined;
      },
      getTableUsersForTable: (chatUnitId: string) => {
        const results: ChatUnitUser[] = [];
        // this loops all users and checks if they are in the tableUsers map, prioritises memory usage over speed
        for (const tableUserId of get().chatUnitUserIds.get(chatUnitId) ?? []) {
          const tableUser = get().users.get(tableUserId);
          if (tableUser && tableUser.status !== ChatUnitUserStatus.ARCHIVED) {
            results.push(tableUser);
          }
        }
        return results.sort(sortByCreatedAt);
      },
      getAllTableUsersForTable: (chatUnitId: string) => {
        // this returns all table users includiong the archived ones
        const results: ChatUnitUser[] = [];
        for (const tableUserId of get().chatUnitUserIds.get(chatUnitId) ?? []) {
          const tableUser = get().users.get(tableUserId);
          if (tableUser) {
            results.push(tableUser);
          }
        }
        return results.sort(sortByCreatedAt);
      },
      getAllChatUnitUsers: () => {
        // returns all the users of all the tables
        return Array.from(get().users.values());
      },
      setTableUserIds: (chatUnitUsers: ChatUnitUser[]) => {
        set(state => {
          chatUnitUsers.forEach(tableUser => {
            if (state.chatUnitUserIds.has(tableUser.chatUnitId)) {
              state.chatUnitUserIds
                .get(tableUser.chatUnitId)
                ?.add(tableUser.chatUnitUserId);
            } else {
              state.chatUnitUserIds.set(
                tableUser.chatUnitId,
                new Set([tableUser.chatUnitUserId]),
              );
            }
          });
        });
      },
    })),
    {
      name: 'tableusers-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
      partialize: state => ({
        chatUnitUserIds: state.chatUnitUserIds ?? new Map(),
        myChatUsers: state.myChatUsers ?? new Map(),
        users: state.users ?? new Map(),
        lastUpdated: state.lastUpdated ?? null,
      }),
    },
  ),
  isEqual,
);
