// useImageUpload.js
import { type UploadDataOutput, uploadData } from 'aws-amplify/storage';
import * as ImagePicker from 'expo-image-picker';
import type { VideoThumbnailsResult } from 'expo-video-thumbnails';
import { debounce, uniqueId } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import type { PhotoFile } from 'react-native-vision-camera';

import DocumentService from '@/components/image/DocumentService';
import { constants } from '@/constants';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { blobToBase64 } from '@/utilities/helpers/downloadImage';
import { formatBytes } from '@/utilities/helpers/formatBytes';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import generateUniqueId from '@/utilities/helpers/uuid';

type OnComplete = (image: string) => void;

const imageMimeTypes: string[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/webp',
];

const useImageUpload = (chatUnitId: string, onComplete: OnComplete) => {
  const [picking, setPicking] = useState(false);
  const [image, setImage] = useState('');
  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadTaskRef = useRef<UploadDataOutput>();
  const [thumbnail, setThumbnail] = useState<VideoThumbnailsResult>();
  const { showPrompt } = usePromptStore();
  const { translate } = useTranslations();

  // Reason:-
  // The amplify onProgress fires multiple times in a short period of time.
  // We do not want to recreate the function on every render
  // and we do not want to add it as a dependency to the hook.
  // We also do not want updates every millisecond.
  // Currently, the linter complains about having an unknown inline function
  // and not being able to detect dependencies. Fixing this error is not worth it
  // and out of scope.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleProgress = useCallback(
    debounce(setProgress, constants.progressDebounceTime),
    [],
  );

  const cancelUpload = useCallback(() => {
    if (uploadTaskRef.current) {
      uploadTaskRef.current.cancel();
    }
    setLoading(false);
    setImage('');
  }, []);

  const uploadImage = useCallback(
    async (localFileUri: string, fileName: string, mime: string) => {
      try {
        setLoading(true);
        setProgress(0);

        const fileRes = await fetch(localFileUri);
        const blob = await fileRes.blob();
        const task = uploadData({
          data: blob,
          key: fileName,
          options: {
            contentType: mime,
            // Folder in S3
            onProgress(event) {
              const progressVal = Math.min(
                Math.ceil(
                  (event?.transferredBytes / (event.totalBytes || 1)) * 100,
                ),
                100,
              );
              handleProgress(progressVal);
            },
          },
        });
        uploadTaskRef.current = task;

        const { key } = await task.result;
        onComplete(key);
      } catch (err) {
        setLoading(false);
        setImage('');
        // Reason: The error is sometimes a CancelledError and has a type of unknown
        // @ts-ignore
        if (err?.name === 'CanceledError' || err === 'CanceledError') {
          return;
        }
        showPrompt({
          body: translate('file_upload_network_error_message', { fileName }),
          title: translate('file_upload_network_error'),
        });
        logger.error('useImageUpload::Error while uploading image:', err);
      } finally {
        setLoading(false);
      }
    },
    [handleProgress, onComplete, showPrompt, translate],
  );

  const handleImageLibrarySelection = useCallback(async () => {
    setPicking(true);
    const imagePickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      exif: false,
      base64: false,
      allowsMultipleSelection: false,
      quality: 0.85,
      videoQuality: ImagePicker.UIImagePickerControllerQualityType.Medium,
    });
    setPicking(false);

    const pickedAsset = imagePickerResult.assets?.[0];

    if (!pickedAsset) {
      return;
    }

    const assetLocalUri = pickedAsset.uri;
    const mimeType = pickedAsset.mimeType || '';
    // Reason: pickedAsset.fileSize is not always available and pickedAsset.filesize is not always available
    // However, one of them is always available.
    // @ts-ignore
    const fileSize = pickedAsset?.fileSize || pickedAsset?.filesize! || 0;

    if (!assetLocalUri || !mimeType || !fileSize) {
      showPrompt({
        body: translate('unknown_file_upload_unsupported_error_message'),
        title: translate('file_upload_unsupported_error'),
      });
      return;
    }

    if (!fileSize || fileSize > constants.maxFileSize) {
      showPrompt({
        body: translate('file_upload_size_error_message', {
          fileName: `"${pickedAsset.fileName}"`,
          size: formatBytes(fileSize!),
          limit: formatBytes(constants.maxFileSize),
        }),
        title: translate('file_upload_size_error'),
      });
      return;
    }

    setSize(fileSize ?? 0);

    const imgKey = `chatunit/${chatUnitId}/attachments/${uniqueId().concat(
      pickedAsset.fileName || '',
    )}`;

    if (ImageKeyHelpers.isImageKeyVideo(imgKey)) {
      const res = await DocumentService.getVideoThumbnail(assetLocalUri, {
        quality: constants.videoThumbnailQuality,
        time: constants.videoThumbnailTimePositionInMillis,
      });
      setThumbnail(res);
    }

    setImage(assetLocalUri);
    uploadImage(assetLocalUri, imgKey, mimeType || '');
  }, [chatUnitId, showPrompt, translate, uploadImage]);

  const handleWebImagePaste = useCallback(async () => {
    //Navigator is only available on web
    // @ts-ignore: Navigator is available on web
    const clipBoardItems = await navigator.clipboard.read();

    if (clipBoardItems?.[0] == null) {
      return;
    }
    const mimeType = clipBoardItems[0].types.find((type: string) =>
      imageMimeTypes.includes(type),
    );

    try {
      if (mimeType) {
        const fileType = mimeType.split('/')[1];
        const assetFileName = generateUniqueId() + '.' + fileType;
        const imgKey = `chatunit/${chatUnitId}/attachments/${assetFileName}`;
        const blob = await clipBoardItems[0].getType(mimeType);
        const base64String = await blobToBase64(blob);
        await uploadImage(base64String, imgKey, mimeType);
        setImage(base64String);
      }
    } catch (e: any) {
      logger.error('handleWebImagePaste', e.message, { mimeType });
    }
  }, [chatUnitId, uploadImage]);

  const handlePhotoTaken = useCallback(
    async (data: PhotoFile) => {
      // Note: If you want to consume this file (e.g. for displaying it in an <Image> component),
      // you might have to add the file:// prefix.
      // Note: This file might get deleted once the app closes because it lives in the temp directory.
      const assetLocalUri = 'file://'.concat(data.path);
      setImage(assetLocalUri);
      const fileName = data.path.split('/').pop() || '';
      // Process the local file path on the client and upload the file to S3
      const fileExtension = assetLocalUri.split('.').pop() || '';
      const mimeType = `image/${fileExtension}`;
      uploadImage(assetLocalUri, fileName, mimeType);
    },
    [uploadImage],
  );

  return {
    image,
    loading,
    handleImageLibrarySelection,
    setImage,
    handlePhotoTaken,
    handleWebImagePaste,
    thumbnail,
    progress,
    cancelUpload,
    size,
    picking,
  };
};

export default useImageUpload;
