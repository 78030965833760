import { addNewUsersToChatUnit } from './addNewUsersToChatUnit';

import type { Contact } from '@/domain/contact/state/useContactStore';
import { getName } from '@/domain/user/functions/getName';
import type { Roles } from '@/models';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { createAuthUsers } from '@/services/invite';
import { logger } from '@/services/logger/logger';
import { formatPhoneNumber } from '@/utilities/helpers/formatPhoneNumber';

export type ContactInput = Partial<Contact> & {
  firstName: string;
  phoneNumbers: { label: string; number: string }[];
  role?: Roles;
};

export const inviteContactsToDM = async (
  chatUnitId: string,
  contacts: ContactInput[],
  currentUserId: string,
) => {
  logger.debug('inviteContactsStart', {
    contactsCount: contacts.length,
    chatUnitId,
  });

  const formattedContacts = contacts.map(contact => {
    return {
      name: getName(contact, 'first'),
      phoneNumber: formatPhoneNumber(
        contact.phoneNumbers.find(number => number.label === 'mobile')
          ?.number || contact.phoneNumbers[0]?.number,
      ),
      avatar: contact.avatar,
    };
  });

  const users = await createAuthUsers(
    formattedContacts.map(contact => contact.phoneNumber),
  );

  await addNewUsersToChatUnit(
    chatUnitId,
    users.map((user, index) => {
      const contact = formattedContacts[index];
      return {
        avatar: user.userProfile?.avatar ?? undefined,
        phoneNumber: user.phoneNumber,
        username: user.userProfile?.username || contact?.name || 'Unknown',
        userId: user.cognitoId,
      };
    }),
    ChatUnitUserStatus.ACTIVE,
    currentUserId,
  );

  logger.debug('inviteContactsComplete');
};
