import React from 'react';
import type { SvgProps } from 'react-native-svg';

import { type IconKey, Icons } from './icons';

import type { Color } from '@/ui/common/types/color';

export interface IconProps extends SvgProps {
  fillColor?: Color;
  iconKey: IconKey;
  size?: number;
}

const Icon = ({ fillColor, iconKey, size = 10, ...props }: IconProps) => {
  const IconComponent = Icons[iconKey];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      fill={fillColor}
      height={size}
      testID={`${iconKey}-iconKey`}
      width={size}
      {...props}
    />
  );
};

export default Icon;
