import { useCallback } from 'react';
import type PagerView from 'react-native-pager-view';
import type { SharedValue } from 'react-native-reanimated';

import { ChatUnitType } from '@/API';
import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { logger } from '@/services/logger/logger';
import { web } from '@/utilities/platform';

export type Props = {
  offset: SharedValue<number>;
  pagerRef: React.RefObject<PagerView>;
};

export const useOnPressChatHead = ({ offset, pagerRef }: Props) => {
  const storeApi = useActiveConversationStoreApi();
  const currentUserId = useUserStore(state => state.user?.id);

  const onPressChatHead = useCallback(
    async ({
      index,
      isLongPress = false,
    }: {
      index: number;
      isLongPress?: boolean;
    }) => {
      try {
        const {
          activeConversationIds,
          conversationUserIds,
          userIdIndexMap,
          getOrCreateConversationId,
          setPendingConvoId,
          setWebActiveConversationId,
          activeTableId,
        } = storeApi.getState();

        const chatUnitType = useTableStore
          .getState()
          .getTable(activeTableId || '')?.type;

        if (!currentUserId) {
          throw new Error('Current user not found');
        }

        if (chatUnitType === ChatUnitType.ROOM) {
          // For rooms, we only toggle between "You" (index 0) and "All" (index 1)
          const targetIndex = offset.value === 0 ? 1 : 0;
          // Animate offset.value, the pager animation of offset.value will be suppressed
          pagerRef?.current?.setPageWithoutAnimation(targetIndex);
          if (web) {
            setWebActiveConversationId(activeConversationIds[targetIndex]);
          }
          // Web can't use setPage without animation since it is a PagerView method.
          offset.value = targetIndex;
          return;
        }

        // For tables and other chat types
        const currentConversationId = activeConversationIds[offset.value];
        const currentConversationUserIds = conversationUserIds.get(
          currentConversationId,
        );

        if (!currentConversationUserIds) {
          throw new Error('Current conversation user IDs not found');
        }

        // Simplified: Get the pressed user ID directly from the chatUsers array
        const chatUsers = Array.from(userIdIndexMap.keys());
        const pressedUserId = chatUsers[index];

        if (!pressedUserId) {
          throw new Error('Pressed user ID not found');
        }

        let targetUserIds: string[];

        if (isLongPress) {
          // For long press, create a conversation with just the current user and the long-pressed user
          targetUserIds = [currentUserId, pressedUserId];
        } else {
          // Toggle the pressed user in the current conversation
          if (currentConversationUserIds.includes(pressedUserId)) {
            // If the pressed user is already in the conversation, remove them
            targetUserIds = currentConversationUserIds.filter(
              id => id !== pressedUserId,
            );
            // Ensure we don't remove the current user
            if (!targetUserIds.includes(currentUserId)) {
              targetUserIds.push(currentUserId);
            }
          } else {
            // If the pressed user is not in the conversation, add them
            targetUserIds = [...currentConversationUserIds, pressedUserId];
          }
        }

        // Ensure the current user is always included
        if (!targetUserIds.includes(currentUserId)) {
          targetUserIds.push(currentUserId);
        }

        const newConversationId =
          await getOrCreateConversationId(targetUserIds);

        // Check if target conversation is active
        let targetConversationIndex = activeConversationIds.findIndex(
          id => id === newConversationId,
        );

        if (targetConversationIndex < 0) {
          setPendingConvoId(newConversationId);
          targetConversationIndex = 0;
        }

        // Animate offset.value, the pager animation of offset.value will be suppressed
        pagerRef?.current?.setPageWithoutAnimation(targetConversationIndex);
        if (web) {
          setWebActiveConversationId(newConversationId);
        }
        // Web can't use setPage without animation since it is a PagerView method.
        offset.value = targetConversationIndex;
      } catch (error) {
        logger.error('Error in onPressChatHead', error);
      }
    },
    [currentUserId, offset, pagerRef],
  );

  return onPressChatHead;
};
