/* eslint-disable react-native/no-inline-styles */
import React, { type FC } from 'react';
import { StyleSheet, View } from 'react-native';

import BoldCaption from '@/components/text/BoldCaption';
import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

interface Props {
  label: string;
}

export const TextSeparator: FC<Props> = ({ label }) => (
  <View style={{ width: '100%' }}>
    <View style={styles.orContainer}>
      <View style={styles.breker} />
      <BoldCaption style={styles.orText}>{label}</BoldCaption>
      <View style={styles.breker} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  breker: {
    backgroundColor: Greys.shade0,
    height: 1.5,
    width: '42%',
  },
  orContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  orText: {
    color: Greys.shade0,
    marginTop: 4,
    paddingHorizontal: web ? '3%' : wp(3),
  },
});
