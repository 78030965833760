import type React from 'react';
import { useCallback } from 'react';
import {
  FlatList,
  type ListRenderItem,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { BottomSheet } from './BottomSheet';

import { AppText } from '@/ui/app';
import { Greys, Neutrals, UserThemes } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';

type Item = {
  disabled?: boolean;
  icon?: React.ReactNode;
  title: string;
  onPress: (index: number) => void;
};

type Props = {
  actionButtonTitle?: string;
  headerImage?: React.ReactNode;
  items?: Item[];
  title?: string;
  subtitle?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const ActionList = ({
  actionButtonTitle,
  headerImage,
  items,
  subtitle,
  title,
  open,
  setOpen,
}: Props) => {
  const showHeader = title || subtitle;
  const showActionButton = actionButtonTitle;

  const renderItem: ListRenderItem<Item> = useCallback(
    ({ item, index }) => {
      const onPress = () => {
        setOpen(false);
        item.onPress(index);
      };

      return (
        <Pressable
          onPress={onPress}
          style={[styles.itemRow, item.disabled && styles.disabledButton]}
          disabled={item.disabled}>
          {item.icon}
          <AppText color={Greys.shade500} size={14}>
            {item.title}
          </AppText>
        </Pressable>
      );
    },
    [setOpen],
  );

  return (
    <BottomSheet open={open} setOpen={setOpen}>
      {showHeader && (
        <>
          <View style={headerImage ? styles.imageHeader : styles.header}>
            {headerImage}
            <View>
              {title && (
                <AppText
                  color={Neutrals.shade700}
                  size={17}
                  textAlign={headerImage ? 'left' : 'center'}
                  type="primary700">
                  {title}
                </AppText>
              )}
              {subtitle && (
                <AppText
                  color={Greys.shade500}
                  size={14}
                  textAlign={headerImage ? 'left' : 'center'}>
                  {subtitle}
                </AppText>
              )}
            </View>
          </View>
          <View style={styles.contentContainer}>
            <LinearGradient
              colors={[Greys.shade200, `${Greys.shade200}00`]}
              end={{ x: 0.5, y: 1.0 }}
              start={{ x: 0.5, y: 0.0 }}
              style={styles.gradient}
            />
          </View>
        </>
      )}
      <FlatList
        data={items}
        renderItem={renderItem}
        scrollEnabled={false}
        style={styles.maxContentList}
      />
      {showActionButton && (
        <Pressable onPress={() => setOpen(false)} style={styles.actionButton}>
          <AppText
            color={Greys.shade500}
            size={14}
            textAlign="center"
            type="primary700">
            {actionButtonTitle}
          </AppText>
        </Pressable>
      )}
    </BottomSheet>
  );
};

const AVATAR_SIZE = 40;

const styles = StyleSheet.create({
  actionButton: {
    paddingVertical: 15,
    marginBottom: 15,
  },
  avatar: {
    alignItems: 'center',
    backgroundColor: UserThemes.choice3,
    borderRadius: AVATAR_SIZE * 0.5,
    color: Greys.shade0,
    height: AVATAR_SIZE,
    justifyContent: 'center',
    width: AVATAR_SIZE,
  },
  disabledButton: {
    backgroundColor: Greys.shade200,
    opacity: 0.65,
  },
  header: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  imageHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  contentContainer: {
    paddingTop: 10,
  },
  gradient: {
    position: 'absolute',
    height: 20,
    left: 0,
    right: 0,
    top: 0,
  },
  itemRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 15,
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Neutrals.shade200,
  },
  maxContentList: {
    flexGrow: 0,
  },
  spacerTop: {
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.lg,
    height: 15,
  },
});
