import moment from 'moment-timezone';

/**
 * Format a date string to a string representing the logical day/date relative to today
 *
 * Current day in your timezone = TODAY
 *
 * Previous day in your timezone = YESTERDAY
 *
 * Before yesterday, but less than 1 week ago = [NAME OF DAY] – e.g. TUESDAY
 *
 * 1 week ago or later, same calendar year = [SHORT DAY NAME  + DD + SHORT MONTH NAME] – e.g. MON 15 MAR (prefer no leading zero)
 *
 * Previous calendar year or older = [DD + SHORT MONTH NAME + YYYY] – e.g. 27 DEC 2022
 *
 * @param {string} dateStr The date string to format (relative to today)
 * @param {string} timezone The timezone to use when formatting the date string
 * @returns {string} The formatted day/date
 */
const formatMessageDate = (dateStr: string) => {
  // NOTE: if you update here, please also update formatDate() in node_modules\react-native-gifted-chat\lib\utils.js
  const date = moment(dateStr);
  const referenceDate = moment();
  const today = referenceDate.startOf('day');
  const yesterday = today.clone().subtract(1, 'days');
  const oneWeekAgo = today.clone().subtract(6, 'days');

  if (date.isSame(today, 'day')) {
    return 'TODAY';
  }

  if (date.isSame(yesterday, 'day')) {
    return 'YESTERDAY';
  }

  if (date.isAfter(oneWeekAgo)) {
    return date.format('dddd').toUpperCase();
  }

  if (date.isSame(today, 'year')) {
    return date.format('ddd DD MMM').toUpperCase();
  }

  return date.format('DD MMM YYYY').toUpperCase();
};

export default formatMessageDate;
