import { constants } from '@/constants';
import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import BorderRadius from '@/ui/common/styles/BorderRadius';
import Fonts from '@/ui/common/styles/Fonts';
import { useCallback } from 'react';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
  CodeField,
  Cursor,
  type RenderCellOptions,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

type Props = {
  otp: string;
  setOtp: (otp: string) => void;
  onSubmit?: () => void;
  error?: string;
};

export default function OnboardingOTPCells({
  otp,
  setOtp,
  onSubmit,
  error,
}: Props) {
  const ref = useBlurOnFulfill({ cellCount: constants.otpCount, value: otp });

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    setValue: setOtp,
    value: otp,
  });

  const renderCell = useCallback(
    ({ index, symbol, isFocused }: RenderCellOptions) => {
      return (
        <View
          key={index}
          onLayout={getCellOnLayoutHandler(index)}
          style={styles.cell}>
          <AppText
            size={34}
            textAlign="center"
            color={error ? Colors.rasberry : Colors.neutral90}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </AppText>
        </View>
      );
    },
    [error, getCellOnLayoutHandler],
  );

  return (
    <CodeField
      ref={ref}
      {...props}
      cellCount={constants.otpCount}
      keyboardType="number-pad"
      inputMode="numeric"
      onChangeText={setOtp}
      renderCell={renderCell}
      autoComplete={Platform.OS === 'android' ? 'sms-otp' : 'one-time-code'}
      rootStyle={styles.codeField}
      textContentType="oneTimeCode"
      value={otp}
      textInputStyle={styles.textInputStyle}
      onSubmitEditing={onSubmit}
    />
  );
}

const styles = StyleSheet.create({
  codeField: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 295,
  },
  textInputStyle: {
    fontSize: 34,
    verticalAlign: 'middle',
    textAlign: 'center',
    fontFamily: Fonts.primary400,
    color: Colors.neutral90,
  },
  cell: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.neutral10,
    height: 60,
    width: 44,
    borderRadius: BorderRadius.md,
  },
});
