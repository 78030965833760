import React from 'react';
import {
  Dimensions,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

import { Colors } from '../theme/Colors';

import OnboardingBackground from './components/OnboardingBackground';
import OnboardingORTextSeperator from './components/OnboardingORTextSeperator';
import OnboardingSignInButton from './components/OnboardingSignInButton';
import OnboardingSignUpButton from './components/OnboardingSignUpButton';

import LogoDark from '@/assets/svg/logo-dark.svg';
import Section from '@/components/section/Section';
import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { useMMKVString } from 'react-native-mmkv';
import OnboardingLinksFooter from './components/OnboardingLinksFooter';

type Props = MainStackScreenProps<'OnboardingStart'>;

const OnboardingStartScreen = ({ navigation }: Props) => {
  const { translate } = useTranslations();
  const [_, setOnboardingType] = useMMKVString('onboardingType');

  return (
    <>
      <OnboardingBackground />
      <ScrollView>
        <View style={styles.container}>
          <Spacer height={120} />
          <LogoDark />
          <Spacer height={28} />
          <AppText
            color={Colors.neutral0}
            size={26}
            type="secondary900"
            textAlign="center"
            style={styles.heading}>
            {translate('get_more_stuff_done_with_your_team')}
          </AppText>
          <Section fg1 aic jcfe>
            <AppText size={17} color={Colors.neutral0}>
              {translate('get_started_with_8seats')}
            </AppText>
            <Spacer height={20} />
            <OnboardingSignInButton />
            <Spacer height={20} />
            <OnboardingORTextSeperator />
            <Spacer height={20} />
            <OnboardingSignUpButton />
            <Spacer height={30} />
            <Pressable
              onPress={() => {
                setOnboardingType('businessInvitee');
                navigation.navigate('OnboardingStepOne', {
                  onboardingType: 'businessInvitee',
                  stepNumber: 1,
                  totalSteps: 4,
                });
              }}>
              <AppText
                size={17}
                type="primary700"
                color={Colors.neutral0}
                textAlign="center">
                {translate('invited_label')}
              </AppText>
            </Pressable>
            <Spacer height={60} />
          </Section>
        </View>
        <Section p={30}>
          <OnboardingLinksFooter color={Colors.neutral0} />
        </Section>
        <Spacer height={60} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    paddingHorizontal: 45,
  },
  container: {
    alignItems: 'center',
    minHeight: Dimensions.get('window').height,
  },
});

export default OnboardingStartScreen;
