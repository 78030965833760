import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import { useFeatureFlag } from 'posthog-react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dimensions,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitIcon } from '../ChatUnitIcon';
import NetworkImage from '../image/NetworkImage';
import NetworkVideo from '../video/NetworkVideo';

import ChatUnitNotificationGradient from './ChatUnitNotificationGradient';

import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { getThemeTableColor } from '@/domain/table/functions/getThemeTableColor';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { useTheme } from '@/domain/theme';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import useUserStore from '@/domain/user/state/useUserStore';
import useTimeout from '@/hooks/useTimeout';
import type { Message } from '@/services/chatUnit/types';
import { eventBus } from '@/services/eventBus/eventBus';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { web } from '@/utilities/platform';

const HORIZONTAL_SWIPE_THRESHOLD = -100;
const VERTICAL_SWIPE_THRESHOLD = -75;
const DISMISSAL_DISTANCE = web
  ? -Dimensions.get('window').width
  : -Dimensions.get('window').height;
const DISPLAY_MILLISECONDS = 6000;

type Props = Message;

export const showChatUnitNotificationToast = (payload: Props) => {
  eventBus.emit('showChatUnitNotificationToast', payload);
};

const ChatUnitNotificationToast = () => {
  const enableInAppNotifications = useFeatureFlag('inAppNotifications');
  const [props, setProps] = useState<Props>();
  const navigation = useNavigation();

  const { styles, lowEmphasisTextColor } = useStyles();
  const insets = useSafeAreaInsets();

  const fileName = ImageKeyHelpers.getFileName(props?.image);
  const {
    chatUnit,
    chatUnitColor,
    conversationFirstNames,
    focussedConversationId,
    myUserId,
    senderName,
  } = useData(
    props?.userId || '',
    props?.chatUnitId || '',
    props?.conversationId || '',
  );

  const [visibility, setVisibility] = useState(false);

  const { panGesture, toastVerticalOffset, toastHorizontalOffset } =
    usePanGesture(visibility, setVisibility);
  // Reason: useCallback thinks dependencies are unknown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNewMessage = useCallback(
    debounce((payload: Message) => {
      if (
        payload.conversationId === focussedConversationId ||
        !enableInAppNotifications
      ) {
        return;
      }

      // Check if the toast is already visible
      if (visibility) {
        // Hide the toast with a spring animation
        toastVerticalOffset.value = withSpring(
          DISMISSAL_DISTANCE,
          {},
          dismissed => {
            if (dismissed) {
              runOnJS(setVisibility)(false);

              // Fade in by default
              toastHorizontalOffset.value = withSpring(0);
              toastVerticalOffset.value = withSpring(0);

              runOnJS(setProps)(payload);
              runOnJS(setVisibility)(true);
            }
          },
        );
      } else {
        // Fade in by default
        toastHorizontalOffset.value = withSpring(0);
        toastVerticalOffset.value = withSpring(0);

        setProps(payload);
        setVisibility(true);
      }
    }, DISPLAY_MILLISECONDS),
    [
      focussedConversationId,
      toastHorizontalOffset,
      toastVerticalOffset,
      visibility,
      enableInAppNotifications,
    ],
  );

  useEffect(() => {
    eventBus.on('showChatUnitNotificationToast', handleNewMessage);

    return () => {
      eventBus.off('showChatUnitNotificationToast');
    };
  }, [handleNewMessage]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      { translateY: toastVerticalOffset.value },
      { translateX: toastHorizontalOffset.value },
    ],
  }));

  const handlePress = useCallback(() => {
    // Hide the toast with a spring animation
    toastVerticalOffset.value = withSpring(
      DISMISSAL_DISTANCE,
      {},
      dismissed => {
        if (dismissed) {
          runOnJS(setVisibility)(false);
        }
      },
    );

    navigation.navigate('ChatView', {
      chatUnitId: chatUnit?.id!,
      conversationId: props?.conversationId,
    });
  }, [chatUnit?.id, navigation, props?.conversationId, toastVerticalOffset]);

  if (
    !props ||
    !visibility ||
    myUserId === props.userId ||
    props.conversationId === focussedConversationId
  ) {
    return null;
  }

  return (
    <>
      <GestureDetector gesture={panGesture}>
        <Animated.View
          style={[
            styles.container,
            {
              top:
                insets.top +
                Platform.select({ ios: 0, android: 24, default: 44 }),
            },
            animatedStyle,
          ]}>
          <Pressable style={styles.pressView} onPress={handlePress}>
            <View style={styles.row}>
              <AppText type="primary700" size={14}>
                {senderName}
              </AppText>
              {ImageKeyHelpers.isImageKeyImage(props?.image) ? (
                <NetworkImage
                  imageKey={props?.image}
                  width={60}
                  height={60}
                  borderRadius={BorderRadius.sm}
                  showImagePreview={false}
                />
              ) : ImageKeyHelpers.isImageKeyVideo(props.image) ? (
                <NetworkVideoThumbnail imageKey={props.image || ''} />
              ) : ImageKeyHelpers.isImageKeyDocument(props?.image) ? (
                <>
                  {!props.text ? (
                    <AppText
                      numberOfLines={3}
                      size={14}
                      ellipsizeMode="tail"
                      style={styles.textContainer}>
                      Sent a document:{' '}
                      <AppText type="primary700" size={14}>
                        {fileName}
                      </AppText>
                    </AppText>
                  ) : (
                    <AppText
                      numberOfLines={3}
                      size={14}
                      ellipsizeMode="tail"
                      style={styles.textContainer}>
                      <AppText type="primary700" size={14}>
                        📄 {fileName}
                        {' • '}
                      </AppText>
                      {props.text}
                    </AppText>
                  )}
                </>
              ) : null}
              {props?.text &&
              !ImageKeyHelpers.isImageKeyDocument(props?.image) ? (
                <AppText
                  size={14}
                  numberOfLines={3}
                  textAlign="left"
                  style={styles.textContainer}>
                  {props?.text}
                </AppText>
              ) : null}
            </View>
            <Spacer height={15} />
            <ChatUnitNotificationGradient>
              <ChatUnitIcon
                fill={chatUnitColor}
                size={14}
                type={chatUnit?.type}
              />
              <Spacer width={4} />
              <AppText
                type="primary700"
                size={10}
                color={chatUnitColor}
                numberOfLines={1}>
                {chatUnit?.title}
              </AppText>
              <Spacer width={16} />
              <AppText
                size={10}
                numberOfLines={1}
                color={lowEmphasisTextColor}
                style={styles.textContainer}
                textAlign="right">
                {conversationFirstNames}
              </AppText>
            </ChatUnitNotificationGradient>
          </Pressable>
        </Animated.View>
      </GestureDetector>
    </>
  );
};

export const usePanGesture = (
  visibility: boolean,
  setVisibility: (visible: boolean) => void,
) => {
  // By default, the toast comes in from the top
  const toastVerticalOffset = useSharedValue(DISMISSAL_DISTANCE);
  const toastHorizontalOffset = useSharedValue(0);

  const { resetTimer } = useTimeout(visibility, DISPLAY_MILLISECONDS, () => {
    // Hide with a spring animation
    toastVerticalOffset.value = withSpring(
      DISMISSAL_DISTANCE,
      {},
      dismissed => {
        if (dismissed) {
          runOnJS(setVisibility)(false);
        }
      },
    );
  });

  const panGesture = Gesture.Pan()
    .onUpdate(event => {
      runOnJS(resetTimer)();
      // The toast should solely move either vertically or horizontally based on the direction of the swipe
      // If already swiped vertically, don't allow horizontal movement and vice versa
      if (Math.abs(event.translationY) > Math.abs(event.translationX)) {
        // Check if swiped horizontally first
        if (toastHorizontalOffset.value !== 0) {
          return;
        }
        toastVerticalOffset.value = event.translationY;
      } else {
        // Check if swiped vertically first
        if (toastVerticalOffset.value !== 0) {
          return;
        }
        toastHorizontalOffset.value = event.translationX;
      }
    })
    .onEnd(event => {
      if (event.translationY < VERTICAL_SWIPE_THRESHOLD) {
        // Check if swiped up enough to dismiss
        // Animate toast out of view if swiped up enough
        toastVerticalOffset.value = withSpring(
          DISMISSAL_DISTANCE,
          {},
          dismissed => {
            if (dismissed) {
              runOnJS(setVisibility)(false);
            }
          },
        );
      } else {
        toastVerticalOffset.value = withSpring(0); // Return to original position
      }

      // Do the same with a left swipe
      if (event.translationX < HORIZONTAL_SWIPE_THRESHOLD) {
        // Check if swiped left enough to dismiss
        // Animate toast out of view if swiped left enough
        toastHorizontalOffset.value = withSpring(
          DISMISSAL_DISTANCE,
          {},
          dismissed => {
            if (dismissed) {
              runOnJS(setVisibility)(false);
            }
          },
        );
      } else {
        // Return to original position
        toastHorizontalOffset.value = withSpring(0);
      }
    });

  return { panGesture, toastVerticalOffset, toastHorizontalOffset };
};

const useStyles = () => {
  const {
    theme: {
      ui: {
        toast: {
          variants: { newMessage: toastTheme },
        },
      },
    },
  } = useTheme();

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        zIndex: 1000,
        position: 'absolute',
        alignSelf: 'center',
        width: Dimensions.get('window').width - 16,
        maxWidth: web ? 320 : undefined,
        borderRadius: BorderRadius.lg,
        backgroundColor: toastTheme.backgroundColor,
        ...Platform.select({
          default: {
            elevation: 4,
          },
          ios: {
            shadowColor: toastTheme.shadowColor,
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowOpacity: 0.25,
            shadowRadius: 8,
          },
        }),
      },
      pressView: {
        flex: 1,
        paddingTop: 15,
      },
      row: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingHorizontal: 15,
        gap: 10,
      },
      textContainer: {
        width: 0,
        flexGrow: 1,
      },
      fileImg: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
      },
    });
  }, [toastTheme.backgroundColor, toastTheme.shadowColor]);

  return {
    styles,
    highEmphasisTextColor: toastTheme.highEmphasisTextColor,
    lowEmphasisTextColor: toastTheme.lowEmphasisTextColor,
  };
};

const useData = (
  userId: string,
  chatUnitId: string,
  conversationId: string,
) => {
  const myUserId = useUserStore(state => state.user?.id);

  const conversation = useConversationStore(
    useShallow(state => state.getConversation(chatUnitId, conversationId)),
  );
  const getTableUser = useTableUsersStore(
    useShallow(state => state.getTableUser),
  );

  const chatUsers = useMemo(() => {
    return conversation?.chatUnitUserIds?.map(chatUnitUserId => {
      return getTableUser(chatUnitUserId);
    });
  }, [conversation?.chatUnitUserIds, getTableUser]);

  const focussedConversationId = useConversationStore(
    useShallow(state => state.focussedConversationId),
  );

  const chatUnit = useTableStore(
    useShallow(state => state.getTable(chatUnitId || '')),
  );

  const chatUnitColor = useMemo(() => {
    return getThemeTableColor(chatUnit?.colorId!) || Colors.neutral80;
  }, [chatUnit?.colorId]);

  const conversationFirstNames = useMemo(() => {
    return chatUsers
      ?.map(chatUser => chatUser && getName(chatUser, 'first'))
      .join('    ');
  }, [chatUsers]);

  const senderName = useMemo(() => {
    const sender = chatUsers?.find(chatUser => chatUser?.id === userId);
    if (!sender) {
      return '';
    }
    return getName(sender, 'first');
  }, [chatUsers, userId]);

  return {
    chatUnit,
    chatUnitColor,
    conversationFirstNames,
    focussedConversationId,
    myUserId,
    senderName,
  };
};

const NetworkVideoThumbnail = ({ imageKey }: { imageKey: string }) => {
  return (
    <NetworkVideo
      imageKey={imageKey}
      width={60}
      height={60}
      borderRadius={BorderRadius.sm}
    />
  );
};

export default ChatUnitNotificationToast;
