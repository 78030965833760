import { ChatUnitColorId } from '@/API';
import Avatar from '@/components/avatar';
import NetworkImage from '@/components/image/NetworkImage';
import FreePill from '@/components/organisation/FreePill';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { getRandomTableColor } from '@/utilities/constants/table-colors';
import React, { memo } from 'react';

export type IDrawerWorkspaceItem = {
  name: string;
  id: string;
  imageKey: string;
  organisationPlan: string;
  role: string;
};

function DrawerWorkspaceItem(props: IDrawerWorkspaceItem) {
  const userName = useUserStore(state => state.user?.name);
  return (
    <Section row aic gap={15}>
      {props.id === 'personal' ? (
        <Avatar
          size={40}
          imageKey={
            props.imageKey ?? getRandomTableColor(ChatUnitColorId.BLACK)
          }
          displayName={userName!}
          style={{ borderRadius: BorderRadius.xs }}
        />
      ) : (
        <Avatar
          size={40}
          imageKey={
            props.imageKey ?? getRandomTableColor(ChatUnitColorId.BLACK)
          }
          displayName={props.name!}
          style={{ borderRadius: BorderRadius.xs }}
        />
      )}
      <Section gap={5} w0 fg1 jcc>
        <AppText size={14} type="primary600">
          {props.name}
        </AppText>
        {props.role ? (
          <AppText size={12} color={Colors.neutral70}>
            {props.role}
          </AppText>
        ) : null}
      </Section>

      <FreePill plan={props.organisationPlan} />
    </Section>
  );
}

export default memo(DrawerWorkspaceItem);
