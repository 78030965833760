import { useNavigation } from '@react-navigation/native';
import { Image, type ImageProps } from 'expo-image';
import React, { memo, useCallback, useMemo, type FC } from 'react';
import { type ImageStyle, TouchableOpacity } from 'react-native';

import NetworkImage from './NetworkImage';

import defaultPlaceholderImg from '@/assets/chat-unit-background-images/erosion.jpg';
import type { BorderRadius } from '@/ui/common/styles';
import { getChatUnitBackgroundImage } from '@/utilities/constants/chat-unit-background-images';
import { web } from '@/utilities/platform';

type BaseProps = {
  imageKey?: string;
  width: ImageStyle['width'];
  height: ImageStyle['height'];
  borderRadius?: BorderRadius;
  contentFit?: ImageProps['contentFit'];
  /* Placeholder image to show while the image is loading */
  placeholder?: ImageProps['source'];
};

// Props: Extended properties for the image component that include conditional logic.
// This type combines BaseProps with additional properties based on the component's usage context.
// The structure below ensures that if `showImagePreview` is true, `onPress` cannot be set,
// enforcing a clear separation of behavior based on the component's configuration.
type Props = BaseProps &
  // If the component is used without image preview functionality:
  (
    | {
        onPress?: () => void; // Optional onPress function if the component is interactive but does not show an image preview.
        showImagePreview?: false; // Explicitly stating that image preview is not available.
      }
    // If the component is used with image preview functionality:
    | {
        onPress?: undefined; // onPress should not be provided when image preview is enabled
        showImagePreview: true; // showImagePreview is true, indicating that the component will show an image preview.
      }
  );

const ChatUnitBackgroundImage: FC<Props> = ({
  width,
  height,
  placeholder = defaultPlaceholderImg,
  borderRadius,
  imageKey,
  onPress,
  showImagePreview = false,
  ...rest
}) => {
  const navigation = useNavigation();

  const handlePress = useCallback(() => {
    if (onPress && typeof onPress === 'function') {
      onPress();
    } else if (showImagePreview && !web) {
      navigation.navigate('ImageExpand', {
        source: { uri: imageKey || placeholder },
        imageKey,
      });
    }
  }, [onPress, showImagePreview, navigation, imageKey, placeholder]);

  // Default image if no background image is provided
  const imageSource = useMemo(() => {
    if (!imageKey) {
      return placeholder;
    }
    if (imageKey.startsWith('file://')) {
      return { uri: imageKey };
    }
    if (!getChatUnitBackgroundImage(imageKey)) {
      return { uri: imageKey };
    }
    return getChatUnitBackgroundImage(imageKey);
  }, [imageKey, placeholder]);

  // Local image if one surely exists and the path starts with file://
  const isNetworkImage = useMemo(() => {
    return (
      imageKey &&
      !imageKey.startsWith('file://') &&
      !getChatUnitBackgroundImage(imageKey)
    );
  }, [imageKey]);

  return (
    // Predefined image
    <TouchableOpacity
      disabled={!onPress && !showImagePreview}
      onPress={handlePress}
      style={{ height, width }}>
      {isNetworkImage ? (
        <NetworkImage
          imageKey={imageKey}
          showImagePreview={!web && showImagePreview}
          width={width}
          height={height}
          borderRadius={borderRadius}
          placeholder={placeholder}
          {...rest}
        />
      ) : (
        <Image
          cachePolicy="memory-disk"
          source={imageSource}
          style={{ width, height, borderRadius }}
          {...rest}
        />
      )}
    </TouchableOpacity>
  );
};

export default memo(ChatUnitBackgroundImage);
