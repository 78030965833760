import emojiRegex from 'emoji-regex';
import { Image } from 'expo-image';
import React, { type FC, useCallback, useState } from 'react';
import {
  type ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
} from 'react-native';
import {
  type BubbleProps,
  type IMessage as GiftedMessage,
  MessageText,
  Time,
  utils,
} from 'react-native-gifted-chat';
import Animated, { FadeIn } from 'react-native-reanimated';

import BubbleDocument from './BubbleDocument';
import BubbleMessageImage from './BubbleMessageImage';
import BubbleMessageVideo from './BubbleMessageVideo';
import type { BubbleMessage, GiftedUser, ReplyInfo } from './types';

import Avatar from '@/components/avatar';
import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import { ChatUnitColorId } from '@/models';
import { logger } from '@/services/logger/logger';
import { wp } from '@/theme/responsiveHelpers';
import { AppText } from '@/ui/app';
import { Greys, Neutrals } from '@/ui/common/colors';
import { Fonts } from '@/ui/common/styles';
import { tableColors } from '@/utilities/constants/table-colors';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { android, web } from '@/utilities/platform';
import { Metadata } from 'libphonenumber-js';
import CollapsibleText from './CollapsibleText';

// TODO: refactor this component, extract into seperate components
interface BubbleComponentProps extends BubbleProps<GiftedMessage> {
  currentMessage: BubbleMessage;
  imageStyle?: ImageStyle;
  messageTextStyle?: ViewStyle;
  myUser: GiftedUser;
  chatUnitId: string | undefined;
  onLongPress: () => void;
}

const isOnlyEmoji = (str: string) => {
  const regex = emojiRegex();
  const match = str.match(regex);
  return match && match.join('') === str;
};

const countEmojis = (str: string) => {
  const regex = emojiRegex();
  const match = str.match(regex);
  return match ? match.length : 0;
};

const { isSameDay, isSameUser } = utils;

const Bubble: FC<BubbleComponentProps> = props => {
  const query = useActiveConversationStore(state => state.query);
  const getTableColor = useCallback((chatUnitId: string) => {
    const colorId =
      useTableStore(state => state.getTable(chatUnitId))?.colorId ??
      ChatUnitColorId.GREEN;
    const tableColor = tableColors.find(colorObj => colorObj.id === colorId);
    return tableColor ? tableColor.color : Colors.primaryLight; // Default color if not found
  }, []);
  const renderMessageText = useCallback(() => {
    if (props?.currentMessage?.text) {
      const { messageTextStyle, ...messageTextProps } = props;
      const messageText = messageTextProps.currentMessage.text;

      const onlyEmoji = isOnlyEmoji(messageTextProps.currentMessage.text);

      const emojiCount = countEmojis(messageTextProps.currentMessage.text);
      const style =
        onlyEmoji && emojiCount === 1
          ? { fontSize: 60 }
          : onlyEmoji && emojiCount === 2
            ? { fontSize: 35 }
            : onlyEmoji
              ? { fontSize: 25 }
              : styles.standardFont;

      const parsePatterns: any = [
        {
          pattern: /\*\*(.*?)\*\*|\*(.*?)\*/, // bold
          style: styles.fontWeightBold,
          renderText: (text: string) => {
            return text?.replace(/^\*\*|\*\*|\*|\*$/g, '');
          },
        },
        {
          pattern: /\b_[^_]+_\b/g, // italic
          style: styles.fontStyleItalic,
          renderText: (text: string) => {
            return text?.replace(/^_|_$/g, '');
          },
        },
      ];

      if (query) {
        parsePatterns.unshift({
          pattern: new RegExp(query, 'gi'),
          style: styles.highlightText,
          renderText: (text: string) => {
            return text;
          },
        });
      }

      return (
        <MessageText
          {...messageTextProps}
          currentMessage={{
            ...messageTextProps.currentMessage,
            text: messageText,
          }}
          textStyle={{
            left: [
              style,
              styles.slackMessageText,
              messageTextProps.textStyle?.[props.position],
              messageTextStyle,
            ],
          }}
          parsePatterns={() => parsePatterns}
          linkStyle={{
            left: [styles.hyperlink],
            right: [styles.hyperlink],
          }}
        />
      );
    }
    return null;
  }, [query, props?.currentMessage?.text]);
  const renderMessageImage = useCallback(() => {
    const imageKey = props.currentMessage.image;

    if (!imageKey) {
      return null;
    }

    if (ImageKeyHelpers.isImageKeyImage(imageKey)) {
      return (
        <BubbleMessageImage
          imageKey={imageKey}
          onLongPress={props.onLongPress}
        />
      );
    }

    if (ImageKeyHelpers.isImageKeyVideo(imageKey)) {
      return <BubbleMessageVideo imageKey={imageKey} />;
    }

    if (ImageKeyHelpers.isImageKeyDocument(imageKey)) {
      return <BubbleDocument imageKey={imageKey} />;
    }

    return null;
  }, [props.currentMessage.image]);

  const renderTicks = () => {
    const { currentMessage } = props;
    if (currentMessage?.user?.id !== props?.myUser?.id) {
      return null;
    }
    if (currentMessage?.sent || currentMessage?.received) {
      return (
        <View style={[styles.headerItem, styles.tickView]}>
          {currentMessage.sent && (
            <Text style={[styles.standardFont, styles.tick, props.tickStyle]}>
              ✓
            </Text>
          )}
          {currentMessage.received && (
            <Text style={[styles.standardFont, styles.tick, props.tickStyle]}>
              ✓
            </Text>
          )}
        </View>
      );
    }
    return null;
  };

  const renderUsername = () => {
    const isMe = props?.currentMessage?.user._id === props?.myUser?.id;

    const themeColor = getTableColor(props.chatUnitId ?? '');

    const username = isMe ? 'You' : props.currentMessage.user.username;
    if (username) {
      const { ...timeProps } = props;
      return (
        <View style={styles.row}>
          <AppText
            size={15}
            type="primary700"
            style={[
              styles.headerItem,
              styles.username,
              isMe && { color: themeColor },
            ]}>
            {username}
          </AppText>
          <Time
            {...timeProps}
            containerStyle={{ left: [styles.timeContainer] }}
            timeTextStyle={{
              left: [styles.time],
            }}
          />
        </View>
      );
    }
    return null;
  };

  const [isReplyExpanded, setIsReplyExpanded] = useState(false);

  const parseReplyInfo = useCallback(
    (metaData: string | null | undefined): ReplyInfo | null => {
      if (!metaData) return null;

      try {
        const parsedMetaData =
          typeof metaData === 'string' ? JSON.parse(metaData) : metaData;
        const replyInfo = parsedMetaData?.replyInfo;

        if (replyInfo?.createdAt) {
          replyInfo.createdAt = new Date(replyInfo.createdAt).toISOString();
        }

        logger.debug('Reply info parsed successfully', { replyInfo });
        return replyInfo;
      } catch (error) {
        logger.error('Failed to parse reply metadata', { error, metaData });
        return null;
      }
    },
    [],
  );

  const renderReplyPreview = useCallback(() => {
    const { currentMessage, position } = props;

    const replyInfo = parseReplyInfo(currentMessage.metaData);
    if (!replyInfo) return null;

    const replyToChatUser = useTableUsersStore(state =>
      state.getTableUser(replyInfo.userId),
    );
    if (!replyToChatUser) {
      logger.warn('Reply user not found', { userId: replyInfo.userId });
      return null;
    }

    const isMe = replyInfo.createdBy === props?.myUser?.id;
    const stripeColor = getTableColor(replyToChatUser.chatUnitId);

    return (
      <View style={styles.replyPreviewContainer}>
        <View style={[styles.replyStripe, { backgroundColor: stripeColor }]} />
        <View style={styles.replyContent}>
          <View style={styles.replyHeader}>
            <View style={styles.usernameTimeContainer}>
              <Avatar
                size={18}
                imageKey={replyToChatUser.avatar}
                displayName={getName(replyToChatUser, 'short')}
              />
              <AppText
                size={14}
                type="primary700"
                style={[styles.replyUsername, isMe && { color: stripeColor }]}>
                {isMe ? 'You' : getName(replyToChatUser, 'short')}
              </AppText>
              <Time
                currentMessage={{
                  _id: replyInfo.id,
                  text: replyInfo.text,
                  createdAt: new Date(replyInfo.createdAt),
                  user: {
                    _id: replyInfo.createdBy,
                    name: isMe ? 'You' : getName(replyToChatUser, 'short'),
                  },
                }}
                position={position}
                containerStyle={{ left: [styles.replyTimeContainer] }}
                timeTextStyle={{
                  left: [styles.replyTime],
                }}
              />
            </View>
          </View>
          <View style={styles.replyContentWrapper}>
            {replyInfo.image && (
              <Image
                source={{ uri: replyInfo.image }}
                style={styles.replyImagePreview}
                cachePolicy="memory-disk"
              />
            )}
            <View style={styles.replyTextWrapper}>
              <CollapsibleText
                size={14}
                color={Neutrals.shade700}
                style={styles.replyText}
                collapsible={true}
                maxLines={6}
                themeColor={stripeColor}
                onLongPress={props.onLongPress}>
                {replyInfo.text}
              </CollapsibleText>
            </View>
          </View>
        </View>
      </View>
    );
  }, [props.currentMessage, props.position, props.myUser?.id, parseReplyInfo]);

  const renderHeader = () => {
    if (!props.currentMessage || !props.previousMessage) {
      return;
    }
    const isSameThread =
      isSameUser(props?.currentMessage, props?.previousMessage) &&
      isSameDay(props.currentMessage, props.previousMessage);

    const isImage = props.currentMessage.image;
    const marginBottom = isImage ? 0 : 5;
    return isSameThread ? null : (
      <View style={[styles.headerView, { marginBottom }]}>
        {renderUsername()}
        {renderTicks()}
      </View>
    );
  };

  if (!props.user) {
    return null;
  }

  return (
    <Animated.View entering={FadeIn.duration(100)} style={styles.container}>
      {renderHeader()}
      {renderReplyPreview()}
      {renderMessageImage()}
      {renderMessageText()}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'flex-end',
  },
  headerItem: {
    marginRight: 10,
  },
  headerView: {
    flexDirection: 'row',
    marginTop: android ? -2 : 0,
  },
  image: {
    borderRadius: 10,
    height: '100%',
    marginTop: 10,
    width: '100%',
  },
  landscapeContainerStyle: {
    aspectRatio: 16 / 10,
    borderRadius: 10,
    height: wp(52),
    overflow: 'hidden',
    width: wp(60),
  },
  portraitContainerStyle: {
    aspectRatio: 10 / 16,
    borderRadius: 10,
    height: wp(100),
    overflow: 'hidden',
    width: wp(50),
  },
  row: { alignItems: 'center', flexDirection: 'row', marginBottom: 3 },
  slackMessageText: {
    marginLeft: 0,
    marginRight: 0,
    ...(web && { userSelect: 'text' }),
  },
  squareContainerStyle: {
    aspectRatio: 1,
    borderRadius: 10,
    height: wp(83),
    overflow: 'hidden',
    width: wp(60),
  },
  standardFont: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 15,
  },
  tick: {
    backgroundColor: 'transparent',
    color: Greys.shade0,
  },
  tickView: {
    flexDirection: 'row',
  },
  time: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    marginTop: 2,
  },
  timeContainer: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  username: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 15,
    ...(web && { userSelect: 'text' }),
  },
  fontWeightBold: {
    fontWeight: 'bold',
    fontFamily: Fonts.primary700,
  },
  fontStyleItalic: {
    fontStyle: 'italic',
    fontFamily: Fonts.primary700,
  },
  highlightText: {
    backgroundColor: Colors.primaryLight,
    color: Colors.neutral0,
    fontWeight: 'bold',
    fontFamily: Fonts.primary700,
  },
  replyHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  usernameTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Add margin between username and time
  },
  replyUsername: {
    marginLeft: 7,
  },
  replyTimeContainer: {
    marginLeft: 10, // Space between username and time
    marginBottom: 0,
  },
  replyTime: {
    color: Neutrals.shade500,
    fontSize: 10,
    fontFamily: 'OpenSans-Regular',
  },
  replyPreviewContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 5.5,
    paddingRight: 34,
  },
  replyStripe: {
    width: 2,
    borderRadius: 1,
    marginRight: 10,
  },
  replyContent: {
    flex: 1,
  },
  replyContentWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  replyImagePreview: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: 10,
  },
  replyTextWrapper: {
    flex: 1,
  },
  replyText: {
    marginTop: 2,
    lineHeight: 18,
  },
  hyperlink: {
    fontFamily: Fonts.primary500,
    color: Colors.primaryLight,
  },
});

export default React.memo(Bubble);
