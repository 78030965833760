import { Image } from 'expo-image';
import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useShallow } from 'zustand/react/shallow';

import { formatActivityDate } from '../../timeHelper';
import { ActivityItemBadge } from '../ActivityItemBadge';

import { Badge } from './Badge';
import { getStyles } from './styles';

import fileImg from '@/assets/chatview/file.png';
import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import Avatar from '@/components/avatar';
import NetworkImage from '@/components/image/NetworkImage';
import NetworkVideo from '@/components/video/NetworkVideo';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { getThemeTableColor } from '@/domain/table/functions/getThemeTableColor';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import { ChatUnitType } from '@/models';
import type { ChatMessage } from '@/services/chatUnit/types';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { tableColors } from '@/utilities/constants/table-colors';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { removeMarkdown, truncate } from '@/utilities/helpers/strings';

export const MAX_LENGTH = 82;

export interface Props {
  message: ChatMessage;
  onPress?: (message: ChatMessage) => void;
  userId: string;
  boldText?: string;
  showChatunitName?: boolean;
}

export const ConversationActivityCard = React.memo(
  ({ message, onPress, userId, boldText, showChatunitName = true }: Props) => {
    const { translate } = useTranslations();
    const [unreadCount] = useMessageStore(
      useShallow(state => [
        state.getUnreadCountForConversation(
          message.chatUnitId,
          message.conversationId,
        ),
      ]),
    );

    const table = useTableStore(
      useShallow(state => state.getTable(message.chatUnitId)),
    );
    const conversation = useConversationStore(
      useShallow(state =>
        state.getConversation(message.chatUnitId, message.conversationId),
      ),
    );

    const [myChatUser, chatUnitUsers] = useTableUsersStore(state => [
      state.getMyChatUsers().get(message.chatUnitId),
      state.getAllTableUsersForTable(message.chatUnitId),
    ]);

    const { image, text, chatUnitUserId: authorChatUnitUserId } = message;

    const fileName = image?.split('/').pop();
    const isVideo = ImageKeyHelpers.isImageKeyVideo(image);
    const isImage = ImageKeyHelpers.isImageKeyImage(image);
    const isDocument = ImageKeyHelpers.isImageKeyDocument(image);

    const styles = useMemo(() => getStyles(table?.type), [table?.type]);

    const conversationUsers = useMemo(() => {
      return conversation?.chatUnitUserIds?.map(chatUnitUserId => {
        return chatUnitUsers.find(
          chatUnitUser => chatUnitUser.chatUnitUserId === chatUnitUserId,
        );
      });
    }, [conversation?.chatUnitUserIds, chatUnitUsers]);

    const messageUser = useMemo(() => {
      return chatUnitUsers.find(
        chatUnitUser => chatUnitUser.chatUnitUserId === authorChatUnitUserId,
      );
    }, [chatUnitUsers, authorChatUnitUserId]);

    const messageToShow = useMemo(
      () => removeMarkdown(truncate(text, MAX_LENGTH, boldText)),
      [text, boldText],
    );
    const tableColor = table?.colorId ?? tableColors[0].color;

    const linearGradientStart = useMemo(() => ({ x: 0, y: 0 }), []);
    const linearGradientEnd = useMemo(() => ({ x: 0, y: 1 }), []);

    const name = messageUser ? getName(messageUser, 'short') : '';
    const tableTitle = table?.title ?? '';
    const formattedDate = formatActivityDate(new Date(message.createdAt));
    const themeTableColor = getThemeTableColor(tableColor);

    const viewedByUsers = useMemo(() => {
      if (table?.type === ChatUnitType.ROOM) {
        return (
          <AppText style={styles.viewedBy}>
            {conversationUsers?.length === 1
              ? '1 person'
              : conversationUsers?.length + ' people'}
          </AppText>
        );
      }

      const otherUsers =
        conversationUsers?.filter(user => user?.id !== userId) || [];
      const totalUsers = otherUsers.length + 1; // +1 for the current user

      if (totalUsers <= 8) {
        // Display all names if 8 or fewer users
        return (
          <>
            <AppText style={styles.viewedBy}>{translate('you_text')}</AppText>
            {otherUsers.map((item, index) => {
              if (item?.id && item.id !== userId) {
                return (
                  <AppText key={index.toString()} style={styles.viewedBy}>
                    {item?.username}
                  </AppText>
                );
              }
              return null;
            })}
          </>
        );
      } else {
        // Display first 5 names and "+X others" for more than 8 users
        return (
          <>
            <AppText style={styles.viewedBy}>{translate('you_text')}</AppText>
            {otherUsers.slice(0, 4).map((item, index) => (
              <AppText key={index.toString()} style={styles.viewedBy}>
                {item?.username}
              </AppText>
            ))}
            <AppText style={styles.viewedBy}>
              {`+${totalUsers - 5} ${translate('others')}`}
            </AppText>
          </>
        );
      }
    }, [conversationUsers, userId, styles.viewedBy, table?.type, translate]);

    if (!message || !table || myChatUser?.status !== 'ACTIVE') {
      return null;
    }

    return (
      <TouchableOpacity
        onPress={() => {
          onPress && onPress(message);
        }}
        style={styles.container}>
        <View style={styles.imageContainer}>
          <Badge style={styles.badge} type={table.type} />
          <View style={styles.avatarContainer}>
            <Avatar
              imageKey={messageUser?.avatar}
              displayName={name}
              size={40}
            />
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.nameAndDateContainer}>
            <View style={styles.nameContainer}>
              <AppText style={styles.name} type="primary700" size={14}>
                {name}
              </AppText>
              {showChatunitName && (
                <View style={styles.nameContainer}>
                  <Text children={translate('in')} style={styles.inText} />
                  <View style={styles.iconContainer}>
                    <ChatUnitIcon
                      fill={themeTableColor}
                      size={14}
                      type={table.type}
                    />
                  </View>
                  <AppText
                    size={10}
                    type="primary700"
                    style={[
                      styles.teamText,
                      {
                        color: themeTableColor,
                      },
                    ]}>
                    {tableTitle}
                  </AppText>
                </View>
              )}
            </View>
            <View style={styles.timeAndIndicatorCountContainer}>
              <Text children={formattedDate} style={styles.time} />
              {!boldText && (
                <ActivityItemBadge unreadMessageCount={unreadCount ?? 0} />
              )}
            </View>
          </View>
          <View style={styles.messageContainer}>
            <MediaContent
              isImage={isImage}
              isVideo={isVideo}
              style={styles}
              image={image}
              messageToShow={messageToShow}
              boldText={boldText}
            />
            {isDocument && (
              <DocumentContent
                style={styles}
                messageToShow={messageToShow}
                boldText={boldText}
                fileName={fileName}
              />
            )}
            {!image && (
              <TextContent messageToShow={messageToShow} boldText={boldText} />
            )}
          </View>
          <LinearGradient
            colors={[Colors.neutral05, Colors.neutral0]}
            end={linearGradientEnd}
            start={linearGradientStart}
            style={styles.viewedByContainer}>
            {viewedByUsers}
          </LinearGradient>
        </View>
      </TouchableOpacity>
    );
  },
);

const NetworkVideoThumbnail = React.memo(
  ({ imageKey }: { imageKey: string }) => {
    return (
      <NetworkVideo
        imageKey={imageKey}
        width={60}
        height={60}
        borderRadius={BorderRadius.sm}
      />
    );
  },
);

const MediaContent = React.memo(
  ({
    isImage,
    isVideo,
    style,
    image,
    messageToShow,
    boldText,
  }: {
    isImage: boolean;
    isVideo: boolean;
    style: any;
    image: string | undefined;
    messageToShow: string;
    boldText?: string;
  }) => {
    if (isImage || isVideo) {
      const parts = messageToShow
        .trim()
        .split(new RegExp(`(${boldText})`, 'gi'))
        .filter(Boolean);
      return (
        <View style={style.imageTextRow}>
          {isVideo ? (
            <NetworkVideoThumbnail imageKey={image || ''} />
          ) : (
            <NetworkImage
              imageKey={image}
              width={60}
              height={60}
              borderRadius={BorderRadius.sm}
              showImagePreview={true}
            />
          )}
          <AppText
            numberOfLines={3}
            size={14}
            ellipsizeMode="tail"
            style={style.imageText}>
            {parts.map((part, index) =>
              part.toLowerCase() === boldText?.toLowerCase() ? (
                <AppText key={index} size={14} style={styles.highlightText}>
                  {part}
                </AppText>
              ) : (
                <AppText key={index} size={14}>
                  {part}
                </AppText>
              ),
            )}
          </AppText>
        </View>
      );
    }
    return null;
  },
);

const DocumentContent = React.memo(
  ({
    style,
    messageToShow,
    boldText,
    fileName,
  }: {
    style: any;
    messageToShow: string;
    boldText?: string;
    fileName: string | undefined;
  }) => {
    const parts = messageToShow
      .trim()
      .split(new RegExp(`(${boldText})`, 'gi'))
      .filter(Boolean);
    return (
      <View style={style.imageTextRow}>
        <View style={style.fileContainer}>
          <Image
            source={fileImg}
            style={style.fileImg}
            cachePolicy="memory-disk"
          />
        </View>
        {!messageToShow ? (
          <AppText numberOfLines={3} size={14} ellipsizeMode="tail">
            Sent a document:{' '}
            <AppText type="primary700" size={14}>
              {fileName}
            </AppText>
          </AppText>
        ) : (
          <AppText
            numberOfLines={3}
            size={14}
            ellipsizeMode="tail"
            style={style.imageText}>
            <AppText type="primary700" size={14}>
              📄 {fileName}
              {' • '}
            </AppText>
            {parts.map((part, index) =>
              part.toLowerCase() === boldText?.toLowerCase() ? (
                <AppText key={index} size={14} style={styles.highlightText}>
                  {part}
                </AppText>
              ) : (
                <AppText key={index} size={14}>
                  {part}
                </AppText>
              ),
            )}
          </AppText>
        )}
      </View>
    );
  },
);
const TextContent = React.memo(
  ({
    messageToShow,
    boldText,
  }: {
    messageToShow: string;
    boldText?: string;
  }) => {
    const parts = messageToShow
      .split(new RegExp(`(${boldText})`, 'gi'))
      .filter(Boolean);
    return (
      <AppText numberOfLines={3} size={14} ellipsizeMode="tail">
        {parts.map((part, index) =>
          part.toLowerCase() === boldText?.toLowerCase() ? (
            <AppText key={index} size={14} style={styles.highlightText}>
              {part}
            </AppText>
          ) : (
            <AppText key={index} size={14}>
              {part}
            </AppText>
          ),
        )}
      </AppText>
    );
  },
);

const styles = StyleSheet.create({
  thumbnailContainer: {
    width: 60,
    height: 60,
    borderRadius: BorderRadius.sm,
    borderWidth: 1,
    borderColor: Colors.neutral30,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playIcon: {
    zIndex: 3,
  },
  highlightText: {
    backgroundColor: Colors.primaryLight,
    color: Colors.neutral0,
    fontWeight: 'bold',
    fontFamily: Fonts.primary700,
  },
});
