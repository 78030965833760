import { useEffect, useState } from 'react';

import { web } from '../platform';

export enum ScreenSizes {
  xl = 0,
  l = 1,
  md = 2,
  sm = 3,
  xs = 4,
  xxs = 5,
}

const screenSizes = {
  [ScreenSizes.xl]: { minWidth: 1075, name: ScreenSizes.xl },
  [ScreenSizes.l]: { minWidth: 875, name: ScreenSizes.l },
  [ScreenSizes.md]: { minWidth: 675, name: ScreenSizes.md },
  [ScreenSizes.sm]: { minWidth: 475, name: ScreenSizes.sm },
  [ScreenSizes.xs]: { minWidth: 275, name: ScreenSizes.xs },
  [ScreenSizes.xxs]: { minWidth: 0, name: ScreenSizes.xxs },
};

const getWindowDimensions = () => {
  if (!web) {
    return { screenSize: ScreenSizes.xl };
  }

  // @ts-ignore: window does exist on web
  const height = window?.innerHeight;
  // @ts-ignore: window does exist on web
  const width = window?.innerWidth;

  const sizes = Object.values(screenSizes);
  let screenSize = ScreenSizes.xl;
  sizes.some(size => {
    if (width > size.minWidth) {
      screenSize = size.name;
      return true;
    }
  });
  return {
    width,
    height,
    screenSize,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    // @ts-ignore: window does exist on web
    if (web && window) {
      // @ts-ignore: window does exist on web
      window?.addEventListener('resize', handleResize);
      // @ts-ignore: window does exist on web
      return () => window?.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
