import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { getMessages } from '../services/getMessages';
import { useMessageStore } from '../state/useMessageStore';

type RefreshMessagesProps = Parameters<typeof getMessages>[0];

export const useRefreshMessages = () => {
  const [addMessages, isLoading, setLoadingMessages] = useMessageStore(
    useShallow(state => [
      state.addMessages,
      state.loadingMessages,
      state.setLoadingMessages,
    ]),
  );

  const refreshMessages = useCallback(
    async (props: RefreshMessagesProps) => {
      setLoadingMessages(true);
      const messages = await getMessages(props);

      addMessages({
        messages: messages.map(message => {
          return message;
        }),
      });

      if (!messages || !messages.length || props.limit !== messages.length) {
        setLoadingMessages(false);
        return false;
      }

      setLoadingMessages(false);
      return true;
    },
    [addMessages, setLoadingMessages],
  );

  return { refreshMessages, isLoading };
};
