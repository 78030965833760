import { useCallback, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import type { User } from '@/API';
import {
  type Contact,
  useContactStore,
} from '@/domain/contact/state/useContactStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { getName } from '@/domain/user/functions/getName';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatUnitUser } from '@/services/chatUnitUser/types';
import ContactsService from '@/services/contacts/ContactsService';
import { logger } from '@/services/logger/logger';
import { useFocusEffect } from '@react-navigation/native';

export const useAppContacts = () => {
  const [
    deviceContacts,
    setDeviceContacts,
    eightSeatsContacts,
    upsertEightSeatsUsers,
  ] = useContactStore(
    useShallow(state => [
      state.deviceContacts,
      state.setDeviceContacts,
      state.eightSeatsContacts,
      state.upsertEightSeatsUsers,
    ]),
  );
  const [allChatUnitUsers, setAllChatUnitUsers] = useState<ChatUnitUser[]>([]);
  const [userDetails, setUserDetails] = useState<User[]>([]);
  const user = useUserStore(state => state.user);

  useFocusEffect(
    useCallback(() => {
      setAllChatUnitUsers(useTableUsersStore.getState().getAllChatUnitUsers());
    }, []),
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const localContacts = await ContactsService.fetchDeviceContacts();
      // remove phone numbers from localContacts that are already in allChatUnitUsers
      const filteredLocalContacts = localContacts.filter(
        contact =>
          !allChatUnitUsers.some(user => {
            const foundNumberIndex = contact.phoneNumbers.findIndex(
              phone =>
                phone.number.replace(/\s/g, '') ===
                user.phoneNumber.replace(/\s/g, ''),
            );
            // remove phoneNumbers at foundNumberIndex
            if (foundNumberIndex !== -1) {
              contact.phoneNumbers.splice(foundNumberIndex, 1);
            }
            // if therea re no phoneNumbers left, remove the contact
            if (contact.phoneNumbers.length === 0) {
              return true;
            }
            return false;
          }),
      );
      setDeviceContacts(filteredLocalContacts);

      const orgContacts = await ContactsService.fetchOrganisationsUsers();
      const orgAndChatUnitUsers = [...orgContacts, ...allChatUnitUsers];

      const contactIds = orgAndChatUnitUsers.map(contact => contact.id);
      if (contactIds.length > 0) {
        const userDetails = await ContactsService.getUsersDetails(contactIds);
        if (userDetails) {
          setUserDetails(userDetails as User[]);
        }
      }

      upsertEightSeatsUsers(orgAndChatUnitUsers);
    } catch (e) {
      logger.error('fetchData::Error :', e);
    } finally {
      setLoading(false);
    }
  }, [allChatUnitUsers, setDeviceContacts, upsertEightSeatsUsers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const sectionedContacts = useMemo(() => {
    setLoading(true);
    const orgContacts = [...eightSeatsContacts.values()].map(contact => {
      return {
        ...contact,
        name: userDetails.find(user => user?.id === contact.userId)?.name ?? '',
      };
    });

    let contacts = [...orgContacts, ...deviceContacts]?.filter(
      contact => contact.userId !== user?.id,
    );

    // filter contacts
    const query = searchQuery.trim().toLowerCase();
    if (query) {
      contacts = contacts.filter(contact => {
        const result = getName(contact, 'full').toLowerCase().includes(query);
        return result;
      });
    }

    // sort
    contacts.sort((a, b) => {
      const nameA = (getName(a, 'first') ?? '#').toUpperCase();
      const nameB = (getName(b, 'first') ?? '#').toUpperCase();
      const firstLetterA = nameA.charAt(0);
      const firstLetterB = nameB.charAt(0);

      const isAlphaA = /^[A-Z]$/.test(firstLetterA);
      const isAlphaB = /^[A-Z]$/.test(firstLetterB);

      if (!isAlphaA && !isAlphaB) {
        return 0;
      }
      if (!isAlphaA) {
        return 1;
      }
      if (!isAlphaB) {
        return -1;
      }

      return nameA.localeCompare(nameB);
    });

    // add section headings
    let currentSection = '';
    const results: (string | Contact)[] = [];
    // Loop through each sorted contact
    contacts.forEach(contact => {
      // Use '#' as the section title for non-alphabetic characters
      let firstLetter = (
        getName(contact, 'first')?.charAt(0) || '#'
      ).toUpperCase();
      if (!/^[A-Z]$/.test(firstLetter)) {
        firstLetter = '#';
      }
      // Check if the current contact belongs to a new section
      if (currentSection !== firstLetter) {
        // Add the section title (first letter)
        results.push(firstLetter);
        currentSection = firstLetter;
      }
      // Add the contact to the list
      results.push(contact);
    });
    setLoading(false);

    return results;
  }, [deviceContacts, eightSeatsContacts, searchQuery]);

  return {
    hasContacts: !!(deviceContacts.length + eightSeatsContacts.size),
    sectionedContacts,
    loadAllContacts: fetchData,
    loading,
    searchQuery,
    setSearchQuery,
  };
};
