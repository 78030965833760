import { useShallow } from 'zustand/react/shallow';

import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatUnit } from '@/services/types';
import { sortByCreatedAt } from '@/utilities/sorters/sortByCreatedAt';

export const useDMListItem = (dm: ChatUnit) => {
  const [userId, username, userAvatar] = useUserStore(
    useShallow(state => [
      state.user?.id,
      state.user?.username,
      state.user?.avatar,
    ]),
  );
  const chatUsers = useTableUsersStore(
    useShallow(state => state.getTableUsersForTable(dm.id)),
  )
    .sort(sortByCreatedAt)
    .filter(cu => cu.id !== userId);
  const [results, hasMultiThreads] = useMessageStore(
    useShallow(state => [
      state.getMostRecentMessagesFromChatUnits([dm.id]),
      state.hasMultiThreads(dm.id),
    ]),
  );
  const latestMessages = Object.values(results);

  const latestMessage = latestMessages.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )[0];

  const unreadCount = useMessageStore(
    useShallow(state => state.getUnreadCountForChatUnit(dm.id)),
  );

  const firstOtherUser = chatUsers.shift();
  const firstOtherUsername = firstOtherUser?.username;
  const firstOtherUserAvatar = firstOtherUser?.avatar;
  const otherUserCount = chatUsers.length - 3;
  const otherUsersName = chatUsers
    .slice(0, 3)
    .map(cu => cu.username)
    .join('  ');
  const date = latestMessage?.createdAt ?? dm.createdAt;

  return {
    date,
    hasMultiThreads,
    // current username shouldn't be required, but will use as a failsafe
    displayUsername: firstOtherUsername ?? username,
    avatar: firstOtherUserAvatar ?? userAvatar,
    otherUsersName,
    latestMessage,
    otherUserCount,
    unreadCount,
  };
};
