import { Image } from 'expo-image';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import type { PhoneNumber } from 'react-native-contacts';

import { ActionList } from '../../../components/actionList/ActionList';

import type { Contact } from '@/domain/contact/state/useContactStore';
import useTranslations from '@/translations/useTranslation';
import { AppText, Icon } from '@/ui/app';
import { Greys, UserThemes } from '@/ui/common/colors';

type Props = {
  contact?: Contact;
  onPhoneSelect: (phone: PhoneNumber) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ChoosePhoneNumberSheet = ({
  contact,
  onPhoneSelect,
  open,
  setOpen,
}: Props) => {
  const { translate } = useTranslations();
  const description = translate('choose_phone_number');

  if (!contact) {
    return null;
  }

  const headerImage = contact.avatar ? (
    <Image source={{ uri: contact.avatar }} style={styles.avatar} />
  ) : (
    <View style={styles.avatar}>
      <AppText color={Greys.shade0} size={10}>
        {getInviteeInitials(`${contact.firstName} ${contact.lastName}`)}
      </AppText>
    </View>
  );

  const items = contact.phoneNumbers.map(phone => ({
    icon: <Icon fillColor={Greys.shade0} iconKey="Telephone" size={24} />,
    title: phone.number,
    onPress: () => {
      onPhoneSelect(phone);
    },
  }));

  return (
    <ActionList
      actionButtonTitle={translate('cancel_common_button_title')}
      headerImage={headerImage}
      items={items}
      title={`${contact.firstName} ${contact.lastName}`}
      subtitle={description}
      open={open}
      setOpen={setOpen}
    />
  );
};

/**
 * Get the initials of the invitee
 * @param name Name of the invitee
 * @returns The initials of the invitee in uppercase
 * @example
 * getInviteeInitials('John Doe') // JD
 * getInviteeInitials('John') // J
 */
function getInviteeInitials(name = '') {
  const [firstName, lastName] = name.split(' ');
  const firstInitial = firstName[0];
  const lastInitial = lastName?.[0];
  return `${firstInitial}${lastInitial ? lastInitial : ''}`.toUpperCase();
}

const AVATAR_SIZE = 40;

const styles = StyleSheet.create({
  avatar: {
    alignItems: 'center',
    backgroundColor: UserThemes.choice3,
    borderRadius: AVATAR_SIZE * 0.5,
    color: Greys.shade0,
    height: AVATAR_SIZE,
    justifyContent: 'center',
    width: AVATAR_SIZE,
  },
});

export default ChoosePhoneNumberSheet;
