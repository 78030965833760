import { useNavigation } from '@react-navigation/native';
import type React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';

import BackButton from '@/components/BackButton';
import { wp } from '@/theme';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

type Props = {
  backButtonText?: string;
  invitationsCount?: number;
  rightActionVisible?: boolean;
  rightIcon?: React.ReactNode;
  title?: string;
  titleComponent?: React.ReactNode;
  style?: ViewStyle;
};
const SecondaryHeader = ({
  backButtonText,
  rightActionVisible,
  rightIcon,
  title,
  style,
}: Props) => {
  const { goBack } = useNavigation();
  const { translate } = useTranslations();

  return (
    <View style={styles.wrapper}>
      <View style={styles.shadowContainer}>
        <View style={[styles.container, style]}>
          <View style={styles.headerContentLeft}>
            <BackButton buttonText={backButtonText} onPress={goBack} />
          </View>
          <View style={styles.headerContentCenter}>
            <Text style={styles.title}>
              {title ?? translate('select_contacts')}
            </Text>
          </View>
          <TouchableOpacity style={styles.headerContentRight}>
            {rightActionVisible && rightIcon}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    elevation: 2,
    flexDirection: 'row',
    height: 52,
    justifyContent: 'space-between',
    shadowColor: Greys.shade999,
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  headerContentCenter: {
    alignItems: 'center',
    width: wp(60),
  },
  headerContentLeft: {
    paddingLeft: 15,
    width: wp(20),
  },
  headerContentRight: {
    alignItems: 'flex-end',
    paddingRight: 15,
    width: wp(20),
  },
  rightAction: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 3,
  },
  shadowContainer: { overflow: 'hidden', paddingBottom: 1 },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  wrapper: { backgroundColor: Greys.shade0 },
});

export default SecondaryHeader;
