import type { ChatUnitColorId } from '../../../API';

import { UserThemes } from '@/ui/common/colors';
import type { Color } from '@/ui/common/types/color';
import { tableColors } from '@/utilities/constants/table-colors';

export const getThemeTableColor = (
  colorId: ChatUnitColorId | UserThemes,
): Color => {
  const color = tableColors.find(c => c.id === colorId);
  return color ? color.color : UserThemes.choice2;
};
