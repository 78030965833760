import React from 'react';
import { useMemo } from 'react';
import { Pressable } from 'react-native';
import Animated, {
  Extrapolate,
  type SharedValue,
  interpolate,
  interpolateColor,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { styles } from './styles';

import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { logger } from '@/services/logger/logger';
import { Greys } from '@/ui/common/colors';

interface AnimatedDotProps {
  index: number;
  scrollValue: SharedValue<number>;
  total: number;
  onPress: (index: number) => void;
}

export const AnimatedDot = ({
  index,
  scrollValue,
  total,
  onPress,
}: AnimatedDotProps) => {
  const conversationId = useActiveConversationStore(
    state => state.activeConversationIds[index],
  );

  const chatUnitId = useActiveConversationStore(state => state.activeTableId);
  if (!chatUnitId) {
    logger.warn('useConversationsWithUnreadMessages::chatUnitIdMissing');
  }

  const unreadCount = useMessageStore(
    useShallow(store =>
      store.getUnreadCountForConversation(chatUnitId, conversationId),
    ),
  );
  const inputRange = useMemo(
    () => (total > 1 ? Array.from({ length: total }, (_, i) => i) : [1, 0]),
    [total],
  );

  const outputRangeOpacity = useMemo(
    () =>
      total > 1
        ? Array.from({ length: total }, (_, i) =>
            i === index ? styles.activeDot.opacity : styles.inactiveDot.opacity,
          )
        : [0.5, 1],
    [index, total],
  );

  const outputRangeScale = useMemo(
    () =>
      total > 1
        ? Array.from({ length: total }, (_, i) => (i === index ? 1 : 0.7))
        : [0.7, 1],
    [index, total],
  );

  const stylezOpacity = useAnimatedStyle(
    () => ({
      opacity: interpolate(
        scrollValue.value,
        inputRange,
        outputRangeOpacity,
        Extrapolate.CLAMP,
      ),
    }),
    [total, index, outputRangeOpacity],
  );

  const stylezColor = useAnimatedStyle(() => {
    const color = interpolateColor(
      scrollValue.value,
      inputRange,
      total > 1
        ? Array.from({ length: total }, (_, i) =>
            i === index
              ? styles.activeDot.backgroundColor
              : unreadCount
                ? styles.unreadMessagesDot.backgroundColor
                : styles.inactiveDot.backgroundColor,
          )
        : [Greys.shade0, Greys.shade0],
    );
    return {
      backgroundColor: color,
    };
  }, [total, index, unreadCount]);

  const stylezBorder = useAnimatedStyle(() => {
    const borderWidth = interpolate(
      scrollValue.value,
      inputRange,
      total >= 2
        ? Array.from({ length: total }, (_, i) =>
            i === index
              ? styles.activeDot.borderWidth
              : unreadCount
                ? styles.unreadMessagesDot.borderWidth
                : styles.inactiveDot.borderWidth,
          )
        : [1, 0],
    );
    return {
      borderWidth: borderWidth,
    };
  }, [total, index, unreadCount]);

  const stylezScale = useAnimatedStyle(() => {
    const scale = interpolate(
      scrollValue.value,
      inputRange,
      outputRangeScale,
      Extrapolate.CLAMP,
    );
    return {
      transform: [
        {
          scale: scale,
        },
      ],
    } as any;
  }, [total, index]);

  return (
    // Wrapped Animated.View with Pressable
    <Pressable onPress={() => onPress(index)}>
      <Animated.View
        style={[
          // setup base styles
          styles.dot,
          // define initial styles prior to animation
          ...[
            index === scrollValue.value ? styles.activeDot : styles.inactiveDot,
          ],
          // override with unread messages styles
          // finally override with animated styles
          stylezOpacity,
          stylezScale,
          stylezColor,
          stylezBorder,
          ...[unreadCount ? styles.unreadMessagesDot : {}],
        ]}
      />
    </Pressable>
  );
};
