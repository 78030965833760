export const backgroundImage5 = require('@/assets/chat-unit-background-images/architecture.jpg');
export const backgroundImage14 = require('@/assets/chat-unit-background-images/beach.jpg');
export const backgroundImage11 = require('@/assets/chat-unit-background-images/coffee-shop.jpg');
export const backgroundImage6 = require('@/assets/chat-unit-background-images/construction.jpg');
export const backgroundImage20 = require('@/assets/chat-unit-background-images/cosmic.jpg');
export const backgroundImage10 = require('@/assets/chat-unit-background-images/entertainment.jpg');
export const DEFAULT_CHAT_UNIT_IMAGE = require('@/assets/chat-unit-background-images/erosion.jpg');
export const backgroundImage12 = require('@/assets/chat-unit-background-images/gym.jpg');
export const backgroundImage2 = require('@/assets/chat-unit-background-images/medical.jpg');
export const backgroundImage16 = require('@/assets/chat-unit-background-images/modern-room.jpg');
export const backgroundImage17 = require('@/assets/chat-unit-background-images/nature.jpg');
export const backgroundImage19 = require('@/assets/chat-unit-background-images/ocean.jpg');
export const backgroundImage15 = require('@/assets/chat-unit-background-images/orange.jpg');
export const backgroundImage4 = require('@/assets/chat-unit-background-images/photography.jpg');
export const backgroundImage1 = require('@/assets/chat-unit-background-images/real-estate.jpg');
export const backgroundImage8 = require('@/assets/chat-unit-background-images/retail.jpg');
export const backgroundImage18 = require('@/assets/chat-unit-background-images/retro.jpg');
export const backgroundImage3 = require('@/assets/chat-unit-background-images/running-club.jpg');
export const backgroundImage9 = require('@/assets/chat-unit-background-images/sport-field.jpg');
export const backgroundImage7 = require('@/assets/chat-unit-background-images/study-group.jpg');
export const backgroundImage13 = require('@/assets/chat-unit-background-images/zen.jpg');
export const backgroundImage21 = require('@/assets/chat-unit-background-images/whisper.jpg');

const backgroundImages: Record<string, any> = {
  backgroundImage5,
  backgroundImage14,
  backgroundImage11,
  backgroundImage6,
  backgroundImage20,
  backgroundImage10,
  DEFAULT_CHAT_UNIT_IMAGE,
  backgroundImage12,
  backgroundImage2,
  backgroundImage16,
  backgroundImage17,
  backgroundImage19,
  backgroundImage15,
  backgroundImage4,
  backgroundImage1,
  backgroundImage8,
  backgroundImage18,
  backgroundImage3,
  backgroundImage9,
  backgroundImage7,
  backgroundImage13,
  backgroundImage21,
};

export const getBackgroundImage = (backgroundImage: string) => {
  return backgroundImages[backgroundImage] ?? {};
};
