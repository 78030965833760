import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as yup from 'yup';

import Support from './Support';

import SecureIcon from '@/assets/svg/secure_icon.svg';
import MainButton from '@/components/MainButton';
import SecondaryButton from '@/components/SecondaryButton';
import SuccessCheck from '@/components/SuccessCheck';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import PasswordInput from '@/components/password-input';
import Body from '@/components/text/Body';
import Caption from '@/components/text/Caption';
import SubtitleBold from '@/components/text/SubtitleBold';
import AuthService from '@/domain/auth/AuthService';
import useNavigation from '@/hooks/useNavigation';
import type { MainStackScreenProps } from '@/navigation/types';
import { primaryRed } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

interface FormFields {
  password: string;
}

const SUCCESS_TIMEOUT = 3000;

const ForgotPasswordNewPassword = ({
  route: {
    params: { code, phoneNumber },
  },
}: MainStackScreenProps<'ForgotPasswordNewPassword'>) => {
  const { top } = useSafeAreaInsets();
  const { navigate } = useNavigation();
  const { translate } = useTranslations();
  const resolver = yupResolver(
    yup.object().shape({
      password: yup
        .string()
        .min(8, translate('password_length_text'))
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
          translate('password_is_invalid_text'),
        )
        .required(translate('password_is_required_text')),
    }),
    { abortEarly: false },
  );

  const [successShown, setSuccessShown] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm<FormFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const onCancel = () => {
    navigate('SignIn');
  };

  const onSubmit = async (data: FormFields) => {
    try {
      await AuthService.handleForgotPasswordCodeRequest(
        phoneNumber.replace(/\s/g, ''),
        code,
        data.password,
      );
      setSuccessShown(true);
      setTimeout(() => {
        navigate('SignIn');
      }, SUCCESS_TIMEOUT);
    } catch (e: any) {
      setError('root.submit', {
        message: translate('common_wrong_verification_code_error'),
      });
    }
  };

  return (
    <View style={[styles.container, { paddingTop: top }]}>
      <View style={styles.content}>
        <View style={styles.topContainer}>
          <View style={styles.header} />
          <SecureIcon style={styles.logo} />
          <SubtitleBold style={styles.description}>
            {translate('forgot_password_new_password_title')}
          </SubtitleBold>
          {errors.password?.message ? (
            <Caption style={[styles.text, styles.inputTitle]}>
              {errors.password.message}
            </Caption>
          ) : (
            <Body style={[styles.text, styles.inputTitle]}>
              {translate('set_password_text')}
            </Body>
          )}
          <KeyboardAwareScrollView
            bounces={false}
            contentContainerStyle={styles.keyboardContentContainer}
            showsVerticalScrollIndicator={false}
            style={styles.keyboardAware}>
            <PasswordInput
              control={control}
              label={translate('password_placeholder_text')}
              name="password"
            />
          </KeyboardAwareScrollView>
        </View>
        <View style={styles.bottomContainer}>
          {errors?.root?.submit?.message ? (
            <Caption
              style={[styles.text, styles.inputTitle, styles.submitError]}>
              {errors?.root?.submit.message}
            </Caption>
          ) : null}
          <Support />
          <MainButton
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            style={styles.mainButton}
            textStyle={styles.mainButtonText}
            title={translate('common_submit_text')}
          />
          <SecondaryButton
            onPress={onCancel}
            style={styles.secondaryButton}
            textStyle={styles.secondaryButtonText}
            title={translate('forgot_password_cancel_button_title')}
          />
        </View>
      </View>
      {successShown && (
        <View style={styles.successContainer}>
          <SuccessCheck title={translate('forgot_password_success_title')} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    marginBottom: 40,
    paddingHorizontal: 20,
  },
  cell: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 8,
    fontSize: 24,
    height: 59,
    justifyContent: 'center',
    lineHeight: 38,
    marginHorizontal: 2,
    overflow: 'hidden',
    paddingVertical: 10,
    textAlign: 'center',
    width: 44,
  },
  codeFieldRoot: { paddingHorizontal: wp('10') },
  container: { flexGrow: 1 },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  description: {
    color: Greys.shade600,
    marginTop: 20,
    paddingHorizontal: 40,
    textAlign: 'center',
  },
  errorTitle: {
    color: primaryRed,
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 23,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
  header: {
    height: 40,
  },
  inputTitle: {
    marginTop: 30,
    textAlign: 'center',
  },
  keyboardAware: {
    width: '100%',
  },
  keyboardContentContainer: { alignItems: 'center' },
  logo: { height: 70, marginTop: 10, width: 230 },
  mainButton: {
    marginTop: 48,
  },
  mainButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
  },
  secondaryButton: {
    marginTop: 10,
  },
  secondaryButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  submitError: {
    marginBottom: 20,
  },
  successContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    paddingHorizontal: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  text: { marginHorizontal: 20, textAlign: 'center' },
  title: { color: Greys.shade600, marginTop: 27 },
  titleContainer: { marginTop: 20, paddingHorizontal: wp('10') },
  topContainer: {
    alignItems: 'center',
    flex: 1,
  },
});

export default ForgotPasswordNewPassword;
