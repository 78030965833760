import React, { useCallback } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import Loader from './RefreshLoader';

import { constants } from '@/constants';
import { useExperience } from '@/context/Experience';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import { useDatastoreRefresh } from '@/hooks/useDataStoreRefresh';
import useNavigation from '@/hooks/useNavigation';
import { AppText } from '@/ui/app';
import sleep from '@/utilities/helpers/sleep';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const RefreshDataModal = ({ visible, onClose }: Props) => {
  const { track } = useExperience();
  const { closeDrawer } = useNavigation();

  const handleDataStoreRefresh = useCallback(async () => {
    onClose();
    await sleep(constants.modalRaceConditionDelay);
  }, [onClose]);

  const { isLoading, refreshDatastore } = useDatastoreRefresh(
    handleDataStoreRefresh,
  );
  const userId = useUserStore(state => state.user?.id);

  const handleRefreshConfirm = async () => {
    track('Refresh', { userId: userId ? userId : 'No User Id' });
    closeDrawer();
    await refreshDatastore();
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onClose}>
      <View style={styles.modalOverlay}>
        {isLoading && <Loader />}
        <View style={styles.modalContent}>
          <AppText type="primary700" size={15} style={styles.modalTitle}>
            Refresh My Stored Data
          </AppText>
          <AppText size={14} style={styles.modalText}>
            Are you sure you want to clear all of your locally stored app data
            and refresh using the latest data on the server?
          </AppText>
          <AppText size={14} style={styles.modalText}>
            This may assist if you are experiencing some problems with the app.
          </AppText>
          <AppText size={14} style={styles.modalText}>
            Please note that this process may take a few minutes to complete
            updating on your device.
          </AppText>
          <LinearGradient
            colors={[Colors.neutral40, 'rgba(151, 151, 151, 0)']}
            style={styles.divider1}
          />
          <TouchableOpacity
            style={styles.refreshButton}
            onPress={handleRefreshConfirm}
            activeOpacity={0.7}>
            <Text style={styles.refreshButtonText}>Refresh Stored Data</Text>
          </TouchableOpacity>
          <View style={styles.divider2} />
          <TouchableOpacity
            style={styles.cancelButton}
            onPress={onClose}
            activeOpacity={0.7}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'relative',
  },
  modalContent: {
    backgroundColor: Colors.neutral0,
    borderRadius: 18,
    paddingVertical: 20,
    paddingHorizontal: 25,
    width: 335,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 40,
  },
  modalTitle: {
    fontSize: 15,
    fontWeight: '700',
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 16,
    paddingTop: 10,
  },
  modalText: {
    fontSize: 14,
    lineHeight: 19,
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 8,
  },
  divider1: {
    width: 335,
    height: 20,
    opacity: 0.25,
  },
  divider2: {
    width: 315,
    height: 1,
    backgroundColor: Colors.neutral80,
    opacity: 0.25,
  },
  refreshButton: {
    paddingHorizontal: 24,
    alignItems: 'center',
    marginVertical: 16,
  },
  refreshButtonText: {
    fontSize: 17,
    color: Colors.rasberry,
  },
  cancelButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  cancelButtonText: {
    fontSize: 17,
    color: Colors.neutral80,
  },
});

export default RefreshDataModal;
