import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  type AuthUser,
  type ResetPasswordOutput,
  confirmResetPassword,
  confirmSignIn,
  confirmSignUp,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';

import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { bootstrapCleanUp } from '@/services/datastore/bootstrap/datastoreBootstrap';
import { logger } from '@/services/logger/logger';
import { unsubFromNotifications } from '@/services/notifications';

const clearLocalUserData = () => {
  useConversationStore.getState().clearState();
  useMessageStore.getState().clearState();
  useTableStore.getState().clearState();
  useTableUsersStore.getState().clearState();
  useUserStore.getState().clearState();
  useMyInviteStore.getState().clearInvites();
};

const logout = async () => {
  const userId = useUserStore.getState().user?.id;
  if (userId) {
    await unsubFromNotifications(userId);
  }
  await bootstrapCleanUp(); // must trigger prior to auth signout
  await signOut();
  clearLocalUserData();
  logger.info('User logged out:', userId);
};

const userSignUp = async (phoneNumber: string, password: string) => {
  try {
    const signUpResult = await signUp({
      options: {
        autoSignIn: true,
        userAttributes: {
          phone_number: phoneNumber, // E.164 number convention
        },
      },
      password: password,
      username: phoneNumber,
    });
    return signUpResult.isSignUpComplete ? signUpResult.userId : undefined;
  } catch (error: unknown) {
    logger.error('ERROR::useCognitoUser::signUp:: ', error);
    throw new Error('Unable to sign up');
  }
};

const initSignIn = async () => {
  const user = await getCurrentUser();
  const localUserId = await AsyncStorage.getItem('8seats::userId');
  if (localUserId !== user.userId) {
    clearLocalUserData();
    await DataStore.clear();
    await AsyncStorage.setItem('8seats::userId', user.userId);
    await AsyncStorage.setItem('initialized', 'false');
  }

  return user;
};

const userSignIn = async (phoneNumber: string) => {
  try {
    await signOut();
    const signInResult = await signIn({
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
      username: phoneNumber,
    });

    logger.debug('signInResult', signInResult);
    const { isSignedIn, nextStep } = signInResult;

    if (isSignedIn) {
      initSignIn();
      return;
    }

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
        return signInResult;
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        // return signInResult;
        break;
      case 'RESET_PASSWORD':
        break;
      default:
        logger.debug(nextStep.signInStep);
        throw new Error('Not a Sign In Event we handle');
    }
  } catch (error: unknown) {
    logger.error('ERROR::useCognitoUser::signIn:: ', error);
    throw error;
  }
};

const sendCustomChallengeAnswer = async (
  answer: string,
): Promise<AuthUser | null> => {
  let user;
  try {
    const confirmSignInOutput = await confirmSignIn({
      challengeResponse: answer,
    });
    logger.debug('confirmSignInOutput', confirmSignInOutput);
    if (!confirmSignInOutput.isSignedIn) {
      throw new Error('Incorrect Answer. Please try again');
    }

    return initSignIn();
  } catch (error: unknown) {
    if (!user) {
      logger.error(
        'ERROR::useCognitoUser::sendCustomChallengeAnswer:: ',
        error,
      );
      throw new Error('Unable to sign in');
    }
    return null;
  }
};

const resendConfirmationCode = async (username: string) => {
  try {
    await resendSignUpCode({ username });
    return true;
  } catch (error: unknown) {
    logger.error('ERROR::useCognitoUser::resendConfirmationCode:: ', error);
    throw new Error('Unable to resend confirmation code');
  }
};

const handleForgotPasswordRequest = async (username: string) => {
  try {
    const output = await resetPassword({ username });
    handleResetPasswordNextSteps(output);
  } catch (error: unknown) {
    logger.error(
      'ERROR::useCognitoUser::handleForgotPasswordRequest:: ',
      error,
    );
    throw new Error('Unable to handle forget password request');
  }
};

function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
  const { nextStep } = output;
  switch (nextStep.resetPasswordStep) {
    case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
      const codeDeliveryDetails = nextStep.codeDeliveryDetails;
      logger.debug(
        `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
      );
      // Collect the confirmation code from the user and pass to confirmResetPassword.
      break;
    case 'DONE':
      logger.debug('Successfully reset password.');
      break;
  }
}

const handleForgotPasswordCodeRequest = async (
  phoneNumber: string,
  code: string,
  newPassword: string,
) => {
  try {
    const response = await confirmResetPassword({
      confirmationCode: code,
      newPassword,
      username: phoneNumber,
    });

    return response;
  } catch (error: unknown) {
    logger.error(
      'ERROR::useCognitoUser::handleForgotPasswordCodeRequest:: ',
      error,
    );
    throw new Error('Unable to handle forget password code request');
  }
};

const userConfirmSignUp = async (username: string, code: string) => {
  try {
    await confirmSignUp({ confirmationCode: code, username });
    return true;
  } catch (error: unknown) {
    logger.error('ERROR::useCognitoUser::confirmSignUp:: ', error);
    throw new Error('Unable to confirm signup');
  }
};

/**
 * Check for a logged in user, for example on app boot.
 * If a user is found, return the cognito auth user object.
 * If no user is found, return undefined.
 * If an error occurs, log the error and return undefined.
 *
 * @example
 * const user = await AuthService.checkForLoggedInUser();
 * if (user) {
 *  console.log('User found:', user);
 * } else {
 *  console.log('No user found.');
 * }
 *
 * @returns
 * The cognito auth user object if found, otherwise undefined.
 */
const checkForLoggedInUser = async () => {
  try {
    const cognitoUser = await getCurrentUser();
    if (!cognitoUser) {
      logger.info(
        'AuthService::checkForLoggedInUser:: No user found on app boot, logging out user.',
      );
      return undefined;
    }
    return cognitoUser;
  } catch (err) {
    logger.info(
      'AuthService::checkForLoggedInUser:: Error checking for logged in user:',
      err,
    );
    return undefined;
  }
};

const getCognitoUser = async () => {
  try {
    const user = await getCurrentUser();
    return user;
  } catch {
    return undefined;
  }
};

const AuthService = {
  logout,
  signUp: userSignUp,
  signIn: userSignIn,
  sendCustomChallengeAnswer,
  resendConfirmationCode,
  handleForgotPasswordCodeRequest,
  confirmSignUp: userConfirmSignUp,
  handleForgotPasswordRequest,
  checkForLoggedInUser,
  getCognitoUser,
};

export default AuthService;
