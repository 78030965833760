import React, { type FC, type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import Markdown, { type MarkdownProps } from 'react-native-markdown-display';

import { primaryBlue } from '@/theme/colors';
import { Greys } from '@/ui/common/colors';

const DEFAULT_STYLE = {};

const MarkDown: FC<PropsWithChildren<MarkdownProps>> = ({
  style: { body, heading4, link, ...style } = DEFAULT_STYLE,
  ...props
}: MarkdownProps) => {
  return (
    <Markdown
      style={{
        body: {
          ...styles.body,
          ...body,
        },
        heading4: {
          ...styles.heading4,
          ...heading4,
        },
        link: {
          ...styles.link,
          ...link,
        },
        textgroup: { textAlign: 'center', width: '100%' },
        ...style,
      }}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  body: {
    color: Greys.shade600,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
    textAlign: 'left',
  },
  heading4: {
    fontSize: 17,
  },
  link: {
    color: primaryBlue,
    fontWeight: '600',
    textDecorationLine: 'none',
  },
  textgroup: {
    textAlign: 'left',
  },
});

export default MarkDown;
