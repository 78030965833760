import React from 'react';

import { Badge } from '@/components/badge/Badge';
import { getBadgeTextSize } from '@/components/badge/getBadgeTextSize';
import { useActivities } from '@/domain/activity/hooks/useActivities';
import { Icon } from '@/ui/app/elements';
import { Brand, Neutrals } from '@/ui/common/colors';

type Props = {
  isActive: boolean;
};

export const ActivityIcon = ({ isActive }: Props) => {
  const { totalUnreadActivities } = useActivities();

  return (
    <Badge
      badgeContent={totalUnreadActivities}
      hidden={!totalUnreadActivities}
      styles={{ fontSize: getBadgeTextSize(totalUnreadActivities) }}>
      <Icon
        fill={isActive ? Brand.primary100 : Neutrals.shade700}
        iconKey="Bell"
        size={25}
      />
    </Badge>
  );
};
