export const Icons = {
  Bell: require('./Bell').default,
  Bulb: require('./Bulb').default,
  Bust: require('./Bust').default,
  Cog: require('./Cog').default,
  Copy: require('./Copy').default,
  Envelope: require('./Envelope').default,
  Fork: require('./Fork').default,
  Bubble: require('./Bubble').default,
  Lightning: require('./Lightning').default,
  LightningGradient: require('./LightningGradient').default,
  LightningLgFlat: require('./LightningLgFlat').default,
  LogoOctagon: require('./LogoOctagon').default,
  Message: require('./Message').default,
  Octagon: require('./Octagon').default,
  RoomBack: require('./RoomBack').default,
  Rooms: require('./Rooms').default,
  RoomsLgFlat: require('./RoomsLgFlat').default,
  Saturn: require('./Saturn').default,
  SentPlane: require('./SentPlane').default,
  Tables: require('./Tables').default,
  Tag: require('./Tag').default,
  Telephone: require('./Telephone').default,
  Whatsapp: require('./Whatsapp').default,
  PaperPlane: require('./PaperPlane').default,
  Cross: require('./Cross').default,
};
export type IconKey = keyof typeof Icons;
