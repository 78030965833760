import { useCallback, useEffect } from 'react';
import BackgroundFetch from 'react-native-background-fetch';
import SplashScreen from 'react-native-bootsplash';
import { requestTrackingPermission } from 'react-native-tracking-transparency';

import AuthService from '../domain/auth/AuthService';
import useUserStore from '../domain/user/state/useUserStore';
import { factory } from '../services/factory/factory';
import { logger } from '../services/logger/logger';

import { User } from '@/models';
import { app } from '@/utilities/platform';
import { fetchAuthSession } from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';

/**
 * ! WARNING: This hook should only be used in the root component of the app.
 * ! WARNING: This hook should only be used once.
 *
 * Hook to initialize the app.
 */
const useInit = () => {
  const setUser = useUserStore(state => state.setUser);

  const checkForLoggedInUser = useCallback(async () => {
    try {
      const cognitoUser = await AuthService.checkForLoggedInUser();
      if (!cognitoUser) {
        logger.info('useInit::init:: No user found, logging out user.');
        setUser(undefined);
        return;
      }
      const result = await DataStore.query(User, cognitoUser.userId);
      if (result) {
        setUser(factory.user(result));
      }
    } catch (err) {
      logger.error('Error checking for logged in user:', err);
    }
  }, [setUser]);

  const initBackgroundFetch = useCallback(async () => {
    try {
      await BackgroundFetch.configure(
        {
          minimumFetchInterval: 15, // Fetch every 15 minutes
          stopOnTerminate: false,
          startOnBoot: true,
        },
        async (taskId: string) => {
          await fetchAuthSession({ forceRefresh: true });
          BackgroundFetch.finish(taskId);
        },
        (error: unknown) => {
          logger.error(
            'useInit::initBackgroundFetch:: Background Fetch failed to start',
            error,
          );
        },
      );
    } catch (err) {
      logger.error(
        'useInit::initBackgroundFetch:: Error initializing background fetch:',
        err,
      );
    }
  }, [checkForLoggedInUser]);

  const init = useCallback(async () => {
    try {
      await checkForLoggedInUser();
      if (app) {
        await requestTrackingPermission();
        await initBackgroundFetch();
      }
    } catch (err) {
      logger.error('useInit::initCallback::error', err);
    } finally {
      SplashScreen.hide({ fade: true });
    }
  }, [checkForLoggedInUser, initBackgroundFetch]);

  useEffect(() => {
    init();
  }, [init]);
};

export default useInit;
