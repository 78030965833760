import { AppText } from '@/ui/app';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Colors } from '../theme/Colors';

export default function CriticalAnnouncementModal() {
  const navigation = useNavigation();
  const [criticalModalVisible, setCriticalModalVisible] = useState(true);

  return (
    <Modal visible={criticalModalVisible} transparent animationType="fade">
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          <AppText type="primary700" size={15} style={styles.modalTitle}>
            📣 Important Announcement
          </AppText>
          <AppText size={14} style={styles.modalText}>
            This is a critical announcement from the 8seats team so we are
            showing you it in a modal so that you don’t miss out on reading this
            information. Please update the 8seats app from the store to continue
            using it.
          </AppText>
          <LinearGradient
            colors={[Colors.neutral40, 'rgba(151, 151, 151, 0)']}
            style={styles.divider1}
          />
          <TouchableOpacity
            style={styles.refreshButton}
            onPress={() => {
              setCriticalModalVisible(false);
              navigation.dispatch(
                CommonActions.navigate({
                  name: 'MainNavigator',
                  params: { screen: 'Discover' },
                }),
              );
            }}
            activeOpacity={0.7}>
            <Text style={styles.refreshButtonText}>Read more</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'relative',
  },
  modalContent: {
    backgroundColor: Colors.neutral0,
    borderRadius: 18,
    paddingVertical: 20,
    paddingHorizontal: 25,
    width: 335,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 40,
  },
  modalTitle: {
    fontWeight: '700',
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 15,
    paddingTop: 16,
  },
  modalText: {
    lineHeight: 19,
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 30,
  },
  divider1: {
    width: 335,
    height: 20,
    opacity: 0.25,
  },
  divider2: {
    width: 315,
    height: 1,
    backgroundColor: Colors.neutral80,
    opacity: 0.25,
  },
  refreshButton: {
    paddingHorizontal: 24,
    alignItems: 'center',
    marginVertical: 16,
    gap: 8,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshButtonText: {
    fontSize: 17,
    color: Colors.peppermint,
  },
});
