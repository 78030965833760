import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import formatChatDateToString from '@/utilities/lib/formatChatDateToString';

type StickyDateState = {
  conversationStickyDates: Map<string, string>;
  getConversationStickyDate: (conversationId: string) => string | undefined;
  setConversationStickyDate: (conversationId: string, date: string) => void;
};

const newState = {
  conversationStickyDates: new Map(),
};

export const useStickyDateStore = create<StickyDateState>()(
  immer((set, get) => ({
    ...newState,
    getConversationStickyDate: (conversationId: string) => {
      const date = get().conversationStickyDates.get(conversationId);
      if (date) {
        return formatChatDateToString(date);
      }
    },
    setConversationStickyDate: (conversationId: string, date: string) => {
      set(state => {
        state.conversationStickyDates.set(conversationId, date);
      });
    },
  })),
);
