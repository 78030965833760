import { InviteTargetType } from '@/models';
import { ChatUnitType } from '@/models';

export const InviteTypeConversion = {
  [InviteTargetType.QUICK_CHAT]: ChatUnitType.QUICK_CHAT,
  [InviteTargetType.TABLE]: ChatUnitType.TABLE,
  [InviteTargetType.ROOM]: ChatUnitType.ROOM,
  [InviteTargetType.ORGANISATION]: undefined,
};

export const ChatUnitInviteTypeConversion = {
  [ChatUnitType.QUICK_CHAT]: InviteTargetType.QUICK_CHAT,
  [ChatUnitType.TABLE]: InviteTargetType.TABLE,
  [ChatUnitType.ROOM]: InviteTargetType.ROOM,
};
