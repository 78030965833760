import type { Invite } from '@/domain/invites/types/types';
import { factory } from '../factory/factory';
import { logger } from '../logger/logger';

export const parseInviteJson = (input: any): Invite | undefined => {
  let newInvite;

  try {
    const parsedInput = {
      ...input,
      inviteeInfo: input?.inviteeInfo?.map((inviteeInfoItem: string) =>
        inviteeInfoItem ? JSON.parse(inviteeInfoItem) : {},
      ),
      inviterInfo: input?.inviterInfo
        ? JSON.parse(JSON.parse(input?.inviterInfo))
        : {},
      targetInfo: input?.targetInfo
        ? JSON.parse(JSON.parse(input?.targetInfo))
        : {},
    };
    newInvite = factory.invite(parsedInput);
  } catch (e) {
    logger.error('parseInviteJson::', e);
  }

  return newInvite;
};
