import { addMessageCommandsListener } from '@/domain/commands/events/addMessageCommandsListener';
import { useConversationEventListener } from '@/domain/conversation/events/useConversationEventListener';
import { useInviteLoadedEventListener } from '@/domain/invites/events/useInviteLoadedEventListener';
import { useTableEventListeners } from '@/domain/table/events/useTableEventListeners';
import { useSubscribeUserEvents } from '@/domain/user/events/useSubscribeUserEvents';

export const useEventListenersBootstrap = () => {
  useSubscribeUserEvents();
  useTableEventListeners();
  useConversationEventListener();
  addMessageCommandsListener();
  useInviteLoadedEventListener();
};
