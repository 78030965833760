import { yupResolver } from '@hookform/resolvers/yup';
import { CommonActions } from '@react-navigation/native';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as yup from 'yup';

import { type ChatUnitColorId, ChatUnitType } from '../../API';

import BackButtonLight from '@/components/BackButtonLight';
import MainButton from '@/components/MainButton';
import OutlinedButton from '@/components/OutlinedButton';
import ChatUnitBackgroundImagePicker from '@/components/chat-unit-background-image-picker';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import TableColorPicker from '@/components/table-color-picker';
import TextAreaInput from '@/components/text-area-input';
import useNavigation from '@/hooks/useNavigation';
import type { MainStackScreenProps } from '@/navigation/types';
import type { ChatUnit } from '@/services/chatUnit/types';
import { updateChatUnitDetails } from '@/services/datastore/chatUnit/updateTableDetails';
import { logger } from '@/services/logger/logger';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Spacer } from '@/ui/app';
import { Greys } from '@/ui/common/colors';
import { DEFAULT_CHAT_UNIT_PICKER_IMAGE } from '@/utilities/constants/chat-unit-background-images';
import {
  type TableColor,
  tableColors,
} from '@/utilities/constants/table-colors';

const CreateTableParameters = ({
  navigation,
  route,
}: MainStackScreenProps<'CreateTableParameters'>) => {
  const { table } = route.params;

  const { goBack } = useNavigation();
  const { translate } = useTranslations();
  const type = table.type ?? ChatUnitType.TABLE;
  const translatedType = translate(type.toLowerCase());
  const capitalizedTranslatedType = translate(type);

  const schema = yup.object().shape({
    backgroundImage: yup.string().default(DEFAULT_CHAT_UNIT_PICKER_IMAGE),
    color: yup.string().default(tableColors[0]?.id),
    description: yup
      .string()
      .max(
        200,
        // TODO: this is the wrong translation phrase
        translate('create_table_title_max_reached', {
          capitalized_type: capitalizedTranslatedType,
        }),
      )
      .default(''),
  });
  const resolver = yupResolver(schema, { abortEarly: true });
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    // TODO: Use errors to show message
  } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      backgroundImage: DEFAULT_CHAT_UNIT_PICKER_IMAGE,
      color: tableColors[0]?.id,
      description: '',
    },
    resolver,
  });
  const openChatView = (paramTable: ChatUnit) => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: 'MainNavigator' },
          {
            name: 'ChatView',
            params: { chatUnitId: paramTable.id },
          },
        ],
      }),
    );
  };

  const onSkip = () => {
    openChatView(table);
  };
  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    try {
      const { backgroundImage, color, description } = data;
      const updatedTable = await updateChatUnitDetails(table.id, {
        organisationId: table.organisationId,
        backgroundImage,
        colorId: color as unknown as ChatUnitColorId,
        description,
        id: table.id,
      });
      if (!updatedTable) {
        return;
      }
      openChatView(updatedTable);
    } catch (err) {
      logger.error('error', err);
    }
  };

  const { top } = useSafeAreaInsets();

  return (
    <KeyboardAwareScrollView>
      <View style={styles.container}>
        <View
          style={[styles.tableHeader, { height: 50 + top, paddingTop: top }]}>
          <View style={styles.emptyView}>
            <BackButtonLight onPress={goBack} />
          </View>
          <View style={styles.titleView}>
            <Text style={styles.headerTitle}>
              {translate('create_table_header_title', {
                capitalized_type: capitalizedTranslatedType,
              })}
            </Text>
          </View>
          <View style={styles.emptyView} />
        </View>
        <ScrollView
          contentContainerStyle={styles.content}
          style={styles.contentContainer}>
          <View style={styles.titlesContainer}>
            <Image
              source={require('@/assets/logo_tables.png')}
              style={styles.image}
            />
            <Text style={styles.title}>{table.title}</Text>
            <View style={styles.smallTitles}>
              <Text style={styles.smallTitle}>
                {translate('create_table_parameters_title', {
                  type: translatedType,
                })}
              </Text>
              <Text style={styles.smallSubtitle}>
                {translate('create_table_parameters_subtitle', {
                  type: translatedType,
                })}
              </Text>
            </View>
            <OutlinedButton
              onPress={onSkip}
              style={styles.skipButton}
              title={translate('create_table_parameters_skip_button_title', {
                type: translatedType,
              })}
              titleStyle={styles.skipButtonTitle}
            />
          </View>
          <View style={styles.formContainer}>
            <Text style={styles.formTitle}>
              {translate('create_table_parameters_table_settings_title', {
                type: translatedType,
              })}
            </Text>
            <Text style={styles.formDescriptionCaption}>
              {translate('create_table_parameters_description_description', {
                type: translatedType,
              })}
            </Text>
            <View style={styles.separator} />
            <Controller
              control={control}
              name={'description'}
              render={({ field: { onBlur, onChange, value } }) => (
                <TextAreaInput
                  label={translate('create_table_title_placeholder')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  style={styles.formDescriptionContainer}
                  title={translate(
                    'create_table_parameters_description_field_title',
                    { capitalized_type: capitalizedTranslatedType },
                  )}
                  value={value}
                />
              )}
            />
            <View style={styles.separator} />
            <View style={styles.height30} />
            <Controller
              control={control}
              name={'color'}
              render={({ field: { onChange, value } }) => {
                const onColorChange = (color: TableColor) => {
                  onChange(color.id);
                };
                return (
                  <TableColorPicker
                    onChange={onColorChange}
                    title={translate(
                      'create_table_parameters_choose_theme_field_title',
                    )}
                    value={value as ChatUnitColorId}
                  />
                );
              }}
            />
            <Spacer height={20} />
            <Controller
              control={control}
              name={'backgroundImage'}
              render={({ field: { onChange, value } }) => (
                <ChatUnitBackgroundImagePicker
                  onChange={onChange}
                  tableId={table.id}
                  title={translate(
                    'create_table_parameters_choose_theme_field_title',
                  )}
                  value={value}
                />
              )}
            />
          </View>
          <View style={styles.bottomContainer}>
            <MainButton
              disabled={isSubmitting}
              loading={isSubmitting}
              onPress={() => {
                handleSubmit(onSubmit)();
              }}
              title={translate(
                'create_table_parameters_continue_button_title',
                { type: translatedType },
              )}
            />
          </View>
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    marginBottom: 40,
    width: '100%',
  },
  container: { backgroundColor: Greys.shade600, flex: 1 },
  content: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentContainer: {
    flex: 1,
  },
  emptyView: { flex: 1 },
  formContainer: {
    backgroundColor: Greys.shade0,
    marginTop: 30,
    width: '100%',
  },
  formDescriptionCaption: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 17,
    marginTop: 30,
    paddingHorizontal: 20,
  },
  formDescriptionContainer: {
    marginBottom: 10,
    marginTop: 10,
    paddingHorizontal: 20,
  },
  formTitle: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 21,
    fontWeight: '700',
    marginTop: 20,
    paddingHorizontal: 20,
    textAlign: 'left',
  },
  headerTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  height30: {
    height: 30,
  },
  image: {
    height: 80,
    width: 80,
  },
  separator: {
    backgroundColor: Greys.shade600,
    height: 1,
    opacity: 0.1,
    width: '100%',
  },
  skipButton: {
    width: wp(48),
  },
  skipButtonTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
  },
  smallSubtitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginTop: 10,
    textAlign: 'center',
  },
  smallTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'center',
  },
  smallTitles: {
    marginTop: 40,
  },
  tableHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
    paddingHorizontal: 21,
  },
  tableUrlTitle: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 10,
    marginTop: 30,
    paddingHorizontal: 20,
  },
  title: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 35,
    fontWeight: '700',
    marginTop: 15,
    textAlign: 'center',
  },
  titleView: {
    alignItems: 'center',
    flex: 1,
    marginBottom: 6,
  },
  titlesContainer: {
    alignItems: 'center',
    marginHorizontal: 40,
  },
});
export default CreateTableParameters;
