import { Alert, Linking, PermissionsAndroid } from 'react-native';
import Contacts from 'react-native-contacts';

import { logger } from '../logger/logger';

import { getOrganisationsByUser } from '@/domain/auth/helpers/getOrganisationByUser';
import { android, ios } from '@/utilities/platform';
import { getUsersAdditionalDetails } from './helpers/getUsersAdditionalDetails';

const loadAllDeviceContacts = async () => {
  const contactsList = await Contacts.getAll();
  return contactsList || [];
};

const fetchDeviceContacts = async () => {
  try {
    if (ios) {
      return loadAllDeviceContacts();
    } else if (android) {
      return loadAllDeviceContactsWithPermissionsAndroid();
    }
    return [];
  } catch (error) {
    logger.error('fetchDeviceContacts::Error :', error);
    Alert.alert('Permission Denied', 'You can enable permissions in settings', [
      {
        style: 'cancel',
        text: 'Cancel',
      },
      {
        onPress: () =>
          Linking.openSettings().catch(() =>
            console.warn('cannot open settings'),
          ),
        text: 'Open Settings',
      },
    ]);
    return [];
  }
};

const loadAllDeviceContactsWithPermissionsAndroid = async () => {
  const permission = PermissionsAndroid.PERMISSIONS.READ_CONTACTS;
  const hasPermission = await PermissionsAndroid.request(permission);
  if (hasPermission === PermissionsAndroid.RESULTS.GRANTED) {
    return loadAllDeviceContacts();
  }
  return [];
};

const fetchOrganisationsUsers = async () => {
  try {
    const organisationsData = await getOrganisationsByUser();
    return (
      organisationsData
        // Get all users from all organisations
        .flatMap(org => org.users)
        // Remove any falsy values
        .filter(Boolean)
    );
  } catch (error) {
    logger.error('fetchOrganisationsUsers::Error :', error);
    return [];
  }
};

const getUsersDetails = async (userIdsOrPhoneNumbers: string[]) => {
  try {
    const response = await getUsersAdditionalDetails(userIdsOrPhoneNumbers);
    return response;
  } catch (error) {
    logger.error('getUsersAdditionalDetails::Error :', error);
    return [];
  }
};

const ContactsService = {
  fetchDeviceContacts,
  fetchOrganisationsUsers,
  getUsersDetails,
};

export default ContactsService;
