import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type Source = 'messages' | 'chatUnitList' | 'seedActivities';

type State = {
  sources: Set<Source>;
  datastoreReady: boolean;
};

type Actions = {
  setLoading: (source: Source, loading: boolean) => void;
  isLoading: () => boolean;
  setDatastoreReady: (datastoreReady: boolean) => void;
};

/**
 * This store manages the state for the global loader displayed in the header
 * It is required as there could be multiple sources of loading at any given time
 * and we cannot clear the loading for all sources at once, there must manage
 * each loading source individually
 *
 * Usage:
 * You set a loading state for a source and the header will display a loading bar
 * The loading state for that source must be cleared when no loading for that source is complete
 * isLoading will be true if ANY source is set
 */
export const useLoadingStore = create<State & Actions>()(
  immer((set, get) => ({
    sources: new Set<Source>(),
    datastoreReady: false,
    setLoading: (source: Source, loading: boolean) => {
      if (loading) {
        set(state => {
          state.sources.add(source);
        });
      } else {
        set(state => {
          state.sources.delete(source);
        });
      }
    },
    isLoading: () => get().sources.size > 0,
    setDatastoreReady: (datastoreReady: boolean) => {
      set(state => {
        state.datastoreReady = datastoreReady;
      });
    },
  })),
);
