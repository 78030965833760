import type { VideoThumbnailsOptions } from 'expo-video-thumbnails';

const getThumbnailAsync = async (
  sourceFilename: string,
  options: VideoThumbnailsOptions,
) => {
  const thumbnail = await new Promise((resolve, reject) => {
    // @ts-ignore: Exists on web
    const video = document.createElement('video');
    video.src = sourceFilename;
    video.crossOrigin = 'anonymous';
    video.currentTime = (options.time || 1000) / 1000;

    video.onloadeddata = () => {
      // @ts-ignore: Exists on web
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/jpeg', options.quality || 1);

        resolve({
          uri: dataUrl,
          width: video.videoWidth,
          height: video.videoHeight,
        });
      } else {
        reject(new Error('Failed to get canvas context'));
      }
    };

    video.onerror = (error: any) => {
      reject(error);
    };
  });

  return thumbnail;
};

export default getThumbnailAsync;
