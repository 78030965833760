import { createContext } from 'react';
import type { ColorSchemeName } from 'react-native';

import { type Theme, themes } from '../themes';
import type { SchemeName } from '../types';

import { DEFAULT_THEME } from './constants';

export const ThemeContext = createContext<{
  preference: ColorSchemeName;
  scheme: SchemeName;
  switchScheme: (scheme: ColorSchemeName) => void;
  theme: Theme;
}>({
  preference: undefined,
  scheme: DEFAULT_THEME,
  switchScheme: () => {},
  theme: themes[DEFAULT_THEME],
});
