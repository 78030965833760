import { CMSProvider } from '@/domain/cms/CMSModule';
import { Amplify } from 'aws-amplify';
import { enableMapSet } from 'immer';
import React from 'react';
import { AppRegistry } from 'react-native';
import { KeyboardProvider } from 'react-native-keyboard-controller';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { enableFreeze } from 'react-native-screens';

import App from './App';
import { name as appName } from './app.json';
import './src/translations/index';
import awsExports from './src/aws-exports.js';

Amplify.LOG_LEVEL = 'DEBUG';
Amplify.configure(awsExports);
import './src/models';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

enableFreeze(true);

enableMapSet();

if (module.hot) {
  module.hot.accept();
}
const Application = () => (
  <SafeAreaProvider>
    <KeyboardProvider>
      <CMSProvider>
        <App />
      </CMSProvider>
    </KeyboardProvider>
  </SafeAreaProvider>
);

AppRegistry.registerComponent(appName, () => Application);

AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
