import React from 'react';
import {
  Dimensions,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Rive, { Alignment, Fit } from 'rive-react-native';

import { useConnect } from './useConnect';

import Retro from '@/assets/svg/retro.svg';
import { hp, wp } from '@/theme';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const WINDOW_WIDTH = Dimensions.get('window').width;
const _dotSize = 10;

const AnimatedDot = ({
  index,
  scrollX,
}: {
  index: number;
  scrollX: Animated.SharedValue<number>;
}) => {
  const style = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollX.value / WINDOW_WIDTH,
        [index - 1, index, index + 1],
        [0.2, 1, 0.2],
        Extrapolate.CLAMP,
      ),
      width: interpolate(
        scrollX.value / WINDOW_WIDTH,
        [index - 1, index, index + 1],
        [_dotSize * 1.5, _dotSize * 2.5, _dotSize * 1.5],
        Extrapolate.CLAMP,
      ),
    };
  });
  return <Animated.View style={[styles.activeDot, style]} />;
};

const Onboarding = () => {
  const {
    animatedRotationStyle,
    animationName,
    currentStep,
    handleSkipOrDone,
    isUnmounting,
    onTouchEnd,
    onTouchStart,
    riveRef,
    scrollX,
    skipStyle,
    slideTransitionStyle,
    slides,
    stateMachineName,
    subtitleOpacityStyle,
    textOpacityStyle,
  } = useConnect();
  const { bottom } = useSafeAreaInsets();
  const { translate } = useTranslations();

  const slidesContent = [
    <View style={styles.slideOneContainer}>
      <Text style={styles.titleText}>
        {translate('onboarding_slide_one_title')}
      </Text>
    </View>,
    <View style={styles.slideOneContainer}>
      <Text style={styles.titleText}>
        {translate('onboarding_slide_two_title')}
      </Text>
    </View>,
    <View style={styles.thirdSlideContainer}>
      <Animated.Text style={[styles.titleText, textOpacityStyle]}>
        {translate('onboarding_slide_three_title')}
      </Animated.Text>
      <Animated.Text style={[styles.lightTitleText, subtitleOpacityStyle]}>
        {translate('onboarding_slide_three_subtitle')}
      </Animated.Text>
    </View>,
    <View style={styles.thirdSlideContainer}>
      <Animated.Text
        style={[styles.lightTitleTextWithoutMargin, textOpacityStyle]}>
        {translate('onboarding_slide_three_title')}
      </Animated.Text>
      <Animated.Text style={[styles.titleTextWithMargin, subtitleOpacityStyle]}>
        {translate('onboarding_slide_three_subtitle')}
      </Animated.Text>
    </View>,
    <View style={styles.containerWithButton}>
      <Text style={styles.titleText}>
        {translate('onboarding_slide_four_title')}
      </Text>
      <Text style={styles.text}>
        {translate('onboarding_slide_four_subtitle')}
      </Text>
      <LinearGradient
        colors={['#359CA6', '#1E6E80']}
        end={{ x: 0, y: 1 }}
        start={{ x: 0, y: 0 }}
        style={styles.button}>
        <TouchableOpacity onPress={handleSkipOrDone} style={styles.touchable}>
          <Text style={styles.buttonTitle}>
            {translate('onboarding_get_started')}
          </Text>
        </TouchableOpacity>
      </LinearGradient>
    </View>,
  ];

  return (
    <View
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      style={styles.container}>
      <Animated.View
        style={[
          animatedRotationStyle,
          styles.image,
          { left: -wp(25), top: -hp(50) },
        ]}>
        {!isUnmounting && <Retro height={hp(110)} width={wp(150)} />}
      </Animated.View>
      <View style={styles.riveContainer}>
        <Rive
          alignment={Alignment.TopCenter}
          autoplay={true}
          fit={Fit.Cover}
          ref={riveRef}
          resourceName={animationName}
          stateMachineName={stateMachineName}
        />
      </View>
      <Animated.View style={[styles.infoContainer, slideTransitionStyle]}>
        <View
          style={[
            styles.slidesContainer,
            { width: wp(100) * slidesContent.length },
          ]}>
          {slidesContent.map((slide, index) => (
            <View key={index} style={styles.slide}>
              {slide}
            </View>
          ))}
        </View>
      </Animated.View>
      <View style={[styles.dotsContainer, { marginBottom: bottom + wp(5) }]}>
        <View style={styles.emptyRow} />
        <View style={styles.dotsRow}>
          {slides.map((slide, index) => {
            return <AnimatedDot index={index} key={index} scrollX={scrollX} />;
          })}
        </View>
        <View style={styles.skipRow}>
          <Pressable disabled={currentStep === 4}>
            <Animated.Text
              onPress={handleSkipOrDone}
              style={[styles.skipText, skipStyle]}>
              {translate('common_skip')}
            </Animated.Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default Onboarding;

export const styles = StyleSheet.create({
  activeDot: {
    alignSelf: 'flex-end',
    backgroundColor: '#1E6E80',
    borderRadius: 8,
    height: 10,
    marginRight: 12,
    width: 25,
  },
  button: {
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: hp(5),
    paddingHorizontal: 40,
  },
  buttonTitle: {
    color: Greys.shade0,
    fontSize: 17,
    fontWeight: '700',
  },
  container: {
    backgroundColor: Greys.shade0,
    flex: 1,
    justifyContent: 'center',
  },
  containerWithButton: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: wp(100),
  },
  dotsContainer: {
    alignItems: 'center',
    bottom: 0,
    flexDirection: 'row',
    height: 20,
    justifyContent: 'space-between',
    position: 'absolute',
    width: wp(100),
  },
  dotsRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: wp(60),
  },
  emptyRow: { width: wp(20) },
  image: { position: 'absolute' },
  inactiveDot: {
    alignSelf: 'flex-end',
    backgroundColor: '#91C2B4',
    borderRadius: 8,
    height: 10,
    marginRight: 12,
    width: 10,
  },
  infoContainer: { flex: 0.6 },
  lightTitleText: {
    color: Greys.shade800,
    fontSize: 21,
    fontWeight: '900',
    marginTop: 25,
    paddingHorizontal: wp(15),
    textAlign: 'center',
  },
  lightTitleTextWithoutMargin: {
    color: Greys.shade800,
    fontSize: 21,
    fontWeight: '900',
    textAlign: 'center',
  },
  riveContainer: { flex: 1, paddingTop: wp(20) },
  skipRow: {
    alignItems: 'center',
    width: wp(20),
  },
  skipText: {
    color: Greys.shade500,
    fontSize: 17,
    fontWeight: '400',
  },
  slide: {
    paddingHorizontal: wp(17),
    width: wp(100),
  },
  slideOneContainer: {
    paddingTop: 20,
  },
  slidesContainer: {
    flexDirection: 'row',
  },
  text: {
    color: Greys.shade800,
    fontSize: 21,
    fontWeight: '400',
    marginTop: 25,
    textAlign: 'center',
  },
  thirdSlideContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    height: '100%',
    marginTop: 25,
    position: 'absolute',
    width: wp(100),
  },
  titleText: {
    color: Greys.shade800,
    fontSize: 21,
    fontWeight: '900',
    lineHeight: 25,
    textAlign: 'center',
  },
  titleTextWithMargin: {
    color: Greys.shade800,
    fontSize: 21,
    fontWeight: '900',
    marginTop: 25,
    textAlign: 'center',
  },
  touchable: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});
