import React from 'react';
import { StyleSheet } from 'react-native';
import Rive, { Fit } from 'rive-react-native';

import { wp } from '@/theme/responsiveHelpers';

const Loader = () => {
  return (
    <Rive fit={Fit.Cover} resourceName={'loader'} style={styles.container} />
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    position: 'absolute',
    width: wp(100),
    zIndex: 1000,
  },
});

export default Loader;
