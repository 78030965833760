import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { Alert, KeyboardAvoidingView, StyleSheet, View } from 'react-native';
import type { PhoneNumber } from 'react-native-contacts';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import type { Contact } from '../../state/useContactStore';

import SecondaryHeader from '@/components/headers/SecondaryHeader';
import LottieLoader from '@/components/loaders/LottieLoader';
import { constants } from '@/constants';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { getMobileNumberForContact } from '@/domain/contact/functions/getMobileNumberForContact';
import ChooseMemberTypeSheet from '@/domain/invites/components/ChooseMemberTypeSheet';
import ChoosePhoneNumberSheet from '@/domain/invites/components/ChoosePhoneNumberSheet';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { useDefaultCallingCode } from '@/domain/user/hooks/useDefaultCallingCode';
import { useAppContacts } from '@/hooks/useAppContacts';
import type { Roles } from '@/models';
import type { MainStackScreenProps } from '@/navigation/types';
import type { ChatUnitUser } from '@/services/chatUnitUser/types';
import { eventBus } from '@/services/eventBus/eventBus';
import useTranslations from '@/translations/useTranslation';
import { Brand, Greys, Neutrals, Status } from '@/ui/common/colors';
import sleep from '@/utilities/helpers/sleep';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { isPendingMemberOfOrg } from '@/utilities/org/isPendingMemberOfOrg';
import { ChatDirectoryHeader } from './ChatDirectoryHeader';
import EmptyState from './emptyState';
import LexicalSectionList from './listView';
import SelectedContacts from './selectedContacts';

type Props = MainStackScreenProps<'Directory'>;

export const Directory = ({ route }: Props) => {
  const {
    chatUnitId,
    initialContacts = [],
    limit,
    sessionId, // used to identify the origin screen event listener
    title,
    type,
    orgId,
  } = route?.params ?? {};

  const { top } = useSafeAreaInsets();
  const defaultCountryCallingCode = useDefaultCallingCode();
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [showMemberTypeModal, setShowMemberTypeModal] = useState(false);
  const [contactToSelect, setContactToSelect] = useState<Contact>();
  const [selectedContacts, setSelectedContacts] =
    useState<Array<Contact | ContactInput>>(initialContacts);
  const {
    hasContacts,
    sectionedContacts,
    loading,
    loadAllContacts,
    searchQuery,
    setSearchQuery,
  } = useAppContacts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { translate } = useTranslations();
  const availableCount = limit
    ? Math.max(limit - selectedContacts.length, 0)
    : 0;
  const statusText = limit
    ? `${limit + 1 - availableCount}/${limit + 1}`
    : undefined;
  const numberOfNewInvites = limit! - availableCount;
  const organisations = useOrganisations();
  const [chatUsers, setChatUsers] = useState<ChatUnitUser[]>([]);

  useFocusEffect(
    useCallback(() => {
      setChatUsers(
        useTableUsersStore.getState().getTableUsersForTable(chatUnitId ?? ''),
      );
    }, []),
  );

  const chatUnit = useTableStore(
    useShallow(state => state.getTable(chatUnitId ?? '')),
  );
  const invitedNumbers = chatUsers.map(user => user.phoneNumber);

  const styles = getStyles(availableCount);

  const deselectContact = useCallback((contact: ContactInput) => {
    setSelectedContacts(prevValue => [
      ...prevValue.filter(selectedContact => selectedContact.id !== contact.id),
    ]);
  }, []);

  const handleContactSelect = useCallback(
    async (input: { contact: Contact; role?: Roles }) => {
      if (limit === undefined || limit > selectedContacts.length) {
        if (input.contact.phoneNumbers.length > 1) {
          setContactToSelect(input.contact);
          await sleep(constants.modalRaceConditionDelay);
          setShowPhoneNumbers(true);
        } else {
          const validMobileNumber = getMobileNumberForContact(
            input.contact,
            defaultCountryCallingCode,
          );

          if (!validMobileNumber) {
            Alert.alert(
              translate('unable_to_add_contact_alert_title', {
                name: input.contact.firstName,
              }),
              translate('unable_to_add_contact_alert_message') +
                '\n' +
                input.contact.phoneNumbers.map(p => p.number).join('\n'),
            );
            return;
          }
          setSelectedContacts(prevValue => [
            ...prevValue,
            {
              ...input.contact,
              phoneNumbers: [
                {
                  label: 'mobile',
                  number: validMobileNumber,
                },
              ],
              role: input?.role,
            },
          ]);
        }
      }
    },
    [defaultCountryCallingCode, limit, selectedContacts, translate],
  );

  const handleContactPress = useCallback(
    (contact: Contact) => {
      const indexOfSelectContact = selectedContacts.findIndex(
        existingSelectedContacts => contact.id === existingSelectedContacts.id,
      );

      if (indexOfSelectContact >= 0) {
        deselectContact(contact);
      } else {
        setContactToSelect(contact);
        if (
          !isActiveMemberOfOrg(
            organisations,
            orgId,
            contact.userId,
            contact.phoneNumbers[0]?.number,
          ) &&
          !isPendingMemberOfOrg(
            organisations,
            orgId,
            contact.userId,
            contact.phoneNumbers[0]?.number,
          ) &&
          chatUnit
        ) {
          setShowMemberTypeModal(true);
        } else {
          handleContactSelect({ contact });
        }
      }
    },
    [
      chatUnit,
      deselectContact,
      handleContactSelect,
      orgId,
      organisations,
      selectedContacts,
    ],
  );

  const handlePhoneSelect = useCallback(
    (phone: PhoneNumber) => {
      setShowPhoneNumbers(false);
      setSelectedContacts(alreadySelected => {
        if (!contactToSelect) {
          return alreadySelected;
        }
        // Find the index of the contact to select
        const indexOfSelectContact = alreadySelected.findIndex(
          existingSelectedContacts =>
            contactToSelect?.id === existingSelectedContacts.id,
        );

        if (indexOfSelectContact >= 0) {
          // Replace the contact with the new phone number
          return [
            ...alreadySelected.slice(0, indexOfSelectContact),
            {
              ...alreadySelected[indexOfSelectContact],
              phoneNumbers: [phone],
            },
            ...alreadySelected.slice(indexOfSelectContact + 1),
          ];
        } else {
          // Add the contact with the new phone number
          return [
            ...alreadySelected,
            {
              ...contactToSelect,
              phoneNumbers: [phone],
            },
          ];
        }
      });
      setContactToSelect(undefined);
    },
    [contactToSelect],
  );

  // NOTE: we cannot pass callbacks via navigation params, so use the event bus
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (sessionId) {
      eventBus.emit('directorySubmitted', {
        contacts: selectedContacts,
        sessionId, // used to identify the origin screen event listener
      });
    }
  }, [selectedContacts, sessionId]);

  return (
    <>
      {loading && <LottieLoader />}
      {!loading && (
        <View style={styles.container}>
          <View style={[styles.headerContainer, { paddingTop: top }]}>
            {type ? (
              <ChatDirectoryHeader
                chatUnitTitle={title}
                type={type}
                onDone={handleSubmit}
                statusText={statusText}
                isSubmitting={isSubmitting}
                numberOfNewInvites={numberOfNewInvites}
              />
            ) : (
              <SecondaryHeader
                backButtonText={'cancel_button_title'}
                title={title}
              />
            )}
          </View>

          {!hasContacts && !searchQuery ? (
            <EmptyState loadAllContacts={loadAllContacts} />
          ) : (
            <KeyboardAvoidingView
              behavior="padding"
              style={styles.listContainer}>
              <View style={styles.contactsWrapper}>
                <SelectedContacts
                  onContactPress={deselectContact}
                  selectedContacts={selectedContacts}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </View>

              <View style={styles.divider} />

              <View style={styles.listContainer}>
                <LexicalSectionList
                  invitedNumbers={invitedNumbers}
                  onContactPress={handleContactPress}
                  searchQuery={searchQuery}
                  sections={sectionedContacts}
                  selectedContacts={selectedContacts}
                  isLimitFull={availableCount === 0}
                />
              </View>
            </KeyboardAvoidingView>
          )}
        </View>
      )}
      <ChoosePhoneNumberSheet
        contact={contactToSelect}
        onPhoneSelect={handlePhoneSelect}
        open={showPhoneNumbers}
        setOpen={setShowPhoneNumbers}
      />
      <ChooseMemberTypeSheet
        open={showMemberTypeModal}
        setOpen={setShowMemberTypeModal}
        contact={contactToSelect}
        onMemberTypeSelect={handleContactSelect}
      />
    </>
  );
};

const getStyles = (availableCount?: number) =>
  StyleSheet.create({
    boldText: {
      fontWeight: '900',
      textAlign: 'center',
    },
    bottomContainer: {
      backgroundColor: Greys.shade0,
      borderTopEndRadius: 18,
      borderTopStartRadius: 18,
      paddingBottom: 40,
      shadowColor: Greys.shade999,
      shadowOffset: { height: -10, width: 0 },
      shadowOpacity: 0.2,
      shadowRadius: 10,
    },
    contactsWrapper: { padding: 10, width: '100%' },
    container: {
      backgroundColor: Greys.shade0,
      flex: 1,
    },
    divider: {
      backgroundColor: Greys.shade0,
      height: 1,
      width: '100%',
    },
    headerContainer: {
      backgroundColor: Greys.shade0,
    },
    listContainer: {
      flex: 1,
    },
    modalTopIndicator: {
      height: 0,
    },
    submitButton: {
      backgroundColor: Brand.primary75,
      borderRadius: 25,
      marginBottom: 10,
      padding: 8,
      width: '100%',
    },
    submitButtonText: {
      color: Greys.shade0,
      fontFamily: 'OpenSans',
      fontSize: 16,
      fontWeight: '700',
    },
  });
