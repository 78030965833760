import messaging from '@react-native-firebase/messaging';
import { useEffect, useState } from 'react';

import useUserStore from '@/domain/user/state/useUserStore';
import {
  checkAndRequestPushNotificationsPermissions,
  subscribeToTokenChanges,
  updateTokenRegistration,
} from '@/services/notifications';
import notificationHandler from '@/services/notifications/notificationHandler';
import useSubscribeToForegroundNotifications from '@/services/notifications/subscribeToForegroundNotifications';
import { android, app } from '@/utilities/platform';
import notifee, { AndroidImportance } from '@notifee/react-native';

const useNotifications = () => {
  const userId = useUserStore(state => state.user?.id);
  const [allowingNotifications, setAllowingNotifications] = useState<
    boolean | undefined
  >();

  useSubscribeToForegroundNotifications();

  useEffect(() => {
    if (!app || userId === undefined) {
      return () => {};
    }

    const setup = async () => {
      if (allowingNotifications === undefined) {
        setAllowingNotifications(
          await checkAndRequestPushNotificationsPermissions(),
        );
      }
      if (allowingNotifications !== undefined) {
        updateTokenRegistration(userId, allowingNotifications === true);
      }
    };
    setup();
    return subscribeToTokenChanges(userId, allowingNotifications === true);
  }, [allowingNotifications, userId]);

  // useEffect(() => {
  //   if (web) {
  //     return;
  //   }
  //   const unsubscribe = messaging().onMessage(async remoteMessage => {
  //     // Runs when notification received when the app is open
  //     console.log('FCM Message Data:', android && remoteMessage.data);
  //   });

  //   return unsubscribe;
  // }, []);

  useEffect(() => {
    const doIt = async () => {
      if (android) {
        // iPhone gets onForegroundEvent called instead
        // getInitialNotification: When the application is opened from a quit state.
        const message = await messaging()?.getInitialNotification(); // android only
        if (message) {
          notificationHandler(message.data);
        }
        // onNotificationOpenedApp: When the application is running, but in the background.
        messaging().onNotificationOpenedApp(remoteMessage => {
          notificationHandler(remoteMessage.data);
        });
        // create default channel for push notificaions. Required to customise the importance of the notification
        await notifee.createChannel({
          id: 'default',
          name: 'All notifications',
          lights: false,
          vibration: true,
          sound: 'default',
          importance: AndroidImportance.HIGH,
        });
      }
    };
    doIt();
  }, []);
};

export default useNotifications;
