import React, {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { StatusBar, StyleSheet, View } from 'react-native';
import {
  Gesture,
  GestureDetector,
  MouseButton,
} from 'react-native-gesture-handler';
import { KeyboardProvider } from 'react-native-keyboard-controller';
import LinearGradient from 'react-native-linear-gradient';
import PagerView, {
  type PagerViewOnPageScrollEvent,
} from 'react-native-pager-view';
import Animated, {
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { useChatViewContainer } from '../../hooks/useChatViewContainer';
import { useSwipeChatHeads } from '../../hooks/useSwipeChatHeads';
import ChatHeader from '../header/ChatHeader';

import WebArrowNavigation from './WebArrowNavigation';

import LoadingBar from '@/components/loaders/LoadingBar';
import { NoNetworkBanner } from '@/components/network/NoNetworkBanner';
import ChatView from '@/domain/chatUnit/components/view/ChatView';
import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useKeyboardTransitions } from '@/hooks/useKeyboardTransition';
import { useSwipe } from '@/hooks/useSwipe';
import type { ChatUnit } from '@/services/types';
import { Greys } from '@/ui/common/colors';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';
import { web } from '@/utilities/platform';

const linearGradientColors = ['rgba(0,0,0,0.2)', 'rgba(0,0,0,0)'];

type TableChatViewProps = {
  conversationId?: string;
  offset: SharedValue<number>;
  parentPagerRef: RefObject<PagerView>;
  chatUnit: ChatUnit;
  toggleInviteActions: () => void;
  toggleShowMembers: () => void;
  firstMessage?: string;
  uniqueId?: string;
};

const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedPager = web
  ? Animated.createAnimatedComponent(View)
  : Animated.createAnimatedComponent(PagerView);

const ChatPagerContainer = ({
  conversationId,
  offset,
  parentPagerRef,
  chatUnit,
  toggleInviteActions,
  toggleShowMembers,
  firstMessage,
  uniqueId,
}: TableChatViewProps) => {
  const { id: chatUnitId } = chatUnit;
  const { height: height2 } = useKeyboardTransitions();
  const pagerRef = useRef<PagerView>(null);
  const { pagerConversationIds, initialPageIndex } = useChatViewContainer({
    offset,
    conversationId,
    chatUnitId,
    pagerRef,
  });

  const loadingMessages = useMessageStore(
    useShallow(state => state.loadingMessages),
  );
  const onSwipeHeads = useSwipeChatHeads({ pagerRef, offset });
  const avatarContainerStyle = useAnimatedStyle(() => ({
    marginTop: height2.value,
    zIndex: 2,
  }));

  const handleSwipeLeft = useCallback(() => {}, []);
  const handleSwipeRight = useCallback(() => {
    'worklet';
    parentPagerRef?.current?.setPage(0);
  }, [parentPagerRef]);

  const { onTouchStart, onTouchEnd } = useSwipe(
    handleSwipeLeft,
    handleSwipeRight,
    1,
  );

  const storeApi = useActiveConversationStoreApi();

  const handleArrowFunction = useCallback((direction: 'left' | 'right') => {
    storeApi.getState().handleArrowNavigation(direction, offset, pagerRef);
  }, []);

  const handlePageScroll = useCallback(
    (ev: PagerViewOnPageScrollEvent) => {
      offset.value = ev.nativeEvent.offset + ev.nativeEvent.position;
    },
    [offset],
  );

  const chatPages = useMemo(
    () =>
      pagerConversationIds.map((id: string, index: number) => (
        <View
          key={index.toString()}
          style={styles.container}
          onTouchStart={index === 0 ? onTouchStart : undefined}
          onTouchEnd={index === 0 ? onTouchEnd : undefined}>
          <ChatView
            chatUnitId={chatUnitId}
            conversationId={id}
            index={index}
            offset={offset}
            handleArrowNavigation={handleArrowFunction}
            firstMessage={index === 0 ? firstMessage : undefined}
            uniqueId={uniqueId}
          />
        </View>
      )),
    [
      pagerConversationIds,
      onTouchStart,
      onTouchEnd,
      chatUnitId,
      offset,
      firstMessage,
      uniqueId,
    ],
  );

  const fling = useMemo(
    () =>
      Gesture.Pan()
        .activeOffsetX([-200, 200])
        .runOnJS(true)
        .blocksExternalGesture()
        .mouseButton(MouseButton.MIDDLE)
        .onEnd(() => {
          onSwipeHeads();
        }),
    [onSwipeHeads],
  );

  return (
    <KeyboardProvider>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />
        <View style={styles.imageContainer}>
          <AnimatedView style={avatarContainerStyle}>
            <GestureDetector gesture={fling}>
              <ChatHeader
                chatUnit={chatUnit}
                onAddPress={toggleInviteActions}
                offset={offset}
                pagerRef={pagerRef}
                targetIndex={offset}
                toggleShowMembers={toggleShowMembers}
              />
            </GestureDetector>
          </AnimatedView>
        </View>

        <NoNetworkBanner />

        <View style={styles.pagerContainer}>
          <LinearGradient
            colors={linearGradientColors}
            end={{ x: 0, y: 1 }}
            start={{ x: 0, y: 0 }}
            style={styles.topShadow}
          />
          <LoadingBar
            hidden={!loadingMessages}
            containerStyles={styles.loadingBar}
          />
          {chatPages?.length > 0 && (
            <AnimatedPager
              offscreenPageLimit={1}
              initialPage={initialPageIndex.current}
              onPageScroll={handlePageScroll}
              ref={web ? undefined : pagerRef}
              style={styles.pagerContainer}>
              {chatPages}
            </AnimatedPager>
          )}
        </View>
      </View>
    </KeyboardProvider>
  );
};

export default ChatPagerContainer;

const { avatarSize } = getAvatarConfig();

const styles = StyleSheet.create({
  avatarTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: web ? 14 : 10,
    fontWeight: '700',
    marginTop: 4,
  },
  carouselContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  emptyAvatar: {
    backgroundColor: Greys.shade600,
    borderRadius: 20,
    height: avatarSize,
    marginRight: 8,
    width: avatarSize,
  },
  image: {
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    width: '100%',
    zIndex: 1000,
  },
  linearGradient: {
    left: 0,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  loadingBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  loadingContainer: {
    backgroundColor: Greys.shade0,
    flex: 1,
  },
  myAvatarContainer: { alignItems: 'center' },
  pagerContainer: {
    backgroundColor: Greys.shade0,
    flex: 1,
  },
  topShadow: {
    height: 90,
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  },
});
