import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ChatUnitType } from '../../../API';
import { sortConversationsByLatestMessageTime } from '../functions/sortConversationsByLatestMessageTime';
import { sortConversationsForRoom } from '../functions/sortConversationsForRoom';

import { useMessageStore } from './useMessageStore';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import type { Conversation } from '@/services/types';

type ConversationState = {
  focussedConversationId?: string;
  conversations: Map<
    string, // chatUnitId
    Map<
      string, // conversationId
      Conversation
    >
  >;
  lastUpdated: null | Date;
  clearState: () => void;
  setLastUpdated: (lastUpdated: Date) => void;
  getConversation: (
    chatUnitId: string,
    conversationId: string,
  ) => Conversation | undefined;
  getConversations: () => Conversation[];
  getConversationsByTableId: (tableId: string) => Conversation[];
  getArchivedConversationsByTableId: (chatUnitId: string) => Conversation[];
  getNonArchivedConversationsByTableId: (chatUnitId: string) => Conversation[];
  isArchivedConversation: (
    chatUnitId: string,
    conversationId: string,
  ) => boolean;
  setConversationData: (data: ConversationState['conversations']) => void;
  setFocussedConversationId: (conversationId: string) => void;
  upsertConversations: (
    chatUnitConversations: {
      chatUnitId: string;
      conversations: Conversation[];
    }[],
  ) => void;
};

const newState = {
  conversations: new Map(),
  lastUpdated: null,
};

export const useConversationStore = create<ConversationState>()(
  persist(
    immer((set, get) => ({
      ...newState,
      clearState: () => {
        set(() => ({ ...newState }));
      },
      setLastUpdated: (lastUpdated: Date) =>
        set(state => {
          state.lastUpdated = lastUpdated;
        }),
      getConversation(chatUnitId, conversationId) {
        return get().conversations?.get(chatUnitId)?.get(conversationId);
      },
      getConversations() {
        // return a flat array of conversations
        const results = Array.from(get().conversations.values()).flatMap(
          conversations => Array.from(conversations.values()),
        );

        return results
          .map(conversation => {
            return {
              ...conversation,
              latestMessageTime: useMessageStore
                .getState()
                .getMostRecentMessage(
                  conversation.chatUnitId,
                  conversation.conversationId,
                )?.createdAt,
            };
          })
          .sort(sortConversationsByLatestMessageTime);
      },
      getConversationsByTableId: (chatUnitId: string) => {
        // includes all conversations including the archived ones. Archived conversations are later filtered in useActiveConversationStore
        // ignore all user conversation if type === TABLE
        const table = useTableStore.getState().getTable(chatUnitId);
        const ignoreConversationIds =
          table?.type === ChatUnitType.TABLE ||
          table?.type === ChatUnitType.QUICK_CHAT
            ? [table.allConversationId]
            : [];

        const chatUnitMap = get().conversations.get(chatUnitId);
        if (!chatUnitMap) {
          return [];
        }

        const results = [...chatUnitMap.values()]
          .filter(
            conversation =>
              conversation.chatUnitId === chatUnitId &&
              !ignoreConversationIds.includes(conversation.conversationId),
          )
          .map(conversation => {
            return {
              ...conversation,
              latestMessageTime: useMessageStore
                .getState()
                .getMostRecentMessage(chatUnitId, conversation.conversationId)
                ?.createdAt,
            };
          });

        // Sort conversations by most recent message time, or if for Room; put the All Conversation first
        return table?.type === ChatUnitType.ROOM
          ? sortConversationsForRoom(results, table.allConversationId)
          : results.sort(sortConversationsByLatestMessageTime);
      },
      // Only use this in tables
      getArchivedConversationsByTableId: (chatUnitId: string) => {
        const chatUnitMap = get().conversations.get(chatUnitId);
        if (!chatUnitMap || chatUnitMap.size === 0) {
          return [];
        }

        const tableUsers = useTableUsersStore
          .getState()
          .getAllTableUsersForTable(chatUnitId);
        const archivedUsers = tableUsers.filter(
          user => user.status === ChatUnitUserStatus.ARCHIVED,
        );

        const results = [...chatUnitMap.values()]
          .filter(conversation => {
            // Check if any of the conversation's allowed user IDs correspond to archived users
            return archivedUsers.some(
              archivedUser =>
                conversation.allowedUserIds?.includes(archivedUser.id) ||
                conversation.chatUnitUserIds?.includes(
                  archivedUser.chatUnitUserId,
                ),
            );
          })
          .map(conversation => ({
            ...conversation,
            latestMessageTime: useMessageStore
              .getState()
              .getMostRecentMessage(chatUnitId, conversation.conversationId)
              ?.createdAt,
          }));

        return results.sort(sortConversationsByLatestMessageTime);
      },
      getNonArchivedConversationsByTableId: (chatUnitId: string) => {
        const chatUnitMap = get().conversations.get(chatUnitId);
        if (!chatUnitMap || chatUnitMap.size === 0) {
          return [];
        }

        const tableUsers = useTableUsersStore
          .getState()
          .getAllTableUsersForTable(chatUnitId);
        const activeUserIds = new Set(
          tableUsers
            .filter(user => user.status !== ChatUnitUserStatus.ARCHIVED)
            .map(user => user.id),
        );

        const results = [...chatUnitMap.values()]
          .filter(conversation =>
            conversation.allowedUserIds?.every(userId =>
              activeUserIds.has(userId),
            ),
          )
          .map(conversation => ({
            ...conversation,
            latestMessageTime: useMessageStore
              .getState()
              .getMostRecentMessage(chatUnitId, conversation.conversationId)
              ?.createdAt,
          }));

        return results.sort(sortConversationsByLatestMessageTime);
      },
      isArchivedConversation: (chatUnitId: string, conversationId: string) => {
        const tableUsers = useTableUsersStore
          .getState()
          .getAllTableUsersForTable(chatUnitId);
        const archivedUsers = tableUsers.filter(
          user => user.status === ChatUnitUserStatus.ARCHIVED,
        );

        const conversation = get()
          .conversations.get(chatUnitId)
          ?.get(conversationId);

        if (!conversation) {
          return false;
        }

        return archivedUsers.some(archivedUser =>
          conversation.chatUnitUserIds?.includes(archivedUser.chatUnitUserId),
        );
      },
      setConversationData: (data: ConversationState['conversations']) =>
        set(state => {
          state.conversations = data;
        }),
      setFocussedConversationId: (conversationId: string) =>
        set(state => {
          state.focussedConversationId = conversationId;
        }),
      upsertConversations: (
        chatUnitConversations: {
          chatUnitId: string;
          conversations: Conversation[];
        }[],
      ) =>
        set(state => {
          for (const { chatUnitId, conversations } of chatUnitConversations) {
            if (!state.conversations.get(chatUnitId)) {
              state.conversations.set(chatUnitId, new Map());
            }
            for (const conversation of conversations) {
              state.conversations
                .get(chatUnitId)
                ?.set(conversation.conversationId, conversation);
            }
          }
          return state;
        }),
    })),
    {
      name: 'conversation-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
      partialize: state => ({
        conversations: state.conversations,
        lastUpdated: state.lastUpdated,
      }),
    },
  ),
);
