import React, { useState } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import ConfirmModal from './ConfirmModal';

import { red2 } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const DangerZone = () => {
  const { translate } = useTranslations();

  const [isConfirm, setIsConfirm] = useState(false);

  return (
    <View style={styles.coloredHeader}>
      <View style={styles.headerRow}>
        <Text style={styles.deleteHeader}>{translate('the_danger_zone')}</Text>
        <Text style={styles.deleteInfoText}>
          {translate('the_danger_zone_info')}
        </Text>
      </View>
      <View style={styles.deleteContentRow}>
        <TouchableOpacity onPress={() => setIsConfirm(true)}>
          <Text style={styles.deleteText}>{translate('delete_account')}</Text>
        </TouchableOpacity>
        <Text style={styles.deleteWarningText}>
          {translate('delete_account_warning')}
        </Text>
      </View>
      <Modal animationType="fade" transparent={true} visible={isConfirm}>
        <ConfirmModal close={() => setIsConfirm(false)} />
      </Modal>
    </View>
  );
};

export default DangerZone;

const styles = StyleSheet.create({
  coloredHeader: {
    backgroundColor: Greys.shade200,
    paddingBottom: 50,
  },
  deleteContentRow: {
    backgroundColor: Greys.shade0,
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  deleteHeader: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 14,
    textTransform: 'uppercase',
  },
  deleteInfoText: {
    color: Greys.shade500,
    fontSize: 11,
    lineHeight: 14,
    marginTop: 10,
  },
  deleteText: {
    color: red2,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 14,
  },
  deleteWarningText: {
    color: Greys.shade500,
    fontSize: 11,
    lineHeight: 11,
    marginTop: 10,
  },
  headerRow: {
    alignItems: 'flex-start',
    backgroundColor: Greys.shade200,
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
