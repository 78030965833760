import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgSentPlane = (props: SvgProps) => (
  <Svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      clipRule="evenodd"
      d="M.395.85a.96.96 0 0 1 .964-.097l22.08 10.08-.399.874.399.873-22.08 10.08a.96.96 0 0 1-1.352-.985l.96-8.16a.96.96 0 0 1 .185-.464l1.008-1.344-1.008-1.344a.96.96 0 0 1-.185-.464l-.96-8.16A.96.96 0 0 1 .395.85M23.04 11.708l.399.873a.96.96 0 0 0 0-1.747zm-2.312 0L2.113 3.208l.73 6.21 1.285 1.713a.96.96 0 0 1 0 1.152l-1.285 1.713-.73 6.21z"
      fill="#00B9B9"
      fillRule="evenodd"
    />
    <Path
      clipRule="evenodd"
      d="M1.442 9.743a.48.48 0 0 1 .521-.434l21.12 1.92a.48.48 0 0 1 0 .956l-21.12 1.92a.48.48 0 0 1-.086-.956l15.861-1.442-15.861-1.442a.48.48 0 0 1-.435-.522"
      fill="#00B9B9"
      fillRule="evenodd"
    />
    <Path
      clipRule="evenodd"
      d="M.685 1.233a.48.48 0 0 1 .669.12l6.025 8.64a.48.48 0 0 1 .061.122l.48 1.44a.48.48 0 0 1 0 .304l-.48 1.44a.48.48 0 0 1-.061.122l-6.025 8.64a.48.48 0 1 1-.788-.549l5.985-8.583.408-1.222-.408-1.223L.566 1.901a.48.48 0 0 1 .12-.668"
      fill="#00B9B9"
      fillRule="evenodd"
    />
  </Svg>
);
export default SvgSentPlane;
