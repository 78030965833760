import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';

const OnboardingORTextSeperator = () => {
  const { translate } = useTranslations();

  return (
    <View style={styles.seperatorContainer}>
      <View style={styles.seperatorLine}>
        <Spacer
          width={'100%'}
          height={1.5}
          bg={web ? Colors.neutral70 : Colors.neutral0}
        />
      </View>
      <AppText
        color={web ? Colors.neutral70 : Colors.neutral0}
        size={14}
        type="primary700">
        {translate('or').toUpperCase()}
      </AppText>
      <View style={styles.seperatorLine}>
        <Spacer
          width={'100%'}
          height={1.5}
          bg={web ? Colors.neutral70 : Colors.neutral0}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  heading: {
    paddingHorizontal: 45,
  },
  grow: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 40,
  },
  seperatorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: web ? '100%' : Dimensions.get('window').width - 80,
    gap: 10,
  },
  text: {
    zIndex: 2,
    position: 'absolute',
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
  },
  seperatorLine: {
    flex: 1,
  },
});

export default OnboardingORTextSeperator;
