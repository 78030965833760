import React from 'react';
import { Linking, StyleSheet, Text, View, type ViewStyle } from 'react-native';

import MarkDown from '@/components/MarkDown';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const SUPPORT_EMAIL = 'support@8seats.com';

const Support = ({ style }: { style?: ViewStyle }) => {
  const { translate } = useTranslations();
  const onLinkPress = (url: string) => {
    const openEmail = async () => {
      await Linking.openURL(`mailto:${url}`);
      return false;
    };
    openEmail();
    return false;
  };
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.title}>
        {translate('forgot_password_still_having_issues_title')}
      </Text>
      <MarkDown
        onLinkPress={onLinkPress}
        style={{
          body: styles.body,
        }}>
        {translate('forgot_password_still_having_issues_subtitle', {
          supportEmail: SUPPORT_EMAIL,
        })}
      </MarkDown>
    </View>
  );
};
const styles = StyleSheet.create({
  body: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'center',
  },
});
export default Support;
