import Clipboard from '@react-native-clipboard/clipboard';
import { downloadData } from 'aws-amplify/storage';
import moment from 'moment';
import type React from 'react';
import { useCallback } from 'react';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import ReactNativeBlobUtil from 'react-native-blob-util';
import Share from 'react-native-share';
import Svg, { Path, type SvgProps } from 'react-native-svg';

import type { CustomMessage } from './types';

import { BottomSheet } from '@/components/actionList/BottomSheet';
import DocumentService from '@/components/image/DocumentService';
import { deleteMessage } from '@/domain/conversation/services/deleteMessage';
import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import { Colors } from '@/domain/theme/Colors';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app/elements';
import { Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';
import downloadImage from '@/utilities/helpers/downloadImage';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { android, ios, web } from '@/utilities/platform';

const DeleteIcon = (props: any) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.667 5v-.833c0-.92.746-1.667 1.666-1.667h5c.92 0 1.667.746 1.667 1.667V5h2.5a.833.833 0 110 1.667h-.833v9.166a2.5 2.5 0 01-2.5 2.5H7.5a2.5 2.5 0 01-2.5-2.5V6.667h-.833a.833.833 0 010-1.667h2.5zm1.666-.833h5V5h-5v-.833zm-1.666 2.5H15v9.166c0 .46-.373.834-.833.834H7.5a.833.833 0 01-.833-.834V6.667z"
      fill={props.color || '#EB0000'}
    />
  </Svg>
);

const CopyIcon = (props: any) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11 5c0 .46-.373.833-.833.833H6.833a.833.833 0 010-1.666h3.334c.46 0 .833.373.833.833zM11 8.333c0 .46-.373.834-.833.834H6.833a.833.833 0 010-1.667h3.334c.46 0 .833.373.833.833zM6 11.667c0 .46.373.833.833.833h3.334a.833.833 0 000-1.667H6.833a.833.833 0 00-.833.834z"
      fill={props.color || '#262929'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.167 15.833a1.5 1.5 0 01-1.5-1.5v-12a1.5 1.5 0 011.5-1.5h8.666a1.5 1.5 0 011.5 1.5v1.834h1.834a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5H7.5a1.5 1.5 0 01-1.5-1.5v-1.834H4.167zm8-1.666a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5H4.833a.5.5 0 00-.5.5v10.667a.5.5 0 00.5.5h7.334zm2.166-8.334v8.5a1.5 1.5 0 01-1.5 1.5H7.667V17a.5.5 0 00.5.5H15.5a.5.5 0 00.5-.5V6.333a.5.5 0 00-.5-.5h-1.167z"
      fill={props.color || '#262929'}
    />
  </Svg>
);

const ReplyIcon = (props: any) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.833 2.5H3.5a1.667 1.667 0 00-1.667 1.667v13.666a.5.5 0 00.5.5h.127a.5.5 0 00.353-.146l3.04-3.04A.5.5 0 016.208 15h10.626a1.666 1.666 0 001.667-1.667V4.167A1.667 1.667 0 0016.833 2.5zm0 10.833H5.373a.5.5 0 00-.353.147l-.666.666a.5.5 0 01-.854-.353V4.667a.5.5 0 01.5-.5h12.833"
      fill={props.color || '#262929'}
    />
  </Svg>
);

const ShareIcon = (props: any) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15.833 12.5a2.5 2.5 0 11-2.48 2.191L7.568 11.8a2.5 2.5 0 110-3.599l5.783-2.891a2.5 2.5 0 11.746 1.49L8.314 9.691a2.525 2.525 0 010 .618l5.784 2.892c.45-.434 1.061-.701 1.735-.701z"
      fill={props.color || '#262929'}
    />
  </Svg>
);

const SaveIcon = (props: any) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.5 3.5v13h-12v-13h12zm0-1.5h-12a1.5 1.5 0 00-1.5 1.5v13a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-13a1.5 1.5 0 00-1.5-1.5z"
      fill={props.color || '#262929'}
    />
    <Path
      d="M13.5 9.5h-6v-3h6v3zm-1-2h-4v1h4v-1z"
      fill={props.color || '#262929'}
    />
  </Svg>
);

export interface MessageOptionsSheetProps {
  message?: CustomMessage;
  isOwner: boolean;
  open: boolean;
  onClose: () => void;
}

const MessageOptionsSheet = ({
  open,
  onClose,
  message,
  isOwner,
}: MessageOptionsSheetProps) => {
  const { translate } = useTranslations();
  const activeConversationStoreApi = useActiveConversationStoreApi();
  const imageKey = message?.currentMessage?.image ?? '';
  const messageId = message?.currentMessage?._id ?? '';
  const messageText = message?.currentMessage?.text ?? '';
  const messageUserId = message?.currentMessage?.user?._id ?? '';
  const messageSK = message?.currentMessage?.SK ?? '';
  const isDocument = ImageKeyHelpers.isImageKeyDocument(imageKey);

  const handleCopyText = useCallback(() => {
    if (messageText) {
      Clipboard.setString(messageText);
    }
    onClose();
  }, [onClose, messageText]);

  const handleCopy = useCallback(async () => {
    if (imageKey) {
      if (Platform.OS === 'android') {
        const localPath =
          await DocumentService.getLocalFileSystemPathByImageKey(imageKey);
        if (!localPath) {
          return;
        }
        const fileUri = `file://${localPath}`;
        await downloadImage(fileUri);
        onClose();
        return;
      }
      const base64 =
        await DocumentService.getBase64FromLocalFilePathByImageKey(imageKey);

      if (base64) {
        Clipboard.setImage(base64);
      }
      onClose();
    } else {
      handleCopyText();
    }
  }, [handleCopyText, imageKey, onClose]);

  const handleReply = useCallback(() => {
    if (message) {
      activeConversationStoreApi
        .getState()
        .setReplyToMessage(message.currentMessage);
      onClose();
    }
  }, [message, activeConversationStoreApi, onClose]);

  const handleDeleteMessage = useCallback(async () => {
    try {
      if (messageUserId && messageSK) {
        deleteMessage({ userId: messageUserId, SK: messageSK });
      }
    } catch (e) {
      logger.error('Error deleting message');
    }
    onClose();
  }, [onClose, messageUserId, messageSK]);

  const handleShare = useCallback(async () => {
    if (imageKey && !isDocument) {
      const path =
        await DocumentService.getLocalFileSystemPathByImageKey(imageKey);
      const fileUrl = `file://${path}`;
      if (ios) {
        const currentDateFormatted = moment().format('YYYY-MM-DD-HH-mm-ss');

        await Share.open({
          url: fileUrl,
          filename: `8seats_${currentDateFormatted}`,
          failOnCancel: false,
        });
      } else if (android) {
        let res;
        if (fileUrl.startsWith('https')) {
          const response = await fetch(fileUrl);
          res = await response.blob();
        } else {
          res = await ReactNativeBlobUtil.fs.readFile(fileUrl, 'base64');
        }
        let extension = imageKey?.split('.').pop()!;
        if (extension?.includes('jpg')) {
          extension = 'jpeg';
        }
        const base64 = `data:image/${extension};base64,${res}`;
        const fileName = imageKey?.split('/').pop();

        await Share.open({
          url: base64,
          type: `image/${extension}`,
          filename: `${fileName}.${extension}`,
          failOnCancel: false,
          showAppsToView: true,
        });
      }
    } else {
      Share.open({
        message: messageText || '',
        failOnCancel: false,
      });
    }
  }, [imageKey, isDocument, messageText]);

  const handleSaveImageWeb = useCallback(async () => {
    if (imageKey && web) {
      try {
        const downloadState = await downloadData({ key: imageKey }).result;
        const { body } = downloadState;
        const blob = await body.blob();
        const url = URL.createObjectURL(blob);
        //@ts-ignore
        const a = document.createElement('a');
        a.href = url;
        a.download = imageKey.split('/').pop() || 'image';
        //@ts-ignore
        document.body.appendChild(a);
        a.click();
        //@ts-ignore
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        logger.error('Error saving image on web:', error);
      }
    }
    onClose();
  }, [imageKey, onClose]);

  if (!messageId || (!messageText && !imageKey) || !open) {
    return null;
  }

  const imageExists = imageKey && !isDocument;
  const isTextMessage = messageText.length > 0;

  return (
    <BottomSheet open={open} setOpen={onClose}>
      <ScrollView style={styles.sheetScroll}>
        <View style={styles.row}>
          {!imageExists && (
            <Box
              Icon={ReplyIcon}
              text={translate('Reply')}
              onPress={handleReply}
            />
          )}
          {imageExists && (
            <Box
              Icon={SaveIcon}
              text={translate('save_image')}
              onPress={web ? handleSaveImageWeb : handleCopy}
            />
          )}
          {isTextMessage && (
            <>
              {<Spacer width={1} height="100%" bg={`${Neutrals.shade200}`} />}
              <Box
                Icon={CopyIcon}
                text={translate('copy_text')}
                onPress={handleCopyText}
              />
            </>
          )}
          {!web && (
            <>
              <Spacer width={1} height="100%" bg={`${Neutrals.shade200}`} />
              <Box
                Icon={ShareIcon}
                text={translate('share')}
                onPress={handleShare}
              />
            </>
          )}
          {isOwner && (
            <>
              <Spacer width={1} height="100%" bg={`${Neutrals.shade200}`} />
              <Box
                Icon={DeleteIcon}
                text={translate('Delete')}
                onPress={handleDeleteMessage}
                textColor={Colors.rasberry}
              />
            </>
          )}
        </View>
      </ScrollView>
    </BottomSheet>
  );
};

type BoxProps = {
  Icon: React.ComponentType<{ color?: string }>;
  text: string;
  onPress: () => void;
  textColor?: Color | Colors;
};

const Box = ({ Icon, text, onPress, textColor }: BoxProps) => {
  return (
    <Pressable style={styles.box} onPress={onPress}>
      <Icon color={textColor} />
      <AppText size={14} color={textColor}>
        {text}
      </AppText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  sheetBg: {
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.lg,
    ...Platform.select({
      default: {
        elevation: 25,
      },
      ios: {
        shadowColor: Greys.shade999,
        shadowOffset: {
          height: 10,
          width: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 20,
      },
    }),
  },
  sheetScroll: {
    borderRadius: BorderRadius.lg,
  },
  box: {
    paddingVertical: 16,
    backgroundColor: Greys.shade0,
    gap: 10,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
});

export default MessageOptionsSheet;
