import { StyleSheet } from 'react-native';

import { Colors } from '@/domain/theme/Colors';
import { Greys } from '@/ui/common/colors';

export type StyleProps = {
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderWidth?: number;
  bottom?: number;
  fontSize?: 'sm' | 'md' | 'lg';
  height?: number;
  left?: number;
  position?: 'absolute' | 'relative';
  right?: number;
  top?: number;
  width?: number | 'auto';
};

export const getStyles = (
  {
    borderBottomLeftRadius = 15,
    borderBottomRightRadius = 15,
    borderTopLeftRadius = 15,
    borderTopRightRadius = 15,
    borderWidth = 2,
    bottom,
    fontSize = 'lg',
    height = 19,
    left,
    position = 'absolute',
    right = -11,
    top = -9,
    width = 19,
  }: StyleProps,
  hasContent: boolean,
) => {
  const badgeFontSize = fontSize === 'sm' ? 7 : fontSize === 'md' ? 9 : 11;
  return StyleSheet.create({
    badge: {
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderWidth,
      bottom,
      left,
      position,
      right,
      top,
      ...(width === 'auto'
        ? { alignSelf: 'flex-start', paddingLeft: 5, paddingRight: 5 }
        : {}),
      alignItems: 'center',
      backgroundColor: Colors.rasberry,
      borderColor: Greys.shade0,
      height,
      justifyContent: 'center',
      width,
      ...(hasContent ? {} : getDotBadgeStyles()),
    },
    badgeContainer: { zIndex: 1000 },
    badgeText: {
      color: Greys.shade0,
      fontSize: badgeFontSize,
      fontWeight: 'bold',
    },
  });
};

const getDotBadgeStyles = () => ({
  borderWidth: 1,
  height: 11,
  right: -6,
  top: -3,
  width: 11,
});
