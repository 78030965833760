import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useRef, useState } from 'react';

const useTimer = ({ initialMilliseconds = 0, intervalDuration = 1000 }) => {
  const timer = useRef<NodeJS.Timeout>();
  const [currentTime, setCurrentTime] = useState<number>(initialMilliseconds);
  const timerCallback = useCallback(() => {
    setCurrentTime(value => {
      if (value > 0) {
        return value - 1000;
      }
      stopTimer();
      return 0;
    });
  }, []);

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  const startTimer = useCallback(
    (time = initialMilliseconds) => {
      if (timer.current) {
        stopTimer();
      }
      if (!time) {
        return;
      }
      setCurrentTime(time);
      timer.current = setInterval(timerCallback, intervalDuration);
    },
    [initialMilliseconds, intervalDuration, timerCallback],
  );

  useFocusEffect(
    useCallback(() => {
      if (initialMilliseconds) {
        startTimer();
      }
      return () => {
        stopTimer();
      };
    }, [startTimer, initialMilliseconds]),
  );

  return {
    currentTime,
    isCompleted: currentTime === 0,
    restartTimer: startTimer,
    startTimer,
    stopTimer,
  };
};

export default useTimer;
