/**
 * Inserts newItem into the array sorted by CreatedAt DESC
 *
 * @param array
 * @param newItem
 * @returns void
 */
export const insertSorted = (
  array: { createdAt: number | Date }[],
  newItem: { createdAt: number | Date },
) => {
  // If the array is empty or newItem is newer than the first item, prepend it directly
  if (array.length === 0 || array[0].createdAt < newItem.createdAt) {
    array.unshift(newItem);
    return;
  }

  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (array[mid].createdAt > newItem.createdAt) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }

  // Insert newItem at the found position
  array.splice(low, 0, newItem);
};
