import { useCallback, useRef } from 'react';
import { Dimensions } from 'react-native';

export const useSwipe = (
  onSwipeLeft: () => void,
  onSwipeRight: () => void,
  rangeOffset = 6,
) => {
  const windowWidth = Dimensions.get('window').width;
  const initialTouch = useRef(0);

  const onTouchStart = useCallback((e: any) => {
    initialTouch.current = e.nativeEvent.pageX;
  }, []);

  const onTouchEnd = useCallback(
    (e: any) => {
      const endPositionX = e.nativeEvent.pageX;
      const swipeRange = windowWidth / rangeOffset;

      if (initialTouch.current - endPositionX > swipeRange) {
        onSwipeLeft();
      } else if (endPositionX - initialTouch.current > swipeRange) {
        onSwipeRight();
      }
    },
    [windowWidth, rangeOffset, onSwipeLeft, onSwipeRight],
  );

  return { onTouchEnd, onTouchStart };
};
