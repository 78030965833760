import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgRoomBack = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M46.963 97.836a5.234 5.234 0 0 0 6.073.001l36.953-26.332a5.233 5.233 0 0 0 2.169-3.733l4.508-44.418a5.233 5.233 0 0 0-3.205-5.363L52.001.828a5.235 5.235 0 0 0-4.002 0L6.539 17.991a5.233 5.233 0 0 0-3.205 5.363l4.508 44.418a5.233 5.233 0 0 0 2.169 3.733zm2.561-3.593L12.572 67.912a.815.815 0 0 1-.34-.585L7.724 22.909a.819.819 0 0 1 .502-.841l41.46-17.163a.823.823 0 0 1 .628 0l41.46 17.163a.82.82 0 0 1 .503.841l-4.508 44.418a.821.821 0 0 1-.34.585L50.477 94.243a.82.82 0 0 1-.953 0m4.799-66.391L36.675 45.499a2.207 2.207 0 0 0 0 3.12l17.648 17.647a2.206 2.206 0 0 0 3.119-3.12L41.355 47.059l16.087-16.087a2.206 2.206 0 0 0-3.119-3.12" />
  </Svg>
);
export default SvgRoomBack;
