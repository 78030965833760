import React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { Greys } from '@/ui/common/colors';

export const AddPersonIcon = ({
  size,
  color,
}: {
  size: number;
  color?: string;
}) => (
  <View style={{ height: size, width: size }}>
    <Svg
      height="100%"
      preserveAspectRatio="xMinYMin slice"
      width="100%"
      fill={color || Greys.shade600}
      viewBox="0 0 20 22">
      <Path
        clipRule="evenodd"
        d="M13 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fillRule="evenodd"
      />
      <Path d="M9 11a1 1 0 0 0-1 1v6H6v-6a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v6h-2v-6a1 1 0 0 0-1-1H9ZM4 4H2v2H0v2h2v2h2V8h2V6H4V4Z" />
    </Svg>
  </View>
);
