export enum ChatUnitUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
}

export type ChatUnitUser = {
  allowedUserIds?: string[];
  avatar: string;
  createdAt?: string;
  id: string; // userId TODO: FIX THIS!!
  owner?: string;
  chatUnitId: string;
  chatUnitUserId: string;
  updatedAt?: string;
  username: string;
  phoneNumber: string;
  // Note: status will be empty for existing chat users, we'll default to ACTIVE in the ChatUser factory
  status?: ChatUnitUserStatus;
  firstName?: string;
  lastName?: string;
};
