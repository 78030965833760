import { DataStore } from 'aws-amplify/datastore';

import { Invite } from '../../../models';

import { factory } from '@/services/factory/factory';

export const getInvites = async (lastUpdated: Date | null) => {
  let results;
  if (lastUpdated) {
    results = await DataStore.query(Invite, invite =>
      invite.updatedAt.gt(lastUpdated.toISOString()),
    );
  } else {
    results = await DataStore.query(Invite, invite => invite);
  }
  return results.map(item => factory.invite(item)) || [];
};
