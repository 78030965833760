import { DataStore } from 'aws-amplify/datastore';

import { ChatUnitUser } from '../../../models';

import { factory } from '@/services/factory/factory';
import type { ChatUnitUser as IChatUnitUser } from '@/services/types';

type AllowedInput = Partial<
  Pick<
    IChatUnitUser,
    'allowedUserIds' | 'avatar' | 'username' | 'phoneNumber' | 'status'
  >
>;

export const updateChatUnitUser = async (
  chatUnitUserId: string,
  chatUnitUser: AllowedInput,
) => {
  const existingChatUnitUser = await DataStore.query(
    ChatUnitUser,
    chatUnitUserId,
  );

  if (!existingChatUnitUser) {
    throw new Error(`TableUser with tableUserId ${chatUnitUserId} not found`);
  }

  const result = await DataStore.save(
    ChatUnitUser.copyOf(existingChatUnitUser, updated => {
      updated.allowedUserIds =
        chatUnitUser.allowedUserIds ?? existingChatUnitUser.allowedUserIds;
      updated.avatar = chatUnitUser.avatar ?? existingChatUnitUser.avatar;
      updated.username = chatUnitUser.username ?? existingChatUnitUser.username;
      updated.phoneNumber =
        chatUnitUser.phoneNumber ?? existingChatUnitUser.phoneNumber;
      updated.status = chatUnitUser.status ?? existingChatUnitUser.status;
    }),
  );

  return factory.chatUnitUser(result);
};
