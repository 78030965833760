import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import BackArrow from '@/assets/BackArrow.svg';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const BackButton = ({
  buttonText,
  onPress,
}: {
  buttonText?: string;
  onPress: () => void;
}) => {
  const { translate } = useTranslations();
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <BackArrow height={20} width={10} />
      <Text style={styles.text}>
        {buttonText ? translate(buttonText) : translate('common_back')}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginLeft: 10,
  },
});

export default BackButton;
