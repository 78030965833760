import { StyleSheet } from 'react-native';

import { primaryBlue, red } from '../../../../theme/colors';
import { wp } from '../../../../theme/responsiveHelpers';

import { Colors } from '@/domain/theme/Colors';
import { Greys } from '@/ui/common/colors';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  acceptedInvitation: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
  },
  acceptedInvitationImage: {
    borderColor: Greys.shade600,
    borderWidth: 1.5,
    height: 24,
    marginRight: 5,
    width: 24,
  },
  acceptedInvitationText: {
    color: Greys.shade600,
    fontSize: 14,
    marginRight: 5,
  },
  actionContainer: {
    paddingVertical: 2,
    width: 100,
  },
  actionText: {
    color: Greys.shade800,
    fontSize: 12,
    marginTop: 5,
  },
  activeSegment: {
    alignItems: 'center',
    backgroundColor: primaryBlue,
    flex: 1,
    justifyContent: 'center',
  },
  activeSegmentText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 11,
    fontWeight: '700',
    lineHeight: 18,
  },
  avatarInitials: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 8,
    fontWeight: '700',
  },
  background: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    height: web ? 50 : wp(15),
    justifyContent: 'space-between',
    paddingLeft: 12,
  },
  badge: {
    fontSize: 20,
    height: 0,
    left: -50,
    top: 50,
    width: 0,
    zIndex: 100,
  },
  badgeAccepted: {
    backgroundColor: Greys.shade0,
    borderColor: Greys.shade500,
    borderWidth: 1,
    paddingTop: 2,
  },
  badgeAcceptedText: {
    color: Greys.shade600,
  },
  badgeBtn: {
    alignItems: 'center',
    borderRadius: 20,
    height: 16,
    justifyContent: 'center',
    paddingHorizontal: 6,
  },
  badgeTxt: {
    fontSize: 10,
    fontWeight: '600',
    lineHeight: 10,
  },
  chatUnitIcon: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
  },
  container: {
    backgroundColor: Greys.shade200,
    flex: 1,
  },
  controlAndFilterContainer: {
    paddingHorizontal: 15,
    ...(web && { marginBottom: 20 }),
  },
  dateRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  declinedBadge: {
    backgroundColor: Greys.shade0,
    borderColor: red,
    borderWidth: 1,
    paddingTop: 2,
  },
  declinedBadgeText: {
    color: red,
    fontSize: 10,
    fontWeight: '600',
    lineHeight: 10,
  },
  emptyContainer: { width: '20%' },
  flashListContainer: {
    paddingHorizontal: 5,
    // paddingBottom: 50,
    // height: 30,
  },
  flex1: { flex: 1 },
  headerTitle: {
    color: Colors.neutral80,
    fontFamily: Fonts.primary700,
    fontSize: 16,
    textAlign: 'center',
  },
  imageBgContainer: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  imageContainer: { flex: 1, position: 'relative' },
  invitationDate: {
    color: Greys.shade400,
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 10,
  },
  invitationStatus: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 5,
    marginTop: 5,
  },
  invitationStatusContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  invitationStatusText: {
    color: Greys.shade600,
    fontSize: 12,
    marginLeft: 5,
  },
  inviteeDetailsRow: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  inviteeName: {
    color: primaryBlue,
    fontSize: 10,
    fontWeight: '700',
    lineHeight: 10,
    marginRight: 5,
  },
  inviteePhone: {
    color: Greys.shade500,
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 10,
  },
  inviteeRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 5,
  },
  itemButton: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    borderLeftColor: Greys.shade200,
    borderLeftWidth: 1,
    justifyContent: 'center',
    width: 24,
  },
  itemContainer: {
    elevation: 2,
    flexDirection: 'row',
    height: 'auto',
    paddingVertical: 2,
    backgroundColor: Greys.shade200,
    shadowColor: Greys.shade800,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    overflow: 'hidden',
    borderRadius: BorderRadius.sm,
  },
  itemContent: {
    backgroundColor: Greys.shade0,
    flex: 3,
    height: '100%',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  itemContentTime: {
    bottom: 0,
    position: 'relative',
  },
  itemHeaderText: {
    color: Greys.shade600,
    fontSize: 16,
  },
  itemImage: {
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
    flex: 1,
    height: '100%',
    width: '100%',
    zIndex: 999,
    overflow: 'hidden',
  },
  itemImageSent: {
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
    height: '100%',
    width: '100%',
    zIndex: 999,
  },
  itemSeparator: {
    height: 5,
  },
  itemSubtitle: {
    color: Greys.shade500,
    fontSize: 12,
    fontWeight: '400',
    marginBottom: 10,
  },
  itemTitle: {
    color: Greys.shade600,
    fontSize: 17,
    fontWeight: '700',
    marginBottom: 10,
  },
  leftAction: {
    alignItems: 'center',
    backgroundColor: red,
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
    height: '100%',
    justifyContent: 'center',
    width: 110,
  },
  newBadge: {
    alignItems: 'center',
    backgroundColor: Colors.rasberry,
    justifyContent: 'center',
    paddingTop: 1,
  },
  newBadgeText: {
    color: Greys.shade0,
  },
  noAvatar: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 25,
    height: 30,
    justifyContent: 'center',
    width: 30,
  },
  numberOfInvitedText: {
    color: Greys.shade600,
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 10,
  },
  pagerView: { flex: 1, marginTop: 8 },
  passiveSegment: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  passiveSegmentText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 11,
    fontWeight: '700',
    lineHeight: 18,
  },
  receivedItemHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 6,
    justifyContent: 'center',
    paddingVertical: 12,
  },
  receivedItemcontainer: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  },
  rightAction: {
    alignItems: 'center',
    backgroundColor: '#59FFAC',
    borderBottomRightRadius: 6,
    borderTopRightRadius: 6,
    height: '100%',
    justifyContent: 'center',
  },
  segmentedControl: {
    backgroundColor: Greys.shade0,
    borderRadius: 20,
    flexDirection: 'row',
    height: 30,
    overflow: 'hidden',
    width: '100%',
  },
  segmentedControlContainer: {
    flex: 1,
    marginTop: 20,
    paddingHorizontal: 5,
  },
  sentInviteeDetails: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
  },
  sentInviteeText: {
    color: Greys.shade600,
    justifyContent: 'center',
    marginLeft: 10,
  },
  separator: {
    height: 10,
  },
  titleRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  waitingBadge: {
    backgroundColor: Greys.shade200,
    paddingTop: 2,
  },
  waitingBadgeText: {
    color: Greys.shade500,
  },
});
