import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgPaperPlane = (props: SvgProps) => (
  <Svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.3.33A.678.678 0 01.983.261l15.6 7.122L16.3 8l.282.617-15.6 7.122a.678.678 0 01-.956-.697l.679-5.765a.678.678 0 01.13-.327L1.549 8l-.712-.95a.678.678 0 01-.131-.327L.026.958A.678.678 0 01.301.33zM16.3 8l.282.617a.678.678 0 000-1.234L16.3 8zm-1.633 0L1.514 1.996l.517 4.387.907 1.21c.181.241.181.573 0 .814l-.907 1.21-.517 4.387L14.667 8z"
      fill="#fff"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04 6.613a.34.34 0 01.369-.307L16.33 7.662a.34.34 0 010 .676L1.409 9.694a.34.34 0 01-.061-.675l11.206-1.02L1.348 6.982a.34.34 0 01-.308-.368z"
      fill="#fff"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.506.6a.34.34 0 01.472.084L5.235 6.79a.34.34 0 01.043.086l.34 1.018a.339.339 0 010 .214l-.34 1.018a.34.34 0 01-.043.086L.978 15.316a.34.34 0 01-.556-.388L4.65 8.864 4.938 8l-.288-.864L.422 1.072A.34.34 0 01.506.6z"
      fill="#fff"
    />
  </Svg>
);
export default SvgPaperPlane;
