import { Hub } from 'aws-amplify/utils';
import { useEffect } from 'react';

import { constants } from '@/constants';
import { postHogTrack } from '@/context/Experience';
import { logger } from '@/services/logger/logger';

type Data = { model?: string };

export const useDataStoreEventsListener = () => {
  useEffect(() => {
    const unsubscribe = Hub.listen('datastore', hubData => {
      const { data, event } = hubData.payload;

      if (constants.logDataStoreEvents) {
        logger.debug(`Datastore::event[${event}]`, data ?? '');
      }

      if (event === 'modelSynced' || event === 'networkStatus') {
        postHogTrack(event, {
          data,
          modal: (data as Data)?.model?.toString() ?? '',
        });
      }
    });

    return () => unsubscribe();
  }, []);
};
