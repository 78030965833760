import { useAnimatedStyle } from 'react-native-reanimated';

import { useKeyboardCustomHeightTransition } from './useKeyboardCustomHeightTransition';

export const useStyles = () => {
  const { height } = useKeyboardCustomHeightTransition();

  const scrollViewStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: height.value }],
    } as any;
  }, [height]);

  return {
    scrollViewStyle,
  };
};
