import React from 'react';

const Checkbox = ({ onValueChange, value, style }: any) => (
  <input
    type="checkbox"
    checked={value}
    onChange={event => {
      onValueChange(event.target.checked);
    }}
    style={style}
  />
);

export default Checkbox;
