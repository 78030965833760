import Section from '@/components/section/Section';
import type { PostButton as Props } from '@/domain/cms/graphql/generated/graphql';
import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { Image } from 'expo-image';
import { Linking } from 'react-native';

export default function PostButton(postButton: Props) {
  return (
    <Section
      br={BorderRadius.md}
      bw={1}
      bc={Colors.neutral80}
      pv={10}
      gap={10}
      onPress={() => Linking.openURL(postButton.link || 'https://8seats.com')}
      row
      jcc
      aic>
      {postButton.icon?.url ? (
        <Image
          source={{ uri: postButton.icon.url }}
          style={{ width: 32, height: 32 }}
          contentFit="contain"
        />
      ) : null}
      <AppText type="secondary500" size={17}>
        {postButton?.title}
      </AppText>
    </Section>
  );
}
