import signInImg from '@/assets/onboarding/signInBg.png';
import Section from '@/components/section/Section';
import Breakpoints from '@/domain/theme/Breakpoints';
import { Colors } from '@/domain/theme/Colors';
import OnboardingHeaderLeft from '@/navigation/components/OnboardingHeaderLeft';
import React, { type ReactNode, useEffect, useRef } from 'react';
import {
  Animated,
  type DimensionValue,
  Dimensions,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import OnboardingLinksFooter from './OnboardingLinksFooter';

type Props = {
  children: ReactNode;
};

const GRADIENT_BOTTOM = [`${Colors.ocean}00`, Colors.ocean];
const GRADIENT_TOP = [Colors.ocean, `${Colors.ocean}00`];

const linearGradientStartPosition = { x: 0.5, y: 0 };
const linearGradientEndPosition = { x: 0.5, y: 1 };

export default function OnboardingLoginWebLayout({ children }: Props) {
  const { width, height } = useWindowDimensions();
  const rotateAnim = useRef(new Animated.Value(0.0833)).current; // 30deg / 360deg = 0.0833

  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['30deg', '390deg'], // 30deg to 360deg + 30deg
  });

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 35000,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim, {
          toValue: 0,
          duration: 35000,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [rotateAnim]);

  const imageStyles = {
    ...styles.background,
    width: width * 1.1, // 10% larger than the screen width
    height: height * 1.1, // 10% larger than the screen height
    top: -(height * 0.05), // Move it up by half of the extra height
    left: -(width * 0.1), // Shift it a bit towards the left just to make it look good
    transform: [{ rotate }],
  };

  if (width < Breakpoints.sm) {
    return (
      <>
        <Section bg={Colors.neutral0} jcfs aifs p={25}>
          <OnboardingHeaderLeft />
        </Section>
        {children}
        <View style={styles.links}>
          <OnboardingLinksFooter color={Colors.neutral70} />
        </View>
      </>
    );
  }

  return (
    <Section f1 jcc aic bg={Colors.ocean} style={styles.screen}>
      <Section bg={Colors.neutral0} style={styles.back}>
        <OnboardingHeaderLeft />
      </Section>
      <View style={styles.webScreenView}>{children}</View>
      <Animated.Image source={signInImg} style={imageStyles} />
      <LinearGradient
        colors={GRADIENT_TOP}
        style={styles.backgroundGradientTop}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
      <LinearGradient
        colors={GRADIENT_BOTTOM}
        style={styles.backgroundGradientBottom}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
      <View style={styles.links}>
        <OnboardingLinksFooter color={Colors.neutral0} />
      </View>
    </Section>
  );
}

const styles = StyleSheet.create({
  webScreenView: {
    padding: 40,
    alignSelf: 'center',
    backgroundColor: Colors.neutral0,
    borderRadius: 36,
    maxWidth: Math.min(540, Dimensions.get('window').width - 40),
    zIndex: 2,
    position: 'absolute',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.4,
    shadowRadius: 12,
    maxHeight: 'calc(100% - 200px)' as DimensionValue,
  },

  background: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -4,
    width: Dimensions.get('window').width * 1.1, // 10% larger than the screen width
    height: Dimensions.get('window').height * 1.1, // 10% larger than the screen height
    resizeMode: 'contain',
    position: 'absolute',
    top: -(Dimensions.get('window').height * 0.05), // Move it up by half of the extra height
    left: -(Dimensions.get('window').width * 0.1), // Move it left by half of the extra width
  },

  screen: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  backgroundGradientBottom: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: '50%',
    width: '100%',
  },
  backgroundGradientTop: {
    top: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: '50%',
    width: '100%',
  },
  back: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: Colors.neutral0,
    position: 'absolute',
    zIndex: 2,
    top: 30,
    left: 30,
    borderRadius: 18,
  },
  links: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
  },
});
