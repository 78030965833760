import React from 'react';

import { ChatUnitType } from '../API';

import type { Colors } from '@/domain/theme/Colors';
import { Icon } from '@/ui/app/elements';
import type { Color } from '@/ui/common/types/color';

type Props = {
  fill: Color | Colors;
  size: number;
  type?: ChatUnitType;
};

export const ChatUnitIcon = ({ fill, size, type }: Props) => {
  const key =
    type === ChatUnitType.QUICK_CHAT
      ? 'Bubble'
      : type === ChatUnitType.TABLE
        ? 'Tables'
        : 'Rooms';
  return <Icon fill={fill} iconKey={key} size={size} />;
};
