import { DataStore } from 'aws-amplify/datastore';
import { Hub } from 'aws-amplify/utils';
import { useEffect, useState } from 'react';

import {
  bootstrapCleanUp,
  doBootstrap,
} from '@/services/datastore/bootstrap/datastoreBootstrap';
import { logger } from '@/services/logger/logger';

export const useDatastoreRefresh = (onReady: () => Promise<void>) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const listener = Hub.listen('datastore', async hubData => {
      const { event } = hubData.payload;
      if (event === 'ready') {
        await onReady();
        setIsLoading(false);
        logger.info('Datastore refreshed successfully');
      }
    });

    return () => {
      listener();
    };
  }, [onReady]);

  const refreshDatastore = async () => {
    setIsLoading(true);

    try {
      logger.info('Starting Datastore refresh...');

      await bootstrapCleanUp();

      logger.info('Bootstrap cleanup complete. Starting Datastore clear...');

      await DataStore.clear();

      logger.info(
        'Datastore clear complete. Starting Datastore doBootstrap...',
      );

      await doBootstrap();

      logger.info('Bootstrap complete');
    } catch (error) {
      logger.error('Error refreshing DataStore:', error);
      setIsLoading(false);
      // Optionally, you can handle retries or notify the user here
    }
  };

  return { isLoading, refreshDatastore };
};
