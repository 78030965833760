import messaging from '@react-native-firebase/messaging';

import { registerDeviceToken } from './registerDeviceToken';

export function subscribeToTokenChanges(
  userId: string | undefined,
  allowingNotifications: boolean,
): () => void {
  if (!allowingNotifications || !userId) {
    return () => {};
  }

  const unsubscribeCallback = messaging().onTokenRefresh(
    async token => await registerDeviceToken(token, userId),
  );

  return unsubscribeCallback;
}
