import { InviteTargetType } from '@/API';
import { addNewUsersToChatUnit } from '@/domain/chatUnit/functions/addNewUsersToChatUnit';
import { extractContactsFromInvites } from '@/domain/chatUnit/functions/extractContactsFromInvites';
import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { createInvite } from '@/domain/invites/helpers/createInvite';
import { getName } from '@/domain/user/functions/getName';
import { Organisation } from '@/models';
import { DataStore } from '@aws-amplify/datastore';
import { ChatUnitUserStatus } from '../chatUnitUser/types';
import { parseInviteJson } from './parseInviteJson';

type CreateInviteInput = {
  orgId: string;
  selectedContacts: ContactInput[];
  chatUnitId?: string;
  userId?: string;
};

export async function createOrgInvite({
  orgId,
  selectedContacts,
  chatUnitId,
  userId,
}: CreateInviteInput) {
  const org = await DataStore.query(Organisation, orgId);

  if (!org) {
    return;
  }
  const newOrgInvite = (
    await createInvite({
      InviterInfo: {},
      inviteTarget: orgId,
      inviteeInfo: selectedContacts.map(item => ({
        name: getName(item, 'first'),
        phone: item.phoneNumbers[0].number,
        avatar: item.avatar,
      })),
      targetInfo: {
        backgroundImage: org.image,
        title: org.name,
        chatUnitId: chatUnitId ?? undefined,
        orgId: orgId,
      },
      targetType: InviteTargetType.ORGANISATION,
    })
  )?.data?.createNewInvite;
  if (!newOrgInvite) return null;

  const newInvites = extractContactsFromInvites(newOrgInvite.newInvites);
  const inviteId = newOrgInvite.inviteId;

  const newSenderInvite = parseInviteJson(newOrgInvite?.newSenderInvite);

  if (chatUnitId) {
    if (newInvites?.length && userId) {
      await addNewUsersToChatUnit(
        chatUnitId,
        newInvites,
        ChatUnitUserStatus.PENDING,
        userId,
      );
    }
  }

  return { inviteId, newInvites, newSenderInvite };
}
