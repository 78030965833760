import _ from 'lodash';
import React, { memo } from 'react';
import { Dimensions } from 'react-native';

import NetworkImage from '@/components/image/NetworkImage';
import { BorderRadius } from '@/ui/common/styles';

type Props = {
  imageKey?: string;
  onLongPress?: () => void;
};

const maxHeight = 300;

const BubbleMessageImage = ({ imageKey, onLongPress }: Props) => {
  return (
    <NetworkImage
      contentFit="contain"
      contentPosition="left"
      imageKey={imageKey}
      width={'100%'}
      height={_.min([screenHeight * 0.35, maxHeight])}
      borderRadius={BorderRadius.sm}
      showImagePreview={true}
      onLongPress={onLongPress}
    />
  );
};

const screenHeight = Dimensions.get('window').height;

export default memo(BubbleMessageImage);
