import { ChatUnitColorId, Roles, Statuses } from '@/API';
import { constants } from '@/constants';
import type { MainStackScreenProps } from '@/navigation/types';
import { saveOrganisation } from '@/services/datastore/organisation/saveOrganisation';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { getRandomTableColor } from '@/utilities/constants/table-colors';
import generateUniqueId from '@/utilities/helpers/uuid';
import { web } from '@/utilities/platform';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadData } from 'aws-amplify/storage';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, TextInput } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import * as yup from 'yup';
import { Colors } from '../theme/Colors';
import useUserStore from '../user/state/useUserStore';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingWebLayout from './components/OnboardingWebLayout';
import OnboardingWorkspaceLogoSelect from './components/OnboardingWorkspaceLogoSelect';

type Props = MainStackScreenProps<'OnboardingCreateWorkspace'>;

type FormFields = {
  workspaceName: string;
  logo: {
    localUri: string;
    imageKey: string;
  };
};

const validationSchema = yup.object({
  workspaceName: yup
    .string()
    .label('Workspace name')
    .required('Workspace name is required'),
  logo: yup.object({
    localUri: yup.string().default(''),
    imageKey: yup
      .string()
      .default('')
      .test('imageKey', 'Image is required', function () {
        const { localUri, imageKey } = this.parent;
        // If localUri is present, imageKey must be present
        if (localUri && !imageKey) {
          return false;
        }
        return true;
      }),
  }),
});

function OnboardingCreateWorkspaceScreen({ route, navigation }: Props) {
  const user = useUserStore(state => state.user);
  const { translate } = useTranslations();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(false);

  const handlePhotoSelection = useCallback(async () => {
    setImageLoading(true);
    try {
      const imagePickerResult = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: false,
        base64: false,
        allowsMultipleSelection: false,
        quality: 0.85,
      });

      const pickedAsset = imagePickerResult.assets?.[0];

      if (!pickedAsset) {
        return;
      }

      const assetLocalUri = pickedAsset.uri;
      const mimeType = pickedAsset.mimeType || '';
      // Reason: pickedAsset.fileSize is not always available and pickedAsset.filesize is not always available
      // However, one of them is always available.
      // @ts-ignore
      const fileSize = pickedAsset?.fileSize || pickedAsset?.filesize! || 0;

      if (!assetLocalUri) {
        logger.error(
          'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
          'Invalid asset uri',
        );
        return;
      }

      setValue('logo.localUri', assetLocalUri);

      if (!web && (!mimeType || !fileSize)) {
        logger.error(
          'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
          'Invalid asset',
        );
        return;
      }

      if (!web && (!fileSize || fileSize > constants.maxFileSize)) {
        logger.error(
          'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
          'File size is too large',
        );
        return;
      }

      const fileRes = await fetch(assetLocalUri);
      const blob = await fileRes.blob();

      const fileType = mimeType.split('/')[1];
      const assetFileName = (pickedAsset?.fileName || generateUniqueId())
        .concat('.')
        .concat(fileType);

      const imageKey = `users/${
        user?.id
      }/workspaces/${Date.now()}-${assetFileName}`;

      const { key } = await uploadData({
        data: blob,
        key: imageKey,
        options: {
          contentType: mimeType,
        },
      }).result;
      setValue('logo.imageKey', key);
    } finally {
      setImageLoading(false);
    }
  }, [setValue, user?.id]);

  const onSubmit = async (data: FormFields) => {
    setLoading(true);

    if (user) {
      const newOrg = await saveOrganisation({
        id: '',
        billingId: '',
        name: data.workspaceName,
        image:
          data.logo?.imageKey ?? getRandomTableColor(ChatUnitColorId.BLACK),

        plan: 'FREE',
        usage: { guests: 0, tables: 0, rooms: 0 },
        allowedUserIds: [user.id],
        members: [
          {
            userId: user.id,
            role: Roles.OWNER,
            status: Statuses.ACTIVE,
            name: user.name,
            phoneNumber: user.phoneNumber ?? '',
            avatar: user?.avatar,
          },
        ],
      });

      navigation.replace('OnboardingAddMembers', {
        stepNumber: route?.params?.stepNumber
          ? route?.params?.stepNumber + 1
          : 2,
        totalSteps: route?.params?.totalSteps ? route?.params?.totalSteps : 2,
        orgId: newOrg.id,
      });
    }

    setLoading(false);
  };
  return (
    <>
      {web ? null : <Spacer width="100%" height={1} bg={Colors.neutral10} />}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('step_x_of_y', {
            current: route.params?.stepNumber || 1,
            total: route.params?.totalSteps || 2,
          }).toUpperCase()}
        </AppText>
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate('create_workspace_title')}
        </AppText>
        <Spacer height={30} />
        <AppText size={web ? 21 : 17} color={Colors.neutral70}>
          {translate('create_workspace_subtitle')}
        </AppText>
        <Spacer height={40} />
        <AppText size={web ? 17 : 14} type="primary700">
          {translate('your_company_name')}
        </AppText>
        <Spacer height={10} />
        <AppText size={14} color={Colors.neutral70}>
          {translate('your_company_name_subtitle')}
        </AppText>
        <Spacer height={15} />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="workspaceName"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={styles.nameInput}
              textAlign="center"
              verticalAlign="middle"
            />
          )}
        />
        <Spacer height={40} />
        <AppText size={web ? 17 : 14} type="primary700">
          {translate('your_company_logo')}{' '}
          <AppText size={web ? 17 : 14}>({translate('optional')})</AppText>
        </AppText>
        <Spacer height={10} />
        <AppText size={14} color={Colors.neutral70}>
          {translate('your_company_logo_subtitle')}
        </AppText>
        <Spacer height={15} />
        <OnboardingWorkspaceLogoSelect
          onPress={handlePhotoSelection}
          logo={watch('logo.localUri')}
        />
        <Spacer height={40} />
        <OnboardingNextButton
          disabled={!isValid || imageLoading}
          onPress={handleSubmit(onSubmit)}
          text={translate('workspace_submit')}
          loading={loading}
        />
        <Spacer height={60} />
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    paddingTop: 50,
    flexGrow: 1,
  },
  nameInput: {
    fontSize: web ? 21 : 17,
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    height: 50,
    borderWidth: 1,
    borderRadius: BorderRadius.sm,
    borderColor: Colors.neutral30,
    textAlign: 'center',
    ...(web ? { outlineStyle: 'none' } : {}),
  },
});

const OnboardingCreateWorkspaceScreenWeb = (props: Props) => {
  const { navigation } = props;
  return (
    <OnboardingWebLayout showBackButton={navigation.canGoBack()}>
      <OnboardingCreateWorkspaceScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web
  ? OnboardingCreateWorkspaceScreenWeb
  : OnboardingCreateWorkspaceScreen;
