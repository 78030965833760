import { MMKV } from 'react-native-mmkv';

export const storage = new MMKV();

export function getOrDefault(key: string, defaultFn: () => string): string {
  const result = storage.getString(key);
  if (result) {
    return result;
  }
  const newValue = defaultFn();
  storage.set(key, newValue);
  return newValue;
}
