import Config from 'react-native-config';
import * as RNLocalize from 'react-native-localize';

import { parseArray } from './utilities/constants/parseArray';
import { parseNumber } from './utilities/constants/parseNumber';

import { countries } from '@/utilities/countries';

// IMPORTANT NOTE: please limit imports to other files to avoid circular dependencies

// Define beforehand to avoid re-creating the same object every time the property is accessed
const LOCAL_PHONE_CODE = RNLocalize?.getCountry() || 'AU';
const LOCAL_COUNTRY =
  countries.find(c => c.code === LOCAL_PHONE_CODE) ||
  countries.find(c => c.code === 'AU')!;

enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  SILENT = 10,
}

// NOTE: may need to refactor this to a function for mocking in tests
export const constants = {
  env: Config.ENV,

  // animation
  fadeInDuration: 400,
  chatHeadAnimationDuration: 225,

  // message fetching
  bootstrapMessageFetchLimit:
    parseNumber(Config.BOOTSTRAP_MESSAGE_FETCH_LIMIT) ?? 200,
  initialLoadMessagesLimit:
    parseNumber(Config.INITIAL_LOAD_MESSAGES_LIMIT) ?? 50,
  loadPreviousMessagesLimit:
    parseNumber(Config.LOAD_PREV_MESSAGES_LIMIT) ?? 100,

  // query retries
  fetchBackoffAttemptCount:
    parseNumber(Config.FETCH_BACKOFF_ATTEMPT_COUNT) ?? 3,

  // bottom sheet modal
  fullScreenModalTopOffset: 30,

  //invites
  inviteUrl:
    Config.ENV_URL === 'prod'
      ? 'https://invite.8seats.com'
      : `https://invite.${Config.ENV_URL}.8seats.com`,

  // logging
  logDataStoreEvents: Config.LOG_DATASTORE_EVENTS === 'true' || false,
  logLevel: parseNumber(Config.LOG_LEVEL) ?? LogLevel?.INFO ?? 1,
  logLevels: LogLevel,
  logRestrictToUsernames: parseArray(Config.LOG_RESTRICT_TO_USERNAMES) ?? [],

  dmUserLimit: 8,
  roomUserLimit: parseNumber(Config.ROOM_USER_LIMIT) ?? 10000,
  tableUserLimit: 20,
  tableLimit: parseNumber(Config.TABLES_LIMIT) ?? 1000,
  roomLimit: parseNumber(Config.ROOMS_LIMIT) ?? 1000,
  onHeadTapDebounceDelay: 100,

  // UUID Namespace for v5
  NAMESPACE: '4730692c-3795-443b-a5cd-3982734bd59b',

  // Early Access
  waitlistUrl: 'https://site.8seats.com/waitlist',
  defaultCountry: LOCAL_COUNTRY,
  defaultPhoneNumberCode: LOCAL_PHONE_CODE,

  // Timeouts
  loadingTimeout: 10000,
  modalRaceConditionDelay: 500,
  progressDebounceTime: 250,
  refreshAuthSessionInterval: 1000 * 60 * 60 * 12, // 12 hours
  storageDebounceTime: 1000, // 1 second

  // Support details
  supportEmail: 'support@8seats.com',

  // File types
  supportedImageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic'],
  supportedVideoExtensions: [
    'mp4',
    'mov',
    // 'avi' -> not supported by expo-video-thumbnails
    'mkv',
    'webm',
    // 'flv',
    // 'wmv',
    'm4v',
  ],
  displayUserNameLimit: 7,

  videoThumbnailQuality: 0.75,
  videoThumbnailTimePositionInMillis: 500,
  maxDots: 20,
  maxFileSize: 125 * 1024 * 1024, // 125 MB in bytes
  otpCount: 6, // OTP code length in number of digits
  resendSMSCodeDuration: 10 * 1000, // 10 seconds
  minChatUnitTitleLength: 3,
  maxChatUnitTitleLength: 20,
  codePushVersion: 'a',
};
