import React from 'react';
import { StyleSheet, Text, View, type ViewStyle } from 'react-native';

import CachedImage from './image/cachedImage';

import { Greys } from '@/ui/common/colors';

const SuccessCheck = ({
  style,
  title,
}: {
  style?: ViewStyle;
  title: string;
}) => {
  return (
    <View style={[styles.container, style]}>
      <CachedImage
        source={require('@/assets/success-big-icon.png')}
        style={styles.image}
      />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  image: {
    height: 90,
    width: 90,
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 28,
    fontWeight: '400',
    marginTop: 30,
    textAlign: 'center',
  },
});

export default SuccessCheck;
