import { Organisation } from '@/models';
import { DataStore } from 'aws-amplify/datastore';
import { useEffect, useState } from 'react';

export default function useOrganisations() {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  useEffect(() => {
    const subscription = DataStore.observeQuery(Organisation).subscribe(
      ({ items }) => {
        if (items.length) {
          setOrganisations(items);
        }
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return organisations;
}
