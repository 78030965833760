import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Avatar from '../avatar';

import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import { AppText, Spacer } from '@/ui/app';
import { Greys, Neutrals } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

type Variant = 'default' | 'light';
type Props = {
  title: string;
  variant?: Variant;
};

const UserHeader = ({ title, variant = 'default' }: Props) => {
  const user = useUserStore(state => state.user);
  const { openDrawer } = useNavigation();

  const styles = getStyles(variant);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={openDrawer}>
        <Avatar
          imageKey={user?.avatar}
          displayName={user ? getName(user, 'short') : ''}
          style={styles.avatar}
          size={32}
        />
      </TouchableOpacity>
      <Spacer width={18} />
      <AppText
        size={16}
        numberOfLines={2}
        type="primary700"
        color={variant === 'light' ? Greys.shade0 : Neutrals.shade800}
        style={styles.text}>
        {title}
      </AppText>
    </View>
  );
};

const getStyles = (variant: Variant) =>
  StyleSheet.create({
    avatar: {
      borderColor: variant === 'light' ? Colors.neutral0 : Colors.neutral80,
      borderWidth: 2,
    },
    container: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    text: {
      maxWidth: web ? undefined : 180,
    },
  });

export default UserHeader;
