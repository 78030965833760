import { Colors } from '../Colors';

import type { Theme } from '.';

import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

export const DarkTheme: Theme = {
  chat: {
    head: {
      avatar: {
        container: {
          alignItems: 'center',
          backgroundColor: Colors.neutral10,
          justifyContent: 'center',
        },
        text: {
          fontWeight: '600',
        },
      },
      name: {
        container: {},
        text: {
          color: Greys.shade0,
          fontSize: web ? 14 : 10,
        },
      },
    },
    presence: {
      backgroundColor: Colors.peppermint,
    },
    addUser: {
      container: {},
      text: {
        fontSize: 12,
        color: 'white',
      },
    },
  },
  icon: {
    color: Colors.primaryLight,
  },
  terms: {
    variants: {
      dark: {
        color: Colors.neutral90,
      },
      light: {
        color: Colors.neutral0,
      },
    },
    link: {
      container: {},
      text: {
        fontFamily: 'OpenSans-SemiBold',
        top: web ? 0 : 3,
      },
    },
    text: {
      fontFamily: 'OpenSans-Regular',
    },
  },
  ui: {
    button: {
      container: {
        borderWidth: 2,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: 20,
        height: 40,
        justifyContent: 'center',
        marginTop: 0,
        width: '100%',
      },
      text: {
        color: Colors.neutral100,
        fontFamily: 'OpenSans-SemiBold',
        fontSize: 18,
        textAlign: 'center',
      },
      variants: {
        disabled: {
          container: {
            backgroundColor: Colors.neutral50,
            borderColor: Colors.neutral50,
          },
          text: {
            color: Colors.neutral0,
          },
        },
        disabledLink: {
          container: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
          text: {
            color: Colors.neutral50,
          },
        },
        feature: {
          container: {
            borderWidth: 0,
          },
          text: {},
        },
        link: {
          container: {},
          text: {},
        },
        primary: {
          container: {
            backgroundColor: Colors.primaryDark,
            borderColor: Colors.primaryDark,
          },
          text: {
            color: Colors.neutral100,
          },
        },
        secondary: {
          container: {
            backgroundColor: 'transparent',
            borderColor: Colors.primaryDark,
          },
          text: {
            color: Colors.primaryDark,
          },
        },
      },
    },
    icon: {
      color: Colors.neutral0,
    },
    toast: {
      variants: {
        newMessage: {
          backgroundColor: Colors.neutral0,
          shadowColor: Colors.neutral100,
          gradientColor: [Colors.neutral05, Colors.neutral0],
          gradientBackgroundColor: Colors.neutral60,
          highEmphasisTextColor: Colors.neutral80,
          lowEmphasisTextColor: Colors.neutral60,
        },
      },
    },
    text: {
      defaultColor: Colors.neutral80,
    },
    container: {
      variants: {
        contrast: {
          backgroundColor: Colors.neutral100,
        },
      },
    },
  },
};
