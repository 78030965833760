import { DataStore } from 'aws-amplify/datastore';
import { ChatUnitUser } from 'src/models';

import { factory } from '@/services/factory/factory';
import type { ChatUnitUser as IChatUnitUser } from '@/services/types';

type Props = Partial<Omit<IChatUnitUser, 'id'>> & {
  userId: string;
  chatUnitId: string;
};

export const createChatUnitUser = async (input: Props) => {
  const result = await DataStore.save(
    new ChatUnitUser({
      ...input,
    }),
  );
  return factory.chatUnitUser(result);
};
