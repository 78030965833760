import { yupResolver } from '@hookform/resolvers/yup';
import {
  CommonActions,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType, Roles } from '../../../API';

import type { CreateInviteProps } from './types';

import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import MainButton from '@/components/MainButton';
import CountryPicker from '@/components/country-picker';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import { PhoneController } from '@/components/phoneInput/PhoneController';
import { constants } from '@/constants';
import { useExperience } from '@/context/Experience';
import { inviteContactsToRoomOrTable } from '@/domain/chatUnit/components/view/inviteContactsToRoomOrTable';
import { addNewUsersToChatUnit } from '@/domain/chatUnit/functions/addNewUsersToChatUnit';
import { inviteContactsToDM } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { useChatUnit } from '@/domain/chatUnit/hooks/useTable';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { MIN_INPUT_HEIGHT, Validation } from '@/hooks/useInput';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { eventBus } from '@/services/eventBus/eventBus';
import { createOrgInvite } from '@/services/invite/createOrgInvite';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { Greys, Neutrals } from '@/ui/common/colors';
import { Fonts } from '@/ui/common/styles';
import { formatPhoneNumber } from '@/utilities/helpers/formatPhoneNumber';
import { ChooseMemberTypeSelect } from '../components/ChooseMemberTypeSelect';
import { useMyInviteStore } from '../state/useMyInvitesStore';

type FormFields = {
  firstName: string;
  phoneNumber: string;
};

const ChatUnitTypeText = {
  invite_to_text: {
    [ChatUnitType.QUICK_CHAT]: 'invite_to_dm_text',
    [ChatUnitType.TABLE]: 'invite_to_table_text',
    [ChatUnitType.ROOM]: 'invite_to_room_text',
  },
  invite_to_label: {
    [ChatUnitType.QUICK_CHAT]: 'invite_to_dm_label',
    [ChatUnitType.TABLE]: 'invite_to_table_label',
    [ChatUnitType.ROOM]: 'invite_to_room_label',
  },
};
const DEFAULT_CHATUNIT_BG = 'retro';

const InviteUserView = ({ navigation, route }: CreateInviteProps) => {
  const {
    backgroundImage,
    tableId: chatUnitId,
    title,
    sessionId,
    type,
  } = route.params;
  const { goBack } = useNavigation();
  const userId = useUserStore(useShallow(state => state.user?.id));
  const [selectedCountry, setSelectedCountry] = useState(
    constants.defaultCountry,
  );
  const { chatUnit } = useChatUnit(chatUnitId ?? '');
  const table = useTableStore(state => state.getTable(chatUnitId ?? ''));
  const chatUnitUsers = useTableUsersStore(
    useShallow(state => state.getTableUsersForTable(chatUnitId ?? '')),
  );
  const myChatUnitUser = chatUnitUsers.find(u => u.id === userId);
  const [showPicker, setShowPicker] = useState(false);
  const { translate } = useTranslations();
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const [memberType, setMemberType] = useState(Roles.GUEST);

  const { track } = useExperience();
  const addInvites = useMyInviteStore(state => state.addInvites);
  const { showPrompt } = usePromptStore();
  const resolver = yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .required(translate('first_name_is_required_text')),
      phoneNumber: yup
        .string()
        .test(
          'is-valid-phone',
          translate('phone_number_is_invalid_text'),
          value => {
            return isPossiblePhoneNumber(value || '');
          },
        )
        .required(translate('phone_number_is_required_text')),
    }),
    { abortEarly: false },
  );

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
    setValue,
  } = useForm<FormFields>({
    defaultValues: {
      firstName: '',
      phoneNumber: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const submitInvite = async (data: FormFields) => {
    const parsedPhoneNumber = formatPhoneNumber(data.phoneNumber);
    try {
      if (type === ChatUnitType.QUICK_CHAT) {
        if (sessionId) {
          eventBus.emit('inviteUserSubmitted', {
            contacts: [
              {
                ...data,
                phoneNumbers: [{ label: 'mobile', number: parsedPhoneNumber }],
              },
            ],
            sessionId, // used to identify the origin screen event listener
          });
        }
      }
      if (!table || !userId || !myChatUnitUser || !chatUnitId) {
        return;
      }

      if (table.type === ChatUnitType.QUICK_CHAT) {
        if (table.id) {
          await inviteContactsToDM(
            table.id,
            [
              {
                firstName: data.firstName,
                phoneNumbers: [{ label: 'mobile', number: parsedPhoneNumber }],
              },
            ],
            userId,
          );
        }
      } else {
        const newContact = {
          firstName: data.firstName,
          phoneNumbers: [{ label: 'typed', number: parsedPhoneNumber }],
        };

        let orgInviteRes;

        if (chatUnit?.organisationId && memberType === Roles.MEMBER) {
          orgInviteRes = await createOrgInvite({
            orgId: chatUnit?.organisationId,
            selectedContacts: [newContact],
            userId: userId,
          });
        }

        const chatUnitInviteRes = await inviteContactsToRoomOrTable(
          chatUnitId,
          [newContact],
          chatUnit,
          userId,
          chatUnit?.organisationId,
        );

        track('Invite Sent', {
          phoneNumbersInvited: parsedPhoneNumber,
          chatUnitId,
        });

        const newInvites = chatUnitInviteRes?.newInvites;
        if (newInvites) {
          addNewUsersToChatUnit(
            chatUnitId,
            newInvites,
            ChatUnitUserStatus.PENDING,
            userId,
          );
        }

        //Adding the newly created invites to the stores. This prevents going to the invite Success
        if (chatUnitInviteRes?.newSenderInvite) {
          addInvites([chatUnitInviteRes.newSenderInvite]);
        }

        if (orgInviteRes?.newSenderInvite) {
          addInvites([orgInviteRes?.newSenderInvite]);
        }

        // Extract inviteId from response and navigate to success view
        const inviteId = chatUnitInviteRes?.inviteId;
        if (inviteId) {
          navigation.dispatch(
            StackActions.replace('SenderInviteSuccess', {
              inviteId,
            }),
          );
          return;
        }
      }

      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'MainNavigator' },
            {
              name: 'ChatView',
              params: { chatUnitId },
            },
          ],
        }),
      );
    } catch (error) {
      logger.error('Error inviting single user', error);
      showPrompt({
        title: translate('send_invite_error_prompt_title'),
        body: translate('send_invite_error_prompt_body'),
      });
    }
  };

  const onPhoneInputBlur = () => {
    const phoneNumber = getValues('phoneNumber');
    if (!isPossiblePhoneNumber(phoneNumber)) {
      return;
    }
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    setValue('phoneNumber', formattedPhoneNumber);
  };

  return (
    <SafeAreaView edges={['left', 'right']} style={styles.container}>
      <View style={styles.imageContainer}>
        <ChatUnitBackgroundImage
          imageKey={backgroundImage || DEFAULT_CHATUNIT_BG}
          width="100%"
          height="100%"
        />

        <View style={styles.imageOverlay} />
        <View style={styles.iconTitleRow}>
          <ChatUnitIcon
            fill={Greys.shade0}
            size={20}
            type={table?.type ?? type ?? ChatUnitType.TABLE}
          />
          <Text style={styles.headerTitle}>
            {translate(
              ChatUnitTypeText.invite_to_label[table?.type ?? type ?? 'TABLE'],
            )}{' '}
            <Text style={styles.headerTitleBold}>{title}</Text>
          </Text>
        </View>
      </View>
      <View style={styles.content}>
        <Text style={styles.title}>
          {translate(
            ChatUnitTypeText.invite_to_text[table?.type ?? type ?? 'TABLE'],
          )}
        </Text>
        <Spacer height={30} />
        <AppText textAlign="center" size={14} color={Neutrals.shade700}>
          {translate('display_name_label')}
        </AppText>
        <Spacer height={10} />
        <Controller
          control={control}
          defaultValue=""
          name="firstName"
          render={({ field }) => (
            <>
              <TextInput
                onChangeText={field.onChange}
                placeholderTextColor={Greys.shade300}
                style={styles.firstNameInput}
                value={field.value}
                maxLength={constants.displayUserNameLimit}
              />
              <AppText textAlign="left" size={14} color={Neutrals.shade700}>
                {translate('phone_number_text')}
              </AppText>
              <Spacer height={10} />
              <PhoneController
                control={control}
                error={errors.phoneNumber?.message}
                label={translate('phone_number_placeholder_text')}
                name="phoneNumber"
                onBlur={onPhoneInputBlur}
                onSearch={() => setShowPicker(true)}
                selectedCountry={selectedCountry}
                validation={errors.phoneNumber && Validation.ERROR}
              />
              <Spacer height={20} />
              {chatUnit?.organisationId && (
                <View>
                  <AppText
                    textAlign="center"
                    size={14}
                    color={Neutrals.shade700}>
                    {translate('invite_membership_as')}
                  </AppText>
                  <Spacer height={20} />
                  <ChooseMemberTypeSelect
                    openMemberSelect={openMemberSelect}
                    setOpenMemberSelect={setOpenMemberSelect}
                    memberType={memberType}
                    setMemberType={setMemberType}
                  />
                  <Spacer height={20} />
                </View>
              )}
            </>
          )}
        />

        <View style={styles.mainActionContainer}>
          <MainButton
            disabled={!isValid}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit(submitInvite)();
            }}
            style={styles.mainButton}
            textStyle={styles.mainButtonText}
            title="Invite"
          />
          <Pressable onPress={goBack} style={styles.cancelButton}>
            <Text style={styles.cancelText}>
              {translate('cancel_button_title')}
            </Text>
          </Pressable>
        </View>
      </View>
      <CountryPicker
        onCountrySelected={setSelectedCountry}
        open={showPicker}
        selectedCountry={selectedCountry}
        setOpen={setShowPicker}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  cancelButton: {
    marginTop: 20,
    marginBottom: 20,
    width: '95%',
    alignItems: 'center',
  },
  cancelText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  container: {
    flex: 1,
  },
  content: {
    alignItems: 'center',
    flex: 1,
    marginTop: 30,
    paddingHorizontal: 30,
  },
  firstNameInput: {
    borderColor: Neutrals.shade200,
    borderRadius: 5,
    borderWidth: 1,
    color: Neutrals.shade800,
    fontFamily: Fonts.primary400,
    fontSize: 17,
    marginBottom: 30,
    minHeight: MIN_INPUT_HEIGHT,
    paddingBottom: 0,
    paddingTop: 0,
    textAlign: 'center',
    textAlignVertical: 'center',
    width: '95%',
  },
  firstNameLabel: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 10,
    marginTop: 30,
  },
  headerTitle: { color: Greys.shade0, fontSize: 17, marginLeft: 10 },
  headerTitleBold: { fontWeight: '700' },
  iconTitleRow: {
    alignItems: 'center',
    bottom: 20,
    flexDirection: 'row',
    paddingHorizontal: 10,
    position: 'absolute',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    flex: 0.2,
  },
  imageOverlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  mainButton: {
    width: '95%',
  },
  mainActionContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
  },
  mainButtonText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
  },
  phoneNumberLabel: {
    color: Greys.shade500,
    fontSize: 14,
    marginBottom: 10,
  },
  title: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 23,
    textAlign: 'center',
  },
  dashedBorder: {
    borderStyle: 'dashed',
  },
});

export default InviteUserView;
