import { Image } from 'expo-image';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

import BackArrow from '@/assets/backArrow.png';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { useNavigation } from '@react-navigation/native';

export default function OnboardingHeaderLeft() {
  const { translate } = useTranslations();
  const navigation = useNavigation();

  if (!navigation.canGoBack()) {
    return null;
  }

  return (
    <Pressable
      hitSlop={20}
      style={styles.container}
      onPress={navigation.goBack}>
      <Image
        source={BackArrow}
        style={styles.icon}
        tintColor={Colors.neutral80}
      />
      <AppText size={14} color={Colors.neutral80}>
        {translate('common_back')}
      </AppText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  icon: {
    width: 8,
    height: 16,
    resizeMode: 'contain',
  },
});
