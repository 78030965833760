import type { NativeScrollEvent } from 'react-native';
import {
  Easing,
  useAnimatedScrollHandler,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

const DURATION = 300;
const SHOW_SHADOW_Y_OFFSET = 30;
const EASING = Easing.linear;

type Props = {
  onScrollCallback?: (e: NativeScrollEvent) => void;
};

export const useAnimdatedScrollView = ({ onScrollCallback }: Props = {}) => {
  const yScrollOffset = useSharedValue(0);
  const animateShadow = useSharedValue(0);

  const onScroll = useAnimatedScrollHandler(
    {
      onMomentumEnd: e => {
        // NOTE: onMomentumScrollEnd must be defined on ScrollView for this to fire
        if (e.contentOffset.y < SHOW_SHADOW_Y_OFFSET) {
          animateShadow.value = withTiming(0, {
            duration: DURATION,
            easing: EASING,
          });
        }
      },
      onScroll: e => {
        yScrollOffset.value = e.contentOffset.y;
        if (e.contentOffset.y >= SHOW_SHADOW_Y_OFFSET) {
          animateShadow.value = withTiming(1, {
            duration: DURATION,
            easing: EASING,
          });
        }
        onScrollCallback && onScrollCallback(e);
      },
    },
    [],
  );

  const animateShowShadow = () =>
    (animateShadow.value = withTiming(1, {
      duration: DURATION,
      easing: EASING,
    }));

  const animateHideShadow = () =>
    (animateShadow.value = withTiming(0, {
      duration: DURATION,
      easing: EASING,
    }));

  return {
    animateHideShadow,
    animateShadow,
    animateShowShadow,
    onScroll,
  };
};
