import { type RefObject, useEffect, useMemo, useRef } from 'react';
import type PagerView from 'react-native-pager-view';
import type { SharedValue } from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { useActiveConversationStore } from '@/domain/conversation/state/useActiveConversationStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import { web } from '@/utilities/platform';

type Props = {
  conversationId?: string;
  chatUnitId: string;
  offset: SharedValue<number>;
  pagerRef: RefObject<PagerView>;
};

export const useChatViewContainer = ({
  conversationId,
  chatUnitId,
  offset,
  pagerRef,
}: Props) => {
  useStatusBarHandler('light-content');
  const initialPageIndex = useRef<number>(0);

  const {
    setActiveChatId,
    setWebActiveConversationId,
    generateConversationUserMappings,
    activeConversationIds,
    webActiveConversationId,
    clearState,
    pendingConvoId,
  } = useActiveConversationStore(
    useShallow(s => ({
      setActiveChatId: s.setActiveChatId,
      setWebActiveConversationId: s.setWebActiveConversationId,
      generateConversationUserMappings: s.generateConversationUserMappings,
      activeConversationIds: s.activeConversationIds,
      webActiveConversationId: s.webActiveConversationId,
      clearState: s.clearState,
      pendingConvoId: s.pendingConvoId,
    })),
  );

  const chatUnitUserIds = useTableUsersStore(
    useShallow(state => state.getTableUsersForTable(chatUnitId).map(u => u.id)),
  );

  // set active table id and active conversations
  useEffect(() => {
    setActiveChatId(chatUnitId);
    generateConversationUserMappings(chatUnitId);
    return () => {
      clearState();
    };
  }, [
    chatUnitId,
    setActiveChatId,
    generateConversationUserMappings,
    clearState,
    chatUnitUserIds,
  ]);

  // Use pendingConvoId if available, otherwise use the provided conversationId
  const effectiveConversationId = pendingConvoId || conversationId;

  const activeConvoIdx = useMemo(
    () => activeConversationIds.findIndex(id => id === effectiveConversationId),
    [activeConversationIds, effectiveConversationId],
  );

  useEffect(() => {
    if (activeConvoIdx > -1) {
      initialPageIndex.current = activeConvoIdx;
      offset.value = activeConvoIdx;
      requestAnimationFrame(() =>
        pagerRef?.current?.setPageWithoutAnimation(activeConvoIdx),
      );
    }
    if (web) {
      setWebActiveConversationId(effectiveConversationId);
    }
  }, [
    effectiveConversationId,
    setWebActiveConversationId,
    activeConvoIdx,
    offset,
    pagerRef,
  ]);

  const pagerConversationIds = useMemo(
    () =>
      web
        ? [webActiveConversationId || activeConversationIds[0]]
        : activeConversationIds,
    [webActiveConversationId, activeConversationIds],
  );

  return {
    pagerConversationIds,
    initialPageIndex,
  };
};
