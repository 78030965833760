import type { ImageSource } from 'expo-image';

import { web } from '../platform';

import { getBackgroundImage } from './background-images';

export type ChatUnitBackgroundImage = {
  id: string;
  source?: number;
  title: string;
};

export const DEFAULT_CHAT_UNIT_PICKER_IMAGE = 'REAL_ESTATE';

export const chatUnitBackgroundImages: ChatUnitBackgroundImage[] = [
  {
    id: 'real_estate',
    source: getBackgroundImage('backgroundImage1'),
    title: 'Real Estate',
  },
  {
    id: 'medical',
    source: getBackgroundImage('backgroundImage2'),
    title: 'Medical',
  },
  {
    id: 'running_club',
    source: getBackgroundImage('backgroundImage3'),
    title: 'Running Club',
  },
  {
    id: 'photography',
    source: getBackgroundImage('backgroundImage4'),
    title: 'Photography',
  },
  {
    id: 'architecture',
    source: getBackgroundImage('backgroundImage5'),
    title: 'Architecture',
  },
  {
    id: 'construction',
    source: getBackgroundImage('backgroundImage6'),
    title: 'Construction',
  },
  {
    id: 'study_group',
    source: getBackgroundImage('backgroundImage7'),
    title: 'Study Group',
  },
  {
    id: 'retail',
    source: getBackgroundImage('backgroundImage8'),
    title: 'Retail',
  },
  {
    id: 'sport_field',
    source: getBackgroundImage('backgroundImage9'),
    title: 'Sport Field',
  },
  {
    id: 'entertainment',
    source: getBackgroundImage('backgroundImage10'),
    title: 'Entertainment',
  },
  {
    id: 'coffee_shop',
    source: getBackgroundImage('backgroundImage11'),
    title: 'Coffee_shop',
  },
  {
    id: 'gym',
    source: getBackgroundImage('backgroundImage12'),
    title: 'Gym',
  },
  {
    id: 'zen',
    source: getBackgroundImage('backgroundImage13'),
    title: 'Zen',
  },
  {
    id: 'beach',
    source: getBackgroundImage('backgroundImage14'),
    title: 'Beach',
  },
  {
    id: 'orange',
    source: getBackgroundImage('backgroundImage15'),
    title: 'Orange',
  },
  {
    id: 'modern_room',
    source: getBackgroundImage('backgroundImage16'),
    title: 'Modern Room',
  },
  {
    id: 'nature',
    source: getBackgroundImage('backgroundImage17'),
    title: 'Nature',
  },
  {
    id: 'retro',
    source: getBackgroundImage('backgroundImage18'),
    title: 'Retro',
  },
  {
    id: 'ocean',
    source: getBackgroundImage('backgroundImage19'),
    title: 'Ocean',
  },
  {
    id: 'cosmic',
    source: getBackgroundImage('backgroundImage20'),
    title: 'Cosmic',
  },
  {
    id: 'whisper',
    source: getBackgroundImage('backgroundImage21'),
    title: 'Whisper',
  },
];

interface MappedChatUnitBackgroundImage {
  source: NodeModule;
  uri: string;
  title: string;
}

const chatUnitBackgroundImagesMapper: {
  [key: string]: MappedChatUnitBackgroundImage;
} = {
  aglow: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  alleyway: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  architecture: {
    source: getBackgroundImage('backgroundImage5'),
    title: 'Architecture',
    uri: 'architecture.jpg',
  },

  beach: {
    source: getBackgroundImage('backgroundImage14'),
    title: 'Beach',
    uri: 'beach.jpg',
  },

  bondi_sunset: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  centre_stage: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  chairlift: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  coffee_shop: {
    source: getBackgroundImage('backgroundImage11'),
    title: 'Coffee Shop',
    uri: 'coffee-shop.jpg',
  },

  construction: {
    source: getBackgroundImage('backgroundImage6'),
    title: 'Construction',
    uri: 'construction.jpg',
  },

  cosmic: {
    source: getBackgroundImage('backgroundImage20'),
    title: 'Cosmic',
    uri: 'cosmic.jpg',
  },

  disco_floor: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  entertainment: {
    source: getBackgroundImage('backgroundImage10'),
    title: 'Entertainment',
    uri: 'entertainment.jpg',
  },

  erosion: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  fast_lane: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  forest: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  gym: {
    source: getBackgroundImage('backgroundImage12'),
    title: 'Gym',
    uri: 'gym.jpg',
  },

  medical: {
    source: getBackgroundImage('backgroundImage2'),
    title: 'Medical',
    uri: 'medical.jpg',
  },

  modern_room: {
    source: getBackgroundImage('backgroundImage16'),
    title: 'Modern Room',
    uri: 'modern-room.jpg',
  },

  nature: {
    source: getBackgroundImage('backgroundImage17'),
    title: 'Nature',
    uri: 'nature.jpg',
  },

  ocean: {
    source: getBackgroundImage('backgroundImage19'),
    title: 'Ocean',
    uri: 'ocean.jpg',
  },

  orange: {
    source: getBackgroundImage('backgroundImage15'),
    title: 'Orange',
    uri: 'orange.jpg',
  },

  photography: {
    source: getBackgroundImage('backgroundImage4'),
    title: 'Photography',
    uri: 'photography.jpg',
  },

  pier: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  rainfall: {
    source: getBackgroundImage('DEFAULT_CHAT_UNIT_IMAGE'),
    title: 'Default',
    uri: 'erosion.jpg',
  },

  real_estate: {
    source: getBackgroundImage('backgroundImage1'),
    title: 'Real Eestate',
    uri: 'real-estate.jpg',
  },

  retail: {
    source: getBackgroundImage('backgroundImage8'),
    title: 'Retail',
    uri: 'retail.jpg',
  },

  retro: {
    source: getBackgroundImage('backgroundImage18'),
    title: 'Retro',
    uri: 'retro.jpg',
  },

  running_club: {
    source: getBackgroundImage('backgroundImage3'),
    title: 'Running Club',
    uri: 'running-club.jpg',
  },

  sport_field: {
    source: getBackgroundImage('backgroundImage9'),
    title: 'Sport Field',
    uri: 'sport-field.jpg',
  },

  study_group: {
    source: getBackgroundImage('backgroundImage7'),
    title: 'Study Group',
    uri: 'study-group.jpg',
  },

  zen: {
    source: getBackgroundImage('backgroundImage13'),
    title: 'Zen',
    uri: 'zen.jpg',
  },

  whisper: {
    source: getBackgroundImage('backgroundImage21'),
    title: 'Whisper',
    uri: 'whisper.jpg',
  },
};

export const getChatUnitBackgroundImage = (id?: string | null | undefined) => {
  if (!id) {
    return null;
  }

  const mappedImage = chatUnitBackgroundImagesMapper[id.toLowerCase()];

  if (mappedImage == null) {
    return;
  }

  if (web) {
    return `/assets/chat-unit-background-images/${mappedImage.uri}`;
  }

  return mappedImage.source as ImageSource;
};
