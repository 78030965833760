import { DataStore } from 'aws-amplify/datastore';

import { Invite as InviteModal } from '../../../models';

import type { Invite, InviteStatus } from '@/domain/invites/types/types';

export const updateInviteStatus = async (
  invite: Invite,
  status: InviteStatus,
) => {
  try {
    const results = await DataStore.query(InviteModal, inviteLookup =>
      inviteLookup.id.eq(invite.id),
    );
    await DataStore.save(
      InviteModal.copyOf(results[0], updated => {
        updated.status = status;
      }),
    );
  } catch (e) {
    console.error('Error while update invite:', e);
  }
};
