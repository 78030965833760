import React from 'react';
import { Platform, Pressable, StyleSheet, View } from 'react-native';

import AppAvatar from '@/components/avatar';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import useUserStore from '@/domain/user/state/useUserStore';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';

interface Props {
  onPress: () => void;
}

const ProfileButton = ({ onPress }: Props) => {
  const user = useUserStore(state => state.user);
  const { translate } = useTranslations();
  if (!user) {
    return null;
  }
  return (
    <View style={styles.container}>
      {user.avatar && (
        <Pressable onPress={onPress} style={styles.avatarContainer}>
          <AppAvatar
            imageKey={user.avatar}
            displayName={getName(user, 'short')}
            size={50}
          />
        </Pressable>
      )}
      <Section asc gap={4} jcc>
        <AppText size={21}>
          {translate('user_menu_greeting_message', {
            title: user.username,
          })}
        </AppText>
        <Pressable onPress={onPress} style={styles.profileButton}>
          <AppText color={Colors.primaryLight} size={14} type="primary600">
            {translate('user_menu_manage_profile_button_title')}
          </AppText>
        </Pressable>
      </Section>
    </View>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    borderWidth: 2,
    borderColor: Colors.neutral80,
    borderRadius: BorderRadius.full,
    backgroundColor: Colors.neutral0,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      android: {
        elevation: 2,
      },
      default: {
        shadowColor: Colors.neutral0,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 1,
      },
    }),
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    paddingHorizontal: 20,
    paddingBottom: 10,
    paddingTop: 20,
  },
  profileButton: {
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.primaryLight,
    borderRadius: BorderRadius.lg,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ProfileButton;
