import { type GetPropertiesOutput, getProperties } from 'aws-amplify/storage';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, Platform, Pressable, StyleSheet, View } from 'react-native';
import RNBlobUtil, {
  type FetchBlobResponse,
  type StatefulPromise,
} from 'react-native-blob-util';
import Share from 'react-native-share';

import CancelIcon from '@/assets/chatview/cancel.svg';
import DownloadIcon from '@/assets/chatview/download.svg';
import DownloadDarkIcon from '@/assets/chatview/downloadDark.svg';
import fileImg from '@/assets/chatview/file.png';
import DownloadedIcon from '@/assets/chatview/open.svg';
import DocumentService from '@/components/image/DocumentService';
import { constants } from '@/constants';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { Brand, Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { formatBytes } from '@/utilities/helpers/formatBytes';
import { web } from '@/utilities/platform';
import { downloadFileWeb } from '@/utilities/web/downloadFileWeb';

type Props = {
  imageKey: string;
};

export default function BubbleDocument(props: Props) {
  const fileName = props.imageKey.split('/').pop();
  const downloadLocation = web
    ? ''
    : `${RNBlobUtil.fs.dirs.CacheDir}/${fileName}`;
  const [loading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [properties, setProperties] = useState<GetPropertiesOutput>();
  const [progress, setProgress] = useState(0);
  const { translate } = useTranslations();
  const { showPrompt } = usePromptStore();

  const downloadTaskRef = useRef<StatefulPromise<FetchBlobResponse>>();

  const handleDownload = useCallback(async () => {
    if (web) {
      const url = await DocumentService.fetchPresignedUrlFromBackend(
        props.imageKey,
      );

      downloadFileWeb(url, fileName, properties?.contentType);

      return;
    }
    try {
      // Check if the file already exists in the cache
      const fileExists = await RNBlobUtil.fs.exists(downloadLocation);
      setDownloaded(fileExists);

      if (fileExists) {
        // Open the file
        const res = await DocumentService.openDocument(
          downloadLocation,
          properties?.contentType!,
        );
        if (res) {
          return;
        }
      }

      setLoading(true);
      setProgress(0);
      // Fetch the s3 url
      const url = await DocumentService.fetchPresignedUrlFromBackend(
        props.imageKey,
      );

      if (!url) {
        setLoading(false);
        showPrompt({
          title: translate('file_download_error'),
          body: translate('file_download_error_message'),
        });
        return;
      }

      // Download the file
      const task = RNBlobUtil.config({
        path: downloadLocation,
        IOSBackgroundTask: true,
      })
        .fetch('GET', url)
        .uploadProgress(
          { interval: constants.progressDebounceTime },
          (received, total) => {
            let progressVal = (received / total) * 100;
            progressVal = Math.ceil(progressVal);
            progressVal = Math.min(progressVal, 100);

            setProgress(progressVal);
          },
        );

      downloadTaskRef.current = task;

      const res = await task;

      if (!res) {
        setLoading(false);
        showPrompt({
          title: translate('file_download_error'),
          body: translate('file_download_error_message'),
        });
        return;
      }

      setDownloaded(true);

      if (Platform.OS === 'android') {
        // Copy the file from cache to the Downloads folder for Android
        // This is because Android does not allow access to the cache folder
        const result = await RNBlobUtil.MediaCollection.copyToMediaStore(
          {
            name: fileName, // name of the file
            parentFolder: '', // subdirectory in the Media Store, e.g. HawkIntech/Files to create a folder HawkIntech with a subfolder Files and save the image within this folder
            mimeType: properties?.contentType,
          },
          'Download', // Media Collection to store the file in ("Audio" | "Image" | "Video" | "Download")
          res.path(), // Path to the file being copied in the apps own storage
        );

        if (!result) {
          showPrompt({
            title: translate('file_download_error'),
            body: translate('file_download_error_message'),
          });
          setLoading(false);
          return;
        }
        const docOpened = await DocumentService.openDocument(
          res.path(),
          properties?.contentType!,
        );
        if (!docOpened) {
          return;
        }
      } else if (Platform.OS === 'ios') {
        const docOpened = await DocumentService.openDocument(
          downloadLocation,
          properties?.contentType!,
        );
        if (!docOpened) {
          Share.open({
            url: 'file://'.concat(res.path()),
            failOnCancel: false,
          });
          return;
        }
      } else {
        // TODO: Implement for other platforms (web, desktop etc)
      }
    } catch (err) {
      logger.error('BubbleDocument::Error while downloading file:', err);
    } finally {
      setLoading(false);
    }
  }, [
    downloadLocation,
    fileName,
    properties?.contentType,
    props.imageKey,
    showPrompt,
    translate,
  ]);

  const handleDownloadCancel = useCallback(() => {
    downloadTaskRef.current?.cancel();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!web) {
      // Check if the file already exists in the cache
      RNBlobUtil.fs
        .exists(downloadLocation)
        .then(setDownloaded)
        .catch(() => setDownloaded(false));
    }
  }, [downloadLocation, fileName]);

  useEffect(() => {
    // Check if file exists in cache
    getProperties({
      key: props.imageKey,
      options: {
        accessLevel: 'guest', // defaults to `guest` but can be 'private' | 'protected' | 'guest'
      },
    }).then(setProperties);
  }, [props.imageKey]);

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Image source={fileImg} style={styles.icon} />
        <View style={styles.textContainer}>
          <AppText
            size={12}
            type="primary700"
            numberOfLines={1}
            ellipsizeMode="middle">
            {fileName}
          </AppText>
          <AppText size={12}>{formatBytes(properties?.size || 0)}</AppText>
        </View>
        {!loading ? (
          <Pressable onPress={handleDownload} style={styles.downloadContainer}>
            {downloaded ? (
              <DownloadedIcon width={24} height={24} />
            ) : (
              <DownloadIcon width={24} height={24} />
            )}
          </Pressable>
        ) : null}
      </View>
      {loading ? (
        <>
          <Spacer height={10} />
          <Spacer height={1} bg={Neutrals.shade100} />
          <Spacer height={10} />
          <View style={styles.row}>
            <DownloadDarkIcon width={24} height={24} />
            <View style={styles.progressBarContainer}>
              <Spacer
                width={`${progress}%`}
                height={4}
                borderRadius={BorderRadius.xs}
                bg={Brand.primary75}
                zIndex={2}
              />
            </View>
            <AppText size={10} color={Neutrals.shade700} type="primary700">
              {progress}%
            </AppText>
            <Pressable onPress={handleDownloadCancel}>
              <CancelIcon height={30} width={30} />
            </Pressable>
          </View>
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: BorderRadius.md,
    backgroundColor: Greys.shade0,
    borderColor: Neutrals.shade200,
    borderWidth: 1,
    paddingVertical: 10,
    width: web ? '100%' : undefined,
  },
  headerContainer: {
    paddingHorizontal: 10,
    width: '100%',
    gap: 8,
    flexDirection: 'row',
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  textContainer: {
    width: 0,
    flexGrow: 1,
  },
  downloadContainer: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BorderRadius.sm,
    backgroundColor: Brand.primary75,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    paddingHorizontal: 10,
  },
  progressBarContainer: {
    height: 4,
    borderRadius: BorderRadius.xs,
    backgroundColor: Neutrals.shade200,
    flexGrow: 1,
  },
});
