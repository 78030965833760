import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgSaturn = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 90"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M76.193 57.055C71.588 66.904 61.591 73.727 50 73.727a28.765 28.765 0 0 1-16.563-5.214c-3.687.27-7.19.321-10.443.158 6.6 7.471 16.253 12.182 27.006 12.182 17.451 0 32.004-12.409 35.318-28.885-2.737 1.769-5.796 3.477-9.125 5.087m-64.46-17.35C3.279 45.848-1.222 52.51.289 58.147c2.942 10.982 27.584 13.921 55.039 6.565 27.454-7.357 47.325-22.223 44.383-33.205-1.511-5.637-8.739-9.155-19.131-10.249a38.332 38.332 0 0 1 3.782 5.97c2.849.577 5.13 1.37 6.826 2.287 2.347 1.269 3.127 2.507 3.361 3.375.232.869.175 2.331-1.223 4.604-1.406 2.285-3.915 4.931-7.609 7.696-7.364 5.508-18.534 10.812-31.772 14.359-13.239 3.548-25.565 4.539-34.696 3.45-4.582-.546-8.077-1.583-10.438-2.86-2.347-1.269-3.127-2.507-3.36-3.375-.233-.869-.176-2.331 1.222-4.603 1.01-1.642 2.59-3.471 4.769-5.395a38.316 38.316 0 0 1 .291-7.061m14.34 21.335a28.768 28.768 0 0 1-4.972-16.212c0-15.961 12.938-28.9 28.899-28.9s28.899 12.939 28.899 28.9c0 .699-.025 1.392-.073 2.078a60.811 60.811 0 0 0 5.468-3.618c.575-.43 1.115-.854 1.623-1.271C84.483 23.434 68.95 8.802 50 8.802c-19.896 0-36.025 16.129-36.025 36.026a35.884 35.884 0 0 0 3.506 15.523c.651.108 1.334.205 2.05.29a60.4 60.4 0 0 0 6.542.399" />
  </Svg>
);
export default SvgSaturn;
