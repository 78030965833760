import QuestionMarkIcon from '@/assets/onboarding/question.svg';
import Avatar from '@/components/avatar';
import Section from '@/components/section/Section';
import { ChatUnitColorId, InviteStatus } from '@/models';
import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { getRandomTableColor } from '@/utilities/constants/table-colors';
import { web } from '@/utilities/platform';
import React, { memo, useCallback, useMemo } from 'react';
import {
  type ListRenderItem,
  Platform,
  Pressable,
  SectionList,
  type SectionListData,
  StyleSheet,
  View,
} from 'react-native';
import { useMMKVBoolean, useMMKVString } from 'react-native-mmkv';
import { updateInviteBasedOnStatus } from '../invites/helpers/setStatus';
import { useMyInviteStore } from '../invites/state/useMyInvitesStore';
import useOrganisations from '../organisation/hooks/useOrganisations';
import { Colors } from '../theme/Colors';
import useUserStore from '../user/state/useUserStore';
import OnboardingWebLayout from './components/OnboardingWebLayout';
import OnboardingWorkspaceItemBubble from './components/OnboardingWorkspaceItemBubble';

type Props = MainStackScreenProps<'OnboardingInvitedWorkspaces'>;

// This is the onboarding workspace item type
// Used to display a list of workspaces that the user has been invited to join
// and a list of workspaces that the user is already a part of
type Item = {
  id: string;
  name: string;
  image: string;
  // Keep this structure for best React performance
  inviterName: string;
  inviterAvatar: string;
  inviterPhoneNumber: string;
  isInvited?: boolean;
  inviteId?: string;
};

type SectionData = {
  title: string;
  data: Item[];
};

type SectionHeader = (info: {
  section: SectionListData<Item, SectionData>;
}) => JSX.Element | null;

function OnboardingInvitedWorkspacesScreen(_: Props) {
  const [avatar, phoneNumber, name] = useUserStore(state => [
    state.user?.avatar,
    state.user?.phoneNumber,
    state.user?.name,
  ]);
  const pendingInvites = useMyInviteStore(state =>
    state.getPendingInvitesForOrganisations(),
  );
  const { translate } = useTranslations();
  const organisations = useOrganisations();

  const existingWorkspaces = useMemo(() => {
    return [
      // Make sure to keep the personal workspace as the first item and the id as 'personal'
      {
        id: 'personal',
        name: translate('personal_workspace'),
        image: avatar,
        inviterName: name,
        inviterAvatar: avatar,
        inviterPhoneNumber: phoneNumber,
      } as Item,
      ...organisations.map(org => {
        const inviter = org.members?.find(member => member?.role === 'OWNER');
        return {
          id: org.id,
          name: org.name,
          image: org.image,
          inviterName: inviter?.name,
          inviterAvatar: inviter?.avatar,
          inviterPhoneNumber: inviter?.phoneNumber,
        } as Item;
      }),
    ];
  }, [avatar, name, organisations, phoneNumber, translate]);

  const invitedWorkspaces = useMemo(() => {
    return pendingInvites.map(
      pendingInvite =>
        ({
          id: pendingInvite.targetId,
          name: pendingInvite.targetInfo.title,
          image: pendingInvite.targetInfo.backgroundImage,
          inviterName: pendingInvite.inviterInfo.name,
          inviterAvatar: pendingInvite.inviterInfo.avatar,
          inviterPhoneNumber: pendingInvite.inviterInfo.phoneNumber,
          // Making sure to set this flag to true for invited workspaces to see the 'New' bubble
          isInvited: true,
          inviteId: pendingInvite.id,
        }) as Item,
    );
  }, [pendingInvites]);

  const ListHeaderComponent = useMemo(() => {
    return (
      <>
        <Spacer height={50} />
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('join_workspace_caption').toUpperCase()}
        </AppText>
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {pendingInvites?.length
            ? pendingInvites.length > 1
              ? translate('join_workspace_heading_multi', {
                  totalWorkspaces: pendingInvites.length,
                })
              : translate('join_workspace_heading_single')
            : translate('join_workspace_heading_none')}
        </AppText>
        <Spacer height={30} />
        <AppText size={web ? 21 : 17}>
          {pendingInvites?.length
            ? translate('join_workspace_subheading_exist')
            : translate('join_workspace_subheading_none')}
        </AppText>
        <Spacer height={40} />
      </>
    );
  }, [pendingInvites.length, translate]);

  const data = useMemo(
    () => [
      { data: invitedWorkspaces, title: 'INVITED' },
      {
        data: existingWorkspaces,
        title: 'EXISTING',
      },
    ],
    [existingWorkspaces, invitedWorkspaces],
  );

  const renderSectionHeader: SectionHeader = useCallback(
    ({ section: { title, data: sectionData } }) => {
      if (title === 'INVITED' && sectionData.length) {
        return (
          <>
            <AppText size={17} type="primary700">
              {translate('your_workspaces')}
            </AppText>
            <Spacer height={20} />
          </>
        );
      } else if (title === 'EXISTING') {
        return (
          <>
            <AppText size={17} type="primary700">
              {translate('continue_to_your_account')}
            </AppText>
            <Spacer height={20} />
          </>
        );
      }
      return null;
    },
    [translate],
  );

  const renderSectionFooter: SectionHeader = useCallback(
    ({ section: { title } }) => {
      if (title === 'INVITED' && data.length) {
        return (
          <>
            <Section
              p={16}
              bc={Colors.neutral20}
              bw={2}
              br={BorderRadius.lg}
              mv={40}
              bg={Colors.neutral05}>
              {/* Question mark and heading row */}
              <Section row aifs gap={10}>
                <QuestionMarkIcon width={24} height={24} />
                <AppText
                  size={14}
                  type="primary600"
                  style={styles.overflowText}>
                  {translate('dont_see_your_workspace')}
                </AppText>
              </Section>
              <Spacer height={10} />
              {/* Body content */}
              <AppText size={14}>
                {translate('dont_see_your_workspace_body')}
              </AppText>
            </Section>
          </>
        );
      }
      return null;
    },
    [data.length, translate],
  );

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <Section f1 bg={Colors.neutral0}>
        <SectionList
          contentContainerStyle={styles.container}
          ItemSeparatorComponent={ItemSeperator}
          renderSectionHeader={renderSectionHeader}
          renderSectionFooter={renderSectionFooter}
          ListHeaderComponent={ListHeaderComponent}
          sections={data!}
          renderItem={renderItem}
          ListFooterComponent={ListFooterComponent}
        />
      </Section>
    </>
  );
}

const renderItem: ListRenderItem<Item> = ({ item }) => (
  <WorkspaceItem {...item} />
);

const ItemSeperator = memo(() => <Spacer height={40} />);

const ListFooterComponent = memo(() => <Spacer height={60} />);

const WorkspaceItem = memo(
  ({ id, name, image, inviterName, isInvited = false, inviteId }: Item) => {
    const { translate } = useTranslations();
    const [_created, setOnboardingTableCreated] = useMMKVBoolean(
      'onboardingTableCreated',
    );
    const [_, setOnboardingType] = useMMKVString('onboardingType');
    const isPersonal = id === 'personal';

    return (
      <Pressable
        style={styles.workspaceItemContainer}
        onPress={async () => {
          try {
            if (isInvited) {
              // For now, accepting only the workspace invite
              setOnboardingTableCreated(true);
              await updateInviteBasedOnStatus(inviteId!, InviteStatus.ACCEPTED);
            }
          } finally {
            setOnboardingType(undefined);
          }
        }}>
        <Avatar
          size={60}
          displayName={name}
          imageKey={image ?? getRandomTableColor(ChatUnitColorId.BLACK)}
          style={{ borderRadius: BorderRadius.xs }}
        />
        <View>
          <AppText size={17} type="primary700" color={Colors.neutral100}>
            {name}
          </AppText>
          {id === 'personal' ? null : (
            <>
              <Spacer height={10} />
              <AppText size={14} color={Colors.neutral70}>
                {translate('invited_by', { name: inviterName })}
              </AppText>
            </>
          )}
        </View>
        {isInvited ? <OnboardingWorkspaceItemBubble variant="new" /> : null}
        {isPersonal ? (
          <OnboardingWorkspaceItemBubble variant="personal" />
        ) : null}
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    flexGrow: 1,
  },
  workspaceItemContainer: {
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
    borderRadius: BorderRadius.sm,
    backgroundColor: Colors.neutral0,
    padding: 10,
    ...Platform.select({
      default: {
        shadowColor: Colors.neutral0,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 4,
        elevation: 1,
      },
      android: {
        elevation: 1,
      },
    }),
  },
  overflowText: {
    width: 0,
    flexGrow: 1,
  },
});

const OnboardingInvitedWorkspacesScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout showBackButton>
      <OnboardingInvitedWorkspacesScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web
  ? OnboardingInvitedWorkspacesScreenWeb
  : OnboardingInvitedWorkspacesScreen;
