import { StyleSheet } from 'react-native';

import { red } from '@/theme/colors';
import { Greys } from '@/ui/common/colors';

export const styles = StyleSheet.create({
  activeDot: {
    backgroundColor: Greys.shade0,
    borderWidth: 5,
    opacity: 1,
    transform: [{ scale: 1 }],
  },
  backContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 10000,
    gap: 16,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    width: '100%',
    zIndex: 100,
  },
  dot: {
    borderColor: Greys.shade0,
    borderRadius: 5,
    height: 9,
    marginRight: 4,
    width: 9,
  },
  dotsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Greys.shade600,
    borderRadius: 8,
    height: 16,
    marginTop: 5,
    paddingHorizontal: 0,
    paddingLeft: 2,
    alignSelf: 'flex-start',
  },
  inactiveDot: {
    backgroundColor: Greys.shade600,
    borderWidth: 1,
    opacity: 0.5,
    transform: [{ scale: 0.7 }],
  },
  textContainer: {
    justifyContent: 'center',
    transform: [{ translateY: 8 }],
    paddingHorizontal: 15,
    flex: 1,
  },
  unreadMessagesDot: {
    backgroundColor: red,
    opacity: 1,
    borderColor: red,
    borderWidth: 1,
  },
  addButton: {
    marginRight: 10,
  },
  settingsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
