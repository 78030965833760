import { CommonActions } from '@react-navigation/native';

import { navigationRef } from '@/navigation/navigationRef';

/**
 * Parses the deeplink URL to extract necessary data and redirects
 * the user to the appropriate screen.
 *
 * Built to be called after the app has been initialized, and
 * the user is already logged in to the completed profile set
 * of screens.
 *
 * @param url The deeplink URL
 * @param isBackground Whether the app was in the background when the deeplink was received
 */
async function deeplinkHandler(url: string, _ = true) {
  // Log received deeplink information
  // console.log('Deeplink received', { url, isBackground });

  // ? 1. Check if the deeplink is from our domain and uses HTTPS
  if (!url.includes('8seats.com') || !url.startsWith('https')) {
    console.warn('Invalid deeplink: not from 8Seats domain or not HTTPS');
    return;
  }

  // ? 2. Handle different types of deeplinks
  // Invites follow the schema: https://invite.8seats.com?id=ABC123 or https://invite.dev.8seats.com?id=ABC123
  if (url.startsWith('https://invite')) {
    // ? 3. Process the path and query parameters to extract meaningful data
    // Parsing the url to extract the id
    const link = new URL(url);
    const searchParams = new URLSearchParams(link.search);
    const id = searchParams.get('id');

    if (!id) {
      console.warn('Invalid deeplink: missing invite ID');
      return;
    }
    // ? 4. Navigate to relevant screen with the extracted data from the deeplink
    navigationRef.current?.dispatch(
      CommonActions.navigate('InviteDetails', {
        id,
      }),
    );
    return;
  }
  // Add more else-if conditions here to handle other types of deeplinks for the app
}

export default deeplinkHandler;
