import { ActionList } from '@/components/actionList/ActionList';
import useTranslations from '@/translations/useTranslation';
import React, { useCallback, useMemo, useState } from 'react';

import sceneryImg from '@/assets/chatview/scenery.png';
import avatarExamplesImg from '@/assets/onboarding/avatarExamples.png';
import CameraIcon from '@/assets/onboarding/camera.svg';
import { constants } from '@/constants';
import CameraModal from '@/domain/chatUnit/components/view/CameraModal';
import { logger } from '@/services/logger/logger';
import sleep from '@/utilities/helpers/sleep';
import { web } from '@/utilities/platform';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import * as ImagePicker from 'expo-image-picker';
import { StyleSheet } from 'react-native';
import type { PhotoFile } from 'react-native-vision-camera';
import OnboardingChooseAvatarModal from './OnboardingChooseAvatarModal.web';

type Props = {
  avatar: string;
  onSelectAvatar: (avatar: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function OnboardingCreateProfileAvatarPressActions({
  open,
  setOpen,
  avatar,
  onSelectAvatar,
}: Props) {
  const navigation = useNavigation();
  const { translate } = useTranslations();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [avatarChooser, showAvatarChooser] = useState(false);

  const handleCameraSelection = useCallback(
    (photo: PhotoFile) => {
      if (photo.path) {
        console.log('photo', photo);
        onSelectAvatar('file://'.concat(photo.path));
      }
    },
    [onSelectAvatar],
  );

  const handlePhotoSelection = useCallback(async () => {
    const imagePickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      exif: false,
      base64: false,
      allowsMultipleSelection: false,
      quality: 0.85,
    });

    const pickedAsset = imagePickerResult.assets?.[0];

    if (!pickedAsset) {
      return;
    }

    const assetLocalUri = pickedAsset.uri;
    const mimeType = pickedAsset.mimeType || '';
    // Reason: pickedAsset.fileSize is not always available and pickedAsset.filesize is not always available
    // However, one of them is always available.
    // @ts-ignore
    const fileSize = pickedAsset?.fileSize || pickedAsset?.filesize! || 0;

    if (!assetLocalUri) {
      logger.error(
        'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
        'Invalid asset uri',
      );
      return;
    }

    if (!web && (!mimeType || !fileSize)) {
      logger.error(
        'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
        'Invalid asset',
      );
      return;
    }

    if (!web && (!fileSize || fileSize > constants.maxFileSize)) {
      logger.error(
        'OnboardingCreateProfileAvatarPressActions::handlePhotoSelection:: ',
        'File size is too large',
      );
      return;
    }
    onSelectAvatar(assetLocalUri);
  }, [onSelectAvatar]);

  return (
    <>
      {isCameraOpen ? (
        <CameraModal
          isOpen={isCameraOpen}
          toggleCameraVisibility={() => setIsCameraOpen(o => !o)}
          onPhotoTaken={handleCameraSelection}
        />
      ) : null}

      {web ? (
        avatarChooser ? (
          <OnboardingChooseAvatarModal
            open={avatarChooser}
            setOpen={showAvatarChooser}
          />
        ) : null
      ) : null}

      <ActionList
        actionButtonTitle={translate('invite_cancel_button')}
        items={useMemo(
          () =>
            web
              ? [
                  {
                    title: 'Choose a file',
                    onPress: handlePhotoSelection,
                    icon: <Image source={sceneryImg} style={styles.icon} />,
                  },
                  {
                    title: 'Choose a preset avatar',
                    onPress: async () => {
                      showAvatarChooser(true);
                    },
                    icon: (
                      <Image
                        source={avatarExamplesImg}
                        style={styles.avatars}
                      />
                    ),
                  },
                ]
              : [
                  // Extra camera option for mobile
                  {
                    title: 'Use my camera',
                    onPress: () => setIsCameraOpen(true),
                    icon: <CameraIcon />,
                  },
                  {
                    title: 'From photo library',
                    onPress: handlePhotoSelection,
                    icon: <Image source={sceneryImg} style={styles.icon} />,
                  },
                  {
                    title: 'Choose a preset avatar',
                    onPress: async () => {
                      await sleep(constants.modalRaceConditionDelay);
                      navigation.navigate('ChooseAvatar', {
                        selectedAvatar: avatar,
                      });
                    },
                    icon: (
                      <Image
                        source={avatarExamplesImg}
                        style={styles.avatars}
                      />
                    ),
                  },
                ],
          [avatar, handlePhotoSelection, navigation],
        )}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
  avatars: {
    width: 48,
    height: 24,
    resizeMode: 'cover',
  },
});
