import { constants } from '@/constants';

type Fn = (imageKey?: string | null) => boolean;

const isImageKeyDocument: Fn = imageKey => {
  if (!imageKey) {
    return false;
  }

  // If the imageKey is an attachment and the file extension
  // is not supported  as an image or video, then it is a document.
  return (
    imageKey.includes('attachment') &&
    !isImageKeyImage(imageKey) &&
    !isImageKeyVideo(imageKey)
  );
};

const isImageKeyVideo: Fn = imageKey => {
  if (!imageKey) {
    return false;
  }

  const fileExtension = imageKey.split('.').pop()?.toLowerCase() || '';

  return constants.supportedVideoExtensions.includes(fileExtension);
};

const isImageKeyImage = (imageKey?: string | null) => {
  if (!imageKey) {
    return false;
  }

  const fileExtension = imageKey.split('.').pop()?.toLowerCase() || '';

  return (
    constants.supportedImageExtensions.includes(fileExtension || '') ||
    !imageKey.includes('attachment')
  );
};

const getFileName = (imageKey?: string | null) => {
  if (!imageKey) {
    return '';
  }

  return imageKey.split('/').pop();
};

const ImageKeyHelpers = {
  isImageKeyDocument,
  isImageKeyImage,
  isImageKeyVideo,
  getFileName,
};

export default ImageKeyHelpers;
