import type { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TabBarIcon } from './TabBarIcon/TabBarIcon';
import { styles } from './styles';

import { Neutrals } from '@/ui/common/colors';
import Brand from '@/ui/common/colors/Brand';
import { android } from '@/utilities/platform';

const TabBar = ({ descriptors, navigation, state }: BottomTabBarProps) => {
  const { bottom } = useSafeAreaInsets();
  const paddingBottom = android ? bottom + 10 : bottom || 10;
  return (
    <View style={[styles.tabRow, { paddingBottom }]}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          typeof options.tabBarLabel === 'string'
            ? options.tabBarLabel
            : options.title !== undefined
              ? options.title
              : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            canPreventDefault: true,
            target: route.key,
            type: 'tabPress',
          });

          if (!isFocused && !event.defaultPrevented) {
            // The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ merge: true, name: route.name, params: {} });
          }
        };

        const onLongPress = () => {
          navigation.emit({
            target: route.key,
            type: 'tabLongPress',
          });
        };

        return (
          <Pressable
            accessibilityLabel={options.tabBarAccessibilityLabel}
            accessibilityState={isFocused ? { selected: true } : {}}
            key={index.toString()}
            onLongPress={onLongPress}
            onPress={onPress}
            role="button"
            style={styles.icon}
            testID={options.tabBarTestID}>
            <TabBarIcon isFocused={isFocused} name={route.name} />
            <Text
              style={[
                styles.tabLabel,
                { color: isFocused ? Brand.primary100 : Neutrals.shade700 },
              ]}>
              {label}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};

export default TabBar;
