import React, { memo, useState } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { WebView } from 'react-native-webview';

import { useChatUnit } from '../../hooks/useTable';

import HomeIconSmall from '@/assets/svg/home-icon-small.svg';
import HomeIcon from '@/assets/svg/home-icon.svg';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import { primaryBlue } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

type Props = {
  chatUnitId: string;
};

const TableHomeUrl = ({ chatUnitId }: Props) => {
  const [tableUrl, setTableUrl] = useState('');
  const { translate } = useTranslations();
  const { chatUnit, updateTableUrl } = useChatUnit(chatUnitId);
  if (!chatUnit) {
    return null;
  }

  return (
    <KeyboardAwareScrollView
      bounces={false}
      contentContainerStyle={styles.container}>
      {!chatUnit.url ? (
        <View style={styles.noUrlContainer}>
          <View style={styles.topBar}>
            <HomeIconSmall />
            <Text style={styles.topBarText}>
              {translate('home_page_label')}
            </Text>
          </View>
          <HomeIcon />
          <Text style={styles.noUrlHeading}>
            {translate('no_dashboard_home_page_set_label')}
          </Text>
          <Text style={styles.noUrlSubheading}>
            {translate('add_dashboard_url_explanation_text')}
          </Text>
          <Text style={styles.enterUrl}>{translate('enter_url_label')}</Text>
          <TextInput
            autoCapitalize="none"
            onChangeText={e => setTableUrl(e)}
            style={styles.urlInput}
            value={tableUrl}
          />
          <TouchableOpacity
            disabled={!tableUrl}
            onPress={() => {
              if (tableUrl) {
                updateTableUrl(tableUrl);
              }
            }}
            style={tableUrl ? styles.button : styles.buttonDisabled}>
            <Text style={styles.buttonText}>
              {translate('add_home_page_label')}
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.urlContainer}>
          <View style={styles.webviewTopBar}>
            <HomeIconSmall style={styles.webviewIcon} />
            <View style={styles.urlDisplayContainer}>
              <Text style={styles.urlDisplay}>{chatUnit.url}</Text>
            </View>
          </View>
          <WebView source={{ uri: chatUnit.url }} style={styles.webview} />
        </View>
      )}
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: primaryBlue,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: 20,
    width: '100%',
  },
  buttonDisabled: {
    alignItems: 'center',
    backgroundColor: Greys.shade400,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: 20,
    width: '100%',
  },
  buttonText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '700',
  },
  container: { flex: 1 },
  enterUrl: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 10,
  },
  noUrlContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    flex: 1,
    justifyContent: 'center',
    marginTop: wp(14),
    paddingHorizontal: 20,
  },
  noUrlHeading: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 21,
    fontWeight: '400',
    lineHeight: 28,
    marginTop: 20,
  },
  noUrlSubheading: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 23,
    marginBottom: 40,
    marginTop: 20,
    textAlign: 'center',
  },
  topBar: {
    backgroundColor: 'rgba(255, 255, 255, 0.67)',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    flexDirection: 'row',
    height: 60,
    left: 0,
    paddingHorizontal: 5,
    paddingTop: 10,
    position: 'absolute',
    top: -35,
    width: wp(100),
  },
  topBarText: {
    color: Greys.shade800,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 20,
    paddingLeft: 10,
    paddingTop: 1,
  },
  urlContainer: { flex: 1, marginTop: wp(20) },
  urlDisplay: {
    color: Greys.shade800,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    paddingLeft: 10,
    paddingTop: 1,
  },
  urlDisplayContainer: {
    backgroundColor: Greys.shade0,
    borderRadius: 10,
    height: 30,
    justifyContent: 'center',
    width: wp(90),
    zIndex: 10000,
  },
  urlInput: {
    borderColor: Greys.shade400,
    borderRadius: 6,
    borderWidth: 1,
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    height: 50,
    paddingHorizontal: 10,
    textAlign: 'center',
    width: '100%',
  },
  webview: { flex: 1 },
  webviewIcon: { marginTop: 5 },
  webviewTopBar: {
    backgroundColor: Greys.shade400,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    left: 0,
    paddingHorizontal: 5,
    paddingTop: 3,
    position: 'absolute',
    top: -35,
    width: wp(100),
  },
});

export default memo(TableHomeUrl);
