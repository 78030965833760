import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  type TextStyle,
  TouchableOpacity,
  type ViewStyle,
} from 'react-native';

import { wp } from '@/theme/responsiveHelpers';

const SecondaryButton = ({
  disabled = false,
  loading = false,
  onPress,
  style,
  textStyle,
  title,
}: {
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
  style?: ViewStyle;
  textStyle?: TextStyle;
  title: string;
}) => {
  const disabledStyle = { opacity: disabled ? 0.5 : 1 };
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      disabled={disabled}
      onPress={onPress}
      style={[styles.container, disabledStyle, style]}>
      {loading ? (
        <ActivityIndicator color="#fff" size="small" />
      ) : (
        <Text style={[styles.title, textStyle]}>{title}</Text>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: 20,
    width: wp('80'),
  },
  title: { color: '#1C75BC', fontWeight: '700' },
});

export default SecondaryButton;
