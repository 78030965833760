import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Subheading from './text/BackButtonText';

import BackArrow from '@/assets/svg/back-arrow.svg';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

const BackButtonLight = ({ onPress }: { onPress: () => void }) => {
  const { translate } = useTranslations();
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <BackArrow height={20} style={styles.icon} width={10} />
      <Subheading style={styles.text}>{translate('common_back')}</Subheading>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: 10,
  },
  icon: { marginBottom: 5 },
  text: {
    color: Greys.shade0,
    marginLeft: 10,
  },
});

export default BackButtonLight;
