import { useNetInfo } from '@react-native-community/netinfo';
import { useRoute } from '@react-navigation/native';
import Analytics from 'appcenter-analytics';
import { useState } from 'react';
import { Platform } from 'react-native';

import type { SignInProps } from './types';

import type { SignInFormData } from '.';

import AuthService from '@/domain/auth/AuthService';
import { useErrorStore } from '@/domain/error/state/useErrorStore';
import useNavigation from '@/hooks/useNavigation';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { getConstant } from '@/utilities/constants/getConstant';
import { formatPhoneNumber } from '@/utilities/helpers/formatPhoneNumber';
import openLink from '@/utilities/inAppBrowser';

const useConnect = () => {
  const { params } = useRoute<SignInProps['route']>();
  const [error, setError] = useState<string | undefined>();
  const [emailRequiredError, setEmailRequiredError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isInternetReachable } = useNetInfo();
  const { translate } = useTranslations();
  const { navigate } = useNavigation();
  const setShowUhOhScreen = useErrorStore(state => state.setShowUhOhScreen);
  useStatusBarHandler('dark-content');

  const onSubmit = async (data: SignInFormData) => {
    setIsSubmitting(true);
    const parsedPhoneNumber = formatPhoneNumber(data.phoneNumber);

    try {
      if (Platform.OS !== 'web') {
        Analytics.trackEvent('signIn::number', {
          phoneNumber: data.phoneNumber,
          parsedPhoneNumber: parsedPhoneNumber,
        });
      }

      // note: additionalInfo is not defined
      const result: any = await AuthService.signIn(parsedPhoneNumber);
      const email = parsedPhoneNumber.startsWith('+1')
        ? result?.nextStep?.additionalInfo?.email
        : undefined;

      navigate('SmsVerification', {
        phoneNumber: parsedPhoneNumber,
        email,
      });
    } catch (e: any) {
      if (e.message.includes('email address required for OTP')) {
        setEmailRequiredError(true);
        return;
      }

      logger.error('error', e);

      if (isInternetReachable === false) {
        setShowUhOhScreen(true);
        return;
      }

      setError(e.message?.replace('phone_number', 'phone number'));
      if (Platform.OS !== 'web') {
        Analytics.trackEvent('signIn::error', {
          error: JSON.stringify(e),
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToWaitList = () => {
    openLink(getConstant('waitlistUrl') as string);
  };

  const resetError = () => {
    setError(undefined);
  };

  return {
    emailRequiredError,
    error,
    goToWaitList,
    isSubmitting,
    navigate,
    newAccount: params?.newAccount,
    onSubmit,
    resetError,
    translate,
  };
};

export default useConnect;
