import { FlashList } from '@shopify/flash-list';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isObject } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import type { PathString } from 'react-hook-form';
import { FlatList, StyleSheet, View } from 'react-native';

import type { Contact } from '../../state/useContactStore';

import ContactItem from './contactItem';

import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

interface LexicalSectionListProps {
  invitedNumbers?: string[];
  onContactPress: (item: Contact) => void;
  searchQuery: string;
  sections: (string | Contact)[];
  selectedContacts?: ContactInput[];
  canSelect?: boolean;
  isLimitFull?: boolean;
}

const LexicalSectionList = ({
  invitedNumbers = [],
  onContactPress,
  searchQuery,
  sections,
  selectedContacts,
  canSelect = true,
  isLimitFull,
}: LexicalSectionListProps) => {
  const renderItem = ({
    item,
    extraData,
  }: {
    item: string | Contact;
    extraData: ContactInput[];
  }) => {
    return (
      <ContactItem
        isInvited={
          typeof item === 'string'
            ? false
            : isContactInvited(
                invitedNumbers,
                item.phoneNumbers.map(phoneNumber => phoneNumber.number),
              )
        }
        item={item}
        onContactPress={onContactPress}
        searchQuery={searchQuery}
        isSelected={
          selectedContacts
            ? selectedContacts?.some(
                contact =>
                  // @ts-ignore
                  isObject(contact) && contact.id && contact.id === item.id,
              )
            : false
        }
        canSelect={canSelect}
        isLimitFull={isLimitFull}
        key={typeof item === 'string' ? item : item.id}
      />
    );
  };

  const stickyHeaders = useMemo(() => {
    return sections.reduce((acc, item, idx) => {
      if (typeof item === 'string') {
        acc.push(idx);
        return acc;
      }
      return acc;
    }, [] as number[]);
  }, [sections]);

  if (sections.length === 0) {
    return null;
  }

  return (
    <View style={searchQuery.length > 0 ? {} : { flex: 1 }}>
      <FlatList
        contentContainerStyle={styles.contentContainerStyle}
        data={sections}
        estimatedItemSize={sections.length}
        // @ts-ignore - TS not recognizing extraData prop of renderItem
        renderItem={renderItem}
        stickyHeaderIndices={stickyHeaders}
        keyboardShouldPersistTaps="handled"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainerStyle: { paddingBottom: 50 },
  header: {
    backgroundColor: Greys.shade200,
    paddingLeft: 20,
    paddingVertical: 4,
  },
  sectionList: { flexGrow: 1 },
});

function formatPhoneNumber(number: string) {
  const phoneNumber = parsePhoneNumberFromString(number);
  return phoneNumber?.formatInternational() || '';
}

function isContactInvited(
  invitedNumbers: string[],
  contactPhoneNumbers: string[],
): boolean {
  // Format invited numbers
  const formattedInvitedNumbers = invitedNumbers.map(
    num => formatPhoneNumber(num) || num,
  );

  // Check if any contact phone numbers (formatted) are in the invited numbers list
  return contactPhoneNumbers.some(phoneNumber => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    return (
      formattedPhoneNumber &&
      formattedInvitedNumbers.includes(formattedPhoneNumber)
    );
  });
}

export default React.memo(LexicalSectionList);
