import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import type { TableActivity } from '../../../types';

import OctagonIcon from '@/assets/svg/octagon.svg';
import SeenIcon from '@/assets/svg/seen.svg';
import BoldCaption from '@/components/text/BoldCaption';
import Caption from '@/components/text/Caption';
import { primaryBlue, purple, red } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { truncate } from '@/utilities/helpers/strings';

type GroupCardProps = {
  data: TableActivity;
};

const ProfileIcon = require('@/assets/group_profile_icon.png');

export const GroupCard = ({ data }: GroupCardProps) => {
  const { translate } = useTranslations();

  const namesText = data.members.join('  ');
  const messageToShow = truncate(data.lastMessage);

  return (
    <TouchableOpacity style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: ProfileIcon }} />
        <Image source={{ uri: data.profileImage }} style={styles.image} />
      </View>
      <View style={styles.content}>
        {data.newMessages > 0 && (
          <View style={styles.newMessagesContainer}>
            <Caption
              children={`${data.newMessages}`}
              style={styles.newMessages}
            />
          </View>
        )}
        <View
          style={
            data.newMessages
              ? styles.nameAndDateContainerWithNewMessages
              : styles.nameAndDateContainer
          }>
          <View style={styles.nameContainer}>
            <BoldCaption children={data.name} style={styles.name} />
            <Caption children={translate('in')} style={styles.inText} />
            <OctagonIcon style={styles.octagon} />
            <Caption children={data.groupName} style={styles.groupNameText} />
          </View>
          <Caption children={data.time} style={styles.time} />
        </View>
        <View style={styles.messageContainer}>
          <Caption children={messageToShow} style={styles.message} />
        </View>
        <LinearGradient
          colors={[Greys.shade200, '#F6F6F6']}
          end={{ x: 0, y: 1 }}
          start={{ x: 0, y: 0 }}
          style={styles.viewedByContainer}>
          <Caption children={namesText} style={styles.viewedBy} />
          <View style={styles.seenByContainer}>
            <SeenIcon />
            <Caption
              children={`${data.membersViewed}/${data.members.length}`}
              style={styles.seenBy}
            />
          </View>
        </LinearGradient>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Greys.shade0,
    borderRadius: 3,
    flexDirection: 'row',
    marginLeft: 4,
    marginRight: 4,
    marginTop: 1,
  },
  content: {
    flex: 1,
  },
  groupNameText: {
    color: purple,
    fontSize: 10,
    fontWeight: '700',
    marginLeft: 5,
  },
  image: {
    borderColor: Greys.shade0,
    borderRadius: 40,
    borderWidth: 1,
    bottom: 18,
    height: 40,
    left: 8,
    width: 40,
  },
  imageContainer: {
    justifyContent: 'center',
    paddingLeft: 13,
    paddingRight: 15,
  },
  inText: {
    color: Greys.shade400,
    fontSize: 10,
    fontWeight: '600',
    marginLeft: 8,
  },
  message: {
    color: Greys.shade600,
    fontSize: 14,
  },
  messageByMe: {
    color: primaryBlue,
    fontWeight: '600',
  },
  messageContainer: {
    marginTop: 6,
    paddingRight: 20,
  },
  name: {
    color: Greys.shade600,
    top: 2,
  },
  nameAndDateContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    paddingRight: 8,
  },
  nameAndDateContainerWithNewMessages: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    paddingRight: 40,
  },
  nameContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  newMessages: {
    color: Greys.shade0,
    fontSize: 12,
    fontWeight: '700',
  },
  newMessagesContainer: {
    alignItems: 'center',
    backgroundColor: red,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 2,
    justifyContent: 'center',
    paddingHorizontal: 6,
    paddingVertical: 3,
    position: 'absolute',
    right: 1,
    top: 1,
  },
  octagon: {
    marginLeft: 5,
  },
  seenBy: {
    color: Greys.shade500,
    fontSize: 10,
    marginLeft: 4,
  },
  seenByContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  time: {
    color: Greys.shade500,
    fontSize: 10,
  },
  viewedBy: {
    color: Greys.shade500,
    fontSize: 10,
  },
  viewedByContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderTopLeftRadius: 12,
    flexDirection: 'row',
    height: 21,
    justifyContent: 'space-between',
    marginTop: 7,
    paddingLeft: 12,
    paddingRight: 5,
  },
});
