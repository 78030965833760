import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Colors } from '@/domain/theme/Colors';

type Props = {
  size?: number;
  color?: Colors;
};

const Diamond = ({ size = 16, color = Colors.primaryLight }: Props) => {
  const styles = useStyles(size, color);
  return <View style={styles.diamond} />;
};

const useStyles = (size: number, color: Colors) => {
  return useMemo(
    () =>
      StyleSheet.create({
        diamond: {
          width: size, // Adjust to the size you need
          height: size, // Adjust to the size you need
          transform: [{ rotate: '45deg' }],
          borderRadius: size / 6, // Adjust the border radius to get the rounded corners
          backgroundColor: color,
        },
      }),
    [color, size],
  );
};

export default Diamond;
