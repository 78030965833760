import {
  type AVPlaybackStatus,
  ResizeMode,
  Video,
  VideoFullscreenUpdate,
  type VideoFullscreenUpdateEvent,
  type VideoProps,
} from 'expo-av';
import { Image, type ImageStyle } from 'expo-image';
import type { VideoThumbnailsResult } from 'expo-video-thumbnails';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';

import DocumentService from '../image/DocumentService';

import errorImg from '@/assets/chatview/error.png';
import placeholderImg from '@/assets/chatview/placeholderCard.png';
import PlayTriangle from '@/assets/video/playIcon.svg';
import { constants } from '@/constants';
import { Colors } from '@/domain/theme/Colors';
import { logger } from '@/services/logger/logger';
import { AppText } from '@/ui/app';
import type { BorderRadius } from '@/ui/common/styles';

interface Props {
  imageKey?: string;
  width: number;
  height: number;
  borderRadius?: BorderRadius;
  contentFit?: VideoProps['resizeMode'];
  playButtonType?: 'circleTriangle' | 'triangle';
}

const NetworkVideo = ({
  imageKey,
  width = 0,
  height = 0,
  borderRadius,
  contentFit = ResizeMode.CONTAIN,
  playButtonType = 'triangle',
}: Props) => {
  const player = useRef<Video>(null);
  const { uri, error: uriLoadError, refetch } = useNetworkVideo(imageKey);
  const [videoLoadError, setVideoLoadError] = useState(uriLoadError);
  const thumbnail = useThumbnailVideo(uri);

  const openVideo = useCallback(() => {
    player.current?.presentFullscreenPlayer();
    player.current?.playAsync();
  }, []);

  const [computedWidth, computedHeight] = useMemo(
    () =>
      calculateDimensions(
        width,
        height,
        thumbnail?.width ?? 1,
        thumbnail?.height ?? 1,
      ),
    [height, thumbnail?.height, thumbnail?.width, width],
  );

  const computedPlaceHolderStyle: ViewStyle = useMemo(() => {
    return {
      width,
      height,
      borderRadius,
      ...styles.placeholderContainer,
    };
  }, [borderRadius, height, width]);

  const videoPosterContainer: ViewStyle = useMemo(() => {
    // Max width is specified to prevent the video from overflowing the container and is in the props
    // Max height is specified to prevent the video from overflowing the container and is in the props
    // thumbnail.width and thumbnail.height are the dimensions of the video and might be too large for the mobile screen
    // Compute the aspect ratio of the video and maintain width and height
    // The video will be centered in the container
    // The video will be scaled to fit the container
    return {
      width: computedWidth,
      height: computedHeight,
      borderRadius,
      borderWidth: 1,
      borderColor: Colors.neutral20,
      justifyContent: 'center',
      alignItems: 'center',
    };
  }, [borderRadius, computedHeight, computedWidth]);

  const posterStyle: ImageStyle = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      borderRadius: borderRadius,
      resizeMode: 'stretch',
    };
  }, [borderRadius]);

  const handlePlaybackStatusUpdate = useCallback((status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish && player.current) {
        player.current.setPositionAsync(0);
      }
    }
  }, []);

  const handleVideoFullscreenUpdate = useCallback(
    (e: VideoFullscreenUpdateEvent) => {
      if (e.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_DISMISS) {
        player.current?.stopAsync();
      }
    },
    [],
  );

  const Poster = useMemo(() => {
    return () => (
      <Image
        source={{ uri: thumbnail?.uri, cacheKey: imageKey }}
        placeholder={placeholderImg}
        style={posterStyle}
      />
    );
  }, [imageKey, posterStyle, thumbnail?.uri]);

  if (videoLoadError || uriLoadError) {
    return (
      <Pressable style={computedPlaceHolderStyle} onPress={refetch}>
        <Image source={errorImg} style={styles.icon} />
        <AppText textAlign="center" size={14}>
          Video could not be loaded
        </AppText>
        <AppText textAlign="center" color={Colors.neutral70} size={14}>
          Tap to reload
        </AppText>
      </Pressable>
    );
  }

  return (
    <Pressable style={videoPosterContainer} onPress={openVideo}>
      <PlayButton showCircle={playButtonType === 'circleTriangle'} />
      <Video
        style={[StyleSheet.absoluteFillObject, { borderRadius }]}
        ref={player}
        PosterComponent={Poster}
        onError={err => {
          logger.error('NetworkVideo::Error loading video:', err);
          setVideoLoadError(true);
        }}
        source={uri ? { uri } : undefined}
        shouldPlay={false}
        resizeMode={contentFit}
        onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
        onFullscreenUpdate={handleVideoFullscreenUpdate}
      />
    </Pressable>
  );
};

const PlayButton = memo(({ showCircle }: { showCircle: boolean }) => {
  if (showCircle) {
    return (
      <View style={styles.circle}>
        <PlayTriangle height={22} width={19} />
      </View>
    );
  }
  return (
    <View style={styles.playButtonContainer}>
      <PlayTriangle height={22} width={19} />
    </View>
  );
});

const styles = StyleSheet.create({
  icon: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginBottom: 8,
  },
  placeholderContainer: {
    backgroundColor: Colors.neutral10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  squareImg: {
    overflow: 'hidden',
  },
  squareImgPlaceholder: {
    zIndex: 3,
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    zIndex: 5,
    width: 64,
    height: 64,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.neutral30,
    backgroundColor: Colors.neutral0,
    ...Platform.select({
      android: {
        elevation: 4,
      },
      default: {
        shadowColor: Colors.neutral0,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.4,
        shadowRadius: 8,
        elevation: 4,
      },
    }),
  },
  playButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    zIndex: 5,
  },
});

const useThumbnailVideo = (videoUrl?: string) => {
  const [res, setRes] = useState<VideoThumbnailsResult>();

  const init = useCallback(async () => {
    if (!videoUrl) {
      return;
    }

    const thumb = await DocumentService.getVideoThumbnail(videoUrl!, {
      quality: constants.videoThumbnailQuality,
      time: constants.videoThumbnailTimePositionInMillis,
    });
    setRes(thumb);
  }, [videoUrl]);

  useEffect(() => {
    init();
  }, [init]);

  return res;
};

const useNetworkVideo = (imageKey?: string) => {
  const [uri, setUri] = useState('');
  const [error, setError] = useState(false);

  /**
   * Fetches the video URL from the backend and sets the URI
   */
  const init = useCallback(async () => {
    setError(false);
    if (!imageKey) {
      logger.error('NetworkVideo::init::No image key provided');
      setError(true);
      return;
    }

    try {
      const videoUrl =
        await DocumentService.fetchPresignedUrlFromBackend(imageKey);
      if (!videoUrl) {
        setError(true);
        return;
      }
      setUri(videoUrl);
    } catch (e) {
      setError(true);
    }
  }, [imageKey]);

  useEffect(() => {
    init();
  }, [init]);

  /**
   * Refetches the video URL from the backend
   */
  const refetch = useCallback(() => {
    init();
  }, [init]);

  return { uri, error, refetch };
};

function calculateDimensions(
  maxWidth: number,
  maxHeight: number,
  rawWidth: number,
  rawHeight: number,
): [number, number] {
  const scaleFactor = Math.min(maxWidth / rawWidth, maxHeight / rawHeight);

  const newWidth = rawWidth * scaleFactor;
  const newHeight = rawHeight * scaleFactor;

  return [Math.round(newWidth), Math.round(newHeight)];
}

export default memo(NetworkVideo);
