import { DataStore } from '@aws-amplify/datastore';

import { ChatUnit, ChatUnitType } from '../../../models';

import { factory } from '@/services/factory/factory';
import { SortDirection } from 'aws-amplify/datastore';

type Props = {
  type?: ChatUnitType;
  lastUpdated?: Date | null;
};

export const getChatUnits = async ({ type, lastUpdated }: Props = {}) => {
  let results = [];
  if (type || lastUpdated) {
    results = await DataStore.query(ChatUnit, chatUnit => {
      return chatUnit.and(c => {
        const predicates = [];
        if (lastUpdated) {
          predicates.push(c.updatedAt.gt(lastUpdated.toISOString()));
        }
        if (type) {
          predicates.push(c.type.eq(type));
        }

        return predicates;
      });
    });
  } else {
    results = await DataStore.query(ChatUnit);
  }

  return results.map(chatUnit => factory.chatUnit(chatUnit));
};

export const getDMs = async () => {
  const results = await DataStore.query(
    ChatUnit,
    chatUnit => {
      return chatUnit.and(c => {
        return [c.type.eq(ChatUnitType.QUICK_CHAT)];
      });
    },
    { sort: s => s.createdAt(SortDirection.DESCENDING) },
  );
  return results.map(chatUnit => factory.chatUnit(chatUnit));
};
