import React from 'react';
import { StyleSheet, View } from 'react-native';

import GridButton from './GridButton';

import { primaryBlue } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';

const GridItems = ({ navigate }: { navigate: (route: string) => void }) => {
  const { translate } = useTranslations();

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <GridButton
          iconKey="Envelope"
          onPress={() => navigate('InviteList')}
          subtitle={translate('user_menu_grid_invites_button_subtitle')}
          title={translate('user_menu_grid_invites_button_title')}
        />
        <GridButton
          iconKey="Bust"
          onPress={() => navigate('Directory')}
          subtitle={translate('user_menu_grid_directory_button_subtitle')}
          title={translate('user_menu_grid_directory_button_title')}
        />
      </View>
      <View style={styles.row}>
        <GridButton
          iconKey="Cog"
          subtitle={translate('user_menu_grid_settings_button_subtitle')}
          title={translate('user_menu_grid_settings_button_title')}
        />
        <GridButton
          iconKey="Saturn"
          onPress={() => navigate('Discover')}
          subtitle={translate('user_menu_grid_discover_button_subtitle')}
          title={translate('user_menu_grid_discover_button_title')}
        />
      </View>
      <View style={styles.row}>
        <GridButton
          iconKey="Bell"
          onPress={() => navigate('Activity')}
          subtitle={translate('user_menu_grid_activity_button_subtitle')}
          title={translate('user_menu_grid_activity_button_title')}
        />
        <GridButton
          iconKey="Tables"
          onPress={() => navigate('Tables')}
          subtitle={translate('user_menu_grid_tables_button_subtitle')}
          title={translate('user_menu_grid_tables_button_title')}
        />
      </View>
      <View style={styles.row}>
        <GridButton
          iconKey="Rooms"
          onPress={() => navigate('Rooms')}
          subtitle={translate('user_menu_grid_rooms_button_subtitle')}
          title={translate('user_menu_grid_rooms_button_title')}
        />
        <GridButton
          iconKey="Bubble"
          onPress={() => navigate('DMs')}
          subtitle={translate('user_menu_grid_one_on_one_button_subtitle')}
          title={translate('user_menu_grid_one_on_one_button_title')}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  blueText: {
    color: primaryBlue,
  },
  container: {
    backgroundColor: '#cccccc',
    paddingBottom: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 1,
  },
});
export default GridItems;
