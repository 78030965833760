import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';

import LogoDark from '@/assets/svg/logo-dark.svg';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 40,
  },
  title: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: web ? 60 : 30,
    lineHeight: web ? 60 : 30,
    marginTop: 30,
    maxWidth: 700,
    fontWeight: '900',
    textAlign: 'center',
  },
});

export const Headline = () => (
  <View style={styles.container}>
    <LogoDark />

    <Text style={styles.title}>
      <Trans i18nKey="exclusive_access_intro_title" />
    </Text>
  </View>
);
