import { type UploadDataOutput, uploadData } from 'aws-amplify/storage';
import * as DocumentPicker from 'expo-document-picker';
import type { VideoThumbnailsResult } from 'expo-video-thumbnails';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import DocumentService from '@/components/image/DocumentService';
import { constants } from '@/constants';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { formatBytes } from '@/utilities/helpers/formatBytes';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { uriToBlob } from '@/utilities/lib/uriToBlob';

type OnComplete = (document: string) => void;

type Document = {
  fileName: string;
  localFileUri: string;
  size?: number | null;
};

const MAX_FILE_SIZE = constants.maxFileSize;

const useDocumentUpload = (chatUnitId: string, onComplete: OnComplete) => {
  const [document, setDocument] = useState<Document>();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadTaskRef = useRef<UploadDataOutput>();
  const { translate } = useTranslations();
  const [thumbnail, setThumbnail] = useState<VideoThumbnailsResult>();

  const { showPrompt } = usePromptStore();

  // Reason:-
  // The amplify onProgress fires multiple times in a short period of time.
  // We do not want to recreate the function on every render
  // and we do not want to add it as a dependency to the hook.
  // We also do not want updates every millisecond.
  // Currently, the linter complains about having an unknown inline function
  // and not being able to detect dependencies. Fixing this error is not worth it
  // and out of scope.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleProgress = useCallback(
    debounce(setProgress, constants.progressDebounceTime),
    [],
  );

  const uploadDocument = useCallback(
    async (localFileUri: string, fileName: string, mime: string) => {
      setLoading(true);
      setProgress(0);

      const s3Key = `chatunit/${chatUnitId}/attachments/${fileName}`;
      let blob;
      try {
        blob = await uriToBlob(localFileUri);
      } catch (err) {
        logger.error(
          'useDocumentUpload::Error while picking the document:',
          err,
        );
        setDocument(undefined);
        setLoading(false);
        showPrompt({
          body: translate('file_upload_unsupported_error_message', {
            fileName,
          }),
          title: translate('file_upload_unsupported_error'),
        });
        return;
      }

      try {
        const task = uploadData({
          data: blob,
          key: s3Key,
          options: {
            contentType: mime,
            // Folder in S3
            onProgress(event) {
              const progressVal = Math.min(
                Math.ceil(
                  (event?.transferredBytes / (event.totalBytes || 1)) * 100,
                ),
                100,
              );
              handleProgress(progressVal);
            },
          },
        });
        // Start the upload and store the task in a ref
        uploadTaskRef.current = task;
        // Wait for the upload to complete
        const { key } = await task.result;
        onComplete(key);
        setLoading(false);
      } catch (err) {
        setDocument(undefined);
        setLoading(false);
        // Reason: The error is sometimes a CancelledError and has a type of unknown
        // @ts-ignore
        if (err?.name === 'CanceledError' || err === 'CanceledError') {
          return;
        }
        showPrompt({
          body: translate('file_upload_network_error_message', { fileName }),
          title: translate('file_upload_network_error'),
        });
        logger.error(
          'useDocumentUpload::Error while picking the document:',
          err,
        );
      }
    },
    [chatUnitId, showPrompt, translate, onComplete, handleProgress],
  );

  const cancelUpload = useCallback(() => {
    if (uploadTaskRef.current) {
      uploadTaskRef.current.cancel();
    }
    setLoading(false);
    setDocument(undefined);
  }, []);

  const handleDocumentSelection = useCallback(
    async (onlyWebMedia = false) => {
      try {
        const response = await DocumentPicker.getDocumentAsync({
          copyToCacheDirectory: false,
          type: onlyWebMedia ? ['image/*', 'video/*'] : undefined,
        });

        if (!response.assets?.length) {
          return;
        }

        const selectedFile = response.assets?.[0];

        if (!selectedFile) {
          showPrompt({
            body: translate('unknown_file_upload_unsupported_error_message'),
            title: translate('file_upload_unsupported_error'),
          });
          return;
        }

        const { uri: localFileUri, name, mimeType, size } = selectedFile;
        if (!localFileUri || !mimeType || !size) {
          showPrompt({
            body: translate('unknown_file_upload_unsupported_error_message'),
            title: translate('file_upload_unsupported_error'),
          });
          return;
        }

        const fileName = name || localFileUri.split('/').pop() || '';
        if (!size || size > MAX_FILE_SIZE) {
          showPrompt({
            body: translate('file_upload_size_error_message', {
              fileName: `"${name}"`,
              size: formatBytes(size!),
              limit: formatBytes(MAX_FILE_SIZE),
            }),
            title: translate('file_upload_size_error'),
          });
          return;
        }

        if (ImageKeyHelpers.isImageKeyVideo(localFileUri)) {
          const res = await DocumentService.getVideoThumbnail(localFileUri, {
            quality: constants.videoThumbnailQuality,
            time: constants.videoThumbnailTimePositionInMillis,
          });
          setThumbnail(res);
        }

        setDocument({ localFileUri, fileName, size });
        uploadDocument(localFileUri, fileName, mimeType || '');
      } catch (err) {
        logger.error(
          'useDocumentUpload::Error while picking the document:',
          err,
        );
      }
    },
    [showPrompt, translate, uploadDocument],
  );

  return {
    document,
    loading,
    handleDocumentSelection,
    setDocument,
    cancelUpload,
    progress,
    thumbnail,
  };
};

export default useDocumentUpload;
