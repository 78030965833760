import AsyncStorage from '@react-native-async-storage/async-storage';
import { DataStore } from 'aws-amplify/datastore';

import { dataStoreConfigure } from './datastoreConfigure';

import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import {
  ChatUnit,
  ChatUnitUser,
  Conversation,
  Invite,
  MessageV2,
  User,
} from '@/models';
import {
  addDatastoreObservable,
  unsubscribe,
} from '@/services/datastore/subscriptions/subscriptionManager';
import { logger } from '@/services/logger/logger';

let bootstrapped = false;
let subscriptionsSetup = false;
export const doBootstrap = async () => {
  if (bootstrapped) {
    logger.debug('bootstrapSkipping');
    return;
  }
  logger.debug('boostrapStart');

  dataStoreConfigure();
  await DataStore.start();
  // setup datastore subscriptions

  bootstrapped = true;

  logger.debug('bootstrapComplete');
};

export const setupSubscriptions = async () => {
  if (subscriptionsSetup) {
    return;
  }
  addDatastoreObservable('Conversation', DataStore.observe(Conversation));
  addDatastoreObservable('Message', DataStore.observe(MessageV2));
  addDatastoreObservable('ChatUnit', DataStore.observe(ChatUnit));
  addDatastoreObservable('ChatUnitUser', DataStore.observe(ChatUnitUser));
  addDatastoreObservable('User', DataStore.observe(User));
  addDatastoreObservable('Invites', DataStore.observe(Invite));
  subscriptionsSetup = true;
};

export const bootstrapCleanUp = async () => {
  if (!bootstrapped) {
    return;
  }
  unsubscribe(); // need to deregister all subscriptions
  await AsyncStorage.setItem('initialized', 'false');
  subscriptionsSetup = false;
  useLoadingStore.getState().setDatastoreReady(false);
  await DataStore.stop();
  useMessageStore.getState().clearState();
  bootstrapped = false;
};
