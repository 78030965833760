import React, { useState } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import SuccessBadge from '@/assets/svg/successBadge.svg';
import AuthService from '@/domain/auth/AuthService';
import { red2 } from '@/theme/colors';
import { Greys } from '@/ui/common/colors';
import { delay } from '@/utilities/lib/delay';

type ComfirmModalProps = {
  close: () => void;
};
const ConfirmModal = ({ close }: ComfirmModalProps) => {
  const [successModalVisible, setSuccessModalVisible] =
    useState<boolean>(false);
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modelContentContainer}>
        <Text style={styles.heading}>Delete Your 8seats Account</Text>
        <Text style={styles.infoText}>
          Proceeding with this step will permanently delete your 8seats account.
          Please be aware that this cannot be reversed once done.
        </Text>

        <View style={styles.actionsContainer}>
          <View style={styles.actions}>
            <TouchableOpacity
              onPress={async () => {
                setSuccessModalVisible(true);
                await delay(1000);
                // TODO: HZN-859 - delete cognito user
                await AuthService.logout();
              }}>
              <Text style={styles.deleteText}>Delete my account</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => close()}>
              <Text style={styles.cancelText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Modal animationType="fade" visible={successModalVisible}>
        <View style={styles.successContainer}>
          <SuccessBadge />
          <Text style={styles.successText}>Your account has been deleted</Text>
        </View>
      </Modal>
    </View>
  );
};

export default ConfirmModal;

const styles = StyleSheet.create({
  actions: {
    backgroundColor: Greys.shade0,
  },
  actionsContainer: {
    paddingVertical: 20,
  },
  cancelText: {
    color: Greys.shade600,
    fontSize: 17,
    lineHeight: 17,
    marginTop: 20,
    textAlign: 'center',
  },
  deleteText: {
    color: red2,
    fontSize: 17,
    lineHeight: 17,
    textAlign: 'center',
  },
  heading: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 17,
    textAlign: 'center',
  },
  infoText: {
    color: Greys.shade600,
    fontSize: 14,
    lineHeight: 19,
    marginTop: 10,
    textAlign: 'center',
  },
  modalContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(55,55,55,.2)',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  modelContentContainer: {
    backgroundColor: Greys.shade0,
    borderRadius: 18,
    paddingHorizontal: 25,
    paddingVertical: 30,
    width: '92%',
  },
  successContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: 1,
    justifyContent: 'center',
  },
  successText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 28,
    fontWeight: '400',
    marginTop: 20,
    textAlign: 'center',
  },
});
