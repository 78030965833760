import { useCallback, useEffect, useState } from 'react';

import DocumentService from './DocumentService';

import { logger } from '@/services/logger/logger';
import { web } from '@/utilities/platform';

/**
 * Fetches and caches an image from the network using the image key from Amplify Storage
 * Note that loaded is not the loading state, but the state of the image being loaded, i,e,
 * the image is loaded and ready to be displayed.
 *
 * The `refetch` function can be used to force a refetch of the image from the network, i,e,
 * the image will be downloaded again and cached.
 *
 * The `setError` function can be used to set the error state manually. This is useful when
 * the error state is not set by the hook itself, but by the parent component. For example,
 * when the parent component is responsible for handling the error state and displaying an
 * error message.
 *
 * The states get reset when the image key changes, and if the image is refetched.
 *
 * @param imageKey The image key from Amplify Storage
 * @returns The local URI of the image, error state, loaded state, and a refetch function
 *
 * @example
 * const { localUri, error, loaded, refetch, setError } = useNetworkImage('imageKey');
 * if (error) {
 *  // Good practice to refetch the image if it fails to load
 *  return <ErrorImagePlaceholder onPress={refetch} />;
 * }
 *
 * if (!loaded) {
 *  return <LoadingImagePlaceholder />;
 * }
 *
 * return <FastImage source={{ uri: localUri }} onError={() => {
 *   // Good practice to handle the error
 *   // Failure to handle the local image
 *   // Maybe it was corrupted
 *    setError(true)
 * }} />;
 */
const useNetworkImage = (imageKey = '') => {
  const [localUri, setLocalUri] = useState('');
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const fetchAndCacheImage = useCallback(
    async (forceRefetch = false) => {
      setError(false);

      if (!imageKey) {
        logger.error('useNetworkImage::Error: Image key is empty');
        setError(true);
        return;
      }

      if (web) {
        const imgUrl =
          await DocumentService.fetchPresignedUrlFromBackend(imageKey);
        setLocalUri(imgUrl);
        return;
      }

      try {
        // Check if already exists in the cache
        const localPath =
          await DocumentService.getLocalFileSystemPathByImageKey(imageKey);
        if (!localPath || forceRefetch) {
          setLoaded(false);
          const imgUrl =
            await DocumentService.fetchPresignedUrlFromBackend(imageKey);
          if (!imgUrl) {
            logger.error('useNetworkImage::Error: Image url is empty');
            setError(true);
            return;
          }
          setLocalUri(imgUrl);
        } else {
          if (localPath.startsWith('file://')) {
            setLocalUri(localPath);
          } else {
            setLocalUri(`file://${localPath}`);
          }
        }
        setLoaded(true);
      } catch (e) {
        logger.error('useNetworkImage::Error', e);
        setError(true);
      }
    },
    [imageKey],
  );

  useEffect(() => {
    fetchAndCacheImage();
  }, [fetchAndCacheImage]);

  const refetch = useCallback(() => {
    fetchAndCacheImage(true);
  }, [fetchAndCacheImage]);

  return { localUri, error, loaded, refetch, setError };
};

export default useNetworkImage;
