import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { type Invite, InviteStatus, InviteType } from '../types/types';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import { InviteTargetType } from '@/models';
import { formatActivityDate } from '@/modules/activity/timeHelper';
export type InviteState = {
  addInvites: (invites: Invite[]) => void;
  clearInvites: () => void;
  setLastUpdated: (lastUpdated: Date) => void;
  getInvite: (id: string) => Invite | undefined;
  removeInvite: (id: string) => void;
  getLatestInviteDate: () => string;
  getLatestInviteDateForMessages: () => string;
  getLatestInviteDateForOrganisations: () => string;
  getPendingInvites: () => Invite[];
  getPendingInviteCount: () => number;
  getPendingInviteCountForMessages: () => number;
  getPendingInviteCountForOrganisations: () => number;
  getPendingInvitesForOrganisations: () => Invite[];
  getReceivedInvites: () => Invite[];
  getReceivedInvitesForOrganisations: () => Invite[];
  getReceivedInvitesForMessages: () => Invite[];
  getSentInvites: () => Invite[];
  hasPendingInvites: () => boolean;
  invites: Map<string, Invite>;
  lastUpdated: null | Date;
};

export const useMyInviteStore = create<InviteState>()(
  persist(
    immer((set, get) => ({
      addInvites: (invites: Invite[]): void =>
        set(state => {
          invites.forEach(invite => {
            state.invites.set(invite.inviteId, invite);
          });
        }),
      removeInvite: (inviteId: string): void =>
        set(state => {
          state.invites.delete(inviteId);
        }),
      clearInvites: () =>
        set(state => {
          state.invites = new Map();
          state.lastUpdated = null;
        }),
      setLastUpdated: (lastUpdated: Date) =>
        set(state => {
          state.lastUpdated = lastUpdated;
        }),
      getInvite: (id: string) => get().invites.get(id),
      getLatestInviteDate: () => {
        const pendingInvites = get().getPendingInvites();
        const latestInvite = pendingInvites.reduce(
          (prev, current) =>
            moment(prev.createdAt).isBefore(current.createdAt) ? current : prev,
          pendingInvites[0],
        );

        return latestInvite?.createdAt
          ? formatActivityDate(new Date(latestInvite.createdAt))
          : '';
      },
      getLatestInviteDateForMessages: () => {
        const pendingInvites = get()
          .getPendingInvites()
          .filter(
            invite => invite.targetType !== InviteTargetType.ORGANISATION,
          );
        const latestInvite = pendingInvites.reduce(
          (prev, current) =>
            moment(prev.createdAt).isBefore(current.createdAt) ? current : prev,
          pendingInvites[0],
        );

        return latestInvite?.createdAt
          ? formatActivityDate(new Date(latestInvite.createdAt))
          : '';
      },
      getLatestInviteDateForOrganisations: () => {
        const pendingInvites = get().getPendingInvitesForOrganisations();
        const latestInvite = pendingInvites.reduce(
          (prev, current) =>
            moment(prev.createdAt).isBefore(current.createdAt) ? current : prev,
          pendingInvites[0],
        );

        return latestInvite?.createdAt
          ? formatActivityDate(new Date(latestInvite.createdAt))
          : '';
      },
      getPendingInvites: () =>
        get()
          .getReceivedInvites()
          .filter(invite => invite.status === InviteStatus.PENDING),
      getPendingInviteCount: () => get().getPendingInvites().length,
      getReceivedInvites: () =>
        get().invites.size > 0
          ? [...get().invites?.values()].filter(
              invite => invite.type === InviteType.RECEIVER,
            )
          : [],
      getSentInvites: () =>
        [...get().invites?.values()].filter(
          invite => invite.type === InviteType.SENDER,
        ),
      hasPendingInvites: () =>
        [...get().invites?.values()].some(
          invite =>
            invite.status === InviteStatus.PENDING &&
            invite.type === InviteType.RECEIVER,
        ),
      getPendingInviteCountForMessages: () => {
        // Subtract pending invite count for organisations from total pending invites
        const invites = get().getPendingInvites();
        return invites.filter(
          invite => invite.targetType !== InviteTargetType.ORGANISATION,
        ).length;
      },
      getPendingInviteCountForOrganisations: () => {
        const invites = get().getPendingInvites();
        return invites.filter(
          invite => invite.targetType === InviteTargetType.ORGANISATION,
        ).length;
      },
      getPendingInvitesForOrganisations: () => {
        const invites = get().getPendingInvites();
        return invites.filter(
          invite => invite.targetType === InviteTargetType.ORGANISATION,
        );
      },
      getReceivedInvitesForMessages: () => {
        const invites = get().getReceivedInvites();
        return invites.filter(
          invite => invite.targetType !== InviteTargetType.ORGANISATION,
        );
      },
      getReceivedInvitesForOrganisations: () => {
        const invites = get().getReceivedInvites();
        return invites.filter(
          invite => invite.targetType === InviteTargetType.ORGANISATION,
        );
      },
      invites: new Map(),
      lastUpdated: null,
    })),
    {
      name: 'myinvite-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
      partialize: state => ({
        invites: state.invites instanceof Map ? state.invites : new Map(),
        lastUpdated: state.lastUpdated,
      }),
    },
  ),
);
