import React from 'react';
import { Dimensions, Pressable, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { useNavigation } from '@react-navigation/native';

const CUSTOM_GRADIENT_COLOR_SIGN_IN = '#CCC5BE';

const GRADIENT_COLORS = [Colors.neutral0, CUSTOM_GRADIENT_COLOR_SIGN_IN];
const START = { x: 0, y: 0 };
const END = { x: 0, y: 1 };

function OnboardingSignInButton() {
  const { translate } = useTranslations();
  const navigation = useNavigation();

  return (
    <Pressable onPress={() => navigation.navigate('OnboardingSignIn')}>
      <LinearGradient
        colors={GRADIENT_COLORS}
        style={styles.signInButton}
        start={START}
        end={END}>
        <AppText type="primary700" size={17} textAlign="center">
          {translate('sign_in_label')}
        </AppText>
      </LinearGradient>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  signInButton: {
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: Dimensions.get('window').width - 80,
  },
});

export default OnboardingSignInButton;
