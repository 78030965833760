import React from 'react';
import { StyleSheet, View } from 'react-native';
import Rive, { Fit } from 'rive-react-native';

import { Colors } from '@/domain/theme/Colors';
import { ios } from '@/utilities/platform';

const riveName = ios ? '8seats_intro' : 'seats_intro';

export const Background = () => {
  // if (web) {
  //   return <View style={[styles.background, styles.web]} />;
  // }

  return (
    <View style={styles.background}>
      <Rive fit={Fit.Cover} resourceName={riveName} style={styles.background} />
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  web: {
    backgroundColor: Colors.desert,
  },
});
