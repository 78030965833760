import _ from 'lodash';

import { UserThemes } from '@/ui/common/colors';

function stringToHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const _getColorForName = (username = '') => {
  const hash = stringToHash(username);
  const colorIndex = Math.abs(hash) % Object.keys(UserThemes).length;
  const colorKey = Object.keys(UserThemes)[colorIndex];
  return UserThemes[colorKey as keyof typeof UserThemes];
};

export const getColorForName = _.memoize(_getColorForName);
