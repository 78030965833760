import Section from '@/components/section/Section';
import type { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import { web } from '@/utilities/platform';
import React from 'react';
import { Linking } from 'react-native';

type Props = {
  color: Colors;
};

const currentYear = new Date().getFullYear();

export default function OnboardingLinksFooter({ color }: Props) {
  return (
    <Section row aic gap={20} fw>
      <AppText
        color={color}
        size={12}
        type="primary600"
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/terms-of-use')
        }>
        Terms of use
      </AppText>
      <AppText
        color={color}
        size={12}
        type="primary600"
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/privacy-policy')
        }>
        Privacy Policy
      </AppText>
      <AppText
        color={color}
        size={12}
        type="primary600"
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/end-user-license-agreement')
        }>
        End User License Agreement
      </AppText>
      <AppText
        color={color}
        size={12}
        type="primary600"
        onPress={() => Linking.openURL('https://site.8seats.com/contact-us')}>
        Contact Us
      </AppText>
      <AppText
        color={color}
        size={12}
        type="primary600"
        onPress={() => Linking.openURL('https://support.8seats.com/faqs')}>
        FAQs
      </AppText>
      {web ? <Section fg1 /> : null}
      <AppText color={color} size={12}>
        © 8seats Group Pty Ltd {currentYear}. All rights reserved.
      </AppText>
    </Section>
  );
}
