import { StyleSheet } from 'react-native';

import { primaryBlue } from '@/theme/colors';
import { hp, wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  backBlackIcon: { position: 'absolute' },
  backContainer: {
    alignItems: 'center',
    flex: 0.3,
    flexDirection: 'row',
  },
  backText: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    marginLeft: 10,
  },
  backgroundImage: {
    alignItems: 'center',
    height: web ? '35%' : hp(35),
    justifyContent: 'center',
    width: web ? '100%' : wp(100),
  },
  cancel: {
    color: primaryBlue,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
  },
  container: {
    backgroundColor: Greys.shade200,
    flex: 1,
  },
  containerWithPadding: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  content: {
    backgroundColor: Greys.shade0,
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 100,
  },
  createdByLabel: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  descriptionContainer: {
    backgroundColor: Greys.shade0,
    marginBottom: 10,
    marginTop: 10,
    paddingHorizontal: 20,
  },
  descriptionInput: {
    marginTop: 0,
  },
  descriptionInputNoValue: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
  },
  descriptionInputWithValue: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
  },
  detailsRow: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    marginTop: 10,
    paddingHorizontal: 20,
  },
  detailsRowWithMargin: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    marginTop: 8,
    paddingHorizontal: 20,
  },
  detailsValue: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    marginLeft: 10,
  },
  divider: {
    backgroundColor: Greys.shade200,
    height: 1,
    width: wp(100),
  },
  emptyContainer: {
    alignItems: 'flex-end',
    flex: 0.3,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 44,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    position: 'absolute',
    width: web ? '100%' : wp(100),
    zIndex: 100,
  },
  headerIcon: { bottom: 40, position: 'absolute' },
  headerTitle: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  safeArea: {
    backgroundColor: Greys.shade0,
    position: 'absolute',
    width: web ? '100%' : wp(100),
    zIndex: 100,
  },
  saveLabel: {
    color: primaryBlue,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  spacer10: {
    height: 10,
  },
  tableDescriptionLabel: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  tableNameLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  tableTitle: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 21,
    fontWeight: '600',
  },
  titleContainer: {
    alignItems: 'center',
    flex: 1,
  },
});
