import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Pressable,
  StyleSheet,
} from 'react-native';

import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import { web } from '@/utilities/platform';

type Props = {
  onPress: () => void;
  text: string;
  disabled?: boolean;
  loading?: boolean;
};

function OnboardingNextButton({
  onPress,
  text,
  disabled = false,
  loading = false,
}: Props) {
  return (
    <Pressable
      style={disabled ? styles.nextBtnDisabled : styles.nextBtn}
      onPress={onPress}
      disabled={disabled}>
      {loading ? <ActivityIndicator color={Colors.neutral0} /> : null}
      <AppText
        type="primary700"
        size={17}
        textAlign="center"
        color={disabled ? Colors.neutral50 : Colors.neutral0}>
        {text}
      </AppText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  nextBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: web ? '100%' : Dimensions.get('window').width - 60,
    backgroundColor: Colors.primaryLight,
    gap: 8,
  },
  nextBtnDisabled: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: web ? '100%' : Dimensions.get('window').width - 60,
    backgroundColor: Colors.neutral10,
    gap: 8,
  },
});

export default OnboardingNextButton;
