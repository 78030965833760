import type { ColorSchemeName } from 'react-native';

import { reset } from './reset';

import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';

type Save = (theme: ColorSchemeName) => void;

export const save: Save = async theme => {
  if (theme != null) {
    await asyncStorageMethods.setItem('theme', theme as string);
    return;
  }

  reset();
};
