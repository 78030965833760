import { saveMessage } from '@/services/datastore/message/saveMessage';
import { getChatUnitUsersForConversation } from '@/services/datastore/user/getChatUnitUsersByConversationId';
import { logger } from '@/services/logger/logger';
import generateUniqueId from '@/utilities/helpers/uuid';

export const outputConversationDetails = async (
  conversationId: string,
  chatUnitId: string,
  userId: string,
  chatUnitUserId: string,
) => {
  const chatUnitUsers = await getChatUnitUsersForConversation(
    conversationId,
    chatUnitId,
  );
  const textDetails = {
    conversationId: conversationId,
    chatUnitId: chatUnitId,
    ...chatUnitUsers?.reduce(
      (acc, user, idx) => {
        acc[`user${idx}`] = user.id;
        return acc;
      },
      {} as { [key: string]: string },
    ),
  };
  type TextDetailsKey = keyof typeof textDetails;

  logger.info(
    'outputConversationDetails',
    JSON.stringify(textDetails, null, 2),
  );

  const messageDetails = {
    conversationId: conversationId,
    createdAt: new Date().toISOString(),
    chatUnitId: chatUnitId,
    chatUnitUserId,
    allowedUserIds: chatUnitUsers?.map(user => user.id) ?? [],
    messageId: generateUniqueId(),
    SK: '1',
    text: Object.keys(textDetails).reduce((acc, key) => {
      acc += `${key}: \n${textDetails[key as TextDetailsKey]}\n`;
      return acc;
    }, ''),
    userId,
  };

  await saveMessage(messageDetails);
};
