enum Greys {
  shade0 = '#ffffff',
  shade100 = '#f5f5f5',
  shade200 = '#e6e6e6',
  shade300 = '#b0b0b0',
  shade400 = '#979797',
  shade500 = '#555555',
  shade600 = '#373737',
  shade800 = '#272727',
  shade999 = '#000000',
}

export default Greys;
