import { useCallback, useEffect } from 'react';
import { Linking } from 'react-native';

import deeplinkHandler from './handler';

/**
 * ! Caution: This hook should be called only once.
 * ! Caution: This hook should be called after the navigation container is mounted.
 * ! Caution: This hook should be called after the state is fresh & ready to handle the deeplink.

 * If the app is not already open, it is opened and
 * the url is passed in as the initialURL and handled
 * by the app as a deep link.
 *
 * The hook inits the deeplink handler on the first load
 * of the app if the app was launched by a deeplink.
 *
 * The deeplink handler then redirects the user to the
 * appropriate screen.
 *
 */
const useDeeplinkBackgroundApp = () => {
  const fetchDeeplink = useCallback(async () => {
    /**
     * If the app launch was triggered by an app link with, it will
     * give the link url, otherwise it will return null
     */
    const url = await Linking.getInitialURL();
    if (url) {
      // Run operations on received deeplink url
      await deeplinkHandler(url);
    }
  }, []);

  useEffect(() => {
    fetchDeeplink();
  }, [fetchDeeplink]);
};

/**
 * ! Caution: This hook should be called only once.
 * ! Caution: This hook should be called after the navigation container is mounted.
 * ! Caution: This hook should be called after the state is fresh & ready to handle the deeplink.
 *
 * If the app is already open, it is brought to the foreground.
 * The hook inits the deeplink listener on the first load.
 *
 * The deeplink listener then redirects the user to the appropriate screen
 * through the deeplink handler.
 */
const useDeeplinkForegroundApp = () => {
  useEffect(() => {
    // Add event listener to handle deeplinks
    const subscription = Linking.addEventListener('url', ({ url }) => {
      // Run operations on received deeplink url
      deeplinkHandler(url, false);
    });

    // On unmount, remove the event listener
    return () => {
      subscription.remove();
    };
  }, []);
};

/**
 * ! Caution: This hook should be called only once.
 *
 * This hook should be called after the navigation container is mounted.
 * This hook should be called after the state is fresh & ready to handle the deeplink.
 *
 * This hook sets up the deeplink handler for the app.
 */
const useSetupDeeplink = () => {
  useDeeplinkBackgroundApp();
  useDeeplinkForegroundApp();
};

const DeeplinkService = {
  useDeeplinkBackgroundApp,
  useDeeplinkForegroundApp,
  useSetupDeeplink,
};

export default DeeplinkService;
