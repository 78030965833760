import { useContext } from 'react';

import { ThemeContext } from '../context/ThemeContext';

export const useTheme = () => {
  const value = useContext(ThemeContext);

  if (process.env.NODE_ENV !== 'production') {
    if (!value) {
      throw new Error('useTheme must be wrapped in a <ThemeProvider />');
    }
  }

  return value;
};
