import parseMobile, {
  type CountryCallingCode,
  type PhoneNumber,
} from 'libphonenumber-js/mobile';

import type { Contact } from '../state/useContactStore';

import { sortContactNumbers } from './sortContactNumbers';

/**
 * Gets a valid mobile number for a contact formatted with international calling code
 * If there are multiple numbers, it will return the first valid mobile number
 * prioritised by the following order:
 * 1. Mobile number with international calling code
 * 2. Mobile number without international calling code
 * 3. Any other number with international calling code that validates as a mobile number
 *
 * @param contact {Contact}
 * @param defaultCallingCode {string}
 * @returns {E164Number} formatted mobile number
 */
export const getMobileNumberForContact = (
  contact: Contact,
  defaultCallingCode?: CountryCallingCode,
) => {
  const phoneNumbers = contact.phoneNumbers
    .sort(sortContactNumbers)
    .reduce((acc, phoneNumber) => {
      const parsedNumber = parseMobile(phoneNumber.number, {
        defaultCallingCode,
      });

      if (parsedNumber?.isPossible()) {
        acc.push(parsedNumber);
      }
      return acc;
    }, [] as PhoneNumber[]);

  return phoneNumbers[0]?.number;
};
