import { useState } from 'react';
import { useSharedValue, withTiming } from 'react-native-reanimated';

export const useShowMembersList = () => {
  const backgroundOpacity = useSharedValue(1);
  // note: use a number/timestamp to force re-render when browsing back to chat view and
  // we need to reshow the members list... i.e. from the Room Settings screen
  const [showMembersList, setShowMembersList] = useState<number | null>();

  const toggleShowMembers = () => {
    setShowMembersList(showMembersList ? null : Date.now());
    backgroundOpacity.value = withTiming(backgroundOpacity.value === 0 ? 1 : 0);
  };

  return {
    backgroundOpacity,
    showMembersList,
    toggleShowMembers,
  };
};
