import type { GraphQLQuery } from 'aws-amplify/api';

import type { DeleteMessageV2Mutation, MessagesByUserIdV2Query } from '@/API';
import client from '@/services/API/client';
import { deleteMessageV2 } from '@/services/graphql/mutations';
import { messagesByUserIdV2 } from '@/services/graphql/queries';

type Props =
  | {
      id: string;
      _version: number;
    }
  | {
      userId: string;
      SK: string;
    };

export const deleteMessage = async (props: Props) => {
  let id: string;
  let _version: number;

  if ('id' in props && '_version' in props) {
    id = props.id;
    _version = props._version;
  } else {
    const response = await client.graphql<
      GraphQLQuery<MessagesByUserIdV2Query>
    >({
      query: messagesByUserIdV2,
      variables: {
        userId: props.userId,
        SK: { eq: props.SK },
      },
    });
    const message = response.data?.messagesByUserIdV2?.items?.[0];
    if (!message) {
      throw new Error('Message not found');
    }

    id = message.id;
    _version = message._version;
  }

  await client.graphql<GraphQLQuery<DeleteMessageV2Mutation>>({
    query: deleteMessageV2,
    variables: { input: { id, _version } },
  });
};
