/**
 * A palette of colors that the user can choose from
 * to customize the app. These colors are used in the
 * settings screen. The palette is expected not to
 * change often. These set of colors won't be enforced
 * in the app.
 */
enum UserThemes {
  choice1 = '#373737',
  choice10 = '#9e39e5',
  choice11 = '#e539d7',
  choice12 = '#e53981',
  choice2 = '#e12f1e',
  choice3 = '#cf841a',
  choice4 = '#66a015',
  choice5 = '#16ab23',
  choice6 = '#16a96c',
  choice7 = '#17a4b0',
  choice8 = '#3981e5',
  choice9 = '#4839e5',
}

export default UserThemes;
