import { CommonActions } from '@react-navigation/native';
import { useEffect } from 'react';

import { navigationRef } from '@/navigation/navigationRef';

const state = {
  loaded: false,
  destination: null as { screen: string; params?: any } | null,
};

type UseDelayedNavigation = (props: {
  isLoading: boolean;
  isProfileSetup: boolean;
}) => void;

// When load state changes, if user is loaded and profile is setup,
// navigate to the next screen in the queue
// ** ideally we would use deep links **
const useDelayedNavigation: UseDelayedNavigation = ({
  isLoading,
  isProfileSetup,
}) => {
  useEffect(() => {
    if (isLoading) return;
    if (!isProfileSetup) return;

    state.loaded = true;
    if (state.destination) {
      const { screen, params } = state.destination;
      state.destination = null;
      navigationRef.current?.dispatch(CommonActions.navigate(screen, params));
      return;
    }
  }, [isLoading, isProfileSetup]);
};

const navigateOnLoad = (screen: string, params?: any) => {
  state.destination = { screen, params };
  if (!state.loaded) return;
  navigationRef.current?.dispatch(CommonActions.navigate(screen, params)); // causes error when screen is not ready
};

export { navigateOnLoad, useDelayedNavigation };
