import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  type TextStyle,
  TouchableOpacity,
  type ViewStyle,
} from 'react-native';

import { primaryBlue } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const MainButton = ({
  disabled = false,
  loading = false,
  onPress,
  style,
  textStyle,
  title,
}: {
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
  style?: ViewStyle;
  textStyle?: TextStyle;
  title: string;
}) => {
  const disabledStyle = disabled ? { backgroundColor: Greys.shade400 } : {};
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      disabled={disabled}
      onPress={loading ? undefined : onPress}
      style={[styles.container, style, disabledStyle]}>
      {loading ? (
        <ActivityIndicator color="#fff" size="small" />
      ) : (
        <Text style={[styles.title, textStyle]}>{title}</Text>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: primaryBlue,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginTop: 20,
    width: web ? 200 : wp('80'),
  },
  title: { color: Greys.shade0 },
});

export default MainButton;
