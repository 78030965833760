import { Greys } from '../colors';
import type { Color } from '../types/color';

// allowed sizes
type Size = 'sm' | 'md' | 'lg';

// define the shadow interface
interface IShadow {
  elevation: number;
  shadowColor: Color;
  shadowOffset: {
    height: number;
    width: number;
  };
  shadowOpacity: number;
  shadowRadius: number;
}

// create a dictionary type of shadows
type ShadowDictionary = {
  [key in Size]: IShadow;
};

// function to create a shadow
const createShadow = (
  shadowOffsetHeight: number,
  shadowOpacity: number,
  shadowRadius: number,
): IShadow => ({
  elevation: shadowRadius,
  shadowColor: Greys.shade999,
  shadowOffset: { height: shadowOffsetHeight, width: 0 },
  shadowOpacity,
  shadowRadius,
});

// create the actual shadows values
const Shadows: ShadowDictionary = {
  lg: createShadow(6, 0.2, 12),
  md: createShadow(4, 0.2, 8),
  sm: createShadow(1, 0.1, 4),
};

export default Shadows;
