import { StyleSheet } from 'react-native';

import { blue2 } from '@/theme/colors';
import { hp, wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  addPhotoText: {
    color: Greys.shade200,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    paddingRight: 12,
    textAlign: 'center',
    width: wp(20),
  },
  avatar: {
    borderRadius: 60,
    height: (wp(100) - 60) / 6,
    position: 'absolute',
    width: (wp(100) - 60) / 6,
  },
  avatarContainer: {
    alignItems: 'center',
    backgroundColor: '#D9D9D9',
    borderRadius: 60,
    height: wp(30),
    justifyContent: 'center',
    marginHorizontal: 8,
    width: wp(30),
  },
  avatarControls: {
    flexDirection: 'row',
    width: wp(30),
  },
  avatarImage: {
    borderRadius: 60,
    height: wp(30),
    width: wp(30),
  },
  avatarOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 60,
    height: wp(30),
    justifyContent: 'center',
    position: 'absolute',
    width: wp(30),
  },
  avatarSelected: {
    borderRadius: 60,
    height: (wp(100) - 60) / 6.8,
    position: 'absolute',
    width: (wp(100) - 60) / 6.8,
  },
  avatarsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
    paddingHorizontal: 20,
    width: '100%',
  },
  backContainer: {
    alignItems: 'center',
    flex: 0.3,
    flexDirection: 'row',
  },
  backText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    marginLeft: 10,
  },
  birthdayButton: {
    alignSelf: 'stretch',
  },
  cancelText: {
    color: blue2,
    fontFamily: 'OpenSans-Regular',
    fontSize: web ? 16 : 14,
    fontWeight: '400',
  },
  chooseAvatarLabel: {
    color: Greys.shade200,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 25,
  },
  chooseImgBtn: {
    marginLeft: 8,
    marginRight: 8,
  },
  chooseNowButton: {
    alignItems: 'center',
    borderColor: '#00B9F7',
    borderRadius: 20,
    borderWidth: 2,
    height: wp(8),
    justifyContent: 'center',
    marginTop: wp(2),
    width: wp(30),
  },
  chooseNowButtonText: {
    color: '#00B9F7',
    fontWeight: '600',
  },
  coloredSpacer: {
    backgroundColor: Greys.shade200,
    height: 30,
  },
  container: {
    backgroundColor: Greys.shade0,
    flex: 1,
    paddingBottom: 0,
  },
  content: {
    alignItems: 'flex-start',
    flex: web ? undefined : 0.5,
  },
  contentPhoneNumber: {
    alignItems: 'flex-start',
    borderBottomWidth: 0.5,
    borderColor: Greys.shade200,
    flex: web ? undefined : 0.5,
    paddingLeft: 20,
  },
  contentRow: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: web ? undefined : 1,
    flexDirection: 'row',
    height: 30,
    paddingHorizontal: 20,
  },
  countryCode: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    marginRight: 5,
  },
  dateJoinedText: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
  },
  fullNameInput: {
    backgroundColor: Greys.shade0,
    paddingHorizontal: 20,
    width: wp(100),
  },
  goBack: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  header: {
    alignItems: 'center',
    backgroundColor: Greys.shade600,
    height: hp(32),
  },
  headerContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  headerInside: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-between',
    padding: web ? 10 : 0,
    width: web ? '100%' : wp(100) - 40,
  },
  headerTextContainer: {
    alignItems: 'flex-end',
    width: wp(30),
  },
  headerTop: {
    padding: 10,
  },
  joinDate: {
    color: '#707070',
    fontSize: 14,
    marginLeft: 10,
  },
  localAvatarSelectionContainer: {
    backgroundColor: Greys.shade800,
    paddingVertical: 20,
  },
  localAvatarSelectionSubtitle: {
    color: Greys.shade200,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
  },
  localAvatarSelectionTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginBottom: 10,
    textAlign: 'center',
  },
  lockIcon: {
    marginRight: 5,
    marginTop: 3,
  },
  phoneNumber: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
  phoneNumberLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
    marginBottom: 12,
  },
  row: {
    flexDirection: 'row',
  },
  saveText: {
    color: blue2,
    fontFamily: 'OpenSans-Bold',
    fontSize: web ? 16 : 14,
    fontWeight: '700',
  },
  scrollContainer: {
    paddingBottom: 0,
  },
  settingsContainer: {
    backgroundColor: Greys.shade200,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  settingsHeader: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
  settingsSubheader: {
    color: Greys.shade500,
    fontSize: 11,
    fontWeight: '400',
    marginTop: 5,
  },
  setupText: {
    color: Greys.shade0,
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 28,
    marginBottom: wp(4),
    marginTop: wp(3),
  },
  sheetAvatarsRow: {
    alignItems: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  sheetScrollWrapper: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
  smallHeader: {
    backgroundColor: Greys.shade600,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  spacer: {
    height: 15,
  },
  spacer10: {
    height: 10,
  },
  switchRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    paddingHorizontal: 20,
  },
  switchTitle: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
});
