import moment from 'moment';
import React from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';

import { ChatUnitType } from '../../../../../API';
import type { Details } from '../types';

import type { ChatUnit } from '@/services/types';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

interface ChatUnitDetailsProps {
  currentChatUnit: ChatUnit;
  ownerName: string;
  setCurrentChatUnit: (input: Details) => void;
}

export const ChatUnitDetails = ({
  currentChatUnit,
  ownerName,
  setCurrentChatUnit,
}: ChatUnitDetailsProps) => {
  const { translate } = useTranslations();
  const type = currentChatUnit.type ?? ChatUnitType.TABLE;

  return (
    <View style={styles.content}>
      <View style={styles.containerWithPadding}>
        <Text style={styles.tableNameLabel}>
          {translate('table_name_label', {
            capitalized_type: translate(type),
          })}
        </Text>
        <TextInput
          onChangeText={e => setCurrentChatUnit({ title: e })}
          style={[styles.tableTitle]}
          value={currentChatUnit.title ?? ''}
          maxLength={20}
        />
      </View>
      <View style={styles.divider} />
      <View style={styles.descriptionContainer}>
        <Text style={styles.tableDescriptionLabel}>
          {translate('table_description_label', {
            capitalized_type: translate(type),
          })}
        </Text>
        <TextInput
          onChangeText={e => setCurrentChatUnit({ description: e })}
          placeholder={translate('add_table_description_label')}
          style={styles.descriptionInput}
          value={currentChatUnit.description ?? ''}
        />
      </View>
      <View style={styles.divider} />
      <View style={styles.detailsRow}>
        <Text style={styles.createdByLabel}>
          {translate('table_created_by_label')}
        </Text>
        <Text style={styles.detailsValue}>{ownerName}</Text>
      </View>
      <View style={styles.detailsRowWithMargin}>
        <Text style={styles.createdByLabel}>
          {translate('table_created_on_label')}
        </Text>
        <Text style={styles.detailsValue}>
          {moment(currentChatUnit?.createdAt).format('DD MMM YYYY')}
        </Text>
      </View>
      <View style={styles.spacer10} />
    </View>
  );
};

const styles = StyleSheet.create({
  containerWithPadding: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  content: {
    backgroundColor: Greys.shade0,
    flex: 1,
  },
  createdByLabel: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  descriptionContainer: {
    backgroundColor: Greys.shade0,
    marginBottom: 10,
    marginTop: 10,
    paddingHorizontal: 20,
  },
  descriptionInput: {
    marginTop: 0,
  },
  descriptionInputNoValue: {
    color: Greys.shade400,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
  descriptionInputWithValue: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
  },
  detailsRow: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    marginTop: 10,
    paddingHorizontal: 20,
  },
  detailsRowWithMargin: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    marginTop: 8,
    paddingHorizontal: 20,
  },
  detailsValue: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    marginLeft: 10,
  },
  divider: {
    backgroundColor: Greys.shade200,
    height: 1,
    width: wp(100),
  },
  spacer10: {
    height: 10,
  },
  tableDescriptionLabel: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  tableNameLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
  },
  tableTitle: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 21,
    fontWeight: '600',
  },
});
