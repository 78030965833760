import { DataStore, syncExpression } from 'aws-amplify/datastore';

import { User } from '../../../models';

import { handleDatastoreError } from './handleDatastoreError';

import AuthService from '@/domain/auth/AuthService';
import { Activity, MessageV2 } from '@/models';
import { Message } from '@/models';

export const dataStoreConfigure = () => {
  DataStore.configure({
    maxRecordsToSync: 2000,
    syncPageSize: 3000,
    fullSyncInterval: 60 * 168,
    syncExpressions: [
      syncExpression(User, async () => {
        const cognitoUser = await AuthService.getCognitoUser();
        if (!cognitoUser) {
          return user => user.id.eq('');
        } // return empty expression
        return user => user.id.eq(cognitoUser?.username);
      }),
      syncExpression(Activity, async () => {
        // Only using relation field since it maps to a GSI
        // TODO Remove this when we remove the Activity model
        return activity => activity.relation.eq('');
      }),
      syncExpression(Message, async () => {
        // Only using conversationId field since it maps to a GSI
        // TODO Remove this when we remove the Message model
        return message => message.conversationId.eq('');
      }),
      syncExpression(MessageV2, async () => {
        const cognitoUser = await AuthService.getCognitoUser();
        if (!cognitoUser) {
          return message => message.userId.eq('');
        } // return empty expression
        return message =>
          message.and(m => [
            m.userId.eq(cognitoUser?.username),
            m.SK.gt(`${cognitoUser?.username}#${new Date().toISOString()}`),
          ]);
      }),
    ],
    errorHandler: error => {
      handleDatastoreError(error);
    },
  });
};
