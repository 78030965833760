import { DataStore } from 'aws-amplify/datastore';

import { User } from '../../../models';

import type { User as IUser } from '@/services/chatUnit/types';
import { factory } from '@/services/factory/factory';

type AllowedInput = Partial<
  Omit<
    IUser,
    'id' | 'phoneNumber' | 'createdAt' | 'updatedAt' | 'owner' | 'organisations'
  >
>;
type userInput = AllowedInput & Pick<IUser, 'id'>;
const restrictedKeys = [
  'id',
  'phoneNumber',
  'createdAt',
  'updatedAt',
  'owner',
  'organisations',
];

export const updateUser = async (user: userInput) => {
  const existingUser = await DataStore.query(User, user.id);

  if (!existingUser) {
    throw new Error(`User with id ${user.id} not found`);
  }

  const result = await DataStore.save(
    User.copyOf(existingUser, updated => {
      for (const key in user) {
        if (!restrictedKeys.includes(key)) {
          updated[key as keyof AllowedInput] = user[key as keyof userInput];
        }
      }
    }),
  );

  return factory.user(result);
};
