import CancelImg from '@/assets/chatview/cancel.svg';
import Triangle from '@/assets/video/triangle.svg';
import Avatar from '@/components/avatar';
import { Colors } from '@/domain/theme/Colors';
import { getName } from '@/domain/user/functions/getName';
import type { ChatUnitUser } from '@/services/types';
import { AppText } from '@/ui/app/elements';
import { Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import ImageKeyHelpers from '@/utilities/helpers/imageKeyHelpers';
import { Image } from 'expo-image';
import type React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import type { CustomMsg } from './types';

interface ReplyPreviewProps {
  message: CustomMsg;
  chatUnitUser: ChatUnitUser | undefined;
  onCancel: () => void;
  isMe?: boolean;
  themeColor: string;
}

const ReplyPreview: React.FC<ReplyPreviewProps> = ({
  message,
  chatUnitUser,
  onCancel,
  isMe,
  themeColor,
}) => {
  const isImage = ImageKeyHelpers.isImageKeyImage(message.image);
  const isVideo = ImageKeyHelpers.isImageKeyVideo(message.image);

  const renderMediaPreview = () => {
    if (isImage) {
      return (
        <Image source={{ uri: message.image }} style={styles.mediaPreview} />
      );
    } else if (isVideo) {
      return (
        <View style={styles.videoPreview}>
          <Image source={{ uri: message.image }} style={styles.mediaPreview} />
          <View style={styles.videoOverlay}>
            <Triangle height={24} width={24} />
          </View>
        </View>
      );
    }
    return null;
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.header}>
          <View style={styles.avatarContainer}>
            <Avatar
              size={18}
              imageKey={chatUnitUser?.avatar}
              displayName={chatUnitUser ? getName(chatUnitUser, 'short') : ''}
            />
          </View>
          <AppText
            size={14}
            type={'primary700'}
            style={[styles.username, isMe && { color: themeColor }]}>
            {isMe ? 'You' : chatUnitUser ? getName(chatUnitUser, 'short') : ''}
          </AppText>
        </View>
        <View style={styles.messageContent}>
          {renderMediaPreview()}
          <View style={styles.textContainer}>
            {message.text && (
              <AppText size={14} color={Neutrals.shade700} numberOfLines={3}>
                {message.text}
              </AppText>
            )}
          </View>
        </View>
      </View>
      <Pressable onPress={onCancel} style={styles.cancelButton}>
        <CancelImg height={30} width={30} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 10,
    borderRadius: BorderRadius.md,
    borderWidth: 1,
    borderColor: Neutrals.shade200,
    backgroundColor: Colors.neutral0,
    marginBottom: 5,
  },
  content: {
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  avatarContainer: {
    marginRight: 5,
  },
  username: {
    fontWeight: 'bold',
  },
  messageContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mediaPreview: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: 10,
  },
  videoPreview: {
    position: 'relative',
  },
  videoOverlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  textContainer: {
    flex: 1,
  },
  cancelButton: {
    padding: 5,
  },
});

export default ReplyPreview;
