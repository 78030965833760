import type React from 'react';
import { View } from 'react-native';
import { type SharedValue, useAnimatedStyle } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AnimatedBlurView } from './components/AnimatedBlurView';
import { AnimatedLinearGradient } from './components/AnimatedLinearGradient';
import { getStyles } from './styles';

import { blackAlpha0, blackAlpha30 } from '@/theme/colors';

type Props = {
  children?: React.ReactNode;
  opacity?: SharedValue<number>;
  showBottomBlur?: boolean;
  showBottomShadow?: boolean;
  showTopBlur?: boolean;
  showTopShadow?: boolean;
  topShadowOffset?: number;
};

/**
 * A container that animates the opacity of top & bottom shadow gradients or blur components.
 * Show top gradient if white content is scrolled under a white header.
 * Show bottom gradient if white content is scrolling up under the white tab bar in footer.
 * Show bottom blur component on iOS if page is full screen to the bottom (i.e. no tab bar)
 * Should be used with useAnimdatedScrollView() hook for hooking into scroll events.
 *
 * @param children {React.ReactNode}
 * @param opacity {SharedValue<number>} animates the opacity of the top & bottom shadow gradients
 * @param showTopShadow {boolean} show the top shadow gradient
 * @param showTopBlur {boolean} show the top blur gradient
 * @param showBottomShadow {boolean} show the bottom shadow gradient
 * @param showBottomBlur {boolean} show the bottom blur gradient
 * @param topShadowOffset {number} offset the top shadow gradient (e.g. for sticky headers)
 *
 * @return {JSX.Element}
 */
export const AnimatedShadowContainer = ({
  children,
  opacity,
  showBottomBlur = false,
  showBottomShadow = false,
  showTopBlur = false,
  showTopShadow = false,
  topShadowOffset,
}: Props) => {
  const { bottom, top } = useSafeAreaInsets();
  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity?.value ?? 0,
    };
  });
  const styles = getStyles({
    safeBottomHeight: bottom,
    safeTopHeight: top,
    topShadowOffset,
  });

  return (
    <View style={styles.wrapper}>
      <AnimatedLinearGradient
        colors={[blackAlpha30, blackAlpha0]}
        hidden={!showTopShadow || showTopBlur}
        style={[styles.topShadow, animatedStyle]}
      />
      <AnimatedBlurView hidden={!showTopBlur} style={styles.topBlur} />
      {children}
      <AnimatedLinearGradient
        colors={[blackAlpha0, blackAlpha30]}
        hidden={!showBottomShadow || showBottomBlur}
        style={[styles.bottomShadow, animatedStyle]}
      />
      <AnimatedBlurView hidden={!showBottomBlur} style={styles.bottomBlur} />
    </View>
  );
};
