import { FlashList } from '@shopify/flash-list';
import { isEqual } from 'lodash';
import React, { useMemo, useState, useCallback } from 'react';
import { type LayoutChangeEvent, StyleSheet } from 'react-native';

import { ChatUnitType } from '../../../../API';

import { constants } from '@/constants';
import ChatUnitCard from '@/domain/chatUnit/components/chatUnitCard/ChatUnitCard';
import { CreateRoomCard } from '@/domain/chatUnit/components/chatUnitCard/Rooms/CreateRoomCard';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { useTableStore } from '@/domain/table/state/useTableStore';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatUnit } from '@/services/types';
import { Spacer } from '@/ui/app';
import { Neutrals } from '@/ui/common/colors';
import { web } from '@/utilities/platform';
import { ScreenSizes } from '@/utilities/useWindowDimensions/useWindowDimensions';

type Props = {
  type: ChatUnitType;
};

//TODO maybe move these to theme
const columnSizes = {
  [ScreenSizes.xl]: { columns: 6, minWidth: 1075 },
  [ScreenSizes.l]: { columns: 5, minWidth: 875 },
  [ScreenSizes.md]: { columns: 4, minWidth: 675 },
  [ScreenSizes.sm]: { columns: 3, minWidth: 475 },
  [ScreenSizes.xs]: { columns: 2, minWidth: 275 },
  [ScreenSizes.xxs]: { columns: 1, minWidth: 0 },
};

const Seperator = () => <Spacer height={10} />;
const keyExtractor = (item: ChatUnit) => item.id;

// Wrapping the component with React.memo to prevent unnecessary re-renders
export const ChatGrid = React.memo(({ type }: Props) => {
  const ownerId = useUserStore(state => state.user?.owner);
  const chatUnits = useTableStore(state => state.getActiveChatUnits(type));

  const organisations = useOrganisations();
  const totalChatUnitLimit =
    type === ChatUnitType.ROOM ? constants.roomLimit : constants.tableLimit;

  // Memoize item IDs
  const itemIds = useMemo(() => chatUnits.map(item => item.id), [chatUnits]);

  const latestMessages = useMessageStore(
    state => state.getMostRecentMessagesFromChatUnits(itemIds),
    isEqual,
  );

  const myTotalChatUnits = useMemo(() => {
    return chatUnits.filter(item => item.owner === ownerId).length;
  }, [chatUnits, ownerId]);
  const canMakeNewChatUnit = myTotalChatUnits < totalChatUnitLimit;
  const [webColumns, setWebColumns] = useState(5);

  // Extract and memoize sort function
  const sortFunction = useCallback(
    (a: ChatUnit, b: ChatUnit) => {
      const aMessage = latestMessages[a.id];
      const bMessage = latestMessages[b.id];
      const aDate = aMessage?.createdAt ?? a.createdAt;
      const bDate = bMessage?.createdAt ?? b.createdAt;

      if (!aDate) return 1;
      if (!bDate) return -1;
      // Making each a date first since the createdAt in the chatUnit of type string.
      return new Date(bDate) < new Date(aDate) ? -1 : 1;
    },
    [latestMessages],
  );

  // Memoize sorted DMs
  const sortedChatUnits = useMemo(
    () => [...chatUnits].sort(sortFunction),
    [chatUnits, sortFunction],
  );

  //Getting org name for each chat unit
  const formattedChatUnits = useMemo(
    () =>
      sortedChatUnits.map(item => ({
        ...item,
        organisationName: organisations.find(
          org => org.id === item.organisationId,
        )?.name,
      })),
    [organisations, sortedChatUnits],
  );

  const data = useMemo(() => {
    const extraColumns = web
      ? webColumns - ((formattedChatUnits.length + 1) % webColumns)
      : 0;
    return [
      { id: 'create', type } as ChatUnit,
      ...formattedChatUnits,
      ...new Array(extraColumns).fill({ id: 'blank', type }),
    ];
  }, [formattedChatUnits, type, webColumns]);

  // Memoizing onLayoutWidthChange to prevent recreation on each render
  const onLayoutWidthChange = useCallback((params: LayoutChangeEvent) => {
    if (web) {
      const sizes = Object.values(columnSizes);

      sizes.some(size => {
        if (params?.nativeEvent?.layout?.width > size.minWidth) {
          setWebColumns(size.columns);
          return true; // Return true to terminate the loop once the condition is met
        }
        return false; // Added to ensure proper termination
      });
    }
  }, []); // function doesnt need to change between renders

  const renderItem = useCallback(
    ({ item }: { item: ChatUnit & { organisationName?: string | null } }) => {
      if (item.id === 'create') {
        return <CreateRoomCard hidden={!canMakeNewChatUnit} type={type} />;
      }
      if (item.id === 'blank') {
        return null;
      }
      return <ChatUnitCard key={item.id} {...item} />;
    },
    [canMakeNewChatUnit, type],
  );

  return (
    <FlashList
      key={webColumns}
      onLayout={onLayoutWidthChange}
      data={data}
      numColumns={web ? webColumns : 2}
      estimatedItemSize={257}
      drawDistance={400}
      keyExtractor={keyExtractor}
      contentContainerStyle={styles.container}
      ItemSeparatorComponent={Seperator}
      nestedScrollEnabled={false}
      scrollEnabled={false}
      renderItem={renderItem}
    />
  );
});

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: Neutrals.shade100,
  },
  placeholder: { flex: 1, margin: 5 },
});
