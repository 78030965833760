import signInImg from '@/assets/onboarding/signInBg.png';
import LogoDark from '@/assets/svg/logo-dark.svg';
import Section from '@/components/section/Section';
import Breakpoints from '@/domain/theme/Breakpoints';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import {
  Animated,
  type ImageStyle,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useMMKVString } from 'react-native-mmkv';
import OnboardingLinksFooter from './components/OnboardingLinksFooter';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingORTextSeperator from './components/OnboardingORTextSeperator';
import OnboardingSecondaryDarkButton from './components/OnboardingSecondaryDarkButton';

const GRADIENT_BOTTOM = [`${Colors.ocean}00`, Colors.ocean];
const GRADIENT_TOP = [Colors.ocean, `${Colors.ocean}00`];

const linearGradientStartPosition = { x: 0.5, y: 0 };
const linearGradientEndPosition = { x: 0.5, y: 1 };

export default function OnboardingStartScreenWeb() {
  const { width, height } = useWindowDimensions();
  const rotateAnim = useRef(new Animated.Value(0.0833)).current; // 30deg / 360deg = 0.0833

  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['30deg', '390deg'], // 30deg to 360deg + 30deg
  });

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 35000,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim, {
          toValue: 0,
          duration: 35000,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [rotateAnim]);

  return (
    <Section f1 jcc aic bg={Colors.ocean} style={styles.screen}>
      <WebLayout />
      <Animated.Image
        source={signInImg}
        resizeMode="contain"
        style={{
          transform: [{ rotate: rotate }],
          ...(styles.bg as ImageStyle),
          width: width * 1.1, // 10% larger than the screen width
          height: height * 1.1, // 10% larger than the screen height
          top: -(height * 0.05), // Move it up by half of the extra height
          left: -(width * 0.1), // Shift it a bit towards the left just to make it look good
        }}
      />
      <LinearGradient
        colors={GRADIENT_TOP}
        style={styles.backgroundGradientTop}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
      <LinearGradient
        colors={GRADIENT_BOTTOM}
        style={styles.backgroundGradientBottom}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
    </Section>
  );
}

function WebLayout() {
  const { translate } = useTranslations();
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const [_, setOnboardingType] = useMMKVString('onboardingType');

  return (
    <>
      <Section
        row={width > Breakpoints.md}
        f1
        aic
        jcsb={width > Breakpoints.md}
        jcc={width <= Breakpoints.md}
        gap={40}
        w100
        maxW={web ? 960 : 0}
        ph={web ? 0 : 120}>
        <Section aic jcc>
          <LogoDark />
          <Spacer height={28} />
          <AppText
            color={Colors.neutral0}
            size={38}
            type="secondary900"
            textAlign="center"
            style={styles.heading}>
            {translate('get_more_stuff_done_with_your_team')}
          </AppText>
        </Section>
        <Section aic jcc>
          <Section br={36} p={40} bg={Colors.neutral0} aic>
            <AppText size={21} type="primary700">
              Welcome to 8seats!
            </AppText>
            <Spacer width={320} height={40} />
            <OnboardingNextButton
              text="Sign in to my account"
              onPress={() => navigation.navigate('OnboardingSignIn')}
            />
            <Spacer height={20} />
            <OnboardingORTextSeperator />
            <Spacer height={20} />
            <OnboardingSecondaryDarkButton
              text="Create a new account"
              onPress={() => navigation.navigate('OnboardingStepZero')}
            />
            <Spacer height={40} />
            <Section
              jcsb
              aic
              onPress={() => {
                setOnboardingType('businessInvitee');
                navigation.navigate('OnboardingStepOne', {
                  onboardingType: 'businessInvitee',
                  stepNumber: 1,
                  totalSteps: 3,
                });
              }}>
              <AppText size={17} type="primary700" color={Colors.primaryLight}>
                I've been invited to join a team
              </AppText>
            </Section>
          </Section>
        </Section>
      </Section>
      <Section w100 ph={40} pb={30}>
        <OnboardingLinksFooter color={Colors.neutral0} />
      </Section>
    </>
  );
}

const styles = StyleSheet.create({
  webScreenView: {
    padding: 40,
    alignSelf: 'center',
    backgroundColor: Colors.neutral0,
    borderRadius: 36,
    // @ts-ignore: Web only styles
    maxWidth: 'calc(100% - 40px)',
    zIndex: 2,
    position: 'absolute',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.4,
    shadowRadius: 12,
  },

  screen: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  backgroundGradientBottom: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: '50%',
    width: '100%',
  },
  backgroundGradientTop: {
    top: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: '50%',
    width: '100%',
  },
  back: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: Colors.neutral0,
    position: 'absolute',
    zIndex: 2,
    top: 30,
    left: 30,
    borderRadius: 18,
  },
  heading: {
    maxWidth: 400,
  },
  bg: {
    ...StyleSheet.absoluteFillObject,
    position: 'absolute',
    zIndex: -4,
  },
});
