import { StyleSheet } from 'react-native';

import { red } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';

export const styles = StyleSheet.create({
  activitiesDot: {
    alignItems: 'center',
    backgroundColor: red,
    borderColor: Greys.shade0,
    borderRadius: 9,
    borderWidth: 2,
    height: 18,
    position: 'absolute',
    right: wp(2),
    top: -8,
    width: 18,
  },
  activitiesText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 10,
    fontWeight: '700',
    marginLeft: 0.5,
    marginTop: -0.5,
  },
  dot: {
    backgroundColor: red,
    borderColor: Greys.shade0,
    borderRadius: 40,
    borderWidth: 2,
    height: 12,
    position: 'absolute',
    width: 12,
  },
  icon: {
    alignItems: 'center',
    flex: 1,
  },
  oneOnOneDot: {
    right: 15,
    top: -5,
  },
  roomsDot: {
    right: 15,
    top: -2,
  },
  tabLabel: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 11,
    fontWeight: '600',
    marginTop: 2,
  },
  tabRow: {
    backgroundColor: Greys.shade0,
    borderTopColor: '#E5E5E5',
    borderTopWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  tablesDot: {
    right: 18,
    top: -3,
  },
  switchComponent: { flexDirection: 'row', height: '100%' },
  splitViewPressable: { flex: 1, overflow: 'hidden', borderRadius: 10 },
  // switchComponent: { flexDirection: 'row', height: '100%' },
  addSplitButton: { marginBottom: 10 },
  splitContainerButtons: { alignItems: 'center', flexDirection: 'row' },
  container: {
    flex: 1,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    /* @ts-ignore: style properties do exist when it is compiled for web */
    height: '100vh',
  },
  fullView: {
    display: 'flex',
    /* @ts-ignore: style properties do exist when it is compiled for web */
    height: '92vh',
    width: '98%',
    marginLeft: 0,
    marginTop: 50,
    marginBottom: 0,
  },
  webCompletedProfileStack: {
    width: 90,
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    /* @ts-ignore: style properties do exist when it is compiled for web */
    marginBottom: '30px',
  },
  mainWebWindowDrawerStyle: {
    width: 360,
    backgroundColor: 'rgb(242, 242, 242);',
    marginRight: 10,
    marginBottom: 30,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    /* @ts-ignore: style properties do exist when it is compiled for web */
    padding: '3px',
    overflow: 'hidden',
  },
  splitViewItemContainerInactive: {
    flex: 1,
    /* @ts-ignore: style properties do exist when it is compiled for web */
    transition: 'margin 0.15s, opacity 0.15s, border 0.1s',
    borderRadius: 10,
    opacity: 0.92,
    margin: 2,
    marginRight: 10,
    marginBottom: 30,
  },
  splitViewItemContainerActive: {
    flex: 1,
    /* @ts-ignore: style properties do exist when it is compiled for web */
    transition: 'margin 0.15s, opacity 0.15s, border 0.1s',
    borderRadius: 10,
    'border-top': '4px #7FB8A7 solid',
    marginRight: 10,
    marginBottom: 30,
  },
  splitViewCloseButtonPressableActive: {
    position: 'absolute',
    /* @ts-ignore: style properties do exist when it is compiled for web */
    bottom: '-35px',
    left: '48%',
    transition: 'opacity 0.3s',
    opacity: 100,
  },
  splitViewCloseButtonPressableInactive: {
    position: 'absolute',
    /* @ts-ignore: style properties do exist when it is compiled for web */
    bottom: '-35px',
    left: '48%',
    transition: 'opacity 0.3s',
    opacity: 0,
  },
  splitViewSceneContainer: {
    backgroundColor: 'transparent',
    height: '100%',
  },
});
