export const whiteBackground = 'rgba(255,255,255,0.5)';
export const primaryBlue = '#1C75BC';
export const ligthBlue = '#01b9f8';
export const ligthBlue2 = '#00B9B9';
export const primaryRed = '#FF5050';
export const turquoise = '#18d4d4';
export const brandTeal = '#07818C';
export const brandTealHighlight = '#17D3E5';
export const purple = '#9B51E0';
export const blue2 = '#00B9F7';
export const blue3 = '#2D9CDB';
export const blue4 = '#142B53';
export const blue5 = '#2B8B98';
export const blue6 = '#1C75BC';
export const red = '#FF5050';
export const red2 = '#EB0000';
export const green = '#219653';
export const gray5 = '#F6F6F6';
export const deepNavy = '#0A314A';
export const peppermint = '#008542';
export const gray6 = '#F5F5F5';
export const blackAlpha0 = '#00000000';
export const blackAlpha20 = '#00000020';
export const blackAlpha30 = '#00000030';
