import { chatUnitFactory } from './chatUnitFactory';
import { chatUnitUserFactory } from './chatUnitUserFactory';
import { conversationFactory } from './conversationFactory';
import { inviteFactory } from './inviteFactory';
import { messageFactory } from './messageFactory';
import { userFactory } from './userFactory';

import type { Invite } from '@/domain/invites/types/types';
import type { Message, User } from '@/services/chatUnit/types';
import type { ChatUnit, ChatUnitUser, Conversation } from '@/services/types';

type Factory = {
  conversation: (input: any) => Conversation;
  invite: (input: any) => Invite;
  message: (input: any) => Message;
  // Note: for now we're strictly typing this to the TableUserModel because of the mixup of tableUser.id holding the value
  // of tableUser.userId and don't want to assume an `any` object passed in will follow the same pattern for the id field
  chatUnit: (input: any) => ChatUnit;
  chatUnitUser: (input: any) => ChatUnitUser;
  user: (input: any) => User;
};

export const factory: Factory = {
  conversation: conversationFactory,
  invite: inviteFactory,
  message: messageFactory,
  chatUnit: chatUnitFactory,
  chatUnitUser: chatUnitUserFactory,
  user: userFactory,
};
