import Section from '@/components/section/Section';
import type { MainStackScreenProps } from '@/navigation/types';
import { AppText, Spacer } from '@/ui/app';
import React, { useCallback, useState } from 'react';
import { Colors } from '../theme/Colors';

import QuestionIcon from '@/assets/svg/get_help.svg';
import SecureIcon from '@/assets/svg/secure_icon.svg';
import { constants } from '@/constants';
import { postHogTrack } from '@/context/Experience';
import useTimer from '@/hooks/useTimer';
import { User } from '@/models';
import useTranslations from '@/translations/useTranslation';
import { web } from '@/utilities/platform';
import { DataStore } from 'aws-amplify/datastore';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Linking, Pressable, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import { useMMKVString } from 'react-native-mmkv';
import AuthService from '../auth/AuthService';
import useUserStore from '../user/state/useUserStore';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingOTPCells from './components/OnboardingOTPCells';
import OnboardingWebLayout from './components/OnboardingWebLayout';

type Props = MainStackScreenProps<'OnboardingStepOneOTP'>;

function OnboardingStepOneOTPScreen({ route }: Props) {
  const { translate } = useTranslations();
  const { phoneNumber } = route.params;
  const [otp, setOtp] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const isInvalid = otp.length < constants.otpCount;
  const [onboardingType, setOnboardingType] = useMMKVString('onboardingType');
  const handleOTPChange = useCallback((text: string) => {
    setOtp(text);
    // Since we are clearing the error message on every change, we can clear the error message here
    // This is to prevent the error message from showing up when the user starts typing
    setError('');
  }, []);

  const {
    currentTime,
    isCompleted: timerComplete,
    startTimer,
  } = useTimer({
    initialMilliseconds: constants.resendSMSCodeDuration,
  });

  const handleSubmit = useCallback(async () => {
    setError('');
    setSubmitting(true);
    try {
      const cognitoUser = await AuthService.sendCustomChallengeAnswer(otp);

      if (cognitoUser?.userId) {
        useUserStore.getState().setUser({
          id: cognitoUser?.userId ?? '',
          phoneNumber: cognitoUser?.signInDetails?.loginId ?? '',
        });

        const [user] = await DataStore.query(User); // Query user modal
        //If user is already signed up, then we don't need to show the onboarding screen
        //Unless the user is being invited to a business.
        if (user && onboardingType !== 'businessInvitee') {
          setOnboardingType(undefined);
        }
      } else {
        setError(translate('otp_error'));
      }

      postHogTrack('Login', { userId: cognitoUser?.userId ?? '' });
    } catch (err) {
      setError(translate('otp_error'));
    } finally {
      setSubmitting(false);
    }
  }, [otp, translate]);

  const handleResendOTPPress = useCallback(async () => {
    setError('');
    try {
      startTimer();
      await AuthService.signIn(phoneNumber);
    } catch (err) {
      setError(translate('unknown_error'));
    } finally {
    }
  }, [phoneNumber, startTimer, translate]);

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <SecureIcon height={web ? 72 : 48} width={web ? 72 : 48} />
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate('secure_label')}
        </AppText>
        <Spacer height={30} />
        <AppText size={17} color={Colors.neutral70}>
          {translate('secure_body_part_one')}
          {web ? ' ' : '\n'}
          <AppText type="primary700">
            {parsePhoneNumber(phoneNumber).formatInternational()}
          </AppText>{' '}
          {translate('secure_body_part_two')}
        </AppText>
        <Spacer height={40} />
        <AppText size={17} type="primary700">
          {translate('sms_enter_code_text')}
        </AppText>
        <Spacer height={16} />
        <OnboardingOTPCells
          otp={otp}
          setOtp={handleOTPChange}
          onSubmit={!isInvalid ? handleSubmit : undefined}
          error={error}
        />
        {error ? (
          <>
            <Spacer height={10} />
            <AppText size={web ? 14 : 12} color={Colors.rasberry}>
              {error}
            </AppText>
          </>
        ) : null}
        <Spacer height={40} />
        <OnboardingNextButton
          onPress={handleSubmit}
          text={translate('common_submit_text')}
          disabled={isInvalid}
          loading={submitting}
        />
        <Spacer height={30} />
        <Section row={web} aic={web} jcsb={web}>
          {timerComplete ? (
            <Pressable onPress={handleResendOTPPress}>
              <AppText size={14} type="primary700" color={Colors.primaryLight}>
                {translate('resend_code_text')}
              </AppText>
            </Pressable>
          ) : (
            <AppText size={14} type="primary700" color={Colors.neutral70}>
              {translate('sms_resend_timer', { time: currentTime / 1000 })}
            </AppText>
          )}
          <Spacer height={30} />
          <Section
            row
            aic
            gap={4}
            onPress={() => Linking.openURL('https://support.8seats.com')}>
            <QuestionIcon />
            <AppText size={14} color={Colors.primaryLight} type="primary700">
              {translate('get_help_text')}
            </AppText>
          </Section>
        </Section>
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    padding: 30,
    flexGrow: 1,
  },
});

const OnboardingStepOneOTPScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout showBackButton>
      <OnboardingStepOneOTPScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web ? OnboardingStepOneOTPScreenWeb : OnboardingStepOneOTPScreen;
