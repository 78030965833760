import parseMobile from 'libphonenumber-js/mobile';
import { useShallow } from 'zustand/react/shallow';

import useUserStore from '../state/useUserStore';

export const useDefaultCallingCode = () => {
  const phoneNumber = useUserStore(
    useShallow(state => state.user?.phoneNumber),
  );

  if (!phoneNumber) {
    return undefined;
  }

  const parsedNumber = parseMobile(phoneNumber);
  return parsedNumber?.countryCallingCode;
};
