import React, { type FC, type ReactNode, useState } from 'react';

import bannerImg from '@/assets/onboarding/banner.png';
import Section from '@/components/section/Section';
import Breakpoints from '@/domain/theme/Breakpoints';
import { Colors } from '@/domain/theme/Colors';
import OnboardingHeaderLeft from '@/navigation/components/OnboardingHeaderLeft';
import { Image } from 'expo-image';
import {
  DimensionValue,
  type LayoutChangeEvent,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';

type Props = {
  showBackButton?: boolean;
  children: ReactNode;
};

const OnboardingWebLayout: FC<Props> = ({
  children,
  showBackButton = false,
}) => {
  const { width } = useWindowDimensions();

  if (width < Breakpoints.sm) {
    if (!showBackButton) {
      return <>{children}</>;
    } else {
      return (
        <>
          <Section bg={Colors.neutral0} jcfs aifs p={25}>
            <OnboardingHeaderLeft />
          </Section>
          {children}
        </>
      );
    }
  }

  return (
    <Section f1 bg={Colors.neutral0} row p={20} gap={30} jcsb>
      {width > Breakpoints.lg ? (
        <Image source={bannerImg} style={styles.banner} contentFit="contain" />
      ) : null}
      {showBackButton ? (
        <Section bg={Colors.neutral0} jcfs aifs pt={10}>
          <OnboardingHeaderLeft />
        </Section>
      ) : null}
      <View style={styles.webScreenView}>{children}</View>
    </Section>
  );
};

const styles = StyleSheet.create({
  banner: {
    maxWidth: 520,
    borderRadius: 18,
    width: '100%',
    height: '100%',
  },
  webScreenView: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    maxWidth: 520,
    height: '100%',
    width: '100%',
  },
});

export default OnboardingWebLayout;
