import PostHog, { PostHogProvider, usePostHog } from 'posthog-react-native';
import React, {
  useEffect,
  type FC,
  useContext,
  type ReactElement,
  useCallback,
} from 'react';
import Config from 'react-native-config';
import { useShallow } from 'zustand/react/shallow';

import type { EventType, TrackingEvent } from './types';

import { constants } from '@/constants';
import useUserStore from '@/domain/user/state/useUserStore';
import DeviceInfo from 'react-native-device-info';

const currentVersion = DeviceInfo.getVersion();

const ExperienceContext = React.createContext<{
  posthog: PostHog | undefined;
  track: (name: EventType, event?: TrackingEvent) => void;
  resetPostHogSession: () => void;
}>({
  posthog: undefined,
  track: () => {},
  resetPostHogSession: () => {},
});

interface Props {
  children: ReactElement;
}

export let posthogGlobal: PostHog | undefined;

export const Providers: FC<Props> = ({ children }) => {
  const posthog = usePostHog();
  const [userId, username] = useUserStore(
    useShallow(state => [state.user?.id, state.user?.username]),
  );

  useEffect(() => {
    if (PostHogProvider == null) {
      return;
    }

    if (!userId) {
      return;
    }

    const userIdentification =
      Config.ENV === 'prod' ? userId : username || userId;
    posthog?.identify(userId, { username: userIdentification });
  }, [posthog, userId, username]);

  const track = useCallback(
    async (name: EventType, event?: TrackingEvent) => {
      try {
        const payload = {
          ...event,
          env: Config.ENV,
          userId: userId,
          version: `${currentVersion}.${constants.codePushVersion}`,
        };
        posthog?.capture(name, payload);
      } catch (e) {
        //Using console instead of logger here since the logger calls here.
        console.error('Error sending event to posthog', e);
      }
    },
    [userId, posthog],
  );

  const resetPostHogSession = useCallback(() => {
    posthogGlobal?.reset();
    posthogGlobal?.resetSessionId();
    posthog?.reset();
    posthog?.resetSessionId();
  }, [posthog]);

  if (PostHogProvider == null) {
    return children;
  }

  return (
    <ExperienceContext.Provider value={{ posthog, track, resetPostHogSession }}>
      {children}
    </ExperienceContext.Provider>
  );
};

// Used for sending post hog events outside of the provider.
export const postHogTrack = async (name: EventType, event?: TrackingEvent) => {
  try {
    if (!posthogGlobal) {
      posthogGlobal = await PostHog.initAsync(Config.POSTHOG_API_KEY ?? '');
    }

    posthogGlobal?.capture(name, {
      ...event,
      env: Config.ENV,
      version: `${currentVersion}.${constants.codePushVersion}`,
    });
  } catch (e) {
    //Using console instead of logger here since the logger calls here.
    console.error('Error sending event to global posthog', e);
  }
};

export const postHogGetFeatureFlag = async (flagKey: string) => {
  try {
    if (!posthogGlobal) {
      posthogGlobal = await PostHog.initAsync(Config.POSTHOG_API_KEY ?? '');
    }

    return posthogGlobal.getFeatureFlag(flagKey);
  } catch (e) {
    console.error('Error getting feature flag from global posthog', e);
  }
};

export const useExperience = () => useContext(ExperienceContext);
