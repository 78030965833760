import React from 'react';
import { StyleSheet, Text } from 'react-native';

import Logo from '@/assets/svg/8seats-logo-dark.svg';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

interface SignInTitleProps {
  newAccount?: boolean;
}

const SignInTitle = ({ newAccount }: SignInTitleProps) => {
  const { translate } = useTranslations();

  return (
    <>
      <Logo height={70} />

      {newAccount && (
        <Text style={[styles.base, styles.invitedTitle]}>
          {translate('welcome_to_8seats_label')}
        </Text>
      )}

      <Text style={[styles.base, styles.title]}>
        {newAccount
          ? translate('exclusive_access_add_phone_number_title')
          : translate('already_have_an_account')}
      </Text>
    </>
  );
};

export default SignInTitle;

const styles = StyleSheet.create({
  base: {
    textAlign: 'center',
    maxWidth: 700,
  },
  invitedTitle: {
    color: Greys.shade600,
    marginTop: 30,
    fontFamily: 'OpenSans-Bold',
    fontSize: web ? 40 : 30,
    lineHeight: web ? 40 : 30,
  },
  title: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontWeight: '400',
    fontSize: web ? 24 : 20,
    marginTop: 15,
    lineHeight: 24,
    maxWidth: 400,
    paddingHorizontal: 30,
  },
});
