import React from 'react';

import type { Details } from '../types';

import { SettingsSubtitle } from './SettingsSubtitle';

import ChatUnitBackgroundImagePicker from '@/components/chat-unit-background-image-picker';
import { ChatUnitType } from '@/domain/invites/types/types';
import type { ChatUnit } from '@/services/types';
import useTranslations from '@/translations/useTranslation';

interface ImagePickerProps {
  currentChatUnit: ChatUnit;
  setCurrentChatUnit: (input: Details) => void;
}

const ImagePickerTypeText = {
  background_explanation_text: {
    [ChatUnitType.QUICK_CHAT]: 'dm_background_explanation',
    [ChatUnitType.TABLE]: 'table_background_explanation',
    [ChatUnitType.ROOM]: 'room_background_explanation',
  },
  background_label: {
    [ChatUnitType.QUICK_CHAT]: 'dm_background_label',
    [ChatUnitType.TABLE]: 'table_background_label',
    [ChatUnitType.ROOM]: 'room_background_label',
  },
};

const ImagePicker = ({
  currentChatUnit: currentTable,
  setCurrentChatUnit,
}: ImagePickerProps) => {
  const { translate } = useTranslations();

  return (
    <>
      <SettingsSubtitle
        subtitle={translate(
          ImagePickerTypeText.background_explanation_text[currentTable.type],
        )}
        title={translate(
          ImagePickerTypeText.background_label[currentTable.type],
        )}
      />
      <ChatUnitBackgroundImagePicker
        onChange={image =>
          setCurrentChatUnit({
            ...currentTable,
            backgroundImage: image,
          })
        }
        value={currentTable.backgroundImage}
        tableId={currentTable.id}
      />
    </>
  );
};

/**
 * Custom background picker
 * This type has a key of chatUnitId and a value of the image uri or image key
 */
export type CustomBackground = {
  [chatUnitId: string]: {
    localFilePath: string;
    imageKey?: string;
  };
};

export default ImagePicker;
