import { Roles } from '@/API';

import UpIcon from '@/assets/chatview/up.svg';
import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import { AppText, Spacer } from '@/ui/app';
import { Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import React from 'react';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
type Props = {
  openMemberSelect: boolean;
  setOpenMemberSelect: (open: boolean) => void;
  memberType: Roles;
  setMemberType: (role: Roles) => void;
};

export const ChooseMemberTypeSelect = ({
  openMemberSelect,
  setOpenMemberSelect,
  memberType,
  setMemberType,
}: Props) => {
  const onPress = () => {
    if (!openMemberSelect) {
      setOpenMemberSelect(true);
    }
  };

  return (
    <Pressable style={styles.pressable} onPress={onPress}>
      <View
        style={[
          styles.container,
          openMemberSelect ? styles.containerActive : {},
        ]}>
        <View style={styles.viewContainer}>
          {(openMemberSelect || memberType === Roles.MEMBER) && (
            <Section
              onPress={
                openMemberSelect
                  ? () => {
                      setMemberType(Roles.MEMBER);
                      setOpenMemberSelect(false);
                    }
                  : undefined
              }>
              <Section
                br={BorderRadius.md}
                bw={1}
                mh={20}
                ph={10}
                pv={5}
                mb={5}
                bc={Colors.neutral80}
                aic
                jcfs
                bg={Colors.neutral0}
                asfs>
                <AppText size={12} type="primary600">
                  {Roles.MEMBER}
                </AppText>
              </Section>
              <Section ph={20} mb={10}>
                <AppText size={12} color={Colors.neutral70}>
                  Can start Tables, Rooms and DMs. Can invite existing members
                  or new guests.
                  <AppText size={12} type="primary600" color={Colors.neutral70}>
                    Billed as part of your team.
                  </AppText>
                </AppText>
              </Section>
            </Section>
          )}

          {openMemberSelect && <Spacer height={1} bg={Neutrals.shade200} />}
          {(openMemberSelect || memberType === Roles.GUEST) && (
            <Section
              bg={Colors.neutral05}
              ph={20}
              onPress={
                openMemberSelect
                  ? () => {
                      setMemberType(Roles.GUEST);
                      setOpenMemberSelect(false);
                    }
                  : undefined
              }>
              <Section
                br={BorderRadius.md}
                bw={1}
                ph={10}
                pv={5}
                mb={5}
                mt={10}
                bc={Colors.neutral70}
                aic
                jcfs
                bg={Colors.neutral05}
                asfs
                style={styles.dashedBorder}>
                <AppText size={12} color={Colors.neutral70} type="primary600">
                  GUEST
                </AppText>
              </Section>
              <AppText size={12} color={Colors.neutral70}>
                Cannot start new chats. Must be invited to Tables, Rooms or DMs.
                <AppText size={12} type="primary600" color={Colors.neutral70}>
                  Always free.
                </AppText>
              </AppText>
              <Spacer height={10} />
            </Section>
          )}
        </View>
        {!openMemberSelect && (
          <View style={styles.selectContainer}>
            <>
              <Section jcc aic gap={0} style={styles.arrowsContainer}>
                <View style={{ flex: 1 }}>
                  <UpIcon />
                </View>
                <View style={[styles.rotateUpToDown, { flex: 1 }]}>
                  <UpIcon />
                </View>
              </Section>
            </>
          </View>
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  avatar: {
    borderWidth: 1,
    borderRadius: BorderRadius.full,
    borderColor: Colors.neutral80,
  },
  gradient: {
    height: 20,
  },
  pressable: {
    width: '100%',
    height: 200,
  },
  dashedBorder: {
    borderStyle: 'dashed',
  },
  container: {
    flexDirection: 'row',
    borderRadius: BorderRadius.md,
    borderStyle: 'solid',
    borderColor: Neutrals.shade300,
    borderWidth: 1,
    width: '100%',
    backgroundColor: Colors.neutral05,
  },
  containerActive: {
    flexDirection: 'row',
    width: '100%',
    ...Platform.select({
      default: {
        // For Android, adjust as necessary
        elevation: 5,
      },
      ios: {
        shadowColor: Greys.shade999,
        shadowOffset: {
          height: 10,
          width: 1, // You can adjust the height to control the spread
        },
        shadowOpacity: 0.8, // This approximates the 20% black at the end of your gradient
        shadowRadius: 20, // This controls the blur radius
      },
    }),
  },
  viewContainer: { flex: 5, paddingTop: 12, paddingBottom: 12 },
  arrowsContainer: {
    flexDirection: 'column',
    height: 40,
  },
  selectContainer: {
    flex: 1,
    justifyContent: 'center',
    borderLeftWidth: 1,
    backgroundColor: Neutrals.shade100,
    borderColor: Neutrals.shade300,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  rotateUpToDown: {
    transform: [{ rotate: '180deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
});
