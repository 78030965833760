import { handleMessageCommands } from '../functions/handleMessageCommands';

import { eventBus } from '@/services/eventBus/eventBus';
import { logger } from '@/services/logger/logger';

let isListenerInitialsed = false;

export const addMessageCommandsListener = () => {
  if (isListenerInitialsed) {
    return;
  }
  eventBus.on('messageSaved', async ({ message }) => {
    try {
      handleMessageCommands(message);
    } catch (e) {
      logger.error('addMessageCommandsListener::messageSavedError', e);
    }
  });
  isListenerInitialsed = true;
};
