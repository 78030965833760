import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgArchiveSmall = (props: SvgProps) => (
  <Svg
    width={19}
    height={18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M14.75 14.25V4.5h1.5v9.75A2.25 2.25 0 0114 16.5H5a2.25 2.25 0 01-2.25-2.25V4.5h1.5v9.75c0 .414.336.75.75.75h9a.75.75 0 00.75-.75z"
      fill="#262929"
    />
    <Path
      d="M7.25 8.25c0-.6.5-.75.75-.75h3c.25 0 .75.15.75.75S11.25 9 11 9H8c-.25 0-.75-.15-.75-.75z"
      fill="#262929"
    />
    <Path
      d="M2 3a.75.75 0 01.75-.75h13.5A.75.75 0 0117 3v1.5a.75.75 0 01-.75.75H2.75A.75.75 0 012 4.5V3z"
      stroke="#262929"
      strokeWidth={1.5}
    />
  </Svg>
);
export default SvgArchiveSmall;
