import type { NavigationProp, ParamListBase } from '@react-navigation/native';
import {
  DrawerActions,
  StackActions,
  useNavigation as useBaseNavigation,
} from '@react-navigation/native';
import { useCallback } from 'react';

import { navigationRef } from '@/navigation/navigationRef';

const useNavigation = <T extends NavigationProp<ParamListBase>>() => {
  const {
    canGoBack,
    getParent,
    goBack: baseGoBack,
    ...rest
  } = useBaseNavigation<T>();

  const goBack = useCallback(() => {
    if (canGoBack()) {
      baseGoBack();
    }
  }, [canGoBack, baseGoBack]);

  const parentGoBack = useCallback(() => {
    if (getParent()?.canGoBack()) {
      getParent()?.goBack();
    }
  }, [getParent]);

  const openDrawer = () => {
    navigationRef.current?.dispatch(DrawerActions.openDrawer());
  };

  const closeDrawer = () => {
    navigationRef.current?.dispatch(DrawerActions.closeDrawer());
  };

  const replaceRoute = (name: string, ...params: any[]) => {
    getParent()?.dispatch(StackActions.replace(name, ...params));
  };

  return {
    canGoBack,
    goBack,
    openDrawer,
    parentGoBack,
    replaceRoute,
    navigationRef,
    closeDrawer,
    ...rest,
  };
};

export default useNavigation;
