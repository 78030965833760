import { type Organisation, Roles, Statuses } from '@/models';

export const isActiveMemberOfOrg = (
  orgs: Organisation[],
  orgId?: string,
  userId?: string,
  phoneNumber?: string,
) => {
  return (
    !orgId ||
    orgs
      .find(org => org.id === orgId)
      ?.members?.find(
        member =>
          (member?.userId === userId || member?.phoneNumber === phoneNumber) &&
          (member?.role === Roles.MEMBER || member?.role === Roles.OWNER) &&
          member?.status === Statuses.ACTIVE,
      )
  );
};
