import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Colors } from '../theme/Colors';

import AppIcon from '@/assets/onboarding/app_icon.png';
import AppIconWeb from '@/assets/onboarding/app_icon_web.png';
import type { MainStackScreenProps } from '@/navigation/types';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';
import { Image } from 'expo-image';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import AuthService from '../auth/AuthService';
import OnboardingLoginWebLayout from './components/OnboardingLoginWebLayout';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingPhoneNumberInput from './components/OnboardingPhoneNumberInput';

type Props = MainStackScreenProps<'OnboardingSignIn'>;

function OnboardingSignInScreen({ navigation }: Props) {
  const { translate } = useTranslations();
  const [phoneNumber, setPhoneNumber] = useState('');
  const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : false;
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      const signInResult = await AuthService.signIn(phoneNumber);
      if (signInResult?.nextStep.signInStep) {
        navigation.navigate('OnboardingSignInOTP', {
          phoneNumber,
        });
      }
    } catch (err) {
      logger.error('Error signing up with phone number', err);
      setError('Error logging in. That account might not have been created.');
    } finally {
      setSubmitting(false);
    }
  }, [navigation, phoneNumber]);

  useEffect(() => {
    if (phoneNumber) {
      setError(null);
    }
  }, [phoneNumber]);

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        {!web ? (
          <>
            <Spacer height={50} />
            <Image source={web ? AppIconWeb : AppIcon} style={styles.icon} />
            <Spacer height={20} />
          </>
        ) : null}

        <AppText size={web ? 35 : 28} type="primary800">
          {translate('sign_in_title')}
        </AppText>
        <Spacer height={20} />
        <AppText size={web ? 21 : 17}>{translate('sign_in_subtitle')}</AppText>
        <Spacer height={40} />
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('your_phone_number')}
        </AppText>
        <Spacer height={16} />
        <OnboardingPhoneNumberInput
          setPhoneNumber={setPhoneNumber}
          onSubmit={isValid ? handleSubmit : undefined}
        />

        {error ? (
          <AppText size={16} color={Colors.melon}>
            {error}
          </AppText>
        ) : null}
        <Spacer height={40} />
        <OnboardingNextButton
          onPress={handleSubmit}
          text={translate('next_step')}
          disabled={!isValid}
          loading={submitting}
        />
        <Spacer height={30} />
        <Pressable onPress={() => navigation.replace('OnboardingStepZero')}>
          <AppText size={web ? 17 : 14} color={Colors.neutral80}>
            {translate('no_account')}
            <AppText
              size={web ? 17 : 14}
              type="primary700"
              color={Colors.primaryLight}>
              {' '}
              {translate('sign_up_here')}
            </AppText>
          </AppText>
        </Pressable>
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    flexGrow: 1,
  },
  icon: {
    width: web ? 128 : 72,
    height: web ? 128 : 72,
  },
});

const OnboardingSignInScreenWeb = (props: Props) => {
  return (
    <OnboardingLoginWebLayout>
      <OnboardingSignInScreen {...props} />
    </OnboardingLoginWebLayout>
  );
};

export default web ? OnboardingSignInScreenWeb : OnboardingSignInScreen;
