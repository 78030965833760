import { useTranslation } from 'react-i18next';

import type en from './en/en.json';

export type Translation = typeof en;

export type TranslationKey = keyof Translation;

export type Translate = (key: TranslationKey) => string;

const useTranslations = () => {
  const { i18n, t } = useTranslation<TranslationKey>();
  return { translate: t, translator: i18n };
};

export default useTranslations;
