import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import PeopleIcon from '@/assets/svg/people.svg';
import OutlinedButton from '@/components/OutlinedButton';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

interface EmptyStateProps {
  loadAllContacts: () => void;
}

const EmptyState = ({ loadAllContacts }: EmptyStateProps) => {
  const { translate } = useTranslations();
  return (
    <View style={styles.container}>
      <PeopleIcon />
      <Text style={styles.title}>
        {translate('no_contacts_in_directory_title')}
      </Text>
      <Text style={styles.subtitle}>
        {translate('no_contacts_in_directory_subtitle')}
      </Text>
      {!web && (
        <OutlinedButton
          onPress={loadAllContacts}
          style={styles.outlineButton}
          title={translate('no_contacts_in_directory_button_title')}
          titleStyle={styles.outlineButtonTitle}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  outlineButton: {
    borderColor: Greys.shade600,
    borderWidth: 2,
    height: 40,
  },
  outlineButtonTitle: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  subtitle: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginBottom: 40,
    textAlign: 'center',
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 21,
    fontWeight: '400',
    marginVertical: 20,
    textAlign: 'center',
  },
});
export default EmptyState;
