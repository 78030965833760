import { DataStore } from 'aws-amplify/datastore';

import { ChatUnit, type ChatUnitColorId } from '../../../models';

import { eventBus } from '@/services/eventBus/eventBus';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';
import type { ChatUnit as IChatUnit } from '@/services/types';

export const updateChatUnitDetails = async (
  chatUnitId: string,
  chatUnitDetails: Partial<IChatUnit>,
) => {
  try {
    const currentChatUnit = await DataStore.query(ChatUnit, chatUnitId);
    if (currentChatUnit) {
      const updatedChatUnit = await DataStore.save(
        ChatUnit.copyOf(currentChatUnit, updated => {
          updated.title = chatUnitDetails.title || currentChatUnit.title;
          updated.description =
            chatUnitDetails.description || currentChatUnit.description;
          updated.colorId =
            (chatUnitDetails.colorId as ChatUnitColorId) ||
            currentChatUnit.colorId;
          updated.backgroundImage =
            chatUnitDetails.backgroundImage || currentChatUnit.backgroundImage;
          updated.allowedUserIds =
            chatUnitDetails.allowedUserIds || currentChatUnit.allowedUserIds;
          updated.allConversationId =
            chatUnitDetails.allConversationId ||
            currentChatUnit.allConversationId;
        }),
      );
      const updatedTableDetails = factory.chatUnit(updatedChatUnit);
      eventBus.emit('chatUnitUpdated', updatedTableDetails);

      return updatedTableDetails;
    } else {
      logger.error('updateTableDetailsError::chatUnitIdMissing', chatUnitId);
      return null;
    }
  } catch (e) {
    logger.error('updateTableDetailsError', chatUnitId);
  }
};
