import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { blue6 } from '../../theme/colors';

import { Greys } from '@/ui/common/colors';

type RoomsChatheadProps = {
  size?: number;
  text: string;
};

const RoomsChathead = ({ size = 36, text }: RoomsChatheadProps) => {
  const styles = getStyles(size);

  return (
    <View
      style={[
        styles.defaultAvatarContainer,
        { backgroundColor: blue6 ?? Greys.shade500 },
      ]}>
      <Text style={styles.defaultAvatarText}>{text}</Text>
    </View>
  );
};

const getStyles = (size: number) =>
  StyleSheet.create({
    defaultAvatarContainer: {
      alignItems: 'center',
      backgroundColor: Greys.shade500,
      borderRadius: 6,
      height: size,
      justifyContent: 'center',
      width: size,
    },
    defaultAvatarText: {
      color: Greys.shade0,
      fontSize: 12,
    },
  });

export default RoomsChathead;
