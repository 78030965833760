import type React from 'react';
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { updateChatUnitUser } from 'src/services/datastore/user/updateChatUnitUser.ts';

import { ChatUnitType } from '@/API';
import RefreshService from '@/domain/table/functions/RefreshService';
import { Colors } from '@/domain/theme/Colors';
import useNavigation from '@/hooks/useNavigation';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { logger } from '@/services/logger/logger';
import type { ChatUnitUser } from '@/services/types';
import { AppText } from '@/ui/app';

interface Props {
  visible: boolean;
  onClose: () => void;
  tableName?: string;
  chatUser?: ChatUnitUser | null;
  isRemovingUser?: boolean;
}

/**
 * LeaveTableModal Component
 *
 * This modal allows a user to leave a table or remove another user from the table.
 * It handles the update operation and provides feedback based on the action taken.
 */
const LeaveTableModal: React.FC<Props> = ({
  visible,
  onClose,
  tableName = '',
  chatUser,
  isRemovingUser = false,
}) => {
  const { navigate } = useNavigation();

  /**
   * Handles confirmation of leaving or removing the user from the table.
   *
   * @async
   */
  const handleConfirm = async () => {
    if (chatUser?.chatUnitUserId) {
      try {
        await updateChatUnitUser(chatUser.chatUnitUserId, {
          status: ChatUnitUserStatus.ARCHIVED,
        });
        await Promise.all([
          RefreshService.refreshChatUnits(ChatUnitType.TABLE),
          RefreshService.refreshChatUsers(),
        ]);
        const action = isRemovingUser ? 'removed' : 'left';
        console.log(`Successfully ${action} the table`);

        if (!isRemovingUser) {
          navigate('MainNavigator');
        }
      } catch (error) {
        const action = isRemovingUser ? 'removing user from' : 'leaving';
        logger.error(`Error ${action} the table:`, error);
      }
    } else {
      logger.error('Action failed: ChatUser or chatUnitUserId is undefined');
    }
    onClose();
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onClose}>
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          {/* Modal Title */}
          <AppText type="primary700" style={styles.modalTitle}>
            {isRemovingUser
              ? `Remove ${chatUser?.username} From This Table`
              : 'Leave This Table'}
          </AppText>

          {/* Modal Message */}
          <AppText size={14} style={styles.modalText}>
            {isRemovingUser ? (
              <>
                Are you sure you want to remove{' '}
                <AppText type="primary700" size={14}>
                  {chatUser?.username}
                </AppText>{' '}
                from the{' '}
                <AppText type="primary700" size={14}>
                  {tableName}
                </AppText>{' '}
                Table?
              </>
            ) : (
              <>
                Are you sure you want to leave the{' '}
                <AppText type="primary700" size={14}>
                  {tableName}
                </AppText>{' '}
                Table?
              </>
            )}
          </AppText>

          {/* Information Message */}
          <AppText size={14} style={styles.modalText}>
            {isRemovingUser ? 'They' : 'You'} will not be able to rejoin unless
            someone from the Table invites
            {isRemovingUser ? ' them ' : ' you '}
            again.
          </AppText>

          <LinearGradient
            colors={[Colors.neutral40, 'rgba(151, 151, 151, 0)']}
            style={styles.divider1}
          />

          {/* Confirm Action Button */}
          <TouchableOpacity
            style={styles.leaveButton}
            onPress={handleConfirm}
            activeOpacity={0.7}>
            <AppText style={styles.leaveButtonText}>
              {isRemovingUser
                ? `Remove ${chatUser?.username}`
                : 'Leave this Table'}
            </AppText>
          </TouchableOpacity>

          <View style={styles.divider2} />

          {/* Cancel Button */}
          <TouchableOpacity
            style={styles.cancelButton}
            onPress={onClose}
            activeOpacity={0.7}>
            <AppText style={styles.cancelButtonText}>Cancel</AppText>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: Colors.neutral0,
    borderRadius: 18,
    paddingVertical: 20,
    paddingHorizontal: 25,
    width: 335,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.neutral100,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 40,
  },
  modalTitle: {
    fontSize: 17,
    fontWeight: '700',
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 16,
    paddingTop: 10,
  },
  modalText: {
    fontSize: 14,
    lineHeight: 19,
    color: Colors.neutral80,
    textAlign: 'center',
    marginBottom: 8,
  },
  divider1: {
    width: 335,
    height: 10,
    opacity: 0.25,
  },
  divider2: {
    width: 315,
    height: 1,
    backgroundColor: Colors.neutral80,
    opacity: 0.25,
  },
  leaveButton: {
    paddingHorizontal: 24,
    alignItems: 'center',
    marginVertical: 16,
  },
  leaveButtonText: {
    fontSize: 17,
    color: Colors.rasberry,
  },
  cancelButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  cancelButtonText: {
    fontSize: 17,
    color: Colors.neutral80,
  },
});

export default LeaveTableModal;
