import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ChatUnitType } from '../../../../API';

import AppAvatar from '@/components/avatar';
import { getName } from '@/domain/user/functions/getName';
import useUserStore from '@/domain/user/state/useUserStore';
import type { ChatUnitUser } from '@/services/types';
import { brandTeal } from '@/theme/colors';

type Props = {
  tableUsers: ChatUnitUser[];
  type: ChatUnitType;
};

const AVATAR_SIZE = 24;

export const Avatars = ({ tableUsers, type }: Props) => {
  const userId = useUserStore(state => state.user?.id);
  const styles = useMemo(() => getStyles(type), [type]);

  const renderAvatar = (tableUser: ChatUnitUser) => (
    <View key={tableUser.chatUnitUserId} style={styles.cardAvatar}>
      <AppAvatar
        size={AVATAR_SIZE}
        style={tableUser.id === userId ? styles.myAvatarRing : undefined}
        imageKey={tableUser.avatar}
        displayName={getName(tableUser, 'short')}
      />
    </View>
  );

  return (
    <View style={styles.cardAvatarsContainer}>
      {tableUsers.map(renderAvatar)}
    </View>
  );
};

const getStyles = (type: ChatUnitType) => {
  const avatarHorizontalMargin = 3;
  const avatarVerticalMargin = type === ChatUnitType.TABLE ? 2 : 0;
  const myAvatarBorderWidth = 2;
  const numRows = type === ChatUnitType.TABLE ? 2 : 1;
  const numAvatarsPerRow = type === ChatUnitType.TABLE ? 4 : 5;
  const avatarContainerWidth =
    numAvatarsPerRow * (AVATAR_SIZE + avatarHorizontalMargin * 2) +
    myAvatarBorderWidth;
  const grossAvatarHeight = AVATAR_SIZE + avatarVerticalMargin * 2;
  const containerHeight = numRows * grossAvatarHeight + myAvatarBorderWidth;

  return StyleSheet.create({
    cardAvatar: {
      marginHorizontal: avatarHorizontalMargin,
      marginVertical: avatarVerticalMargin,
    },
    cardAvatarsContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: containerHeight,
      justifyContent: 'flex-start',
      width: avatarContainerWidth,
    },
    myAvatarRing: {
      borderColor: brandTeal,
      borderRadius: 13,
      borderWidth: myAvatarBorderWidth,
      height: AVATAR_SIZE + myAvatarBorderWidth,
      width: AVATAR_SIZE + myAvatarBorderWidth,
    },
  });
};

export default React.memo(Avatars);
