import { useFeatureFlag } from 'posthog-react-native';
import React from 'react';

import { AddDirectoryIcon } from './AddDirectoryIcon';
import { AddPersonIcon } from './AddPersonIcon';
import { AddRobotIcon } from './AddRobotIcon';

import { ActionList } from '@/components/actionList/ActionList';
import useTranslations from '@/translations/useTranslation';

type InviteActionSheetProps = {
  disableCreateInvite?: boolean;
  goToCreateInvite: () => void;
  goToCreateInvites: () => void;
  inviteChatgpt?: () => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const InviteActionSheet = ({
  disableCreateInvite,
  goToCreateInvite,
  goToCreateInvites,
  inviteChatgpt,
  isOpen,
  setIsOpen,
}: InviteActionSheetProps) => {
  const { translate } = useTranslations();
  const enableInvite8seatsChatAI = useFeatureFlag('invite8seatsChatAI');
  const actionItems: {
    disabled?: boolean;
    icon: JSX.Element;
    title: string;
    onPress: () => void;
  }[] = [
    {
      disabled: disableCreateInvite,
      icon: <AddDirectoryIcon size={25} />,
      title: translate('invite_choose_in_directory'),
      onPress: goToCreateInvites,
    },
    {
      disabled: disableCreateInvite,
      icon: <AddPersonIcon size={25} />,
      title: translate('invite_someone_new_button'),
      onPress: goToCreateInvite,
    },
  ];

  if (inviteChatgpt && enableInvite8seatsChatAI) {
    actionItems.push({
      // disabled: disableCreateInvite,
      icon: <AddRobotIcon size={25} />,
      title: translate('invite_8seats_ai'),
      onPress: inviteChatgpt,
    });
  }

  return (
    <ActionList
      actionButtonTitle={translate('invite_cancel_button')}
      items={actionItems}
      open={isOpen}
      setOpen={setIsOpen}
    />
  );
};
