import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Rive, { Fit } from 'rive-react-native';

import { Colors } from '@/domain/theme/Colors'; // Import Colors enum
import { AppText, Spacer } from '@/ui/app';

const Loader = () => {
  const bottomInsets = useSafeAreaInsets().bottom;
  return (
    <View style={Platform.OS === 'web' ? styles.overlayWeb : styles.overlay}>
      <View style={styles.content}>
        <Rive
          fit={Fit.Cover}
          resourceName={'loader'}
          style={styles.container}
        />
        <AppText type="primary700" size={20} style={styles.textTitle}>
          Refreshing your stored data
        </AppText>
        <AppText size={14} style={styles.textSubtitle}>
          Please note that this process may take a few minutes to complete
          updating on your device.
        </AppText>
      </View>
      <Spacer height={bottomInsets + 16} />
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute', // Absolute for mobile
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent black background
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%', // Adjust to cover the full width
    height: 200, // Adjusted based on the loader's height
  },
  textTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.neutral0, // Using Colors enum
    marginTop: 20,
    textAlign: 'center',
  },
  textSubtitle: {
    fontSize: 14,
    color: Colors.neutral0, // Using Colors enum
    marginTop: 10,
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  overlayWeb: {
    // Reason: Mobile doesn't support fixed positioning, unlike web
    // @ts-ignore
    position: 'fixed', // Fixed positioning for web
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent black background
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
});

export default Loader;
