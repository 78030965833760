import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
// @ts-ignore
import Carousel from 'react-native-snap-carousel';

import BackgroundImage from '../image/ChatUnitBackgroundImage';
import BoldCaption from '../text/BoldCaption';

import trashImg from '@/assets/chatUnitSettings/trash.png';
import sceneryImg from '@/assets/chatview/scenery.png';
import { useCustomBackground } from '@/domain/chatUnit/components/settings/components/useCustomBackground';
import { logger } from '@/services/logger/logger';
import { primaryBlue } from '@/theme/colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { Brand, Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import {
  type ChatUnitBackgroundImage,
  DEFAULT_CHAT_UNIT_PICKER_IMAGE,
  chatUnitBackgroundImages,
  getChatUnitBackgroundImage,
} from '@/utilities/constants/chat-unit-background-images';
import { web } from '@/utilities/platform';

const DESIGN_SCREEN_WIDTH = 375;
const DESIGN_SLIDE_WIDTH = 195;
const DESIGN_SLIDE_HEIGHT = 310;
const SCREEN_WIDTH = web ? 400 : Dimensions.get('window').width;
const SLIDE_WIDTH = (DESIGN_SLIDE_WIDTH * SCREEN_WIDTH) / DESIGN_SCREEN_WIDTH;
const SLIDE_HEIGHT = (SCREEN_WIDTH * DESIGN_SLIDE_HEIGHT) / DESIGN_SCREEN_WIDTH;

export type ChatUnitBackgroundImageValue = ChatUnitBackgroundImage & {
  url?: string;
};

interface IProps {
  onChange: (backgroundImage: string) => void;
  tableId: string;
  title?: string;
  value: string;
}
const ChatUnitBackgroundImagePicker = ({
  onChange,
  tableId,
  title,
  value,
}: IProps) => {
  const { translate } = useTranslations();
  const carouselRef = useRef<Carousel>();

  const [currentId, setCurrentId] = useState(value);
  const [viewWidth, setViewWidth] = useState(0);
  const {
    customBg: lastPickedCustomBackground,
    pickAndApplyCustomBackground,
    loading,
    progress,
    deletePreviouslyAppliedImage,
  } = useCustomBackground(tableId, true);

  const handleDelete = useCallback(() => {
    deletePreviouslyAppliedImage();
    onChange(DEFAULT_CHAT_UNIT_PICKER_IMAGE);
  }, [deletePreviouslyAppliedImage, onChange]);

  const handlePick = useCallback(async () => {
    try {
      const key = await pickAndApplyCustomBackground();
      if (key) {
        onChange(key);
      }
    } catch (error) {
      logger.error('ChatUnitBackgroundImagePicker::', error);
    }
  }, [onChange, pickAndApplyCustomBackground]);

  const allBackgroundImages = useMemo(() => {
    if (value && !getChatUnitBackgroundImage(value.toLowerCase()) && !loading) {
      // Value exists, but is custom
      return [
        {
          id: value,
          title: translate('custom_bg_image_label'),
          source: {
            uri: lastPickedCustomBackground?.localFilePath,
          },
        },
        ...chatUnitBackgroundImages,
      ];
    }

    if (
      lastPickedCustomBackground?.imageKey ||
      lastPickedCustomBackground?.localFilePath
    ) {
      // Show last selected custom background, if it exists
      return [
        {
          id:
            lastPickedCustomBackground?.imageKey ||
            lastPickedCustomBackground.localFilePath,
          title: translate('custom_bg_image_label'),
          source: { uri: lastPickedCustomBackground.localFilePath },
        },
        ...chatUnitBackgroundImages,
      ];
    }

    return [
      { title: translate('add_custom_image'), id: 'noCustom' },
      ...chatUnitBackgroundImages,
    ];
  }, [
    lastPickedCustomBackground?.imageKey,
    lastPickedCustomBackground?.localFilePath,
    loading,
    translate,
    value,
  ]);

  const noCustomImage =
    allBackgroundImages[0].title === translate('add_custom_image');

  const focusedItem =
    allBackgroundImages.find(
      image => image.id.toLowerCase() === currentId.toLowerCase(),
    ) || allBackgroundImages[0];
  const currentIndex =
    allBackgroundImages.findIndex(item => item.id === focusedItem?.id) || 0;

  return (
    <View
      onLayout={event => {
        setViewWidth(event.nativeEvent.layout.width);
      }}>
      {title && <Text style={styles.title}>{title}</Text>}
      <View style={styles.carouselWrapper}>
        <BoldCaption style={styles.backgroundTitle}>
          {focusedItem.title}
        </BoldCaption>
        <Carousel
          activeAnimationType={'decay'}
          data={allBackgroundImages}
          firstItem={currentIndex}
          inactiveSlideOpacity={0.9}
          inactiveSlideScale={10}
          initialNumToRender={allBackgroundImages.length}
          itemWidth={SLIDE_WIDTH}
          onSnapToItem={(index: number) => {
            const item = allBackgroundImages[index];
            setCurrentId(item.id);
          }}
          ref={carouselRef}
          renderItem={({
            index,
            item,
          }: {
            index: number;
            item: ChatUnitBackgroundImage;
          }) => {
            const isSelected = value.toLowerCase() === item.id.toLowerCase();

            const isInFocus = currentIndex === index;

            const itemStyle = isInFocus
              ? { borderColor: Brand.primaryDark100 }
              : { borderColor: 'transparent' };

            const isFirstItem = index === 0;
            const showUploadImagePlaceholder = isFirstItem && noCustomImage;
            const showLoadingState = loading && isFirstItem;

            if (showUploadImagePlaceholder) {
              return (
                <TouchableOpacity
                  style={[styles.itemContainer, itemStyle]}
                  onPress={() => {
                    carouselRef.current?.snapToItem(index, true);
                    handlePick();
                  }}>
                  <View style={styles.chooseBorder}>
                    <Image
                      source={sceneryImg}
                      style={styles.icon}
                      tintColor={Greys.shade0}
                    />
                  </View>
                  <View style={styles.selectBackgroundButton}>
                    <Text style={styles.selectBackgroundButtonText}>
                      {translate('choose_custom_image')}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            }
            return (
              <TouchableOpacity
                onPress={() => carouselRef.current?.snapToItem(index, true)}
                style={[styles.itemContainer, itemStyle]}>
                <View style={styles.image}>
                  <BackgroundImage
                    imageKey={item.id}
                    contentFit="cover"
                    width={SLIDE_WIDTH}
                    height={SLIDE_HEIGHT}
                    placeholder={item.source}
                    showImagePreview={currentIndex === index}
                  />
                </View>

                {isFirstItem && !noCustomImage && !loading ? (
                  <Pressable
                    style={styles.trashContainer}
                    onPress={handleDelete}>
                    <Image source={trashImg} style={styles.trashIcon} />
                  </Pressable>
                ) : null}

                {showLoadingState ? (
                  <View style={styles.previewView}>
                    <Text style={styles.selectedBackgroundText}>
                      {translate('uploading')} {progress || 0}%
                    </Text>
                  </View>
                ) : (
                  currentIndex === index && (
                    <View style={styles.previewView}>
                      <Text style={styles.selectedBackgroundText}>
                        {translate('tap_to_preview')}
                      </Text>
                    </View>
                  )
                )}

                {showLoadingState ? (
                  <View style={styles.loadingView}>
                    <Spacer
                      width={`${progress}%`}
                      height={4}
                      bg={Brand.primaryDark100}
                    />
                  </View>
                ) : isSelected ? (
                  <View
                    style={[
                      styles.selectedBackgroundView,
                      styles.noPointerEvents,
                    ]}>
                    <Text style={styles.selectedBackgroundText}>
                      {translate('current_background_label')}
                    </Text>
                  </View>
                ) : (
                  <>
                    {currentIndex === index && (
                      <TouchableOpacity
                        onPress={() => onChange(item.id)}
                        style={styles.selectBackgroundButton}>
                        <Text style={styles.selectBackgroundButtonText}>
                          {translate('apply_button_title')}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </>
                )}
              </TouchableOpacity>
            );
          }}
          sliderWidth={web ? viewWidth : SCREEN_WIDTH}
          startIndex={currentIndex}
          swipeThreshold={1}
          useScrollView={false}
        />
        <Text style={styles.counterTitle}>
          {translate('table_background_image_carousel_counter', {
            current: currentIndex + 1,
            total: allBackgroundImages.length,
          })}
        </Text>
      </View>
      <Pressable onPress={handlePick} style={styles.row}>
        <View style={styles.border}>
          <Image
            source={sceneryImg}
            style={styles.icon}
            tintColor={Greys.shade999}
          />
        </View>
        <AppText color={Brand.primaryDark100} size={14} type="primary500">
          {translate('custom_background_title')}
        </AppText>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundTitle: {
    color: Greys.shade0,
    marginTop: 15,
    marginVertical: 15,
    textAlign: 'center',
  },
  carouselWrapper: {
    backgroundColor: Neutrals.shade800,
    marginTop: 0,
  },
  counterTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
    marginVertical: 15,
    textAlign: 'center',
  },
  image: {
    height: SLIDE_HEIGHT,
    position: 'absolute',
    width: SLIDE_WIDTH,
  },
  itemContainer: {
    alignItems: 'center',
    borderRadius: 12,
    borderWidth: 3,
    height: SLIDE_HEIGHT,
    justifyContent: 'center',
    marginHorizontal: 10,
    overflow: 'hidden',
    width: SLIDE_WIDTH - 20,
    backgroundColor: Neutrals.shade700,
  },
  selectBackgroundButton: {
    backgroundColor: Brand.primaryDark100,
    borderRadius: 12,
    bottom: 15,
    paddingHorizontal: 25,
    paddingVertical: 6,
    position: 'absolute',
  },
  selectBackgroundButtonText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 10,
    fontWeight: '700',
  },
  selectedBackgroundText: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 10,
    fontWeight: '600',
  },
  loadingView: {
    backgroundColor: Neutrals.shade800,
    borderRadius: 2,
    height: 4,
    width: SLIDE_WIDTH - 44,
    alignSelf: 'center',
    bottom: 13,
    position: 'absolute',
  },
  selectedBackgroundView: {
    backgroundColor: Greys.shade600,
    borderColor: Greys.shade0,
    borderRadius: 12,
    borderWidth: 1,
    bottom: 13,
    paddingHorizontal: 25,
    paddingVertical: 6,
    position: 'absolute',
  },
  previewView: {
    backgroundColor: Greys.shade600,
    borderColor: Greys.shade0,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 25,
    paddingVertical: 6,
    position: 'absolute',
    top: '50%',
    pointerEvents: 'none',
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 15,
    marginLeft: 20,
    textAlign: 'left',
  },
  uploadImageContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    paddingLeft: 14,
  },
  uploadImageIcon: {
    height: 24,
    width: 24,
  },
  uploadImageIconWrapper: {
    alignItems: 'center',
    backgroundColor: primaryBlue,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    margin: 11,
    width: 40,
  },
  uploadImageTitle: {
    color: primaryBlue,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 10,
  },
  row: {
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    gap: 20,
    backgroundColor: Neutrals.shade800,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  border: {
    borderRadius: 80,
    backgroundColor: Brand.primaryDark100,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  chooseBorder: {
    borderRadius: 80,
    backgroundColor: Neutrals.shade800,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  trashIcon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
  trashContainer: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Neutrals.shade800,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: BorderRadius.full,
  },
  noPointerEvents: { pointerEvents: 'none' },
});

export default ChatUnitBackgroundImagePicker;
