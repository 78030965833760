import React, { type FC, type ReactNode } from 'react';
import {
  Pressable,
  type PressableProps,
  Text,
  type TextStyle,
  type ViewStyle,
} from 'react-native';

import { useTheme } from '@/domain/theme';
import openLink from '@/utilities/inAppBrowser';

interface Props extends PressableProps {
  href: string;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
}

export const Link: FC<Props> = ({
  children,
  containerStyle,
  href,
  textStyle,
  ...props
}) => {
  const {
    theme: {
      terms: { link: styles },
    },
  } = useTheme();

  return (
    <Pressable
      role="link"
      onPress={() => openLink(href)}
      style={[styles.container, containerStyle]}
      {...props}>
      <Text style={[styles.text, textStyle]}>{children as ReactNode}</Text>
    </Pressable>
  );
};
