import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { getStyles } from './styles';

import type { TableColor } from '@/utilities/constants/table-colors';

type Props = {
  color: TableColor;
  isWhiteMode?: boolean;
  onPressColor: (color: TableColor) => void;
  selected: boolean;
};

const ColorItem = ({ color, isWhiteMode, onPressColor, selected }: Props) => {
  const onPress = () => onPressColor(color);
  const styles = getStyles(selected, color, isWhiteMode);

  return (
    <View style={[styles.container]}>
      <TouchableOpacity onPress={onPress} style={styles.selectedOverlay}>
        <View style={styles.item} />
      </TouchableOpacity>
    </View>
  );
};

export default ColorItem;
