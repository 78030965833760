import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBell = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M58.701 85.249a8.702 8.702 0 0 1-17.402 0 3.052 3.052 0 0 0-3.05-3.051 3.052 3.052 0 0 0-3.051 3.051c0 8.175 6.627 14.802 14.802 14.802s14.802-6.627 14.802-14.802a3.052 3.052 0 0 0-3.051-3.051 3.052 3.052 0 0 0-3.05 3.051M19.092 45.8l-.063.576c-.043.265-.106.604-.199 1.005a20.165 20.165 0 0 1-1.295 3.77c-1.406 3.09-4.019 6.892-8.746 10.008-4.395 2.896-5.794 7.053-5.794 9.547 0 2.555 1.056 3.639 1.449 4.059.249.267 1.098 1.67 4.345 1.67h82.422c3.252 0 4.101-1.408 4.351-1.676.397-.425 1.444-1.51 1.444-4.053 0-2.495-1.4-6.65-5.794-9.547l-.001-.001c-4.727-3.115-7.341-6.917-8.746-10.007a20.121 20.121 0 0 1-1.295-3.771l-.263-1.551V30.605C80.907 13.796 67.135.051 50 .051c-17.136 0-30.908 13.745-30.908 30.554zm53.693.574.19 1.442.268 1.398a28.31 28.31 0 0 0 1.817 5.305c1.881 4.138 5.344 9.26 11.674 13.432.173.114.335.23.488.349H12.778l.488-.348c6.33-4.172 9.793-9.295 11.674-13.433a28.223 28.223 0 0 0 1.816-5.305l.304-1.577.141-1.101.027-.654V30.605C27.228 18.246 37.4 8.187 50 8.187c12.599 0 22.772 10.059 22.772 22.418v15.44z" />
  </Svg>
);
export default SvgBell;
