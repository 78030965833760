import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import LeaveTableModal from './LeaveTableModal';
import { SettingsSubtitle } from './SettingsSubtitle';

import type { ChatUnitUser } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Greys, Status } from '@/ui/common/colors';

type Props = {
  chatUnitTitle: string;
  myChatUser?: ChatUnitUser;
};

const DangerZone = ({ chatUnitTitle, myChatUser }: Props) => {
  const { translate } = useTranslations();
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <View>
      <View style={styles.divider} />
      <SettingsSubtitle
        subtitle={translate('table_danger_zone_explanation')}
        title={translate('table_danger_zone_label')}
      />
      <Pressable
        style={styles.leaveButton}
        onPress={() => setModalVisible(true)}>
        <AppText color={Status.danger100} size={14} type="primary500">
          {translate('table_leave_label')}
        </AppText>
      </Pressable>
      <LeaveTableModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        tableName={chatUnitTitle}
        chatUser={myChatUser}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  divider: {
    height: 30,
  },
  dangerZoneTitle: {
    marginBottom: 5,
  },
  dangerDescription: {
    marginBottom: 15,
  },
  leaveButton: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: Greys.shade0,
    alignItems: 'flex-start',
  },
});

export default DangerZone;
