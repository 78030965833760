/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Background } from './Background';
import { Headline } from './Headline';
import { Terms } from './Terms';
import { TextSeparator } from './TextSeparator';

import SecureIcon from '@/assets/svg/secureIconWhite.svg';
import { Colors } from '@/domain/theme/Colors';
import { Button } from '@/domain/theme/components/Button';
import useNavigation from '@/hooks/useNavigation';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { getConstant } from '@/utilities/constants/getConstant';
import openLink from '@/utilities/inAppBrowser';
import { web } from '@/utilities/platform';

export const ExclusiveAccessIntro = () => {
  const { translate } = useTranslations();
  const { navigate } = useNavigation();
  useStatusBarHandler('light-content');

  return (
    <>
      <Background />

      <View style={{ flex: 1, justifyContent: 'center' }}>
        <View style={{ alignItems: 'center', flex: 1 }}>
          <Headline />

          <View style={styles.container}>
            <View>
              <SecureIcon />
            </View>

            <Text style={styles.subtitle}>
              {translate('exclusive_access_intro_subtitle')}
            </Text>

            <View style={{ gap: 20, marginTop: 30, width: '100%' }}>
              <View>
                <Button
                  i18nKey="exlusive_access_i_have_key_button"
                  onPress={() => navigate('SignIn', { newAccount: true })}
                  variant="feature"
                />
              </View>

              <TextSeparator label="OR" />

              <View>
                <Button
                  i18nKey="exclusive_access_wait_list_button_title"
                  onPress={() => openLink(getConstant('waitlistUrl') as string)}
                  variant="secondary"
                  containerStyle={{ borderColor: Colors.neutral0 }}
                  textStyle={{ color: Colors.neutral0 }}
                />
              </View>

              <View>
                <Button
                  i18nKey="exclusive_access_i_already_have_account_button_label"
                  onPress={() => navigate('SignIn')}
                  variant="link"
                  textStyle={{ color: Colors.neutral0 }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginVertical: 30 }}>
        <Terms variant="light" />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: web ? 'flex-start' : 'flex-end',
    maxWidth: 400,
    paddingTop: 30,
    paddingHorizontal: 40,
  },
  subtitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: web ? 24 : 18,
    marginBottom: 15,
    marginTop: 10,
    textAlign: 'center',
  },
});
