import React from 'react';
import { Dimensions, Pressable, StyleSheet } from 'react-native';

import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import { web } from '@/utilities/platform';

type Props = {
  text: string;
  onPress: () => void;
};

function OnboardingSecondaryDarkButton({ text, onPress }: Props) {
  return (
    <Pressable style={styles.button} onPress={onPress}>
      <AppText
        type="primary700"
        size={17}
        textAlign="center"
        color={Colors.neutral80}>
        {text}
      </AppText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: web ? '100%' : Dimensions.get('window').width - 60,
    borderWidth: 2,
    borderColor: Colors.neutral80,
    backgroundColor: Colors.neutral0,
  },
});

export default OnboardingSecondaryDarkButton;
