import { logger } from '@/services/logger/logger';

type NewUser = {
  avatar?: string;
  phoneNumber: string;
  username: string;
  userId: string;
};

// invites param is type "any" to account for graphQL response having null as a possible value for every level of the response data.
export const extractContactsFromInvites = (invites?: any): NewUser[] => {
  const results: NewUser[] = [];
  if (!invites || !Array.isArray(invites)) {
    return results;
  }

  invites.map((invite: any) => {
    if (!invite?.inviteeInfo || !Array.isArray(invite.inviteeInfo)) {
      return;
    }
    invite.inviteeInfo.map((inviteeJSON: string) => {
      try {
        const invitee = JSON.parse(JSON.parse(inviteeJSON));
        if (invitee.phone && invitee.name && invitee.userId) {
          results.push({
            ...(invitee.avatar ? { avatar: invitee.avatar } : {}),
            phoneNumber: invitee.phone,
            username: invitee.name,
            userId: invitee.userId,
          });
        } else {
          throw new Error('Invalid invitee');
        }
      } catch (e) {
        logger.error('Invalid invitee:', inviteeJSON);
      }
    });
  });

  return results;
};
