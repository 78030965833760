import { Image, type ImageProps, type ImageStyle } from 'expo-image';
import React, { memo } from 'react';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import useNetworkImage from '../image/useNetworkImage';

import { Colors } from '@/domain/theme/Colors';
import { getColorForName } from '@/domain/user/functions/getColorForName';
import { getAllAvatarsMap } from '@/services/assetService';
import { AppText } from '@/ui/app/elements';
import { Greys } from '@/ui/common/colors';
import type { Fonts } from '@/ui/common/styles';
import type { Color } from '@/ui/common/types/color';
import { web } from '@/utilities/platform';

type StyleProps = {
  /* The background color of the display name initials */
  bgColor?: Color | Colors;
  /* The size of the avatar where width === height */
  size?: number;
  style?: ImageStyle;
  fontType?: keyof typeof Fonts;
};

type Props = {
  /* The full display name of the user for loading or error states */
  displayName: string;
  fontSize?: number;
  /* The user avatar */
  imageKey?: string;
} & StyleProps;

const DEFAULT_SIZE = 36;
const DEFAULT_FONT_SIZE = 12;

/**
 * Create a map of local avatars for constant time lookups
 * This is used to check if the avatar is a local avatar or not
 * in constant time
 */
const AVATARS = getAllAvatarsMap();

const AppAvatar = memo((props: Props) => {
  const { imageKey, bgColor, displayName } = props;

  /**
   * This happens when the user is not a cognito user and has no profile
   * For instance, if the user is being invited to a room and they are not a cognito user
   * The user will have no profile and no avatar since the system does not know who they are
   * so the user will have a placeholder avatar
   */
  if (!imageKey) {
    return (
      <Placeholder
        {...props}
        // If the user has no avatar, the user will have a random color to represent them in the app
        // unless a specific color is provided
        bgColor={bgColor || getColorForName(displayName)}
      />
    );
  }

  /**
   * This happens when the user is not a cognito user and has no profile
   * The user will have a random color to represent them in the app
   * The color is represented by a string that starts with a hash, i,e, a hex color
   * Said color is a random color that is generated and stored in the database in
   * the backend
   */
  if (imageKey.startsWith('#')) {
    // Use the specific color if provided, otherwise use the color which comes from the server
    return <Placeholder {...props} bgColor={bgColor || (imageKey as Color)} />;
  } else {
    // Check if the avatar is a local avatar
    // Lookups are O(1) so this is a constant time operation to check if the avatar is local
    const localAvatar = AVATARS.get(imageKey);

    // If a local avatar is found, use the local avatar
    if (localAvatar) {
      const url = web
        ? `/assets/avatars/${localAvatar.fileName}`
        : localAvatar.url;

      return <ChosenImageAvatar source={url} {...props} />;
    } else {
      // If the avatar is not a local avatar, use the network avatar
      // However, the network avatar will be fetched and cached and
      // that takes time, so the network avatar will fallback to the placeholder
      // until the remote avatar image is downloaded from the url
      return <NetworkAvatar {...props} />;
    }
  }
});

const NetworkAvatar = memo((props: Props) => {
  const { imageKey, displayName, fontSize = DEFAULT_FONT_SIZE } = props;
  const { error, loaded, localUri } = useNetworkImage(imageKey);

  const styles = useStyles(props);

  if (error || !loaded) {
    return <Placeholder displayName={displayName} fontSize={fontSize} />;
  }

  return (
    <Image
      cachePolicy="memory-disk"
      source={{ uri: localUri, cacheKey: imageKey }}
      style={styles.avatar}
    />
  );
});

type ChosenImageAvatarProps = {
  source: ImageProps['source'];
} & StyleProps;

const ChosenImageAvatar = (props: ChosenImageAvatarProps) => {
  const styles = useStyles(props);

  return (
    <Image source={props.source} style={styles.avatar} contentFit="cover" />
  );
};

type PlaceholderProps = {
  fontSize?: number;
  displayName: string;
} & StyleProps;

const Placeholder = (props: PlaceholderProps) => {
  const {
    displayName,
    fontSize = DEFAULT_FONT_SIZE,
    fontType = 'primary700',
  } = props;
  const styles = useStyles(props);
  return (
    <View style={styles.avatar}>
      <AppText
        size={fontSize}
        type={fontType}
        textAlign="center"
        color={Colors.neutral0}>
        {getInitials(displayName)}
      </AppText>
    </View>
  );
};

const useStyles = ({
  size = DEFAULT_SIZE,
  bgColor = Greys.shade200,
  style,
}: StyleProps) => {
  return useMemo(() => {
    return StyleSheet.create({
      avatar: {
        width: size,
        height: size,
        borderRadius: size / 2,
        overflow: 'hidden',
        backgroundColor: bgColor,
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      },
    });
  }, [bgColor, size, style]);
};

/**
 * Get the initials of the invitee
 * @param name Name of the invitee
 * @returns The initials of the invitee in uppercase
 * @example
 * getInitials('John Doe') // JD
 * getInitials('John') // J
 */
function getInitials(name = '') {
  if (!name) {
    return '';
  }

  const [firstName, lastName] = name.split(' ');
  const firstInitial = firstName[0];
  const lastInitial = lastName && lastName[0];
  return `${firstInitial}${lastInitial ? lastInitial : ''}`.toUpperCase();
}

export default memo(AppAvatar);
