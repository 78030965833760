import React, { useCallback, useState } from 'react';
import {
  Dimensions,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated';

import Section from '@/components/section/Section';
import { Colors } from '@/domain/theme/Colors';
import type { MainStackScreenProps } from '@/navigation/types';
import { AppText, Spacer } from '@/ui/app';
import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { useNavigation, useRoute } from '@react-navigation/native';
import AvatarList from './AvatarList';
import OnboardingNextButton from './OnboardingNextButton';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedAvatar?: string;
};

export default function OnboardingChooseAvatarModal({
  open,
  setOpen,
  selectedAvatar = '',
}: Props) {
  const navigation =
    useNavigation<
      MainStackScreenProps<'OnboardingCreateProfile'>['navigation']
    >();
  const route =
    useRoute<MainStackScreenProps<'OnboardingCreateProfile'>['route']>();

  const [selected, setSelected] = useState(selectedAvatar);

  const handleSelectedAvatar = useCallback((avatar: string) => {
    setSelected(currSelected => (currSelected === avatar ? '' : avatar));
  }, []);

  return (
    <Modal animationType="fade" transparent visible={open}>
      <View
        onTouchStart={() => setOpen(false)}
        style={styles.background}
        // @ts-ignore: This adds it to the div element on web and works as expected.
        onClick={() => setOpen(false)}>
        <Animated.View
          entering={SlideInDown}
          exiting={SlideOutDown}
          style={styles.sheet}
          onTouchStart={e => e.stopPropagation()}>
          <View style={styles.container}>
            <Section ph={40} mt={20}>
              <AppText size={28} type="primary800">
                Choose an avatar
              </AppText>
            </Section>
            <Spacer height={10} />
            <Section ph={20}>
              <AvatarList
                selectedAvatarName={selected}
                onSelectAvatar={handleSelectedAvatar}
              />
            </Section>
            <Section ph={40} mb={40} mt={30}>
              <OnboardingNextButton
                onPress={async () => {
                  navigation.navigate({
                    name: 'OnboardingCreateProfile',
                    params: {
                      selectedAvatar: selected,
                      stepNumber: 2,
                      totalSteps: 4,
                      onboardingType: route?.params?.onboardingType,
                    },
                    merge: true,
                  });
                  setOpen(false);
                }}
                text={'Choose avatar'}
              />
              <Spacer height={20} />
              <Pressable onPress={() => setOpen(false)}>
                <AppText size={17} type="primary700" textAlign="center">
                  Cancel
                </AppText>
              </Pressable>
            </Section>
          </View>
        </Animated.View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sheet: {
    alignSelf: 'center',
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.lg,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '90%',
    maxWidth: 600,
    width: Dimensions.get('window').width - 20,
    marginBottom: 20,
    ...Platform.select({
      default: {
        // For Android, adjust as necessary
        elevation: 20,
      },
      ios: {
        shadowColor: Greys.shade999,
        shadowOffset: {
          height: 10,
          width: 1, // You can adjust the height to control the spread
        },
        shadowOpacity: 0.8, // This approximates the 20% black at the end of your gradient
        shadowRadius: 20, // This controls the blur radius
      },
    }),
  },
  press: {
    backgroundColor: Colors.neutral0,
    borderRadius: BorderRadius.full,
    ...Platform.select({
      default: {
        shadowColor: Colors.neutral100,
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
    }),
  },
  container: {
    borderRadius: 18,
    backgroundColor: Colors.neutral0,
  },
});
