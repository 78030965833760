import { useCallback, useEffect, useState } from 'react';

import { useTableStore } from '@/domain/table/state/useTableStore';
import { getChatUnit } from '@/services/datastore/chatUnit/getChatUnit';
import { updateChatUnitDetails as dataStoreUpdateChatUnitDetails } from '@/services/datastore/chatUnit/updateTableDetails';
import { updateTableUrl as dataStoreUpdateTableUrl } from '@/services/datastore/chatUnit/updateTableUrl';
import { logger } from '@/services/logger/logger';
import type { ChatUnit } from '@/services/types';

export const useChatUnit = (id: string) => {
  const chatUnit = useTableStore(state => state.getTable(id));
  const upsertChatUnits = useTableStore(state => state.upsertTables);

  const [loading, setLoading] = useState(!chatUnit);

  useEffect(() => {
    const getAndSetChatUnitData = async () => {
      try {
        if (!chatUnit?.id) {
          setLoading(true);
          const chatUnitFound = await getChatUnit(id);

          if (chatUnitFound) {
            upsertChatUnits([chatUnitFound]);
          }
        }
      } catch (e) {
        logger.error('useChatUnit::Error :', e);
      } finally {
        setLoading(false);
      }
    };

    getAndSetChatUnitData();
  }, [chatUnit?.id, id, upsertChatUnits]);

  const updateTableUrl = useCallback(
    (url: string) => {
      if (chatUnit) {
        dataStoreUpdateTableUrl(chatUnit, url);
      }
    },
    [chatUnit],
  );

  const updateChatUnitDetails = useCallback(
    (details: Partial<ChatUnit>) => {
      if (chatUnit) {
        dataStoreUpdateChatUnitDetails(chatUnit.id, details);
      }
    },
    [chatUnit],
  );

  return {
    loading,
    chatUnit,
    updateChatUnitDetails,
    updateTableUrl,
    upsertTable: upsertChatUnits,
  };
};
