import { Colors } from '@/domain/theme/Colors';
import { getAvatars } from '@/services';
import type { LocalAvatar } from '@/types';
import { Spacer } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
// AvatarList.tsx
import type React from 'react';
import { useCallback } from 'react';
import { FlatList, Image, Platform, Pressable, StyleSheet } from 'react-native';

const AVATARS_LIST = getAvatars();

type AvatarListProps = {
  selectedAvatarName: string;
  onSelectAvatar: (avatar: string) => void;
};

const AvatarList: React.FC<AvatarListProps> = ({
  selectedAvatarName,
  onSelectAvatar,
}) => {
  const renderItem = useCallback(
    ({ item }: { item: LocalAvatar }) => {
      const isSelected = selectedAvatarName === item.name;
      if (isSelected) {
        return (
          <Pressable
            style={styles.selectedItemContainer}
            onPress={() => onSelectAvatar(item.name)}>
            <Image source={item.url} style={styles.img} />
          </Pressable>
        );
      }
      return (
        <Pressable
          style={styles.press}
          onPress={() => onSelectAvatar(item.name)}>
          <Image source={item.url} style={styles.img} />
        </Pressable>
      );
    },
    [onSelectAvatar, selectedAvatarName],
  );

  return (
    <>
      <FlatList
        data={AVATARS_LIST}
        numColumns={web ? 7 : 5}
        contentContainerStyle={styles.contentContainer}
        ItemSeparatorComponent={ItemSeparator}
        keyExtractor={keyExtractor}
        ListFooterComponent={web ? undefined : Footer}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        renderItem={renderItem}
        style={styles.list}
        fadingEdgeLength={100}
        columnWrapperStyle={styles.colWrapper}
      />
    </>
  );
};

function Footer() {
  return <Spacer height={120} />;
}

const ItemSeparator = () => <Spacer height={20} />;

const keyExtractor = (item: LocalAvatar) => item.id.toString();

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 24,
    backgroundColor: Colors.neutral0,
  },
  img: {
    width: 50,
    height: 50,
    borderRadius: 50,
    alignSelf: 'center',
  },
  list: {
    backgroundColor: Colors.neutral0,
  },
  colWrapper: {
    gap: 20,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: web ? undefined : 'center',
  },
  gradient: { height: 24, width: '100%', position: 'absolute', bottom: 115 },
  selectedItemContainer: {
    padding: 4,
    backgroundColor: Colors.neutral0,
    borderColor: Colors.primaryDark,
    borderRadius: BorderRadius.full,
    borderWidth: 4,
    marginHorizontal: -8,
    marginVertical: -8,
  },
  press: {
    backgroundColor: Colors.neutral0,
    borderRadius: BorderRadius.full,
    ...Platform.select({
      default: {
        shadowColor: Colors.neutral100,
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
    }),
  },
});

export default AvatarList;
