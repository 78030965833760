import React, { type FC, type ReactNode, useEffect, useState } from 'react';
import { type ColorSchemeName, useColorScheme } from 'react-native';

import { ThemeService } from '../services/ThemeService';
import { type Theme, themes } from '../themes';
import type { SchemeName } from '../types';

import { ThemeContext } from './ThemeContext';
import { DEFAULT_THEME } from './constants';

interface Props {
  children?: ReactNode;
}

export const ThemeProvider: FC<Props> = ({ children }) => {
  const [scheme, setScheme] = useState<SchemeName>(DEFAULT_THEME);
  const [preference, setPreference] = useState<ColorSchemeName>(null);
  const [theme, setTheme] = useState<Theme>(themes.light);
  const deviceColorScheme = useColorScheme();

  useEffect(() => {
    const init = async () => {
      const themePreference = await ThemeService.current();

      const initialScheme =
        themePreference ?? deviceColorScheme ?? DEFAULT_THEME;

      setPreference(themePreference);
      setScheme(initialScheme);
      setTheme(themes[initialScheme]);
    };

    init().catch(console.error);
  }, [deviceColorScheme]);

  const switchScheme = (updatedScheme: ColorSchemeName): void => {
    setPreference(updatedScheme);

    if (updatedScheme == null) {
      const detected = deviceColorScheme ?? DEFAULT_THEME;
      setScheme(detected);
      setTheme(themes[detected]);
      return;
    }

    setScheme(updatedScheme);
    setTheme(themes[updatedScheme]);
  };

  return (
    <ThemeContext.Provider
      value={{
        preference,
        scheme,
        switchScheme,
        theme,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};
