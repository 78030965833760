/**
 * A palette of colors that are most commonly used
 * in the app. These colors are used in the app
 * components like background, borders, text,
 * dark and light gradients, etc. These colors are
 * expected to be used in the app quite often and
 * need to be strongly enforced.
 */

enum Neutrals {
  shade100 = '#ebeded',
  shade200 = '#d4d6d6',
  shade300 = '#b9bdbd',
  shade400 = '#999e9e',
  shade500 = '#7c7f80',
  shade600 = '#5d6161',
  shade700 = '#3f4242',
  shade800 = '#262929',
  shade900 = '#111212',
}

export default Neutrals;
