import { BlurView } from '@react-native-community/blur';
import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useShallow } from 'zustand/react/shallow';

import { MembersGrid } from './MembersGrid';
import { MembersList } from './MembersList';
import { Toolbar } from './Toolbar';

import CloseButtonIcon from '@/assets/svg/close-button.svg';
import { useTableStore } from '@/domain/table/state/useTableStore';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import type { ListViewMode } from '@/services/types';
import { web } from '@/utilities/platform';

type Props = {
  onClose: () => void;
  chatUnitId: string;
};

const AnimatedView = Animated.createAnimatedComponent(View);

export const Members = ({ onClose, chatUnitId }: Props) => {
  const table = useTableStore(useShallow(state => state.getTable(chatUnitId)));
  const [viewMode, setViewMode] = useState<ListViewMode>('grid');
  const tableUsers = useTableUsersStore(
    useShallow(state => state.getTableUsersForTable(chatUnitId)),
  );

  if (!table) {
    return null;
  }

  const MembersView = viewMode === 'grid' ? MembersGrid : MembersList;

  return (
    <AnimatedView entering={FadeIn} exiting={FadeOut} style={styles.container}>
      <View style={styles.topActions}>
        <Pressable onPressOut={() => onClose()}>
          <CloseButtonIcon />
        </Pressable>
      </View>

      <Toolbar
        count={tableUsers.length}
        setViewMode={setViewMode}
        viewMode={viewMode}
      />

      <MembersView tableUsers={tableUsers} />
      {!web && <BlurView style={styles.blurredBackground} />}
    </AnimatedView>
  );
};

const styles = StyleSheet.create({
  blurredBackground: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1,
  },
  container: {
    bottom: 0,
    left: 0,
    paddingHorizontal: 10,
    paddingVertical: 20,
    position: 'absolute',
    right: 0,
    top: 120,
    ...(web && { backdropFilter: 'blur(20px)' }),
  },
  topActions: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
