import React, { type FC } from 'react';
import Svg, { Path } from 'react-native-svg';

import type { Icon } from '..';

import { useTheme } from '@/domain/theme';
import { Size } from '@/domain/theme/util/Size';

export const TableBackIcon: FC<Icon> = ({ color, size = Size.L }) => {
  const {
    theme: { icon: styles },
  } = useTheme();

  return (
    <Svg
      accessibilityLabel="Table back icon"
      height={size}
      width={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M18.756 5.327a3.25 3.25 0 012.488 0l8.252 3.418a3.25 3.25 0 011.759 1.759l3.418 8.252a3.25 3.25 0 010 2.488l-3.418 8.252a3.25 3.25 0 01-1.759 1.759l-8.252 3.418a3.25 3.25 0 01-2.488 0l-8.252-3.418a3.25 3.25 0 01-1.759-1.759l-3.418-8.252a3.25 3.25 0 010-2.488l3.418-8.252a3.25 3.25 0 011.759-1.759l8.252-3.418z"
        stroke={color ?? styles.color}
        strokeWidth={1.5}
      />

      <Path
        d="M22 14l-6 6 6 6"
        stroke={color ?? styles.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
