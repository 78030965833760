import React from 'react';

import { ActivityIcon } from './Icons/ActivityIcon';

import { Icon } from '@/ui/app/elements';
import { Brand, Neutrals } from '@/ui/common/colors';
import { DiscoverIcon } from '../components/DiscoverIcon';

type Props = {
  isFocused: boolean;
  name: string;
  size?: number;
};

export const TabBarIcon = ({ isFocused, name, size }: Props) => {
  if (name === 'Activity') {
    return <ActivityIcon isActive={isFocused} />;
  }
  if (name === 'DMs' || name === 'Rooms' || name === 'Tables') {
    const key = name === 'DMs' ? 'Bubble' : name;
    return (
      <Icon
        fill={isFocused ? Brand.primary100 : Neutrals.shade700}
        iconKey={key}
        size={size || 25}
      />
    );
  }
  if (name === 'Discover') {
    return <DiscoverIcon isActive={isFocused} />;
  }
  return null;
};
