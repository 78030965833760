import * as React from 'react';
import Svg, { LinearGradient, Stop, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgLightningGradient = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <LinearGradient
      gradientTransform="matrix(0 92.5926 -92.5926 0 50 3.704)"
      gradientUnits="userSpaceOnUse"
      id="lightningGradient_svg__a"
      x1={0}
      x2={1}
      y1={0}
      y2={0}>
      <Stop offset={0} stopColor="#17d3e5" />
      <Stop offset={1} stopColor="#07818c" />
    </LinearGradient>
    <Path
      d="M7.853 53.792a3.124 3.124 0 0 0 1.764 5.495l32.437 2.703L25.6 94.899a3.124 3.124 0 0 0 4.819 3.778l61.728-52.469a3.125 3.125 0 0 0-1.764-5.495L57.946 38.01 74.4 5.101a3.125 3.125 0 0 0-4.819-3.778zm9.778-.109 44.331-37.682-11.671 23.342a3.125 3.125 0 0 0 2.536 4.512l29.542 2.462-44.331 37.682 11.671-23.342a3.125 3.125 0 0 0-2.536-4.512z"
      fill="url(#lightningGradient_svg__a)"
    />
  </Svg>
);
export default SvgLightningGradient;
