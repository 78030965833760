import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {
  CodeField,
  Cursor,
  type RenderCellOptions,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import SecureIcon from '@/assets/svg/secure_icon.svg';
import BackButton from '@/components/BackButton';
import MainButton from '@/components/MainButton';
import MarkDown from '@/components/MarkDown';
import SecondaryButton from '@/components/SecondaryButton';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import AuthService from '@/domain/auth/AuthService';
import useNavigation from '@/hooks/useNavigation';
import useTimer from '@/hooks/useTimer';
import type { MainStackScreenProps } from '@/navigation/types';
import { primaryRed } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { DURATION_FOR_RESEND_SMS_VERIFICATION_CODE } from '@/utilities/constants';

const CELL_COUNT = 6;

const ForgotPasswordSecurityCode = ({
  navigation,
  route: {
    params: { phoneNumber },
  },
}: MainStackScreenProps<'ForgotPasswordSecurityCode'>) => {
  const { bottom, top } = useSafeAreaInsets();
  const { goBack } = useNavigation();
  const { translate } = useTranslations();

  const { currentTime, isCompleted, startTimer } = useTimer({
    initialMilliseconds: DURATION_FOR_RESEND_SMS_VERIFICATION_CODE,
  });

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const ref = useBlurOnFulfill({ cellCount: CELL_COUNT, value });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    setValue,
    value,
  });
  const isValid = value?.length === CELL_COUNT;

  const renderCell = ({ index, isFocused, symbol }: RenderCellOptions) => {
    return (
      <View key={index} onLayout={getCellOnLayoutHandler(index)}>
        <Text key={index} style={[styles.cell, isFocused && styles.focusCell]}>
          {symbol || (isFocused ? <Cursor /> : null)}
        </Text>
      </View>
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'ForgotPasswordNewPassword',
            params: {
              code: value,
              phoneNumber,
            },
          },
        ],
      });
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendSms = async () => {
    try {
      startTimer();
      await AuthService.handleForgotPasswordRequest(
        phoneNumber.replace(/\s/g, '') ?? '',
      );
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <KeyboardAwareScrollView
      bounces={false}
      contentContainerStyle={[
        styles.contentContainer,
        { paddingBottom: bottom },
      ]}
      showsVerticalScrollIndicator={false}
      style={[styles.container, { paddingTop: top }]}>
      <View style={styles.content}>
        <View style={styles.topContainer}>
          <View style={styles.header}>
            <BackButton onPress={goBack} />
          </View>
          <SecureIcon style={styles.logo} />
          <View style={styles.markdown}>
            <MarkDown style={{ body: styles.description }}>
              {translate('forgot_password_verification_code_title', {
                phoneNumber,
              })}
            </MarkDown>
          </View>
          <Text
            children={translate('sms_enter_code_text')}
            style={styles.phoneCodeLabel}
          />
          <CodeField
            ref={ref}
            {...props}
            cellCount={CELL_COUNT}
            inputMode="numeric"
            onChangeText={setValue}
            renderCell={renderCell}
            rootStyle={styles.codeFieldRoot}
            textContentType="oneTimeCode"
            value={value}
          />
          <Text style={styles.errorTitle}>{error ? error : ' '}</Text>
        </View>
        <View style={styles.bottomContainer}>
          <MainButton
            disabled={isSubmitting || !isValid}
            onPress={handleSubmit}
            textStyle={styles.mainButtonText}
            title={translate('common_submit_text')}
          />
          <SecondaryButton
            disabled={isSubmitting || !isCompleted}
            onPress={resendSms}
            style={styles.secondaryButton}
            textStyle={isCompleted ? {} : styles.timerButtonStyle}
            title={
              isCompleted
                ? translate('resend_code_text')
                : translate('sms_resend_timer', {
                    time: currentTime / 1000,
                  })
            }
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  cell: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 8,
    color: Greys.shade800,
    fontFamily: 'OpenSans-Regular',
    fontSize: 35,
    fontWeight: '400',
    height: 59,
    justifyContent: 'center',
    lineHeight: 38,
    marginHorizontal: 2,
    overflow: 'hidden',
    paddingVertical: wp(3.5),
    textAlign: 'center',
    width: 44,
  },
  codeFieldRoot: { paddingHorizontal: wp('10') },
  container: { flexGrow: 1 },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  description: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 25,
    marginTop: 20,
    textAlign: 'center',
  },
  errorTitle: {
    color: primaryRed,
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 23,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
  header: {
    height: 40,
    justifyContent: 'flex-end',
    paddingLeft: 2,
    width: '100%',
  },
  inputTitle: {
    marginTop: 30,
    textAlign: 'center',
  },
  logo: { height: 70, marginTop: 10, width: 230 },
  mainButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
  },
  markdown: { marginHorizontal: 40 },
  phoneCodeLabel: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 10,
    marginTop: 30,
    textAlign: 'center',
  },
  secondaryButton: {
    marginTop: 10,
  },
  secondaryButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  text: { marginHorizontal: 20, textAlign: 'center' },
  timerButtonStyle: {
    color: Greys.shade500,
    fontWeight: '400',
  },
  title: { marginTop: 27 },
  titleContainer: { marginTop: 20, paddingHorizontal: wp('10') },
  topContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    width: '100%',
  },
});

export default ForgotPasswordSecurityCode;
