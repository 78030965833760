import { useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';
import RefreshService from '../table/functions/RefreshService';

export default function useCMSRefresh() {
  const appState = useRef(AppState.currentState);

  // Fetch whenever the app is in the foreground
  // This is to ensure that the content is up to date
  // and the user is always seeing the latest content
  // This is also used to refresh the critical announcement modal
  // whenever the app is brought to the foreground
  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        RefreshService.refreshCMSPosts();
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);
}
