import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

type Props = {
  variant: 'new' | 'personal';
};

export default function OnboardingWorkspaceItemBubble({ variant }: Props) {
  const { translate } = useTranslations();

  const containerStyles = useMemo(() => {
    return [
      styles.bubbleContainer,
      {
        backgroundColor:
          variant === 'personal' ? Colors.neutral80 : Colors.rasberry,
      },
    ];
  }, [variant]);

  return (
    <View style={containerStyles}>
      <AppText size={12} type="primary700" color={Colors.neutral0}>
        {variant === 'personal' ? translate('personal') : translate('new')}
      </AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  bubbleContainer: {
    borderTopLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 9,
    position: 'absolute',
    top: 1,
    right: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 6.5,
    paddingTop: 3,
    paddingBottom: 5,
  },
});
