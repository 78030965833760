import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';

import AppAvatar from '@/components/avatar';
import { getName } from '@/domain/user/functions/getName';
import type { ChatUnitUser } from '@/services/types';
import { Greys } from '@/ui/common/colors';

type Props = {
  tableUsers: ChatUnitUser[];
};

const AnimatedView = Animated.createAnimatedComponent(ScrollView);

export const MembersGrid = ({ tableUsers }: Props) => {
  return (
    <AnimatedView
      entering={FadeInRight}
      exiting={FadeOutLeft}
      contentContainerStyle={styles.usersLayout}>
      {tableUsers.map(tableUser => {
        return (
          <View key={tableUser.chatUnitUserId} style={styles.userContainer}>
            <AppAvatar
              key={tableUser.id}
              size={40}
              imageKey={tableUser.avatar}
              displayName={getName(tableUser, 'short')}
            />
            <Text
              style={styles.username}
              numberOfLines={1}
              ellipsizeMode="tail">
              {getName(tableUser, 'short')}
            </Text>
          </View>
        );
      })}
      <View style={styles.tail} />
    </AnimatedView>
  );
};

const styles = StyleSheet.create({
  tail: {
    flexGrow: 1,
  },
  userContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    paddingBottom: 20,
  },
  username: {
    color: Greys.shade0,
    marginTop: 6,
  },
  usersLayout: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});
