import { DataStore } from 'aws-amplify/datastore';
import { ChatUnitUser } from 'src/models';

export const deleteChatUnitUser = async (chatUnitUserId: string) => {
  const existingChatUnitUser = await DataStore.query(
    ChatUnitUser,
    chatUnitUserId,
  );

  if (!existingChatUnitUser) {
    throw new Error(
      `ChatUnitUser with chatUnitUserId ${chatUnitUserId} not found`,
    );
  }

  console.log('Deleting chat unit user', existingChatUnitUser);
  DataStore.delete(existingChatUnitUser);
};
