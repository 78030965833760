import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType } from '../../../../API';
import { useChatUnits } from '../../hooks/useChatUnits';

import { ChatItems } from './ChatItems';

import PrimaryHeader from '@/components/headers/PrimaryHeader';
import RefreshService from '@/domain/table/functions/RefreshService';
import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import { useStatusBarHandler } from '@/hooks/useStatusBar';
import useTranslations from '@/translations/useTranslation';
import { Neutrals } from '@/ui/common/colors';

type Props = {
  route?: {
    params: {
      type: ChatUnitType;
    };
  };
};

export const ChatListScreen = ({ route }: Props) => {
  const type = route?.params.type ?? ChatUnitType.TABLE;

  const { translate } = useTranslations();
  const { chatUnits } = useChatUnits(type);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  useStatusBarHandler('dark-content');
  const setLoading = useLoadingStore(useShallow(state => state.setLoading));

  const onRefresh = useCallback(async () => {
    setLoading('chatUnitList', true);
    setIsRefreshing(true);
    await Promise.all([
      RefreshService.refreshChatUnits(type),
      RefreshService.refreshChatUsers(),
    ]);
    setIsRefreshing(false);
    setLoading('chatUnitList', false);
  }, [setLoading, type]);

  useEffect(() => {
    if (!chatUnits.length) {
      Promise.all([
        RefreshService.refreshChatUnits(type),
        RefreshService.refreshChatUsers(),
      ]).then(() => setIsLoading(false));
      return;
    }
    setIsLoading(false);
  }, [chatUnits.length, type]);

  const title = useMemo(() => {
    return type === ChatUnitType.ROOM
      ? translate('my_rooms_title')
      : type === ChatUnitType.TABLE
        ? translate('my_tables_title')
        : translate('my_dms_title');
  }, [type, translate]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: { backgroundColor: Neutrals.shade100, flex: 1 },
        flex1: {
          flex: 1,
        },
        roomCardsContainer: {
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: tileSpacing / 2,
        },
        scrollViewContentContainer: {
          alignItems: 'stretch',
          minHeight: '100%',
        },
        tile: {
          padding: tileSpacing / 2,
          width: '50%',
        },
        refreshControl: {
          opacity: 0,
        },
      }),
    [],
  );

  return (
    <View style={styles.flex1}>
      <PrimaryHeader rightActionVisible title={title} />
      <ScrollView
        contentContainerStyle={styles.scrollViewContentContainer}
        refreshControl={
          <RefreshControl
            onRefresh={onRefresh}
            refreshing={isRefreshing}
            style={styles.refreshControl}
          />
        }
        style={styles.container}>
        <ChatItems isLoading={isLoading} items={chatUnits} type={type} />
      </ScrollView>
    </View>
  );
};

const tileSpacing = 10;

export default ChatListScreen;
