import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgWhatsapp = (props: SvgProps) => (
  <Svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      clipRule="evenodd"
      d="M12.123 0C5.667 0 .433 5.234.433 11.692c0 2.208.613 4.274 1.677 6.036L0 24l6.472-2.072a11.65 11.65 0 0 0 5.65 1.454c6.458 0 11.692-5.235 11.692-11.692S18.58 0 12.123 0m0 21.423a9.68 9.68 0 0 1-5.355-1.609l-3.74 1.198 1.215-3.615A9.677 9.677 0 0 1 2.39 11.69c.001-5.366 4.366-9.733 9.733-9.733 5.366 0 9.733 4.367 9.733 9.733 0 5.367-4.367 9.733-9.733 9.733m5.481-7.076c-.294-.16-1.733-.937-2.003-1.046-.27-.11-.467-.167-.677.124-.21.29-.808.941-.988 1.133-.181.193-.356.211-.65.05-.293-.16-1.242-.512-2.345-1.57-.857-.824-1.42-1.825-1.582-2.129-.163-.306 0-.46.153-.603.14-.13.312-.338.468-.507.155-.168.21-.29.316-.486.107-.195.064-.37-.005-.523-.07-.152-.612-1.642-.837-2.25-.226-.606-.478-.517-.653-.523-.174-.006-.373-.039-.572-.046a1.093 1.093 0 0 0-.809.343c-.284.289-1.083.982-1.138 2.45-.054 1.468.962 2.928 1.103 3.132.142.204 1.94 3.387 4.929 4.696 2.99 1.309 3.004.91 3.553.88.549-.03 1.792-.656 2.067-1.343.275-.688.296-1.285.227-1.412-.07-.127-.266-.21-.558-.37z"
      fill="#7C7F80"
      fillRule="evenodd"
    />
  </Svg>
);
export default SvgWhatsapp;
