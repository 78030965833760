import LottieView from 'lottie-react-native';
import React from 'react';
import { StyleSheet, View } from 'react-native';

const spinner = require('@/assets/lottie/spinner-light.json');

type Props = {
  hidden?: boolean;
  hideContainer?: boolean;
};

const LottieLoader = ({ hidden, hideContainer }: Props) => {
  if (hidden) {
    return null;
  }
  if (hideContainer) {
    return <LottieView autoPlay loop source={spinner} style={styles.lottie} />;
  }
  return (
    <View style={styles.container}>
      <LottieView autoPlay loop source={spinner} style={styles.lottie} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  lottie: {
    height: 54,
    width: 54,
  },
});
export default LottieLoader;
