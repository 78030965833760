import { calc } from './calc';

export const Size = {
  NONE: 0,
  XXS: calc(0.25),
  XS: calc(0.5),
  S: calc(1),
  M: calc(2),
  L: calc(4),
  XL: calc(8),
  XXL: calc(10),
  calc,
};
