import notifee from '@notifee/react-native';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { app, web } from '@/utilities/platform';

export const useActivities = () => {
  const [totalUnreadCount, loadingMessages] = useMessageStore(
    useShallow(state => [state.getTotalUnreadCount(), state.loadingMessages]),
  );

  const pendingInviteCount = useMyInviteStore(state =>
    state.getPendingInviteCount(),
  );

  // Used for bottom Activity tab bar badge & app icon badge count
  const totalUnreadActivities = totalUnreadCount + pendingInviteCount;

  useEffect(() => {
    if (web) {
      // @ts-ignore: Exists on web
      document.title =
        totalUnreadActivities > 0
          ? `${totalUnreadActivities} unread message${
              totalUnreadActivities > 1 ? 's' : ''
            } - 8seats`
          : '8seats';
    }
  }, [totalUnreadActivities]);

  // TODO: Make this run even when app starts headless
  useEffect(() => {
    if (app) {
      const doIt = async () => {
        await notifee.setBadgeCount(totalUnreadActivities);
      };
      doIt();
    }
  }, [totalUnreadActivities]);

  return {
    totalUnreadActivities,
    loadingMessages,
  };
};
