import React, { useMemo, useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useShallow } from 'zustand/react/shallow';

import AppAvatar from '@/components/avatar';
import { useDMListItem } from '@/domain/chatUnit/hooks/useDMListItem';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import { ActivityItemBadge } from '@/modules/activity/components/ActivityItemBadge';
import { formatActivityDate } from '@/modules/activity/timeHelper';
import type { ChatUnit } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Icon } from '@/ui/app/elements';
import { Brand, Greys, Neutrals } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';

type Props = {
  dm: ChatUnit;
};

export const DMListItem = React.memo(({ dm }: Props) => {
  const {
    date,
    hasMultiThreads,
    displayUsername,
    otherUsersName,
    avatar,
    latestMessage,
    otherUserCount,
    unreadCount,
  } = useDMListItem(dm);

  const { translate } = useTranslations();
  const { navigate } = useNavigation();

  const userId = useUserStore(useShallow(state => state.user?.id));

  const formattedDate = date && formatActivityDate(new Date(date));

  const goToDM = useCallback(
    (id: string) => {
      navigate('ChatView', { chatUnitId: id });
    },
    [navigate],
  );

  const handlePress = useCallback(() => {
    goToDM(dm.id);
  }, [goToDM, dm.id]);

  const OtherUsersName = useMemo(
    () =>
      otherUsersName?.length > 0 ? (
        <AppText style={styles.others}>+ {otherUsersName}</AppText>
      ) : null,
    [otherUsersName],
  );

  const OtherUsersText = useMemo(
    () =>
      otherUserCount > 0 ? (
        <AppText style={styles.others}>
          {'  '}+{otherUserCount}{' '}
          {otherUserCount === 1 ? translate('other') : translate('others')}
        </AppText>
      ) : null,
    [otherUserCount, translate],
  );

  const ThreadsIcon = useMemo(
    () =>
      hasMultiThreads ? (
        <Icon
          fill={Brand.primary75}
          iconKey="Fork"
          size={14}
          style={styles.threadsIcon}
        />
      ) : null,
    [hasMultiThreads],
  );

  const UnreadBadge = useMemo(
    () =>
      unreadCount ? (
        <ActivityItemBadge
          containerStyles={styles.activityCountContainer}
          unreadMessageCount={unreadCount}
        />
      ) : null,
    [unreadCount],
  );

  const showAvatar = otherUserCount === -3;

  return (
    <Pressable onPress={handlePress} style={styles.container}>
      <View style={styles.flexDirectionRow}>
        {showAvatar && (
          <View style={styles.avatarContainer}>
            <AppAvatar
              displayName={displayUsername || ''}
              imageKey={avatar}
              size={40}
            />
          </View>
        )}
        <View style={styles.flex}>
          <View style={styles.headerRow}>
            <View style={styles.headerLeft}>
              {ThreadsIcon}
              <View style={styles.usernamesContainer}>
                <AppText style={styles.messageUser} size={14} type="primary700">
                  {displayUsername}
                </AppText>
                {OtherUsersName}
                {OtherUsersText}
              </View>
            </View>
            <View style={styles.flexDirectionRow}>
              <AppText style={styles.date}>{formattedDate}</AppText>
              {UnreadBadge}
            </View>
          </View>
          <AppText style={styles.title} type="primary600" numberOfLines={1}>
            {dm.title}
          </AppText>
          {latestMessage?.text?.trim()?.length > 0 && (
            <AppText
              style={styles.message}
              numberOfLines={1}
              ellipsizeMode="tail">
              {latestMessage?.userId === userId && (
                <AppText style={[styles.message, styles.you]} type="primary600">
                  You:{' '}
                </AppText>
              )}
              {latestMessage?.text?.trim()}
            </AppText>
          )}
        </View>
      </View>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  activityCountContainer: {
    alignSelf: 'flex-end',
  },
  container: {
    backgroundColor: Greys.shade0,
    borderRadius: BorderRadius.sm,
    marginBottom: 1,
    padding: 10,
  },
  date: {
    color: Neutrals.shade700,
    fontSize: 10,
  },
  headerLeft: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  headerRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  messageUser: {
    marginRight: 5,
  },
  others: {
    color: Neutrals.shade500,
    fontSize: 12,
  },
  threadsIcon: {
    marginRight: 7,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    marginTop: 4,
    paddingRight: 20,
  },
  message: {
    color: Colors.neutral70,
    fontSize: 14,
    marginTop: 4,
    paddingRight: 20,
  },
  you: {
    color: Colors.neutral80,
    fontWeight: '600',
    paddingRight: 0,
  },
  avatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  usernamesContainer: { flexDirection: 'row', alignItems: 'baseline' },
});
