import notifee, { EventType } from '@notifee/react-native';
import { useEffect } from 'react';

import notificationHandler from './notificationHandler';

import { ios } from '@/utilities/platform';

// Used by iphones to detect launch from notification
const useSubscribeToForegroundNotifications = () => {
  useEffect(() => {
    return notifee.onForegroundEvent(({ type, detail }) => {
      if (ios) {
        if (type === EventType.PRESS && detail.notification?.data?.data) {
          notificationHandler(
            detail.notification.data.data as Record<string, any>,
          );
        }
      }
    });
  }, []);
};

export default useSubscribeToForegroundNotifications;
