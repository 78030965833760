import { DataStore } from 'aws-amplify/datastore';

import { Invite } from '../../../models';

import { factory } from '@/services/factory/factory';

export const getInvite = async (id: string) => {
  const results = await DataStore.query(Invite, invite => invite.id.eq(id));
  return factory.invite(results[0]);
};
