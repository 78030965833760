import * as React from 'react';
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

function SvgDeleted(props: SvgProps) {
  return (
    <Svg width={10} height={10} viewBox="0 0 10 10" fill="none" {...props}>
      <Circle cx={5} cy={5} r={5} fill="#262929" />
      <Path d="M3 3l4 4M7 3L3 7" stroke="#FF5050" strokeLinecap="square" />
      <Circle cx={5} cy={5} r={3.5} stroke="#FF5050" />
    </Svg>
  );
}

export default SvgDeleted;
