import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { web } from '@/utilities/platform';
import React from 'react';
import { Linking } from 'react-native';

export default function OnboardingCreateProfileTNC() {
  const { translate } = useTranslations();
  return (
    <AppText size={web ? 17 : 14}>
      {translate('profile_tnc_part_one')}{' '}
      <AppText
        size={web ? 17 : 14}
        color={Colors.primaryLight}
        underline
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/terms-of-use')
        }>
        {translate('tnc')}
      </AppText>
      ,{' '}
      <AppText
        size={web ? 17 : 14}
        color={Colors.primaryLight}
        underline
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/privacy-policy')
        }>
        {translate('pp')}
      </AppText>
      , {translate('and')}{' '}
      <AppText
        size={web ? 17 : 14}
        color={Colors.primaryLight}
        underline
        onPress={() =>
          Linking.openURL('https://legal.8seats.com/end-user-license-agreement')
        }>
        {translate('eula')}
      </AppText>
      .
    </AppText>
  );
}
