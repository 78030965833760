// Reason: Imports not resolved within the design-system folder
// @ts-nocheck
import React, { memo } from 'react';
import { View, type ViewStyle } from 'react-native';

import { Misc } from '@/ui/common/colors';
import type { BorderRadius } from '@/ui/common/styles';

type SpacerProps = {
  width?: ViewStyle['width'];
  height?: ViewStyle['height'];
  bg?: ViewStyle['backgroundColor'];
  borderRadius?: BorderRadius;
  zIndex?: ViewStyle['zIndex'];
};

const Spacer = ({
  width,
  height,
  bg: backgroundColor = Misc.transparent,
  borderRadius,
  zIndex,
}: SpacerProps) => {
  return (
    <View
      style={{
        width,
        height,
        backgroundColor,
        borderRadius,
        zIndex,
      }}
    />
  );
};

export default memo(Spacer);
