import React from 'react';
import { Image, StyleSheet } from 'react-native';

import fileImg from '@/assets/chatview/file.png';
import sceneryImg from '@/assets/chatview/scenery.png';
import { ActionList } from '@/components/actionList/ActionList';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

type Props = {
  open: boolean;
  onClose: () => void;
  onImageChooseOptionPress: () => void;
  onFileChooseOptionPress: () => void;
};

const UploadOptionsSheet = ({
  open,
  onClose,
  onImageChooseOptionPress,
  onFileChooseOptionPress,
}: Props) => {
  const { translate } = useTranslations();

  return (
    <ActionList
      actionButtonTitle={translate('cancel_common_button_title')}
      title={translate('send_a_document')}
      subtitle={translate('document_upload_size_limit')}
      open={open}
      setOpen={onClose}
      items={[
        {
          icon: <Image source={sceneryImg} style={styles.icon} />,
          title: translate('choose_an_image_or_video'),
          onPress: onImageChooseOptionPress,
        },
        {
          icon: <Image source={fileImg} style={styles.icon} />,
          title: translate('upload_file'),
          onPress: onFileChooseOptionPress,
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: Greys.shade999,
  },
});

export default UploadOptionsSheet;
