import { ToastAndroid } from 'react-native';
import RNFetchBlob from 'react-native-blob-util';

import { android } from '../platform';

import { logger } from '@/services/logger/logger';

const downloadImage = async (imageUrl: string) => {
  try {
    // Get the app's specific directory
    const dirs = RNFetchBlob.fs.dirs;
    // Take the image url and split it by the '?' and then by the '/'
    const fileName = `8seats_image.${imageUrl.split('.').pop()}`;
    // saving inside app's cache directory
    const path = `${dirs.LegacyDownloadDir}/${fileName}`;
    // Start the download
    const res = await RNFetchBlob.fs.cp(imageUrl, path);

    // Output the file path of the downloaded file
    logger.debug('The file is saved to:', res, path);

    if (android) {
      ToastAndroid.show(
        'Image saved to downloads: ' + fileName,
        ToastAndroid.LONG,
      );
    }
  } catch (error) {
    logger.error('Error downloading image:', error);
  }
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

export const imageUrlToBase64 = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();

  return await blobToBase64(blob);
};

export default downloadImage;
