import { useNetInfo } from '@react-native-community/netinfo';
import React, { useCallback } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import type { ChatUnitType } from '../../../../../API';

import { ChatUnitIcon } from '@/components/ChatUnitIcon';
import useNavigation from '@/hooks/useNavigation';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { Brand, Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

type Props = {
  hidden?: boolean;
  type: ChatUnitType;
};

const GRADIENT = [Brand.primary75, Brand.primaryDark100];

export const CreateRoomCard = React.memo(({ hidden, type }: Props) => {
  const { translate } = useTranslations();
  const { navigate } = useNavigation();
  const { isInternetReachable } = useNetInfo();

  const handlePress = useCallback(() => {
    if (isInternetReachable) {
      navigate('CreateRoom', { type });
    }
  }, [isInternetReachable, navigate, type]);

  if (hidden) {
    return null;
  }

  return (
    <TouchableOpacity onPress={handlePress} style={styles.createRoomCard}>
      <LinearGradient colors={GRADIENT} style={styles.createRoomGradient}>
        <ChatUnitIcon fill={Greys.shade0} size={40} type={type} />
        <Spacer height={10} />
        <AppText
          textAlign="center"
          size={14}
          color={Greys.shade0}
          type="primary700">
          {translate('create_table_button_title', {
            type: translate(type),
          })}
        </AppText>
        <Spacer height={6} />
        <AppText textAlign="center" size={11} color={Greys.shade0}>
          {translate(`create_${type.toLowerCase()}_card_text`)}
        </AppText>
      </LinearGradient>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  createRoomCard: {
    backgroundColor: Greys.shade200,
    borderRadius: BorderRadius.sm,
    elevation: 5,
    height: 248,
    width: web ? undefined : Dimensions.get('window').width / 2 - 15,
    shadowColor: Greys.shade999,
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    ...(web && { margin: 5, flexShrink: 1, flex: 1, flexGrow: 1 }),
  },
  createRoomGradient: {
    alignItems: 'center',
    borderRadius: BorderRadius.sm,
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
});
