import type { LocalAvatar } from '../../types';

import avatar2 from '@/assets/avatars/Frame-1.png';
import avatar3 from '@/assets/avatars/Frame-3.png';
import avatar4 from '@/assets/avatars/Group-12.png';
import avatar5 from '@/assets/avatars/Group-13.png';
import avatar6 from '@/assets/avatars/Group-14.png';
import avatar7 from '@/assets/avatars/Group-15.png';
import avatar8 from '@/assets/avatars/Group-16.png';
import avatar9 from '@/assets/avatars/Group-17.png';
import avatar11 from '@/assets/avatars/Group-18.png';
import avatar12 from '@/assets/avatars/Group-19.png';
import avatar13 from '@/assets/avatars/Group-20.png';
import avatar14 from '@/assets/avatars/Group-21.png';
import avatar15 from '@/assets/avatars/Group-22.png';
import avatar16 from '@/assets/avatars/Group-23.png';
import avatar17 from '@/assets/avatars/Group-24.png';
import avatar18 from '@/assets/avatars/Group-25.png';
import avatar19 from '@/assets/avatars/Group-26.png';
import avatar20 from '@/assets/avatars/Group-27.png';
import avatar22 from '@/assets/avatars/Group-28.png';
import avatar23 from '@/assets/avatars/Group-29.png';
import avatar25 from '@/assets/avatars/Mask-Group-1.png';
import avatar29 from '@/assets/avatars/Mask-Group-1.png';
import avatar26 from '@/assets/avatars/Mask-Group-2.png';
import avatar27 from '@/assets/avatars/Mask-Group-3.png';
import avatar28 from '@/assets/avatars/Mask-Group-4.png';
import avatar30 from '@/assets/avatars/Mask-Group-5.png';
import avatar31 from '@/assets/avatars/Mask-Group-6.png';
import avatar32 from '@/assets/avatars/Mask-Group-7.png';
import avatar33 from '@/assets/avatars/Mask-Group-8.png';
import avatar34 from '@/assets/avatars/Mask-Group-9.png';
import avatar35 from '@/assets/avatars/Mask-Group-10.png';
import avatar36 from '@/assets/avatars/Mask-Group-11.png';
import avatar37 from '@/assets/avatars/Mask-Group-12.png';
import avatar38 from '@/assets/avatars/Mask-Group-13.png';
import avatar39 from '@/assets/avatars/Mask-Group-14.png';
import avatar40 from '@/assets/avatars/Mask-Group-15.png';
import avatar41 from '@/assets/avatars/Mask-Group-16.png';
import avatar42 from '@/assets/avatars/Mask-Group-17.png';
import avatar43 from '@/assets/avatars/Mask-Group-18.png';
import avatar44 from '@/assets/avatars/Mask-Group-19.png';
import avatar45 from '@/assets/avatars/Mask-Group-20.png';
import avatar46 from '@/assets/avatars/Mask-Group-21.png';
import avatar47 from '@/assets/avatars/Mask-Group-22.png';
import avatar48 from '@/assets/avatars/Mask-Group-23.png';
import avatar49 from '@/assets/avatars/Mask-Group-24.png';
import avatar24 from '@/assets/avatars/Mask-Group.png';
import avatar1 from '@/assets/avatars/face.png';

export {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar22,
  avatar23,
  avatar24,
  avatar25,
  avatar26,
  avatar27,
  avatar28,
  avatar29,
  avatar30,
  avatar31,
  avatar32,
  avatar33,
  avatar34,
  avatar35,
  avatar36,
  avatar37,
  avatar38,
  avatar39,
  avatar40,
  avatar41,
  avatar42,
  avatar43,
  avatar44,
  avatar45,
  avatar46,
  avatar47,
  avatar48,
  avatar49,
};

export const defaultAvatarName = 'Bob';

export const getAvatars = (): LocalAvatar[] => {
  return [
    {
      id: 1,
      name: 'Alice',
      url: avatar1,
      fileName: 'face.png',
    },
    // Caution: if we change 'Bob' please change defaultAvatarName
    {
      id: 2,
      name: 'Bob',
      url: avatar2,
      fileName: 'Frame-1.png',
    },
    {
      id: 3,
      name: 'Charlie',
      url: avatar3,
      fileName: 'Frame-3.png',
    },
    {
      id: 4,
      name: 'Dave',
      url: avatar4,
      fileName: 'Group-12.png',
    },
    {
      id: 5,
      name: 'Eva',
      url: avatar5,
      fileName: 'Group-13.png',
    },
    {
      id: 6,
      name: 'Frank',
      url: avatar6,
      fileName: 'Group-14.png',
    },
    {
      id: 7,
      name: 'Grace',
      url: avatar7,
      fileName: 'Group-15.png',
    },
    {
      id: 8,
      name: 'Hank',
      url: avatar8,
      fileName: 'Group-16.png',
    },
    {
      id: 9,
      name: 'Ivy',
      url: avatar9,
      fileName: 'Group-17.png',
    },
    {
      id: 10,
      name: 'Jack',
      url: avatar11,
      fileName: 'Group-18.png',
    },
    {
      id: 11,
      name: 'Kara',
      url: avatar12,
      fileName: 'Group-19.png',
    },
    {
      id: 12,
      name: 'Leo',
      url: avatar13,
      fileName: 'Group-20.png',
    },
    {
      id: 13,
      name: 'Mia',
      url: avatar14,
      fileName: 'Group-21.png',
    },
    {
      id: 14,
      name: 'Nick',
      url: avatar15,
      fileName: 'Group-22.png',
    },
    {
      id: 15,
      name: 'Oscar',
      url: avatar16,
      fileName: 'Group-23.png',
    },
    {
      id: 16,
      name: 'Patty',
      url: avatar17,
      fileName: 'Group-24.png',
    },
    {
      id: 17,
      name: 'Quinn',
      url: avatar18,
      fileName: 'Group-25.png',
    },
    {
      id: 18,
      name: 'Rudy',
      url: avatar19,
      fileName: 'Group-26.png',
    },
    {
      id: 19,
      name: 'Sara',
      url: avatar20,
      fileName: 'Group-27.png',
    },
    {
      id: 20,
      name: 'Toby',
      url: avatar22,
      fileName: 'Group-28.png',
    },
    {
      id: 21,
      name: 'Una',
      url: avatar23,
      fileName: 'Group-29.png',
    },
    {
      id: 22,
      name: 'Victor',
      url: avatar24,
      fileName: 'Mask-Group.png',
    },
    {
      id: 23,
      name: 'Wendy',
      url: avatar25,
      fileName: 'Mask-Group-1.png',
    },
    {
      id: 24,
      name: 'Xander',
      url: avatar26,
      fileName: 'Mask-Group-2.png',
    },
    {
      id: 25,
      name: 'Yara',
      url: avatar27,
      fileName: 'Mask-Group-3.png',
    },
    {
      id: 26,
      name: 'Zoe',
      url: avatar28,
      fileName: 'Mask-Group-4.png',
    },
    {
      id: 27,
      name: 'Alex',
      url: avatar30,
      fileName: 'Mask-Group-5.png',
    },
    {
      id: 28,
      name: 'Bella',
      url: avatar31,
      fileName: 'Mask-Group-6.png',
    },
    {
      id: 29,
      name: 'Carl',
      url: avatar32,
      fileName: 'Mask-Group-7.png',
    },
    {
      id: 30,
      name: 'Diana',
      url: avatar33,
      fileName: 'Mask-Group-8.png',
    },
    {
      id: 31,
      name: 'Eli',
      url: avatar34,
      fileName: 'Mask-Group-9.png',
    },
    {
      id: 32,
      name: 'Faye',
      url: avatar35,
      fileName: 'Mask-Group-10.png',
    },
    {
      id: 33,
      name: 'George',
      url: avatar36,
      fileName: 'Mask-Group-11.png',
    },
    {
      id: 34,
      name: 'Hannah',
      url: avatar37,
      fileName: 'Mask-Group-12.png',
    },
    {
      id: 35,
      name: 'Ian',
      url: avatar38,
      fileName: 'Mask-Group-13.png',
    },
    {
      id: 36,
      name: 'Julia',
      url: avatar39,
      fileName: 'Mask-Group-14.png',
    },
    {
      id: 37,
      name: 'Kyle',
      url: avatar40,
      fileName: 'Mask-Group-15.png',
    },
    {
      id: 38,
      name: 'Lily',
      url: avatar41,
      fileName: 'Mask-Group-16.png',
    },
    {
      id: 39,
      name: 'Mike',
      url: avatar42,
      fileName: 'Mask-Group-17.png',
    },
    {
      id: 40,
      name: 'Nina',
      url: avatar43,
      fileName: 'Mask-Group-18.png',
    },
    {
      id: 41,
      name: 'Oliver',
      url: avatar44,
      fileName: 'Mask-Group-19.png',
    },
    {
      id: 42,
      name: 'Piper',
      url: avatar45,
      fileName: 'Mask-Group-20.png',
    },
    {
      id: 43,
      name: 'Andy',
      url: avatar47,
      fileName: 'Mask-Group-22.png',
    },
    {
      id: 44,
      name: 'Sad',
      url: avatar48,
      fileName: 'Mask-Group-23.png',
    },
    {
      id: 45,
      name: 'Pinky',
      url: avatar49,
      fileName: 'Mask-Group-24.png',
    },
  ];
};

export const getCommonAvatars = () => {
  const avatars = getAllAvatarsMap();

  return [
    avatars.get('Bella'),
    avatars.get('Lily'),
    avatars.get('Kyle'),
    avatars.get('Sara'),
    avatars.get('Andy'),
  ];
};

/**
 * Create a map of local avatars for constant time lookups
 * This is used to check if the avatar is a local avatar or not
 * in constant time.
 *
 * Returns a map of local avatars where the key is the name of the avatar
 * and the value is the LocalAvatar object
 */
export const getAllAvatarsMap = () => {
  return getAvatars().reduce((acc, curr) => {
    acc.set(curr.name, curr);
    return acc;
  }, new Map<string, LocalAvatar>());
};
