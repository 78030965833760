import _ from 'lodash';
import React, { memo } from 'react';
import { Dimensions } from 'react-native';

import NetworkVideo from '@/components/video/NetworkVideo';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

type Props = {
  imageKey?: string;
};

const maxHeight = 300;
const maxWidth = web ? 400 : Dimensions.get('window').width - 64;

const BubbleMessageVideo = ({ imageKey }: Props) => {
  return (
    <NetworkVideo
      imageKey={imageKey}
      width={_.min([screenWidth - 64, maxWidth])!}
      height={_.min([screenHeight * 0.35, maxHeight])!}
      borderRadius={BorderRadius.sm}
      playButtonType="circleTriangle"
    />
  );
};

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

export default memo(BubbleMessageVideo);
