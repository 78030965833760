import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import LeaveTableModal from './LeaveTableModal';

import type { ChatUnitType } from '@/API';
import { BottomSheet } from '@/components/actionList/BottomSheet';
import Avatar from '@/components/avatar';
import { getName } from '@/domain/user/functions/getName';
import { type MemberType, Roles } from '@/models';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';
import { deleteChatUnitUser } from '@/services/datastore/user/deleteChatUnitUser';
import type { ChatUnitUser } from '@/services/types';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Greys, Neutrals, Status } from '@/ui/common/colors';

const TableUsers = ({
  hidden,
  chatUnitType,
  otherTableUsers,
  ownerId,
  chatUnitTitle,
  orgMembers,
  orgId,
}: {
  hidden?: boolean;
  chatUnitType: ChatUnitType;
  otherTableUsers: ChatUnitUser[];
  ownerId: string;
  chatUnitTitle: string;
  orgMembers: (MemberType | null)[];
  orgId?: string;
}) => {
  const { translate } = useTranslations();
  const [open, setOpen] = React.useState(false);

  const [activeMember, setActiveMember] = React.useState<ChatUnitUser | null>(
    null,
  );
  const [leaveModalVisible, setLeaveModalVisible] = React.useState(false);

  if (hidden) {
    return null;
  }

  const RoleLabels = {
    [Roles.OWNER]: 'owner_label',
    [Roles.MEMBER]: 'member_label',
    [Roles.GUEST]: 'guest_label',
    [Roles.ADMIN]: 'admin_label',
  };

  return (
    <View>
      {otherTableUsers.map(tableUser => {
        const isInvited = tableUser.status === ChatUnitUserStatus.PENDING;

        const roleName = orgId
          ? translate(
              RoleLabels[
                orgMembers?.find(m => m?.userId === tableUser.id)?.role ??
                  Roles.MEMBER
              ],
            )
          : translate('member_label');

        return (
          <Pressable
            key={tableUser.id}
            onPress={() => {
              setActiveMember(tableUser);
              setOpen(true);
            }}>
            <View key={tableUser.id} style={styles.userCard}>
              <Avatar
                size={50}
                imageKey={tableUser.avatar}
                displayName={getName(tableUser, 'short')}
              />
              <View style={styles.userDetails}>
                <View style={styles.nameRow}>
                  <Text style={styles.nameLabel}>
                    {getName(tableUser, 'short')}
                  </Text>
                  {isInvited && (
                    <>
                      <View style={styles.dot} />
                      <Text style={styles.nameLabel}>
                        {parsePhoneNumberFromString(
                          tableUser.phoneNumber,
                        )?.formatInternational()}
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View
                style={
                  tableUser?.id === ownerId
                    ? styles.owner
                    : isInvited
                      ? styles.invited
                      : styles.member
                }>
                <Text
                  style={
                    tableUser?.id === ownerId
                      ? styles.roleLabel
                      : isInvited
                        ? styles.roleLabelInvited
                        : styles.roleLabelMember
                  }>
                  {tableUser?.id === ownerId
                    ? translate('owner_label')
                    : isInvited
                      ? translate('invited_member_label')
                      : roleName}
                </Text>
              </View>
            </View>
            <View style={styles.divider} />
          </Pressable>
        );
      })}
      <BottomSheet
        open={open}
        setOpen={state => {
          setActiveMember(null);
          setOpen(state);
        }}>
        {activeMember && (
          <>
            <View style={styles.imageHeader}>
              <Avatar
                size={50}
                imageKey={activeMember.avatar}
                displayName={activeMember.username}
              />
              <View>
                <AppText
                  color={Neutrals.shade700}
                  size={17}
                  textAlign="left"
                  type="primary700">
                  {activeMember.username}
                </AppText>
              </View>
            </View>
            <View style={styles.contentContainer}>
              <LinearGradient
                colors={[Greys.shade200, `${Greys.shade200}00`]}
                end={{ x: 0.5, y: 1.0 }}
                start={{ x: 0.5, y: 0.0 }}
                style={styles.gradient}
              />
            </View>
            {activeMember.status === ChatUnitUserStatus.PENDING && (
              <Pressable
                onPress={() => {
                  console.log('Remove...');
                  deleteChatUnitUser(activeMember?.chatUnitUserId);
                  setOpen(false);
                }}
                style={[styles.itemRow]}>
                <AppText color={Status.danger100} size={14}>
                  Remove from this {chatUnitType.toLocaleLowerCase()}
                </AppText>
              </Pressable>
            )}
            {activeMember.status === ChatUnitUserStatus.ACTIVE &&
              chatUnitType === 'TABLE' && (
                <Pressable
                  onPress={() => {
                    setLeaveModalVisible(true);
                    setOpen(false);
                  }}
                  style={styles.itemRow}>
                  <AppText color={Status.danger100} size={14}>
                    Remove from this {chatUnitType.toLocaleLowerCase()}
                  </AppText>
                </Pressable>
              )}
            <Pressable
              onPress={() => setOpen(false)}
              style={styles.actionButton}>
              <AppText
                color={Greys.shade500}
                size={14}
                textAlign="center"
                type="primary700">
                Done
              </AppText>
            </Pressable>
          </>
        )}
      </BottomSheet>
      <LeaveTableModal
        visible={leaveModalVisible}
        onClose={() => setLeaveModalVisible(false)}
        tableName={chatUnitTitle}
        chatUser={activeMember}
        isRemovingUser={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  avatarName: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
  },
  dot: {
    backgroundColor: Status.success300,
    borderRadius: 2.5,
    height: 5,
    marginHorizontal: 6,
    marginTop: 2,
    width: 5,
  },
  divider: {
    backgroundColor: Greys.shade200,
    height: 1,
    width: wp(100),
  },
  member: {
    alignSelf: 'center',
    borderColor: Greys.shade200,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  invited: {
    alignSelf: 'center',
    borderColor: Status.success300,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  nameLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '700',
  },
  nameRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  noAvatar: {
    alignItems: 'center',
    borderRadius: 25,
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  owner: {
    alignSelf: 'center',
    borderColor: Greys.shade400,
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  roleLabel: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 9,
    fontWeight: '700',
  },
  roleLabelMember: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 9,
    fontWeight: '400',
  },
  roleLabelInvited: {
    color: Status.success300,
    fontFamily: 'OpenSans-Regular',
    fontSize: 9,
    fontWeight: '400',
  },
  userCard: {
    backgroundColor: Greys.shade0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  userDetails: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 20,
  },
  header: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  imageHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  contentContainer: {
    paddingTop: 10,
  },
  gradient: {
    position: 'absolute',
    height: 20,
    left: 0,
    right: 0,
    top: 0,
  },
  itemRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 15,
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Neutrals.shade200,
  },
  disabledButton: {
    backgroundColor: Greys.shade200,
    opacity: 0.65,
  },
  actionButton: {
    paddingVertical: 15,
    marginBottom: 0,
  },
});

export default TableUsers;
