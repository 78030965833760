import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import Support from './Support';

import Logo from '@/assets/svg/8seats-logo-dark.svg';
import MainButton from '@/components/MainButton';
import SecondaryButton from '@/components/SecondaryButton';
import CountryPicker from '@/components/country-picker';
import KeyboardAwareScrollView from '@/components/keyboard-aware-scrollview';
import { PhoneController } from '@/components/phoneInput/PhoneController';
import { constants } from '@/constants';
import AuthService from '@/domain/auth/AuthService';
import { Validation } from '@/hooks/useInput';
import useNavigation from '@/hooks/useNavigation';
import type { ForgetPasswordParams } from '@/navigation/types';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

type FormFields = {
  phoneNumber: string;
};

const ForgetPassword = ({
  route: { params },
}: {
  route: { params: ForgetPasswordParams };
}) => {
  const { bottom, top } = useSafeAreaInsets();
  const { goBack, navigate } = useNavigation();
  const { translate } = useTranslations();

  const [showPicker, setShowPicker] = useState(false);
  const resolver = yupResolver(
    yup.object().shape({
      phoneNumber: yup
        .string()
        .test(
          'is-valid-phone',
          translate('phone_number_is_invalid_text'),
          value => {
            return isValidPhoneNumber(value || '');
          },
        )
        .required(translate('phone_number_is_required_text')),
    }),
    { abortEarly: false },
  );
  const [selectedCountry, setSelectedCountry] = useState(
    constants.defaultCountry,
  );
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormFields>({
    defaultValues: {
      phoneNumber: params.phoneNumber ?? selectedCountry?.dial_code ?? '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const onSubmit = async (data: FormFields) => {
    try {
      const parsedPhoneNumber = data.phoneNumber?.replace(/\s/g, '') ?? '';
      await AuthService.handleForgotPasswordRequest(parsedPhoneNumber);
      navigate('ForgotPasswordSecurityCode', { phoneNumber: data.phoneNumber });
    } catch (e) {
      if ((e as { code: string })?.code === 'UserNotFoundException') {
        navigate('ForgotPasswordSecurityCode', {
          phoneNumber: data.phoneNumber,
        });
      }
    }
  };

  return (
    <View style={[styles.container, { paddingTop: top }]}>
      <View style={styles.content}>
        <View style={styles.topContainer}>
          <Logo style={styles.logo} />
          <Text style={[styles.text, styles.title]}>
            {translate('forgot_password_title')}
          </Text>
          <Text style={[styles.text, styles.description]}>
            {translate('forgot_password_description')}
          </Text>
          <Text style={[styles.text, styles.inputTitle]}>
            {translate('phone_number_text')}
          </Text>
          <KeyboardAwareScrollView
            bounces={false}
            contentContainerStyle={{ paddingBottom: bottom }}
            showsVerticalScrollIndicator={false}>
            <PhoneController
              control={control}
              error={errors.phoneNumber?.message}
              label={translate('phone_number_placeholder_text')}
              name="phoneNumber"
              onSearch={() => setShowPicker(true)}
              selectedCountry={selectedCountry}
              validation={errors.phoneNumber && Validation.ERROR}
            />
          </KeyboardAwareScrollView>
        </View>
        <View style={styles.bottomContainer}>
          <Support />
          <MainButton
            disabled={!isValid}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            style={styles.mainButton}
            textStyle={styles.mainButtonText}
            title={translate('forgot_password_reset_password_button_title')}
          />
          <SecondaryButton
            onPress={goBack}
            style={styles.secondaryButton}
            textStyle={styles.secondaryButtonText}
            title={translate('forgot_password_cancel_button_title')}
          />
        </View>
      </View>
      <CountryPicker
        selectedCountry={selectedCountry}
        onCountrySelected={setSelectedCountry}
        open={showPicker}
        setOpen={setShowPicker}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 40,
    paddingHorizontal: 20,
  },
  container: { flexGrow: 1 },
  content: {
    flex: 1,
  },
  description: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginTop: 20,
  },
  inputTitle: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginTop: 30,
    textAlign: 'center',
    marginBottom: 10,
  },
  logo: { height: 70, marginTop: 46, width: 230 },
  mainButton: {
    marginTop: 48,
  },
  mainButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
  },
  secondaryButton: {
    marginTop: 10,
  },
  secondaryButtonText: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    fontWeight: '700',
  },
  text: { marginHorizontal: wp(8), textAlign: 'center' },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
    marginTop: 27,
  },
  topContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
  },
});

export default ForgetPassword;
