import {
  blue2,
  blue3,
  green,
  ligthBlue,
  primaryBlue,
  primaryRed,
  purple,
  red,
  turquoise,
} from './colors';
import { hp, wp } from './responsiveHelpers';

export {
  ligthBlue,
  primaryBlue,
  primaryRed,
  turquoise,
  purple,
  blue2,
  blue3,
  red,
  green,
  hp,
  wp,
};
