import placeholderCardImg from '@/assets/chatview/placeholderCard.png';
import type { Asset } from '@/domain/cms/graphql/generated/graphql';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
import { ScreenSizes } from '@/utilities/useWindowDimensions/useWindowDimensions';
import { Image } from 'expo-image';
import { useState } from 'react';
import { View, useWindowDimensions } from 'react-native';
import DiscoverTabPill from './DiscoverTabPill';

const columnSizes = {
  [ScreenSizes.xl]: { columns: 2, minWidth: 1075 },
  [ScreenSizes.l]: { columns: 2, minWidth: 875 },
  [ScreenSizes.md]: { columns: 2, minWidth: 675 },
  [ScreenSizes.sm]: { columns: 1, minWidth: 475 },
  [ScreenSizes.xs]: { columns: 1, minWidth: 275 },
  [ScreenSizes.xxs]: { columns: 1, minWidth: 0 },
};

type Props = Asset & { sectionTitle: string };

const getWidthForColumnsOnWeb = (width: number) => {
  const numColumns = Object.values(columnSizes).find(
    size => width >= size.minWidth,
  )?.columns;
  return width / numColumns!;
};

export default function PostImage(props?: Props) {
  const [webWidth, setWebWidth] = useState(0);
  let width = useWindowDimensions().width;
  width = web ? getWidthForColumnsOnWeb(webWidth) : width - 32;
  // Maintain aspect ratio based on props
  const computedHeight =
    props?.height && props?.width
      ? (props.height / props.width) * width
      : width;

  if (!props?.url) {
    return null;
  }

  return (
    <View
      onLayout={
        web ? event => setWebWidth(event.nativeEvent.layout.width) : undefined
      }
      style={{
        overflow: 'hidden',
        borderTopLeftRadius: BorderRadius.md,
        borderTopRightRadius: BorderRadius.md,
      }}>
      <Image
        source={{ uri: props.url }}
        style={{ width, height: computedHeight }}
        contentFit="cover"
        placeholder={placeholderCardImg}
      />
      {props.sectionTitle.includes('All') ? null : (
        <View style={{ position: 'absolute', bottom: 10, right: 10 }}>
          <DiscoverTabPill title={props.sectionTitle} variant="dark" />
        </View>
      )}
    </View>
  );
}
