import * as React from 'react';
import Svg, {
  Mask,
  Path,
  G,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgLogoOctagon = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}>
    <Mask
      id="logoOctagon_svg__b"
      width={80}
      height={80}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse">
      <Path fill="url(#logoOctagon_svg__a)" d="M0 0h80v80H0z" />
    </Mask>
    <G mask="url(#logoOctagon_svg__b)">
      <Path
        stroke="#fff"
        strokeWidth={4.5}
        d="M35.759 4.192a11.083 11.083 0 0 1 8.482 0l18.08 7.489a11.083 11.083 0 0 1 5.998 5.998l7.489 18.08a11.083 11.083 0 0 1 0 8.482l-7.489 18.08a11.083 11.083 0 0 1-5.998 5.998l-18.08 7.489a11.083 11.083 0 0 1-8.482 0l-18.08-7.489a11.083 11.083 0 0 1-5.998-5.998L4.192 44.24a11.083 11.083 0 0 1 0-8.482l7.489-18.08a11.083 11.083 0 0 1 5.998-5.998z"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M54.746 52.23c1.79-.588 3.376-1.631 4.76-3.129 2.28-2.467 3.42-5.488 3.42-9.063 0-3.852-1.115-6.936-3.345-9.252-1.303-1.383-2.833-2.358-4.591-2.924.023.346.035.697.035 1.052 0 .719-.05 1.423-.15 2.112a7.848 7.848 0 0 1 2.522 1.817l.023.024c1.588 1.65 2.506 3.932 2.506 7.171 0 2.874-.89 5.152-2.623 7.027-.802.868-1.676 1.525-2.64 1.977.09.598.136 1.205.136 1.82 0 .464-.018.92-.053 1.368M25.19 30.897c-.977.445-1.843 1.08-2.62 1.904C20.888 34.606 20 36.87 20 39.811c0 3.262.928 5.55 2.532 7.198a8.164 8.164 0 0 0 2.514 1.78 14.729 14.729 0 0 0-.144 2.073c0 .37.011.736.035 1.096-1.732-.564-3.25-1.517-4.555-2.857C18.127 46.785 17 43.69 17 39.811c0-3.625 1.127-6.646 3.382-9.062 1.354-1.436 2.925-2.445 4.712-3.028-.028.392-.041.79-.041 1.193 0 .673.046 1.335.138 1.983"
        clipRule="evenodd"
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M34.518 41.179a1.5 1.5 0 0 0 .061-2.464c-1.25-.915-2.283-1.862-3.112-2.836-1.823-2.142-2.693-4.45-2.693-6.965 0-3.136 1.029-5.583 3.04-7.477l.012-.012c1.983-1.908 4.666-2.925 8.212-2.925 3.224 0 5.874 1.015 8.045 3.022 2.157 1.994 3.219 4.424 3.219 7.392 0 2.69-.93 5.095-2.861 7.268-.844.95-1.887 1.864-3.142 2.737a1.5 1.5 0 0 0-.053 2.425 19.13 19.13 0 0 1 3.052 2.88c.469.557.893 1.134 1.274 1.73 1.02 1.612 1.503 3.24 1.503 4.908 0 3.17-1.023 5.662-3.02 7.607-1.982 1.93-4.675 2.957-8.244 2.957-3.284 0-5.926-1.009-8.037-2.976-2.096-1.98-3.151-4.469-3.151-7.588 0-2.648.89-4.96 2.712-6.998.844-.944 1.9-1.841 3.183-2.685Z"
      />
    </G>
    <Defs>
      <LinearGradient
        id="logoOctagon_svg__a"
        x1={40}
        x2={40}
        y1={0}
        y2={80}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SvgLogoOctagon;
