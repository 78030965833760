import { StyleSheet } from 'react-native';

import { wp } from '@/theme';
import { Greys, Misc } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

export const styles = StyleSheet.create({
  cameraModal: {
    alignItems: 'center',
    backgroundColor: 'red',
    flex: 1,
    justifyContent: 'center',
  },
  captureButton: {
    alignItems: 'center',
    borderColor: Greys.shade0,
    borderRadius: 100,
    borderWidth: 3,
    height: 70,
    justifyContent: 'center',
    width: 70,
  },
  captureButtonContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    width: '33%',
  },
  captureButtonInner: {
    backgroundColor: Greys.shade0,
    borderRadius: 100,
    height: 50,
    width: 50,
  },
  changeCameraButton: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 100,
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  chatListContentContainer: {
    // Note: top & bottom are inverted
    // paddingBottom set to 14 to align top of message history date indicator with sticky date component
    paddingBottom: 14,
    paddingTop: 10,
  },
  closeButton: {
    left: 20,
    position: 'absolute',
  },
  flex: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingTop: 35,
  },
  controlsContainer: {
    backgroundColor: 'transparent',
    flex: 0.15,
    flexDirection: 'row',
  },
  emptyChatIcon: { left: 2, position: 'absolute', top: 2 },
  fixedRatio: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  leftControl: {
    backgroundColor: 'transparent',
    width: '33%',
  },
  loadingBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  modalContainer: {
    alignItems: 'center',
    backgroundColor: Misc.transparent,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  newConversation: {
    alignSelf: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 15,
    elevation: 5,
    flexDirection: 'row',
    position: 'absolute',
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    top: 20,
    width: web ? '95%' : wp(95),
  },
  newConversationText: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
    marginLeft: 40,
    marginRight: 20,
  },
  rightControl: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: 10,
    width: '33%',
  },
  rotateCameraIconContainer: {
    height: 30,
    width: 30,
  },
  shadowLinearGradient: { height: '100%', width: 42 },
  shadowView: {
    height: '100%',
    opacity: 1,
    position: 'absolute',
    right: 0,
    width: 42,
  },
  textGroup: { marginTop: -3 },
  messageLoaderContainer: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  messageLoaderGradient: {
    left: 0,
    opacity: 0.3,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  webChatContainer: {
    paddingHorizontal: 20,
    flex: 1,
  },
});
