import PrimaryHeader from '@/components/headers/PrimaryHeader';
import { AppText, Spacer } from '@/ui/app';
import { web } from '@/utilities/platform';
import { ScreenSizes } from '@/utilities/useWindowDimensions/useWindowDimensions';
import { useFocusEffect } from '@react-navigation/native';
import { FlashList, MasonryFlashList } from '@shopify/flash-list';
import { memo, useCallback, useMemo, useState } from 'react';
import {
  FlatList,
  type LayoutChangeEvent,
  type ListRenderItemInfo,
  StyleSheet,
  View,
} from 'react-native';
import {
  type Post,
  type PostSection,
  usePostSectionQuery,
  usePostsLazyQuery,
} from '../cms/graphql/generated/graphql';
import useCMSStore from '../cms/useCMSStore';
import { Colors } from '../theme/Colors';
import DiscoverTabPill from './components/DiscoverTabPill';
import PostCard from './components/PostCard';

const columnSizes = {
  [ScreenSizes.xl]: { columns: 2, minWidth: 1075 },
  [ScreenSizes.l]: { columns: 2, minWidth: 875 },
  [ScreenSizes.md]: { columns: 2, minWidth: 675 },
  [ScreenSizes.sm]: { columns: 1, minWidth: 475 },
  [ScreenSizes.xs]: { columns: 1, minWidth: 275 },
  [ScreenSizes.xxs]: { columns: 1, minWidth: 0 },
};

export default function DiscoverScreen() {
  const { data: postSectionData } = usePostSectionQuery();
  const [selectedTab, setSelectedTab] = useState('All');
  const [webColumns, setWebColumns] = useState(1);

  const [fetchPosts, { data: postsData }] = usePostsLazyQuery({
    variables: { limit: 15 },
  });

  useFocusEffect(
    useCallback(() => {
      fetchPosts({ variables: { limit: 15 } });
    }, [fetchPosts]),
  );
  // Update the unread time when the screen is focused
  // This is used to determine if the user has read the discovery screen
  // If the user has read the screen, the unread indicator should be removed
  useFocusEffect(
    useCallback(() => {
      return () => {
        useCMSStore
          .getState()
          .setLastReadDiscoveryPostPublishedAtInMillis(Date.now());
      };
    }, []),
  );

  const posts = (postsData?.postCollection?.items || []) as Post[];

  const postsToDisplay = useMemo(() => {
    let selectedPosts: Post[];
    if (selectedTab.includes('All')) {
      selectedPosts = posts;
    } else {
      selectedPosts = posts.filter(post => post.type?.name === selectedTab);
    }
    // Sort by publish date in descending order, i.e, latest first
    return selectedPosts.sort((a, b) => {
      return (
        new Date(b.publishDate || b.sys?.publishedAt).getTime() -
        new Date(a.publishDate || a.sys?.publishedAt).getTime()
      );
    });
  }, [selectedTab, posts]);

  const postSections = useMemo(() => {
    // Have the 'All' section first, followed by the rest
    return (
      ((postSectionData?.postSectionCollection?.items || []) as PostSection[])
        .sort((a, b) => {
          if (a.name?.includes('All')) {
            return -1;
          }
          if (b.name?.includes('All')) {
            return 1;
          }
          return 0;
        })
        // Hide sections which do not have any posts, except 'All'
        .filter(section => {
          return posts.some(
            post =>
              post.type?.name === section.name || section.name?.includes('All'),
          );
        })
    );
  }, [postSectionData?.postSectionCollection?.items, posts]);

  const PillSpacing = useMemo(
    () => (
      <>
        <Spacer width={20} />
      </>
    ),
    [],
  );

  const renderPostSections = useCallback(
    ({ item }: ListRenderItemInfo<PostSection>) => {
      return (
        <DiscoverTabPill
          title={item.name || ''}
          variant={item.name?.includes(selectedTab) ? 'selected' : 'light'}
          onPress={setSelectedTab}
          showUnread
        />
      );
    },
    [selectedTab],
  );

  // Memoizing onLayoutWidthChange to prevent recreation on each render
  const onLayoutWidthChange = useCallback((params: LayoutChangeEvent) => {
    if (web) {
      const sizes = Object.values(columnSizes);

      sizes.some(size => {
        if (params?.nativeEvent?.layout?.width > size.minWidth) {
          setWebColumns(size.columns);
          return true; // Return true to terminate the loop once the condition is met
        }
        return false; // Added to ensure proper termination
      });
    }
  }, []); // function doesnt need to change between renders

  return (
    <>
      <View style={styles.container}>
        <PrimaryHeader
          hasShadow={false}
          title="Discover all the latest about 8seats"
          rightActionVisible
        />
        <Spacer height={16} />
        <FlatList
          data={postSections}
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={PillSpacing}
          ListFooterComponent={PillSpacing}
          renderItem={renderPostSections}
          ItemSeparatorComponent={PillSeperator}
          style={styles.sectionList}
        />
        <Spacer height={16} />
        {webColumns >= 2 ? (
          <MasonryFlashList
            data={postsToDisplay}
            estimatedItemSize={400}
            numColumns={2}
            renderItem={({ item }) => <PostCard {...item} />}
            contentContainerStyle={styles.contentContainer}
            ItemSeparatorComponent={() => <Spacer height={10} />}
            onLayout={onLayoutWidthChange}
          />
        ) : (
          <FlashList
            data={postsToDisplay}
            estimatedItemSize={400}
            renderItem={({ item }) => <PostCard {...item} />}
            contentContainerStyle={styles.contentContainer}
            ItemSeparatorComponent={() => <Spacer height={10} />}
            onLayout={onLayoutWidthChange}
          />
        )}
      </View>
    </>
  );
}

const PillSeperator = memo(() => <Spacer width={10} />);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.neutral0,
  },
  contentContainer: {
    padding: 16,
  },
  sectionList: {
    backgroundColor: Colors.neutral0,
    height: 30,
    maxHeight: 30,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
