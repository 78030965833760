import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useChatInvites } from './useChatInvites';

import { ChatUnitType } from '@/API';
import { getChatUnitLimit } from '@/domain/chatUnit/functions/getChatUnitLimit';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { InviteStatus } from '@/models';
import type { ChatUnit } from '@/services/types';

/**
 * Returns the remaining seats limit for the chat unit
 * @param chatUnit ChatUnit
 * @returns The remaining seats limit for the chat unit
 */
const useRemainingSeatsLimit = (chatUnit?: ChatUnit) => {
  const { invitesGroupedByStatus } = useChatInvites(chatUnit?.id ?? '');
  const chatUsers = useTableUsersStore(
    useShallow(state => state.getTableUsersForTable(chatUnit?.id ?? '')),
  );
  const limit = useMemo(
    () => getChatUnitLimit(chatUnit?.type ?? ChatUnitType.TABLE),
    [chatUnit?.type],
  );

  if (!chatUnit) {
    return 0;
  }

  const numOfPendingInvites =
    invitesGroupedByStatus?.[InviteStatus.PENDING]?.reduce((acc, invite) => {
      const phoneNumber = invite?.inviteeInfo[0]?.phone;
      if (
        phoneNumber &&
        chatUsers.every(user => user.phoneNumber !== phoneNumber)
      ) {
        return acc + 1;
      }
      return acc;
    }, 0) ?? 0;

  return limit - chatUsers.length - numOfPendingInvites;
};

export default useRemainingSeatsLimit;
