import type { Conversation } from '@/services/types';

export type SortingConversation = Conversation & {
  latestMessageTime?: Date;
};

export const sortConversationsByLatestMessageTime = (
  a: SortingConversation,
  b: SortingConversation,
) => {
  if (
    a.latestMessageTime &&
    b.latestMessageTime &&
    a.latestMessageTime !== b.latestMessageTime
  ) {
    return a.latestMessageTime < b.latestMessageTime ? 1 : -1;
  }
  if (a.latestMessageTime && !b.latestMessageTime) {
    return -1;
  }
  if (!a.latestMessageTime && b.latestMessageTime) {
    return 1;
  }
  return 0;
};
