import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Easing,
  Pressable,
  type StyleProp,
  StyleSheet,
  TextInput,
  type TextInputProps,
  View,
  type ViewStyle,
} from 'react-native';

import { Greys } from '@/ui/common/colors';

const Container = Animated.createAnimatedComponent(Pressable);

interface FloatingTextInputProps extends TextInputProps {
  borderActiveColor?: string; // need to update this to Color type from design system
  borderInactiveColor?: string; // need to update this to Color type from design system
  hasIcon?: boolean;
  icon?: React.ReactNode;
  label?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  titleActiveColor?: string;
  titleActiveSize?: number;
  titleInActiveSize?: number;
  titleInactiveColor?: string; // need to update this to Color type from design system
  value?: string;
}

const FloatingTextInput: React.FC<FloatingTextInputProps> = ({
  borderActiveColor = Greys.shade200,
  borderInactiveColor = Greys.shade200,
  hasIcon = false,
  icon,
  label = 'New Title',
  onPress,
  style,
  titleActiveColor = '#444444',
  titleActiveSize = 11,
  titleInActiveSize = 11,
  titleInactiveColor = Greys.shade500,
  value,
  ...props
}) => {
  const animatedValue = useRef(new Animated.Value(1)).current;
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    Animated.timing(animatedValue, {
      duration: 500,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      toValue: 1,
      useNativeDriver: false,
    }).start();
  }, [value, isFocused, animatedValue]);

  const returnAnimatedTitleStyles = {
    color: animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [titleInactiveColor, titleActiveColor],
    }),
    fontSize: animatedValue.interpolate({
      extrapolate: 'clamp',
      inputRange: [0, 1],
      outputRange: [titleInActiveSize, titleActiveSize],
    }),
    transform: [
      {
        translateY: animatedValue.interpolate({
          extrapolate: 'clamp',
          inputRange: [0, 1],
          outputRange: [22, -4],
        }),
      },
    ],
  };

  const viewStyles = {
    borderBottomColor: animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [borderInactiveColor, borderActiveColor],
    }),
    borderBottomWidth: 0.5,
  };

  return (
    <Container
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
      style={[viewStyles, style]}>
      <Animated.Text style={[returnAnimatedTitleStyles, styles.label]}>
        {label}
      </Animated.Text>
      <View style={styles.row}>
        {hasIcon && icon && icon}
        <TextInput
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onPressIn={() => {
            if (onPress) {
              onPress();
            }
          }}
          {...props}
          style={styles.textStyle}
          value={value}
        />
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  label: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 11,
    fontWeight: '400',
    marginBottom: 5,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  textStyle: {
    color: Greys.shade600,
    flex: 1,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 14,
    fontWeight: '600',
    paddingBottom: 10,
  },
});

export default FloatingTextInput;
