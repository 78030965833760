import { Image } from 'expo-image';
import React from 'react';
import { Pressable, ScrollView, StyleSheet } from 'react-native';

import { Colors } from '../theme/Colors';

import OnboardingSecondaryDarkButton from './components/OnboardingSecondaryDarkButton';

import AppIcon from '@/assets/onboarding/app_icon.png';
import AppIconWeb from '@/assets/onboarding/app_icon_web.png';

import QuestionMarkIcon from '@/assets/onboarding/question.svg';
import Section from '@/components/section/Section';
import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';
import { useMMKVString } from 'react-native-mmkv';
import OnboardingWebLayout from './components/OnboardingWebLayout';

type Props = MainStackScreenProps<'OnboardingStepZero'>;

const OnboardingStepZeroScreen = ({ navigation }: Props) => {
  const { translate } = useTranslations();
  const [_, setOnboardingType] = useMMKVString('onboardingType');

  return (
    <>
      {web ? undefined : (
        <Spacer width="100%" height={1} bg={Colors.neutral10} />
      )}
      <ScrollView style={styles.scroll} showsVerticalScrollIndicator={false}>
        <Spacer height={30} />
        <Image source={web ? AppIconWeb : AppIcon} style={styles.icon} />
        <Spacer height={30} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate('get_started_thanks')}
        </AppText>
        <Spacer height={30} />
        <AppText size={web ? 21 : 17}>
          {translate('get_started_thanks_subtitle')}
        </AppText>
        <Spacer height={40} />
        <OnboardingSecondaryDarkButton
          text={translate('me_individual_label')}
          onPress={() => {
            setOnboardingType('individual');
            navigation.navigate('OnboardingStepOne', {
              stepNumber: 1,
              totalSteps: 4,
              onboardingType: 'individual',
            });
          }}
        />
        <Spacer height={20} />
        <OnboardingSecondaryDarkButton
          text={translate('me_business_label')}
          onPress={() => {
            setOnboardingType('business');
            navigation.navigate('OnboardingStepOne', {
              stepNumber: 1,
              totalSteps: 4,
              onboardingType: 'business',
            });
          }}
        />
        <Spacer height={40} />
        <Section
          p={16}
          bc={Colors.neutral20}
          bw={2}
          br={BorderRadius.lg}
          bg={Colors.neutral05}>
          {/* Question mark and heading row */}
          <Section row aifs gap={10}>
            <QuestionMarkIcon width={24} height={24} />
            <AppText size={14} type="primary600" style={styles.overflowText}>
              {translate('step_zero_question')}
            </AppText>
          </Section>
          <Spacer height={10} />
          {/* Body content */}
          <AppText size={14}>
            {translate('step_zero_body').concat('\n')}
          </AppText>
          <AppText size={14}>{translate('already_have_account')}</AppText>
          <Pressable onPress={() => navigation.navigate('OnboardingSignIn')}>
            <AppText size={14} type="primary700" color={Colors.primaryLight}>
              {translate('sign_in_here')}
            </AppText>
          </Pressable>
        </Section>
        <Spacer height={60} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: web ? 128 : 72,
    height: web ? 128 : 72,
  },
  scroll: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    flexGrow: web ? 0 : undefined,
  },
  overflowText: {
    width: 0,
    flexGrow: 1,
  },
});

const OnboardingStepZeroScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout>
      <OnboardingStepZeroScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web ? OnboardingStepZeroScreenWeb : OnboardingStepZeroScreen;
