import { Dimensions } from 'react-native';

import { web } from '../platform';

// Get the screen width for responsive calculations
const screenWidth = Dimensions.get('screen').width;

/**
 * Calculates and returns avatar configuration based on user count and chat type.
 * This function handles both web and mobile layouts, adjusting sizes and spacing accordingly.
 *
 * @param {number} userCount - The number of users in the chat (default: 8)
 * @param {string} chatType - The type of chat (e.g., 'ROOM')
 * @returns {Object} Configuration object with avatar sizes, spacings, and margins
 */
const getAvatarConfig = (userCount = 8, chatType?: string) => {
  // Constants for padding and margin
  const BASE_MARGIN = 6;

  // Side padding is larger for fewer users to maintain visual balance
  // It remain same if chatType is Room
  const sidePadding = userCount <= 9 || chatType === 'ROOM' ? 6 : 4;

  // Calculate available width for avatars
  const availableWidth = screenWidth - sidePadding * 2;

  // Web-specific configuration
  if (web) {
    return getWebAvatarConfig(userCount, chatType);
  }

  // Mobile-specific configuration
  return getMobileAvatarConfig(
    userCount,
    chatType,
    availableWidth,
    BASE_MARGIN,
  );
};

/**
 * Generates avatar configuration for web layout
 */
const getWebAvatarConfig = (
  userCount: number,
  chatType: string | undefined,
) => {
  const WEB_MAX_AVATAR_SIZE = 45;
  let avatarSize, betweenIconSpace;

  // Adjust sizes based on user count or chat type
  if (userCount <= 8 || chatType === 'ROOM') {
    avatarSize = 55;
    betweenIconSpace = 6;
  } else if (userCount === 9) {
    avatarSize = 50;
    betweenIconSpace = 5.5; // Slightly reduced spacing for 9 avatars
  } else {
    avatarSize = WEB_MAX_AVATAR_SIZE;
    betweenIconSpace = 5; // Further reduced spacing for 10+ avatars
  }

  return {
    avatarSize,
    betweenIconSpace,
    bottomMargin: 15, // Consistent bottom margin for web
    inactiveYOffset: avatarSize - 10,
    sidePadding: 6,
    _padding: 2, // Kept for consistency with mobile config
  };
};

/**
 * Generates avatar configuration for mobile layout
 */
const getMobileAvatarConfig = (
  userCount: number,
  chatType: string | undefined,
  availableWidth: number,
  baseMargin: number,
) => {
  let avatarSize, betweenIconSpace;

  // Calculate sizes based on user count or chat type
  if (userCount <= 8 || chatType === 'ROOM') {
    avatarSize = (availableWidth - baseMargin * 7) / 8;
    betweenIconSpace = baseMargin - 0.5;
  } else if (userCount === 9) {
    betweenIconSpace = baseMargin - 0.5; // Slightly reduced spacing for 9 avatars
    avatarSize = (availableWidth - betweenIconSpace * 8) / 9;
  } else {
    betweenIconSpace = baseMargin - 2.75; // Further reduced spacing for 10+ avatars
    avatarSize = (availableWidth - betweenIconSpace * 9) / 10;
  }

  const bottomMargin = avatarSize / 2; // Show half of the avatar above the bottom overlay
  const inactiveYOffset = avatarSize - 10; // Offset for inactive avatars

  return {
    avatarSize,
    betweenIconSpace,
    bottomMargin,
    inactiveYOffset,
    sidePadding: userCount <= 9 || chatType === 'ROOM' ? 5.5 : 3.5,
    _padding: 2,
    margin: baseMargin, // Kept for backward compatibility
  };
};

export default getAvatarConfig;
