import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native';
import { uniqueId } from 'lodash';
import React from 'react';
import { Pressable, View } from 'react-native';

import { CompletedProfileScreens } from './CompletedProfileScreens';
import { styles } from './styles';

import AddIcon from '@/assets/svg/add-grey-web-icon.svg';
import CloseIcon from '@/assets/svg/close-o-web.svg';
import { ActiveConversationProvider } from '@/domain/conversation/state/useActiveConversationStore';

const Drawer = createDrawerNavigator();

//This is only used on web
export const SplitView = ({ route }: any) => {
  const [activeScreen, setActiveScreen] = React.useState(0);

  const [refArray, setRefArray] = React.useState([
    { ref: createNavigationContainerRef(), id: uniqueId() },
  ]);
  React.useEffect(() => {
    if (refArray[activeScreen].ref?.isReady() && route?.params?.route) {
      refArray[activeScreen].ref.navigate(route?.params?.route, {
        ...route?.params,
        activeScreen,
      });
      route.params.route = null;
    }
  }, [route.params, refArray, activeScreen]);
  const navigatorContainers = React.useMemo(() => {
    return refArray.map((element, index) => {
      const isActive = activeScreen === index;

      return (
        <View
          key={`${element.id}`}
          style={
            isActive
              ? styles.splitViewItemContainerActive
              : styles.splitViewItemContainerInactive
          }>
          <Pressable
            onPress={() => {
              !isActive && selectNavigator(index);
            }}
            style={styles.splitViewPressable}>
            <ActiveConversationProvider>
              <NavigationContainer
                documentTitle={{ enabled: false }}
                independent={true}
                ref={element.ref}>
                <Drawer.Navigator
                  screenOptions={{
                    drawerStyle: {
                      width: 0,
                    },
                    sceneContainerStyle: styles.splitViewSceneContainer,
                    drawerType: 'permanent',
                    headerShown: false,
                    swipeEdgeWidth: 0,
                    drawerItemStyle: { height: 0 },
                    swipeEnabled: false,
                  }}>
                  <Drawer.Screen
                    component={CompletedProfileScreens}
                    name="Home"
                    initialParams={{
                      setActiveScreen: () => setActiveScreen(index),
                    }}
                  />
                </Drawer.Navigator>
              </NavigationContainer>
            </ActiveConversationProvider>
          </Pressable>

          <Pressable
            style={
              refArray.length > 1
                ? styles.splitViewCloseButtonPressableActive
                : styles.splitViewCloseButtonPressableInactive
            }
            onPress={() => {
              const newActiveScreens = refArray;
              newActiveScreens.splice(index, 1);

              setRefArray([...newActiveScreens]);
              setActiveScreen(0);
            }}>
            <CloseIcon />
          </Pressable>
        </View>
      );
    });
  }, [refArray, activeScreen]);

  const selectNavigator = (index: number) => {
    setActiveScreen(index);
  };
  return (
    <View style={styles.switchComponent}>
      {navigatorContainers}

      <View style={styles.splitContainerButtons}>
        {refArray.length < 3 && (
          <Pressable
            style={styles.addSplitButton}
            onPress={() => {
              setRefArray([
                ...refArray,
                { ref: createNavigationContainerRef(), id: uniqueId() },
              ]);
              setActiveScreen(refArray.length);
            }}>
            <AddIcon />
          </Pressable>
        )}
      </View>
    </View>
  );
};
