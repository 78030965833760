import React, { type FC, type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import {
  ActivityIndicator,
  type GestureResponderEvent,
  Pressable,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { useTheme } from '@/domain/theme';
import { Colors } from '@/domain/theme/Colors';
import type { ButtonStyle } from '@/domain/theme/themes';

interface Props {
  i18nKey: string;
  busy?: boolean;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  textStyle?: TextStyle;
  variant?: 'feature' | 'link' | 'primary' | 'secondary'; // KeyOf here?
  values?: any;
}

interface ContainerProps extends Props {
  children: ReactNode;
  styles: any;
  variants: ButtonStyle;
}

const Container: FC<ContainerProps> = ({
  children,
  containerStyle,
  styles,
  variant,
  variants,
}) => {
  const style = [styles.container, variants.container, containerStyle];

  if (variant !== 'feature') {
    return <View style={style}>{children}</View>;
  }

  return (
    <LinearGradient
      colors={[Colors.neutral0, '#CCC5BE']}
      end={{ x: 0, y: 1 }}
      start={{ x: 0, y: 0 }}
      style={style}>
      <View>{children}</View>
    </LinearGradient>
  );
};

export const Button: FC<Props> = props => {
  const {
    theme: {
      ui: { button: styles },
    },
  } = useTheme();

  const {
    busy = false,
    disabled = false,
    i18nKey,
    onPress,
    textStyle,
    variant = 'primary',
    values,
  } = props;

  let variants = styles.variants[disabled ? 'disabled' : variant];

  if (variant === 'link' && disabled) {
    variants = styles.variants.disabledLink;
  }

  const onPressHandler = (event: GestureResponderEvent) => {
    if (!busy && !disabled) {
      onPress?.(event);
    }
  };

  return (
    <Container {...props} styles={styles} variants={variants}>
      <Pressable disabled={disabled} onPress={onPressHandler} role="button">
        {busy && (
          <ActivityIndicator color="#fff" size="small" testID="spinner" />
        )}

        {!busy && (
          <Text style={[styles.text, variants.text, textStyle]}>
            <Trans i18nKey={i18nKey} values={values} />
          </Text>
        )}
      </Pressable>
    </Container>
  );
};
