import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { insertSorted } from '../functions/insertSorted';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';
import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import type { ChatMessage, Message } from '@/services/chatUnit/types';
import { ChatUnitUserStatus } from '@/services/chatUnitUser/types';

type AddMessagesProps = {
  messages: Message[];
};

type State = {
  chatUnits: Map<
    string, // chatUnitId
    Map<
      string, // conversationId
      {
        messageIds: Set<string>; // index of message ids
        messages: ChatMessage[]; // sorted by createdAt
        unreadMessages?: Map<string, Message>;
      }
    >
  >;
  loadingMessages: boolean;
  lastMessageTime: string;
};

type Actions = {
  addMessages: (props: AddMessagesProps) => void;
  clearAllConversations: () => void;
  clearChatUnit: (chatUnitId: string) => void;
  clearConversation: (chatUnitId: string, conversationId: string) => void;
  clearConversationUnreadCount: (
    chatUnitId: string,
    conversationId: string,
  ) => void;
  clearState: () => void;
  getMessages: (chatUnitId: string, conversationId: string) => ChatMessage[];
  getMessagesForChatView: (
    chatUnitId: string,
    conversationId: string,
  ) => ChatMessage[];
  getMostRecentMessage: (
    chatUnitId: string,
    conversationId: string,
  ) => ChatMessage | undefined;
  getMostRecentMessagesFromChatUnits: (
    chatUnitIds: string[],
  ) => Record<string, ChatMessage>;
  hasMultiThreads: (chatUnitId: string) => boolean;
  getMostRecentMessagesFromConversations: (
    chatUnitId: string,
    conversationIds: string[],
  ) => ChatMessage[];
  getMostRecentMessages: () => ChatMessage[];
  getMostRecentMessagesMatchingQuery: (query: string) => ChatMessage[];
  getUnreadChatUnitCount: () => number;
  getUnreadCountForChatUnit: (chatUnitId: string) => number;
  getUnreadCountForConversation: (
    chatunitId?: string,
    conversationId?: string,
  ) => number;
  getUnreadMessages: (
    chatUnitId: string,
    conversationId: string,
  ) => Map<string, Message>;
  deleteMessage: (
    chatUnitId: string,
    conversationId: string,
    messageId: string,
  ) => void;
  getTotalUnreadCount: () => number;
  setLoadingMessages: (loadingMessages: boolean) => void;
};

const newState: State = {
  chatUnits: new Map(),
  loadingMessages: false,
  lastMessageTime: '1970-01-01T00:00:00.000Z',
};

export const useMessageStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...newState,
      addMessages: ({ messages }: AddMessagesProps) => {
        set(state => {
          for (const message of messages) {
            if (!message.id) {
              throw new Error('Message id is required');
            }

            // check if message already exists - should use messageId here as `id` value is overwritten by amplify
            // and therefore we cannot match against the id value inserted in the frontend prior to saving (which is done for responsiveness)
            if (
              state.chatUnits
                .get(message.chatUnitId)
                ?.get(message.conversationId)
                ?.messageIds.has(message.messageId)
            ) {
              //If it already exists and the status is read and it is exists in the unread set, then remove it.
              if (
                message.status === 'READ' &&
                state?.chatUnits
                  ?.get(message.chatUnitId)
                  ?.get(message.conversationId)
                  ?.unreadMessages?.get(message.messageId)
              ) {
                state?.chatUnits
                  ?.get(message.chatUnitId)
                  ?.get(message.conversationId)
                  ?.unreadMessages?.delete(message.messageId);
              }
              // TODO: need to account for a change in status
              continue;
            }

            let chatUnitMap = state.chatUnits.get(message.chatUnitId);
            if (!chatUnitMap) {
              chatUnitMap = new Map();
              state.chatUnits.set(message.chatUnitId, chatUnitMap);
            }
            let conversationData = chatUnitMap.get(message.conversationId);
            if (!conversationData) {
              conversationData = {
                messageIds: new Set(),
                messages: [],
                unreadMessages: new Map(),
              };
              chatUnitMap.set(message.conversationId, conversationData);
            }

            // add id to set index
            conversationData.messageIds.add(message.messageId);

            if (message.status === 'UNREAD') {
              if (!conversationData.unreadMessages) {
                conversationData.unreadMessages = new Map();
              }
              conversationData.unreadMessages.set(message.messageId, message);
            } else if (message.status === 'READ') {
              if (conversationData.unreadMessages) {
                conversationData.unreadMessages.delete(message.messageId);
              }
            }

            // TODO: do a single sort at the end?
            insertSorted(
              conversationData.messages as { createdAt: Date }[],
              transformMessage(message),
            );

            // update lastMessageTime if the message is newer. Do not update when loading past data
            if (
              new Date(conversationData.messages[0].createdAt) >
              new Date(state.lastMessageTime)
            ) {
              state.lastMessageTime =
                conversationData.messages[0].createdAt.toISOString();
            }
          }
          return state;
        });
      },
      clearAllConversations: () => {
        set(state => {
          // loop all chatunits and clear each conversation
          state.chatUnits.forEach(chatUnit => {
            chatUnit.forEach(conversation => {
              conversation.messageIds = new Set();
              conversation.messages = [];
              conversation.unreadMessages = new Map();
            });
          });
        });
      },
      clearChatUnit: chatUnitId => {
        set(state => {
          state.chatUnits.delete(chatUnitId);
        });
      },
      clearConversation: (chatUnitId: string, conversationId: string) => {
        set(state => {
          const chatUnit = state.chatUnits.get(chatUnitId);

          chatUnit?.set(conversationId, {
            messageIds: new Set(),
            messages: [],
            unreadMessages: new Map(),
          });
        });
      },
      clearConversationUnreadCount: (chatUnitId, conversationId) => {
        set(state => {
          const chatUnit = state.chatUnits.get(chatUnitId);
          const conversation = chatUnit?.get(conversationId);
          if (conversation) {
            conversation.unreadMessages = new Map();
          }
        });
      },
      clearState: () => {
        set(() => newState);
      },
      getMessages: (chatUnitId: string, conversationId: string) => {
        const results = get()
          .chatUnits.get(chatUnitId)
          ?.get(conversationId)?.messages;
        return results ? [...results] : [];
      },
      getMostRecentMessage: (chatUnitId: string, conversationId: string) => {
        const messages = get()
          .chatUnits.get(chatUnitId)
          ?.get(conversationId)?.messages;
        return messages?.[0];
      },
      getMostRecentMessagesFromChatUnits: (chatUnitIds: string[]) => {
        const results: Record<string, ChatMessage> = {};
        chatUnitIds.forEach(chatUnitId => {
          const chatUnit = get().chatUnits.get(chatUnitId);
          if (chatUnit) {
            let latestMessage: ChatMessage | undefined;
            chatUnit.forEach(conversation => {
              const message = conversation.messages[0];
              if (
                !latestMessage ||
                (message && message.createdAt > latestMessage.createdAt)
              ) {
                latestMessage = message;
              }
            });
            if (latestMessage) {
              results[chatUnitId] = latestMessage;
            }
          }
        });
        return results;
      },
      hasMultiThreads: (chatUnitId: string) => {
        const latestMessagesForEachConversation = [];
        const chatUnit = get().chatUnits.get(chatUnitId);
        if (chatUnit) {
          chatUnit.forEach(conversation => {
            const message = conversation.messages[0];
            if (message) {
              latestMessagesForEachConversation.push(message);
            }
            if (latestMessagesForEachConversation.length > 1) return;
          });
        }
        return latestMessagesForEachConversation.length > 1;
      },
      getMostRecentMessagesFromConversations: (
        chatUnitId: string,
        conversationIds: string[],
      ) => {
        const results: ChatMessage[] = [];
        const chatUnit = get().chatUnits.get(chatUnitId);
        if (chatUnit) {
          chatUnit.forEach((conversation, key) => {
            if (conversationIds.includes(key)) {
              const message = conversation.messages[0];
              if (message) {
                results.push(message);
              }
            }
          });
        }
        return results;
      },
      getMostRecentMessages: () => {
        const results: ChatMessage[] = [];
        get().chatUnits?.forEach(chatUnit => {
          chatUnit.forEach(conversation => {
            const message = conversation.messages[0];
            if (message) {
              results.push(message);
            }
          });
        });
        return results.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1));
      },
      getMostRecentMessagesMatchingQuery: (query: string) => {
        if (!query) {
          return [];
        }
        const results: ChatMessage[] = [];
        get().chatUnits?.forEach(chatUnit => {
          chatUnit.forEach(conversation => {
            conversation.messages?.forEach(message => {
              if (message.text?.toLowerCase().includes(query?.toLowerCase())) {
                results.push(message);
              }
            });
          });
        });
        return results.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1));
      },
      getUnreadChatUnitCount: () => {
        let count = 0;
        const myChatUsers = useTableUsersStore.getState().myChatUsers;
        if (get()?.chatUnits && get()?.chatUnits?.size > 0) {
          get()?.chatUnits?.forEach((chatUnit, key) => {
            if (myChatUsers.get(key)?.status !== ChatUnitUserStatus.PENDING) {
              chatUnit.forEach(conversation => {
                count += conversation.unreadMessages?.size ? 1 : 0;
              });
            }
          });
        }
        return count;
      },
      getTotalUnreadCount: () => {
        let count = 0;
        const myChatUsers = useTableUsersStore.getState().myChatUsers;
        if (get()?.chatUnits && get()?.chatUnits?.size > 0) {
          get()?.chatUnits?.forEach((chatUnit, key) => {
            if (myChatUsers.get(key)?.status !== ChatUnitUserStatus.PENDING) {
              chatUnit.forEach(conversation => {
                // TODO: ignore pending invite tables/rooms
                count += conversation.unreadMessages?.size
                  ? conversation.unreadMessages?.size
                  : 0;
              });
            }
          });
        }
        return count;
      },
      getUnreadCountForChatUnit: chatUnitId => {
        let count = 0;
        get()
          .chatUnits.get(chatUnitId)
          ?.forEach(conversation => {
            count += conversation.unreadMessages?.size || 0;
          });
        return count;
      },
      getUnreadCountForConversation: (
        chatunitId?: string,
        conversationId?: string,
      ) =>
        get()
          .chatUnits.get(chatunitId ?? '')
          ?.get(conversationId ?? '')?.unreadMessages?.size || 0,
      deleteMessage: (
        chatUnitId: string,
        conversationId: string,
        messageId: string,
      ) => {
        set(state => {
          const chatUnit = state.chatUnits.get(chatUnitId);
          const conversation = chatUnit?.get(conversationId);
          if (conversation) {
            conversation?.messageIds.delete(messageId);
            conversation?.unreadMessages?.delete(messageId);
            const messages = conversation?.messages.filter(
              message => message.messageId !== messageId,
            );
            const conversationData = { ...conversation, messages };

            chatUnit?.set(conversationId, conversationData);
          }
        });
      },
      getMessagesForChatView: (chatUnitId: string, conversationId: string) => {
        const results = get()
          .chatUnits.get(chatUnitId)
          ?.get(conversationId)?.messages;
        return results ? [...results] : [];
      },
      getUnreadMessages: (chatUnitId: string, conversationId: string) => {
        return (
          get().chatUnits.get(chatUnitId)?.get(conversationId)
            ?.unreadMessages ?? new Map()
        );
      },
      setLoadingMessages: (loadingMessages: boolean) => {
        set(state => {
          state.loadingMessages = loadingMessages;
          useLoadingStore.getState().setLoading('messages', loadingMessages);
        });
      },
    })),
    {
      name: 'message-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
      partialize: state => ({
        chatUnits: state.chatUnits,
        lastMessageTime: state.lastMessageTime,
      }),
    },
  ),
);

const transformMessage = (message: Message): ChatMessage => {
  return {
    ...message,
    _id: message.id!,
    id: message.id!,
    createdAt: new Date(message.createdAt),
    userId: message.userId,
    user: {
      _id: message.userId,
      id: message.userId,
      status: '',
    },
  };
};
