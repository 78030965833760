import { DataStore } from '@aws-amplify/datastore';

import { ChatUnit } from '../../../models';

import { factory } from '@/services/factory/factory';

export const getChatUnit = async (chatUnitId: string) => {
  if (!chatUnitId) {
    // must return undefined to avoid it returning array of all results
    return undefined;
  }

  return factory.chatUnit(await DataStore.query(ChatUnit, chatUnitId));
};
