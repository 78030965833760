import type { GraphQLQuery } from 'aws-amplify/api';

import type { BatchUpdateMessagesMutation, UpdateMessageV2Input } from '@/API';
import client from '@/services/API/client';
import type { Message } from '@/services/chatUnit/types';
import { batchUpdateMessages } from '@/services/graphql/mutations';

type Result = {
  successCount: number;
  errorData: Partial<Message>[];
};

export const updateMessages = async (input: UpdateMessageV2Input[]) => {
  const chunkedInput = chunkArray(input, 50);

  const promises = chunkedInput.map(chunk =>
    client.graphql<GraphQLQuery<BatchUpdateMessagesMutation>>({
      query: batchUpdateMessages,
      variables: { input: chunk },
    }),
  );

  const responses = await Promise.allSettled(promises);
  return responses.reduce(
    (acc, response) => {
      if (
        response.status === 'fulfilled' &&
        response.value.data?.batchUpdateMessages
      ) {
        const results = JSON.parse(response.value.data?.batchUpdateMessages);
        acc.successCount += results.successCount;
        acc.errorData = [...acc.errorData, ...results.errorData];
      }
      return acc;
    },
    { successCount: 0, errorData: [] } as Result,
  );
};

function chunkArray<T>(array: T[], size: number): T[][] {
  const chunkedArray: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    chunkedArray.push(chunk);
  }
  return chunkedArray;
}
