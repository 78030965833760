export const downloadFileWeb = (
  url: string,
  name?: string,
  mimeType?: string,
) => {
  console.log('url', url);
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': mimeType || 'application/pdf',
    },
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      // @ts-ignore: Exists on web
      const objectUrl = window.URL.createObjectURL(new Blob([blob]));
      // @ts-ignore: Exists on web
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', `${name}`);

      // Append to html link element page
      // @ts-ignore: Exists on web
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode.removeChild(link);
    });
};
