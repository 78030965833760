import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import RingCircleIcon from '@/assets/svg/circleRing.svg';
import MainButton from '@/components/MainButton';
import OutlinedButton from '@/components/OutlinedButton';
import { ChatUnitType } from '@/domain/invites/types/types';
import useNavigation from '@/hooks/useNavigation';
import { navigationRef } from '@/navigation/navigationRef';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const NoActivities = () => {
  const { translate } = useTranslations();
  const { navigate } = useNavigation();

  const goToCreateTable = () => {
    if (web) {
      navigationRef.navigate('SplitView', {
        route: 'CreateRoom',
        type: ChatUnitType.TABLE,
      });
    } else {
      navigate('CreateRoom', { type: ChatUnitType.TABLE });
    }
  };

  const goToInviteList = () => {
    if (web) {
      navigationRef.navigate('SplitView', {
        route: 'InviteList',
      });
    } else {
      navigate('InviteList');
    }
  };

  return (
    <View style={styles.container}>
      <RingCircleIcon />
      <Text style={styles.title}>{translate('no_activities_title')}</Text>
      <Text style={styles.subtitle}>{translate('no_activities_subtitle')}</Text>
      <MainButton
        onPress={goToCreateTable}
        textStyle={styles.createTableButtonTitle}
        title={translate('create_table_button_title', {
          type: translate('table'),
        })}
      />
      <OutlinedButton
        onPress={goToInviteList}
        style={styles.outlineButton}
        title={translate('view_invites_button_label')}
        titleStyle={styles.outlineButtonTitleStyle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 30,
    paddingTop: 100,
    width: '100%',
  },
  createTableButtonTitle: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
    maxWidth: '80%',
  },
  outlineButton: {
    borderColor: Greys.shade600,
    borderWidth: 2,
    height: 40,
  },
  outlineButtonTitleStyle: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
  },
  subtitle: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Regular',
    fontSize: 17,
    fontWeight: '400',
    marginBottom: 20,
    textAlign: 'center',
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: web ? 20 : 21,
    fontWeight: '400',
    marginVertical: 20,
    textAlign: 'center',
  },
});
export default NoActivities;
