/* eslint-disable react-native/no-inline-styles */
import { useNavigation } from '@react-navigation/native';
import type React from 'react';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Keyboard,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import { EmailInfoMessage } from './EmailInfoMessage';
import type { Props } from './types';

import BackIcon from '@/assets/BackArrow.svg';
import HelpIcon from '@/assets/svg/get_help.svg';
import SecureIcon from '@/assets/svg/secure_icon.svg';
import Body from '@/components/text/Body';
import BoldCaption from '@/components/text/BoldCaption';
import Subtitle from '@/components/text/Subtitle';
import SubtitleBold from '@/components/text/SubtitleBold';
import { postHogTrack } from '@/context/Experience';
import AuthService from '@/domain/auth/AuthService';
import { Button } from '@/domain/theme/components/Button';
import useUserStore from '@/domain/user/state/useUserStore';
import useTimer from '@/hooks/useTimer';
import { logger } from '@/services/logger/logger';
import { primaryBlue, primaryRed } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { DURATION_FOR_RESEND_SMS_VERIFICATION_CODE } from '@/utilities/constants';
import { web } from '@/utilities/platform';

interface RenderCellOptions {
  index: number;
  isFocused: boolean;
  symbol: string;
}
const CELL_COUNT = 6;

const SmsVerification: React.FC<Props> = ({ route }) => {
  const { phoneNumber, email } = route.params;
  const setUser = useUserStore(state => state.setUser);
  const { goBack } = useNavigation();
  const { translate } = useTranslations();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const ref = useBlurOnFulfill({ cellCount: CELL_COUNT, value });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    setValue,
    value,
  });

  const { currentTime, isCompleted, startTimer } = useTimer({
    initialMilliseconds: DURATION_FOR_RESEND_SMS_VERIFICATION_CODE,
  });

  const renderCell = ({ index, isFocused, symbol }: RenderCellOptions) => (
    <View key={index} onLayout={getCellOnLayoutHandler(index)}>
      <Text key={index} style={[styles.cell, isFocused && styles.focusCell]}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </Text>
    </View>
  );

  const submitSmsCode = async () => {
    setIsSubmitting(true);
    try {
      //Attempt to send a challenge answer if correct returns the user.
      const cognitoUser = await AuthService.sendCustomChallengeAnswer(value);
      // If the user is not found in the database, still set the state to go
      // through with the profile creation process.
      if (cognitoUser?.userId) {
        setUser({
          id: cognitoUser?.userId ?? '',
          phoneNumber: cognitoUser?.signInDetails?.loginId ?? '',
        });
      }

      postHogTrack('Login', { userId: cognitoUser?.userId ?? '' });
    } catch (e: any) {
      logger.error(e);
      setError(e.message?.replace('phone_number', 'phone number'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendSms = async () => {
    try {
      startTimer();
      await AuthService.signIn(phoneNumber);
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <View onTouchStart={Keyboard.dismiss} style={styles.dismissable}>
      <SafeAreaView style={styles.root}>
        <Pressable onPress={() => goBack()} style={styles.header}>
          <BackIcon />
          <Body children={translate('common_back')} style={styles.backText} />
        </Pressable>

        <View style={{ alignItems: 'center' }}>
          <SecureIcon />
        </View>

        <View style={styles.titleContainer}>
          {error ? (
            <Subtitle children={error} style={styles.errorTitle} />
          ) : (
            <Subtitle
              children={
                <Trans
                  components={{
                    email: <SubtitleBold />,
                    phone: <SubtitleBold />,
                  }}
                  i18nKey={
                    email ? 'sms_confirmation_email' : 'sms_confirmation'
                  }
                  values={{ email, phoneNumber }}
                />
              }
            />
          )}
        </View>

        <Body
          children={translate('sms_enter_code_text')}
          style={styles.phoneCodeLabel}
        />

        <View style={{ alignItems: 'center' }}>
          <View style={{ maxWidth: 400, gap: 20 }}>
            <CodeField
              ref={ref}
              {...props}
              cellCount={CELL_COUNT}
              inputMode="numeric"
              onChangeText={setValue}
              renderCell={renderCell}
              rootStyle={styles.codeFieldRoot}
              textContentType="oneTimeCode"
              value={value}
            />

            <EmailInfoMessage hidden={!email} />

            <View style={{ gap: 20 }}>
              <Button
                busy={isSubmitting}
                disabled={value.length < 6}
                i18nKey="common_submit_text"
                onPress={submitSmsCode}
              />

              <Button
                disabled={!isCompleted}
                i18nKey={isCompleted ? 'resend_code_text' : 'sms_resend_timer'}
                onPress={resendSms}
                values={{ time: currentTime / 1000 }}
                variant="link"
              />
            </View>
          </View>
        </View>

        <Pressable onPress={() => {}} style={styles.getHelpButton}>
          <HelpIcon />
          <BoldCaption
            children={translate('get_help_text')}
            style={styles.getHelpButtonText}
          />
        </Pressable>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  backText: {
    marginLeft: 10,
    marginTop: 5,
  },
  cell: {
    alignItems: 'center',
    backgroundColor: Greys.shade200,
    borderRadius: 8,
    color: Greys.shade800,
    fontFamily: 'OpenSans-Regular',
    fontSize: 35,
    fontWeight: '400',
    height: 60,
    justifyContent: 'center',
    lineHeight: 35,
    overflow: 'hidden',
    paddingTop: web ? 12 : 17,
    textAlign: 'center',
    width: 44,
  },
  codeFieldRoot: {
    gap: 10,
    marginTop: 10,
    maxWidth: 314,
  },
  dismissable: { flex: 1 },
  errorTitle: {
    color: primaryRed,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: Greys.shade0,
  },
  getHelpButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 25,
  },
  getHelpButtonText: {
    color: primaryBlue,
    marginLeft: 5,
    marginTop: 4,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    marginLeft: 22,
    marginTop: 10,
  },
  phoneCodeLabel: {
    marginTop: 30,
    textAlign: 'center',
  },
  root: {
    backgroundColor: Greys.shade0,
    flex: 1,
    padding: 20,
  },
  successContainer: {
    alignItems: 'center',
    backgroundColor: Greys.shade0,
    flex: 1,
    justifyContent: 'center',
  },
  title: { textAlign: 'center' },
  titleContainer: { marginTop: 20, paddingHorizontal: wp('10') },
});
export default SmsVerification;
