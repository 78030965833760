import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { InviteType } from '../../../API';
import { type Invite, InviteStatus } from '../types/types';

import { constants } from '@/constants';
import { asyncStorageMethods } from '@/domain/shared/helpers/asyncStorageMethods';
import { createDebouncedStorage } from '@/domain/shared/helpers/debounceStorageApi';
export type InviteState = {
  addInvites: (targetId: string, invites: Invite[]) => void;
  clearInvites: () => void;
  getInvites: (targetId?: string) => Invite[] | undefined;
  getInvitesGroupedByStatus: (
    targetId?: string,
  ) => Record<string, Invite[]> | undefined;
  getPendingUsers: (targetId: string) => { name: string; phone: string }[];
  invites: Map<string, Invite[]>;
  setInvites: (targetId: string, invites: Invite[]) => void;
};

export const useInviteStore = create<InviteState>()(
  persist(
    immer((set, get) => ({
      addInvites: (targetId: string, invites: Invite[]): void =>
        set(state => {
          const newInvites = invites.filter(
            invite => invite.type === InviteType.RECEIVER,
          );
          const existingInvites = state.invites.get(targetId);
          if (!existingInvites) {
            state.invites.set(targetId, newInvites);
            return;
          }

          for (const invite of newInvites) {
            const foundIndex = existingInvites.findIndex(
              a => a.id === invite.id,
            );
            if (foundIndex > -1) {
              existingInvites[foundIndex] = invite;
            } else {
              existingInvites.push(invite);
            }
          }
          state.invites.set(targetId, existingInvites);
          return state;
        }),
      clearInvites: () =>
        set(state => {
          state.invites = new Map<string, Invite[]>();
        }),
      getInvites: (id?: string) => (id ? get().invites.get(id) : []),
      getInvitesGroupedByStatus: (id?: string) => {
        const invites = get().getInvites(id);
        if (!invites) {
          return;
        }

        const groupedInvites = invites.reduce(
          (acc, invite) => {
            const status = invite.status;
            if (!status) {
              return acc;
            }
            if (!acc[status]) {
              acc[status] = [];
            }
            acc[status].push(invite);
            return acc;
          },
          {} as Record<string, Invite[]>,
        );

        return groupedInvites;
      },
      getPendingUsers: (targetId: string) => {
        const invites = get().getInvitesGroupedByStatus(targetId);
        const users = invites
          ? invites[InviteStatus.PENDING]?.reduce(
              (acc, invite) => {
                const data = {
                  name: invite.inviteeInfo[0].name,
                  phone: invite.inviteeInfo[0].phone,
                };

                if (acc.some(item => item.phone === data.phone)) {
                  return acc;
                }
                return [...acc, data];
              },
              [] as { name: string; phone: string }[],
            )
          : [];
        return users;
      },
      invites: new Map<string, Invite[]>(),
      setInvites: (targetId: string, invites: Invite[]) =>
        set(state => {
          const newInvites = invites.filter(
            invite => invite.type === InviteType.RECEIVER,
          );

          // only set invites if they're different
          const invitesHaveChanged =
            state.invites.get(targetId)?.length !== invites.length ||
            !newInvites.every(invite => {
              const target = state.invites
                .get(targetId)
                ?.find(i => i.id === invite.id);
              return target?.status === invite.status;
            });

          if (!invitesHaveChanged) {
            return;
          }

          state.invites.set(targetId, newInvites);
        }),
    })),
    {
      name: 'invite-state-storage',
      storage: createDebouncedStorage(asyncStorageMethods, {
        debounceTime: constants.storageDebounceTime, // Debounce time in milliseconds ⏳
      }),
      partialize: state => ({
        invites: state.invites,
      }),
    },
  ),
);
