/**
 * These fonts are used in the app components like text, buttons, icons, etc.
 * They need to be consistent throughout the app. These fonts are expected
 * to change quite often for UI trials and experiments.
 */
enum Fonts {
  primary400 = 'OpenSans-Regular',
  primary500 = 'OpenSans-Medium',
  primary600 = 'OpenSans-SemiBold',
  primary700 = 'OpenSans-Bold',
  primary800 = 'OpenSans-ExtraBold',
  secondary500 = 'Inter-Medium',
  secondary600 = 'Inter-SemiBold',
  secondary700 = 'Inter-Bold',
  secondary900 = 'Inter-ExtraBold',
}

export default Fonts;
