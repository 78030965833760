import type React from 'react';
import { StyleSheet, Text, View, type ViewStyle } from 'react-native';

import ColorItem from './ColorItem';

import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import {
  type TableColor,
  tableColors,
} from '@/utilities/constants/table-colors';
import { web } from '@/utilities/platform';

interface TableColorPickerProps {
  containerStyle?: ViewStyle;
  isWhiteMode?: boolean;
  onChange: (color: TableColor) => void;
  style?: ViewStyle;
  title?: string;
  value: TableColor['id'] | undefined;
}

const TableColorPicker: React.FC<TableColorPickerProps> = ({
  containerStyle,
  isWhiteMode = true,
  onChange,
  style,
  title,
  value,
}) => {
  const onPressColor = (color: TableColor) => {
    onChange(color);
  };
  return (
    <View style={[styles.textInputContainer, style]}>
      {title && (
        <View style={{ backgroundColor: Greys.shade0 }}>
          <Text style={styles.title}>{title}</Text>
        </View>
      )}
      <View style={[styles.colorsContainer, containerStyle]}>
        {tableColors.map((color, index) => (
          <ColorItem
            color={color}
            isWhiteMode={isWhiteMode}
            key={index.toString()}
            onPressColor={onPressColor}
            selected={value === color.id}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  colorsContainer: {
    backgroundColor: Greys.shade600,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: web ? 10 : wp(2),
    justifyContent: web ? 'flex-start' : 'space-evenly',
    flexGrow: 1,
    paddingHorizontal: wp(5.5),
  },
  colorsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: wp(10),
  },
  textInputContainer: {
    backgroundColor: Greys.shade600,
    flexDirection: 'column',
  },
  title: {
    color: Greys.shade600,
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 17,
    paddingHorizontal: 20,
  },
});

export default TableColorPicker;
