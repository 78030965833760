import type { SyncError } from 'aws-amplify/datastore';
import mitt, { type Emitter } from 'mitt';

import type { MessageSavedEvent } from './types';

import type { ContactInput } from '@/domain/chatUnit/functions/inviteContactsToDM';
import type { Message } from '@/services/chatUnit/types';
import type { ChatUnit } from '@/services/types';

type Events = {
  chatUnitUpdated: ChatUnit;
  directoryCancelled: string;
  directorySubmitted: {
    contacts: ContactInput[];
    sessionId: string;
  };
  errorDataStoreSync: SyncError<Readonly<Record<string, any>>>;
  inviteUserSubmitted: {
    contacts: ContactInput[];
    sessionId: string;
  };
  messageSaved: MessageSavedEvent;
  // UI
  showChatUnitNotificationToast: Message;
};

export const eventBus: Emitter<Events> = mitt<Events>();
