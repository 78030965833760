import type { Notification } from './types';

import { android, ios } from '@/utilities/platform';

export function asNotification(
  data: Record<string, unknown> | undefined,
): Notification {
  if (ios) return data as Notification;
  if (android) {
    if (data?.['pinpoint.jsonBody'] as string) {
      return JSON.parse(data?.['pinpoint.jsonBody'] as string) as Notification;
    } else {
      return data as Notification;
    }
  }
  throw new Error('Unsupported platform for push notifications');
}
