import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import Svg, { Defs, Stop, Path, RadialGradient } from 'react-native-svg';

import { Greys } from '@/ui/common/colors';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

const AnimatedSpinner = () => {
  // Create an animated value for the rotation
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // Loop the animated value from 0 to 1 indefinitely
    Animated.loop(
      Animated.timing(rotateAnim, {
        toValue: 1, // Animate from 0 to 1
        duration: 1500, // Duration of one full rotation
        easing: Easing.linear, // Use linear easing
        useNativeDriver: true, // Use native driver for better performance
      }),
    ).start();
  }, [rotateAnim]);

  // Interpolate the rotation from the animated value
  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1], // Input range for full rotation
    outputRange: ['0deg', '360deg'], // Output range from 0 to 360 degrees
  });

  // Apply the rotation to the Svg component
  const spinStyle = {
    transform: [{ rotate }],
  };

  return (
    <AnimatedSvg
      style={spinStyle}
      width={54}
      height={54}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M7.515 38.25c6.213 10.761 19.973 14.449 30.735 8.235l2.25 3.898c-12.914 7.455-29.427 3.03-36.883-9.883C-3.838 27.586.587 11.073 13.5 3.617 25.758-3.46 41.26.167 49.178 11.598c.708 1.021.332 2.406-.744 3.027s-2.443.244-3.168-.766C38.604 4.575 25.855 1.68 15.75 7.514 4.99 13.727 1.301 27.488 7.515 38.25z"
        fill="url(#grad)"
      />
      <Defs>
        <RadialGradient
          id="grad"
          cx="0.5"
          cy="0.5"
          r={1.6}
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0.5 0.5) rotate(90) scale(1.0) translate(-0.5 -0.5)">
          <Stop offset="0.5" stopColor={Greys.shade600} />
          <Stop offset="0.75" stopColor={Greys.shade600} stopOpacity="0" />
          <Stop offset="1" stopColor={Greys.shade0} stopOpacity="0" />
        </RadialGradient>
      </Defs>
    </AnimatedSvg>
  );
};

export default AnimatedSpinner;
