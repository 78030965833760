/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum InviteStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
}


export type Invite = {
  __typename: "Invite",
  id: string,
  inviteId: string,
  owner: string,
  type?: InviteType | null,
  status?: InviteStatus | null,
  targetId: string,
  targetType?: InviteTargetType | null,
  message?: string | null,
  isOpenInvite?: boolean | null,
  targetInfo?: string | null,
  inviterInfo?: string | null,
  inviteeInfo?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum InviteType {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
  SENDERSHAREABLE = "SENDERSHAREABLE",
}


export enum InviteTargetType {
  TABLE = "TABLE",
  ROOM = "ROOM",
  QUICK_CHAT = "QUICK_CHAT",
  ORGANISATION = "ORGANISATION",
}


export type CreateActivityInput = {
  PK: string,
  SK: string,
  owner: string,
  userId: string,
  model: ActivityModel,
  type?: string | null,
  relation: string,
  payload: string,
  _version?: number | null,
};

export enum ActivityModel {
  MESSAGE = "MESSAGE",
}


export type ModelActivityConditionInput = {
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  model?: ModelActivityModelInput | null,
  type?: ModelStringInput | null,
  relation?: ModelIDInput | null,
  payload?: ModelStringInput | null,
  and?: Array< ModelActivityConditionInput | null > | null,
  or?: Array< ModelActivityConditionInput | null > | null,
  not?: ModelActivityConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelActivityModelInput = {
  eq?: ActivityModel | null,
  ne?: ActivityModel | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Activity = {
  __typename: "Activity",
  PK: string,
  SK: string,
  owner: string,
  userId: string,
  model: ActivityModel,
  type?: string | null,
  relation: string,
  payload: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateActivityInput = {
  PK: string,
  SK: string,
  owner?: string | null,
  userId?: string | null,
  model?: ActivityModel | null,
  type?: string | null,
  relation?: string | null,
  payload?: string | null,
  _version?: number | null,
};

export type DeleteActivityInput = {
  PK: string,
  SK: string,
  _version?: number | null,
};

export type CreateChatUnitInput = {
  id?: string | null,
  title: string,
  colorId: ChatUnitColorId,
  backgroundImage: string,
  description?: string | null,
  url?: string | null,
  guestOpenInviteId?: string | null,
  memberOpenInviteId?: string | null,
  allowedUserIds?: Array< string | null > | null,
  type: ChatUnitType,
  subtype?: ChatUnitSubType | null,
  allConversationId?: string | null,
  organisationId?: string | null,
  _version?: number | null,
};

export enum ChatUnitColorId {
  BLACK = "BLACK",
  RED = "RED",
  ORANGE = "ORANGE",
  LIME = "LIME",
  GREEN = "GREEN",
  TURQUOISE = "TURQUOISE",
  SKY = "SKY",
  BLUE = "BLUE",
  VIOLET = "VIOLET",
  PURPLE = "PURPLE",
  PINK = "PINK",
  MAGENTA = "MAGENTA",
}


export enum ChatUnitType {
  TABLE = "TABLE",
  ROOM = "ROOM",
  QUICK_CHAT = "QUICK_CHAT",
}


export enum ChatUnitSubType {
  ONE_ON_ONE = "ONE_ON_ONE",
  GROUP_CHAT = "GROUP_CHAT",
}


export type ModelChatUnitConditionInput = {
  title?: ModelStringInput | null,
  colorId?: ModelChatUnitColorIdInput | null,
  backgroundImage?: ModelStringInput | null,
  description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  guestOpenInviteId?: ModelStringInput | null,
  memberOpenInviteId?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  type?: ModelChatUnitTypeInput | null,
  subtype?: ModelChatUnitSubTypeInput | null,
  allConversationId?: ModelIDInput | null,
  organisationId?: ModelStringInput | null,
  and?: Array< ModelChatUnitConditionInput | null > | null,
  or?: Array< ModelChatUnitConditionInput | null > | null,
  not?: ModelChatUnitConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelChatUnitColorIdInput = {
  eq?: ChatUnitColorId | null,
  ne?: ChatUnitColorId | null,
};

export type ModelChatUnitTypeInput = {
  eq?: ChatUnitType | null,
  ne?: ChatUnitType | null,
};

export type ModelChatUnitSubTypeInput = {
  eq?: ChatUnitSubType | null,
  ne?: ChatUnitSubType | null,
};

export type ChatUnit = {
  __typename: "ChatUnit",
  id: string,
  title: string,
  colorId: ChatUnitColorId,
  backgroundImage: string,
  description?: string | null,
  url?: string | null,
  guestOpenInviteId?: string | null,
  memberOpenInviteId?: string | null,
  chatUnitUsers?: ModelChatUnitUserConnection | null,
  allowedUserIds?: Array< string | null > | null,
  type: ChatUnitType,
  subtype?: ChatUnitSubType | null,
  allConversationId?: string | null,
  ownerDetails?: ChatUnitUser | null,
  organisationId?: string | null,
  organisation?: Organisation | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelChatUnitUserConnection = {
  __typename: "ModelChatUnitUserConnection",
  items:  Array<ChatUnitUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ChatUnitUser = {
  __typename: "ChatUnitUser",
  id: string,
  chatUnitId: string,
  userId: string,
  username?: string | null,
  phoneNumber?: string | null,
  avatar?: string | null,
  status?: string | null,
  allowedUserIds?: Array< string | null > | null,
  conversationIds?: Array< string | null > | null,
  chatUnit?: ChatUnit | null,
  firstName?: string | null,
  lastName?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  chatUnitChatUnitUsersId?: string | null,
  owner?: string | null,
};

export type Organisation = {
  __typename: "Organisation",
  id: string,
  name?: string | null,
  billingId?: string | null,
  users?: Array< string | null > | null,
  members?:  Array<MemberType | null > | null,
  allowedUserIds?: Array< string | null > | null,
  plan?: string | null,
  image?: string | null,
  usage?: Usage | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type MemberType = {
  __typename: "MemberType",
  userId?: string | null,
  role: Roles,
  status: Statuses,
  name?: string | null,
  phoneNumber: string,
  avatar?: string | null,
};

export enum Roles {
  OWNER = "OWNER",
  MEMBER = "MEMBER",
  ADMIN = "ADMIN",
  GUEST = "GUEST",
}


export enum Statuses {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  PENDING = "PENDING",
}


export type Usage = {
  __typename: "Usage",
  guests: number,
  tables: number,
  rooms: number,
};

export type UpdateChatUnitInput = {
  id: string,
  title?: string | null,
  colorId?: ChatUnitColorId | null,
  backgroundImage?: string | null,
  description?: string | null,
  url?: string | null,
  guestOpenInviteId?: string | null,
  memberOpenInviteId?: string | null,
  allowedUserIds?: Array< string | null > | null,
  type?: ChatUnitType | null,
  subtype?: ChatUnitSubType | null,
  allConversationId?: string | null,
  organisationId?: string | null,
  _version?: number | null,
};

export type DeleteChatUnitInput = {
  id: string,
  _version?: number | null,
};

export type CreateInviteInput = {
  id?: string | null,
  inviteId: string,
  owner: string,
  type?: InviteType | null,
  status?: InviteStatus | null,
  targetId: string,
  targetType?: InviteTargetType | null,
  message?: string | null,
  isOpenInvite?: boolean | null,
  targetInfo?: string | null,
  inviterInfo?: string | null,
  inviteeInfo?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelInviteConditionInput = {
  inviteId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  type?: ModelInviteTypeInput | null,
  status?: ModelInviteStatusInput | null,
  targetId?: ModelIDInput | null,
  targetType?: ModelInviteTargetTypeInput | null,
  message?: ModelStringInput | null,
  isOpenInvite?: ModelBooleanInput | null,
  targetInfo?: ModelStringInput | null,
  inviterInfo?: ModelStringInput | null,
  inviteeInfo?: ModelStringInput | null,
  and?: Array< ModelInviteConditionInput | null > | null,
  or?: Array< ModelInviteConditionInput | null > | null,
  not?: ModelInviteConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelInviteTypeInput = {
  eq?: InviteType | null,
  ne?: InviteType | null,
};

export type ModelInviteStatusInput = {
  eq?: InviteStatus | null,
  ne?: InviteStatus | null,
};

export type ModelInviteTargetTypeInput = {
  eq?: InviteTargetType | null,
  ne?: InviteTargetType | null,
};

export type UpdateInviteInput = {
  id: string,
  inviteId?: string | null,
  owner?: string | null,
  type?: InviteType | null,
  status?: InviteStatus | null,
  targetId?: string | null,
  targetType?: InviteTargetType | null,
  message?: string | null,
  isOpenInvite?: boolean | null,
  targetInfo?: string | null,
  inviterInfo?: string | null,
  inviteeInfo?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteInviteInput = {
  id: string,
  _version?: number | null,
};

export type CreateConversationInput = {
  conversationId?: string | null,
  chatUnitId?: string | null,
  owner?: string | null,
  allowedUserIds?: Array< string | null > | null,
  chatUnitUserIds?: Array< string | null > | null,
};

export type ModelConversationConditionInput = {
  chatUnitUserIds?: ModelIDInput | null,
  allowedUserIds?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationConditionInput | null > | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  not?: ModelConversationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Conversation = {
  __typename: "Conversation",
  conversationId: string,
  chatUnitId: string,
  chatUnit?: ChatUnit | null,
  chatUnitUserIds?: Array< string | null > | null,
  allowedUserIds?: Array< string | null > | null,
  updatedAt: string,
  createdAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateConversationInput = {
  conversationId: string,
  chatUnitId: string,
  chatUnitUserIds?: Array< string | null > | null,
  allowedUserIds?: Array< string | null > | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteConversationInput = {
  conversationId: string,
  chatUnitId: string,
  _version?: number | null,
};

export type CreateMessageInput = {
  id?: string | null,
  conversationId: string,
  userId: string,
  chatUnitId: string,
  text?: string | null,
  image?: string | null,
  chatUnitUserId: string,
  createdAt?: string | null,
  allowedUserIds?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelMessageConditionInput = {
  conversationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  image?: ModelStringInput | null,
  chatUnitUserId?: ModelIDInput | null,
  allowedUserIds?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  conversationId: string,
  userId: string,
  chatUnitId: string,
  text?: string | null,
  image?: string | null,
  chatUnitUserId: string,
  createdAt: string,
  allowedUserIds?: Array< string | null > | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateMessageInput = {
  id: string,
  conversationId?: string | null,
  userId?: string | null,
  chatUnitId?: string | null,
  text?: string | null,
  image?: string | null,
  chatUnitUserId?: string | null,
  createdAt: string,
  allowedUserIds?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteMessageInput = {
  id: string,
  createdAt: string,
  _version?: number | null,
};

export type CreateMessageV2Input = {
  id?: string | null,
  userId: string,
  SK: string,
  allowedUserIds?: Array< string | null > | null,
  authorId: string,
  chatUnitUserId: string,
  chatUnitId: string,
  chatUnitIdConversationId: string,
  conversationId: string,
  createdAt?: string | null,
  image?: string | null,
  messageId: string,
  owner: string,
  status?: string | null,
  text?: string | null,
  source?: string | null,
  metaData?: string | null,
  assetMetadata?: string | null,
  _version?: number | null,
};

export type ModelMessageV2ConditionInput = {
  userId?: ModelIDInput | null,
  SK?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  authorId?: ModelIDInput | null,
  chatUnitUserId?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  chatUnitIdConversationId?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  image?: ModelStringInput | null,
  messageId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  status?: ModelStringInput | null,
  text?: ModelStringInput | null,
  source?: ModelStringInput | null,
  metaData?: ModelStringInput | null,
  assetMetadata?: ModelStringInput | null,
  and?: Array< ModelMessageV2ConditionInput | null > | null,
  or?: Array< ModelMessageV2ConditionInput | null > | null,
  not?: ModelMessageV2ConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MessageV2 = {
  __typename: "MessageV2",
  id: string,
  userId: string,
  SK: string,
  allowedUserIds?: Array< string | null > | null,
  authorId: string,
  chatUnitUserId: string,
  chatUnitId: string,
  chatUnitIdConversationId: string,
  conversationId: string,
  createdAt: string,
  image?: string | null,
  messageId: string,
  owner: string,
  status?: string | null,
  text?: string | null,
  source?: string | null,
  metaData?: string | null,
  assetMetadata?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMessageV2Input = {
  id: string,
  userId?: string | null,
  SK?: string | null,
  allowedUserIds?: Array< string | null > | null,
  authorId?: string | null,
  chatUnitUserId?: string | null,
  chatUnitId?: string | null,
  chatUnitIdConversationId?: string | null,
  conversationId?: string | null,
  createdAt?: string | null,
  image?: string | null,
  messageId?: string | null,
  owner?: string | null,
  status?: string | null,
  text?: string | null,
  source?: string | null,
  metaData?: string | null,
  assetMetadata?: string | null,
  _version: number,
};

export type DeleteMessageV2Input = {
  id: string,
  _version?: number | null,
};

export type CreateOrganisationInput = {
  id?: string | null,
  name?: string | null,
  billingId?: string | null,
  users?: Array< string | null > | null,
  members?: Array< MemberTypeInput | null > | null,
  allowedUserIds?: Array< string | null > | null,
  plan?: string | null,
  image?: string | null,
  usage?: UsageInput | null,
  _version?: number | null,
};

export type MemberTypeInput = {
  userId?: string | null,
  role: Roles,
  status: Statuses,
  name?: string | null,
  phoneNumber: string,
  avatar?: string | null,
};

export type UsageInput = {
  guests: number,
  tables: number,
  rooms: number,
};

export type ModelOrganisationConditionInput = {
  name?: ModelStringInput | null,
  billingId?: ModelStringInput | null,
  users?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  plan?: ModelStringInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelOrganisationConditionInput | null > | null,
  or?: Array< ModelOrganisationConditionInput | null > | null,
  not?: ModelOrganisationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateOrganisationInput = {
  id: string,
  name?: string | null,
  billingId?: string | null,
  users?: Array< string | null > | null,
  members?: Array< MemberTypeInput | null > | null,
  allowedUserIds?: Array< string | null > | null,
  plan?: string | null,
  image?: string | null,
  usage?: UsageInput | null,
  _version?: number | null,
};

export type DeleteOrganisationInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  owner?: string | null,
  name?: string | null,
  username?: string | null,
  avatar?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  gender?: string | null,
  birthday?: string | null,
  pronouns?: string | null,
  marketingOptIn?: boolean | null,
  organisations?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  pronouns?: ModelStringInput | null,
  marketingOptIn?: ModelBooleanInput | null,
  organisations?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username?: string | null,
  avatar?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  gender?: string | null,
  birthday?: string | null,
  pronouns?: string | null,
  marketingOptIn?: boolean | null,
  organisations?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username?: string | null,
  avatar?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  gender?: string | null,
  birthday?: string | null,
  pronouns?: string | null,
  marketingOptIn?: boolean | null,
  organisations?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateChatUnitUserInput = {
  id?: string | null,
  chatUnitId: string,
  userId: string,
  username?: string | null,
  avatar?: string | null,
  status?: string | null,
  owner?: string | null,
  phoneNumber?: string | null,
  allowedUserIds?: Array< string | null > | null,
  chatUnitChatUnitUsersId?: string | null,
};

export type ModelChatUnitUserConditionInput = {
  chatUnitId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  status?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  conversationIds?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  and?: Array< ModelChatUnitUserConditionInput | null > | null,
  or?: Array< ModelChatUnitUserConditionInput | null > | null,
  not?: ModelChatUnitUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  chatUnitChatUnitUsersId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateChatUnitUserInput = {
  id: string,
  chatUnitId?: string | null,
  userId?: string | null,
  username?: string | null,
  phoneNumber?: string | null,
  avatar?: string | null,
  status?: string | null,
  allowedUserIds?: Array< string | null > | null,
  conversationIds?: Array< string | null > | null,
  firstName?: string | null,
  lastName?: string | null,
  _version?: number | null,
  chatUnitChatUnitUsersId?: string | null,
};

export type DeleteChatUnitUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateNewInviteInput = {
  type: InviteType,
  status: InviteStatus,
  targetId: string,
  targetType?: InviteTargetType | null,
  message?: string | null,
  owner: string,
  targetInfo?: string | null,
  inviterInfo?: string | null,
  inviteeInfo?: Array< string | null > | null,
};

export type CreateNewInviteResponse = {
  __typename: "CreateNewInviteResponse",
  inviteId: string,
  message?: string | null,
  newInvites?:  Array<Invite | null > | null,
  newSenderInvite?: Invite | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelActivityFilterInput = {
  PK?: ModelStringInput | null,
  SK?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  model?: ModelActivityModelInput | null,
  type?: ModelStringInput | null,
  relation?: ModelIDInput | null,
  payload?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelActivityFilterInput | null > | null,
  or?: Array< ModelActivityFilterInput | null > | null,
  not?: ModelActivityFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelActivityConnection = {
  __typename: "ModelActivityConnection",
  items:  Array<Activity | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChatUnitFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  colorId?: ModelChatUnitColorIdInput | null,
  backgroundImage?: ModelStringInput | null,
  description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  guestOpenInviteId?: ModelStringInput | null,
  memberOpenInviteId?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  type?: ModelChatUnitTypeInput | null,
  subtype?: ModelChatUnitSubTypeInput | null,
  allConversationId?: ModelIDInput | null,
  organisationId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatUnitFilterInput | null > | null,
  or?: Array< ModelChatUnitFilterInput | null > | null,
  not?: ModelChatUnitFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelChatUnitConnection = {
  __typename: "ModelChatUnitConnection",
  items:  Array<ChatUnit | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInviteFilterInput = {
  id?: ModelIDInput | null,
  inviteId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  type?: ModelInviteTypeInput | null,
  status?: ModelInviteStatusInput | null,
  targetId?: ModelIDInput | null,
  targetType?: ModelInviteTargetTypeInput | null,
  message?: ModelStringInput | null,
  isOpenInvite?: ModelBooleanInput | null,
  targetInfo?: ModelStringInput | null,
  inviterInfo?: ModelStringInput | null,
  inviteeInfo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInviteFilterInput | null > | null,
  or?: Array< ModelInviteFilterInput | null > | null,
  not?: ModelInviteFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInviteConnection = {
  __typename: "ModelInviteConnection",
  items:  Array<Invite | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelConversationFilterInput = {
  conversationId?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  chatUnitUserIds?: ModelIDInput | null,
  allowedUserIds?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConversationFilterInput | null > | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  not?: ModelConversationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items:  Array<Conversation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  conversationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  image?: ModelStringInput | null,
  chatUnitUserId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMessageV2FilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  SK?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  authorId?: ModelIDInput | null,
  chatUnitUserId?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  chatUnitIdConversationId?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  image?: ModelStringInput | null,
  messageId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  status?: ModelStringInput | null,
  text?: ModelStringInput | null,
  source?: ModelStringInput | null,
  metaData?: ModelStringInput | null,
  assetMetadata?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMessageV2FilterInput | null > | null,
  or?: Array< ModelMessageV2FilterInput | null > | null,
  not?: ModelMessageV2FilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMessageV2Connection = {
  __typename: "ModelMessageV2Connection",
  items:  Array<MessageV2 | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrganisationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  billingId?: ModelStringInput | null,
  users?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  plan?: ModelStringInput | null,
  image?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganisationFilterInput | null > | null,
  or?: Array< ModelOrganisationFilterInput | null > | null,
  not?: ModelOrganisationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOrganisationConnection = {
  __typename: "ModelOrganisationConnection",
  items:  Array<Organisation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  pronouns?: ModelStringInput | null,
  marketingOptIn?: ModelBooleanInput | null,
  organisations?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChatUnitUserFilterInput = {
  id?: ModelIDInput | null,
  chatUnitId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  status?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
  conversationIds?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatUnitUserFilterInput | null > | null,
  or?: Array< ModelChatUnitUserFilterInput | null > | null,
  not?: ModelChatUnitUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  chatUnitChatUnitUsersId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ConversationMessages = {
  __typename: "ConversationMessages",
  chatUnitId: string,
  conversationId: string,
  messages?:  Array<MessageV2 | null > | null,
  nextToken?: string | null,
};

export type OpenChatUnitResponse = {
  __typename: "OpenChatUnitResponse",
  id: string,
  title: string,
  colorId: ChatUnitColorId,
  backgroundImage: string,
  description?: string | null,
  url?: string | null,
  guestOpenInviteId?: string | null,
  memberOpenInviteId?: string | null,
  chatUnitUsers?: ChatUnitUserItems | null,
  allowedUserIds?: Array< string | null > | null,
  type: ChatUnitType,
  subtype?: ChatUnitSubType | null,
  allConversationId?: string | null,
  ownerDetails?: ChatUnitUser | null,
  organisationId?: string | null,
  organisation?: Organisation | null,
};

export type ChatUnitUserItems = {
  __typename: "ChatUnitUserItems",
  items?:  Array<ChatUnitUser | null > | null,
};

export type ModelSubscriptionActivityFilterInput = {
  PK?: ModelSubscriptionStringInput | null,
  SK?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  model?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  relation?: ModelSubscriptionIDInput | null,
  payload?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionActivityFilterInput | null > | null,
  or?: Array< ModelSubscriptionActivityFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionChatUnitFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  colorId?: ModelSubscriptionStringInput | null,
  backgroundImage?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  guestOpenInviteId?: ModelSubscriptionStringInput | null,
  memberOpenInviteId?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  subtype?: ModelSubscriptionStringInput | null,
  allConversationId?: ModelSubscriptionIDInput | null,
  organisationId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatUnitFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatUnitFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  chatUnitChatUnitUsersId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
};

export type ModelSubscriptionInviteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  inviteId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  targetId?: ModelSubscriptionIDInput | null,
  targetType?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  isOpenInvite?: ModelSubscriptionBooleanInput | null,
  targetInfo?: ModelSubscriptionStringInput | null,
  inviterInfo?: ModelSubscriptionStringInput | null,
  inviteeInfo?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInviteFilterInput | null > | null,
  or?: Array< ModelSubscriptionInviteFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionConversationFilterInput = {
  conversationId?: ModelSubscriptionIDInput | null,
  chatUnitId?: ModelSubscriptionIDInput | null,
  chatUnitUserIds?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conversationId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  chatUnitId?: ModelSubscriptionIDInput | null,
  text?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  chatUnitUserId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
};

export type ModelSubscriptionMessageV2FilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  SK?: ModelSubscriptionStringInput | null,
  allowedUserIds?: ModelSubscriptionStringInput | null,
  authorId?: ModelSubscriptionIDInput | null,
  chatUnitUserId?: ModelSubscriptionIDInput | null,
  chatUnitId?: ModelSubscriptionIDInput | null,
  chatUnitIdConversationId?: ModelSubscriptionStringInput | null,
  conversationId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  messageId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  source?: ModelSubscriptionStringInput | null,
  metaData?: ModelSubscriptionStringInput | null,
  assetMetadata?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMessageV2FilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageV2FilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionOrganisationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  billingId?: ModelSubscriptionStringInput | null,
  users?: ModelSubscriptionStringInput | null,
  plan?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganisationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganisationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  pronouns?: ModelSubscriptionStringInput | null,
  marketingOptIn?: ModelSubscriptionBooleanInput | null,
  organisations?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionChatUnitUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  chatUnitId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  conversationIds?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatUnitUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatUnitUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  allowedUserIds?: ModelStringInput | null,
};

export type AcceptShareableInviteForTargetMutationVariables = {
  id: string,
};

export type AcceptShareableInviteForTargetMutation = {
  acceptShareableInviteForTarget?: string | null,
};

export type UpdateInviteStatusMutationVariables = {
  id: string,
  status: InviteStatus,
};

export type UpdateInviteStatusMutation = {
  updateInviteStatus?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateActivityMutationVariables = {
  input: CreateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type CreateActivityMutation = {
  createActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateActivityMutationVariables = {
  input: UpdateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type UpdateActivityMutation = {
  updateActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteActivityMutationVariables = {
  input: DeleteActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type DeleteActivityMutation = {
  deleteActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChatUnitMutationVariables = {
  input: CreateChatUnitInput,
  condition?: ModelChatUnitConditionInput | null,
};

export type CreateChatUnitMutation = {
  createChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateChatUnitMutationVariables = {
  input: UpdateChatUnitInput,
  condition?: ModelChatUnitConditionInput | null,
};

export type UpdateChatUnitMutation = {
  updateChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteChatUnitMutationVariables = {
  input: DeleteChatUnitInput,
  condition?: ModelChatUnitConditionInput | null,
};

export type DeleteChatUnitMutation = {
  deleteChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInviteMutationVariables = {
  input: CreateInviteInput,
  condition?: ModelInviteConditionInput | null,
};

export type CreateInviteMutation = {
  createInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInviteMutationVariables = {
  input: UpdateInviteInput,
  condition?: ModelInviteConditionInput | null,
};

export type UpdateInviteMutation = {
  updateInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInviteMutationVariables = {
  input: DeleteInviteInput,
  condition?: ModelInviteConditionInput | null,
};

export type DeleteInviteMutation = {
  deleteInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConversationMutationVariables = {
  input: CreateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type DeleteConversationMutation = {
  deleteConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateMessageV2MutationVariables = {
  input: CreateMessageV2Input,
  condition?: ModelMessageV2ConditionInput | null,
};

export type CreateMessageV2Mutation = {
  createMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMessageV2MutationVariables = {
  input: UpdateMessageV2Input,
  condition?: ModelMessageV2ConditionInput | null,
};

export type UpdateMessageV2Mutation = {
  updateMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMessageV2MutationVariables = {
  input: DeleteMessageV2Input,
  condition?: ModelMessageV2ConditionInput | null,
};

export type DeleteMessageV2Mutation = {
  deleteMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type CreateOrganisationMutation = {
  createOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type UpdateOrganisationMutation = {
  updateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteOrganisationMutationVariables = {
  input: DeleteOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type DeleteOrganisationMutation = {
  deleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateChatUnitUserMutationVariables = {
  input: CreateChatUnitUserInput,
  condition?: ModelChatUnitUserConditionInput | null,
};

export type CreateChatUnitUserMutation = {
  createChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateChatUnitUserMutationVariables = {
  input: UpdateChatUnitUserInput,
  condition?: ModelChatUnitUserConditionInput | null,
};

export type UpdateChatUnitUserMutation = {
  updateChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteChatUnitUserMutationVariables = {
  input: DeleteChatUnitUserInput,
  condition?: ModelChatUnitUserConditionInput | null,
};

export type DeleteChatUnitUserMutation = {
  deleteChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateNewInviteMutationVariables = {
  input: CreateNewInviteInput,
};

export type CreateNewInviteMutation = {
  createNewInvite?:  {
    __typename: "CreateNewInviteResponse",
    inviteId: string,
    message?: string | null,
    newInvites?:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    newSenderInvite?:  {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
  } | null,
};

export type BatchUpdateMessagesMutationVariables = {
  input: Array< UpdateMessageV2Input >,
};

export type BatchUpdateMessagesMutation = {
  batchUpdateMessages?: string | null,
};

export type SendPushMessagesMutationVariables = {
  msg?: string | null,
  title?: string | null,
};

export type SendPushMessagesMutation = {
  sendPushMessages?: string | null,
};

export type JoinOpenInviteMutationVariables = {
  chatUnitId?: string | null,
  uniqueUrlId?: string | null,
  userId?: string | null,
};

export type JoinOpenInviteMutation = {
  joinOpenInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetActivityQueryVariables = {
  PK: string,
  SK: string,
};

export type GetActivityQuery = {
  getActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListActivitiesQueryVariables = {
  PK?: string | null,
  SK?: ModelStringKeyConditionInput | null,
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActivitiesQuery = {
  listActivities?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      PK: string,
      SK: string,
      owner: string,
      userId: string,
      model: ActivityModel,
      type?: string | null,
      relation: string,
      payload: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncActivitiesQueryVariables = {
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncActivitiesQuery = {
  syncActivities?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      PK: string,
      SK: string,
      owner: string,
      userId: string,
      model: ActivityModel,
      type?: string | null,
      relation: string,
      payload: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ActivitiesByOwnerAndTypeQueryVariables = {
  owner: string,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivitiesByOwnerAndTypeQuery = {
  activitiesByOwnerAndType?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      PK: string,
      SK: string,
      owner: string,
      userId: string,
      model: ActivityModel,
      type?: string | null,
      relation: string,
      payload: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ActivitiesByRelationQueryVariables = {
  relation: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivitiesByRelationQuery = {
  activitiesByRelation?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      PK: string,
      SK: string,
      owner: string,
      userId: string,
      model: ActivityModel,
      type?: string | null,
      relation: string,
      payload: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChatUnitQueryVariables = {
  id: string,
};

export type GetChatUnitQuery = {
  getChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListChatUnitsQueryVariables = {
  filter?: ModelChatUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatUnitsQuery = {
  listChatUnits?:  {
    __typename: "ModelChatUnitConnection",
    items:  Array< {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChatUnitsQueryVariables = {
  filter?: ModelChatUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChatUnitsQuery = {
  syncChatUnits?:  {
    __typename: "ModelChatUnitConnection",
    items:  Array< {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatUnitByGuestOpenInviteIdQueryVariables = {
  guestOpenInviteId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatUnitByGuestOpenInviteIdQuery = {
  chatUnitByGuestOpenInviteId?:  {
    __typename: "ModelChatUnitConnection",
    items:  Array< {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatUnitByMemberOpenInviteIdQueryVariables = {
  memberOpenInviteId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatUnitByMemberOpenInviteIdQuery = {
  chatUnitByMemberOpenInviteId?:  {
    __typename: "ModelChatUnitConnection",
    items:  Array< {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInviteQueryVariables = {
  id: string,
};

export type GetInviteQuery = {
  getInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvitesQueryVariables = {
  id?: string | null,
  filter?: ModelInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvitesQuery = {
  listInvites?:  {
    __typename: "ModelInviteConnection",
    items:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvitesQueryVariables = {
  filter?: ModelInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvitesQuery = {
  syncInvites?:  {
    __typename: "ModelInviteConnection",
    items:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvitesByInviteIdAndOwnerQueryVariables = {
  inviteId: string,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitesByInviteIdAndOwnerQuery = {
  invitesByInviteIdAndOwner?:  {
    __typename: "ModelInviteConnection",
    items:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvitesByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitesByOwnerQuery = {
  invitesByOwner?:  {
    __typename: "ModelInviteConnection",
    items:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvitesByTargetIdAndStatusQueryVariables = {
  targetId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitesByTargetIdAndStatusQuery = {
  invitesByTargetIdAndStatus?:  {
    __typename: "ModelInviteConnection",
    items:  Array< {
      __typename: "Invite",
      id: string,
      inviteId: string,
      owner: string,
      type?: InviteType | null,
      status?: InviteStatus | null,
      targetId: string,
      targetType?: InviteTargetType | null,
      message?: string | null,
      isOpenInvite?: boolean | null,
      targetInfo?: string | null,
      inviterInfo?: string | null,
      inviteeInfo?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConversationQueryVariables = {
  conversationId: string,
  chatUnitId: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListConversationsQueryVariables = {
  conversationId?: string | null,
  chatUnitId?: ModelIDKeyConditionInput | null,
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConversationsQuery = {
  listConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      conversationId: string,
      chatUnitId: string,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      chatUnitUserIds?: Array< string | null > | null,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConversationsQuery = {
  syncConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      conversationId: string,
      chatUnitId: string,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      chatUnitUserIds?: Array< string | null > | null,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConversationByChatUnitIDQueryVariables = {
  chatUnitId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConversationByChatUnitIDQuery = {
  conversationByChatUnitID?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      conversationId: string,
      chatUnitId: string,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      chatUnitUserIds?: Array< string | null > | null,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListMessagesQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      conversationId: string,
      userId: string,
      chatUnitId: string,
      text?: string | null,
      image?: string | null,
      chatUnitUserId: string,
      createdAt: string,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMessagesQuery = {
  syncMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      conversationId: string,
      userId: string,
      chatUnitId: string,
      text?: string | null,
      image?: string | null,
      chatUnitUserId: string,
      createdAt: string,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByConversationIDQueryVariables = {
  conversationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByConversationIDQuery = {
  messagesByConversationID?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      conversationId: string,
      userId: string,
      chatUnitId: string,
      text?: string | null,
      image?: string | null,
      chatUnitUserId: string,
      createdAt: string,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByChatUnitIDQueryVariables = {
  chatUnitId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByChatUnitIDQuery = {
  messagesByChatUnitID?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      conversationId: string,
      userId: string,
      chatUnitId: string,
      text?: string | null,
      image?: string | null,
      chatUnitUserId: string,
      createdAt: string,
      allowedUserIds?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMessageV2QueryVariables = {
  id: string,
};

export type GetMessageV2Query = {
  getMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMessageV2sQueryVariables = {
  id?: string | null,
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMessageV2sQuery = {
  listMessageV2s?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMessageV2sQueryVariables = {
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMessageV2sQuery = {
  syncMessageV2s?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByUserIdV2QueryVariables = {
  userId: string,
  SK?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByUserIdV2Query = {
  messagesByUserIdV2?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByChatUnitIdConversationIdV2QueryVariables = {
  chatUnitIdConversationId: string,
  SK?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByChatUnitIdConversationIdV2Query = {
  messagesByChatUnitIdConversationIdV2?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByConversationIdV2QueryVariables = {
  conversationId: string,
  SK?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByConversationIdV2Query = {
  messagesByConversationIdV2?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByMessageIdV2QueryVariables = {
  messageId: string,
  SK?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByMessageIdV2Query = {
  messagesByMessageIdV2?:  {
    __typename: "ModelMessageV2Connection",
    items:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganisationQueryVariables = {
  id: string,
};

export type GetOrganisationQuery = {
  getOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListOrganisationsQueryVariables = {
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganisationsQuery = {
  listOrganisations?:  {
    __typename: "ModelOrganisationConnection",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganisationsQueryVariables = {
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganisationsQuery = {
  syncOrganisations?:  {
    __typename: "ModelOrganisationConnection",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      username?: string | null,
      avatar?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      gender?: string | null,
      birthday?: string | null,
      pronouns?: string | null,
      marketingOptIn?: boolean | null,
      organisations?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      username?: string | null,
      avatar?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      gender?: string | null,
      birthday?: string | null,
      pronouns?: string | null,
      marketingOptIn?: boolean | null,
      organisations?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChatUnitUserQueryVariables = {
  id: string,
};

export type GetChatUnitUserQuery = {
  getChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListChatUnitUsersQueryVariables = {
  filter?: ModelChatUnitUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatUnitUsersQuery = {
  listChatUnitUsers?:  {
    __typename: "ModelChatUnitUserConnection",
    items:  Array< {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChatUnitUsersQueryVariables = {
  filter?: ModelChatUnitUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChatUnitUsersQuery = {
  syncChatUnitUsers?:  {
    __typename: "ModelChatUnitUserConnection",
    items:  Array< {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatUnitUserByChatUnitIDQueryVariables = {
  chatUnitId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatUnitUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatUnitUserByChatUnitIDQuery = {
  chatUnitUserByChatUnitID?:  {
    __typename: "ModelChatUnitUserConnection",
    items:  Array< {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatUnitUserByUserIDQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatUnitUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatUnitUserByUserIDQuery = {
  chatUnitUserByUserID?:  {
    __typename: "ModelChatUnitUserConnection",
    items:  Array< {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBatchConversationMessagesQueryVariables = {
  chatUnitIdConversationIds?: Array< string > | null,
  lastMessageTime?: string | null,
  nextTokens?: Array< string | null > | null,
  limit?: number | null,
};

export type GetBatchConversationMessagesQuery = {
  getBatchConversationMessages?:  Array< {
    __typename: "ConversationMessages",
    chatUnitId: string,
    conversationId: string,
    messages?:  Array< {
      __typename: "MessageV2",
      id: string,
      userId: string,
      SK: string,
      allowedUserIds?: Array< string | null > | null,
      authorId: string,
      chatUnitUserId: string,
      chatUnitId: string,
      chatUnitIdConversationId: string,
      conversationId: string,
      createdAt: string,
      image?: string | null,
      messageId: string,
      owner: string,
      status?: string | null,
      text?: string | null,
      source?: string | null,
      metaData?: string | null,
      assetMetadata?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    nextToken?: string | null,
  } | null > | null,
};

export type GetInvitesForTargetQueryVariables = {
  id: string,
};

export type GetInvitesForTargetQuery = {
  getInvitesForTarget?:  Array< {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null > | null,
};

export type GetOrganisationByUserQueryVariables = {
};

export type GetOrganisationByUserQuery = {
  getOrganisationByUser?:  Array< {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null > | null,
};

export type GetUsersDetailsQueryVariables = {
  users?: Array< string | null > | null,
};

export type GetUsersDetailsQuery = {
  getUsersDetails?:  Array< {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null > | null,
};

export type GetChatUnitByUrlQueryVariables = {
  uniqueUrlId?: string | null,
};

export type GetChatUnitByUrlQuery = {
  getChatUnitByUrl?:  {
    __typename: "OpenChatUnitResponse",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ChatUnitUserItems",
      items?:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null > | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
  } | null,
};

export type GetPublicChatUnitByUrlQueryVariables = {
  uniqueUrlId?: string | null,
};

export type GetPublicChatUnitByUrlQuery = {
  getPublicChatUnitByUrl?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
  owner?: string | null,
};

export type OnCreateActivitySubscription = {
  onCreateActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
  owner?: string | null,
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
  owner?: string | null,
};

export type OnDeleteActivitySubscription = {
  onDeleteActivity?:  {
    __typename: "Activity",
    PK: string,
    SK: string,
    owner: string,
    userId: string,
    model: ActivityModel,
    type?: string | null,
    relation: string,
    payload: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChatUnitSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatUnitSubscription = {
  onCreateChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateChatUnitSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatUnitSubscription = {
  onUpdateChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteChatUnitSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatUnitSubscription = {
  onDeleteChatUnit?:  {
    __typename: "ChatUnit",
    id: string,
    title: string,
    colorId: ChatUnitColorId,
    backgroundImage: string,
    description?: string | null,
    url?: string | null,
    guestOpenInviteId?: string | null,
    memberOpenInviteId?: string | null,
    chatUnitUsers?:  {
      __typename: "ModelChatUnitUserConnection",
      items:  Array< {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUserIds?: Array< string | null > | null,
    type: ChatUnitType,
    subtype?: ChatUnitSubType | null,
    allConversationId?: string | null,
    ownerDetails?:  {
      __typename: "ChatUnitUser",
      id: string,
      chatUnitId: string,
      userId: string,
      username?: string | null,
      phoneNumber?: string | null,
      avatar?: string | null,
      status?: string | null,
      allowedUserIds?: Array< string | null > | null,
      conversationIds?: Array< string | null > | null,
      chatUnit?:  {
        __typename: "ChatUnit",
        id: string,
        title: string,
        colorId: ChatUnitColorId,
        backgroundImage: string,
        description?: string | null,
        url?: string | null,
        guestOpenInviteId?: string | null,
        memberOpenInviteId?: string | null,
        chatUnitUsers?:  {
          __typename: "ModelChatUnitUserConnection",
          items:  Array< {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        allowedUserIds?: Array< string | null > | null,
        type: ChatUnitType,
        subtype?: ChatUnitSubType | null,
        allConversationId?: string | null,
        ownerDetails?:  {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null,
        organisationId?: string | null,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name?: string | null,
          billingId?: string | null,
          users?: Array< string | null > | null,
          members?:  Array< {
            __typename: "MemberType",
            userId?: string | null,
            role: Roles,
            status: Statuses,
            name?: string | null,
            phoneNumber: string,
            avatar?: string | null,
          } | null > | null,
          allowedUserIds?: Array< string | null > | null,
          plan?: string | null,
          image?: string | null,
          usage?:  {
            __typename: "Usage",
            guests: number,
            tables: number,
            rooms: number,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      chatUnitChatUnitUsersId?: string | null,
      owner?: string | null,
    } | null,
    organisationId?: string | null,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name?: string | null,
      billingId?: string | null,
      users?: Array< string | null > | null,
      members?:  Array< {
        __typename: "MemberType",
        userId?: string | null,
        role: Roles,
        status: Statuses,
        name?: string | null,
        phoneNumber: string,
        avatar?: string | null,
      } | null > | null,
      allowedUserIds?: Array< string | null > | null,
      plan?: string | null,
      image?: string | null,
      usage?:  {
        __typename: "Usage",
        guests: number,
        tables: number,
        rooms: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInviteSubscriptionVariables = {
  filter?: ModelSubscriptionInviteFilterInput | null,
  owner?: string | null,
};

export type OnCreateInviteSubscription = {
  onCreateInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInviteSubscriptionVariables = {
  filter?: ModelSubscriptionInviteFilterInput | null,
  owner?: string | null,
};

export type OnUpdateInviteSubscription = {
  onUpdateInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInviteSubscriptionVariables = {
  filter?: ModelSubscriptionInviteFilterInput | null,
  owner?: string | null,
};

export type OnDeleteInviteSubscription = {
  onDeleteInvite?:  {
    __typename: "Invite",
    id: string,
    inviteId: string,
    owner: string,
    type?: InviteType | null,
    status?: InviteStatus | null,
    targetId: string,
    targetType?: InviteTargetType | null,
    message?: string | null,
    isOpenInvite?: boolean | null,
    targetInfo?: string | null,
    inviterInfo?: string | null,
    inviteeInfo?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
  owner?: string | null,
};

export type OnCreateConversationSubscription = {
  onCreateConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateConversationSubscription = {
  onUpdateConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteConversationSubscription = {
  onDeleteConversation?:  {
    __typename: "Conversation",
    conversationId: string,
    chatUnitId: string,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    chatUnitUserIds?: Array< string | null > | null,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    conversationId: string,
    userId: string,
    chatUnitId: string,
    text?: string | null,
    image?: string | null,
    chatUnitUserId: string,
    createdAt: string,
    allowedUserIds?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateMessageV2SubscriptionVariables = {
  filter?: ModelSubscriptionMessageV2FilterInput | null,
  owner?: string | null,
};

export type OnCreateMessageV2Subscription = {
  onCreateMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMessageV2SubscriptionVariables = {
  filter?: ModelSubscriptionMessageV2FilterInput | null,
  owner?: string | null,
};

export type OnUpdateMessageV2Subscription = {
  onUpdateMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMessageV2SubscriptionVariables = {
  filter?: ModelSubscriptionMessageV2FilterInput | null,
  owner?: string | null,
};

export type OnDeleteMessageV2Subscription = {
  onDeleteMessageV2?:  {
    __typename: "MessageV2",
    id: string,
    userId: string,
    SK: string,
    allowedUserIds?: Array< string | null > | null,
    authorId: string,
    chatUnitUserId: string,
    chatUnitId: string,
    chatUnitIdConversationId: string,
    conversationId: string,
    createdAt: string,
    image?: string | null,
    messageId: string,
    owner: string,
    status?: string | null,
    text?: string | null,
    source?: string | null,
    metaData?: string | null,
    assetMetadata?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrganisationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganisationFilterInput | null,
  owner?: string | null,
};

export type OnCreateOrganisationSubscription = {
  onCreateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateOrganisationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganisationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOrganisationSubscription = {
  onUpdateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteOrganisationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganisationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOrganisationSubscription = {
  onDeleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name?: string | null,
    billingId?: string | null,
    users?: Array< string | null > | null,
    members?:  Array< {
      __typename: "MemberType",
      userId?: string | null,
      role: Roles,
      status: Statuses,
      name?: string | null,
      phoneNumber: string,
      avatar?: string | null,
    } | null > | null,
    allowedUserIds?: Array< string | null > | null,
    plan?: string | null,
    image?: string | null,
    usage?:  {
      __typename: "Usage",
      guests: number,
      tables: number,
      rooms: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    username?: string | null,
    avatar?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    gender?: string | null,
    birthday?: string | null,
    pronouns?: string | null,
    marketingOptIn?: boolean | null,
    organisations?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateChatUnitUserSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatUnitUserSubscription = {
  onCreateChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateChatUnitUserSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatUnitUserSubscription = {
  onUpdateChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteChatUnitUserSubscriptionVariables = {
  filter?: ModelSubscriptionChatUnitUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatUnitUserSubscription = {
  onDeleteChatUnitUser?:  {
    __typename: "ChatUnitUser",
    id: string,
    chatUnitId: string,
    userId: string,
    username?: string | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    status?: string | null,
    allowedUserIds?: Array< string | null > | null,
    conversationIds?: Array< string | null > | null,
    chatUnit?:  {
      __typename: "ChatUnit",
      id: string,
      title: string,
      colorId: ChatUnitColorId,
      backgroundImage: string,
      description?: string | null,
      url?: string | null,
      guestOpenInviteId?: string | null,
      memberOpenInviteId?: string | null,
      chatUnitUsers?:  {
        __typename: "ModelChatUnitUserConnection",
        items:  Array< {
          __typename: "ChatUnitUser",
          id: string,
          chatUnitId: string,
          userId: string,
          username?: string | null,
          phoneNumber?: string | null,
          avatar?: string | null,
          status?: string | null,
          allowedUserIds?: Array< string | null > | null,
          conversationIds?: Array< string | null > | null,
          chatUnit?:  {
            __typename: "ChatUnit",
            id: string,
            title: string,
            colorId: ChatUnitColorId,
            backgroundImage: string,
            description?: string | null,
            url?: string | null,
            guestOpenInviteId?: string | null,
            memberOpenInviteId?: string | null,
            allowedUserIds?: Array< string | null > | null,
            type: ChatUnitType,
            subtype?: ChatUnitSubType | null,
            allConversationId?: string | null,
            organisationId?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          firstName?: string | null,
          lastName?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          chatUnitChatUnitUsersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUserIds?: Array< string | null > | null,
      type: ChatUnitType,
      subtype?: ChatUnitSubType | null,
      allConversationId?: string | null,
      ownerDetails?:  {
        __typename: "ChatUnitUser",
        id: string,
        chatUnitId: string,
        userId: string,
        username?: string | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        status?: string | null,
        allowedUserIds?: Array< string | null > | null,
        conversationIds?: Array< string | null > | null,
        chatUnit?:  {
          __typename: "ChatUnit",
          id: string,
          title: string,
          colorId: ChatUnitColorId,
          backgroundImage: string,
          description?: string | null,
          url?: string | null,
          guestOpenInviteId?: string | null,
          memberOpenInviteId?: string | null,
          chatUnitUsers?:  {
            __typename: "ModelChatUnitUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          allowedUserIds?: Array< string | null > | null,
          type: ChatUnitType,
          subtype?: ChatUnitSubType | null,
          allConversationId?: string | null,
          ownerDetails?:  {
            __typename: "ChatUnitUser",
            id: string,
            chatUnitId: string,
            userId: string,
            username?: string | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            status?: string | null,
            allowedUserIds?: Array< string | null > | null,
            conversationIds?: Array< string | null > | null,
            firstName?: string | null,
            lastName?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            chatUnitChatUnitUsersId?: string | null,
            owner?: string | null,
          } | null,
          organisationId?: string | null,
          organisation?:  {
            __typename: "Organisation",
            id: string,
            name?: string | null,
            billingId?: string | null,
            users?: Array< string | null > | null,
            allowedUserIds?: Array< string | null > | null,
            plan?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        chatUnitChatUnitUsersId?: string | null,
        owner?: string | null,
      } | null,
      organisationId?: string | null,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name?: string | null,
        billingId?: string | null,
        users?: Array< string | null > | null,
        members?:  Array< {
          __typename: "MemberType",
          userId?: string | null,
          role: Roles,
          status: Statuses,
          name?: string | null,
          phoneNumber: string,
          avatar?: string | null,
        } | null > | null,
        allowedUserIds?: Array< string | null > | null,
        plan?: string | null,
        image?: string | null,
        usage?:  {
          __typename: "Usage",
          guests: number,
          tables: number,
          rooms: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    chatUnitChatUnitUsersId?: string | null,
    owner?: string | null,
  } | null,
};
