/**
 * These colors are used in the app components like buttons, icons, etc.
 * They signify a sense of identity and familiarity with the brand.
 * They also expose a feeling of trust and reliability. These colors
 * are expected to change quite often. The colors give feedback
 * to the user about the state of the app.
 */
enum Status {
  danger100 = '#eb0000',
  danger50 = '#ff5050',
  success100 = '#00b9b9',
  success200 = '#59ffac',
  success300 = '#008542',
  success50 = '#91c2b4',
  warning100 = '#bf5600',
}

export default Status;
