export const SLIDES = [
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/tables_getting_started.gif',
    text: '**Tables. The heart of 8seats.**\n\nTables enable dynamic conversations between all people with a simple tap or swipe. No more static groups.\n\nUp to 8 people are allowed in a table (at present). Need more? **Rooms** can hold **256** people.',
  },
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/hold_head_down.gif',
    text: '**Talk to just one person** (long hold)\n\nNeed to have a conversation with just one person on your table? Easy! No need to tap everyone else’s head to remove them.\n\nSimply **long hold** (tap and hold for a couple of seconds) on their head to switch to a conversation with just you and them.',
  },
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/swipe_all_up.gif',
    text: '**Everyone on!** (swipe)\n\nNeed to quickly get everyone’s heads back up, without tapping them all?\n\nSimply swipe left or right across the whole row of heads. Note: This feature is not activated yet.',
  },
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/rooms_many_people.gif',
    text: '**Rooms** (for more than 8 people)\n\nRooms can hold a lot of people (up to 256 at present)\n\nCurrently Rooms still have a lot of new exciting features in development.',
  },
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/TIP_edit_delete_copy.gif',
    text: '**Edit. Delete. Copy** (long hold)\n\n**Long hold** on any message and you will see extra controls. If it is your message you will be able to edit and delete the message.',
  },
  {
    image:
      'https://8seats-stage.s3.ap-southeast-2.amazonaws.com/dynamicContent/conversation_panel.gif',
    text: '**Conversation Helper** (tables)\n\nAs you swipe left/right in your chat this panel shows where you are. Just like pages of a book.\n\nTapping the panel shows the last message in each thread. A **red dot** means you have an unread message waiting.',
  },
];
