import React from 'react';
import { Image, type ImageStyle, type StyleProp } from 'react-native';

import { ChatUnitType } from '../../../../API';

import { BADGES } from '@/domain/invites/views/invite-list/ReceivedInvitationItem';

type Props = {
  style?: StyleProp<ImageStyle>;
  type?: ChatUnitType;
};

export const Badge = ({ style, type }: Props) => {
  let DynamicBadge = null;
  if (type === ChatUnitType.TABLE) {
    DynamicBadge = BADGES.TablesBadge;
  } else if (type === ChatUnitType.ROOM) {
    DynamicBadge = BADGES.RoomsBadge;
  }
  return DynamicBadge ? <Image source={DynamicBadge} style={style} /> : null;
};
