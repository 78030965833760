import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgFork = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M85.286 8.531a8.315 8.315 0 0 1 6.38-2.975c4.6 0 8.334 3.734 8.334 8.333 0 4.6-3.734 8.334-8.334 8.334a8.317 8.317 0 0 1-6.382-2.978h-7.505c-11.922 0-17.572 8.217-23.533 15.965-2.534 3.294-5.099 6.54-7.934 9.432h38.974a8.315 8.315 0 0 1 6.38-2.975c4.6 0 8.334 3.734 8.334 8.333 0 4.6-3.734 8.334-8.334 8.334a8.317 8.317 0 0 1-6.382-2.978h-38.97c2.834 2.892 5.399 6.138 7.933 9.432 5.96 7.749 11.61 15.965 23.532 15.965h7.507a8.315 8.315 0 0 1 6.38-2.975c4.6 0 8.334 3.734 8.334 8.333 0 4.6-3.734 8.334-8.334 8.334a8.317 8.317 0 0 1-6.382-2.978h-7.505c-15.861 0-24.094-9.837-32.025-20.146-5.928-7.707-11.548-15.875-23.335-15.965l-.198.001h-7.506a8.318 8.318 0 0 1-6.382 2.977C3.734 58.334 0 54.6 0 50a8.337 8.337 0 0 1 8.333-8.333 8.316 8.316 0 0 1 6.38 2.974h7.51l.196.001c11.785-.09 17.406-8.258 23.335-15.964 7.93-10.31 16.164-20.147 32.025-20.147z" />
  </Svg>
);
export default SvgFork;
