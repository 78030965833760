import type React from 'react';
import { StyleSheet, Text, type TextProps, type TextStyle } from 'react-native';

import { Greys } from '@/ui/common/colors';

const SubtitleBold = ({
  children,
  style,
  ...props
}: {
  children?: React.ReactElement | string;
  style?: TextStyle;
} & TextProps) => {
  return (
    <Text style={[styles.text, style]} {...props}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Greys.shade500,
    fontFamily: 'OpenSans-Bold',
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 23,
  },
});

export default SubtitleBold;
