import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import MembersIcon from '../../../../assets/svg/view-members-icon.svg';

import { Greys } from '@/ui/common/colors';

type Props = {
  onPress?: () => void;
};

const ViewMembersButton = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <MembersIcon style={styles.icon} />
      <View style={styles.textContainer}>
        <Text style={styles.text}>View Members</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: Greys.shade800,
    borderColor: Greys.shade500,
    borderRadius: 14,
    borderWidth: 1,
    flexDirection: 'row',
    height: 28,
    marginRight: 6,
    marginVertical: 6,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 5,
    width: 120,
  },
  icon: {
    height: 20,
    marginRight: 5,
    width: 20,
  },
  text: {
    color: Greys.shade0,
    fontFamily: 'OpenSans-Regular',
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 13.62,
    textAlign: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default ViewMembersButton;
