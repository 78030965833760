import { FlashList, type ListRenderItem } from '@shopify/flash-list';
import isEqual from 'fast-deep-equal';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useShallow } from 'zustand/react/shallow';

import { ConversationActivityCard } from './components';
import { ActivitySkeletons } from './components/ActivitySkeletons';
import InvitesHeaderRow from './components/InvitesHeaderRow';
import NoActivities from './components/NoActivities';

import SearchIcon from '@/assets/svg/search.svg';
import PrimaryHeader from '@/components/headers/PrimaryHeader';
import { useActivities } from '@/domain/activity/hooks/useActivities';
import CriticalAnnouncementModal from '@/domain/cms/CriticalAnnouncementModal';
import useCMSStore from '@/domain/cms/useCMSStore';
import { useConversationStore } from '@/domain/conversation/state/useConversationStore';
import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import RefreshService from '@/domain/table/functions/RefreshService';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import type { ChatMessage } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { Greys, Neutrals } from '@/ui/common/colors';
import generateUniqueId from '@/utilities/helpers/uuid';
import { ios, web } from '@/utilities/platform';

const ItemSeparatorComponent = React.memo(() => (
  <View style={styles.itemSeparator} />
));

export const Activity = () => {
  const { navigate, navigationRef, reset } = useNavigation();
  const userId = useUserStore(useShallow(state => state.user?.id));
  const { loadingMessages } = useActivities();
  const sortedMessages = useMessageStore(
    useShallow(state => state.getMostRecentMessages()),
    isEqual,
  );

  const criticalPostsExist = useCMSStore(
    // Filter out read critical posts
    state => state.getUnreadCriticalPosts().length > 0,
  );

  const isArchivedConversation = useConversationStore(
    useShallow(state => state.isArchivedConversation),
  );

  const onActivityPress = useCallback(
    (message: ChatMessage) => {
      const archivedConversation = isArchivedConversation(
        message.chatUnitId,
        message.conversationId,
      );

      if (web) {
        navigationRef.current?.navigate('SplitView', {
          route: archivedConversation ? 'ArchivedConversation' : 'ChatView',
          conversationId: message.conversationId,
          chatUnitId: message.chatUnitId,
          uniqueId: generateUniqueId(),
        });
      } else {
        navigate(archivedConversation ? 'ArchivedConversation' : 'ChatView', {
          conversationId: message.conversationId,
          chatUnitId: message.chatUnitId,
        });
      }
    },
    [navigate, navigationRef, isArchivedConversation],
  );

  const { translate } = useTranslations();

  const renderItem: ListRenderItem<ChatMessage> = useCallback(
    ({ item }) => (
      <ConversationActivityCard
        message={item}
        onPress={onActivityPress}
        userId={userId || ''}
      />
    ),
    [onActivityPress, userId],
  );

  const renderEmptyComponent = useCallback(() => {
    return loadingMessages ? <ActivitySkeletons /> : <NoActivities />;
  }, [loadingMessages]);

  const navigateToSearch = () => {
    web
      ? reset({ index: 0, routes: [{ name: 'Search' }] })
      : navigate('Search');
  };

  return (
    <>
      {Platform.OS !== 'web' && (
        <PrimaryHeader rightActionVisible title={translate('my_activity')} />
      )}
      {criticalPostsExist ? <CriticalAnnouncementModal /> : null}
      <TouchableWithoutFeedback onPress={navigateToSearch}>
        <View style={styles.searchBar}>
          <SearchIcon style={styles.searchIcon} />
          <TextInput
            placeholder="Search messages"
            placeholderTextColor={Neutrals.shade700}
            style={styles.searchInput}
            editable={ios ? false : true}
            onFocus={navigateToSearch}
            // To make this work on both iOS and Android: onFocus is triggered on Android
            // for iOS we need to disable the input field and the onPress is captured on parent view
          />
        </View>
      </TouchableWithoutFeedback>

      <FlashList
        data={sortedMessages}
        ListHeaderComponent={InvitesHeaderRow}
        ItemSeparatorComponent={ItemSeparatorComponent}
        keyExtractor={item => item.id.toString()}
        ListEmptyComponent={renderEmptyComponent}
        renderItem={renderItem}
        estimatedItemSize={200}
        drawDistance={450}
        showsVerticalScrollIndicator={false}
        onRefresh={() =>
          loadingMessages ? null : RefreshService.seedActivities(userId ?? '')
        }
        refreshing={false} // stop the spinner from displaying in place with loading bar
        refreshControl={undefined}
      />
    </>
  );
};

const ITEM_SEPARATOR_HEIGHT = 1;

const styles = StyleSheet.create({
  itemSeparator: {
    height: ITEM_SEPARATOR_HEIGHT,
  },
  searchBar: {
    marginVertical: 10,
    paddingHorizontal: 20,
  },
  searchIcon: {
    left: 35,
    position: 'absolute',
    top: 8,
    zIndex: 1,
  },
  searchInput: {
    backgroundColor: Greys.shade0,
    borderRadius: 18,
    height: 36,
    paddingLeft: 40,
    shadowColor: Greys.shade999,
    shadowOffset: { height: 0.5, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
});

export default Activity;
