import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import * as yup from 'yup';
import { Colors } from '../theme/Colors';
import OnboardingNextButton from './components/OnboardingNextButton';
import OnboardingPhoneNumberInput from './components/OnboardingPhoneNumberInput';

import { ChatUnitType } from '@/API';
import AddIcon from '@/assets/onboarding/add_icon.svg';
import Section from '@/components/section/Section';
import { navigationRef } from '@/navigation/navigationRef';
import { createOrgInvite } from '@/services/invite/createOrgInvite';
import { web } from '@/utilities/platform';
import { useMMKVBoolean, useMMKVString } from 'react-native-mmkv';
import { inviteContactsToRoomOrTable } from '../chatUnit/components/view/inviteContactsToRoomOrTable';
import { createChatUnit } from '../chatUnit/functions/createChatUnit';
import useUserStore from '../user/state/useUserStore';
import OnboardingWebLayout from './components/OnboardingWebLayout';

type Props = MainStackScreenProps<'OnboardingAddMembers'>;

type invitee = {
  name?: string;
  phoneNumber?: string;
};

type FormFields = { invitees?: invitee[] };

const inviteeSchema = yup.object({
  name: yup.string().label('Name'),
  phoneNumber: yup
    .string()
    .label('Phone number')
    .test('is-valid-phone', 'Phone number is required', function (phoneNumber) {
      // If either name or phone number is filled, the other must be filled as well
      // If both are filled, the phone number must be a valid phone number
      const { name } = this.parent;

      // Ignore country code for existence check
      // Check only for part of the phone number that is not the country code
      if (name && !phoneNumber) {
        return false;
      }
      if (!name && phoneNumber) {
        return false;
      }

      const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : true;
      return isValid;
    }),
});

const validationSchema = yup.object().shape({
  invitees: yup.array().of(inviteeSchema),
});

const UNFILLED_FIELD = { name: '', phoneNumber: '' };
// Generate 3 empty fields
const UNFILLED_FIELDS = Array.from({ length: 3 }, () => UNFILLED_FIELD);

function OnboardingAddMembersScreen({ route }: Props) {
  const { translate } = useTranslations();
  const [_, setOnboardingTableCreated] = useMMKVBoolean(
    'onboardingTableCreated',
  );
  const [onboardingType, setOnboardingType] = useMMKVString('onboardingType');
  const [loading, setLoading] = React.useState(false);
  const user = useUserStore(state => state.user);
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      invitees: UNFILLED_FIELDS,
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'invitees',
  });

  const isBusiness = onboardingType === 'business';

  const onSubmit = async (data: FormFields) => {
    if (!data.invitees || !user) {
      return;
    }

    const phoneNumbers = new Set();
    const invitees = data.invitees
      .filter(
        invitee =>
          invitee.name &&
          invitee.name !== '' &&
          invitee.phoneNumber &&
          invitee.phoneNumber !== '',
      )
      .filter(invitee => {
        if (phoneNumbers.has(invitee.phoneNumber)) {
          return false;
        } else {
          phoneNumbers.add(invitee.phoneNumber);
          return true;
        }
      })
      .map(invitee => ({
        firstName: invitee.name ?? '',
        phoneNumbers: [
          {
            label: 'mobile',
            number: invitee.phoneNumber ?? '',
          },
        ],
      }));

    setLoading(true);

    //Create new table for them.
    const { chatUnit } = await createChatUnit({
      title: 'My First Table',
      allowedUserIds: [user.id],
      type: ChatUnitType.TABLE,
      user,
      organisationId: route.params?.orgId ? route.params?.orgId : undefined,
    });

    // Create Org Invite
    if (route.params?.orgId) {
      await createOrgInvite({
        orgId: route.params?.orgId,
        selectedContacts: invitees,
        chatUnitId: chatUnit.id,
        userId: user.id,
      });
    }
    if (!route.params?.orgId) {
      await inviteContactsToRoomOrTable(
        chatUnit.id,
        invitees,
        chatUnit,
        user.id,
        chatUnit?.organisationId,
      );
    }

    setLoading(false);
    setOnboardingTableCreated(true);
    if (onboardingType) {
      setOnboardingType(undefined);
    } else {
      if (web) {
        navigationRef.current?.navigate('SplitView', { route: 'Tables' });
      } else {
        navigationRef.current?.goBack();
      }
    }
  };

  return (
    <>
      {web ? null : <Spacer width="100%" height={1} bg={Colors.neutral10} />}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('step_x_of_y', {
            current: route.params?.stepNumber || 4,
            total: route.params?.totalSteps || 4,
          }).toUpperCase()}
        </AppText>
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate(
            isBusiness
              ? 'create_members_title'
              : 'create_members_title_individual',
          )}
        </AppText>
        <Spacer height={30} />
        <AppText size={web ? 21 : 17} color={Colors.neutral70}>
          {translate('create_members_subtitle')}
        </AppText>
        <Spacer height={40} />
        <AppText size={17} type="primary700">
          {translate('add_phone_numbers')}
        </AppText>
        <Spacer height={10} />
        <AppText size={14} color={Colors.neutral70}>
          {translate('add_phone_numbers_subtitle')}
        </AppText>
        {fields.map((item, index) => {
          if (web) {
            return (
              <View key={index}>
                <Spacer height={10} />
                <Section key={item.id} row aic jcsb gap={5}>
                  <Controller
                    control={control}
                    name={`invitees.${index}.name`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Section f1>
                        <TextInput
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          style={styles.nameInput}
                          verticalAlign="middle"
                          placeholder={translate('first_name_label')}
                          placeholderTextColor={Colors.neutral50}
                        />
                      </Section>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`invitees.${index}.phoneNumber`}
                    render={({ field: { onChange } }) => {
                      return (
                        <Section f1>
                          <OnboardingPhoneNumberInput
                            setPhoneNumber={onChange}
                            borderColor={Colors.neutral30}
                            type="compact"
                          />
                        </Section>
                      );
                    }}
                  />
                </Section>
              </View>
            );
          }
          return (
            <React.Fragment key={item.id}>
              <Spacer height={20} />
              <Controller
                control={control}
                name={`invitees.${index}.name`}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    style={styles.nameInput}
                    verticalAlign="middle"
                    placeholder={translate('first_name_label')}
                    placeholderTextColor={Colors.neutral50}
                  />
                )}
              />
              <Spacer height={5} />
              <Controller
                control={control}
                name={`invitees.${index}.phoneNumber`}
                render={({ field: { onChange } }) => {
                  return (
                    <OnboardingPhoneNumberInput
                      setPhoneNumber={onChange}
                      borderColor={Colors.neutral30}
                      type="compact"
                    />
                  );
                }}
              />
            </React.Fragment>
          );
        })}
        {fields.length < 7 ? (
          <>
            <Spacer height={20} />
            <Pressable
              style={styles.addMoreContainer}
              onPress={() => {
                append({ name: '', phoneNumber: '' });
              }}>
              <AddIcon width={32} height={32} />
              <AppText size={17} type="primary700" color={Colors.primaryLight}>
                {translate('add_more')}
              </AppText>
            </Pressable>
          </>
        ) : (
          <>
            <Spacer height={15} />
            <AppText size={14} color={Colors.neutral70}>
              {translate('add_more_end')}
            </AppText>
          </>
        )}
        <Spacer height={40} />
        <OnboardingNextButton
          disabled={!isValid}
          onPress={handleSubmit(onSubmit)}
          text={translate('send_invites')}
          loading={loading}
        />
        <Spacer height={30} />

        <Pressable
          onPress={() => {
            if (!onboardingType) {
              if (web) {
                navigationRef.current?.navigate('SplitView', {
                  route: 'Tables',
                });
              } else {
                // Go back to the original screen
                navigationRef.current?.goBack();
              }
            } else {
              setOnboardingType(undefined);
            }
          }}>
          <AppText
            size={web ? 17 : 14}
            color={Colors.primaryLight}
            type="primary700">
            {translate('skip_do_later')}
          </AppText>
        </Pressable>

        <Spacer height={60} />
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    paddingTop: 50,
    flexGrow: 1,
  },
  nameInput: {
    fontSize: 17,
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    height: 40,
    borderWidth: 1,
    borderRadius: BorderRadius.sm,
    borderColor: Colors.neutral30,
    paddingLeft: 10,
    ...(web ? { outlineStyle: 'none' } : {}),
  },
  addMoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});

const OnboardingAddMembersScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout showBackButton>
      <OnboardingAddMembersScreen {...props} />
    </OnboardingWebLayout>
  );
};

export default web ? OnboardingAddMembersScreenWeb : OnboardingAddMembersScreen;
