import MuteIcon from '@/assets/video/mute.svg';
import UnmuteIcon from '@/assets/video/unmute.svg';
import Section from '@/components/section/Section';
import type { MainStackScreenProps } from '@/navigation/types';
import { AppText } from '@/ui/app';
import { type AVPlaybackStatus, ResizeMode, Video } from 'expo-av';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Dimensions, Pressable, StyleSheet } from 'react-native';
import { useMMKVBoolean } from 'react-native-mmkv';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { Colors } from '../theme/Colors';

type Props = MainStackScreenProps<'OnboardingVideo'>;

export default function OnboardingVideoScreen({ navigation }: Props) {
  const [_, setOnboardingViewed] = useMMKVBoolean('onboardingVideoViewed');
  const player = useRef<Video>(null);
  const [muted, setMuted] = useState(true);
  const insets = useSafeAreaInsets();

  const videoStyles = useMemo(() => {
    return {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height - 64 - insets.top - insets.bottom,
    };
  }, [insets.bottom, insets.top]);

  const handleSkip = useCallback(async () => {
    await player.current?.unloadAsync();
    setOnboardingViewed(true);
    // If user is logged in, navigate to the main app
    // If not, this is the first time user is seeing the app, navigate to onboarding start
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('OnboardingStart');
    }
  }, [navigation]);

  const handlePlayback = useCallback(
    (status: AVPlaybackStatus) => {
      if (status.isLoaded && status.didJustFinish) {
        handleSkip();
      }
    },
    [handleSkip],
  );

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Video
          source={require('@/assets/video/onboarding_video.mp4')}
          style={videoStyles}
          useNativeControls={false}
          resizeMode={ResizeMode.CONTAIN}
          isMuted={muted}
          ref={player}
          isLooping={false}
          shouldPlay
          onPlaybackStatusUpdate={handlePlayback}
        />
        <Section row aic gap={20} mt={8} ph={30} w100>
          <Pressable onPress={() => setMuted(m => !m)}>
            {muted ? <MuteIcon /> : <UnmuteIcon />}
          </Pressable>
          <Section fg1>
            <Pressable style={styles.nextBtn} onPress={handleSkip}>
              <AppText
                type="primary700"
                size={17}
                textAlign="center"
                color={Colors.neutral0}>
                Skip & continue
              </AppText>
            </Pressable>
          </Section>
        </Section>
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  video: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height - 90,
    alignSelf: 'center',
  },
  nextBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryLight,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.neutral05,
    flex: 1,
  },
});
