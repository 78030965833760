import { useCallback, useEffect } from 'react';
import {
  type SharedValue,
  runOnJS,
  useAnimatedReaction,
} from 'react-native-reanimated';

import { updateMessages } from '../services/updateMessages';
import { useMessageStore } from '../state/useMessageStore';

import { logger } from '@/services/logger/logger';
import { web } from '@/utilities/platform';

type Props = {
  chatUnitId: string;
  conversationId: string;
  currentIndex: number;
  offset: SharedValue<number>;
};

export const useMessageReadHandlers = ({
  chatUnitId,
  conversationId,
  currentIndex,
  offset,
}: Props) => {
  const [clearConversationCount, unreadMessages] = useMessageStore(state => [
    state.clearConversationUnreadCount,
    state.getUnreadMessages(chatUnitId, conversationId),
  ]);
  const setConversationAsRead = useCallback(async () => {
    clearConversationCount(chatUnitId, conversationId);
  }, [clearConversationCount, conversationId, chatUnitId]);

  const updateMessageStatuses = useCallback(async () => {
    if (unreadMessages.size > 0) {
      const results = await updateMessages(
        Array.from(unreadMessages.values()).map(message => ({
          id: message.id!,
          status: 'READ',
          _version: message._version ?? 1,
        })),
      );

      logger.debug(
        `updateMessageStatuses::SuccessCount - ${results.successCount}`,
      );
      if (results.errorData.length > 0) {
        logger.error('updateMessageStatuses', results.errorData);
      }
    }
  }, [unreadMessages]);

  useEffect(() => {
    if (web && unreadMessages && unreadMessages.size > 0) {
      updateMessageStatuses();
      setConversationAsRead();
    }
  }, [updateMessageStatuses, unreadMessages, setConversationAsRead]);

  const setConversationAsReadMobile = useCallback(() => {
    if (unreadMessages && unreadMessages.size > 0) {
      updateMessageStatuses();
      setConversationAsRead();
    }
  }, [setConversationAsRead, updateMessageStatuses, unreadMessages]);

  useAnimatedReaction(
    () => offset.value,
    offsetValue => {
      if (currentIndex === offsetValue) {
        if (!web) {
          runOnJS(setConversationAsReadMobile)();
        }
      }
    },
    [offset.value, currentIndex, setConversationAsReadMobile],
  );
};
