import React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { useDataStoreEventsListener } from './src/hooks/useDataStoreEventsListener';
import useInit from './src/hooks/useInit';
import Navigator from './src/navigation';

const styles = {
  container: {
    flex: 1,
  },
};

export default function App() {
  useInit();
  useDataStoreEventsListener();

  return (
    <GestureHandlerRootView style={styles.container}>
      <Navigator />
    </GestureHandlerRootView>
  );
}
