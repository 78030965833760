export const sortByCreatedAt = (
  a: { createdAt?: string },
  b: { createdAt?: string },
) => {
  // put items without a createdAt at the end
  if (!a.createdAt) {
    return 1;
  }
  if (!b.createdAt) {
    return -1;
  }
  return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
};
