import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import Animated, {
  type SharedValue,
  interpolateColor,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ChatUnitType } from '../../../../../API';
import { styles } from '../styles';

import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';

// TODO: replace this with an svg
const backIcon = require('@/assets/backArrow.png');

type Props = {
  cancelUpdate: () => void;
  isDirty: boolean;
  isLoading: boolean;
  opacity: SharedValue<number>;
  saveUpdate: () => void;
  setHeaderHeight: (height: number) => void;
  type: ChatUnitType;
};

export const SettingsHeader = ({
  cancelUpdate,
  isDirty,
  isLoading,
  opacity,
  saveUpdate,
  setHeaderHeight,
  type,
}: Props) => {
  const { top } = useSafeAreaInsets();
  const { goBack } = useNavigation();
  const { translate } = useTranslations();

  const textStylez = useAnimatedStyle(() => {
    const color = interpolateColor(
      opacity.value,
      [0, 1],
      [Greys.shade0, Greys.shade600],
    );
    return {
      color,
    };
  });

  const backIconStylez = useAnimatedStyle(() => {
    const color = interpolateColor(
      opacity.value,
      [0, 1],
      [Greys.shade0, Greys.shade600],
    );
    return {
      tintColor: color,
    };
  });

  const stylez = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      opacity.value,
      [0, 1],
      ['transparent', Greys.shade0],
    );
    return {
      backgroundColor,
    };
  });

  return (
    <>
      <Animated.View style={[styles.safeArea, { height: top }, stylez]} />
      <Animated.View
        onLayout={e => {
          setHeaderHeight(e.nativeEvent.layout.height);
        }}
        style={[styles.header, { top }, stylez]}>
        {isDirty ? (
          <TouchableOpacity
            disabled={isLoading}
            onPress={cancelUpdate}
            style={styles.backContainer}>
            <Text style={styles.cancel}>
              {translate('cancel_common_button_title')}
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={goBack} style={styles.backContainer}>
            <Animated.Image source={backIcon} style={backIconStylez} />
            <Animated.Text style={[styles.backText, textStylez]}>
              {translate('common_back')}
            </Animated.Text>
          </TouchableOpacity>
        )}
        <View style={styles.titleContainer}>
          <Animated.Text style={[styles.headerTitle, textStylez]}>
            {translate('table_settings_label', {
              capitalized_type: translate(type ?? ChatUnitType.TABLE),
            })}
          </Animated.Text>
        </View>
        <View style={styles.emptyContainer}>
          {isDirty && (
            <>
              {!isLoading ? (
                <TouchableOpacity onPress={saveUpdate}>
                  <Text style={styles.saveLabel}>
                    {translate('common_save')}
                  </Text>
                </TouchableOpacity>
              ) : (
                <ActivityIndicator />
              )}
            </>
          )}
        </View>
      </Animated.View>
    </>
  );
};
