import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgMessage = (props: SvgProps) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M3.884 14.056L1.75 16.189V6A3.25 3.25 0 015 2.75h8A3.25 3.25 0 0116.25 6v4A3.25 3.25 0 0113 13.25H5.828c-.729 0-1.428.29-1.944.806z"
      stroke="#07818C"
      strokeWidth={1.5}
    />
    <Circle cx={9} cy={8} r={1} fill="#07818C" />
    <Circle cx={6} cy={8} r={1} fill="#07818C" />
    <Circle cx={12} cy={8} r={1} fill="#07818C" />
  </Svg>
);
export default SvgMessage;
