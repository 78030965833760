import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import PagerView from 'react-native-pager-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import BackButton from '../../../../components/BackButton';
import { updateInviteBasedOnStatus } from '../../helpers/setStatus';
import { type Invite, InviteStatus } from '../../types/types';

import { renderReceivedItem } from './ReceivedInvitationItem';
import { renderSentItem } from './SentInvitationItem';
import { styles } from './styles';

import Section from '@/components/section/Section';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { Colors } from '@/domain/theme/Colors';
import useNavigation from '@/hooks/useNavigation';
import type { MainStackScreenProps } from '@/navigation/types';
import { InviteSlideType } from '@/services/types';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';
import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

type InviteGroup = {
  groupedInvites: Invite[];
  status: InviteStatus;
};

const slides = [
  InviteSlideType.RECEIVED,
  InviteSlideType.SENT,
  InviteSlideType.WORKSPACES,
];

const ItemSeparatorComponent = () => {
  return <View style={styles.itemSeparator} />;
};

const ReceivedList = ({
  acceptInvitationHandler,
  declineInvitationHandler,
  invites,
}: {
  acceptInvitationHandler: (inviteId: string) => void;
  declineInvitationHandler: (inviteId: string) => void;
  invites: InviteGroup[];
}) => {
  return (
    <>
      {/* <ReceivedItemHeader type={type} /> */}
      <FlashList
        contentContainerStyle={styles.flashListContainer}
        data={invites.sort((a, b) => b.status.localeCompare(a.status))}
        estimatedItemSize={200}
        ItemSeparatorComponent={ItemSeparatorComponent}
        keyExtractor={(_, index) => index.toString()}
        renderItem={renderReceivedItem({
          acceptInvitationHandler,
          declineInvitationHandler,
        })}
        showsVerticalScrollIndicator={false}
      />
    </>
  );
};

type Props = MainStackScreenProps<'InviteList'>;

const InviteList = ({ route }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(InviteSlideType.RECEIVED);

  useEffect(() => {
    if (pagerRef.current) {
      pagerRef.current.setPage(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (route.params?.activeSlideType) {
      setSelectedIndex(route.params?.activeSlideType);
    } else {
      setSelectedIndex(InviteSlideType.RECEIVED);
    }
  }, [route.params?.activeSlideType]);

  const [receivedInvites, sentInvites, receivedInvitesOfOrg] = useMyInviteStore(
    useShallow(state => [
      state.getReceivedInvitesForMessages(),
      state.getSentInvites(),
      state.getReceivedInvitesForOrganisations(),
    ]),
  );

  const invitesGroupedByStatus: InviteGroup[] = receivedInvites.reduce(
    (current: InviteGroup[], next: Invite) => {
      if (next?.status) {
        const foundIndex = current.findIndex(
          item => item.status === next?.status,
        );
        foundIndex >= 0
          ? current[foundIndex].groupedInvites.push(next)
          : current.push({
              groupedInvites: [next],
              status: next.status,
            });
        return current;
      }
      return current;
    },
    [],
  );

  const workspaceInvitesGroupedByStatus: InviteGroup[] =
    receivedInvitesOfOrg.reduce((current: InviteGroup[], next: Invite) => {
      if (next?.status) {
        const foundIndex = current.findIndex(
          item => item.status === next?.status,
        );
        foundIndex >= 0
          ? current[foundIndex].groupedInvites.push(next)
          : current.push({
              groupedInvites: [next],
              status: next.status,
            });
        return current;
      }
      return current;
    }, []);

  const pagerRef = useRef<PagerView>(null);

  const acceptInvitationHandler = (inviteId: string) => {
    updateInviteBasedOnStatus(inviteId, InviteStatus.ACCEPTED);
  };
  const declineInvitationHandler = (inviteId: string) => {
    updateInviteBasedOnStatus(inviteId, InviteStatus.DECLINED);
  };

  const { top } = useSafeAreaInsets();
  const { goBack, navigate } = useNavigation();
  const { translate } = useTranslations();

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: Greys.shade0, height: top }} />
      <View style={styles.background}>
        <BackButton onPress={goBack} />
        <Text style={styles.headerTitle}>{translate('my_invites_text')}</Text>
        <View style={styles.emptyContainer} />
      </View>
      <SegmentedControl
        selectedSlide={selectedIndex}
        setSelectedSlide={setSelectedIndex}
      />
      {!web && (
        <PagerView
          ref={pagerRef}
          scrollEnabled={false}
          style={styles.pagerView}>
          {slides.map((slide, slideIndex) => {
            return (
              <View key={slideIndex} style={styles.flex1}>
                {slide === InviteSlideType.SENT && (
                  <FlashList
                    contentContainerStyle={styles.flashListContainer}
                    data={sentInvites.sort(
                      (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime(),
                    )}
                    estimatedItemSize={200}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    keyExtractor={(_, index) => index.toString()}
                    renderItem={renderSentItem({ navigate, translate })}
                    showsVerticalScrollIndicator={false}
                  />
                )}

                {slide === InviteSlideType.RECEIVED && (
                  <>
                    <ReceivedList
                      acceptInvitationHandler={acceptInvitationHandler}
                      declineInvitationHandler={declineInvitationHandler}
                      invites={invitesGroupedByStatus}
                    />
                  </>
                )}

                {slide === InviteSlideType.WORKSPACES && (
                  <>
                    <ReceivedList
                      acceptInvitationHandler={acceptInvitationHandler}
                      declineInvitationHandler={declineInvitationHandler}
                      invites={workspaceInvitesGroupedByStatus}
                    />
                  </>
                )}
              </View>
            );
          })}
        </PagerView>
      )}

      {web && selectedIndex === InviteSlideType.SENT && (
        <View style={styles.flex1}>
          <FlashList
            contentContainerStyle={styles.flashListContainer}
            data={sentInvites.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )}
            estimatedItemSize={200}
            ItemSeparatorComponent={ItemSeparatorComponent}
            keyExtractor={(_, index) => index.toString()}
            renderItem={renderSentItem({ navigate, translate })}
            showsVerticalScrollIndicator={false}
          />
        </View>
      )}

      {web && selectedIndex === InviteSlideType.RECEIVED && (
        <View style={styles.flex1}>
          <>
            <ReceivedList
              acceptInvitationHandler={acceptInvitationHandler}
              declineInvitationHandler={declineInvitationHandler}
              invites={invitesGroupedByStatus}
            />
          </>
        </View>
      )}

      {web && selectedIndex === InviteSlideType.WORKSPACES && (
        <View style={styles.flex1}>
          <>
            <ReceivedList
              acceptInvitationHandler={acceptInvitationHandler}
              declineInvitationHandler={declineInvitationHandler}
              invites={workspaceInvitesGroupedByStatus}
            />
          </>
        </View>
      )}
    </View>
  );
};

type SegmentedControlProps = {
  selectedSlide: InviteSlideType;
  setSelectedSlide: (slide: InviteSlideType) => void;
};

const SegmentedControl = ({
  selectedSlide,
  setSelectedSlide,
}: SegmentedControlProps) => {
  return (
    <Section bg={Colors.neutral0} p={10}>
      <Section
        bg={Colors.neutral10}
        row
        aic
        br={BorderRadius.lg}
        fg1
        p={5}
        bw={1}
        bc={Colors.neutral20}>
        <Section
          onPress={() => setSelectedSlide(InviteSlideType.RECEIVED)}
          f1
          pv={10}
          br={BorderRadius.md}
          bg={
            selectedSlide === InviteSlideType.RECEIVED
              ? Colors.primaryLight
              : 'transparent'
          }>
          <AppText
            textAlign="center"
            type="primary700"
            size={12}
            color={
              selectedSlide === InviteSlideType.RECEIVED
                ? Colors.neutral0
                : Colors.neutral80
            }>
            Received
          </AppText>
        </Section>
        <Section
          onPress={() => setSelectedSlide(InviteSlideType.SENT)}
          f1
          pv={10}
          br={BorderRadius.md}
          bg={
            selectedSlide === InviteSlideType.SENT
              ? Colors.primaryLight
              : 'transparent'
          }>
          <AppText
            textAlign="center"
            size={12}
            type="primary700"
            color={
              selectedSlide === InviteSlideType.SENT
                ? Colors.neutral0
                : Colors.neutral80
            }>
            Sent by me
          </AppText>
        </Section>
        <Section
          onPress={() => setSelectedSlide(InviteSlideType.WORKSPACES)}
          f1
          pv={10}
          br={BorderRadius.md}
          bg={
            selectedSlide === InviteSlideType.WORKSPACES
              ? Colors.primaryLight
              : 'transparent'
          }>
          <AppText
            textAlign="center"
            size={12}
            type="primary700"
            color={
              selectedSlide === InviteSlideType.WORKSPACES
                ? Colors.neutral0
                : Colors.neutral80
            }>
            Workspaces
          </AppText>
        </Section>
      </Section>
    </Section>
  );
};

export default InviteList;
