import { useEffect } from 'react';
import type { Subscription } from 'rxjs';

import useUserStore from '../state/useUserStore';

import { useLoadingStore } from '@/domain/ui/state/useLoadingStore';
import { getDatastoreObservable } from '@/services/datastore/subscriptions/subscriptionManager';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';

export const useSubscribeUserEvents = () => {
  const setUser = useUserStore(state => state.setUser);
  const datastoreReady = useLoadingStore(state => state.datastoreReady);

  useEffect(() => {
    let subscription: Subscription | undefined;
    if (datastoreReady) {
      subscription = getDatastoreObservable('User').subscribe(
        subscriptionMessage => {
          try {
            const user = factory.user(subscriptionMessage.element);
            logger.debug('userLoaded', user);
            setUser(user);
          } catch (e) {
            logger.error('subscribeUserLoadedError', e);
          }
        },
      );
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [setUser, datastoreReady]);
};
