import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgRooms = (props: SvgProps) => (
  <Svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M47.128 9.705 13.277 24.212a1.604 1.604 0 0 0-.964 1.63l3.742 38.17L43.3 49.156a7.346 7.346 0 0 0 3.828-6.379v-.07zm5.744 0V42.776a7.343 7.343 0 0 0 3.828 6.38l27.245 14.856 3.742-38.17a1.604 1.604 0 0 0-.964-1.63zm28.091 59.227L53.515 53.965a7.347 7.347 0 0 0-7.033 0L19.036 68.93l30.013 22.142a1.604 1.604 0 0 0 1.903 0zm7.235 5.368a10.216 10.216 0 0 0 4.103-7.225l3.96-40.393a10.22 10.22 0 0 0-6.144-10.388L54.025.826a10.223 10.223 0 0 0-8.05 0L9.883 16.294a10.221 10.221 0 0 0-6.144 10.388l3.96 40.393a10.218 10.218 0 0 0 4.103 7.225l32.132 23.705a10.217 10.217 0 0 0 12.132-.001" />
  </Svg>
);
export default SvgRooms;
