import { StackActions } from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PagerView from 'react-native-pager-view';
import Animated, {
  FadeIn,
  FadeOut,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useShallow } from 'zustand/react/shallow';

import { ChatUnitType } from '../../../../API';
import { InviteActionSheet } from '../../../invites/components/InviteActionSheet';
import { useChatUnitViewLoadingTimeoutPrompt } from '../../hooks/useChatUnitViewLoadingTimeoutPrompt';

import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import LottieLoader from '@/components/loaders/LottieLoader';
import { useExperience } from '@/context/Experience';
import ChatPagerContainer from '@/domain/chatUnit/components/view/ChatPagerContainer';
import TableHomeUrl from '@/domain/chatUnit/components/view/TableHomeUrl';
import DotBar from '@/domain/chatUnit/components/view/dotBar/DotBar';
import {
  type ContactInput,
  inviteContactsToDM,
} from '@/domain/chatUnit/functions/inviteContactsToDM';
import { useChatUnit } from '@/domain/chatUnit/hooks/useTable';
import { useActiveConversationStoreApi } from '@/domain/conversation/state/useActiveConversationStore';
import useRemainingSeatsLimit from '@/domain/invites/hooks/useRemainingSeats';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { Members } from '@/domain/rooms/components/members/Members';
import { useShowMembersList } from '@/domain/rooms/hooks/useShowMembersList';
import { useTableUsersStore } from '@/domain/table/state/useTableUsersStore';

import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { Colors } from '@/domain/theme/Colors';
import useUserStore from '@/domain/user/state/useUserStore';
import useNavigation from '@/hooks/useNavigation';
import { Roles } from '@/models';
import type { MainStackScreenProps } from '@/navigation/types';

import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { eventBus } from '@/services/eventBus/eventBus';
import { createOrgInvite } from '@/services/invite/createOrgInvite';
import { logger } from '@/services/logger/logger';
import { wp } from '@/theme/responsiveHelpers';
import useTranslation from '@/translations/useTranslation';
import generateUniqueId from '@/utilities/helpers/uuid';
import { isActiveMemberOfOrg } from '@/utilities/org/isActiveMemberOfOrg';
import { web } from '@/utilities/platform';
import { inviteContactsToRoomOrTable } from './inviteContactsToRoomOrTable';

export type ViewProps = MainStackScreenProps<'ChatView'>;
const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedPager = web
  ? Animated.createAnimatedComponent(View)
  : Animated.createAnimatedComponent(PagerView);

const ChatUnitView = ({ navigation, route }: ViewProps) => {
  const { goBack, navigate } = useNavigation();
  const pagerRef = useRef<PagerView>(null);
  const userId = useUserStore(state => state.user?.id);
  const {
    conversationId,
    showMembers,
    chatUnitId,
    selectedQuery,
    selectedMessage,
    firstMessage,
    uniqueId,
  } = route.params;

  const storeApi = useActiveConversationStoreApi();

  const { translate } = useTranslation();
  const [isAddOpen, setAddOpen] = useState(false);
  const { top } = useSafeAreaInsets();
  const { loading, chatUnit } = useChatUnit(chatUnitId);
  const directorySessionId = useMemo(() => generateUniqueId(), []);
  const limit = useRemainingSeatsLimit(chatUnit);

  const { track } = useExperience();
  const offset = useSharedValue(0);
  const dotbarOpacity = useSharedValue(
    chatUnit?.type === ChatUnitType.TABLE ||
      chatUnit?.type === ChatUnitType.QUICK_CHAT
      ? 1
      : 0,
  );

  const chatGptUser = useMemo<ContactInput>(
    () => ({
      firstName: '8seatsAI',
      phoneNumbers: [{ label: 'mobile', number: '+33639980968' }],
    }),
    [],
  );

  const showInviteChatGpt = useTableUsersStore(
    useShallow(
      state =>
        !state
          .getTableUsersForTable(chatUnitId ?? '')
          .find(
            chatUser =>
              chatUser.phoneNumber === chatGptUser?.phoneNumbers[0].number,
          ),
    ),
  );

  const addInvites = useMyInviteStore(state => state.addInvites);

  useEffect(() => {
    if (selectedMessage && selectedQuery) {
      const { setSelectedMessage, setQuery } = storeApi.getState();
      setSelectedMessage(selectedMessage);
      setQuery(selectedQuery);
    }
  }, [selectedMessage, selectedQuery, uniqueId]);

  const { showPrompt } = usePromptStore();
  const { backgroundOpacity, showMembersList, toggleShowMembers } =
    useShowMembersList();

  const animatedStyle = useAnimatedStyle(
    () => ({ opacity: backgroundOpacity.value }),
    [backgroundOpacity],
  );

  const toggleInviteActions = useCallback(() => setAddOpen(o => !o), []);

  const inviteChatGpt = useCallback(async () => {
    setAddOpen(false);
    if (chatUnit && userId) {
      await inviteContactsToDM(chatUnit.id, [chatGptUser], userId);
    }
  }, [chatUnit, userId, chatGptUser]);

  const goToDirectory = useCallback(() => {
    navigate('Directory', {
      chatUnitId,
      limit,
      sessionId: directorySessionId,
      title: chatUnit?.title,
      type: chatUnit?.type,
      orgId: chatUnit?.organisationId,
    });
  }, [
    chatUnit?.organisationId,
    chatUnit?.title,
    chatUnit?.type,
    chatUnitId,
    directorySessionId,
    limit,
    navigate,
    translate,
  ]);

  const organisations = useOrganisations();

  const isAllowedToInvite =
    !chatUnit?.organisationId ||
    isActiveMemberOfOrg(organisations, chatUnit?.organisationId, userId);

  const goToSettings = useCallback(
    () => navigate('ChatSettings', { id: chatUnit?.id }),
    [navigate, chatUnit?.id],
  );

  const goToCreateInvite = useCallback(() => {
    setAddOpen(false);
    navigate('CreateInvite', {
      backgroundImage: chatUnit?.backgroundImage,
      tableId: chatUnitId,
      title: chatUnit?.title,
    });
  }, [chatUnit?.backgroundImage, chatUnitId, chatUnit?.title, navigate]);

  const chatViewContainerStyle = useMemo(
    () => ({ paddingTop: top + 20 }),
    [top],
  );

  useEffect(() => {
    if (showMembers) toggleShowMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMembers]);

  useEffect(() => {
    eventBus.on(
      'directorySubmitted',
      async ({ contacts: selectedContacts, sessionId }) => {
        try {
          if (sessionId !== directorySessionId || !userId || !chatUnit) {
            return;
          }
          if (selectedContacts.length === 0) {
            goBack();
          }
          setAddOpen(false);

          track('Invite Sent', {
            chatUnitId,
            phoneNumbersInvited: selectedContacts
              .map(item => item.phoneNumbers[0].number)
              .join(', '),
          });
          if (chatUnit?.type === ChatUnitType.QUICK_CHAT) {
            await inviteContactsToDM(chatUnitId, selectedContacts, userId);

            goBack(); // return to this screen
          } else {
            // Split into org and guest invites
            const orgInvites = selectedContacts.filter(
              contact => contact.role === Roles.MEMBER,
            );

            let orgInviteRes;
            let chatUnitInviteRes;
            if (chatUnit?.organisationId && orgInvites.length > 0) {
              orgInviteRes = await createOrgInvite({
                orgId: chatUnit?.organisationId,
                selectedContacts: orgInvites,
                userId: userId,
              });
            }

            if (selectedContacts.length > 0) {
              chatUnitInviteRes = await inviteContactsToRoomOrTable(
                chatUnit.id,
                selectedContacts,
                chatUnit,
                userId,
                chatUnit?.organisationId,
              );
            }

            //Adding the newly created invites to the stores. This prevents going to the invite Success
            if (chatUnitInviteRes?.newSenderInvite) {
              addInvites([chatUnitInviteRes?.newSenderInvite]);
            }
            if (orgInviteRes?.newSenderInvite) {
              addInvites([orgInviteRes?.newSenderInvite]);
            }
            const inviteId =
              chatUnitInviteRes?.inviteId ?? orgInviteRes?.inviteId;
            if (inviteId) {
              navigation.dispatch(
                StackActions.replace('SenderInviteSuccess', {
                  inviteId,
                }),
              );
            }
          }
        } catch (error) {
          logger.error('Error while sending invite', error);
          showPrompt({
            title: translate('send_invite_error_prompt_title'),
            body: translate('send_invite_error_prompt_body'),
          });
          goBack();
        }
      },
    );

    return () => {
      eventBus.off('directorySubmitted');
    };
  }, [
    chatUnit,
    chatUnitId,
    directorySessionId,
    goBack,
    navigation,
    track,
    showPrompt,
    translate,
    userId,
  ]);

  useEffect(() => {
    eventBus.on('directoryCancelled', sessionId => {
      if (sessionId === directorySessionId) {
        setAddOpen(false);
      }
    });

    return () => {
      eventBus.off('directoryCancelled');
    };
  }, [directorySessionId, goBack]);

  useChatUnitViewLoadingTimeoutPrompt({
    loading,
    hasChatUnit: !!chatUnit,
    navigate,
    type: chatUnit?.type,
  });

  if (loading || !chatUnit) {
    return (
      <View style={styles.loader}>
        <LottieLoader />
      </View>
    );
  }

  return (
    <>
      <View style={styles.imgContainer}>
        <ChatUnitBackgroundImage
          imageKey={chatUnit.backgroundImage}
          height={Dimensions.get('window').height}
          width="100%"
        />
      </View>
      {!showMembersList && (
        <AnimatedView entering={FadeIn} exiting={FadeOut}>
          <LinearGradient
            colors={[Colors.neutral100, 'rgba(0, 0, 0, 0)']}
            end={{ x: 0.0, y: 4.0 }}
            start={{ x: 0.0, y: 0.0 }}
            style={styles.linearGradient}
          />
        </AnimatedView>
      )}
      <DotBar
        goToSettings={isAllowedToInvite ? goToSettings : undefined}
        offset={offset}
        onPress={goBack}
        opacity={dotbarOpacity}
        title={chatUnit.title}
        type={chatUnit.type}
        chatUnit={chatUnit}
        toggleInviteActions={toggleInviteActions} // Pass the handler here
      />
      <AnimatedPager
        initialPage={1}
        onPageScroll={e => {
          dotbarOpacity.value = e.nativeEvent.offset + e.nativeEvent.position;
        }}
        ref={pagerRef}
        style={[styles.container, animatedStyle]}>
        {!web && (
          <View key="dashboard" style={styles.containerFullWidth}>
            <View style={styles.container}>
              <View style={{ height: top }} />
              <TableHomeUrl chatUnitId={chatUnitId} />
            </View>
          </View>
        )}
        <View
          key="chatView"
          style={[chatViewContainerStyle, styles.containerFullWidth]}>
          <ChatPagerContainer
            conversationId={conversationId}
            offset={offset}
            parentPagerRef={pagerRef}
            chatUnit={chatUnit}
            toggleInviteActions={toggleInviteActions}
            toggleShowMembers={toggleShowMembers}
            firstMessage={firstMessage}
            uniqueId={uniqueId}
          />
        </View>
      </AnimatedPager>
      {showMembersList && (
        <Members onClose={toggleShowMembers} chatUnitId={chatUnit.id} />
      )}
      <InviteActionSheet
        goToCreateInvite={goToCreateInvite}
        goToCreateInvites={goToDirectory}
        inviteChatgpt={showInviteChatGpt ? inviteChatGpt : undefined}
        isOpen={isAddOpen}
        setIsOpen={setAddOpen}
      />
    </>
  );
};

export default ChatUnitView;

const styles = StyleSheet.create({
  containerFullWidth: {
    flex: 1,
    width: web ? '100%' : wp(100),
  },
  container: {
    flex: 1,
  },
  image: {
    height: '100%',
    width: '100%',
  },
  linearGradient: {
    opacity: 0.5,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    height: 175,
  },
  loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },
  paragraph: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 24,
    textAlign: 'center',
  },
  imgContainer: {
    height: 175,
    width: '100%',
    zIndex: -2,
    position: 'absolute',
  },
});
