import React from 'react';
import { type Control, Controller } from 'react-hook-form';

import { PhoneInput, type PhoneInputProps } from './index';

export interface PhoneControllerProps
  extends Omit<PhoneInputProps, 'value' | 'onChange'> {
  control: Control<any>;
  name: string;
  onBlur?(): void;
}

export const PhoneController = ({
  control,
  name,
  onBlur,
  ...props
}: PhoneControllerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <PhoneInput
            {...props}
            onBlur={onBlur}
            onChange={(option: string) => {
              field.onChange(option);
            }}
            value={field.value}
          />
        );
      }}
    />
  );
};
