import { Dimensions, StyleSheet } from 'react-native';

var width = Dimensions.get('window').width; //full width

type Props = {
  safeBottomHeight: number;
  safeTopHeight: number;
  topShadowOffset?: number;
};

export const getStyles = ({
  safeBottomHeight,
  safeTopHeight,
  topShadowOffset,
}: Props) => {
  const baseStyles = StyleSheet.create({
    blur: {
      position: 'absolute',
      width: width,
      zIndex: 2,
    },
    bottom: {
      bottom: 0,
    },
    shadow: {
      height: 60,
      position: 'absolute',
      width: width,
      zIndex: 2,
    },
    top: {
      top: 0,
    },
    wrapper: {
      flex: 1,
      position: 'relative',
      zIndex: 1,
    },
  });

  return {
    bottomBlur: StyleSheet.flatten([
      baseStyles.blur,
      baseStyles.bottom,
      { height: safeBottomHeight + 20 },
    ]),
    bottomShadow: StyleSheet.flatten([baseStyles.shadow, baseStyles.bottom]),
    topBlur: StyleSheet.flatten([
      baseStyles.blur,
      baseStyles.top,
      { height: safeTopHeight },
    ]),
    topShadow: StyleSheet.flatten([
      baseStyles.shadow,
      baseStyles.top,
      topShadowOffset ? { top: topShadowOffset } : {},
    ]),
    wrapper: baseStyles.wrapper,
  };
};
