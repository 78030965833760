import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgUnion = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5 42.5c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18zm0 6c13.255 0 24-10.745 24-24S48.755.5 35.5.5s-24 10.745-24 24a23.896 23.896 0 005.04 14.717L1.38 54.379A3 3 0 105.62 58.62L20.783 43.46A23.896 23.896 0 0035.5 48.5zm0-12c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm0 3c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
      fill="url(#paint0_linear_13832_1339)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_13832_1339"
        x1={30}
        y1={0.5}
        x2={30}
        y2={59.5}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#07818C" />
        <Stop offset={1} stopColor="#17D3E5" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SvgUnion;
