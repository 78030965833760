import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './styles';

import Avatar from '@/components/avatar';
import { getCommonAvatars } from '@/services';
import type { Translate } from '@/translations/useTranslation';
import getAvatarConfig from '@/utilities/lib/getAvatarConfig';
import { web } from '@/utilities/platform';

type Props = {
  heading: string;
  showAvatarPicker: () => void;
  subHeading: string;
  translate: Translate;
};

const { avatarSize } = getAvatarConfig();
const commonAvatars = getCommonAvatars();

export const ChooseAvatarBlock = ({
  heading,
  showAvatarPicker,
  subHeading,
  translate,
}: Props) => {
  return (
    <View style={styles.avatarPicker}>
      <View style={styles.avatarPickerContainer}>
        <Text style={styles.avatarPickerTitle}>{heading}</Text>
        <Text style={styles.avatarPickerSubtitle}>{subHeading}</Text>
        <View style={styles.avatarsContainer}>
          <View style={styles.avatarsRow}>
            {commonAvatars.map((cAvatar, index) => {
              return (
                <View
                  style={[{ left: index * -22 }]}
                  key={`${cAvatar?.name} + ${index}`}>
                  <Avatar
                    displayName=""
                    imageKey={cAvatar?.name}
                    key={index.toString()}
                    size={web ? avatarSize : avatarSize + 8}
                  />
                </View>
              );
            })}
          </View>
          <TouchableOpacity
            onPress={showAvatarPicker}
            style={styles.chooseNowButton}>
            <Text style={styles.chooseNowButtonText}>
              {translate('choose_now_text')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
