import { PermissionsAndroid, Platform } from 'react-native';

export async function android_requestUserPermission() {
  let notificationsPermissionCheck = PermissionsAndroid.RESULTS.GRANTED;
  // The permission exists only for Android API versions bigger than 33 (Android 13),
  // we can assume it's always granted beforehand

  if (Platform.OS === 'android' && Platform.Version >= 33) {
    // Version is a number for android
    notificationsPermissionCheck = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS,
    );
  }
  return notificationsPermissionCheck === PermissionsAndroid.RESULTS.GRANTED;
}
