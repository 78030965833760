import { StyleSheet } from 'react-native';

import { ChatUnitType } from '../../../../API';

import { green, primaryBlue } from '@/theme/colors';
import { wp } from '@/theme/responsiveHelpers';
import { Greys } from '@/ui/common/colors';
import { BorderRadius } from '@/ui/common/styles';
import { web } from '@/utilities/platform';

export const getStyles = (type?: ChatUnitType) =>
  StyleSheet.create({
    activityTextContainer: { width: wp(60) },
    avatar: {
      borderColor: Greys.shade0,
      borderWidth: 1,
    },
    avatarContainer: {
      borderColor: Greys.shade0,
      borderRadius: 100,
      borderWidth: 2,
      left: 10,
      position: 'absolute',
      top: type === ChatUnitType.QUICK_CHAT ? 15 : 30,
      zIndex: 100,
    },
    badge: {
      height: 28,
      left: -13,
      top: 20,
      width: 28,
      zIndex: 100,
    },
    container: {
      backgroundColor: Greys.shade0,
      borderRadius: 3,
      flexDirection: 'row',
      marginLeft: 4,
      marginRight: 4,
      marginTop: 1,
    },
    content: {
      flex: 1,
    },
    defaultAvatar: {
      alignItems: 'center',
      borderColor: Greys.shade0,
      borderRadius: 40,
      borderWidth: 1,
      height: 40,
      justifyContent: 'center',
      left: 10,
      marginRight: 18,
      width: 40,
      zIndex: 100,
    },
    defaultAvatarText: {
      color: Greys.shade0,
      fontSize: 12,
    },
    iconContainer: {
      marginLeft: 5,
      marginTop: 2,
    },
    imageContainer: {
      alignItems: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      width: web ? 80 : wp(15),
    },
    imageTextRow: {
      marginTop: 6,
      flexDirection: 'row',
      gap: 10,
    },
    inText: {
      color: Greys.shade400,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 10,
      fontWeight: '600',
      marginLeft: 5,
    },
    message: {
      color: Greys.shade600,
      fontFamily: 'OpenSans-Regular',
      fontSize: 14,
      fontWeight: '400',
    },
    messageByMe: {
      color: primaryBlue,
      fontWeight: '600',
    },
    messageContainer: {
      marginTop: 2,
      paddingRight: 20,
    },
    name: {
      color: Greys.shade600,
      marginBottom: 0.5,
    },
    nameAndDateContainer: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 8,
      paddingRight: 8,
    },
    nameAndDateContainerWithNewMessages: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 8,
      paddingRight: 40,
    },
    nameContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    newMessages: {
      color: Greys.shade0,
      fontSize: 12,
      fontWeight: '700',
    },
    newMessagesContainer: {
      alignItems: 'center',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 2,
      justifyContent: 'center',
      paddingHorizontal: 6,
      paddingVertical: 3,
      position: 'absolute',
      right: 1,
      top: 1,
    },
    teamText: {
      color: green,
      marginLeft: 5,
    },
    time: {
      color: Greys.shade500,
      fontFamily: 'OpenSans-Regular',
      fontSize: 10,
      fontWeight: '400',
      marginBottom: 3,
    },
    timeAndIndicatorCountContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    viewedBy: {
      color: Greys.shade400,
      fontFamily: 'OpenSans-Regular',
      fontSize: 10,
      fontWeight: '400',
      marginRight: 7,
    },
    viewedByContainer: {
      alignItems: 'center',
      backgroundColor: Greys.shade200,
      borderTopLeftRadius: 12,
      flexDirection: 'row',
      height: 21,
      justifyContent: 'flex-end',
      marginTop: 7,
      paddingLeft: 12,
      paddingRight: 5,
    },
    imageText: {
      // To make the text take up the remaining space
      // and not overflow the parent container
      // This is a workaround for the ellipsizeMode prop not working
      // on children of a flex row container
      width: 0,
      flexGrow: 1,
    },
    fileImg: {
      width: 24,
      height: 24,
      resizeMode: 'contain',
    },
    fileContainer: {
      padding: 12,
      borderRadius: BorderRadius.sm,
      borderColor: Greys.shade200,
      borderWidth: 1,
    },
  });
