import { ChatUnitType } from '../../../API';

import { constants } from '@/constants';

/**
 * Get the limit of users for the chat unit type
 * @see src/domain/invites/hooks/useRemainingSeats.ts
 *
 * @param type ChatUnitType
 * @returns The limit of users for the chat unit type
 */
export const getChatUnitLimit = (type?: ChatUnitType) => {
  if (!type) {
    return 0;
  }
  if (type === ChatUnitType.TABLE) {
    return constants.tableUserLimit;
  }
  if (type === ChatUnitType.ROOM) {
    return constants.roomUserLimit;
  }
  return constants.dmUserLimit;
};
