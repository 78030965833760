import { getUser } from './mockData';
import type { TableChatUser } from './types';

export const getUserDetails = (): Promise<TableChatUser> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(getUser());
    }, 1000);
  });
};
