import { useCallback, useRef } from 'react';
import Config from 'react-native-config';
import { sentence } from 'txtgen';
import { useShallow } from 'zustand/react/shallow';

import { useMessageStore } from '@/domain/conversation/state/useMessageStore';
import useUserStore from '@/domain/user/state/useUserStore';
import { saveMessage } from '@/services/datastore/message/saveMessage';
import { logger } from '@/services/logger/logger';
import type { ChatMessage } from '@/services/types';
import generateUniqueId from '@/utilities/helpers/uuid';
import { useActiveConversationStoreApi } from '../state/useActiveConversationStore';

type Props = {
  chatUnitId: string;
  conversationId: string;
  myTableUserId?: string;
};

export const useMessages = ({
  chatUnitId,
  conversationId,
  myTableUserId,
}: Props) => {
  const [messages, addMessages] = useMessageStore(
    useShallow(state => [
      state.getMessagesForChatView(chatUnitId, conversationId),
      state.addMessages,
    ]),
  );

  const user = useUserStore(useShallow(state => state.user));

  const storeApi = useActiveConversationStoreApi();

  const messageUrl = useRef('');
  const setImage = useCallback((url: string) => (messageUrl.current = url), []);

  const isMessageInConvo = useCallback(
    (id: string | number) => {
      return messages.find(msg => msg._id === id) || false;
    },
    [messages],
  );

  const addMessageToConvo = useCallback(
    (msg: ChatMessage) => {
      if (!isMessageInConvo(msg._id)) {
        addMessages({
          messages: [
            {
              ...msg,
              createdAt: new Date(msg.createdAt).toISOString(),
            },
          ],
        });
      }
    },
    [addMessages, isMessageInConvo],
  );

  const onSend = useCallback(
    async (text: string, metaData?: string) => {
      try {
        if (!user?.id) {
          throw new Error('Current user is not fetched');
        }
        if (!myTableUserId) {
          throw new Error('TableUserId is required for sending a message');
        }
        const id = generateUniqueId();

        const allowedUserIds =
          storeApi.getState().conversationUserIds.get(conversationId) ?? [];

        if (!allowedUserIds.length) {
          logger.error('useMessages::onSend', {
            message: 'unable to determine allowedUserIds for conversation',
            conversationId,
          });
        }

        const messageId = generateUniqueId();
        const now = new Date();
        const createdAt = now.toISOString();
        const SK = `${user.id}#${createdAt}#${messageId}`;

        if (
          (Config.ENV === 'dev' || Config.ENV === 'stage') &&
          text.toLowerCase() === 'random'
        ) {
          text = sentence();
        }

        const newMessage = {
          _id: id,
          allowedUserIds,
          conversationId: conversationId,
          createdAt: now,
          id,
          image: messageUrl.current,
          sent: true,
          chatUnitId,
          chatUnitUserId: myTableUserId,
          messageId,
          SK,
          text: text,
          status: 'SENT',
          metaData: metaData,
          user: {
            _id: user?.id ?? '',
            avatar: user?.avatar ?? '',
            id: user?.id ?? '',
            name: user?.name ?? '',
            pending: false,
            status: 'online',
            username: user?.username ?? '',
          },
          userId: user?.id ?? '',
        };
        addMessageToConvo(newMessage);
        saveMessage({
          allowedUserIds,
          conversationId,
          createdAt,
          id: newMessage._id,
          image: newMessage.image,
          chatUnitId: newMessage.chatUnitId,
          chatUnitUserId: newMessage.chatUnitUserId,
          messageId: newMessage.messageId,
          SK,
          text: newMessage.text,
          userId: newMessage.userId,
          metaData: metaData,
        });

        storeApi.getState().markPendingConvoHasMessages();

        setImage('');

        if (
          (Config.ENV === 'dev' || Config.ENV === 'stage') &&
          text.toLowerCase() === 'random 100'
        ) {
          for (let i = 0; i < 100; i++) {
            await onSend(sentence());
            await new Promise(resolve => setTimeout(resolve, 500));
          }
        }
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    },
    [
      user,
      myTableUserId,
      conversationId,
      chatUnitId,
      addMessageToConvo,
      setImage,
      storeApi,
    ],
  );

  return { messages, onSend, setImage };
};
