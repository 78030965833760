import { getAllConversationId } from '@/domain/conversation/functions/getAllConversationId';
import type { ChatUnit } from '@/services';
import { getConversations } from '@/services/datastore/conversation/getConversations';
import { updateConversation } from '@/services/datastore/conversation/updateConversation';
import { logger } from '@/services/logger/logger';
import type { ChatUnitUser } from '@/services/types';

export const addNewUsersToAllConversation = async (
  chatUnit: ChatUnit,
  newUsers: ChatUnitUser[],
) => {
  const conversations = await getConversations(chatUnit.id);
  const allConversation = conversations.find(
    conversation =>
      conversation.conversationId === getAllConversationId(chatUnit.id),
  );

  if (!allConversation) {
    logger.debug('No all conversation found for chatUnit', chatUnit.id);
    return;
  }

  newUsers.reduce((acc, user) => {
    acc.allowedUserIds = [...(acc.allowedUserIds ?? []), user.id];
    acc.chatUnitUserIds = [...(acc.chatUnitUserIds ?? []), user.chatUnitUserId];
    return acc;
  }, allConversation);

  const result = await updateConversation({
    ...allConversation,
    // dedupe allowedUserIds and chatUnitUserIds
    allowedUserIds: Array.from(new Set(allConversation.allowedUserIds)),
    chatUnitUserIds: Array.from(new Set(allConversation.chatUnitUserIds)),
  });

  return result;
};
