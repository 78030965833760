export const generatePassword = () => {
  const length = 8;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+=-[]{};:<>,.?/';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  if (!/[A-Z]/.test(retVal)) {
    retVal =
      retVal.slice(0, 0) +
      String.fromCharCode(Math.random() * (91 - 65) + 65) +
      retVal.slice(0 + 1);
  }

  if (!/[0-9]/.test(retVal)) {
    retVal =
      retVal.slice(0, 1) +
      String.fromCharCode(Math.random() * (58 - 48) + 48) +
      retVal.slice(1 + 1);
  }

  if (!/[!@#$%^&*()_+=\-[\]{};:<>,.?/]/.test(retVal)) {
    retVal =
      retVal.slice(0, 2) +
      charset.charAt(Math.floor(Math.random() * 15) + 62) +
      retVal.slice(2 + 1);
  }

  return retVal;
};
