import { ChatUnitSubType, ChatUnitType } from '@/API';
import AddIcon from '@/assets/svg/add-friend-icon.svg';
import LottieLoader from '@/components/loaders/LottieLoader';
import { constants } from '@/constants';
import { createChatUnit } from '@/domain/chatUnit/functions/createChatUnit';
import { inviteContactsToDM } from '@/domain/chatUnit/functions/inviteContactsToDM';
import LexicalSectionList from '@/domain/contact/components/directory/listView';
import { getMobileNumberForContact } from '@/domain/contact/functions/getMobileNumberForContact';
import type { Contact } from '@/domain/contact/state/useContactStore';
import ChoosePhoneNumberSheet from '@/domain/invites/components/ChoosePhoneNumberSheet';
import useOrganisations from '@/domain/organisation/hooks/useOrganisations';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import { Colors } from '@/domain/theme/Colors';
import { useDefaultCallingCode } from '@/domain/user/hooks/useDefaultCallingCode';
import useUserStore from '@/domain/user/state/useUserStore';
import { useAppContacts } from '@/hooks/useAppContacts';
import useNavigation from '@/hooks/useNavigation';
import { getDMs } from '@/services/datastore/chatUnit/getChatUnits';
import { eventBus } from '@/services/eventBus/eventBus';
import { logger } from '@/services/logger/logger';
import useTranslations from '@/translations/useTranslation';
import AppText from '@/ui/app/elements/AppText';
import SvgContact from '@/ui/app/elements/Icon/icons/Contact';
import sleep from '@/utilities/helpers/sleep';
import generateUniqueId from '@/utilities/helpers/uuid';
import { web } from '@/utilities/platform';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  KeyboardAvoidingView,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import type { PhoneNumber } from 'react-native-contacts';

const OneOnOneChat = () => {
  const { sectionedContacts, loading, searchQuery, setSearchQuery } =
    useAppContacts();

  const { translate } = useTranslations();
  const { navigate, replaceRoute, goBack } = useNavigation();
  const user = useUserStore(state => state.user);
  const { showPrompt } = usePromptStore();
  const organisations = useOrganisations();

  const defaultCountryCallingCode = useDefaultCallingCode();
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [contactToSelect, setContactToSelect] = useState<Contact>();
  const [loadingDM, setLoadingDM] = React.useState(false);

  const handleContactSelect = useCallback(
    async (contact: Contact) => {
      if (contact.phoneNumbers.length > 1) {
        setContactToSelect(contact);
        await sleep(constants.modalRaceConditionDelay);
        setShowPhoneNumbers(true);
      } else {
        const validMobileNumber = getMobileNumberForContact(
          contact,
          defaultCountryCallingCode,
        );

        if (!validMobileNumber) {
          Alert.alert(
            translate('unable_to_add_contact_alert_title', {
              name: contact.firstName,
            }),
            translate('unable_to_add_contact_alert_message') +
              '\n' +
              contact.phoneNumbers.map(p => p.number).join('\n'),
          );
          return;
        }
        const selectedContact = {
          ...contact,
          phoneNumbers: [
            {
              label: 'mobile',
              number: validMobileNumber,
            },
          ],
        };
        createAndNavigateToChatUnit(selectedContact);
      }
    },
    [defaultCountryCallingCode, translate],
  );

  const handlePhoneSelect = useCallback(
    (phone: PhoneNumber) => {
      setShowPhoneNumbers(false);
      const selectedContact = {
        ...contactToSelect!,
        phoneNumbers: [phone],
      };
      setContactToSelect(undefined);
      createAndNavigateToChatUnit(selectedContact);
    },
    [contactToSelect],
  );

  const createAndNavigateToChatUnit = async (selectedContact: Contact) => {
    if (!user) {
      return;
    }
    if (selectedContact.userId) {
      const chatUnitUserIds = [selectedContact.userId!, user?.id!];
      const chatUnits = (await getDMs()).filter(
        chatUnit =>
          chatUnit.allowedUserIds?.length === chatUnitUserIds.length &&
          chatUnit.allowedUserIds?.every(id => chatUnitUserIds.includes(id)),
      );
      if (chatUnits.length) {
        replaceRoute('ChatView', { chatUnitId: chatUnits[0].id });
        return;
      }
    }

    try {
      setLoadingDM(true);
      const startTimestamp = Date.now();
      const subject =
        `${selectedContact.firstName} ${selectedContact.lastName}` !== ' '
          ? `${selectedContact.firstName || ''} ${selectedContact.lastName || ''}`.trim()
          : selectedContact.username;
      const selectedOrganisation = organisations.find(
        org => org.allowedUserIds?.indexOf(selectedContact.userId!) !== -1,
      );
      const orgId =
        selectedOrganisation?.id === 'personal'
          ? undefined
          : selectedOrganisation?.id;

      const { chatUnit } = await createChatUnit({
        allowedUserIds: [user.id],
        title: subject,
        type: ChatUnitType.QUICK_CHAT,
        subtype: ChatUnitSubType.ONE_ON_ONE,
        organisationId: orgId,
        user,
        backgroundImage: 'whisper',
      });

      await inviteContactsToDM(chatUnit.id, [selectedContact], user.id);

      const executionTime = Date.now() - startTimestamp;
      logger.info('Create1-on-1', {
        executionTime: executionTime / 1000,
      });
      replaceRoute('ChatView', { chatUnitId: chatUnit.id }); // go to the newly created DM
    } catch (error) {
      logger.error('createDMError', error);
      showPrompt({
        title: translate('send_invite_error_prompt_title'),
        body: translate('send_invite_error_prompt_body'),
      });
    } finally {
      setLoadingDM(false);
    }
  };

  const onSubmitEditing = useCallback(() => {
    if (searchQuery.length === 0 || sectionedContacts.length > 2) {
      return;
    }
    if (sectionedContacts.length === 2) {
      handleContactSelect(sectionedContacts[1] as Contact);
    }
  }, [searchQuery, sectionedContacts]);

  const directorySessionId = useMemo(() => generateUniqueId(), []);

  const goToCreateInvite = useCallback(() => {
    navigate('CreateInvite', {
      type: ChatUnitType.QUICK_CHAT,
      sessionId: directorySessionId,
    });
  }, [navigate, directorySessionId]);

  useEffect(() => {
    eventBus.on(
      'inviteUserSubmitted',
      ({ contacts: selectedContacts, sessionId }) => {
        if (sessionId === directorySessionId) {
          console.log('selectedContacts', selectedContacts[0]);
          handleContactSelect(selectedContacts[0] as Contact);
          goBack(); // return back to this screen
        }
      },
    );

    return () => {
      eventBus.off('inviteUserSubmitted');
    };
  }, [directorySessionId, goBack]);

  if (loading || loadingDM) {
    return (
      <View style={{ height: '100%' }}>
        <LottieLoader />
      </View>
    );
  }

  return (
    <View style={styles.flex}>
      <View style={styles.container}>
        <SvgContact style={styles.contactIcon} />
        <Text style={styles.selectContactText}>
          {translate('select_a_contact')}
        </Text>
      </View>
      <View style={styles.selectedContactsRow}>
        <TextInput
          onChangeText={setSearchQuery}
          placeholder={
            searchQuery.length > 0 ? '' : translate('search_contacts_label')
          }
          value={searchQuery}
          style={[
            styles.searchbar,
            searchQuery.length > 0 && styles.searchbarActive,
          ]}
          placeholderTextColor={Colors.neutral70}
          onSubmitEditing={onSubmitEditing}
        />
      </View>

      {searchQuery.length === 0 && (
        <Pressable onPress={goToCreateInvite} style={styles.addSomeoneAbove}>
          <AddIcon size={40} style={{ marginRight: 15 }} />
          <AppText size={14} type="primary700" color={Colors.neutral80}>
            {translate('add_someone_new')}
          </AppText>
        </Pressable>
      )}
      <KeyboardAvoidingView
        behavior="padding"
        style={styles.flex}
        keyboardVerticalOffset={100}>
        <LexicalSectionList
          onContactPress={handleContactSelect}
          searchQuery={searchQuery}
          sections={sectionedContacts}
          canSelect={false}
        />
        {searchQuery.length > 0 && (
          <Pressable
            onPress={goToCreateInvite}
            style={[
              styles.addSomeoneBelow,
              { marginTop: sectionedContacts.length > 0 ? -50 : 0 },
            ]}>
            <AddIcon size={40} style={{ marginRight: 15 }} />
            <AppText size={14} type="primary700" color={Colors.neutral80}>
              {translate('add_someone_new')}
            </AppText>
          </Pressable>
        )}
      </KeyboardAvoidingView>
      <ChoosePhoneNumberSheet
        contact={contactToSelect}
        onPhoneSelect={handlePhoneSelect}
        open={showPhoneNumbers}
        setOpen={setShowPhoneNumbers}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  contactIcon: {
    marginRight: 10,
  },
  selectContactText: {
    color: Colors.neutral70,
    fontSize: 12,
    fontWeight: '700',
  },
  selectedContactsRow: {
    flexDirection: 'row',
    height: 44,
    margin: 10,
    padding: 5,
    backgroundColor: Colors.neutral10,
    borderRadius: 12,
  },
  searchbar: {
    paddingHorizontal: 10,
    height: 24,
    marginTop: 5,
    padding: 0,
    flex: 1,
    fontFamily: 'OpenSans-SemiBold',
    fontWeight: '600',
    fontSize: 14,
    color: Colors.neutral80,
  },
  searchbarActive: {
    flex: 0,
    marginLeft: 10,
    backgroundColor: Colors.neutral0,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.primaryLight,
  },
  addSomeoneAbove: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    marginVertical: 10,
    maxHeight: 50,
  },
  addSomeoneBelow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    maxHeight: 50,
  },
});

export default OneOnOneChat;
