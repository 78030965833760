import { useEffect, useRef } from 'react';

import { constants } from '@/constants';
import { ChatUnitType } from '@/domain/invites/types/types';
import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import useTranslations from '@/translations/useTranslation';

type Props = {
  hasChatUnit: boolean;
  loading: boolean;
  navigate: (route: string) => void;
  type?: ChatUnitType;
};

export const useChatUnitViewLoadingTimeoutPrompt = ({
  hasChatUnit,
  loading,
  navigate,
  type,
}: Props) => {
  const { showPrompt } = usePromptStore();
  const { translate } = useTranslations();
  const loadingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if ((loading || !hasChatUnit) && type && !loadingTimeoutRef.current) {
      loadingTimeoutRef.current = setTimeout(() => {
        showPrompt({
          title: translate(`loading_${type}_error_prompt_title`),
          body: translate(`loading_${type}_error_prompt_body`),
          onConfirm: async () =>
            navigate(
              type === ChatUnitType.TABLE
                ? 'Tables'
                : type === ChatUnitType.ROOM
                  ? 'Rooms'
                  : 'DMs',
            ),
        });
      }, constants.loadingTimeout);
    }

    if (!loading && hasChatUnit && loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
      loadingTimeoutRef.current = null;
    }
  }, [hasChatUnit, loading, navigate, showPrompt, translate, type]);

  useEffect(() => {
    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
        loadingTimeoutRef.current = null;
      }
    };
  }, []);
};
