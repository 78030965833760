import React from 'react';
import { StyleSheet, View } from 'react-native';

import Diamond from '@/domain/invites/views/success/Diamond';
import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';

type Props = {
  organisationName?: string | null;
  size?: number;
};

export const WorkspaceIndicator = ({ organisationName, size = 10 }: Props) => {
  const { translate } = useTranslations();

  return (
    <View style={styles.workspaceIndicator}>
      <Diamond
        size={16}
        color={organisationName ? Colors.melon : Colors.primaryLight}
      />
      <Spacer width={4} />
      <AppText size={size}>{organisationName || translate('Personal')}</AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  workspaceIndicator: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 4,
  },
  workspaceIndicatorText: {
    fontSize: 10,
    marginLeft: 4,
  },
});
