export const validateInput = (
  input: any,
  requiredKeys: string[],
  modelName: string,
) => {
  requiredKeys.forEach(key => {
    if (!input[key]) {
      throw new Error(
        `Factory::invalid ${modelName}::${key} is required - ${JSON.stringify(
          input,
        )}`,
      );
    }
  });
};
