import { useKeyboardHandler } from 'react-native-keyboard-controller';
import { Easing, useSharedValue, withTiming } from 'react-native-reanimated';

import { android } from '@/utilities/platform';

export const useKeyboardTransitions = () => {
  const height = useSharedValue(0);

  useKeyboardHandler(
    {
      onStart: e => {
        'worklet';
        if (android) {
          height.value = withTiming(e.height > 0 ? -15 : 0, {
            duration: 250,
            easing: Easing.bezier(
              0.19919472913616398,
              0.010644531250000006,
              0.27920937042459737,
              0.91025390625,
            ),
          });
        } else {
          height.value = e.height > 0 ? -20 : 0;
        }
      },
    },
    [],
  );

  return { height };
};
