import { useNetInfo } from '@react-native-community/netinfo';
import { useCallback, useEffect, useState } from 'react';

import { constants } from '@/constants';
import { logger } from '@/services/logger/logger';
import { delay } from '@/utilities/lib/delay';

export const useNoConnection = () => {
  const [isChecking, setIsChecking] = useState(false);
  const { isInternetReachable } = useNetInfo();

  // callback for the "Try again" button
  const checkInternetConnection = useCallback(async () => {
    try {
      setIsChecking(true);
    } catch (e) {
      logger.debug('checkForLoggedInUser::', e);
    } finally {
      // the error is instant but want to show the user some visual feedback
      await delay(constants.modalRaceConditionDelay);
      setIsChecking(false);
    }
  }, []);

  // automatically check when internet connection is detected
  // this is redundant with the checkInternetConnection function above
  // however the "Try again" button is a requirement
  useEffect(() => {
    if (isInternetReachable) {
      checkInternetConnection();
    }
  }, [checkInternetConnection, isInternetReachable]);

  return { checkInternetConnection, isChecking };
};
