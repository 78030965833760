import React, { type FC, useCallback } from 'react';
import {
  type GestureResponderEvent,
  type ScrollViewProps,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import { useResizeMode } from 'react-native-keyboard-controller';
import Reanimated, {
  interpolate,
  runOnJS,
  scrollTo,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  useWorkletCallback,
} from 'react-native-reanimated';

import { useSmoothKeyboardHandler } from './useSmoothKeyboardHandler';

const BOTTOM_OFFSET = 50;

type Props = {
  onScrollY?: (y: number) => void;
};

// NOTE: onScroll is overridden and is very awkward to combine useAnimatedScrollHandler with a native scroll handler
// hence introducing onScrollY callback prop
const KeyboardAwareScrollView: FC<
  Omit<ScrollViewProps, 'onScroll'> & Props
> = ({ children, onScrollY, ...rest }) => {
  useResizeMode();

  const scrollViewAnimatedRef = useAnimatedRef<Reanimated.ScrollView>();
  const scrollPosition = useSharedValue(0);
  const click = useSharedValue(0);
  const position = useSharedValue(0);
  const fakeViewHeight = useSharedValue(0);
  const keyboardHeight = useSharedValue(0);

  const { height } = useWindowDimensions();

  const onScroll = useAnimatedScrollHandler(
    {
      onScroll: e => {
        position.value = e.contentOffset.y;
        if (onScrollY) {
          runOnJS(onScrollY)(e.contentOffset.y);
        }
      },
    },
    [],
  );

  const onContentTouch = useCallback(
    (e: GestureResponderEvent) => {
      // to prevent clicks when keyboard is animating
      if (keyboardHeight.value === 0) {
        click.value = e.nativeEvent.pageY;
        scrollPosition.value = position.value;
      }
    },
    [click, keyboardHeight, position, scrollPosition],
  );

  /**
   * Function that will scroll a ScrollView as keyboard gets moving
   */
  const maybeScroll = useWorkletCallback((e: number) => {
    'worklet';

    fakeViewHeight.value = e;

    const visibleRect = height - keyboardHeight.value;

    if (visibleRect - click.value <= BOTTOM_OFFSET) {
      const interpolatedScrollTo = interpolate(
        e,
        [0, keyboardHeight.value],
        [0, keyboardHeight.value - (height - click.value) + BOTTOM_OFFSET],
      );
      const targetScrollY =
        Math.max(interpolatedScrollTo, 0) + scrollPosition.value;

      scrollTo(scrollViewAnimatedRef, 0, targetScrollY, false);
    }
  }, []);

  useSmoothKeyboardHandler(
    {
      onEnd: e => {
        'worklet';

        keyboardHeight.value = e.height;
      },
      onMove: e => {
        'worklet';

        maybeScroll(e.height);
      },
      onStart: e => {
        'worklet';

        if (e.height > 0) {
          // just persist height - later will be used in interpolation
          keyboardHeight.value = e.height;
        }
      },
    },
    [height],
  );

  const view = useAnimatedStyle(
    () => ({
      height: fakeViewHeight.value,
    }),
    [],
  );

  return (
    <Reanimated.ScrollView
      ref={scrollViewAnimatedRef}
      {...rest}
      onScroll={onScroll}
      onTouchStart={onContentTouch}
      scrollEventThrottle={16}>
      {children}
      <Reanimated.View style={view} />
    </Reanimated.ScrollView>
  );
};

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
export default KeyboardAwareScrollView;
