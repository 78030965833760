import React, { useMemo } from 'react';

import { Badge } from '@/components/badge/Badge';
import { getBadgeTextSize } from '@/components/badge/getBadgeTextSize';

type Props = {
  unreadMessageCount: number;
};

export const CardBadge = ({ unreadMessageCount }: Props) => {
  const styles = useMemo(
    () => ({
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 6,
      borderWidth: 0,
      fontSize: getBadgeTextSize(unreadMessageCount) as 'sm' | 'md' | 'lg',
      right: 0,
      top: 0,
    }),
    [unreadMessageCount],
  );
  return (
    <Badge
      badgeContent={unreadMessageCount}
      hidden={!unreadMessageCount}
      styles={styles}
    />
  );
};
