import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import SingleReceivedInvitation from './SingleReceivedInvitation';
import SingleSentInvitation from './SingleSentInvitation';

import BackIcon from '@/assets/BackArrow.svg';
import ChatUnitBackgroundImage from '@/components/image/ChatUnitBackgroundImage';
import Caption from '@/components/text/Caption';
import { useMyInviteStore } from '@/domain/invites/state/useMyInvitesStore';
import { InviteType } from '@/domain/invites/types/types';
//import { usePromptStore } from '@/domain/prompt/state/usePromptStore';
import useNavigation from '@/hooks/useNavigation';
import type { InviteDetailsParams } from '@/navigation/types';
import { whiteBackground } from '@/theme/colors';
import { hp, wp } from '@/theme/responsiveHelpers';
import useTranslations from '@/translations/useTranslation';
import { Greys } from '@/ui/common/colors';
import { web } from '@/utilities/platform';

const InviteDetails = ({
  route: { params },
}: {
  route: { params: InviteDetailsParams };
}) => {
  const { translate } = useTranslations();
  const { goBack } = useNavigation();
  const invite = useMyInviteStore(state => state.getInvite(params.id));

  const { top } = useSafeAreaInsets();
  //const { showPrompt } = usePromptStore();

  // Disabling this since it is triggering before the store has
  // been updated with a valid invite
  // if (!invite) {
  //   showPrompt({
  //     title: translate('invalid_invite_prompt_title'),
  //     body: translate('invalid_invite_prompt_body'),
  //     onConfirm: async () => navigate('InviteList'),
  //   });

  //   // display a nice background image
  //   return (
  //     <View style={styles.backgroundImage}>
  //       <ChatUnitBackgroundImage width="100%" height="100%" />
  //     </View>
  //   );
  // }

  return (
    <View style={styles.root}>
      {invite && invite.type === InviteType.RECEIVER ? (
        <View style={styles.backgroundImage}>
          <ChatUnitBackgroundImage
            imageKey={invite.targetInfo.backgroundImage}
            width="100%"
            height="100%"
          />
        </View>
      ) : null}
      <TouchableOpacity
        onPress={() => goBack()}
        style={[styles.header, { marginTop: top }]}>
        <BackIcon />
        {invite &&
          (invite.type === InviteType.RECEIVER ? (
            <Caption
              children={translate('all_received_invites')}
              style={styles.backText}
            />
          ) : (
            <Caption
              children={translate('all_sent_invites')}
              style={styles.backText}
            />
          ))}
      </TouchableOpacity>
      <View style={styles.overlay} />
      <View style={styles.inviteContainer}>
        {invite &&
          (invite.type === InviteType.RECEIVER ? (
            <SingleReceivedInvitation data={invite} />
          ) : (
            <SingleSentInvitation data={invite} />
          ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backText: {
    fontSize: 14,
    fontWeight: '400',
    marginLeft: 10,
    marginTop: 5,
  },
  backgroundImage: {
    height: web ? '100%' : hp(100),
    left: 0,
    position: 'absolute',
    top: 0,
    width: web ? '100%' : wp(100),
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    left: 22,
    marginBottom: 10,
    position: 'absolute',
    top: 10,
    zIndex: 50000,
  },
  inviteContainer: {
    padding: 20,
    width: web ? '100%' : wp(100),
  },
  overlay: {
    backgroundColor: whiteBackground,
    height: web ? '100%' : hp(100),
    position: 'absolute',
    width: web ? '100%' : wp(100),
  },
  root: {
    flex: 1,
    backgroundColor: Greys.shade0,
  },
});

export default InviteDetails;
