import { DataStore } from 'aws-amplify/datastore';

import {
  ChatUnitUser as ChatUnitUserModel,
  Conversation,
} from '../../../models';

import { factory } from '@/services/factory/factory';
import type { ChatUnitUser } from '@/services/types';

export const getChatUnitUsersForConversation = async (
  conversationId: string,
  chatUnitId: string,
) => {
  const results: ChatUnitUser[] = [];

  const conversation = factory.conversation(
    await DataStore.query(Conversation, { conversationId, chatUnitId }),
  );

  if (conversation && conversation.chatUnitUserIds) {
    await Promise.all(
      conversation.chatUnitUserIds.map(async chatUnitUserId => {
        if (chatUnitUserId) {
          const chatUnitUser = await DataStore.query(
            ChatUnitUserModel,
            chatUnitUserId,
          );

          if (chatUnitUser) {
            results.push(factory.chatUnitUser(chatUnitUser));
          }
        }
      }),
    );
  }

  return results;
};
