import { Colors } from '@/domain/theme/Colors';
import { AppText } from '@/ui/app';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

const FreePill = ({ plan }: { plan: string }) => {
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      angle={180}
      useAngle
      colors={[Colors.neutral0, Colors.neutral20]}
      style={styles.pill}>
      <AppText size={12} type="primary600">
        {plan}
      </AppText>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  pill: {
    paddingHorizontal: 10,
    paddingVertical: 4.5,
    borderRadius: 10,
  },
});

export default memo(FreePill);
