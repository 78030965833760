import React from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Rive, { Fit } from 'rive-react-native';

import { Colors } from '@/domain/theme/Colors';
import { ios } from '@/utilities/platform';

const riveName = ios ? '8seats_intro' : 'seats_intro';

const GRADIENT_BOTTOM = [`${Colors.ocean}00`, Colors.ocean];
const GRADIENT_TOP = [Colors.ocean, `${Colors.ocean}00`];

const linearGradientStartPosition = { x: 0.5, y: 0 };
const linearGradientEndPosition = { x: 0.5, y: 1 };

const OnboardingBackground = () => {
  return (
    <>
      <Rive fit={Fit.Cover} resourceName={riveName} style={styles.background} />
      <LinearGradient
        colors={GRADIENT_TOP}
        style={styles.backgroundGradientTop}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
      <LinearGradient
        colors={GRADIENT_BOTTOM}
        style={styles.backgroundGradientBottom}
        start={linearGradientStartPosition}
        end={linearGradientEndPosition}
      />
    </>
  );
};

const styles = StyleSheet.create({
  background: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -4,
  },
  backgroundGradientBottom: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: Dimensions.get('window').height / 2,
    width: Dimensions.get('window').width,
  },
  backgroundGradientTop: {
    top: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -2,
    height: Dimensions.get('window').height / 2,
    width: Dimensions.get('window').width,
  },
  web: {
    backgroundColor: Colors.desert,
  },
});

export default OnboardingBackground;
