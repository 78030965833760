import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBubble = (props: SvgProps) => (
  <Svg
    width={27}
    height={26}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M25.293 19.015l-.26-.26-.033-.033-3.692-3.692a2 2 0 00-1.414-.585h-1.338v-1.5h1.338a3.5 3.5 0 012.475 1.025L25 16.6V4.723a2.5 2.5 0 00-2.5-2.5H10.278a2.5 2.5 0 00-2.5 2.5v3.222h-1.5V4.722a4 4 0 014-4H22.5a4 4 0 014 4v14.293a.5.5 0 01-.854.354l-.353-.354z"
      fill={props?.fill}
    />
    <Path
      d="M4.985 20.101L1.5 23.586v-10.92a3 3 0 013-3h9.333a3 3 0 013 3v3.556a3 3 0 01-3 3H7.106a3 3 0 00-2.121.879z"
      stroke={props?.fill}
      strokeWidth={2}
      fill="none"
    />
  </Svg>
);
export default SvgBubble;
