import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Dimensions, Pressable, StyleSheet } from 'react-native';

import { Colors } from '@/domain/theme/Colors';
import useTranslations from '@/translations/useTranslation';
import { AppText } from '@/ui/app';

function OnboardingSignUpButton() {
  const navigation = useNavigation();
  const { translate } = useTranslations();

  return (
    <Pressable
      style={styles.signUpButton}
      onPress={() => navigation.navigate('OnboardingStepZero')}>
      <AppText
        type="primary700"
        size={17}
        textAlign="center"
        color={Colors.neutral0}>
        {translate('sign_up_label')}
      </AppText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  signUpButton: {
    paddingVertical: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: Dimensions.get('window').width - 80,
    borderWidth: 2,
    borderColor: Colors.neutral0,
  },
});

export default OnboardingSignUpButton;
